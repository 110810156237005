import {CodeResponse} from "@react-oauth/google";
import {BffServiceClient} from '../generated/sp/bff_service/bff_service_grpc_web_pb';
import {AuthRequest, AuthResponse, UserRole} from "../generated/sp/bff_service/bff_service_pb";
import {Metadata} from "grpc-web";
import {encodeBase64FromArray} from "./encodeUtils";
import {getSourcePointTokensFromStorage} from "./localStorageUtils";

// SourcePointTokens+UserProfile used across the platform
export interface SourcePointTokens {
    imageUrl: string | undefined;
    name: string | undefined;
    email: string | undefined;
    authToken: string | undefined;
    tokenStartTime: number | undefined;
    tokenEndTime: number | undefined;
    userRoles: Array<UserRole> | undefined;
    responseBase64: string | undefined;
}

export const isUserStandardRole = (sourcePointTokens: SourcePointTokens|undefined): boolean => {
    if (sourcePointTokens !== undefined && sourcePointTokens.userRoles !== undefined) {
        return sourcePointTokens.userRoles.includes(UserRole.USER_ROLE_STANDARD);
    }
    return false;
}

export const isUserAdminRole = (sourcePointTokens: SourcePointTokens|undefined): boolean => {
    if (sourcePointTokens !== undefined && sourcePointTokens.userRoles !== undefined) {
        return sourcePointTokens.userRoles.includes(UserRole.USER_ROLE_ADMIN);
    }
    return false;
}

export const isUserVncRole = (sourcePointTokens: SourcePointTokens|undefined): boolean => {
    if (sourcePointTokens !== undefined && sourcePointTokens.userRoles !== undefined) {
        return sourcePointTokens.userRoles.includes(UserRole.USER_ROLE_VNC_ACCESS) || isUserAdminRole(sourcePointTokens);
    }
    return false;
}

export const isUserModelTrainingRole = (sourcePointTokens: SourcePointTokens|undefined): boolean => {
    if (sourcePointTokens !== undefined && sourcePointTokens.userRoles !== undefined) {
        return sourcePointTokens.userRoles.includes(UserRole.USER_ROLE_MODEL_TRAINING_ACCESS) || isUserAdminRole(sourcePointTokens);
    }
    return false;
}

const getAuthResponse = async (bffService: BffServiceClient, redirectUri: string, authToken: string): Promise<AuthResponse> => {
    var req = new AuthRequest();
    req.setAuthToken(authToken);
    req.setRedirectUri(redirectUri);
    return new Promise<AuthResponse>((resolve, reject) => {
        bffService.authenticate(req, {}, (err, response) => {
            if (err) reject(err);
            else resolve(response!)
        });
    });
}

const fetchSourcePointTokens = async (codeResponse: CodeResponse) => {
    let authToken = codeResponse.code;
    const bffService = new BffServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
    const redirect_uri = process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI!;

    return getAuthResponse(bffService, redirect_uri, authToken)
        .then(authResponse => {
            let responseBase64 = encodeBase64FromArray(authResponse.serializeBinary());
            let encryptedSourcePointToken = authResponse.getEncryptedSourcePointToken();
            console.log(`Received sourcePointToken=[${encryptedSourcePointToken!}]`);
            let userProfile = authResponse.getUserProfile();
            console.log(`Received userProfile =[${userProfile!}]`);
            let tokenStart = new Date();
            var tokenEnd = new Date(tokenStart);
            tokenEnd.setSeconds(tokenEnd.getSeconds() + 86400); //TODO: properly implement token expiry
            const profile: SourcePointTokens = {
                imageUrl: userProfile?.getPictureUrl(),
                name: userProfile?.getName(),
                email: userProfile?.getEmailAddress(),
                authToken: encryptedSourcePointToken,
                tokenStartTime: tokenStart.getTime(),
                tokenEndTime: tokenEnd.getTime(),
                userRoles: userProfile?.getUserRolesList(),
                responseBase64: responseBase64
            };
            return profile;
        });
};

const getStoredAuthResponse = () : string | undefined => {
    //TODO: check expiry here
    let sourcePointTokens: SourcePointTokens | undefined = getSourcePointTokensFromStorage();
    if (sourcePointTokens === undefined) {
        console.log("Could not generate grpc authorization header because auth token was missing from localstorage!");
        return undefined;
    } else {
        return sourcePointTokens?.responseBase64!
    }
}

const getAuthToken = (): string | undefined => {
    //TODO: check expiry here
    let sourcePointTokens: SourcePointTokens | undefined = getSourcePointTokensFromStorage();
    if (sourcePointTokens === undefined) {
        console.log("Could not generate grpc authorization header because auth token was missing from localstorage!");
        return undefined;
    } else {
        return sourcePointTokens?.authToken!
    }
}

// generate authheader to be used by all grpc calls
const generateAuthHeader = (): Metadata | undefined => {
    let authToken = getAuthToken();
    if (authToken === undefined) {
        return undefined;
    }
    return {
        "x-sourcepoint-auth-token": authToken!
    };
}

export {
    getAuthToken,
    getStoredAuthResponse,
    generateAuthHeader,
    fetchSourcePointTokens,
};
