import React, {FC, ReactElement, useCallback, useContext, useEffect, useState} from "react";
import {Box, Grid, IconButton, LinearProgress, TextField, Toolbar, Typography} from '@mui/material';
import {ScanServiceClient} from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {ScanJob} from '../generated/sp/scan_service/scan_service_pb';
import {DisplayOption} from '../generated/sp/scan_runner/common_pb';
import {useNavigate, useParams} from 'react-router-dom';
import {enumName} from '../lib/enumUtils';
import {formatRegion} from '../lib/regionUtils';
import {getScanJobStatusColor} from '../lib/statusUtils';
import {isUserAdminRole} from '../lib/authorizationUtils';
import KillJobButton from "../components/buttons/KillJobButton";
import {SourcePointTokensContext} from "../App";
import MasterContainer from "../components/containers/MasterContainer";
import {ProxyType} from "../generated/sp/scan_runner/scan_runner_pb";
import ScanJobPodLogsButton from "../components/buttons/ScanJobPodLogsButton";
import LinkIcon from '@mui/icons-material/Link';
import ProcessScanJobArtifactsButton from "../components/buttons/ProcessScanJobArtifactsButton";
import ScanJobArtifactInfinityTable from "../components/ScanJobArtifactInfinityTable";
import {fetchIabCmpMap} from "../lib/iabUtils";
import {formatGatingType} from "../lib/gatingUtils";
import ScanTimelineVisualListButton from "../components/buttons/ScanTimelineVisualListButton";
import ScanJobTimelineListCard from "../components/cards/ScanJobTimelineListCard";
import ScanResultsCard from "../components/cards/ScanResultsCard";
import {getScanJobById} from "../lib/scanServiceUtils";
import ScanJobGatingLogButton from "../components/buttons/ScanJobGatingLogButton";
import ScanJobField = ScanJob.ScanJobField;
import ScanType = ScanJob.ScanType;

const ScanJobDetail: FC<any> = (): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [ scanJob, setScanJob] = useState<ScanJob>();
    const { scanJobId } = useParams();
    const [cmpIdLookup, setCmpIdLookup] = useState<Map<number,string>>(new Map());
    const sourcePointTokens = useContext(SourcePointTokensContext);

    const navigate = useNavigate();
    const navigateToProperty = (propertyName: String) => {
        navigate(`/properties/${propertyName}`);
    };

    // 👇️ get memoized callback
    const reloadScanJob = useCallback(async () => {
        console.log(`Loading data for scanJobId=[${scanJobId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanJob = await getScanJobById(scanService, scanJobId!, [ScanJobField.SCAN_JOB_FIELD_RESULT_REPORTS]);
        console.log(`Finished reloading scanJobId=${scanJobId}`);
        setScanJob(scanJob);
        setLoading(false);
    }, [scanJobId]);

    useEffect(() => {
        (async () => {
            let cmpLookup = await fetchIabCmpMap();
            setCmpIdLookup(cmpLookup);
            await reloadScanJob();
        })();
    }, [reloadScanJob]);

    const isManualScan = () : boolean => {
        return !(scanJob?.getScanType() === ScanJob.ScanType.SCAN_TYPE_AUTOMATIC_SCAN || scanJob?.getScanType() === ScanJob.ScanType.SCAN_TYPE_EXTENSION || scanJob?.getScanType() === ScanJob.ScanType.SCAN_TYPE_DIAGNOSE);
    }

    return (
        <MasterContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Scan Job</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" label="ID" type="string" value={scanJob?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <TextField size="small" label="Property Name" type="string" value={scanJob?.getParameters()?.getPropertyName() || ''}
                               InputProps={{readOnly: true, endAdornment: <IconButton onClick={() => navigateToProperty(`${scanJob?.getParameters()?.getPropertyName()}`)}><LinkIcon/></IconButton>}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <TextField size="small" label="Completion Time" type="string" value={scanJob?.getCompletionTime()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <TextField size="small" label="Region" type="string" value={formatRegion(scanJob?.getParameters()?.getRegion()) || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Scan Type" type="string" value={enumName(ScanJob.ScanType, scanJob?.getScanType(), false, "SCAN_TYPE")} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Status" sx={{ input: { color: getScanJobStatusColor(scanJob?.getStatus()) } }} type="string" value={enumName(ScanJob.ScanJobStatus, scanJob?.getStatus(), false)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <TextField size="small" label="Pod Termination Status" type="string" value={enumName(ScanJob.PodTerminationStatus, scanJob?.getPodTerminationStatus(), false, "POD_TERMINATION_STATUS")} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <TextField size="small" label="Priority" type="string" value={scanJob?.getPriority().toString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <TextField size="small" label="Gating" type="string" value={formatGatingType(scanJob?.getGatingType())} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                { !isManualScan() && scanJob?.getScanJobDebugData() !== undefined &&
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Debug</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField size="small" label="Proxy Type" type="string" value={enumName(ProxyType, scanJob?.getScanJobDebugData()?.getProxyType(), true, "PROXY_TYPE") || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField size="small" label="Display Option" type="string" value={enumName(DisplayOption, scanJob?.getScanJobDebugData()?.getDisplayOption(), false)  || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField size="small" label="Job Name" type="string" value={scanJob?.getScanJobDebugData()?.getJobName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField size="small" label="Output URI" type="string" value={scanJob?.getScanJobDebugData()?.getOutputUri() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField size="small" label="Job Completion Time (secs)" type="string" value={scanJob?.getScanJobDebugData()?.getJobCompletionTimeSecs() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    { scanJob?.getScanType() === ScanType.SCAN_TYPE_DIAGNOSE &&
                      <Grid item xs={12}>
                        <TextField size="small" multiline rows={6} label="Script" type="string" value={scanJob?.getScript() || ''} inputProps={{ readOnly: true}} fullWidth/>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                }
                <Grid item xs={12}>
                    <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Audit</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Created" type="string" value={scanJob?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={9}>
                    <TextField size="small" label="Created By" type="string" value={scanJob?.getAuditData()?.getCreatedBy() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Updated" type="string" value={scanJob?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={9}>
                    <TextField size="small" label="Updated By" type="string" value={scanJob?.getAuditData()?.getUpdatedBy() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                { !isManualScan() && scanJob?.getScanJobResult() !== undefined  && scanJob?.getScanJobResult()?.getConsentResult() !== undefined &&
                  <React.Fragment>
                    <Grid item xs={12}>
                        <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Consent CMP Data</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="CMP ID" type="string" value={scanJob?.getScanJobResult()?.getConsentResult()?.getCmpId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="CMP Version" type="string" value={scanJob?.getScanJobResult()?.getConsentResult()?.getCmpVersion() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField size="small" label="CMP Name" type="string" value={cmpIdLookup.get(scanJob?.getScanJobResult()?.getConsentResult()?.getCmpId() || -1)  || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="TCF Policy Version" type="string" value={scanJob?.getScanJobResult()?.getConsentResult()?.getTcfPolicyVersion() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Consent Language" type="string" value={scanJob?.getScanJobResult()?.getConsentResult()?.getConsentLanguage() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                  </React.Fragment>
                }
                <Grid item xs={12}>
                    {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
                <Grid item xs={12}>
                   {isUserAdminRole(sourcePointTokens) && !isManualScan() &&
                       <React.Fragment>
                         <Toolbar disableGutters={true}>
                           <KillJobButton scanJobId={scanJobId} scanJobStatus={scanJob?.getStatus()}/>
                           <ScanJobPodLogsButton sx={{ ml: 1 }} scanJobId={scanJobId} scanJobStatus={scanJob?.getStatus()}/>
                           <ProcessScanJobArtifactsButton sx={{ ml: 1 }} scanJobId={scanJobId} scanJobStatus={scanJob?.getStatus()}/>
                           <ScanTimelineVisualListButton scanJobId={scanJobId} sx={{ ml: 1 }}/>
                           <ScanJobGatingLogButton scanJobId={scanJobId} sx={{ ml: 1 }} />
                         </Toolbar>
                       </React.Fragment>
                   }
                </Grid>

                {scanJob?.getScanJobResult() !== undefined && ((scanJob?.getScanJobResult()?.getScanRuleReportsList()?.length || 0) > 0) &&
                    <React.Fragment>
                        { scanJob?.getScanJobResult() !== undefined  &&
                          <React.Fragment>
                              <Grid item xs={12}>
                                  <Typography style={{fontWeight: 600}} variant="subtitle1" component="div">Scan Job Results</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                  <ScanResultsCard scanJobId={scanJob?.getId()} scanJobResult={scanJob?.getScanJobResult()} maxHeight={250}/>
                              </Grid>
                          </React.Fragment>
                        }
                    </React.Fragment>
                }
                { !isManualScan() &&
                  <React.Fragment>
                      <Grid item xs={12}>
                          <Typography style={{fontWeight: 600}} variant="subtitle1" component="div">Scan Timeline List</Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <ScanJobTimelineListCard maxHeight={250} scanJobId={scanJobId} />
                      </Grid>
                      <Grid item xs={12}>
                          <Typography style={{fontWeight: 600}} variant="subtitle1" component="div">Scan Artifacts</Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <ScanJobArtifactInfinityTable maxHeight={250} showFilterUi={false} showScanJobLink={false} showArtifactId={true} embeddedModeScanJobIdFilter={scanJobId}/>
                      </Grid>
                  </React.Fragment>
                }
                <Grid item xs={12}><br/></Grid>
            </Grid>
        </MasterContainer>
    );
};

export default ScanJobDetail;