/**
 * @fileoverview gRPC-Web generated client stub for sp.bff_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.sp = {};
proto.sp.bff_service = require('./bff_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.bff_service.BffServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.bff_service.BffServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.bff_service.AuthRequest,
 *   !proto.sp.bff_service.AuthResponse>}
 */
const methodDescriptor_BffService_Authenticate = new grpc.web.MethodDescriptor(
  '/sp.bff_service.BffService/Authenticate',
  grpc.web.MethodType.UNARY,
  proto.sp.bff_service.AuthRequest,
  proto.sp.bff_service.AuthResponse,
  /**
   * @param {!proto.sp.bff_service.AuthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.bff_service.AuthResponse.deserializeBinary
);


/**
 * @param {!proto.sp.bff_service.AuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.bff_service.AuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.bff_service.AuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.bff_service.BffServiceClient.prototype.authenticate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.bff_service.BffService/Authenticate',
      request,
      metadata || {},
      methodDescriptor_BffService_Authenticate,
      callback);
};


/**
 * @param {!proto.sp.bff_service.AuthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.bff_service.AuthResponse>}
 *     Promise that resolves to the response
 */
proto.sp.bff_service.BffServicePromiseClient.prototype.authenticate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.bff_service.BffService/Authenticate',
      request,
      metadata || {},
      methodDescriptor_BffService_Authenticate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.bff_service.GetBrowserExtensionsRequest,
 *   !proto.sp.bff_service.GetBrowserExtensionsResponse>}
 */
const methodDescriptor_BffService_GetBrowserExtensions = new grpc.web.MethodDescriptor(
  '/sp.bff_service.BffService/GetBrowserExtensions',
  grpc.web.MethodType.UNARY,
  proto.sp.bff_service.GetBrowserExtensionsRequest,
  proto.sp.bff_service.GetBrowserExtensionsResponse,
  /**
   * @param {!proto.sp.bff_service.GetBrowserExtensionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.bff_service.GetBrowserExtensionsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.bff_service.GetBrowserExtensionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.bff_service.GetBrowserExtensionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.bff_service.GetBrowserExtensionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.bff_service.BffServiceClient.prototype.getBrowserExtensions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.bff_service.BffService/GetBrowserExtensions',
      request,
      metadata || {},
      methodDescriptor_BffService_GetBrowserExtensions,
      callback);
};


/**
 * @param {!proto.sp.bff_service.GetBrowserExtensionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.bff_service.GetBrowserExtensionsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.bff_service.BffServicePromiseClient.prototype.getBrowserExtensions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.bff_service.BffService/GetBrowserExtensions',
      request,
      metadata || {},
      methodDescriptor_BffService_GetBrowserExtensions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.bff_service.GetLatestBrowserExtensionRequest,
 *   !proto.sp.bff_service.GetLatestBrowserExtensionResponse>}
 */
const methodDescriptor_BffService_GetLatestBrowserExtension = new grpc.web.MethodDescriptor(
  '/sp.bff_service.BffService/GetLatestBrowserExtension',
  grpc.web.MethodType.UNARY,
  proto.sp.bff_service.GetLatestBrowserExtensionRequest,
  proto.sp.bff_service.GetLatestBrowserExtensionResponse,
  /**
   * @param {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.bff_service.GetLatestBrowserExtensionResponse.deserializeBinary
);


/**
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.bff_service.GetLatestBrowserExtensionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.bff_service.GetLatestBrowserExtensionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.bff_service.BffServiceClient.prototype.getLatestBrowserExtension =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.bff_service.BffService/GetLatestBrowserExtension',
      request,
      metadata || {},
      methodDescriptor_BffService_GetLatestBrowserExtension,
      callback);
};


/**
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.bff_service.GetLatestBrowserExtensionResponse>}
 *     Promise that resolves to the response
 */
proto.sp.bff_service.BffServicePromiseClient.prototype.getLatestBrowserExtension =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.bff_service.BffService/GetLatestBrowserExtension',
      request,
      metadata || {},
      methodDescriptor_BffService_GetLatestBrowserExtension);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.bff_service.GetBrowserExtensionByIdRequest,
 *   !proto.sp.bff_service.GetBrowserExtensionByIdResponse>}
 */
const methodDescriptor_BffService_GetBrowserExtensionById = new grpc.web.MethodDescriptor(
  '/sp.bff_service.BffService/GetBrowserExtensionById',
  grpc.web.MethodType.UNARY,
  proto.sp.bff_service.GetBrowserExtensionByIdRequest,
  proto.sp.bff_service.GetBrowserExtensionByIdResponse,
  /**
   * @param {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.bff_service.GetBrowserExtensionByIdResponse.deserializeBinary
);


/**
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.bff_service.GetBrowserExtensionByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.bff_service.GetBrowserExtensionByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.bff_service.BffServiceClient.prototype.getBrowserExtensionById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.bff_service.BffService/GetBrowserExtensionById',
      request,
      metadata || {},
      methodDescriptor_BffService_GetBrowserExtensionById,
      callback);
};


/**
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.bff_service.GetBrowserExtensionByIdResponse>}
 *     Promise that resolves to the response
 */
proto.sp.bff_service.BffServicePromiseClient.prototype.getBrowserExtensionById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.bff_service.BffService/GetBrowserExtensionById',
      request,
      metadata || {},
      methodDescriptor_BffService_GetBrowserExtensionById);
};


module.exports = proto.sp.bff_service;

