import React, {FC, ReactElement, useContext} from "react";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import {googleLogout} from '@react-oauth/google';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeIcon from '@mui/icons-material/Home';
import WebIcon from '@mui/icons-material/Web';
import RadarIcon from '@mui/icons-material/Radar';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ExtensionIcon from '@mui/icons-material/Extension';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SchoolOutlineIcon from "@mui-extra/icons/SchoolOutlineIcon";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {
    isUserAdminRole, isUserModelTrainingRole,
    isUserStandardRole,
} from '../lib/authorizationUtils';
import {SourcePointTokensContext} from "../App";
import {removeSourcePointTokensFromStorage} from "../lib/localStorageUtils";

const NavBar: FC = (): ReactElement => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (shouldRefresh: boolean) => {
        setAnchorElUser(null);
        if (shouldRefresh) {
            window.location.reload();
        }
    };
    // determine current tab to highlight
    const {pathname} = useLocation();
    const rootPath = pathname.match(/^(\/[^/]*)/g);
    const currentTab = rootPath !== null ? rootPath[0] : "/";

    const handleLogoutButton = () => {
        console.log("Logging out...")
        googleLogout();
        removeSourcePointTokensFromStorage();
        window.location.reload();
    };

    const sourcePointTokens = useContext(SourcePointTokensContext);
    const tabs = new Set<JSX.Element>();
    if (isUserStandardRole(sourcePointTokens)) {
        tabs.add(<Tab key="home" label="Home" value="/" to="/" component={Link} icon={<HomeIcon fontSize="small"/>}/>);
        tabs.add(<Tab key="properties" label="Property" value="/properties" to="/properties" component={Link} icon={<WebIcon fontSize="small"/>}/>);
        tabs.add(<Tab key="scanjobs" label="ScanJob" value="/scanJobs" to="/scanJobs" component={Link} icon={<RadarIcon fontSize="small"/>}/>);
        tabs.add(<Tab key="scanrules" label="ScanRules" value="/scanRules" to="/scanRules" component={Link} icon={<FormatListNumberedIcon fontSize="small"/>}/>);
        tabs.add(<Tab key="scanjobartifacts" label="Artifacts" value="/scanJobArtifacts" to="/scanJobArtifacts" component={Link} icon={<ExtensionIcon fontSize="small"/>}/>);
        tabs.add(<Tab key="sets" label="Sets" value="/sets" to="/sets" component={Link} icon={<WorkspacesIcon fontSize="small"/>}/>);
        tabs.add(<Tab key="todo" label="Todo" value="/todo" to="/todo" component={Link} icon={<ChecklistIcon fontSize="small"/>}/>);
    }
    if (isUserModelTrainingRole(sourcePointTokens)) {
        tabs.add(<Tab key="scanjobtraining" label="Training" value="/scanJobTraining" to="/scanJobTraining" component={Link} icon={<SchoolOutlineIcon fontSize="small"/>}/>);
    }
    // if (isUserVncRole(sourcePointTokens)) {
    //     tabs.add(<Tab key="remote" label="Remote" value="/remote" to="/remote" component={Link} icon={<RemoteDesktopIcon fontSize="small"/>}/>);
    // }
    if (isUserAdminRole(sourcePointTokens)) {
        tabs.add(<Tab key="admin" label="Admin" value="/admin" to="/admin" component={Link} icon={<AdminPanelSettingsIcon fontSize="small"/>}/>);
    }

    return (
        <AppBar position="static" color="inherit">
            <Container>
                <Toolbar disableGutters>
                    <Link to="/">
                        <Box
                            component="img"
                            sx={{
                                height: 75,
                                width: 210,
                                maxHeight: {xs: 75, md: 75},
                                maxWidth: {xs: 210, md: 210},
                            }}
                            alt="Pl Eng Console"
                            src="/logo.svg"
                        />
                    </Link>
                    {sourcePointTokens !== undefined &&
                        <Box sx={{flexGrow: 1, marginLeft: "1rem", display: {xs: "none", md: "flex"}}}>
                            <Tabs value={currentTab} textColor="secondary" indicatorColor="secondary">
                                {tabs}
                            </Tabs>
                        </Box>
                    }
                    {sourcePointTokens !== undefined &&
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="User Settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt={sourcePointTokens.name} src={sourcePointTokens.imageUrl}/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => handleCloseUserMenu(false)}
                            >
                                <MenuItem key="userProfile">
                                    <Typography textAlign="right">{sourcePointTokens.name}</Typography>
                                </MenuItem>
                                <MenuItem key="logout">
                                    <Button variant="contained" size="small" color="secondary"
                                            onClick={() => handleLogoutButton()} endIcon={<LogoutIcon/>}>Logout</Button>
                                </MenuItem>
                            </Menu>
                        </Box>
                    }

                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default NavBar;
