import React, {ReactElement, FC, useContext, useState} from "react";
import {Grid} from '@mui/material';
import CreateScanCard from "../components/cards/CreateScanCard";

import {SourcePointTokensContext} from "../App";
import {isUserAdminRole} from "../lib/authorizationUtils";
import UploadScanResultsCard from "../components/cards/UploadScanResultsCard";
import MasterContainer from "../components/containers/MasterContainer";
import ScanJobsCard from "../components/cards/ScanJobsCard";

const ScanJobs: FC<any> = (): ReactElement => {
    const sourcePointTokens = useContext(SourcePointTokensContext);
    const [forceRefresh, doForceRefresh] = useState(0);
    const refreshRequired = () => {
        // trigger the scanJob table to refresh.
        console.log("Attempting to refresh child scan job table.")
        doForceRefresh(prev => prev + 1)
    };
    return (
        <MasterContainer>
            <Grid container spacing={0} alignItems="center">
                {isUserAdminRole(sourcePointTokens) &&
                    <Grid item xs={12} sx={{ mt: 2}}>
                        <CreateScanCard createScanCompleteFunction={() => refreshRequired()}/>
                    </Grid>
                }
                <Grid item xs={12} sx={{ mt: 2}}>
                    <UploadScanResultsCard/>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2}}>
                    <ScanJobsCard maxHeight={750} showFilterUi={true} showExportUi={true} forceRefresh={forceRefresh}/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};
export default ScanJobs;