import React, {ReactElement, FC} from "react";
import {Box, Grid, Typography} from '@mui/material';
import MasterContainer from "../components/containers/MasterContainer";
import ScanJobArtifactTrainingCard from "../components/cards/ScanJobArtifactTrainingCard";
import {useParams} from "react-router-dom";
import DownloadTrainingModelButton from "../components/buttons/DownloadTrainingModelButton";

const ScanJobArtifactTraining: FC<any> = (): ReactElement => {
    const { scanJobArtifactId, propertySetId } = useParams();
    return (
        <MasterContainer fullWidth={true}>
            <Grid container spacing={1}>
                <Grid item xs={12}>&nbsp;</Grid>
                <Grid item xs={4}>
                    <Typography style={{fontWeight: 600}} variant="subtitle1" component="div">
                        Scan Job Artifact Training
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex" justifyContent="flex-end">
                        <DownloadTrainingModelButton/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <ScanJobArtifactTrainingCard initialScanJobArtifactId={scanJobArtifactId} initialPropertySetId={propertySetId}/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};

export default ScanJobArtifactTraining;