import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {
    GetPropertySnapshotsRequest, GetPropertySnapshotsResponse, Property, PropertySnapshot
} from '../generated/sp/scan_service/scan_service_pb';
import {PagingParameters, SortDirection, StringFilter, StringList} from '../generated/sp/service_common/common_pb';
import { Box, Grid, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import {useNavigate} from "react-router-dom";
import {regionSelectorItems, formatRegion} from '../lib/regionUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {enumName} from "../lib/enumUtils";
import {SELECTOR_ALL_ID} from "../lib/selectorUtils";

const generateGetPropertySnapshotsRequest = (continuationToken: string | undefined, limit: number, sortField: GetPropertySnapshotsRequest.SortField, sortDirection: SortDirection, propertyIdsFilter: string[] | undefined, resultRegionFilter: string, propertyNameFilter: string): GetPropertySnapshotsRequest => {
  // determine filtering.
  var req = new GetPropertySnapshotsRequest();
  let filters = new GetPropertySnapshotsRequest.Filters();
  if( resultRegionFilter.length > 0 && resultRegionFilter !== SELECTOR_ALL_ID) {
    filters.addRegionIdFilter(resultRegionFilter);
  }
  if( propertyNameFilter !== undefined && propertyNameFilter.length > 0) {
    filters.addNameFilter(new StringFilter().setCaseInsensitive(true).setContains(new StringList().addValues(propertyNameFilter)));
  }
  if( propertyIdsFilter !== undefined && propertyIdsFilter.length > 0) {
    filters.setPropertyIdFilterList(propertyIdsFilter);
  }
  filters.setLatestOnly(true) // we only want to display the latest snapshot in this table UI.
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetPropertySnapshotsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchPropertySnapshots = async (scanService: ScanServiceClient, request: GetPropertySnapshotsRequest): Promise<GetPropertySnapshotsResponse> => {
  return new Promise<GetPropertySnapshotsResponse>((resolve, reject) => {
    scanService.getPropertySnapshots(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface PropertySnapshotInfinityTableProps {
  maxHeight: number;
  embeddedModePropertyIdsFilter?: string[] | undefined; // if defined, the table will render without infinity mode and do exact match search
  showFilterUi: boolean;
  forceRefresh?: number;
}

const PropertySnapshotInfinityTable: FC<PropertySnapshotInfinityTableProps> = (props): ReactElement => {
    const [propertySnapshots, setPropertySnapshots] = useState<PropertySnapshot[]>([]);
    const [resultRegionFilter, setResultRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [propertyNameFilter, setPropertyNameFilter] = useState<string>("");
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetPropertySnapshotsRequest.SortField>(GetPropertySnapshotsRequest.SortField.SORT_FIELD_CREATED_TIME);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_DESC);
    const [limit] = useState<number>(50);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToPropertySnapshot = (propertyName: String, propertySnapshotId: String) => {
      navigate(`/properties/${propertyName}/propertySnapshots/${propertySnapshotId}`);
    };

    useEffect(() => {
        (async () => {
            setPropertySnapshots([]);
            setRefreshToggle(true);
        })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading property list data, embeddedModePropertyIdsFilter=[${props.embeddedModePropertyIdsFilter}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetPropertySnapshotsRequest(currentContinuationToken, limit, sortField, sortDirection, props.embeddedModePropertyIdsFilter, resultRegionFilter, propertyNameFilter);
        const propertySnapshotsResponse = await fetchPropertySnapshots(scanService, req);
        let continuationToken = propertySnapshotsResponse.getContinuationToken().length > 0 ? propertySnapshotsResponse.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        if (props.embeddedModePropertyIdsFilter !== undefined && props.embeddedModePropertyIdsFilter.length > 0) {
          if (props.embeddedModePropertyIdsFilter.length === 0) {
            // this is a safety to prevent false loading of an unfiltered list of scan jobs. embeddedModePropertyIdsFilter should have at least one value.
            setPropertySnapshots([]);
          } else if (props.embeddedModePropertyIdsFilter.length > 0) {
            // always set the entire list, not infinity mode
            setPropertySnapshots(propertySnapshotsResponse.getSnapshotsList());
          }
        } else {
          // to support scrolling, we need to append to whatever is currently loaded, infinity mode
          setPropertySnapshots(old => [...old, ...propertySnapshotsResponse.getSnapshotsList()]);
        }
        setLoading(false);
      })();
    }, [props.embeddedModePropertyIdsFilter, currentContinuationToken, limit, resultRegionFilter, sortDirection, sortField, refreshToggle]);

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
        setPropertySnapshots([]); // wipe out data such that we dont keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handleResultRegionFilterChange = (event: SelectChangeEvent) => {
        let revised = event.target.value;
        if ( revised !== resultRegionFilter) {
            startFreshSearch();
            setResultRegionFilter(revised);
        }
    };

    const handlePropertyNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPropertyNameFilter(event.target.value);
    };

    const handlePropertyNameFilterKeyChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if( event.key === 'Enter' ){
        handleRefresh();
      }
    };

    const handleSortRequest = (sortField: GetPropertySnapshotsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
        <div>
          { props.showFilterUi &&
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter Property Snapshots</Typography>
              </Grid>
              <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="region">Region</InputLabel>
                    <Select size="small" labelId="region" id="region" value={resultRegionFilter} label="Region" onChange={handleResultRegionFilterChange}>
                      {regionSelectorItems().map((region) => (
                          <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField size="small" label="Search by Property Name" type="string" value={propertyNameFilter || ''} onChange={x=> handlePropertyNameFilterChange(x)}   onKeyPress={x=> handlePropertyNameFilterKeyChange(x)} fullWidth/>
              </Grid>
              <Grid item xs={4}>
                  <LoadingButton
                    size="small"
                    color="secondary"
                    onClick={handleRefresh}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<RefreshIcon />}
                    variant="contained"
                  >
                    Refresh
                  </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {propertySnapshots.length} property snapshots</Typography>
              </Grid>
              <Grid item xs={12}>
                {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
              </Grid>
              <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
            </Grid>
          }
           <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Properties">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" onClick={() => handleSortRequest(GetPropertySnapshotsRequest.SortField.SORT_FIELD_NAME)}>
                      <TableSortLabel active={sortField === GetPropertySnapshotsRequest.SortField.SORT_FIELD_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left" onClick={() => handleSortRequest(GetPropertySnapshotsRequest.SortField.SORT_FIELD_REGION)}>
                    <TableSortLabel active={sortField === GetPropertySnapshotsRequest.SortField.SORT_FIELD_REGION} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Region</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetPropertySnapshotsRequest.SortField.SORT_FIELD_PROPERTY_TYPE)}>
                    <TableSortLabel active={sortField === GetPropertySnapshotsRequest.SortField.SORT_FIELD_PROPERTY_TYPE} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Type</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">Cache Time</StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetPropertySnapshotsRequest.SortField.SORT_FIELD_CREATED_TIME)}>
                      <TableSortLabel active={sortField === GetPropertySnapshotsRequest.SortField.SORT_FIELD_CREATED_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Created</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {propertySnapshots.map((row) => (
                    <StyledTableRow hover key={row.getId()} onClick={() => navigateToPropertySnapshot(`${row.getPropertyName()}`, `${row.getId()}`)} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                    <StyledTableCell align="left">{row.getPropertyName()}</StyledTableCell>
                    <StyledTableCell align="center">{formatRegion(row.getRegionId())}</StyledTableCell>
                    <StyledTableCell align="center">{enumName(Property.PropertyType, row.getPropertyType(), false)}</StyledTableCell>
                    <StyledTableCell align="center">{row.getCacheTime()?.toDate().toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="center">{row.getAuditData()?.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    );
};

export default PropertySnapshotInfinityTable;