import React, {ReactElement, FC, useState} from "react";
import {Button, Card, CardContent, Grid, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from '@mui/material';
import {ScanServiceInternalClient} from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {UploadCsvRequest} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import Papa from "papaparse";
import CsvLineTable from "../CsvLineTable";
import {SxProps} from "@mui/system";
import {uploadCsv} from "../../lib/scanServiceUtils";

const ID_COLUMN_PREFIX = 'id: ';
const RULE_NAME_REGEX = new RegExp('r\\d+$');
const PASS_OR_FAIL_VALUES = ['pass', 'fail'];

interface UploadScanResultsCardProps {
    sx?: SxProps<Theme>;
}

const UploadScanResultsCard: FC<UploadScanResultsCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [successText, setSuccessText] = useState<string | undefined>(undefined);
    const [csvLines, setCsvLines] = useState<UploadCsvRequest.CsvLine[] | undefined>(undefined);

    // @ts-ignore
    const validateCsvHeader = (headerNames: string[]): string | undefined => {
        // ensure property and region columns are present.
        if (!headerNames.includes("property")) {
            console.log("Missing property column!");
            return "Column 'property' is missing";
        } else if (!headerNames.includes("region")) {
            console.log("Missing region header!");
            return "Column 'region' is missing";
        }
        let idHeaderColumns = headerNames.filter(x => x.startsWith(ID_COLUMN_PREFIX));

        // validate id column header
        for (var idHeaderColumn of idHeaderColumns) {
            let ruleShortName = extractRuleShortName(idHeaderColumn);
            if (ruleShortName === undefined) {
                return `Incorrect column format: '${idHeaderColumn}'`;
            } else if (!RULE_NAME_REGEX.test(ruleShortName)) {
                return `Incorrect rule short name format: '${ruleShortName}'`;
            }
        }

        if (idHeaderColumns.length <= 0) {
            return "Missing at least one id result col";
        }
        //TODO: more validation of the id column name format.
        return undefined;
    }

    const extractRuleShortName = (headerName: string): string => {
        // parse the following type of string : e.g. `"id: r2 valid iab cmp"`
        let splitString = headerName.split(ID_COLUMN_PREFIX).pop();
        try {
            // this should return "r2"
            return splitString!.split(' ')[0].toLowerCase();
        } catch (error) {
            throw new Error(`Failed to parse rule short name from: ${headerName}`);
        }
    }

    // @ts-ignore
    const convertToCsvLine = (rowNumber: number, rowData, idHeaderNames: string[]): UploadCsvRequest.CsvLine | undefined => {
        let property = rowData.property;
        let regionId = rowData.region;
        if (property === null) {
            throw new Error(`[row ${rowNumber}] Missing property column`);
        }
        if (regionId === null) {
            throw new Error(`[row ${rowNumber}] Missing regionId column`);
        }
        let ruleResults: UploadCsvRequest.CsvLine.RuleResult[] = idHeaderNames.map(idHeaderName => {
            // grab and validate the pass or fail value
            var passOrFail = rowData[idHeaderName];
            if (passOrFail === null) {
                console.log(`row ${rowNumber}: Ignoring cell ${idHeaderName} because of missing value!`)
                return undefined;
            } else {
                passOrFail = passOrFail.toLowerCase()
                if (!PASS_OR_FAIL_VALUES.includes(passOrFail)) {
                    throw new Error(`[row ${rowNumber}] ${property}: Bad pass/fail value: '${passOrFail}' for column: '${idHeaderName}'`);
                }
            }
            return new UploadCsvRequest.CsvLine.RuleResult().setRuleShortName(extractRuleShortName(idHeaderName)).setPassed(passOrFail === 'pass');
        }).flatMap(f => f ? [f] : []); // this filters out the undefined values
        return new UploadCsvRequest.CsvLine()
            .setProperty(property)
            .setRegionId(regionId)
            .setRuleResultsList(ruleResults);
    }

    // this function is a hack to allow you to keep uploading the same file over and over.
    const onHandleFileInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }

    const clearMessages = () => {
        setErrorText(undefined);
        setSuccessText(undefined);
    }
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Received file upload request..")
        if (event.target.files !== null && event.target.files.length > 0) {
            setLoading(true);
            clearMessages();
            let fileToProcess = event.target.files[0];
            console.log(`Processing file: ${fileToProcess.name}`);
            var idHeaderNames: string[] | undefined = undefined;
            var pendingRows: UploadCsvRequest.CsvLine[] = [];
            var stepped = 0;
            Papa.parse(fileToProcess, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: function (header) {
                    return header.trim().toLowerCase();
                },
                step: function (row, parser) {
                    let rowData = row.data;
                    // check the first row for validity before proceeding
                    if (idHeaderNames === undefined) {
                        parser.pause();
                        if (row.errors !== undefined && row.errors.length > 0) {
                            console.log("Error processing csv file.");
                            setErrorText("There was an error processing the csv file.");
                            parser.abort();
                        }
                        // @ts-ignore
                        const headerNames = Object.keys(rowData);
                        idHeaderNames = headerNames.filter(val => val.startsWith(ID_COLUMN_PREFIX));
                        let validationResultStr = validateCsvHeader(headerNames);
                        if (validationResultStr === undefined) {
                            console.log("CSV header preliminary validation passed");
                            parser.resume();
                        } else {
                            setErrorText(validationResultStr);
                            parser.abort();
                        }
                    }
                    // now convert each row into csvLines
                    try {
                        let csvLine: UploadCsvRequest.CsvLine | undefined = convertToCsvLine(++stepped, rowData, idHeaderNames);
                        if (csvLine !== undefined) {
                            pendingRows.push(csvLine);
                        }
                    } catch (error) {
                        let message = error instanceof Error ? error.message : String(error);
                        console.log("Error", message);
                        setErrorText(message);
                        pendingRows = [];
                        parser.abort();
                    }
                },
                complete: function (results) {
                    if (errorText === undefined && pendingRows.length > 0) {
                        console.log(`Processed ${pendingRows.length} rows`);
                        setCsvLines(pendingRows);
                    }
                },
            });
            setLoading(false);
        }
    }

    // create scan job dialog
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickSave = () => {
        setOpen(true);
    };

    const handleClose = (shouldUploadCsv: boolean) => () => {
        if (shouldUploadCsv) {
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            let filteredCsvLines = csvLines!.filter(csvLine => csvLine.getRuleResultsList().length > 0);
            Promise.resolve(uploadCsv(scanServiceInternal, filteredCsvLines!)).then(function (scanJobsIdsAdded) {
                console.log(`Created ${scanJobsIdsAdded.length} new scan jobs!`);
                setSuccessText(`Successfully uploaded ${filteredCsvLines?.length} valid line items (originally ${csvLines!.length} line items) resulting in ${scanJobsIdsAdded.length} new scan job(s)`);
                setCsvLines(undefined);
                setLoading(false);
            }, function (rejected) {
                setErrorText(rejected.toString());
                setCsvLines(undefined);
                setLoading(false);
            });
            //TODO: refresh the page since status may have updated.
        }
        setOpen(false);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Dialog fullScreen={fullScreen} maxWidth={"xl"} open={open} onClose={handleClose(false)}
                        aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{"Save Scan Results"}</DialogTitle>
                    <DialogContent>
                        <CsvLineTable csvLines={csvLines} maxHeight={200}/>
                        <br/>
                        <DialogContentText> Are you sure you want to save these scan results?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleClose(false)}>Cancel</Button>
                        <Button variant="contained" onClick={handleClose(true)} autoFocus
                                color="secondary">Save</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 600}}
                                    variant="subtitle1" component="div">Upload Scan Results</Typography>
                    </Grid>
                    {errorText !== undefined &&
                        <Grid item xs={12}>
                            <Typography sx={{color: "red"}} component="div">{errorText}</Typography>
                        </Grid>
                    }
                    {successText !== undefined &&
                        <Grid item xs={12}>
                            <Typography sx={{color: "blue"}} component="div">{successText}</Typography>
                        </Grid>
                    }
                    {csvLines !== undefined &&
                        <Grid item xs={12}>
                            <Typography sx={{color: "blue"}} component="div">Found {csvLines.length} line items (pending
                                save)</Typography>
                        </Grid>
                    }
                    <Grid item xs={2}>
                        <label htmlFor="upload-csv">
                            <input
                                style={{display: 'none'}}
                                id="upload-csv"
                                name="upload-csv"
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}
                                onClick={onHandleFileInputClick}
                            />
                            <LoadingButton
                                size="small"
                                color={errorText !== undefined ? 'error' : 'secondary'}
                                loading={loading}
                                component="span"
                                startIcon={<UploadFileIcon/>}
                                variant="contained">
                                Upload CSV
                            </LoadingButton>
                        </label>
                    </Grid>
                    <Grid item xs={2}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleClickSave}
                            loading={loading}
                            disabled={(errorText !== undefined) || (csvLines === undefined || csvLines.length <= 0)}
                            loadingPosition="start"
                            startIcon={<SaveIcon/>}
                            variant="contained"
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default UploadScanResultsCard;