import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";

const getCurrentTimestampMinusDays = (days: number) => {
  let todayDate = new Date();
  var modifiedDate = new Date();
  modifiedDate.setTime(todayDate.getTime()-(days*24*3600000));
  let epoch = Math.round(modifiedDate.getTime() / 1000);
  return new Timestamp().setSeconds(epoch);
}

export { getCurrentTimestampMinusDays };
