import {GVL} from '@iabtcf/core';
import {getIabCmpListFromStorage, getIabVendorListFromStorage, setIabCmpListIntoStorage, setIabVendorListIntoStorage} from "./localStorageUtils";
import {SELECTOR_ALL_ID_NAME, SelectorIdName} from "./selectorUtils";
export interface IabIdName {
    id: number,
    name: string;
}

const iabSpecialFeatureOptinList: IabIdName[] = [
    { id: 1, name: 'Use precise geolocation data' },
    { id: 2, name: 'Actively scan device characteristics for identification' },
];

const iabPurposeList: IabIdName[] = [
    { id: 1, name: 'Store and/or access information on a device' },
    { id: 2, name: 'Select basic ads' },
    { id: 3, name: 'Create a personalized ads profile' },
    { id: 4, name: 'Select personalized ads' },
    { id: 5, name: 'Create personalized content profile' },
    { id: 6, name: 'Select personalized content' },
    { id: 7, name: 'Measure ad performance' },
    { id: 8, name: 'Measure content performance' },
    { id: 9, name: 'Apply market research to generate audience insights' },
    { id: 10, name: 'Develop and improve products' },
];

const generateIabVendorList = () : Promise<IabIdName[]> => {
    console.log("Loading vendor list for v 3.3...")
    GVL.baseUrl = "/"; // this forces GVL to fetch from the /public folder
    GVL.versionedFilename = 'vendor-list-v3.3.json';
    const gvl = new GVL(3.3);

    return gvl.readyPromise.then(() => {
        const vendors = gvl.vendors;
        // GVL has loaded and it's ready to use
        return Object.values(vendors).map(vendor => {
            let iabVendor: IabIdName = {
                id: vendor.id,
                name: vendor.name
            };
            return iabVendor;
        });
    });
}

const fetchIabVendorList = async () : Promise<IabIdName[]> => {
    let fromLocalStorage = getIabVendorListFromStorage();
    if (fromLocalStorage !== undefined) {
        return fromLocalStorage;
    } else {
        let vendors = await generateIabVendorList();
        setIabVendorListIntoStorage(vendors); // store into localstorage for retrieval later.
        return vendors;
    }
}

const generateIabCmpList = async () : Promise<IabIdName[]> => {
    // parse the json by hand since there is no library for it
    const request = await fetch('cmp-list-v2023-06-01.json');
    const data = await request.text();
    const jsonCmps = JSON.parse(data).cmps;
    const cmps = Object.keys(jsonCmps).map((key) => {
        let item = jsonCmps[key];
        return {id: item.id, name: item.name };
    });
    return cmps;
}
const iabCmpSelectorList = async (): Promise<SelectorIdName[]> => {
    let iabCmps = await fetchIabCmpList();
    let iabCmpsRevised = iabCmps.map((iabIdName) => {
        return {id: iabIdName.id.toString(), name: iabIdName.name };
    }).sort(function (a, b) {
        return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    });
    iabCmpsRevised.unshift(SELECTOR_ALL_ID_NAME);
    return iabCmpsRevised;
}

const fetchIabCmpList = async () : Promise<IabIdName[]> => {
    let fromLocalStorage = getIabCmpListFromStorage();
    if (fromLocalStorage !== undefined) {
        return fromLocalStorage;
    } else {
        let cmps = await generateIabCmpList();
        setIabCmpListIntoStorage(cmps); // store into localstorage for retrieval later.
        return cmps;
    }
}

const fetchIabCmpMap = async () : Promise<Map<number,string>> => {
    let iabCmpList = await fetchIabCmpList();
    return new Map(iabCmpList.map(cmp => [cmp.id, cmp.name]));
}
export { generateIabVendorList, fetchIabVendorList, generateIabCmpList, fetchIabCmpList, fetchIabCmpMap, iabCmpSelectorList, iabSpecialFeatureOptinList, iabPurposeList };