import {PropertySet, ScanJob, ScanRule, ScanRuleSet} from '../generated/sp/scan_service/scan_service_pb';
import { ScanRuleReport, ScanTimeline } from '../generated/sp/scan_runner/scan_runner_pb';



const getProductionStatusColor = (status: ScanRule.ProductionStatus | undefined) => {
    switch(status) {
        case ScanRule.ProductionStatus.PRODUCTION_STATUS_ALPHA:
            return "warning.main";
        case ScanRule.ProductionStatus.PRODUCTION_STATUS_BETA:
            return "secondary.main";
        case ScanRule.ProductionStatus.PRODUCTION_STATUS_GA:
            return "success.main";
        default:
            return "info.main";
    }
}

const getPropertySetStatusColor = (status: PropertySet.PropertySetStatus | undefined) => {
    switch(status) {
        case PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DELETED:
            return "error.main";
        case PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DISABLED:
            return "warning.main";
        case PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DELETED_PENDING:
        case PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DISABLED_PENDING:
            return "secondary.main";
        case PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_ACTIVE:
            return "success.main";
        default:
            return "info.main";
    }
}

const getScanRuleSetStatusColor = (status: ScanRuleSet.ScanRuleSetStatus | undefined) => {
    switch(status) {
        case ScanRuleSet.ScanRuleSetStatus.SCAN_RULE_SET_STATUS_DELETED:
            return "error.main";
        case ScanRuleSet.ScanRuleSetStatus.SCAN_RULE_SET_STATUS_DISABLED:
            return "warning.main";
        case ScanRuleSet.ScanRuleSetStatus.SCAN_RULE_SET_STATUS_ACTIVE:
            return "success.main";
        default:
            return "info.main";
    }
}

const getScanJobStatusColor = (status: ScanJob.ScanJobStatus | undefined) => {
    switch(status) {
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_FAILED:
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_KILLED:
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_ERROR:
            return "error.main";
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_POD_MISSING:
            return "warning.main";
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_QUEUED:
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_RUNNING:
            return "secondary.main";
        case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_COMPLETE:
            return "success.main";
        default:
            return "info.main";
    }
}

const getScanRuleStatusColor = (status: ScanRuleReport.ScanRuleStatus) => {
    switch(status) {
        case ScanRuleReport.ScanRuleStatus.SCAN_RULE_STATUS_FAIL:
            return "error.main";
        case ScanRuleReport.ScanRuleStatus.SCAN_RULE_STATUS_PASS:
            return "success.main";
        default:
            return "info.main";
    }
}

const getScanTimeLineSeverityColor = (status: ScanTimeline.LogEvent.Severity) => {
    switch(status) {
        case ScanTimeline.LogEvent.Severity.SEVERITY_ERROR:
            return "error.main";
        case ScanTimeline.LogEvent.Severity.SEVERITY_WARN:
            return "warning.main";
        case ScanTimeline.LogEvent.Severity.SEVERITY_DEBUG:
            return "text.secondary";
        default:
            return "info.main";
    }
}

export { getProductionStatusColor, getPropertySetStatusColor, getScanRuleSetStatusColor, getScanJobStatusColor, getScanRuleStatusColor, getScanTimeLineSeverityColor };
