import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid, LinearProgress, Paper, TextField,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {fetchScanArtifact} from "../../lib/scanServiceUtils";
import {ScanArtifact} from "../../generated/sp/scan_runner/scan_runner_pb";

const extractTextMetadata = (artifactMetadata: ScanArtifact.ArtifactMetadata | undefined): ScanArtifact.TextMetadata | undefined => {
    if (artifactMetadata !== undefined) {
        switch(artifactMetadata.getMetadataTypeCase()) {
            case ScanArtifact.ArtifactMetadata.MetadataTypeCase.TEXT:
                return artifactMetadata.getText();
            default:
                return undefined;
        }
    }
};

interface ScanTimelineText {
    name: string;
    textContents: string;
    metadata: ScanArtifact.TextMetadata | undefined,
}
interface ScanTimelineTextArtifactCardProps {
    scanJobId: string | undefined;
    artifactId: string | undefined;
    sx?: SxProps<Theme>;
}

const ScanTimelineTextArtifactCard: FC<ScanTimelineTextArtifactCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanTimelineText, setScanTimelineText] = useState<ScanTimelineText  | undefined>(undefined);


    const reloadScanJobArtifact = useCallback(async () => {
        console.log(`Loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanArtifact = await fetchScanArtifact(scanService, props.scanJobId!, props.artifactId!);
        const contents : Uint8Array = scanArtifact!.getContent_asU8();
        const textContents = new TextDecoder().decode(contents);
        const metadata = extractTextMetadata(scanArtifact.getArtifactMetadata());
        const data: ScanTimelineText = {
            name: scanArtifact.getName(),
            textContents,
            metadata
        };
        setScanTimelineText(data);
        console.log(`Finished loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(false);
    }, [props.scanJobId, props.artifactId]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined && props.artifactId !== undefined) {
                await reloadScanJobArtifact();
            }
        })();
    }, [props.scanJobId, props.artifactId, reloadScanJobArtifact]);

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1}}  style={{ fontWeight: 600 }} variant="subtitle1" component="div">{scanTimelineText?.name} for ScanJobId: [{props.scanJobId}]</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={4}>
                        <TextField size="small" label="NLP Text" type="string" value={scanTimelineText?.metadata?.getNlpText() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField size="small" label="NLP Score" type="number" value={scanTimelineText?.metadata?.getNlpScore() || ''} inputProps={{ readOnly: true}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{maxHeight: 200, overflow: 'auto'}}>
                            {scanTimelineText?.textContents}
                        </Paper>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanTimelineTextArtifactCard;