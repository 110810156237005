import React, {ReactElement, FC, useState} from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, TextField, Typography, useMediaQuery } from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import EventIcon from '@mui/icons-material/Event';
import SimpleTable from "../SimpleTable";
import {HttpTransaction} from "../../generated/sp/scan_runner/scan_runner_pb";

interface DiagnoseHttpTransactionViewButtonProps {
  httpTransaction: HttpTransaction | undefined;
  dictionary: Array<string> | undefined;
  sx?: SxProps<Theme>;
}

const DiagnoseHttpTransactionViewButton: FC<DiagnoseHttpTransactionViewButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setDialogOpen(true);
      setLoading(true);
      setLoading(false);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const lookupString = (position: number | undefined): string| undefined => {
      if(position === undefined || props.dictionary === undefined || typeof props.dictionary[position] === 'undefined') {
        return undefined;
      }
      else {
        return props.dictionary[position];
      }
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"xl"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Diagnose Event for request: {lookupString(props.httpTransaction?.getRequestData()?.getReqId())}</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
                    { props.httpTransaction !== undefined && props.httpTransaction.hasRequestData() &&
                      <React.Fragment>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Request</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField size="small" label="ID" type="string" value={lookupString(props.httpTransaction?.getRequestData()?.getReqId()) || 'unknown'} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Payload Size" type="number" value={props.httpTransaction?.getRequestData()?.getPayloadSize() || 0} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="HTTP Method" type="string" value={lookupString(props.httpTransaction?.getRequestData()?.getMethod()) || ' '} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField size="small" label="Initiator" type="string" value={lookupString(props.httpTransaction?.getRequestData()?.getInitiator()) || ' '} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField size="small" label="URL" type="string" value={lookupString(props.httpTransaction?.getRequestData()?.getUrl()) || 'unknown'} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Start Time (ms)" type="number" value={props.httpTransaction?.getResponseData()?.getStartTimeMs() || -1} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Duration (ms)" type="number" value={props.httpTransaction?.getResponseData()?.getDurationMs() || -1} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Request Headers</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleTable rows={props.httpTransaction?.getRequestData()?.getHeadersList().map((header) => [lookupString(header.getKey()) || 'unknown', lookupString(header.getValue()) || 'unknown']) || []} headerNames={["Key", "Value"]} maxHeight={200}/>
                        </Grid>
                      </React.Fragment>
                    }
                    { props.httpTransaction !== undefined && props.httpTransaction.hasResponseData() &&
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Response</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField size="small" label="ID" type="string" value={lookupString(props.httpTransaction?.getResponseData()?.getReqId()) || 'unknown'} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Payload Size" type="number" value={props.httpTransaction?.getResponseData()?.getPayloadSize() || 0} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Status Code" type="string" value={props.httpTransaction?.getResponseData()?.getStatusCode()} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Protocol" type="string" value={lookupString(props.httpTransaction?.getResponseData()?.getProtocol()) || 'unknown'} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField size="small" label="URL" type="string" value={lookupString(props.httpTransaction?.getResponseData()?.getUrl()) || 'unknown'} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Start Time (ms)" type="number" value={props.httpTransaction?.getResponseData()?.getStartTimeMs() || -1} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField size="small" label="Duration (ms)" type="number" value={props.httpTransaction?.getResponseData()?.getDurationMs() || -1} inputProps={{ readOnly: true}} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Response Headers</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleTable rows={props.httpTransaction?.getResponseData()?.getHeadersList().map((header) => [lookupString(header.getKey()) || 'unknown', lookupString(header.getValue()) || 'unknown']) || []} headerNames={["Key", "Value"]} maxHeight={200}/>
                        </Grid>
                      </React.Fragment>
                    }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="View Diagnose Event Data">
                <IconButton
                    sx={props.sx}
                    color="secondary"
                    disabled={loading}
                    onClick={handleDialogClickOpen}
                    aria-label="View Diagnose Event Data"
                    component="span">
                    <EventIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default DiagnoseHttpTransactionViewButton;