import React, {ReactElement, FC, useState, useCallback, useEffect} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid, IconButton, LinearProgress, Tooltip,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import TimelineOppositeContent, {timelineOppositeContentClasses} from "@mui/lab/TimelineOppositeContent";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {GetTimelineRequest} from "../../generated/sp/scan_service/scan_service_pb";
import {ScanArtifact, ScanTimeline} from "../../generated/sp/scan_runner/scan_runner_pb";
import {v4 as uuidv4} from "uuid";
import {PagingParameters} from "../../generated/sp/service_common/common_pb";
import {generateAuthHeader} from "../../lib/authorizationUtils";
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import ImageIcon from "@mui/icons-material/Image";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CookieIcon from "@mui/icons-material/Cookie";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import PolicyIcon from "@mui/icons-material/Policy";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import StethoscopeIcon from "@mui-extra/icons/StethoscopeIcon";
import ScanTimelineImageArtifactCard from "./ScanTimelineImageArtifactCard";
import ScanTimelineTextArtifactCard from "./ScanTimelineTextArtifactCard";
import ScanTimelineLocalStorageDumpArtifactCard from "./ScanTimelineLocalStorageDumpArtifactCard";
import ScanTimelinePageSearchArtifactCard from "./ScanTimelinePageSearchArtifactCard";
import ScanTimelineConsentApiDataArtifactCard from "./ScanTimelineConsentApiDataArtifactCard";
import ScanTimelineDiagnoseEventsArtifactCard from "./ScanTimelineDiagnoseEventsArtifactCard";

interface ScanJobTimelineCardProps {
    scanJobId: string | undefined;
    sx?: SxProps<Theme>;
}

interface ScanTimelineEvent {
    id: string;
    ts: google_protobuf_timestamp_pb.Timestamp | undefined;
    scanPhase: ScanTimeline.ScanPhase | undefined;
    logEvent: ScanTimeline.LogEvent | undefined;
    artifactEvent: ScanTimeline.ArtifactEvent | undefined;
    networkEvent: ScanTimeline.NetworkEvent | undefined;
}

const ScanJobTimelineCard: FC<ScanJobTimelineCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [scanTimelineEvents, setScanTimelineEvents] = useState<ScanTimelineEvent[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<ScanTimelineEvent|undefined>(undefined);

    // 👇️ get memoized callback
    const reloadScanJobTimeline = useCallback(async () => {
        console.log(`Loading timeline data for scanJobId=[${props.scanJobId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const timeline = await getTimeline(scanService, props.scanJobId!, GetTimelineRequest.ScanEventType.SCAN_EVENT_TYPE_ARTIFACT, ScanTimeline.LogEvent.Severity.SEVERITY_UNSET);
        console.log(`Finished reloading timeline for scanJobId=${props.scanJobId!}`);
        const phaseLookup = timeline.getPhaseMapMap();
        const scanTimelineEvents = timeline.getEventsList()
          .map(event => {
              let maybeLogEvent = event.getLog() !== undefined ? event.getLog() : undefined;
              let maybeArtifactEvent = event.getArtifact() !== undefined ? event.getArtifact() : undefined;
              let maybeNetworkEvent = event.getNetwork() !== undefined ? event.getNetwork() : undefined;
              let scanPhase = phaseLookup.get(event.getPhase());
              const data: ScanTimelineEvent = {
                  id: uuidv4(),
                  ts: event.getTs(),
                  scanPhase,
                  logEvent: maybeLogEvent,
                  artifactEvent: maybeArtifactEvent,
                  networkEvent: maybeNetworkEvent,
              };
              return data;
          })
        setScanTimelineEvents(scanTimelineEvents);
        if (scanTimelineEvents.length > 0) {
            // auto select the first item in the list.
            setSelectedEvent(scanTimelineEvents[0]);
        }
        setLoading(false);
    }, [props.scanJobId]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined) {
                await reloadScanJobTimeline();
            }
        })();
    }, [props.scanJobId, reloadScanJobTimeline]);

    const getTimeline = async (scanService: ScanServiceClient, scanJobId: string, eventTypeFilter: GetTimelineRequest.ScanEventType, logEventSeverityFilter: ScanTimeline.LogEvent.Severity): Promise<ScanTimeline> => {
        var req = new GetTimelineRequest();
        req.setScanJobId(scanJobId);
        let filters = new GetTimelineRequest.Filters();
        if( eventTypeFilter > 0) {
            filters.addEventTypes(eventTypeFilter);
        }
        if( logEventSeverityFilter > 0) {
            filters.setMinSeverity(logEventSeverityFilter);
        }
        req.setFilters(filters);

        // determine paging
        var pagingParams = new PagingParameters();
        //TODO: set this limit somewhere
        pagingParams.setLimit(100);
        req.setPagingParameters(pagingParams);

        return new Promise<ScanTimeline>((resolve, reject) => {
            scanService.getTimeline(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getTimeline()!)
            });
        });
    }

    const handleTimelineClick = (scanTimeLineEvent: ScanTimelineEvent | undefined) => {
        setSelectedEvent(scanTimeLineEvent)
    };

    const determineArtifactIcon = (artifactEvent: ScanTimeline.ArtifactEvent): ReactElement | undefined => {
        if( artifactEvent !== undefined) {
            switch (artifactEvent.getArtifactType()) {
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_IMAGE:
                    return <ImageIcon/>
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_TEXT:
                    return <TextSnippetIcon/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_LOCAL_STORE_DUMP:
                    return <CookieIcon/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_PAGE_SEARCH_RESULT:
                    return <FindInPageIcon/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_CONSENT_API_DATA:
                    return <PolicyIcon/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_DOM_SNAPSHOT:
                    return <TextSnippetIcon/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_DIAGNOSE_EVENTS:
                    return <StethoscopeIcon/>;
                default:
                    return undefined;
            }
        }
    }
    const determineArtifactButton = (event: ScanTimelineEvent | undefined, maybeToolTip: string | undefined): ReactElement | undefined => {
        let toolTip = maybeToolTip === undefined ? "" : maybeToolTip;
        let artifactEvent = event?.artifactEvent;
        if( artifactEvent !== undefined) {
            let icon = determineArtifactIcon(artifactEvent);
            return <Tooltip title={toolTip}>
                <IconButton
                  color="secondary"
                  disabled={loading}
                  onClick={() => handleTimelineClick(event)}
                  aria-label={toolTip}
                  component="span">
                    {icon}
                </IconButton>
            </Tooltip>;
        }
        return undefined;
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
                    <Grid item xs={4} sx={{ width: '100vw', height: '100vh', overflow: 'auto' }}>
                        <Timeline
                          sx={{
                              [`& .${timelineOppositeContentClasses.root}`]: {
                                  flex: 0.3,
                              },
                              "mt" : -4,
                              "ml" : -5,
                          }}
                        >
                            <TimelineItem>
                                <TimelineOppositeContent sx={{ m: 'auto 0' }}/>
                                <TimelineSeparator>
                                    <TimelineDot color="info" variant="filled" sx={{ mt: 4 }}/>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ mt: 1.5 }} align="right">
                                    <IconButton color="info" aria-label="end" component="span">
                                        <DirectionsRunIcon/>
                                    </IconButton> <Typography variant="body2" component="span">start</Typography>
                                </TimelineContent>
                            </TimelineItem>
                            { scanTimelineEvents.map((event) => (
                              <TimelineItem key={event.id}>
                                  <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                      {event.ts?.toDate().toLocaleTimeString()}
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                      <TimelineConnector />
                                      { event.id === selectedEvent?.id &&
                                        <TimelineDot color="primary" variant="outlined"/>
                                      }
                                      { event.id !== selectedEvent?.id &&
                                        <TimelineDot color="secondary" variant="outlined"/>
                                      }
                                      <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent sx={{ m: 'auto 0' }} align="right">
                                      {determineArtifactButton(event, event.artifactEvent?.getName()) } <Typography variant="body2" component="span">{event.artifactEvent?.getName()}</Typography>
                                  </TimelineContent>
                              </TimelineItem>
                            ))}
                            <TimelineItem>
                                <TimelineOppositeContent sx={{ m: 'auto 0' }}/>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color="info" variant="filled" sx={{ mb: 4 }}/>
                                </TimelineSeparator>
                                <TimelineContent sx={{ "mt": 1 }} align="right">
                                    <IconButton color="info" aria-label="end" component="span">
                                        <SportsScoreIcon/>
                                    </IconButton> <Typography variant="body2" component="span">end</Typography>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </Grid>
                    <Grid item xs={8}>
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_IMAGE &&
                          <ScanTimelineImageArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_TEXT &&
                          <ScanTimelineTextArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_LOCAL_STORE_DUMP &&
                          <ScanTimelineLocalStorageDumpArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_PAGE_SEARCH_RESULT &&
                          <ScanTimelinePageSearchArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_CONSENT_API_DATA &&
                          <ScanTimelineConsentApiDataArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_DOM_SNAPSHOT &&
                          <ScanTimelineTextArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent !== undefined && selectedEvent.artifactEvent?.getArtifactType() === ScanArtifact.ArtifactType.ARTIFACT_TYPE_DIAGNOSE_EVENTS &&
                          <ScanTimelineDiagnoseEventsArtifactCard scanJobId={props.scanJobId} artifactId={selectedEvent.artifactEvent!.getArtifactId()} sx={{ mt: 1 }}/>
                        }
                        {selectedEvent === undefined &&
                          <Typography variant="body1" component="span">Please select a timeline node to view contents</Typography>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanJobTimelineCard;