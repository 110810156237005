import React, {ReactElement, FC, useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { ScanServiceInternalClient } from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {DumpTrainingDataRequest, DumpTrainingDataResponse} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme} from '@mui/material/styles';
import { generateAuthHeader } from '../../lib/authorizationUtils';
import DownloadIcon from '@mui/icons-material/Download';
import {SxProps} from "@mui/system";
import {downloadByteArray} from "../../lib/downloadUtils";

interface DownloadTrainingModelButtonProps {
  sx?: SxProps<Theme>;
  scanJobArtifactId?: string | undefined,
}


const fetchDataDump = async (scanServiceInternal: ScanServiceInternalClient, scanJobArtifactId: string | undefined): Promise<DumpTrainingDataResponse> => {
  const request = new DumpTrainingDataRequest();
  if (scanJobArtifactId !== undefined) {
    request.setScanJobArtifactId(scanJobArtifactId);
  }
  return new Promise<DumpTrainingDataResponse>((resolve, reject) => {
    scanServiceInternal.dumpTrainingData(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

const DownloadTrainingModelButton: FC<DownloadTrainingModelButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
      console.log("Handling download...");
      setLoading(true);
      const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
      const response = await fetchDataDump(scanServiceInternal, props.scanJobArtifactId);
      let content: Uint8Array = response.getContent() as Uint8Array;
      console.log(`Fetch latest training data dump: ${response.getName()} with bytes: ${content.length}`);
      downloadByteArray(content, response.getName(), "application.zip")
      setLoading(false);
    };

    return (
        <div>
            <LoadingButton
              sx={props.sx}
              size="small"
              color="secondary"
              onClick={handleDownload}
              loading={loading}
              disabled={loading}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              variant="contained">
              { props.scanJobArtifactId !== undefined ? "Download" : "Download All Training Data" }
            </LoadingButton>
        </div>
    );
};

export default DownloadTrainingModelButton;