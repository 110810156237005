import React, {ReactElement, FC, useState, useEffect} from "react";
import { Box, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import { ScanRule, GetScanRuleByIdRequest} from '../generated/sp/scan_service/scan_service_pb';
import { useParams } from 'react-router-dom';
import { formatRegion } from '../lib/regionUtils';
import { enumName } from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import SimpleTable from "../components/SimpleTable";
import MasterContainer from "../components/containers/MasterContainer";

const ScanRuleDetail: FC<any> = (): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [scanRule, setScanRule] = useState<ScanRule>();
    const { scanRuleId } = useParams();

    useEffect(() => {
      (async () => {
        console.log(`Loading data for scan rule id=[${scanRuleId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanRule = await getScanRule(scanService, scanRuleId!);
        setScanRule(scanRule);
        setLoading(false);
      })();
    }, [scanRuleId]);

    const getScanRule = async (scanService: ScanServiceClient, scanRuleId: string): Promise<ScanRule> => {
      var req = new GetScanRuleByIdRequest();
      req.addFields(ScanRule.ScanRuleField.SCAN_RULE_FIELD_REGIONS);
      req.setId(scanRuleId);
      return new Promise<ScanRule>((resolve, reject) => {
        scanService.getScanRuleById(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response.getScanRule()!)
        });
      });
    };

    return (
        <MasterContainer>
           <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Scan Rule</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField size="small" label="ID" type="string" value={scanRule?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Short Name" type="string" value={scanRule?.getShortName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <TextField size="small" label="Name" type="string" value={scanRule?.getName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline maxRows={6} size="small" label="Description" type="string" value={scanRule?.getDescription() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={4}>
                  <TextField size="small" label="Compliance Standard(s)" type="string" value={scanRule?.getComplianceStandardsList().join(", ") || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Category" type="string" value={enumName(ScanRule.Category, scanRule?.getCategory(), false)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Production Status" type="string" value={enumName(ScanRule.ProductionStatus, scanRule?.getProductionStatus(), true)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Audit</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Created" type="string" value={scanRule?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Updated" type="string" value={scanRule?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Regions</Typography>
                </Grid>
                <Grid item xs={12}>
                  <SimpleTable rows={scanRule?.getRegionsList().map(x => [formatRegion(x)])} headerNames={["Region"]} maxHeight={300}/>
                </Grid>
                <Grid item xs={12}>
                  {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
                <Grid item xs={12}><br/></Grid>
            </Grid>
            </Box>
        </MasterContainer>
    );
};

export default ScanRuleDetail;