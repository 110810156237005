import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Grid,
    LinearProgress,
    TextField,
    Typography
} from '@mui/material';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {
    GetPropertyByIdOrNameRequest, Property,
} from '../generated/sp/scan_service/scan_service_pb';
import { useParams } from 'react-router-dom';
import { enumName } from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import MasterContainer from "../components/containers/MasterContainer";
import PropertySetInfinityTable from "../components/PropertySetInfinityTable";
import PropertySnapshotInfinityTable from "../components/PropertySnapshotInfinityTable";
import {parse as uuidParse} from 'uuid';
import ScanJobInfinityTable from "../components/ScanJobInfinityTable";
import RegionResultsTable from "../components/RegionResultsTable";

const PropertyDetail: FC<any> = (): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [property, setProperty] = useState<Property>();
    const { propertyId } = useParams();

    // 👇️ get memoized callback
    const reloadPropertySnapshot = useCallback(async () => {
        console.log(`Loading data for propertyId=[${propertyId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const property = await getProperty(scanService, propertyId!);
        console.log(`Finished reloading propertyId=${propertyId}`);
        setProperty(property);
        setLoading(false);
    }, [propertyId]);

    useEffect(() => {
        (() => {
            reloadPropertySnapshot();
        })();
    }, [reloadPropertySnapshot]);

    const getProperty = async (scanService: ScanServiceClient, propertyIdOrName: string): Promise<Property> => {
        var req = new GetPropertyByIdOrNameRequest();
        try {
            uuidParse(propertyIdOrName);
            req.setId(propertyIdOrName);
        } catch (error) {
            req.setName(propertyIdOrName);
        }

        return new Promise<Property>((resolve, reject) => {
            scanService.getPropertyById(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getProperty()!)
            });
        });
    };

    return (
        <MasterContainer>
           <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Property</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField size="small" label="Snapshot ID" type="string" value={property?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" label="Name" type="string" value={property?.getName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Property Type" type="string" value={enumName(Property.PropertyType, property?.getPropertyType(), false)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Audit</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Created" type="string" value={property?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Updated" type="string" value={property?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
                { property !== undefined &&
                  <React.Fragment>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Rule Results</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RegionResultsTable regionResults={property?.getRegionalResults()?.getRegionResultsList()} maxHeight={250}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Snapshots</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PropertySnapshotInfinityTable maxHeight={400} showFilterUi={false} embeddedModePropertyIdsFilter={[property?.getId()]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Contained in Property Sets</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PropertySetInfinityTable maxHeight={400} showFilterUi={false} showExportUi={true} embeddedModePropertyIdsFilter={[property?.getId()]}/>
                    </Grid>
                    <Grid item xs={12}>
                          <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Related Scan Jobs</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ScanJobInfinityTable maxHeight={400} embeddedMode={true} showFilterUi={false} showExportUi={true} propertyNameFilter={property?.getName()}/>
                    </Grid>
                  </React.Fragment>
                }
            </Grid>
            </Box>
        </MasterContainer>
    );
};

export default PropertyDetail;