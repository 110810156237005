import React, {ReactElement, FC, useState} from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, TextField, Typography, useMediaQuery } from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import ViewListIcon from '@mui/icons-material/ViewList';
import SimpleTable from "../SimpleTable";
import {ScanJob} from "../../generated/sp/scan_service/scan_service_pb";
import {formatRegion} from "../../lib/regionUtils";
import LinkIcon from "@mui/icons-material/Link";

interface TrainingMetadataButtonProps {
  scanJob: ScanJob | undefined,
  scanJobArtifactId: string | undefined;
  stats: string[] | undefined;
  sx?: SxProps<Theme>;
}

const TrainingMetadataButton: FC<TrainingMetadataButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setDialogOpen(true);
      setLoading(true);
      setLoading(false);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const navigateToProperty = (propertyName: String) => {
      window.open(`/properties/${propertyName}`,'_blank');
    };

    const navigateToScanJob = (scanJobId: String) => {
      window.open(`/scanJobs/${scanJobId}`,'_blank');
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={false} maxWidth={"sm"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Training Metadata</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                    { props.scanJob !== undefined &&
                      <React.Fragment>
                          <Grid item xs={12}>
                              <TextField size="small" label="Scan Job Artifact ID" type="string" value={props.scanJobArtifactId || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField size="small" label="Scan Job ID" type="string" value={props.scanJob?.getId() || ''}
                                         InputProps={{readOnly: true, endAdornment: <IconButton onClick={() => navigateToScanJob(`${props.scanJob?.getId()}`)}><LinkIcon/></IconButton>}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField size="small" label="Property Name" type="string" value={props.scanJob?.getParameters()?.getPropertyName() || ''}
                                         InputProps={{readOnly: true, endAdornment: <IconButton onClick={() => navigateToProperty(`${props.scanJob?.getParameters()?.getPropertyName()}`)}><LinkIcon/></IconButton>}} fullWidth/>
                          </Grid>
                          <Grid item xs={4}>
                              <TextField size="small" label="Region" type="string" value={formatRegion(props.scanJob?.getParameters()?.getRegion()) || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                      </React.Fragment>
                    }
                  {props.stats !== undefined &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <SimpleTable rows={props.stats?.map((x) => [x]) || []} headerNames={["Skynet Statistics"]} maxHeight={200}/>
                        </Grid>
                    </React.Fragment>
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="View Training Metadata">
                <IconButton
                    sx={props.sx}
                    color="secondary"
                    disabled={loading}
                    onClick={handleDialogClickOpen}
                    aria-label="View Training Metadata"
                    component="span">
                    <ViewListIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default TrainingMetadataButton;