import React, {ReactElement, FC, useState, useEffect} from "react";
import {Box, CssBaseline, ThemeProvider, Typography} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {routes as appRoutes} from "./routes";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Authorize from "./components/auth/Authorize";
import {SourcePointTokens} from './lib/authorizationUtils';
import {createContext} from 'react';
import MasterContainer from "./components/containers/MasterContainer";
import {getSourcePointTokensFromStorageWithExpiry} from "./lib/localStorageUtils";

// this is the context used across all pages for the auth information
const SourcePointTokensContext = createContext<SourcePointTokens | undefined>(undefined);

// define theme
const theme = createTheme({
    palette: {
        primary: {
            light: "#F8F8F8",
            main: "#8DF6DA",
            dark: "#005db0",
            contrastText: "#000",
        },
        secondary: {
            light: "#82e9de",
            main: "#3F248F",
            dark: "#00867d",
            contrastText: "#fff" //button text white instead of black
        },
        action: {
            hover: "#B0F7F2",
        },
    },
});

const App: FC<any> = (): ReactElement => {
    const [sourcePointTokens, setSourcePointTokens] = useState<SourcePointTokens | undefined>(undefined);

    useEffect(() => {
        (async () => {
            // if not found in react, attempt to grab a non-expired profile from localstorage.
            if (sourcePointTokens === undefined) {
                let fromLocalStorage: SourcePointTokens | undefined = getSourcePointTokensFromStorageWithExpiry();
                if (fromLocalStorage !== undefined) {
                    // since not expired, we can use this profile
                    setSourcePointTokens(fromLocalStorage);
                }
            }
        })();
    }, [sourcePointTokens]);

    return (
        <SourcePointTokensContext.Provider value={sourcePointTokens}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Box minHeight='100vh' display="flex" flexDirection="column">
                    <Router>
                        <NavBar/>
                        <br/>
                        {sourcePointTokens === undefined ?
                            <MasterContainer>
                                <Typography sx={{mt: 1, mb: 1}} variant="subtitle1" component="div">
                                    Please login to continue
                                </Typography>
                                <Authorize setSourcePointTokens={setSourcePointTokens}/>
                            </MasterContainer>
                            :
                                <Routes>
                                    {appRoutes.map((route) => (
                                        <Route
                                            key={route.key}
                                            path={route.path}
                                            element={<route.component/>}
                                        />
                                    ))}
                                </Routes>
                        }
                        <br/>
                        <Footer/>
                    </Router>
                </Box>
            </ThemeProvider>
        </SourcePointTokensContext.Provider>
    );
}

export default App;
export {
    SourcePointTokensContext
};