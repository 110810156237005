import React, {FC, ReactElement, useCallback, useEffect, useState} from "react";
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceInternalClient} from "../../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {GetScanJobReportsRequest, ScanJobReport} from "../../generated/sp/scan_service/scan_service_internal_pb";
import {generateAuthHeader} from "../../lib/authorizationUtils";
import {NumberFilter, NumberList, PagingParameters, SortDirection, TimestampFilter} from "../../generated/sp/service_common/common_pb";
import {getCurrentTimestampMinusDays} from "../../lib/timestampUtils";
import {Box, LinearProgress} from "@mui/material";
import type {ChartData} from 'chart.js';
import {ArcElement, CategoryScale, Chart as ChartJS, Colors, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import {Doughnut} from "react-chartjs-2";
import {fetchIabCmpMap} from "../../lib/iabUtils";
import AggregationParameters = GetScanJobReportsRequest.AggregationParameters;
import AggregateFunction = GetScanJobReportsRequest.AggregationParameters.AggregateFunction;
import ScanJobReportField = GetScanJobReportsRequest.AggregationParameters.ScanJobReportField;
import SortField = GetScanJobReportsRequest.SortField;

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

const generateChartData = (cmpLookup: Map<number,string>, scanJobReports: ScanJobReport[]): ChartData<'doughnut'> => {
    let labels: String[] = scanJobReports.map((report) => {
        let cmpId = report.getConsentCmpId();
        let cmpName = cmpLookup.get(cmpId);
        if (cmpName !== undefined) {
            return cmpName;
        } else {
            return cmpId.toString();
        }
    });
    let data = scanJobReports.map((report) => {
        return report.getCount()
    });

    return ({
        labels: labels,
        datasets: [
            {
                label: "Count",
                data: data,
                hoverOffset: 4
            }
        ],
    });
}

interface CmpScanJobDoughnutChartProps {
    daysBack: number
    maxHeight: number;
    minCount: number;
    sx?: SxProps<Theme>;
}

const CmpScanJobDoughnutChart: FC<CmpScanJobDoughnutChartProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanJobReports, setScanJobReports] = useState<ScanJobReport[]>([]);
    const [cmpIdLookup, setCmpIdLookup] = useState<Map<number,string>>(new Map());

    const getScanJobReports = async (scanServiceInternal: ScanServiceInternalClient, newerThanDaysFilter: number): Promise<ScanJobReport[]> => {
        var req = new GetScanJobReportsRequest();
        let filters= new GetScanJobReportsRequest.Filters();
        // convert newerThanDaysFilter to TimestampFilter
        filters.setCompletionTimeFilter(new TimestampFilter().setOnOrAfter(getCurrentTimestampMinusDays(newerThanDaysFilter)))
        filters.setConsentCmpIdFilter(new NumberFilter().setGreaterThan(new NumberList().setValuesList([0])))
        req.setFilters(filters);
        // figure out aggregates
        let aggregationParameters = new AggregationParameters()
          .setSelectFieldsList([ScanJobReportField.SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID])
          .setAggregateFnc(AggregateFunction.AGGREGATE_FUNCTION_COUNT)
          .setAggregateFieldsList([ScanJobReportField.SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID])
          .setGroupByFieldsList([ScanJobReportField.SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID]);
        req.setAggregationParameters(aggregationParameters);
        req.setSortParametersList([new GetScanJobReportsRequest.SortParameter().setField(SortField.SORT_FIELD_AGGREGATE_COUNT).setDirection(SortDirection.SORT_DIRECTION_DESC)])
        req.setPagingParameters(new PagingParameters().setLimit(100))
        return new Promise<ScanJobReport[]>((resolve, reject) => {
            scanServiceInternal.getScanJobReports(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getScanJobReportsList())
            });
        });
    }
    const refreshScanJobReports = useCallback(() => {
        console.log("Loading scan job reports...")
        setLoading(true);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(getScanJobReports(scanServiceInternal, props.daysBack)).then(function (scanJobReports) {
            console.log(`Found scanJobReports of size=${scanJobReports.length}`);
            // scanJobReports.forEach((crap) => console.log(crap.toObject()));
            setScanJobReports(scanJobReports);
            setLoading(false);
        }, function (rejected) {
            console.error(rejected);
            setLoading(false);
        });
    }, [props.daysBack]);

    useEffect(() => {
        (async () => {
            let cmpLookup = await fetchIabCmpMap();
            setCmpIdLookup(cmpLookup);
            refreshScanJobReports();
        })();
    }, [refreshScanJobReports]);

    return (
      <React.Fragment>
          {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> :
            <Box sx={{height: 10}}>&nbsp;</Box>}
          <Doughnut style={{maxHeight: props.maxHeight + "px"}} options={{
              responsive: true,
              plugins: {
                  colors: {
                      forceOverride: true,
                  },
                  legend: {
                      display: false,
                  },
                  title: {
                      display: false,
                  },
              },
          }}
               data={generateChartData(cmpIdLookup, scanJobReports.filter((report) => report.getCount() > props.minCount))} />
      </React.Fragment>
    );
};

export default CmpScanJobDoughnutChart;