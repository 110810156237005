// source: sp/scan_service/scan_service_internal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var sp_service_common_common_pb = require('../../sp/service_common/common_pb.js');
goog.object.extend(proto, sp_service_common_common_pb);
var sp_scan_runner_common_pb = require('../../sp/scan_runner/common_pb.js');
goog.object.extend(proto, sp_scan_runner_common_pb);
var sp_scan_runner_scan_runner_pb = require('../../sp/scan_runner/scan_runner_pb.js');
goog.object.extend(proto, sp_scan_runner_scan_runner_pb);
var sp_scan_service_scan_service_pb = require('../../sp/scan_service/scan_service_pb.js');
goog.object.extend(proto, sp_scan_service_scan_service_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.sp.scan_service.ComputePropertySnapshotRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputePropertySnapshotResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputePropertyStatisticsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputePropertyStatisticsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputeScanJobStatisticsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputeScanJobStatisticsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic', null, global);
goog.exportSymbol('proto.sp.scan_service.CorrectScanJobResultsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult', null, global);
goog.exportSymbol('proto.sp.scan_service.CorrectScanJobResultsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.DeploymentSettings', null, global);
goog.exportSymbol('proto.sp.scan_service.DumpTrainingDataRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.DumpTrainingDataResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndLaunchScanJobsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndLaunchScanJobsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessPropertiesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessPropertiesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessScanArtifactsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessScanArtifactsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessScanJobsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessScanJobsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessStalePropertiesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.FindAndProcessStalePropertiesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GateScanJobsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GateScanJobsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GenerateApiKeyRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GenerateApiKeyResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountDomainSummaryRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountDomainSummaryResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAttachedPropertySetsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAttachedPropertySetsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData', null, global);
goog.exportSymbol('proto.sp.scan_service.GetCookiesForScanJobRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetCookiesForScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetDeploymentSettingsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetDeploymentSettingsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestActiveScanReportsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestActiveScanReportsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetRandomScanJobPendingGatingRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetRandomScanJobPendingGatingResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetRandomTrainingDataInputRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.AggregateFunction', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobReportsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScoresForPropertySetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScoresForPropertySetResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetStalePropertiesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetStalePropertiesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTrainingDataInputRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTrainingDataInputResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.KillScanJobRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.KillScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.LatestActiveScanReport', null, global);
goog.exportSymbol('proto.sp.scan_service.LaunchScanJobRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.LaunchScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.MonitorExpiredLatestScansRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.MonitorExpiredLatestScansResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.OvertimeScanJob', null, global);
goog.exportSymbol('proto.sp.scan_service.PagingPayload', null, global);
goog.exportSymbol('proto.sp.scan_service.PrioritizeScanJobRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.PrioritizeScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessPropertyRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessPropertyResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessPropertySetsStatsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessPropertySetsStatsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessScanArtifactsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessScanArtifactsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessScanJobRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessStalePropertyRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessStalePropertyResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessedProperty', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessedScanArtifacts', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessedScanJob', null, global);
goog.exportSymbol('proto.sp.scan_service.ProcessedStaleProperty', null, global);
goog.exportSymbol('proto.sp.scan_service.PromoteScanJobRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.PromoteScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertyDetailScore', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertyDetailScore.RuleScore', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySetScoreSummary', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySetScoreSummary.RuleScore', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySetScoreSummary.ScoreSummaryType', null, global);
goog.exportSymbol('proto.sp.scan_service.RebuildPropertySnapshotByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.RebuildPropertySnapshotRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.RebuildPropertySnapshotResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.RebuildPropertySnapshotsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.RebuildPropertySnapshotsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ReprocessPropertiesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ReprocessPropertiesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.RevokeApiKeyRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.RevokeApiKeyResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobKubernetesPod', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobReport', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobReport.ResultCase', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanStalePropertiesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanStalePropertiesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.SortOffset', null, global);
goog.exportSymbol('proto.sp.scan_service.StartDebugScanRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.StartDebugScanResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.StreamLogsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.StreamLogsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.TerminateOvertimeScanJobsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.TerminateOvertimeScanJobsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateGatingRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateGatingResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateTrainingDataRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateTrainingDataResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadCsvRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadCsvRequest.CsvLine', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadCsvResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadExtensionResultsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata', null, global);
goog.exportSymbol('proto.sp.scan_service.UploadExtensionResultsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UpsertDeploymentSettingsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UpsertDeploymentSettingsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.VerifyApiKeyRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.VerifyApiKeyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobKubernetesPod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJobKubernetesPod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobKubernetesPod.displayName = 'proto.sp.scan_service.ScanJobKubernetesPod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessedScanJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessedScanJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessedScanJob.displayName = 'proto.sp.scan_service.ProcessedScanJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessedScanArtifacts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessedScanArtifacts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessedScanArtifacts.displayName = 'proto.sp.scan_service.ProcessedScanArtifacts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.OvertimeScanJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.OvertimeScanJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.OvertimeScanJob.displayName = 'proto.sp.scan_service.OvertimeScanJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessedProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessedProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessedProperty.displayName = 'proto.sp.scan_service.ProcessedProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessedStaleProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessedStaleProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessedStaleProperty.displayName = 'proto.sp.scan_service.ProcessedStaleProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.FindAndLaunchScanJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndLaunchScanJobsRequest.displayName = 'proto.sp.scan_service.FindAndLaunchScanJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.FindAndLaunchScanJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.FindAndLaunchScanJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndLaunchScanJobsResponse.displayName = 'proto.sp.scan_service.FindAndLaunchScanJobsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.LaunchScanJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.LaunchScanJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.LaunchScanJobRequest.displayName = 'proto.sp.scan_service.LaunchScanJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.LaunchScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.LaunchScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.LaunchScanJobResponse.displayName = 'proto.sp.scan_service.LaunchScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.KillScanJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.KillScanJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.KillScanJobRequest.displayName = 'proto.sp.scan_service.KillScanJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.KillScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.KillScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.KillScanJobResponse.displayName = 'proto.sp.scan_service.KillScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessScanJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessScanJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessScanJobRequest.displayName = 'proto.sp.scan_service.ProcessScanJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessScanJobResponse.displayName = 'proto.sp.scan_service.ProcessScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessScanJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessScanJobsRequest.displayName = 'proto.sp.scan_service.FindAndProcessScanJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.FindAndProcessScanJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessScanJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessScanJobsResponse.displayName = 'proto.sp.scan_service.FindAndProcessScanJobsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessScanArtifactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessScanArtifactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessScanArtifactsRequest.displayName = 'proto.sp.scan_service.ProcessScanArtifactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessScanArtifactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessScanArtifactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessScanArtifactsResponse.displayName = 'proto.sp.scan_service.ProcessScanArtifactsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessScanArtifactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessScanArtifactsRequest.displayName = 'proto.sp.scan_service.FindAndProcessScanArtifactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.FindAndProcessScanArtifactsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessScanArtifactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessScanArtifactsResponse.displayName = 'proto.sp.scan_service.FindAndProcessScanArtifactsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.TerminateOvertimeScanJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.TerminateOvertimeScanJobsRequest.displayName = 'proto.sp.scan_service.TerminateOvertimeScanJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.TerminateOvertimeScanJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.TerminateOvertimeScanJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.TerminateOvertimeScanJobsResponse.displayName = 'proto.sp.scan_service.TerminateOvertimeScanJobsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessPropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessPropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessPropertyRequest.displayName = 'proto.sp.scan_service.ProcessPropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessPropertyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessPropertyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessPropertyResponse.displayName = 'proto.sp.scan_service.ProcessPropertyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessPropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessPropertiesRequest.displayName = 'proto.sp.scan_service.FindAndProcessPropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.FindAndProcessPropertiesResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessPropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessPropertiesResponse.displayName = 'proto.sp.scan_service.FindAndProcessPropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessStalePropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessStalePropertiesRequest.displayName = 'proto.sp.scan_service.FindAndProcessStalePropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.FindAndProcessStalePropertiesResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.FindAndProcessStalePropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.FindAndProcessStalePropertiesResponse.displayName = 'proto.sp.scan_service.FindAndProcessStalePropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessStalePropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessStalePropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessStalePropertyRequest.displayName = 'proto.sp.scan_service.ProcessStalePropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessStalePropertyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessStalePropertyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessStalePropertyResponse.displayName = 'proto.sp.scan_service.ProcessStalePropertyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ReprocessPropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ReprocessPropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ReprocessPropertiesRequest.displayName = 'proto.sp.scan_service.ReprocessPropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ReprocessPropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ReprocessPropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ReprocessPropertiesResponse.displayName = 'proto.sp.scan_service.ReprocessPropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.StartDebugScanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.StartDebugScanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.StartDebugScanRequest.displayName = 'proto.sp.scan_service.StartDebugScanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.StartDebugScanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.StartDebugScanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.StartDebugScanResponse.displayName = 'proto.sp.scan_service.StartDebugScanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanStalePropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanStalePropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanStalePropertiesRequest.displayName = 'proto.sp.scan_service.ScanStalePropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanStalePropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanStalePropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanStalePropertiesResponse.displayName = 'proto.sp.scan_service.ScanStalePropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.StreamLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.StreamLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.StreamLogsRequest.displayName = 'proto.sp.scan_service.StreamLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.StreamLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.StreamLogsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.StreamLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.StreamLogsResponse.displayName = 'proto.sp.scan_service.StreamLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetAttachedPropertySetsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetAttachedPropertySetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAttachedPropertySetsRequest.displayName = 'proto.sp.scan_service.GetAttachedPropertySetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetAttachedPropertySetsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetAttachedPropertySetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAttachedPropertySetsResponse.displayName = 'proto.sp.scan_service.GetAttachedPropertySetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.displayName = 'proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ProcessPropertySetsStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessPropertySetsStatsRequest.displayName = 'proto.sp.scan_service.ProcessPropertySetsStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ProcessPropertySetsStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ProcessPropertySetsStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ProcessPropertySetsStatsResponse.displayName = 'proto.sp.scan_service.ProcessPropertySetsStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetStalePropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetStalePropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetStalePropertiesRequest.displayName = 'proto.sp.scan_service.GetStalePropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetStalePropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetStalePropertiesResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetStalePropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetStalePropertiesResponse.displayName = 'proto.sp.scan_service.GetStalePropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadCsvRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UploadCsvRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UploadCsvRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadCsvRequest.displayName = 'proto.sp.scan_service.UploadCsvRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UploadCsvRequest.CsvLine.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UploadCsvRequest.CsvLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadCsvRequest.CsvLine.displayName = 'proto.sp.scan_service.UploadCsvRequest.CsvLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.displayName = 'proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadCsvResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UploadCsvResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UploadCsvResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadCsvResponse.displayName = 'proto.sp.scan_service.UploadCsvResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadExtensionResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UploadExtensionResultsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UploadExtensionResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadExtensionResultsRequest.displayName = 'proto.sp.scan_service.UploadExtensionResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.displayName = 'proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.displayName = 'proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UploadExtensionResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UploadExtensionResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UploadExtensionResultsResponse.displayName = 'proto.sp.scan_service.UploadExtensionResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.SortOffset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.SortOffset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.SortOffset.displayName = 'proto.sp.scan_service.SortOffset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PagingPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.PagingPayload.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.PagingPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PagingPayload.displayName = 'proto.sp.scan_service.PagingPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySetScoreSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertySetScoreSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySetScoreSummary.displayName = 'proto.sp.scan_service.PropertySetScoreSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.displayName = 'proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertySetScoreSummary.RuleScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySetScoreSummary.RuleScore.displayName = 'proto.sp.scan_service.PropertySetScoreSummary.RuleScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetAccountDomainSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountDomainSummaryRequest.displayName = 'proto.sp.scan_service.GetAccountDomainSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetAccountDomainSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetAccountDomainSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountDomainSummaryResponse.displayName = 'proto.sp.scan_service.GetAccountDomainSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.DeploymentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.DeploymentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.DeploymentSettings.displayName = 'proto.sp.scan_service.DeploymentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpsertDeploymentSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpsertDeploymentSettingsRequest.displayName = 'proto.sp.scan_service.UpsertDeploymentSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpsertDeploymentSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpsertDeploymentSettingsResponse.displayName = 'proto.sp.scan_service.UpsertDeploymentSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetDeploymentSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetDeploymentSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetDeploymentSettingsRequest.displayName = 'proto.sp.scan_service.GetDeploymentSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetDeploymentSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetDeploymentSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetDeploymentSettingsResponse.displayName = 'proto.sp.scan_service.GetDeploymentSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PromoteScanJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PromoteScanJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PromoteScanJobRequest.displayName = 'proto.sp.scan_service.PromoteScanJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PromoteScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PromoteScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PromoteScanJobResponse.displayName = 'proto.sp.scan_service.PromoteScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PrioritizeScanJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PrioritizeScanJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PrioritizeScanJobRequest.displayName = 'proto.sp.scan_service.PrioritizeScanJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PrioritizeScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PrioritizeScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PrioritizeScanJobResponse.displayName = 'proto.sp.scan_service.PrioritizeScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertyDetailScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.PropertyDetailScore.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.PropertyDetailScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertyDetailScore.displayName = 'proto.sp.scan_service.PropertyDetailScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertyDetailScore.RuleScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertyDetailScore.RuleScore.displayName = 'proto.sp.scan_service.PropertyDetailScore.RuleScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScoresForPropertySetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScoresForPropertySetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScoresForPropertySetRequest.displayName = 'proto.sp.scan_service.GetScoresForPropertySetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScoresForPropertySetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScoresForPropertySetResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScoresForPropertySetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScoresForPropertySetResponse.displayName = 'proto.sp.scan_service.GetScoresForPropertySetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.VerifyApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.VerifyApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.VerifyApiKeyRequest.displayName = 'proto.sp.scan_service.VerifyApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.VerifyApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.VerifyApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.VerifyApiKeyResponse.displayName = 'proto.sp.scan_service.VerifyApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GenerateApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GenerateApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GenerateApiKeyRequest.displayName = 'proto.sp.scan_service.GenerateApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GenerateApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GenerateApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GenerateApiKeyResponse.displayName = 'proto.sp.scan_service.GenerateApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RevokeApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RevokeApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RevokeApiKeyRequest.displayName = 'proto.sp.scan_service.RevokeApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RevokeApiKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RevokeApiKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RevokeApiKeyResponse.displayName = 'proto.sp.scan_service.RevokeApiKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTrainingDataInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetTrainingDataInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTrainingDataInputRequest.displayName = 'proto.sp.scan_service.GetTrainingDataInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetRandomTrainingDataInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetRandomTrainingDataInputRequest.displayName = 'proto.sp.scan_service.GetRandomTrainingDataInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTrainingDataInputResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetTrainingDataInputResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetTrainingDataInputResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTrainingDataInputResponse.displayName = 'proto.sp.scan_service.GetTrainingDataInputResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateTrainingDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateTrainingDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateTrainingDataRequest.displayName = 'proto.sp.scan_service.UpdateTrainingDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateTrainingDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateTrainingDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateTrainingDataResponse.displayName = 'proto.sp.scan_service.UpdateTrainingDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetRandomScanJobPendingGatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.displayName = 'proto.sp.scan_service.GetRandomScanJobPendingGatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetRandomScanJobPendingGatingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.displayName = 'proto.sp.scan_service.GetRandomScanJobPendingGatingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CorrectScanJobResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.CorrectScanJobResultsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.CorrectScanJobResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CorrectScanJobResultsRequest.displayName = 'proto.sp.scan_service.CorrectScanJobResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.displayName = 'proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CorrectScanJobResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CorrectScanJobResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CorrectScanJobResultsResponse.displayName = 'proto.sp.scan_service.CorrectScanJobResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RebuildPropertySnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RebuildPropertySnapshotsRequest.displayName = 'proto.sp.scan_service.RebuildPropertySnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.RebuildPropertySnapshotsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.RebuildPropertySnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RebuildPropertySnapshotsResponse.displayName = 'proto.sp.scan_service.RebuildPropertySnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RebuildPropertySnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RebuildPropertySnapshotRequest.displayName = 'proto.sp.scan_service.RebuildPropertySnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RebuildPropertySnapshotByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.displayName = 'proto.sp.scan_service.RebuildPropertySnapshotByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RebuildPropertySnapshotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RebuildPropertySnapshotResponse.displayName = 'proto.sp.scan_service.RebuildPropertySnapshotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputePropertySnapshotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComputePropertySnapshotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputePropertySnapshotRequest.displayName = 'proto.sp.scan_service.ComputePropertySnapshotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputePropertySnapshotResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComputePropertySnapshotResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputePropertySnapshotResponse.displayName = 'proto.sp.scan_service.ComputePropertySnapshotResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComputePropertyStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputePropertyStatisticsRequest.displayName = 'proto.sp.scan_service.ComputePropertyStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ComputePropertyStatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ComputePropertyStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputePropertyStatisticsResponse.displayName = 'proto.sp.scan_service.ComputePropertyStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComputeScanJobStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputeScanJobStatisticsRequest.displayName = 'proto.sp.scan_service.ComputeScanJobStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.displayName = 'proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ComputeScanJobStatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ComputeScanJobStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputeScanJobStatisticsResponse.displayName = 'proto.sp.scan_service.ComputeScanJobStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.displayName = 'proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.LatestActiveScanReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.LatestActiveScanReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.LatestActiveScanReport.displayName = 'proto.sp.scan_service.LatestActiveScanReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetLatestActiveScanReportsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetLatestActiveScanReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetLatestActiveScanReportsRequest.displayName = 'proto.sp.scan_service.GetLatestActiveScanReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.displayName = 'proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetLatestActiveScanReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetLatestActiveScanReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetLatestActiveScanReportsResponse.displayName = 'proto.sp.scan_service.GetLatestActiveScanReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.MonitorExpiredLatestScansRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.MonitorExpiredLatestScansRequest.displayName = 'proto.sp.scan_service.MonitorExpiredLatestScansRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.MonitorExpiredLatestScansResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.MonitorExpiredLatestScansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.MonitorExpiredLatestScansResponse.displayName = 'proto.sp.scan_service.MonitorExpiredLatestScansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.scan_service.ScanJobReport.oneofGroups_);
};
goog.inherits(proto.sp.scan_service.ScanJobReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobReport.displayName = 'proto.sp.scan_service.ScanJobReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobReportsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobReportsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobReportsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobReportsRequest.displayName = 'proto.sp.scan_service.GetScanJobReportsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobReportsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetScanJobReportsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.displayName = 'proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobReportsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobReportsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobReportsRequest.Filters.displayName = 'proto.sp.scan_service.GetScanJobReportsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobReportsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobReportsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobReportsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobReportsResponse.displayName = 'proto.sp.scan_service.GetScanJobReportsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.DumpTrainingDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.DumpTrainingDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.DumpTrainingDataRequest.displayName = 'proto.sp.scan_service.DumpTrainingDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.DumpTrainingDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.DumpTrainingDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.DumpTrainingDataResponse.displayName = 'proto.sp.scan_service.DumpTrainingDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetCookiesForScanJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetCookiesForScanJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetCookiesForScanJobRequest.displayName = 'proto.sp.scan_service.GetCookiesForScanJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetCookiesForScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetCookiesForScanJobResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetCookiesForScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetCookiesForScanJobResponse.displayName = 'proto.sp.scan_service.GetCookiesForScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GateScanJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GateScanJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GateScanJobsRequest.displayName = 'proto.sp.scan_service.GateScanJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GateScanJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GateScanJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GateScanJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GateScanJobsResponse.displayName = 'proto.sp.scan_service.GateScanJobsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateGatingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateGatingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateGatingRequest.displayName = 'proto.sp.scan_service.UpdateGatingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateGatingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateGatingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateGatingResponse.displayName = 'proto.sp.scan_service.UpdateGatingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobKubernetesPod.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobKubernetesPod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobKubernetesPod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobKubernetesPod.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    podName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobKubernetesPod}
 */
proto.sp.scan_service.ScanJobKubernetesPod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobKubernetesPod;
  return proto.sp.scan_service.ScanJobKubernetesPod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobKubernetesPod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobKubernetesPod}
 */
proto.sp.scan_service.ScanJobKubernetesPod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPodName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobKubernetesPod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobKubernetesPod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobKubernetesPod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobKubernetesPod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPodName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJobKubernetesPod.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobKubernetesPod} returns this
 */
proto.sp.scan_service.ScanJobKubernetesPod.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pod_name = 2;
 * @return {string}
 */
proto.sp.scan_service.ScanJobKubernetesPod.prototype.getPodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobKubernetesPod} returns this
 */
proto.sp.scan_service.ScanJobKubernetesPod.prototype.setPodName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessedScanJob.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessedScanJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessedScanJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedScanJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanRulesProcessed: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessedScanJob}
 */
proto.sp.scan_service.ProcessedScanJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessedScanJob;
  return proto.sp.scan_service.ProcessedScanJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessedScanJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessedScanJob}
 */
proto.sp.scan_service.ProcessedScanJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScanRulesProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessedScanJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessedScanJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessedScanJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedScanJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanRulesProcessed();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessedScanJob.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedScanJob} returns this
 */
proto.sp.scan_service.ProcessedScanJob.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 scan_rules_processed = 2;
 * @return {number}
 */
proto.sp.scan_service.ProcessedScanJob.prototype.getScanRulesProcessed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ProcessedScanJob} returns this
 */
proto.sp.scan_service.ProcessedScanJob.prototype.setScanRulesProcessed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessedScanArtifacts.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessedScanArtifacts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessedScanArtifacts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedScanArtifacts.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    artifactsProcessed: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessedScanArtifacts}
 */
proto.sp.scan_service.ProcessedScanArtifacts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessedScanArtifacts;
  return proto.sp.scan_service.ProcessedScanArtifacts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessedScanArtifacts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessedScanArtifacts}
 */
proto.sp.scan_service.ProcessedScanArtifacts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArtifactsProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessedScanArtifacts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessedScanArtifacts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessedScanArtifacts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedScanArtifacts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArtifactsProcessed();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessedScanArtifacts.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedScanArtifacts} returns this
 */
proto.sp.scan_service.ProcessedScanArtifacts.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 artifacts_processed = 2;
 * @return {number}
 */
proto.sp.scan_service.ProcessedScanArtifacts.prototype.getArtifactsProcessed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ProcessedScanArtifacts} returns this
 */
proto.sp.scan_service.ProcessedScanArtifacts.prototype.setArtifactsProcessed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.OvertimeScanJob.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.OvertimeScanJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.OvertimeScanJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.OvertimeScanJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runningTimeSecs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.OvertimeScanJob}
 */
proto.sp.scan_service.OvertimeScanJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.OvertimeScanJob;
  return proto.sp.scan_service.OvertimeScanJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.OvertimeScanJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.OvertimeScanJob}
 */
proto.sp.scan_service.OvertimeScanJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRunningTimeSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.OvertimeScanJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.OvertimeScanJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.OvertimeScanJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.OvertimeScanJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunningTimeSecs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.OvertimeScanJob.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.OvertimeScanJob} returns this
 */
proto.sp.scan_service.OvertimeScanJob.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 running_time_secs = 2;
 * @return {number}
 */
proto.sp.scan_service.OvertimeScanJob.prototype.getRunningTimeSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.OvertimeScanJob} returns this
 */
proto.sp.scan_service.OvertimeScanJob.prototype.setRunningTimeSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessedProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessedProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessedProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanRulesProcessed: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessedProperty}
 */
proto.sp.scan_service.ProcessedProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessedProperty;
  return proto.sp.scan_service.ProcessedProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessedProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessedProperty}
 */
proto.sp.scan_service.ProcessedProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScanRulesProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessedProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessedProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessedProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanRulesProcessed();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string property_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessedProperty.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedProperty} returns this
 */
proto.sp.scan_service.ProcessedProperty.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 scan_rules_processed = 2;
 * @return {number}
 */
proto.sp.scan_service.ProcessedProperty.prototype.getScanRulesProcessed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ProcessedProperty} returns this
 */
proto.sp.scan_service.ProcessedProperty.prototype.setScanRulesProcessed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessedStaleProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessedStaleProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedStaleProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scanJobId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessedStaleProperty}
 */
proto.sp.scan_service.ProcessedStaleProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessedStaleProperty;
  return proto.sp.scan_service.ProcessedStaleProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessedStaleProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessedStaleProperty}
 */
proto.sp.scan_service.ProcessedStaleProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessedStaleProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessedStaleProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessedStaleProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string property_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedStaleProperty} returns this
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_name = 2;
 * @return {string}
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedStaleProperty} returns this
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region_id = 3;
 * @return {string}
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedStaleProperty} returns this
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scan_job_id = 4;
 * @return {string}
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessedStaleProperty} returns this
 */
proto.sp.scan_service.ProcessedStaleProperty.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndLaunchScanJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndLaunchScanJobsRequest}
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndLaunchScanJobsRequest;
  return proto.sp.scan_service.FindAndLaunchScanJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndLaunchScanJobsRequest}
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndLaunchScanJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndLaunchScanJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndLaunchScanJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobKubernetesPodsList: jspb.Message.toObjectList(msg.getScanJobKubernetesPodsList(),
    proto.sp.scan_service.ScanJobKubernetesPod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndLaunchScanJobsResponse}
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndLaunchScanJobsResponse;
  return proto.sp.scan_service.FindAndLaunchScanJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndLaunchScanJobsResponse}
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanJobKubernetesPod;
      reader.readMessage(value,proto.sp.scan_service.ScanJobKubernetesPod.deserializeBinaryFromReader);
      msg.addScanJobKubernetesPods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndLaunchScanJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobKubernetesPodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ScanJobKubernetesPod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScanJobKubernetesPod scan_job_kubernetes_pods = 1;
 * @return {!Array<!proto.sp.scan_service.ScanJobKubernetesPod>}
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.prototype.getScanJobKubernetesPodsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobKubernetesPod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobKubernetesPod, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobKubernetesPod>} value
 * @return {!proto.sp.scan_service.FindAndLaunchScanJobsResponse} returns this
*/
proto.sp.scan_service.FindAndLaunchScanJobsResponse.prototype.setScanJobKubernetesPodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobKubernetesPod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobKubernetesPod}
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.prototype.addScanJobKubernetesPods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ScanJobKubernetesPod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.FindAndLaunchScanJobsResponse} returns this
 */
proto.sp.scan_service.FindAndLaunchScanJobsResponse.prototype.clearScanJobKubernetesPodsList = function() {
  return this.setScanJobKubernetesPodsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.LaunchScanJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.LaunchScanJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.LaunchScanJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.LaunchScanJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.LaunchScanJobRequest}
 */
proto.sp.scan_service.LaunchScanJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.LaunchScanJobRequest;
  return proto.sp.scan_service.LaunchScanJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.LaunchScanJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.LaunchScanJobRequest}
 */
proto.sp.scan_service.LaunchScanJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.LaunchScanJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.LaunchScanJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.LaunchScanJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.LaunchScanJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.LaunchScanJobRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LaunchScanJobRequest} returns this
 */
proto.sp.scan_service.LaunchScanJobRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.LaunchScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.LaunchScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.LaunchScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.LaunchScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobKubernetesPod: (f = msg.getScanJobKubernetesPod()) && proto.sp.scan_service.ScanJobKubernetesPod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.LaunchScanJobResponse}
 */
proto.sp.scan_service.LaunchScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.LaunchScanJobResponse;
  return proto.sp.scan_service.LaunchScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.LaunchScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.LaunchScanJobResponse}
 */
proto.sp.scan_service.LaunchScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanJobKubernetesPod;
      reader.readMessage(value,proto.sp.scan_service.ScanJobKubernetesPod.deserializeBinaryFromReader);
      msg.setScanJobKubernetesPod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.LaunchScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.LaunchScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.LaunchScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.LaunchScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobKubernetesPod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ScanJobKubernetesPod.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanJobKubernetesPod scan_job_kubernetes_pod = 1;
 * @return {?proto.sp.scan_service.ScanJobKubernetesPod}
 */
proto.sp.scan_service.LaunchScanJobResponse.prototype.getScanJobKubernetesPod = function() {
  return /** @type{?proto.sp.scan_service.ScanJobKubernetesPod} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJobKubernetesPod, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanJobKubernetesPod|undefined} value
 * @return {!proto.sp.scan_service.LaunchScanJobResponse} returns this
*/
proto.sp.scan_service.LaunchScanJobResponse.prototype.setScanJobKubernetesPod = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.LaunchScanJobResponse} returns this
 */
proto.sp.scan_service.LaunchScanJobResponse.prototype.clearScanJobKubernetesPod = function() {
  return this.setScanJobKubernetesPod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.LaunchScanJobResponse.prototype.hasScanJobKubernetesPod = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.KillScanJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.KillScanJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.KillScanJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.KillScanJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.KillScanJobRequest}
 */
proto.sp.scan_service.KillScanJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.KillScanJobRequest;
  return proto.sp.scan_service.KillScanJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.KillScanJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.KillScanJobRequest}
 */
proto.sp.scan_service.KillScanJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.KillScanJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.KillScanJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.KillScanJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.KillScanJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.KillScanJobRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.KillScanJobRequest} returns this
 */
proto.sp.scan_service.KillScanJobRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.KillScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.KillScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.KillScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.KillScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updated: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.KillScanJobResponse}
 */
proto.sp.scan_service.KillScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.KillScanJobResponse;
  return proto.sp.scan_service.KillScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.KillScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.KillScanJobResponse}
 */
proto.sp.scan_service.KillScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.KillScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.KillScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.KillScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.KillScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool updated = 1;
 * @return {boolean}
 */
proto.sp.scan_service.KillScanJobResponse.prototype.getUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.KillScanJobResponse} returns this
 */
proto.sp.scan_service.KillScanJobResponse.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessScanJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessScanJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessScanJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessScanJobRequest}
 */
proto.sp.scan_service.ProcessScanJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessScanJobRequest;
  return proto.sp.scan_service.ProcessScanJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessScanJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessScanJobRequest}
 */
proto.sp.scan_service.ProcessScanJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessScanJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessScanJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessScanJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessScanJobRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessScanJobRequest} returns this
 */
proto.sp.scan_service.ProcessScanJobRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedScanJob: (f = msg.getProcessedScanJob()) && proto.sp.scan_service.ProcessedScanJob.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessScanJobResponse}
 */
proto.sp.scan_service.ProcessScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessScanJobResponse;
  return proto.sp.scan_service.ProcessScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessScanJobResponse}
 */
proto.sp.scan_service.ProcessScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedScanJob;
      reader.readMessage(value,proto.sp.scan_service.ProcessedScanJob.deserializeBinaryFromReader);
      msg.setProcessedScanJob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedScanJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProcessedScanJob processed_scan_job = 1;
 * @return {?proto.sp.scan_service.ProcessedScanJob}
 */
proto.sp.scan_service.ProcessScanJobResponse.prototype.getProcessedScanJob = function() {
  return /** @type{?proto.sp.scan_service.ProcessedScanJob} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ProcessedScanJob, 1));
};


/**
 * @param {?proto.sp.scan_service.ProcessedScanJob|undefined} value
 * @return {!proto.sp.scan_service.ProcessScanJobResponse} returns this
*/
proto.sp.scan_service.ProcessScanJobResponse.prototype.setProcessedScanJob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ProcessScanJobResponse} returns this
 */
proto.sp.scan_service.ProcessScanJobResponse.prototype.clearProcessedScanJob = function() {
  return this.setProcessedScanJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ProcessScanJobResponse.prototype.hasProcessedScanJob = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessScanJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessScanJobsRequest}
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessScanJobsRequest;
  return proto.sp.scan_service.FindAndProcessScanJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessScanJobsRequest}
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessScanJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessScanJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedScanJobsList: jspb.Message.toObjectList(msg.getProcessedScanJobsList(),
    proto.sp.scan_service.ProcessedScanJob.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessScanJobsResponse}
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessScanJobsResponse;
  return proto.sp.scan_service.FindAndProcessScanJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessScanJobsResponse}
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedScanJob;
      reader.readMessage(value,proto.sp.scan_service.ProcessedScanJob.deserializeBinaryFromReader);
      msg.addProcessedScanJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessScanJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedScanJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessedScanJob processed_scan_jobs = 1;
 * @return {!Array<!proto.sp.scan_service.ProcessedScanJob>}
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.prototype.getProcessedScanJobsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ProcessedScanJob>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ProcessedScanJob, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ProcessedScanJob>} value
 * @return {!proto.sp.scan_service.FindAndProcessScanJobsResponse} returns this
*/
proto.sp.scan_service.FindAndProcessScanJobsResponse.prototype.setProcessedScanJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ProcessedScanJob=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ProcessedScanJob}
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.prototype.addProcessedScanJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ProcessedScanJob, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.FindAndProcessScanJobsResponse} returns this
 */
proto.sp.scan_service.FindAndProcessScanJobsResponse.prototype.clearProcessedScanJobsList = function() {
  return this.setProcessedScanJobsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessScanArtifactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessScanArtifactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessScanArtifactsRequest}
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessScanArtifactsRequest;
  return proto.sp.scan_service.ProcessScanArtifactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessScanArtifactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessScanArtifactsRequest}
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessScanArtifactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessScanArtifactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessScanArtifactsRequest} returns this
 */
proto.sp.scan_service.ProcessScanArtifactsRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessScanArtifactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessScanArtifactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedScanArtifacts: (f = msg.getProcessedScanArtifacts()) && proto.sp.scan_service.ProcessedScanArtifacts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessScanArtifactsResponse}
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessScanArtifactsResponse;
  return proto.sp.scan_service.ProcessScanArtifactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessScanArtifactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessScanArtifactsResponse}
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedScanArtifacts;
      reader.readMessage(value,proto.sp.scan_service.ProcessedScanArtifacts.deserializeBinaryFromReader);
      msg.setProcessedScanArtifacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessScanArtifactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessScanArtifactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedScanArtifacts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedScanArtifacts.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProcessedScanArtifacts processed_scan_artifacts = 1;
 * @return {?proto.sp.scan_service.ProcessedScanArtifacts}
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.prototype.getProcessedScanArtifacts = function() {
  return /** @type{?proto.sp.scan_service.ProcessedScanArtifacts} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ProcessedScanArtifacts, 1));
};


/**
 * @param {?proto.sp.scan_service.ProcessedScanArtifacts|undefined} value
 * @return {!proto.sp.scan_service.ProcessScanArtifactsResponse} returns this
*/
proto.sp.scan_service.ProcessScanArtifactsResponse.prototype.setProcessedScanArtifacts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ProcessScanArtifactsResponse} returns this
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.prototype.clearProcessedScanArtifacts = function() {
  return this.setProcessedScanArtifacts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ProcessScanArtifactsResponse.prototype.hasProcessedScanArtifacts = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessScanArtifactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingLimit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessScanArtifactsRequest;
  return proto.sp.scan_service.FindAndProcessScanArtifactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProcessingLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessScanArtifactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 processing_limit = 1;
 * @return {number}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.prototype.getProcessingLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} returns this
 */
proto.sp.scan_service.FindAndProcessScanArtifactsRequest.prototype.setProcessingLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessScanArtifactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedScanArtifactsList: jspb.Message.toObjectList(msg.getProcessedScanArtifactsList(),
    proto.sp.scan_service.ProcessedScanArtifacts.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessScanArtifactsResponse;
  return proto.sp.scan_service.FindAndProcessScanArtifactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedScanArtifacts;
      reader.readMessage(value,proto.sp.scan_service.ProcessedScanArtifacts.deserializeBinaryFromReader);
      msg.addProcessedScanArtifacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessScanArtifactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedScanArtifactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedScanArtifacts.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessedScanArtifacts processed_scan_artifacts = 1;
 * @return {!Array<!proto.sp.scan_service.ProcessedScanArtifacts>}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.prototype.getProcessedScanArtifactsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ProcessedScanArtifacts>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ProcessedScanArtifacts, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ProcessedScanArtifacts>} value
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse} returns this
*/
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.prototype.setProcessedScanArtifactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ProcessedScanArtifacts=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ProcessedScanArtifacts}
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.prototype.addProcessedScanArtifacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ProcessedScanArtifacts, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.FindAndProcessScanArtifactsResponse} returns this
 */
proto.sp.scan_service.FindAndProcessScanArtifactsResponse.prototype.clearProcessedScanArtifactsList = function() {
  return this.setProcessedScanArtifactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.TerminateOvertimeScanJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingCompletedTimeoutSecs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pendingRunningTimeoutSecs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pendingErrorTimeoutSecs: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.TerminateOvertimeScanJobsRequest;
  return proto.sp.scan_service.TerminateOvertimeScanJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingCompletedTimeoutSecs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingRunningTimeoutSecs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingErrorTimeoutSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.TerminateOvertimeScanJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingCompletedTimeoutSecs();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPendingRunningTimeoutSecs();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPendingErrorTimeoutSecs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 pending_completed_timeout_secs = 1;
 * @return {number}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.getPendingCompletedTimeoutSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} returns this
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.setPendingCompletedTimeoutSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pending_running_timeout_secs = 2;
 * @return {number}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.getPendingRunningTimeoutSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} returns this
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.setPendingRunningTimeoutSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pending_error_timeout_secs = 3;
 * @return {number}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.getPendingErrorTimeoutSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} returns this
 */
proto.sp.scan_service.TerminateOvertimeScanJobsRequest.prototype.setPendingErrorTimeoutSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.TerminateOvertimeScanJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    overtimeScanJobsList: jspb.Message.toObjectList(msg.getOvertimeScanJobsList(),
    proto.sp.scan_service.OvertimeScanJob.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.TerminateOvertimeScanJobsResponse;
  return proto.sp.scan_service.TerminateOvertimeScanJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.OvertimeScanJob;
      reader.readMessage(value,proto.sp.scan_service.OvertimeScanJob.deserializeBinaryFromReader);
      msg.addOvertimeScanJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.TerminateOvertimeScanJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOvertimeScanJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.OvertimeScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OvertimeScanJob overtime_scan_jobs = 1;
 * @return {!Array<!proto.sp.scan_service.OvertimeScanJob>}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.prototype.getOvertimeScanJobsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.OvertimeScanJob>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.OvertimeScanJob, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.OvertimeScanJob>} value
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse} returns this
*/
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.prototype.setOvertimeScanJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.OvertimeScanJob=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.OvertimeScanJob}
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.prototype.addOvertimeScanJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.OvertimeScanJob, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.TerminateOvertimeScanJobsResponse} returns this
 */
proto.sp.scan_service.TerminateOvertimeScanJobsResponse.prototype.clearOvertimeScanJobsList = function() {
  return this.setOvertimeScanJobsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessPropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessPropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessPropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessPropertyRequest}
 */
proto.sp.scan_service.ProcessPropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessPropertyRequest;
  return proto.sp.scan_service.ProcessPropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessPropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessPropertyRequest}
 */
proto.sp.scan_service.ProcessPropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessPropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessPropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessPropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string property_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessPropertyRequest.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessPropertyRequest} returns this
 */
proto.sp.scan_service.ProcessPropertyRequest.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessPropertyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessPropertyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessPropertyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyProcessed: (f = msg.getPropertyProcessed()) && proto.sp.scan_service.ProcessedProperty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessPropertyResponse}
 */
proto.sp.scan_service.ProcessPropertyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessPropertyResponse;
  return proto.sp.scan_service.ProcessPropertyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessPropertyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessPropertyResponse}
 */
proto.sp.scan_service.ProcessPropertyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedProperty;
      reader.readMessage(value,proto.sp.scan_service.ProcessedProperty.deserializeBinaryFromReader);
      msg.setPropertyProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessPropertyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessPropertyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessPropertyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyProcessed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedProperty.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProcessedProperty property_processed = 1;
 * @return {?proto.sp.scan_service.ProcessedProperty}
 */
proto.sp.scan_service.ProcessPropertyResponse.prototype.getPropertyProcessed = function() {
  return /** @type{?proto.sp.scan_service.ProcessedProperty} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ProcessedProperty, 1));
};


/**
 * @param {?proto.sp.scan_service.ProcessedProperty|undefined} value
 * @return {!proto.sp.scan_service.ProcessPropertyResponse} returns this
*/
proto.sp.scan_service.ProcessPropertyResponse.prototype.setPropertyProcessed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ProcessPropertyResponse} returns this
 */
proto.sp.scan_service.ProcessPropertyResponse.prototype.clearPropertyProcessed = function() {
  return this.setPropertyProcessed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ProcessPropertyResponse.prototype.hasPropertyProcessed = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessPropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessPropertiesRequest}
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessPropertiesRequest;
  return proto.sp.scan_service.FindAndProcessPropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessPropertiesRequest}
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessPropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessPropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessPropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedPropertiesList: jspb.Message.toObjectList(msg.getProcessedPropertiesList(),
    proto.sp.scan_service.ProcessedProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessPropertiesResponse}
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessPropertiesResponse;
  return proto.sp.scan_service.FindAndProcessPropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessPropertiesResponse}
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedProperty;
      reader.readMessage(value,proto.sp.scan_service.ProcessedProperty.deserializeBinaryFromReader);
      msg.addProcessedProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessPropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedProperty.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessedProperty processed_properties = 1;
 * @return {!Array<!proto.sp.scan_service.ProcessedProperty>}
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.prototype.getProcessedPropertiesList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ProcessedProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ProcessedProperty, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ProcessedProperty>} value
 * @return {!proto.sp.scan_service.FindAndProcessPropertiesResponse} returns this
*/
proto.sp.scan_service.FindAndProcessPropertiesResponse.prototype.setProcessedPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ProcessedProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ProcessedProperty}
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.prototype.addProcessedProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ProcessedProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.FindAndProcessPropertiesResponse} returns this
 */
proto.sp.scan_service.FindAndProcessPropertiesResponse.prototype.clearProcessedPropertiesList = function() {
  return this.setProcessedPropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessStalePropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessStalePropertiesRequest;
  return proto.sp.scan_service.FindAndProcessStalePropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessStalePropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessStalePropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.FindAndProcessStalePropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedStalePropertiesList: jspb.Message.toObjectList(msg.getProcessedStalePropertiesList(),
    proto.sp.scan_service.ProcessedStaleProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.FindAndProcessStalePropertiesResponse;
  return proto.sp.scan_service.FindAndProcessStalePropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedStaleProperty;
      reader.readMessage(value,proto.sp.scan_service.ProcessedStaleProperty.deserializeBinaryFromReader);
      msg.addProcessedStaleProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.FindAndProcessStalePropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedStalePropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedStaleProperty.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProcessedStaleProperty processed_stale_properties = 1;
 * @return {!Array<!proto.sp.scan_service.ProcessedStaleProperty>}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.prototype.getProcessedStalePropertiesList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ProcessedStaleProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ProcessedStaleProperty, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ProcessedStaleProperty>} value
 * @return {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse} returns this
*/
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.prototype.setProcessedStalePropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ProcessedStaleProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ProcessedStaleProperty}
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.prototype.addProcessedStaleProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ProcessedStaleProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.FindAndProcessStalePropertiesResponse} returns this
 */
proto.sp.scan_service.FindAndProcessStalePropertiesResponse.prototype.clearProcessedStalePropertiesList = function() {
  return this.setProcessedStalePropertiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessStalePropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessStalePropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessStalePropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessStalePropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessStalePropertyRequest}
 */
proto.sp.scan_service.ProcessStalePropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessStalePropertyRequest;
  return proto.sp.scan_service.ProcessStalePropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessStalePropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessStalePropertyRequest}
 */
proto.sp.scan_service.ProcessStalePropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessStalePropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessStalePropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessStalePropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessStalePropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ProcessStalePropertyRequest.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessStalePropertyRequest} returns this
 */
proto.sp.scan_service.ProcessStalePropertyRequest.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.ProcessStalePropertyRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ProcessStalePropertyRequest} returns this
 */
proto.sp.scan_service.ProcessStalePropertyRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessStalePropertyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessStalePropertyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessStalePropertyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessStalePropertyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyProcessed: (f = msg.getPropertyProcessed()) && proto.sp.scan_service.ProcessedStaleProperty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessStalePropertyResponse}
 */
proto.sp.scan_service.ProcessStalePropertyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessStalePropertyResponse;
  return proto.sp.scan_service.ProcessStalePropertyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessStalePropertyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessStalePropertyResponse}
 */
proto.sp.scan_service.ProcessStalePropertyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ProcessedStaleProperty;
      reader.readMessage(value,proto.sp.scan_service.ProcessedStaleProperty.deserializeBinaryFromReader);
      msg.setPropertyProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessStalePropertyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessStalePropertyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessStalePropertyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessStalePropertyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyProcessed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ProcessedStaleProperty.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProcessedStaleProperty property_processed = 1;
 * @return {?proto.sp.scan_service.ProcessedStaleProperty}
 */
proto.sp.scan_service.ProcessStalePropertyResponse.prototype.getPropertyProcessed = function() {
  return /** @type{?proto.sp.scan_service.ProcessedStaleProperty} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ProcessedStaleProperty, 1));
};


/**
 * @param {?proto.sp.scan_service.ProcessedStaleProperty|undefined} value
 * @return {!proto.sp.scan_service.ProcessStalePropertyResponse} returns this
*/
proto.sp.scan_service.ProcessStalePropertyResponse.prototype.setPropertyProcessed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ProcessStalePropertyResponse} returns this
 */
proto.sp.scan_service.ProcessStalePropertyResponse.prototype.clearPropertyProcessed = function() {
  return this.setPropertyProcessed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ProcessStalePropertyResponse.prototype.hasPropertyProcessed = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ReprocessPropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ReprocessPropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ReprocessPropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ReprocessPropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ReprocessPropertiesRequest}
 */
proto.sp.scan_service.ReprocessPropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ReprocessPropertiesRequest;
  return proto.sp.scan_service.ReprocessPropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ReprocessPropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ReprocessPropertiesRequest}
 */
proto.sp.scan_service.ReprocessPropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ReprocessPropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ReprocessPropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ReprocessPropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ReprocessPropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ReprocessPropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ReprocessPropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ReprocessPropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ReprocessPropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesMarkedForReprocess: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ReprocessPropertiesResponse}
 */
proto.sp.scan_service.ReprocessPropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ReprocessPropertiesResponse;
  return proto.sp.scan_service.ReprocessPropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ReprocessPropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ReprocessPropertiesResponse}
 */
proto.sp.scan_service.ReprocessPropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPropertiesMarkedForReprocess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ReprocessPropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ReprocessPropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ReprocessPropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ReprocessPropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertiesMarkedForReprocess();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 properties_marked_for_reprocess = 1;
 * @return {number}
 */
proto.sp.scan_service.ReprocessPropertiesResponse.prototype.getPropertiesMarkedForReprocess = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ReprocessPropertiesResponse} returns this
 */
proto.sp.scan_service.ReprocessPropertiesResponse.prototype.setPropertiesMarkedForReprocess = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.StartDebugScanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.StartDebugScanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StartDebugScanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayOption: jspb.Message.getFieldWithDefault(msg, 3, 0),
    proxyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    script: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.StartDebugScanRequest}
 */
proto.sp.scan_service.StartDebugScanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.StartDebugScanRequest;
  return proto.sp.scan_service.StartDebugScanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.StartDebugScanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.StartDebugScanRequest}
 */
proto.sp.scan_service.StartDebugScanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_runner.DisplayOption} */ (reader.readEnum());
      msg.setDisplayOption(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_runner.ProxyType} */ (reader.readEnum());
      msg.setProxyType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.StartDebugScanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.StartDebugScanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StartDebugScanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayOption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProxyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.StartDebugScanRequest} returns this
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region = 2;
 * @return {string}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.StartDebugScanRequest} returns this
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional sp.scan_runner.DisplayOption display_option = 3;
 * @return {!proto.sp.scan_runner.DisplayOption}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.getDisplayOption = function() {
  return /** @type {!proto.sp.scan_runner.DisplayOption} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_runner.DisplayOption} value
 * @return {!proto.sp.scan_service.StartDebugScanRequest} returns this
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.setDisplayOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional sp.scan_runner.ProxyType proxy_type = 4;
 * @return {!proto.sp.scan_runner.ProxyType}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.getProxyType = function() {
  return /** @type {!proto.sp.scan_runner.ProxyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_runner.ProxyType} value
 * @return {!proto.sp.scan_service.StartDebugScanRequest} returns this
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.setProxyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string script = 5;
 * @return {string}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.getScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.StartDebugScanRequest} returns this
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.setScript = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.StartDebugScanRequest} returns this
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.clearScript = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.StartDebugScanRequest.prototype.hasScript = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.StartDebugScanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.StartDebugScanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.StartDebugScanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StartDebugScanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.StartDebugScanResponse}
 */
proto.sp.scan_service.StartDebugScanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.StartDebugScanResponse;
  return proto.sp.scan_service.StartDebugScanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.StartDebugScanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.StartDebugScanResponse}
 */
proto.sp.scan_service.StartDebugScanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.StartDebugScanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.StartDebugScanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.StartDebugScanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StartDebugScanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.StartDebugScanResponse.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.StartDebugScanResponse} returns this
 */
proto.sp.scan_service.StartDebugScanResponse.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanStalePropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanStalePropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanStalePropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stalenessDays: jspb.Message.getFieldWithDefault(msg, 2, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    proxyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stalenessDuration: (f = msg.getStalenessDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanStalePropertiesRequest;
  return proto.sp.scan_service.ScanStalePropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanStalePropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStalenessDays(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_runner.ProxyType} */ (reader.readEnum());
      msg.setProxyType(value);
      break;
    case 5:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setStalenessDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanStalePropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanStalePropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanStalePropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStalenessDays();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {!proto.sp.scan_runner.ProxyType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStalenessDuration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_set_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 staleness_days = 2;
 * @return {number}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.getStalenessDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.setStalenessDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 priority = 3;
 * @return {number}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.setPriority = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.clearPriority = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.hasPriority = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.scan_runner.ProxyType proxy_type = 4;
 * @return {!proto.sp.scan_runner.ProxyType}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.getProxyType = function() {
  return /** @type {!proto.sp.scan_runner.ProxyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_runner.ProxyType} value
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.setProxyType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.clearProxyType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.hasProxyType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Duration staleness_duration = 5;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.getStalenessDuration = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 5));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
*/
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.setStalenessDuration = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanStalePropertiesRequest} returns this
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.clearStalenessDuration = function() {
  return this.setStalenessDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanStalePropertiesRequest.prototype.hasStalenessDuration = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanStalePropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanStalePropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanStalePropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanStalePropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesMarkedForRescanning: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanStalePropertiesResponse}
 */
proto.sp.scan_service.ScanStalePropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanStalePropertiesResponse;
  return proto.sp.scan_service.ScanStalePropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanStalePropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanStalePropertiesResponse}
 */
proto.sp.scan_service.ScanStalePropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPropertiesMarkedForRescanning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanStalePropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanStalePropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanStalePropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanStalePropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertiesMarkedForRescanning();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 properties_marked_for_rescanning = 1;
 * @return {number}
 */
proto.sp.scan_service.ScanStalePropertiesResponse.prototype.getPropertiesMarkedForRescanning = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanStalePropertiesResponse} returns this
 */
proto.sp.scan_service.ScanStalePropertiesResponse.prototype.setPropertiesMarkedForRescanning = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.StreamLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.StreamLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.StreamLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StreamLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.StreamLogsRequest}
 */
proto.sp.scan_service.StreamLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.StreamLogsRequest;
  return proto.sp.scan_service.StreamLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.StreamLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.StreamLogsRequest}
 */
proto.sp.scan_service.StreamLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.StreamLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.StreamLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.StreamLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StreamLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.StreamLogsRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.StreamLogsRequest} returns this
 */
proto.sp.scan_service.StreamLogsRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.StreamLogsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.StreamLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.StreamLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.StreamLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StreamLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    logLinesList: jspb.Message.toObjectList(msg.getLogLinesList(),
    sp_scan_runner_scan_runner_pb.LogLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.StreamLogsResponse}
 */
proto.sp.scan_service.StreamLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.StreamLogsResponse;
  return proto.sp.scan_service.StreamLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.StreamLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.StreamLogsResponse}
 */
proto.sp.scan_service.StreamLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_runner_scan_runner_pb.LogLine;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.LogLine.deserializeBinaryFromReader);
      msg.addLogLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.StreamLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.StreamLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.StreamLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.StreamLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sp_scan_runner_scan_runner_pb.LogLine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated sp.scan_runner.LogLine log_lines = 1;
 * @return {!Array<!proto.sp.scan_runner.LogLine>}
 */
proto.sp.scan_service.StreamLogsResponse.prototype.getLogLinesList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.LogLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_scan_runner_scan_runner_pb.LogLine, 1));
};


/**
 * @param {!Array<!proto.sp.scan_runner.LogLine>} value
 * @return {!proto.sp.scan_service.StreamLogsResponse} returns this
*/
proto.sp.scan_service.StreamLogsResponse.prototype.setLogLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_runner.LogLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.LogLine}
 */
proto.sp.scan_service.StreamLogsResponse.prototype.addLogLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_runner.LogLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.StreamLogsResponse} returns this
 */
proto.sp.scan_service.StreamLogsResponse.prototype.clearLogLinesList = function() {
  return this.setLogLinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAttachedPropertySetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleSetIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    limitPerSet: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAttachedPropertySetsRequest;
  return proto.sp.scan_service.GetAttachedPropertySetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanRuleSetIds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimitPerSet(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAttachedPropertySetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleSetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getLimitPerSet();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated string scan_rule_set_ids = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.getScanRuleSetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.setScanRuleSetIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.addScanRuleSetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.clearScanRuleSetIdsList = function() {
  return this.setScanRuleSetIdsList([]);
};


/**
 * optional uint32 limit_per_set = 2;
 * @return {number}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.getLimitPerSet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.setLimitPerSet = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 account_id = 3;
 * @return {number}
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAttachedPropertySetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAttachedPropertySetsResponse;
  return proto.sp.scan_service.GetAttachedPropertySetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData;
      reader.readMessage(value,proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAttachedPropertySetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleSetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertySetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertySetName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData;
  return proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleSetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleSetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertySetName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string scan_rule_set_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.getScanRuleSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.setScanRuleSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_set_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string property_set_name = 3;
 * @return {string}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.getPropertySetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData.prototype.setPropertySetName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AttachedPropertySetData values = 1;
 * @return {!Array<!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData>}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData>} value
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse} returns this
*/
proto.sp.scan_service.GetAttachedPropertySetsResponse.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData}
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.GetAttachedPropertySetsResponse.AttachedPropertySetData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAttachedPropertySetsResponse} returns this
 */
proto.sp.scan_service.GetAttachedPropertySetsResponse.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessPropertySetsStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsRequest}
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessPropertySetsStatsRequest;
  return proto.sp.scan_service.ProcessPropertySetsStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsRequest}
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessPropertySetsStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertySetsStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ProcessPropertySetsStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySetIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsResponse}
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ProcessPropertySetsStatsResponse;
  return proto.sp.scan_service.ProcessPropertySetsStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsResponse}
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPropertySetIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ProcessPropertySetsStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string property_set_ids = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.prototype.getPropertySetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsResponse} returns this
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.prototype.setPropertySetIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsResponse} returns this
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.prototype.addPropertySetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ProcessPropertySetsStatsResponse} returns this
 */
proto.sp.scan_service.ProcessPropertySetsStatsResponse.prototype.clearPropertySetIdsList = function() {
  return this.setPropertySetIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetStalePropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetStalePropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetStalePropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stalenessDays: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stalenessDuration: (f = msg.getStalenessDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetStalePropertiesRequest}
 */
proto.sp.scan_service.GetStalePropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetStalePropertiesRequest;
  return proto.sp.scan_service.GetStalePropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetStalePropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetStalePropertiesRequest}
 */
proto.sp.scan_service.GetStalePropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStalenessDays(value);
      break;
    case 3:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setStalenessDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetStalePropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetStalePropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetStalePropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStalenessDays();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStalenessDuration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_set_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetStalePropertiesRequest} returns this
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 staleness_days = 2;
 * @return {number}
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.getStalenessDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetStalePropertiesRequest} returns this
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.setStalenessDays = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Duration staleness_duration = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.getStalenessDuration = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sp.scan_service.GetStalePropertiesRequest} returns this
*/
proto.sp.scan_service.GetStalePropertiesRequest.prototype.setStalenessDuration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetStalePropertiesRequest} returns this
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.clearStalenessDuration = function() {
  return this.setStalenessDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetStalePropertiesRequest.prototype.hasStalenessDuration = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetStalePropertiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetStalePropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetStalePropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetStalePropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetStalePropertiesResponse}
 */
proto.sp.scan_service.GetStalePropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetStalePropertiesResponse;
  return proto.sp.scan_service.GetStalePropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetStalePropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetStalePropertiesResponse}
 */
proto.sp.scan_service.GetStalePropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPropertyNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetStalePropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetStalePropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetStalePropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string region_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetStalePropertiesResponse} returns this
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string property_names = 2;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.getPropertyNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetStalePropertiesResponse} returns this
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.setPropertyNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetStalePropertiesResponse} returns this
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.addPropertyNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetStalePropertiesResponse} returns this
 */
proto.sp.scan_service.GetStalePropertiesResponse.prototype.clearPropertyNamesList = function() {
  return this.setPropertyNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UploadCsvRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadCsvRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadCsvRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadCsvRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.sp.scan_service.UploadCsvRequest.CsvLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadCsvRequest}
 */
proto.sp.scan_service.UploadCsvRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadCsvRequest;
  return proto.sp.scan_service.UploadCsvRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadCsvRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadCsvRequest}
 */
proto.sp.scan_service.UploadCsvRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.UploadCsvRequest.CsvLine;
      reader.readMessage(value,proto.sp.scan_service.UploadCsvRequest.CsvLine.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadCsvRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadCsvRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadCsvRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.UploadCsvRequest.CsvLine.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadCsvRequest.CsvLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ruleResultsList: jspb.Message.toObjectList(msg.getRuleResultsList(),
    proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadCsvRequest.CsvLine;
  return proto.sp.scan_service.UploadCsvRequest.CsvLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProperty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult;
      reader.readMessage(value,proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.deserializeBinaryFromReader);
      msg.addRuleResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadCsvRequest.CsvLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRuleResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    passed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult;
  return proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleShortName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string rule_short_name = 1;
 * @return {string}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.prototype.getRuleShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult} returns this
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.prototype.setRuleShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool passed = 2;
 * @return {boolean}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.prototype.getPassed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult} returns this
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult.prototype.setPassed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string property = 1;
 * @return {string}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.getProperty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine} returns this
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.setProperty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine} returns this
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RuleResult rule_results = 3;
 * @return {!Array<!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult>}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.getRuleResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult>} value
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine} returns this
*/
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.setRuleResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult}
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.addRuleResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.UploadCsvRequest.CsvLine.RuleResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine} returns this
 */
proto.sp.scan_service.UploadCsvRequest.CsvLine.prototype.clearRuleResultsList = function() {
  return this.setRuleResultsList([]);
};


/**
 * repeated CsvLine lines = 1;
 * @return {!Array<!proto.sp.scan_service.UploadCsvRequest.CsvLine>}
 */
proto.sp.scan_service.UploadCsvRequest.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.sp.scan_service.UploadCsvRequest.CsvLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.UploadCsvRequest.CsvLine, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.UploadCsvRequest.CsvLine>} value
 * @return {!proto.sp.scan_service.UploadCsvRequest} returns this
*/
proto.sp.scan_service.UploadCsvRequest.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.UploadCsvRequest.CsvLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.UploadCsvRequest.CsvLine}
 */
proto.sp.scan_service.UploadCsvRequest.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.UploadCsvRequest.CsvLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UploadCsvRequest} returns this
 */
proto.sp.scan_service.UploadCsvRequest.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UploadCsvResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadCsvResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadCsvResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadCsvResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobsIdsAddedList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    ruleResultsAdded: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadCsvResponse}
 */
proto.sp.scan_service.UploadCsvResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadCsvResponse;
  return proto.sp.scan_service.UploadCsvResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadCsvResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadCsvResponse}
 */
proto.sp.scan_service.UploadCsvResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanJobsIdsAdded(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRuleResultsAdded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadCsvResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadCsvResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadCsvResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadCsvResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobsIdsAddedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRuleResultsAdded();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated string scan_jobs_ids_added = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.UploadCsvResponse.prototype.getScanJobsIdsAddedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.UploadCsvResponse} returns this
 */
proto.sp.scan_service.UploadCsvResponse.prototype.setScanJobsIdsAddedList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.UploadCsvResponse} returns this
 */
proto.sp.scan_service.UploadCsvResponse.prototype.addScanJobsIdsAdded = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UploadCsvResponse} returns this
 */
proto.sp.scan_service.UploadCsvResponse.prototype.clearScanJobsIdsAddedList = function() {
  return this.setScanJobsIdsAddedList([]);
};


/**
 * optional int32 rule_results_added = 2;
 * @return {number}
 */
proto.sp.scan_service.UploadCsvResponse.prototype.getRuleResultsAdded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UploadCsvResponse} returns this
 */
proto.sp.scan_service.UploadCsvResponse.prototype.setRuleResultsAdded = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UploadExtensionResultsRequest.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadExtensionResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanMetadata: (f = msg.getScanMetadata()) && proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.toObject(includeInstance, f),
    pageResultsList: jspb.Message.toObjectList(msg.getPageResultsList(),
    sp_scan_runner_common_pb.PageSearchResult.toObject, includeInstance),
    timeline: (f = msg.getTimeline()) && sp_scan_runner_scan_runner_pb.ScanTimeline.toObject(includeInstance, f),
    ruleScoresList: jspb.Message.toObjectList(msg.getRuleScoresList(),
    proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadExtensionResultsRequest;
  return proto.sp.scan_service.UploadExtensionResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata;
      reader.readMessage(value,proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.deserializeBinaryFromReader);
      msg.setScanMetadata(value);
      break;
    case 2:
      var value = new sp_scan_runner_common_pb.PageSearchResult;
      reader.readMessage(value,sp_scan_runner_common_pb.PageSearchResult.deserializeBinaryFromReader);
      msg.addPageResults(value);
      break;
    case 3:
      var value = new sp_scan_runner_scan_runner_pb.ScanTimeline;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanTimeline.deserializeBinaryFromReader);
      msg.setTimeline(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore;
      reader.readMessage(value,proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.deserializeBinaryFromReader);
      msg.addRuleScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadExtensionResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.serializeBinaryToWriter
    );
  }
  f = message.getPageResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      sp_scan_runner_common_pb.PageSearchResult.serializeBinaryToWriter
    );
  }
  f = message.getTimeline();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_scan_runner_scan_runner_pb.ScanTimeline.serializeBinaryToWriter
    );
  }
  f = message.getRuleScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore;
  return proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleShortName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string rule_short_name = 1;
 * @return {string}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.prototype.getRuleShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.prototype.setRuleShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipAddr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 3, ""),
    jsTimezone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    propertyName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata;
  return proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setJsTimezone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getJsTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string region_id = 1;
 * @return {string}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ip_addr = 2;
 * @return {string}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.getIpAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.setIpAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string locale = 3;
 * @return {string}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string js_timezone = 4;
 * @return {string}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.getJsTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.setJsTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string property_name = 5;
 * @return {string}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ScanMetadata scan_metadata = 1;
 * @return {?proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.getScanMetadata = function() {
  return /** @type{?proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata, 1));
};


/**
 * @param {?proto.sp.scan_service.UploadExtensionResultsRequest.ScanMetadata|undefined} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
*/
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.setScanMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.clearScanMetadata = function() {
  return this.setScanMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.hasScanMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated sp.scan_runner.PageSearchResult page_results = 2;
 * @return {!Array<!proto.sp.scan_runner.PageSearchResult>}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.getPageResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.PageSearchResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_scan_runner_common_pb.PageSearchResult, 2));
};


/**
 * @param {!Array<!proto.sp.scan_runner.PageSearchResult>} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
*/
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.setPageResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_runner.PageSearchResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.PageSearchResult}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.addPageResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_runner.PageSearchResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.clearPageResultsList = function() {
  return this.setPageResultsList([]);
};


/**
 * optional sp.scan_runner.ScanTimeline timeline = 3;
 * @return {?proto.sp.scan_runner.ScanTimeline}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.getTimeline = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_scan_runner_pb.ScanTimeline, 3));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline|undefined} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
*/
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.setTimeline = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.clearTimeline = function() {
  return this.setTimeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.hasTimeline = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated RuleScore rule_scores = 4;
 * @return {!Array<!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore>}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.getRuleScoresList = function() {
  return /** @type{!Array<!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore>} value
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
*/
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.setRuleScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore}
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.addRuleScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sp.scan_service.UploadExtensionResultsRequest.RuleScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UploadExtensionResultsRequest} returns this
 */
proto.sp.scan_service.UploadExtensionResultsRequest.prototype.clearRuleScoresList = function() {
  return this.setRuleScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UploadExtensionResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UploadExtensionResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UploadExtensionResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UploadExtensionResultsResponse}
 */
proto.sp.scan_service.UploadExtensionResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UploadExtensionResultsResponse;
  return proto.sp.scan_service.UploadExtensionResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UploadExtensionResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UploadExtensionResultsResponse}
 */
proto.sp.scan_service.UploadExtensionResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UploadExtensionResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UploadExtensionResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UploadExtensionResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UploadExtensionResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.SortOffset.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.SortOffset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.SortOffset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.SortOffset.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.SortOffset}
 */
proto.sp.scan_service.SortOffset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.SortOffset;
  return proto.sp.scan_service.SortOffset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.SortOffset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.SortOffset}
 */
proto.sp.scan_service.SortOffset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.SortOffset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.SortOffset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.SortOffset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.SortOffset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.sp.scan_service.SortOffset.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.SortOffset} returns this
 */
proto.sp.scan_service.SortOffset.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes data = 2;
 * @return {string}
 */
proto.sp.scan_service.SortOffset.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes data = 2;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.sp.scan_service.SortOffset.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.sp.scan_service.SortOffset.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sp.scan_service.SortOffset} returns this
 */
proto.sp.scan_service.SortOffset.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.PagingPayload.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PagingPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PagingPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PagingPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PagingPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOffsetsList: jspb.Message.toObjectList(msg.getSortOffsetsList(),
    proto.sp.scan_service.SortOffset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PagingPayload}
 */
proto.sp.scan_service.PagingPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PagingPayload;
  return proto.sp.scan_service.PagingPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PagingPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PagingPayload}
 */
proto.sp.scan_service.PagingPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.SortOffset;
      reader.readMessage(value,proto.sp.scan_service.SortOffset.deserializeBinaryFromReader);
      msg.addSortOffsets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PagingPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PagingPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PagingPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PagingPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOffsetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.SortOffset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SortOffset sort_offsets = 1;
 * @return {!Array<!proto.sp.scan_service.SortOffset>}
 */
proto.sp.scan_service.PagingPayload.prototype.getSortOffsetsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.SortOffset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.SortOffset, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.SortOffset>} value
 * @return {!proto.sp.scan_service.PagingPayload} returns this
*/
proto.sp.scan_service.PagingPayload.prototype.setSortOffsetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.SortOffset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.SortOffset}
 */
proto.sp.scan_service.PagingPayload.prototype.addSortOffsets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.SortOffset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.PagingPayload} returns this
 */
proto.sp.scan_service.PagingPayload.prototype.clearSortOffsetsList = function() {
  return this.setSortOffsetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySetScoreSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySetScoreSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySetScoreSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scoresByTypeMap: (f = msg.getScoresByTypeMap()) ? f.toObject(includeInstance, proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.toObject) : [],
    scoresByRuleIdMap: (f = msg.getScoresByRuleIdMap()) ? f.toObject(includeInstance, proto.sp.scan_service.PropertySetScoreSummary.RuleScore.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary}
 */
proto.sp.scan_service.PropertySetScoreSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySetScoreSummary;
  return proto.sp.scan_service.PropertySetScoreSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySetScoreSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary}
 */
proto.sp.scan_service.PropertySetScoreSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = msg.getScoresByTypeMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.deserializeBinaryFromReader, 0, new proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore());
         });
      break;
    case 3:
      var value = msg.getScoresByRuleIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.sp.scan_service.PropertySetScoreSummary.RuleScore.deserializeBinaryFromReader, "", new proto.sp.scan_service.PropertySetScoreSummary.RuleScore());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySetScoreSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySetScoreSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySetScoreSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getScoresByTypeMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.serializeBinaryToWriter);
  }
  f = message.getScoresByRuleIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.sp.scan_service.PropertySetScoreSummary.RuleScore.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.PropertySetScoreSummary.ScoreSummaryType = {
  SCORE_SUMMARY_TYPE_UNSET: 0,
  SCORE_SUMMARY_TYPE_PASS_ALL: 1,
  SCORE_SUMMARY_TYPE_SAFE: 2,
  SCORE_SUMMARY_TYPE_UNSAFE: 3,
  SCORE_SUMMARY_TYPE_MEET_THRESHOLD: 4,
  SCORE_SUMMARY_TYPE_TOTAL: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalScore: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore}
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore;
  return proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore}
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalScore();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint32 count = 1;
 * @return {number}
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 total_score = 2;
 * @return {number}
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.prototype.getTotalScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore.prototype.setTotalScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySetScoreSummary.RuleScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    passed: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore}
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySetScoreSummary.RuleScore;
  return proto.sp.scan_service.PropertySetScoreSummary.RuleScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore}
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPassed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySetScoreSummary.RuleScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPassed();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 count = 1;
 * @return {number}
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 passed = 2;
 * @return {number}
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.prototype.getPassed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySetScoreSummary.RuleScore} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.RuleScore.prototype.setPassed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_count = 1;
 * @return {number}
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySetScoreSummary} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<int32, PropertySetScore> scores_by_type = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore>}
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.getScoresByTypeMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.sp.scan_service.PropertySetScoreSummary.PropertySetScore));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.clearScoresByTypeMap = function() {
  this.getScoresByTypeMap().clear();
  return this;};


/**
 * map<string, RuleScore> scores_by_rule_id = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sp.scan_service.PropertySetScoreSummary.RuleScore>}
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.getScoresByRuleIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sp.scan_service.PropertySetScoreSummary.RuleScore>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.sp.scan_service.PropertySetScoreSummary.RuleScore));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sp.scan_service.PropertySetScoreSummary} returns this
 */
proto.sp.scan_service.PropertySetScoreSummary.prototype.clearScoresByRuleIdMap = function() {
  this.getScoresByRuleIdMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountDomainSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertySetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountDomainSummaryRequest;
  return proto.sp.scan_service.GetAccountDomainSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountDomainSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 account_id = 1;
 * @return {number}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.clearRegionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.hasRegionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string property_set_id = 3;
 * @return {string}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryRequest} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.clearPropertySetId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountDomainSummaryRequest.prototype.hasPropertySetId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountDomainSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    domainsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryResponse}
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountDomainSummaryResponse;
  return proto.sp.scan_service.GetAccountDomainSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryResponse}
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountDomainSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryResponse} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string domains = 2;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.getDomainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryResponse} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.setDomainsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryResponse} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.addDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountDomainSummaryResponse} returns this
 */
proto.sp.scan_service.GetAccountDomainSummaryResponse.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.DeploymentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.DeploymentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.DeploymentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.DeploymentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    scanRunnerImageName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.DeploymentSettings}
 */
proto.sp.scan_service.DeploymentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.DeploymentSettings;
  return proto.sp.scan_service.DeploymentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.DeploymentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.DeploymentSettings}
 */
proto.sp.scan_service.DeploymentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRunnerImageName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.DeploymentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.DeploymentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.DeploymentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.DeploymentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getScanRunnerImageName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.DeploymentSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.DeploymentSettings} returns this
 */
proto.sp.scan_service.DeploymentSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.DeploymentSettings.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.DeploymentSettings} returns this
*/
proto.sp.scan_service.DeploymentSettings.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.DeploymentSettings} returns this
 */
proto.sp.scan_service.DeploymentSettings.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.DeploymentSettings.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string scan_runner_image_name = 3;
 * @return {string}
 */
proto.sp.scan_service.DeploymentSettings.prototype.getScanRunnerImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.DeploymentSettings} returns this
 */
proto.sp.scan_service.DeploymentSettings.prototype.setScanRunnerImageName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpsertDeploymentSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRunnerImageName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsRequest}
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpsertDeploymentSettingsRequest;
  return proto.sp.scan_service.UpsertDeploymentSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsRequest}
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRunnerImageName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpsertDeploymentSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_runner_image_name = 1;
 * @return {string}
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.prototype.getScanRunnerImageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} returns this
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.prototype.setScanRunnerImageName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} returns this
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.prototype.clearScanRunnerImageName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpsertDeploymentSettingsRequest.prototype.hasScanRunnerImageName = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpsertDeploymentSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsResponse}
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpsertDeploymentSettingsResponse;
  return proto.sp.scan_service.UpsertDeploymentSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsResponse}
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpsertDeploymentSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UpsertDeploymentSettingsResponse} returns this
 */
proto.sp.scan_service.UpsertDeploymentSettingsResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetDeploymentSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetDeploymentSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetDeploymentSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetDeploymentSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetDeploymentSettingsRequest}
 */
proto.sp.scan_service.GetDeploymentSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetDeploymentSettingsRequest;
  return proto.sp.scan_service.GetDeploymentSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetDeploymentSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetDeploymentSettingsRequest}
 */
proto.sp.scan_service.GetDeploymentSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetDeploymentSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetDeploymentSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetDeploymentSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetDeploymentSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetDeploymentSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetDeploymentSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deploymentSettings: (f = msg.getDeploymentSettings()) && proto.sp.scan_service.DeploymentSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetDeploymentSettingsResponse}
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetDeploymentSettingsResponse;
  return proto.sp.scan_service.GetDeploymentSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetDeploymentSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetDeploymentSettingsResponse}
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.DeploymentSettings;
      reader.readMessage(value,proto.sp.scan_service.DeploymentSettings.deserializeBinaryFromReader);
      msg.setDeploymentSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetDeploymentSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetDeploymentSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeploymentSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.DeploymentSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeploymentSettings deployment_settings = 1;
 * @return {?proto.sp.scan_service.DeploymentSettings}
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.prototype.getDeploymentSettings = function() {
  return /** @type{?proto.sp.scan_service.DeploymentSettings} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.DeploymentSettings, 1));
};


/**
 * @param {?proto.sp.scan_service.DeploymentSettings|undefined} value
 * @return {!proto.sp.scan_service.GetDeploymentSettingsResponse} returns this
*/
proto.sp.scan_service.GetDeploymentSettingsResponse.prototype.setDeploymentSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetDeploymentSettingsResponse} returns this
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.prototype.clearDeploymentSettings = function() {
  return this.setDeploymentSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetDeploymentSettingsResponse.prototype.hasDeploymentSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PromoteScanJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PromoteScanJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PromoteScanJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PromoteScanJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    promoted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PromoteScanJobRequest}
 */
proto.sp.scan_service.PromoteScanJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PromoteScanJobRequest;
  return proto.sp.scan_service.PromoteScanJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PromoteScanJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PromoteScanJobRequest}
 */
proto.sp.scan_service.PromoteScanJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromoted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PromoteScanJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PromoteScanJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PromoteScanJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PromoteScanJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPromoted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.PromoteScanJobRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PromoteScanJobRequest} returns this
 */
proto.sp.scan_service.PromoteScanJobRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool promoted = 2;
 * @return {boolean}
 */
proto.sp.scan_service.PromoteScanJobRequest.prototype.getPromoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.PromoteScanJobRequest} returns this
 */
proto.sp.scan_service.PromoteScanJobRequest.prototype.setPromoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PromoteScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PromoteScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PromoteScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PromoteScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PromoteScanJobResponse}
 */
proto.sp.scan_service.PromoteScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PromoteScanJobResponse;
  return proto.sp.scan_service.PromoteScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PromoteScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PromoteScanJobResponse}
 */
proto.sp.scan_service.PromoteScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PromoteScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PromoteScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PromoteScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PromoteScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.PromoteScanJobResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PromoteScanJobResponse} returns this
 */
proto.sp.scan_service.PromoteScanJobResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PrioritizeScanJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PrioritizeScanJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PrioritizeScanJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PrioritizeScanJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PrioritizeScanJobRequest}
 */
proto.sp.scan_service.PrioritizeScanJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PrioritizeScanJobRequest;
  return proto.sp.scan_service.PrioritizeScanJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PrioritizeScanJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PrioritizeScanJobRequest}
 */
proto.sp.scan_service.PrioritizeScanJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PrioritizeScanJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PrioritizeScanJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PrioritizeScanJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PrioritizeScanJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.PrioritizeScanJobRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PrioritizeScanJobRequest} returns this
 */
proto.sp.scan_service.PrioritizeScanJobRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 priority = 2;
 * @return {number}
 */
proto.sp.scan_service.PrioritizeScanJobRequest.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PrioritizeScanJobRequest} returns this
 */
proto.sp.scan_service.PrioritizeScanJobRequest.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PrioritizeScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PrioritizeScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PrioritizeScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PrioritizeScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PrioritizeScanJobResponse}
 */
proto.sp.scan_service.PrioritizeScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PrioritizeScanJobResponse;
  return proto.sp.scan_service.PrioritizeScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PrioritizeScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PrioritizeScanJobResponse}
 */
proto.sp.scan_service.PrioritizeScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PrioritizeScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PrioritizeScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PrioritizeScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PrioritizeScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.PrioritizeScanJobResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PrioritizeScanJobResponse} returns this
 */
proto.sp.scan_service.PrioritizeScanJobResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.PropertyDetailScore.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertyDetailScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertyDetailScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertyDetailScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isSafe: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    score: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ruleScoresList: jspb.Message.toObjectList(msg.getRuleScoresList(),
    proto.sp.scan_service.PropertyDetailScore.RuleScore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertyDetailScore}
 */
proto.sp.scan_service.PropertyDetailScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertyDetailScore;
  return proto.sp.scan_service.PropertyDetailScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertyDetailScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertyDetailScore}
 */
proto.sp.scan_service.PropertyDetailScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSafe(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScore(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.PropertyDetailScore.RuleScore;
      reader.readMessage(value,proto.sp.scan_service.PropertyDetailScore.RuleScore.deserializeBinaryFromReader);
      msg.addRuleScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertyDetailScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertyDetailScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertyDetailScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsSafe();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getRuleScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sp.scan_service.PropertyDetailScore.RuleScore.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertyDetailScore.RuleScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertyDetailScore.RuleScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    passed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertyDetailScore.RuleScore}
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertyDetailScore.RuleScore;
  return proto.sp.scan_service.PropertyDetailScore.RuleScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertyDetailScore.RuleScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertyDetailScore.RuleScore}
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleShortName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertyDetailScore.RuleScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertyDetailScore.RuleScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string scan_rule_short_name = 1;
 * @return {string}
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.prototype.getScanRuleShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertyDetailScore.RuleScore} returns this
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.prototype.setScanRuleShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool passed = 2;
 * @return {boolean}
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.prototype.getPassed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.PropertyDetailScore.RuleScore} returns this
 */
proto.sp.scan_service.PropertyDetailScore.RuleScore.prototype.setPassed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertyDetailScore} returns this
 */
proto.sp.scan_service.PropertyDetailScore.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_safe = 2;
 * @return {boolean}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.getIsSafe = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.PropertyDetailScore} returns this
 */
proto.sp.scan_service.PropertyDetailScore.prototype.setIsSafe = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint32 score = 3;
 * @return {number}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertyDetailScore} returns this
 */
proto.sp.scan_service.PropertyDetailScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated RuleScore rule_scores = 4;
 * @return {!Array<!proto.sp.scan_service.PropertyDetailScore.RuleScore>}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.getRuleScoresList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertyDetailScore.RuleScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertyDetailScore.RuleScore, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertyDetailScore.RuleScore>} value
 * @return {!proto.sp.scan_service.PropertyDetailScore} returns this
*/
proto.sp.scan_service.PropertyDetailScore.prototype.setRuleScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sp.scan_service.PropertyDetailScore.RuleScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertyDetailScore.RuleScore}
 */
proto.sp.scan_service.PropertyDetailScore.prototype.addRuleScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sp.scan_service.PropertyDetailScore.RuleScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.PropertyDetailScore} returns this
 */
proto.sp.scan_service.PropertyDetailScore.prototype.clearRuleScoresList = function() {
  return this.setRuleScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScoresForPropertySetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScoresForPropertySetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeDetails: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScoresForPropertySetRequest}
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScoresForPropertySetRequest;
  return proto.sp.scan_service.GetScoresForPropertySetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScoresForPropertySetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScoresForPropertySetRequest}
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScoresForPropertySetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScoresForPropertySetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeDetails();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string property_set_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScoresForPropertySetRequest} returns this
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_details = 2;
 * @return {boolean}
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.prototype.getIncludeDetails = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.GetScoresForPropertySetRequest} returns this
 */
proto.sp.scan_service.GetScoresForPropertySetRequest.prototype.setIncludeDetails = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScoresForPropertySetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScoresForPropertySetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: (f = msg.getSummary()) && proto.sp.scan_service.PropertySetScoreSummary.toObject(includeInstance, f),
    detailScoresList: jspb.Message.toObjectList(msg.getDetailScoresList(),
    proto.sp.scan_service.PropertyDetailScore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScoresForPropertySetResponse}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScoresForPropertySetResponse;
  return proto.sp.scan_service.GetScoresForPropertySetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScoresForPropertySetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScoresForPropertySetResponse}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.PropertySetScoreSummary;
      reader.readMessage(value,proto.sp.scan_service.PropertySetScoreSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.PropertyDetailScore;
      reader.readMessage(value,proto.sp.scan_service.PropertyDetailScore.deserializeBinaryFromReader);
      msg.addDetailScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScoresForPropertySetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScoresForPropertySetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.PropertySetScoreSummary.serializeBinaryToWriter
    );
  }
  f = message.getDetailScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.PropertyDetailScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional PropertySetScoreSummary summary = 1;
 * @return {?proto.sp.scan_service.PropertySetScoreSummary}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.getSummary = function() {
  return /** @type{?proto.sp.scan_service.PropertySetScoreSummary} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.PropertySetScoreSummary, 1));
};


/**
 * @param {?proto.sp.scan_service.PropertySetScoreSummary|undefined} value
 * @return {!proto.sp.scan_service.GetScoresForPropertySetResponse} returns this
*/
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScoresForPropertySetResponse} returns this
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PropertyDetailScore detail_scores = 2;
 * @return {!Array<!proto.sp.scan_service.PropertyDetailScore>}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.getDetailScoresList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertyDetailScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertyDetailScore, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertyDetailScore>} value
 * @return {!proto.sp.scan_service.GetScoresForPropertySetResponse} returns this
*/
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.setDetailScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.PropertyDetailScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertyDetailScore}
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.addDetailScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.PropertyDetailScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScoresForPropertySetResponse} returns this
 */
proto.sp.scan_service.GetScoresForPropertySetResponse.prototype.clearDetailScoresList = function() {
  return this.setDetailScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.VerifyApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.VerifyApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.VerifyApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.VerifyApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKeyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiKeySecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.VerifyApiKeyRequest}
 */
proto.sp.scan_service.VerifyApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.VerifyApiKeyRequest;
  return proto.sp.scan_service.VerifyApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.VerifyApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.VerifyApiKeyRequest}
 */
proto.sp.scan_service.VerifyApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKeyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKeySecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.VerifyApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.VerifyApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.VerifyApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.VerifyApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKeyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiKeySecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string api_key_id = 1;
 * @return {string}
 */
proto.sp.scan_service.VerifyApiKeyRequest.prototype.getApiKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.VerifyApiKeyRequest} returns this
 */
proto.sp.scan_service.VerifyApiKeyRequest.prototype.setApiKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_key_secret = 2;
 * @return {string}
 */
proto.sp.scan_service.VerifyApiKeyRequest.prototype.getApiKeySecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.VerifyApiKeyRequest} returns this
 */
proto.sp.scan_service.VerifyApiKeyRequest.prototype.setApiKeySecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.VerifyApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.VerifyApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.VerifyApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.VerifyApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.VerifyApiKeyResponse}
 */
proto.sp.scan_service.VerifyApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.VerifyApiKeyResponse;
  return proto.sp.scan_service.VerifyApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.VerifyApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.VerifyApiKeyResponse}
 */
proto.sp.scan_service.VerifyApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.VerifyApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.VerifyApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.VerifyApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.VerifyApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 account_id = 1;
 * @return {number}
 */
proto.sp.scan_service.VerifyApiKeyResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.VerifyApiKeyResponse} returns this
 */
proto.sp.scan_service.VerifyApiKeyResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.VerifyApiKeyResponse} returns this
 */
proto.sp.scan_service.VerifyApiKeyResponse.prototype.clearAccountId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.VerifyApiKeyResponse.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GenerateApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GenerateApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GenerateApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GenerateApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GenerateApiKeyRequest}
 */
proto.sp.scan_service.GenerateApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GenerateApiKeyRequest;
  return proto.sp.scan_service.GenerateApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GenerateApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GenerateApiKeyRequest}
 */
proto.sp.scan_service.GenerateApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GenerateApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GenerateApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GenerateApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GenerateApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 account_id = 1;
 * @return {number}
 */
proto.sp.scan_service.GenerateApiKeyRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GenerateApiKeyRequest} returns this
 */
proto.sp.scan_service.GenerateApiKeyRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GenerateApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GenerateApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GenerateApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GenerateApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKeyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiKeySecret: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GenerateApiKeyResponse}
 */
proto.sp.scan_service.GenerateApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GenerateApiKeyResponse;
  return proto.sp.scan_service.GenerateApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GenerateApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GenerateApiKeyResponse}
 */
proto.sp.scan_service.GenerateApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKeyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKeySecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GenerateApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GenerateApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GenerateApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GenerateApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKeyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiKeySecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string api_key_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GenerateApiKeyResponse.prototype.getApiKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GenerateApiKeyResponse} returns this
 */
proto.sp.scan_service.GenerateApiKeyResponse.prototype.setApiKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_key_secret = 2;
 * @return {string}
 */
proto.sp.scan_service.GenerateApiKeyResponse.prototype.getApiKeySecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GenerateApiKeyResponse} returns this
 */
proto.sp.scan_service.GenerateApiKeyResponse.prototype.setApiKeySecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RevokeApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RevokeApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RevokeApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RevokeApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    apiKeyId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RevokeApiKeyRequest}
 */
proto.sp.scan_service.RevokeApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RevokeApiKeyRequest;
  return proto.sp.scan_service.RevokeApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RevokeApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RevokeApiKeyRequest}
 */
proto.sp.scan_service.RevokeApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RevokeApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RevokeApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RevokeApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RevokeApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getApiKeyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 account_id = 1;
 * @return {number}
 */
proto.sp.scan_service.RevokeApiKeyRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.RevokeApiKeyRequest} returns this
 */
proto.sp.scan_service.RevokeApiKeyRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string api_key_id = 2;
 * @return {string}
 */
proto.sp.scan_service.RevokeApiKeyRequest.prototype.getApiKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.RevokeApiKeyRequest} returns this
 */
proto.sp.scan_service.RevokeApiKeyRequest.prototype.setApiKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RevokeApiKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RevokeApiKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RevokeApiKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RevokeApiKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RevokeApiKeyResponse}
 */
proto.sp.scan_service.RevokeApiKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RevokeApiKeyResponse;
  return proto.sp.scan_service.RevokeApiKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RevokeApiKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RevokeApiKeyResponse}
 */
proto.sp.scan_service.RevokeApiKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RevokeApiKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RevokeApiKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RevokeApiKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RevokeApiKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.RevokeApiKeyResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.RevokeApiKeyResponse} returns this
 */
proto.sp.scan_service.RevokeApiKeyResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTrainingDataInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTrainingDataInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTrainingDataInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTrainingDataInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    artifactId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTrainingDataInputRequest}
 */
proto.sp.scan_service.GetTrainingDataInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTrainingDataInputRequest;
  return proto.sp.scan_service.GetTrainingDataInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTrainingDataInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTrainingDataInputRequest}
 */
proto.sp.scan_service.GetTrainingDataInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArtifactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTrainingDataInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTrainingDataInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTrainingDataInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTrainingDataInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArtifactId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string artifact_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetTrainingDataInputRequest.prototype.getArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetTrainingDataInputRequest} returns this
 */
proto.sp.scan_service.GetTrainingDataInputRequest.prototype.setArtifactId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetRandomTrainingDataInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertySetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetRandomTrainingDataInputRequest}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetRandomTrainingDataInputRequest;
  return proto.sp.scan_service.GetRandomTrainingDataInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetRandomTrainingDataInputRequest}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetRandomTrainingDataInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string region_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} returns this
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} returns this
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.clearRegionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.hasRegionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string property_set_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} returns this
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} returns this
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.clearPropertySetId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetRandomTrainingDataInputRequest.prototype.hasPropertySetId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetTrainingDataInputResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTrainingDataInputResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTrainingDataInputResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTrainingDataInputResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJob: (f = msg.getScanJob()) && sp_scan_service_scan_service_pb.ScanJob.toObject(includeInstance, f),
    scanArtifactPrivacyPolicyText: (f = msg.getScanArtifactPrivacyPolicyText()) && sp_scan_runner_scan_runner_pb.ScanArtifact.toObject(includeInstance, f),
    scanArtifactPrivacyPolicyImagesList: jspb.Message.toObjectList(msg.getScanArtifactPrivacyPolicyImagesList(),
    sp_scan_runner_scan_runner_pb.ScanArtifact.toObject, includeInstance),
    scanJobArtifact: (f = msg.getScanJobArtifact()) && sp_scan_service_scan_service_pb.ScanJobArtifact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTrainingDataInputResponse;
  return proto.sp.scan_service.GetTrainingDataInputResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTrainingDataInputResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_service_scan_service_pb.ScanJob;
      reader.readMessage(value,sp_scan_service_scan_service_pb.ScanJob.deserializeBinaryFromReader);
      msg.setScanJob(value);
      break;
    case 2:
      var value = new sp_scan_runner_scan_runner_pb.ScanArtifact;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanArtifact.deserializeBinaryFromReader);
      msg.setScanArtifactPrivacyPolicyText(value);
      break;
    case 3:
      var value = new sp_scan_runner_scan_runner_pb.ScanArtifact;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanArtifact.deserializeBinaryFromReader);
      msg.addScanArtifactPrivacyPolicyImages(value);
      break;
    case 100:
      var value = new sp_scan_service_scan_service_pb.ScanJobArtifact;
      reader.readMessage(value,sp_scan_service_scan_service_pb.ScanJobArtifact.deserializeBinaryFromReader);
      msg.setScanJobArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTrainingDataInputResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTrainingDataInputResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTrainingDataInputResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_scan_service_scan_service_pb.ScanJob.serializeBinaryToWriter
    );
  }
  f = message.getScanArtifactPrivacyPolicyText();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_scan_runner_scan_runner_pb.ScanArtifact.serializeBinaryToWriter
    );
  }
  f = message.getScanArtifactPrivacyPolicyImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      sp_scan_runner_scan_runner_pb.ScanArtifact.serializeBinaryToWriter
    );
  }
  f = message.getScanJobArtifact();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      sp_scan_service_scan_service_pb.ScanJobArtifact.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanJob scan_job = 1;
 * @return {?proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.getScanJob = function() {
  return /** @type{?proto.sp.scan_service.ScanJob} */ (
    jspb.Message.getWrapperField(this, sp_scan_service_scan_service_pb.ScanJob, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanJob|undefined} value
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
*/
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.setScanJob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.clearScanJob = function() {
  return this.setScanJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.hasScanJob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sp.scan_runner.ScanArtifact scan_artifact_privacy_policy_text = 2;
 * @return {?proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.getScanArtifactPrivacyPolicyText = function() {
  return /** @type{?proto.sp.scan_runner.ScanArtifact} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_scan_runner_pb.ScanArtifact, 2));
};


/**
 * @param {?proto.sp.scan_runner.ScanArtifact|undefined} value
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
*/
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.setScanArtifactPrivacyPolicyText = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.clearScanArtifactPrivacyPolicyText = function() {
  return this.setScanArtifactPrivacyPolicyText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.hasScanArtifactPrivacyPolicyText = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated sp.scan_runner.ScanArtifact scan_artifact_privacy_policy_images = 3;
 * @return {!Array<!proto.sp.scan_runner.ScanArtifact>}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.getScanArtifactPrivacyPolicyImagesList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ScanArtifact>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_scan_runner_scan_runner_pb.ScanArtifact, 3));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanArtifact>} value
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
*/
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.setScanArtifactPrivacyPolicyImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.addScanArtifactPrivacyPolicyImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_runner.ScanArtifact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.clearScanArtifactPrivacyPolicyImagesList = function() {
  return this.setScanArtifactPrivacyPolicyImagesList([]);
};


/**
 * optional ScanJobArtifact scan_job_artifact = 100;
 * @return {?proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.getScanJobArtifact = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact} */ (
    jspb.Message.getWrapperField(this, sp_scan_service_scan_service_pb.ScanJobArtifact, 100));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact|undefined} value
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
*/
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.setScanJobArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTrainingDataInputResponse} returns this
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.clearScanJobArtifact = function() {
  return this.setScanJobArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTrainingDataInputResponse.prototype.hasScanJobArtifact = function() {
  return jspb.Message.getField(this, 100) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateTrainingDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateTrainingDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateTrainingDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobArtifactId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scanJobTrainingData: (f = msg.getScanJobTrainingData()) && sp_scan_service_scan_service_pb.ScanJobArtifact.ScanJobTrainingData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateTrainingDataRequest}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateTrainingDataRequest;
  return proto.sp.scan_service.UpdateTrainingDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateTrainingDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateTrainingDataRequest}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobArtifactId(value);
      break;
    case 3:
      var value = new sp_scan_service_scan_service_pb.ScanJobArtifact.ScanJobTrainingData;
      reader.readMessage(value,sp_scan_service_scan_service_pb.ScanJobArtifact.ScanJobTrainingData.deserializeBinaryFromReader);
      msg.setScanJobTrainingData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateTrainingDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateTrainingDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateTrainingDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobArtifactId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScanJobTrainingData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_scan_service_scan_service_pb.ScanJobArtifact.ScanJobTrainingData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string scan_job_artifact_id = 2;
 * @return {string}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.getScanJobArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateTrainingDataRequest} returns this
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.setScanJobArtifactId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ScanJobArtifact.ScanJobTrainingData scan_job_training_data = 3;
 * @return {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.getScanJobTrainingData = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} */ (
    jspb.Message.getWrapperField(this, sp_scan_service_scan_service_pb.ScanJobArtifact.ScanJobTrainingData, 3));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData|undefined} value
 * @return {!proto.sp.scan_service.UpdateTrainingDataRequest} returns this
*/
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.setScanJobTrainingData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdateTrainingDataRequest} returns this
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.clearScanJobTrainingData = function() {
  return this.setScanJobTrainingData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateTrainingDataRequest.prototype.hasScanJobTrainingData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateTrainingDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateTrainingDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateTrainingDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateTrainingDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateTrainingDataResponse}
 */
proto.sp.scan_service.UpdateTrainingDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateTrainingDataResponse;
  return proto.sp.scan_service.UpdateTrainingDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateTrainingDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateTrainingDataResponse}
 */
proto.sp.scan_service.UpdateTrainingDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateTrainingDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateTrainingDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateTrainingDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateTrainingDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.UpdateTrainingDataResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UpdateTrainingDataResponse} returns this
 */
proto.sp.scan_service.UpdateTrainingDataResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertySetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetRandomScanJobPendingGatingRequest;
  return proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string region_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} returns this
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} returns this
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.clearRegionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.hasRegionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string property_set_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} returns this
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} returns this
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.clearPropertySetId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingRequest.prototype.hasPropertySetId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJob: (f = msg.getScanJob()) && sp_scan_service_scan_service_pb.ScanJob.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetRandomScanJobPendingGatingResponse;
  return proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_service_scan_service_pb.ScanJob;
      reader.readMessage(value,sp_scan_service_scan_service_pb.ScanJob.deserializeBinaryFromReader);
      msg.setScanJob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_scan_service_scan_service_pb.ScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanJob scan_job = 1;
 * @return {?proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.prototype.getScanJob = function() {
  return /** @type{?proto.sp.scan_service.ScanJob} */ (
    jspb.Message.getWrapperField(this, sp_scan_service_scan_service_pb.ScanJob, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanJob|undefined} value
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse} returns this
*/
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.prototype.setScanJob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse} returns this
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.prototype.clearScanJob = function() {
  return this.setScanJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.prototype.hasScanJob = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CorrectScanJobResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correctedResultsList: jspb.Message.toObjectList(msg.getCorrectedResultsList(),
    proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.toObject, includeInstance),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CorrectScanJobResultsRequest;
  return proto.sp.scan_service.CorrectScanJobResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult;
      reader.readMessage(value,proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.deserializeBinaryFromReader);
      msg.addCorrectedResults(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CorrectScanJobResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrectedResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    passed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult;
  return proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleShortName(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassed(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string rule_short_name = 1;
 * @return {string}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.getRuleShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.setRuleShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool passed = 2;
 * @return {boolean}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.getPassed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.setPassed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.clearComment = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult.prototype.hasComment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated CorrectedResult corrected_results = 2;
 * @return {!Array<!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult>}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.getCorrectedResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult>} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest} returns this
*/
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.setCorrectedResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.addCorrectedResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.CorrectScanJobResultsRequest.CorrectedResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.clearCorrectedResultsList = function() {
  return this.setCorrectedResultsList([]);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsRequest} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.clearComment = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CorrectScanJobResultsRequest.prototype.hasComment = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CorrectScanJobResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CorrectScanJobResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gatingId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsResponse}
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CorrectScanJobResultsResponse;
  return proto.sp.scan_service.CorrectScanJobResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CorrectScanJobResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CorrectScanJobResultsResponse}
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CorrectScanJobResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CorrectScanJobResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGatingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsResponse} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string gating_id = 2;
 * @return {string}
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.prototype.getGatingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CorrectScanJobResultsResponse} returns this
 */
proto.sp.scan_service.CorrectScanJobResultsResponse.prototype.setGatingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RebuildPropertySnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsRequest}
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RebuildPropertySnapshotsRequest;
  return proto.sp.scan_service.RebuildPropertySnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsRequest}
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RebuildPropertySnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 limit = 1;
 * @return {number}
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RebuildPropertySnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsResponse}
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RebuildPropertySnapshotsResponse;
  return proto.sp.scan_service.RebuildPropertySnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsResponse}
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RebuildPropertySnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string updated = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.prototype.getUpdatedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsResponse} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.prototype.setUpdatedList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsResponse} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.prototype.addUpdated = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotsResponse} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotsResponse.prototype.clearUpdatedList = function() {
  return this.setUpdatedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RebuildPropertySnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cacheTime: (f = msg.getCacheTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotRequest}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RebuildPropertySnapshotRequest;
  return proto.sp.scan_service.RebuildPropertySnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotRequest}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCacheTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RebuildPropertySnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCacheTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotRequest} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotRequest} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp cache_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.getCacheTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotRequest} returns this
*/
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.setCacheTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotRequest} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.clearCacheTime = function() {
  return this.setCacheTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.RebuildPropertySnapshotRequest.prototype.hasCacheTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySnapshotId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest}
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RebuildPropertySnapshotByIdRequest;
  return proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest}
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySnapshotId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySnapshotId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string property_snapshot_id = 1;
 * @return {string}
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.prototype.getPropertySnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotByIdRequest.prototype.setPropertySnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RebuildPropertySnapshotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySnapshotId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotResponse}
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RebuildPropertySnapshotResponse;
  return proto.sp.scan_service.RebuildPropertySnapshotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotResponse}
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySnapshotId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RebuildPropertySnapshotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySnapshotId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string property_snapshot_id = 1;
 * @return {string}
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.prototype.getPropertySnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.RebuildPropertySnapshotResponse} returns this
 */
proto.sp.scan_service.RebuildPropertySnapshotResponse.prototype.setPropertySnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputePropertySnapshotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputePropertySnapshotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cacheTime: (f = msg.getCacheTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotRequest}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputePropertySnapshotRequest;
  return proto.sp.scan_service.ComputePropertySnapshotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputePropertySnapshotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotRequest}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCacheTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputePropertySnapshotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputePropertySnapshotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCacheTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComputePropertySnapshotRequest} returns this
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComputePropertySnapshotRequest} returns this
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp cache_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.getCacheTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.ComputePropertySnapshotRequest} returns this
*/
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.setCacheTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotRequest} returns this
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.clearCacheTime = function() {
  return this.setCacheTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputePropertySnapshotRequest.prototype.hasCacheTime = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputePropertySnapshotResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputePropertySnapshotResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && sp_scan_service_scan_service_pb.PropertySnapshot.Data.toObject(includeInstance, f),
    artifactId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotResponse}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputePropertySnapshotResponse;
  return proto.sp.scan_service.ComputePropertySnapshotResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputePropertySnapshotResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotResponse}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_service_scan_service_pb.PropertySnapshot.Data;
      reader.readMessage(value,sp_scan_service_scan_service_pb.PropertySnapshot.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArtifactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputePropertySnapshotResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputePropertySnapshotResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_scan_service_scan_service_pb.PropertySnapshot.Data.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional PropertySnapshot.Data data = 1;
 * @return {?proto.sp.scan_service.PropertySnapshot.Data}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.getData = function() {
  return /** @type{?proto.sp.scan_service.PropertySnapshot.Data} */ (
    jspb.Message.getWrapperField(this, sp_scan_service_scan_service_pb.PropertySnapshot.Data, 1));
};


/**
 * @param {?proto.sp.scan_service.PropertySnapshot.Data|undefined} value
 * @return {!proto.sp.scan_service.ComputePropertySnapshotResponse} returns this
*/
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotResponse} returns this
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string artifact_id = 2;
 * @return {string}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.getArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComputePropertySnapshotResponse} returns this
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.setArtifactId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ComputePropertySnapshotResponse} returns this
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.clearArtifactId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputePropertySnapshotResponse.prototype.hasArtifactId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputePropertyStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cacheTime: (f = msg.getCacheTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputePropertyStatisticsRequest;
  return proto.sp.scan_service.ComputePropertyStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCacheTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputePropertyStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCacheTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest} returns this
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest} returns this
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest} returns this
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.clearRegionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.hasRegionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp cache_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.getCacheTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest} returns this
*/
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.setCacheTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsRequest} returns this
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.clearCacheTime = function() {
  return this.setCacheTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputePropertyStatisticsRequest.prototype.hasCacheTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputePropertyStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    relevantScanJobsList: jspb.Message.toObjectList(msg.getRelevantScanJobsList(),
    sp_scan_service_scan_service_pb.ScanJob.toObject, includeInstance),
    regionalResults: (f = msg.getRegionalResults()) && sp_scan_service_scan_service_pb.Property.RegionalResults.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsResponse}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputePropertyStatisticsResponse;
  return proto.sp.scan_service.ComputePropertyStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsResponse}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_service_scan_service_pb.ScanJob;
      reader.readMessage(value,sp_scan_service_scan_service_pb.ScanJob.deserializeBinaryFromReader);
      msg.addRelevantScanJobs(value);
      break;
    case 2:
      var value = new sp_scan_service_scan_service_pb.Property.RegionalResults;
      reader.readMessage(value,sp_scan_service_scan_service_pb.Property.RegionalResults.deserializeBinaryFromReader);
      msg.setRegionalResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputePropertyStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelevantScanJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sp_scan_service_scan_service_pb.ScanJob.serializeBinaryToWriter
    );
  }
  f = message.getRegionalResults();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_scan_service_scan_service_pb.Property.RegionalResults.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScanJob relevant_scan_jobs = 1;
 * @return {!Array<!proto.sp.scan_service.ScanJob>}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.getRelevantScanJobsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJob>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_scan_service_scan_service_pb.ScanJob, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob>} value
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsResponse} returns this
*/
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.setRelevantScanJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJob=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.addRelevantScanJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ScanJob, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsResponse} returns this
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.clearRelevantScanJobsList = function() {
  return this.setRelevantScanJobsList([]);
};


/**
 * optional Property.RegionalResults regional_results = 2;
 * @return {?proto.sp.scan_service.Property.RegionalResults}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.getRegionalResults = function() {
  return /** @type{?proto.sp.scan_service.Property.RegionalResults} */ (
    jspb.Message.getWrapperField(this, sp_scan_service_scan_service_pb.Property.RegionalResults, 2));
};


/**
 * @param {?proto.sp.scan_service.Property.RegionalResults|undefined} value
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsResponse} returns this
*/
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.setRegionalResults = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComputePropertyStatisticsResponse} returns this
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.clearRegionalResults = function() {
  return this.setRegionalResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputePropertyStatisticsResponse.prototype.hasRegionalResults = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputeScanJobStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filters: (f = msg.getFilters()) && proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputeScanJobStatisticsRequest;
  return proto.sp.scan_service.ComputeScanJobStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputeScanJobStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters;
  return proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 1;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 1));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters} returns this
*/
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters} returns this
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 1;
 * @return {?proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters, 1));
};


/**
 * @param {?proto.sp.scan_service.ComputeScanJobStatisticsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} returns this
*/
proto.sp.scan_service.ComputeScanJobStatisticsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} returns this
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComputeScanJobStatisticsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputeScanJobStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statisticsList: jspb.Message.toObjectList(msg.getStatisticsList(),
    proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputeScanJobStatisticsResponse;
  return proto.sp.scan_service.ComputeScanJobStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic;
      reader.readMessage(value,proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.deserializeBinaryFromReader);
      msg.addStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputeScanJobStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic;
  return proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional ScanJob.ScanJobStatus status = 1;
 * @return {!proto.sp.scan_service.ScanJob.ScanJobStatus}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobStatus} value
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic} returns this
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic} returns this
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ScanJobStatistic statistics = 1;
 * @return {!Array<!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic>}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.prototype.getStatisticsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic>} value
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse} returns this
*/
proto.sp.scan_service.ComputeScanJobStatisticsResponse.prototype.setStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic}
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.prototype.addStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ComputeScanJobStatisticsResponse.ScanJobStatistic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ComputeScanJobStatisticsResponse} returns this
 */
proto.sp.scan_service.ComputeScanJobStatisticsResponse.prototype.clearStatisticsList = function() {
  return this.setStatisticsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.LatestActiveScanReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.LatestActiveScanReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.LatestActiveScanReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertySetId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    propertySetName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountSettingId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastScanned: (f = msg.getLastScanned()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.LatestActiveScanReport}
 */
proto.sp.scan_service.LatestActiveScanReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.LatestActiveScanReport;
  return proto.sp.scan_service.LatestActiveScanReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.LatestActiveScanReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.LatestActiveScanReport}
 */
proto.sp.scan_service.LatestActiveScanReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountSettingId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastScanned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.LatestActiveScanReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.LatestActiveScanReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.LatestActiveScanReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPropertySetName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountSettingId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLastScanned();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_id = 1;
 * @return {string}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_name = 2;
 * @return {string}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string region_id = 3;
 * @return {string}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string property_set_id = 4;
 * @return {string}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string property_set_name = 5;
 * @return {string}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getPropertySetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setPropertySetName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_setting_id = 6;
 * @return {string}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getAccountSettingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setAccountSettingId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 account_id = 7;
 * @return {number}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp last_scanned = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.getLastScanned = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
*/
proto.sp.scan_service.LatestActiveScanReport.prototype.setLastScanned = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.LatestActiveScanReport} returns this
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.clearLastScanned = function() {
  return this.setLastScanned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.LatestActiveScanReport.prototype.hasLastScanned = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetLatestActiveScanReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetLatestActiveScanReportsRequest;
  return proto.sp.scan_service.GetLatestActiveScanReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetLatestActiveScanReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_PROPERTY_ID: 1,
  SORT_FIELD_PROPERTY_NAME: 2,
  SORT_FIELD_REGION_ID: 3,
  SORT_FIELD_PROPERTY_SET_ID: 4,
  SORT_FIELD_PROPERTY_SET_NAME: 5,
  SORT_FIELD_ACCOUNT_SETTING_ID: 6,
  SORT_FIELD_ACCOUNT_ID: 7,
  SORT_FIELD_LAST_SCANNED: 8
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter;
  return proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortField}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    propertyNameFilterList: jspb.Message.toObjectList(msg.getPropertyNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    lastScannedFilter: (f = msg.getLastScannedFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    propertySetIdFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters;
  return proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionIdFilter(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addPropertyNameFilter(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setLastScannedFilter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPropertySetIdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPropertyNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getLastScannedFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getPropertySetIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * repeated string region_id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.getRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.setRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.addRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.clearRegionIdFilterList = function() {
  return this.setRegionIdFilterList([]);
};


/**
 * repeated sp.service_common.StringFilter property_name_filter = 2;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.getPropertyNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 2));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
*/
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.setPropertyNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.addPropertyNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.clearPropertyNameFilterList = function() {
  return this.setPropertyNameFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter last_scanned_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.getLastScannedFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
*/
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.setLastScannedFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.clearLastScannedFilter = function() {
  return this.setLastScannedFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.hasLastScannedFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string property_set_id_filter = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.getPropertySetIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.setPropertySetIdFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.addPropertySetIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters.prototype.clearPropertySetIdFilterList = function() {
  return this.setPropertySetIdFilterList([]);
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} returns this
*/
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetLatestActiveScanReportsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} returns this
*/
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter>}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} returns this
*/
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter}
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetLatestActiveScanReportsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetLatestActiveScanReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latestScanReportsList: jspb.Message.toObjectList(msg.getLatestScanReportsList(),
    proto.sp.scan_service.LatestActiveScanReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsResponse}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetLatestActiveScanReportsResponse;
  return proto.sp.scan_service.GetLatestActiveScanReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsResponse}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.LatestActiveScanReport;
      reader.readMessage(value,proto.sp.scan_service.LatestActiveScanReport.deserializeBinaryFromReader);
      msg.addLatestScanReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetLatestActiveScanReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatestScanReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.LatestActiveScanReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LatestActiveScanReport latest_scan_reports = 2;
 * @return {!Array<!proto.sp.scan_service.LatestActiveScanReport>}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.getLatestScanReportsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.LatestActiveScanReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.LatestActiveScanReport, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.LatestActiveScanReport>} value
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} returns this
*/
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.setLatestScanReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.LatestActiveScanReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.LatestActiveScanReport}
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.addLatestScanReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.LatestActiveScanReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetLatestActiveScanReportsResponse} returns this
 */
proto.sp.scan_service.GetLatestActiveScanReportsResponse.prototype.clearLatestScanReportsList = function() {
  return this.setLatestScanReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.MonitorExpiredLatestScansRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    daysBack: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansRequest}
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.MonitorExpiredLatestScansRequest;
  return proto.sp.scan_service.MonitorExpiredLatestScansRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansRequest}
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysBack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.MonitorExpiredLatestScansRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDaysBack();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 days_back = 1;
 * @return {number}
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.prototype.getDaysBack = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} returns this
 */
proto.sp.scan_service.MonitorExpiredLatestScansRequest.prototype.setDaysBack = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.MonitorExpiredLatestScansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    expiredPropertyIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansResponse}
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.MonitorExpiredLatestScansResponse;
  return proto.sp.scan_service.MonitorExpiredLatestScansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansResponse}
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addExpiredPropertyIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.MonitorExpiredLatestScansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpiredPropertyIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string expired_property_ids = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.prototype.getExpiredPropertyIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansResponse} returns this
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.prototype.setExpiredPropertyIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansResponse} returns this
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.prototype.addExpiredPropertyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.MonitorExpiredLatestScansResponse} returns this
 */
proto.sp.scan_service.MonitorExpiredLatestScansResponse.prototype.clearExpiredPropertyIdsList = function() {
  return this.setExpiredPropertyIdsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.scan_service.ScanJobReport.oneofGroups_ = [[6]];

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJobReport.ResultCase = {
  RESULT_NOT_SET: 0,
  COUNT: 6
};

/**
 * @return {proto.sp.scan_service.ScanJobReport.ResultCase}
 */
proto.sp.scan_service.ScanJobReport.prototype.getResultCase = function() {
  return /** @type {proto.sp.scan_service.ScanJobReport.ResultCase} */(jspb.Message.computeOneofCase(this, proto.sp.scan_service.ScanJobReport.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobReport.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    consentCmpId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    count: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobReport}
 */
proto.sp.scan_service.ScanJobReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobReport;
  return proto.sp.scan_service.ScanJobReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobReport}
 */
proto.sp.scan_service.ScanJobReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConsentCmpId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJobReport.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.setPropertyName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.clearPropertyName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobReport.prototype.hasPropertyName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.ScanJobReport.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.setRegionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.clearRegionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobReport.prototype.hasRegionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScanJob.ScanJobStatus status = 3;
 * @return {!proto.sp.scan_service.ScanJob.ScanJobStatus}
 */
proto.sp.scan_service.ScanJobReport.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobStatus} value
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobReport.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp completion_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.ScanJobReport.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
*/
proto.sp.scan_service.ScanJobReport.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobReport.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 consent_cmp_id = 5;
 * @return {number}
 */
proto.sp.scan_service.ScanJobReport.prototype.getConsentCmpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.setConsentCmpId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.clearConsentCmpId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobReport.prototype.hasConsentCmpId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 count = 6;
 * @return {number}
 */
proto.sp.scan_service.ScanJobReport.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.setCount = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.sp.scan_service.ScanJobReport.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobReport} returns this
 */
proto.sp.scan_service.ScanJobReport.prototype.clearCount = function() {
  return jspb.Message.setOneofField(this, 6, proto.sp.scan_service.ScanJobReport.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobReport.prototype.hasCount = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobReportsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobReportsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetScanJobReportsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.toObject, includeInstance),
    aggregationParameters: (f = msg.getAggregationParameters()) && proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest}
 */
proto.sp.scan_service.GetScanJobReportsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobReportsRequest;
  return proto.sp.scan_service.GetScanJobReportsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest}
 */
proto.sp.scan_service.GetScanJobReportsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetScanJobReportsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobReportsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetScanJobReportsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.deserializeBinaryFromReader);
      msg.setAggregationParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobReportsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetScanJobReportsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getAggregationParameters();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_PROPERTY_NAME: 1,
  SORT_FIELD_REGION_ID: 2,
  SORT_FIELD_SCAN_JOB_STATUS: 3,
  SORT_FIELD_COMPLETION_TIME: 4,
  SORT_FIELD_CONSENT_CMP_ID: 5,
  SORT_FIELD_AGGREGATE_COUNT: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobReportsRequest.SortParameter;
  return proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetScanJobReportsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.SortField}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetScanJobReportsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.repeatedFields_ = [1,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectFieldsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    aggregateFnc: jspb.Message.getFieldWithDefault(msg, 2, 0),
    aggregateFieldsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    groupByFieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters;
  return proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSelectFields(values[i]);
      }
      break;
    case 2:
      var value = /** @type {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.AggregateFunction} */ (reader.readEnum());
      msg.setAggregateFnc(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAggregateFields(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGroupByFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getAggregateFnc();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAggregateFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getGroupByFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField = {
  SCAN_JOB_REPORT_FIELD_UNSET: 0,
  SCAN_JOB_REPORT_FIELD_PROPERTY_NAME: 1,
  SCAN_JOB_REPORT_FIELD_REGION_ID: 2,
  SCAN_JOB_REPORT_FIELD_SCAN_JOB_STATUS: 3,
  SCAN_JOB_REPORT_FIELD_COMPLETION_TIME: 4,
  SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID: 5
};

/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.AggregateFunction = {
  AGGREGATE_FUNCTION_UNSET: 0,
  AGGREGATE_FUNCTION_COUNT: 1
};

/**
 * repeated ScanJobReportField select_fields = 1;
 * @return {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.getSelectFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.setSelectFieldsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.addSelectFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.clearSelectFieldsList = function() {
  return this.setSelectFieldsList([]);
};


/**
 * optional AggregateFunction aggregate_fnc = 2;
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.AggregateFunction}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.getAggregateFnc = function() {
  return /** @type {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.AggregateFunction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.AggregateFunction} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.setAggregateFnc = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated ScanJobReportField aggregate_fields = 3;
 * @return {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.getAggregateFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.setAggregateFieldsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.addAggregateFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.clearAggregateFieldsList = function() {
  return this.setAggregateFieldsList([]);
};


/**
 * repeated ScanJobReportField group_by_fields = 4;
 * @return {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.getGroupByFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.setGroupByFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.ScanJobReportField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.addGroupByFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters.prototype.clearGroupByFieldsList = function() {
  return this.setGroupByFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.repeatedFields_ = [1,2,3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobReportsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    propertyIdFilterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    propertyNameFilterList: jspb.Message.toObjectList(msg.getPropertyNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    completionTimeFilter: (f = msg.getCompletionTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    statusFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    consentCmpIdFilter: (f = msg.getConsentCmpIdFilter()) && sp_service_common_common_pb.NumberFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobReportsRequest.Filters;
  return proto.sp.scan_service.GetScanJobReportsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionIdFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPropertyIdFilter(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addPropertyNameFilter(value);
      break;
    case 4:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCompletionTimeFilter(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusFilter(values[i]);
      }
      break;
    case 6:
      var value = new sp_service_common_common_pb.NumberFilter;
      reader.readMessage(value,sp_service_common_common_pb.NumberFilter.deserializeBinaryFromReader);
      msg.setConsentCmpIdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobReportsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPropertyIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getPropertyNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getCompletionTimeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getConsentCmpIdFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      sp_service_common_common_pb.NumberFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string region_id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.getRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.setRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.addRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.clearRegionIdFilterList = function() {
  return this.setRegionIdFilterList([]);
};


/**
 * repeated string property_id_filter = 2;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.getPropertyIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.setPropertyIdFilterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.addPropertyIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.clearPropertyIdFilterList = function() {
  return this.setPropertyIdFilterList([]);
};


/**
 * repeated sp.service_common.StringFilter property_name_filter = 3;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.getPropertyNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 3));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.setPropertyNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.addPropertyNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.clearPropertyNameFilterList = function() {
  return this.setPropertyNameFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter completion_time_filter = 4;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.getCompletionTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 4));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.setCompletionTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.clearCompletionTimeFilter = function() {
  return this.setCompletionTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.hasCompletionTimeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ScanJob.ScanJobStatus status_filter = 5;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.getStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.setStatusFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.addStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.clearStatusFilterList = function() {
  return this.setStatusFilterList([]);
};


/**
 * optional sp.service_common.NumberFilter consent_cmp_id_filter = 6;
 * @return {?proto.sp.service_common.NumberFilter}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.getConsentCmpIdFilter = function() {
  return /** @type{?proto.sp.service_common.NumberFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.NumberFilter, 6));
};


/**
 * @param {?proto.sp.service_common.NumberFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.setConsentCmpIdFilter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.clearConsentCmpIdFilter = function() {
  return this.setConsentCmpIdFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobReportsRequest.Filters.prototype.hasConsentCmpIdFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetScanJobReportsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetScanJobReportsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetScanJobReportsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetScanJobReportsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter>}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetScanJobReportsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetScanJobReportsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * optional AggregationParameters aggregation_parameters = 4;
 * @return {?proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.getAggregationParameters = function() {
  return /** @type{?proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters, 4));
};


/**
 * @param {?proto.sp.scan_service.GetScanJobReportsRequest.AggregationParameters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
*/
proto.sp.scan_service.GetScanJobReportsRequest.prototype.setAggregationParameters = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobReportsRequest} returns this
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.clearAggregationParameters = function() {
  return this.setAggregationParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobReportsRequest.prototype.hasAggregationParameters = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobReportsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobReportsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobReportsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanJobReportsList: jspb.Message.toObjectList(msg.getScanJobReportsList(),
    proto.sp.scan_service.ScanJobReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobReportsResponse}
 */
proto.sp.scan_service.GetScanJobReportsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobReportsResponse;
  return proto.sp.scan_service.GetScanJobReportsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobReportsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobReportsResponse}
 */
proto.sp.scan_service.GetScanJobReportsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJobReport;
      reader.readMessage(value,proto.sp.scan_service.ScanJobReport.deserializeBinaryFromReader);
      msg.addScanJobReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobReportsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobReportsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobReportsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanJobReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanJobReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanJobReportsResponse} returns this
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobReportsResponse} returns this
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScanJobReport scan_job_reports = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJobReport>}
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.getScanJobReportsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobReport, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobReport>} value
 * @return {!proto.sp.scan_service.GetScanJobReportsResponse} returns this
*/
proto.sp.scan_service.GetScanJobReportsResponse.prototype.setScanJobReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobReport}
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.addScanJobReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanJobReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobReportsResponse} returns this
 */
proto.sp.scan_service.GetScanJobReportsResponse.prototype.clearScanJobReportsList = function() {
  return this.setScanJobReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.DumpTrainingDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.DumpTrainingDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.DumpTrainingDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.DumpTrainingDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobArtifactId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.DumpTrainingDataRequest}
 */
proto.sp.scan_service.DumpTrainingDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.DumpTrainingDataRequest;
  return proto.sp.scan_service.DumpTrainingDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.DumpTrainingDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.DumpTrainingDataRequest}
 */
proto.sp.scan_service.DumpTrainingDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobArtifactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.DumpTrainingDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.DumpTrainingDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.DumpTrainingDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.DumpTrainingDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_artifact_id = 1;
 * @return {string}
 */
proto.sp.scan_service.DumpTrainingDataRequest.prototype.getScanJobArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.DumpTrainingDataRequest} returns this
 */
proto.sp.scan_service.DumpTrainingDataRequest.prototype.setScanJobArtifactId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.DumpTrainingDataRequest} returns this
 */
proto.sp.scan_service.DumpTrainingDataRequest.prototype.clearScanJobArtifactId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.DumpTrainingDataRequest.prototype.hasScanJobArtifactId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.DumpTrainingDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.DumpTrainingDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.DumpTrainingDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.DumpTrainingDataResponse}
 */
proto.sp.scan_service.DumpTrainingDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.DumpTrainingDataResponse;
  return proto.sp.scan_service.DumpTrainingDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.DumpTrainingDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.DumpTrainingDataResponse}
 */
proto.sp.scan_service.DumpTrainingDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.DumpTrainingDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.DumpTrainingDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.DumpTrainingDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.DumpTrainingDataResponse} returns this
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes content = 2;
 * @return {string}
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sp.scan_service.DumpTrainingDataResponse} returns this
 */
proto.sp.scan_service.DumpTrainingDataResponse.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetCookiesForScanJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetCookiesForScanJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetCookiesForScanJobRequest}
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetCookiesForScanJobRequest;
  return proto.sp.scan_service.GetCookiesForScanJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetCookiesForScanJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetCookiesForScanJobRequest}
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetCookiesForScanJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetCookiesForScanJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetCookiesForScanJobRequest} returns this
 */
proto.sp.scan_service.GetCookiesForScanJobRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetCookiesForScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetCookiesForScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cookiesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetCookiesForScanJobResponse}
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetCookiesForScanJobResponse;
  return proto.sp.scan_service.GetCookiesForScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetCookiesForScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetCookiesForScanJobResponse}
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCookies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetCookiesForScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetCookiesForScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCookiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string cookies = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.prototype.getCookiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetCookiesForScanJobResponse} returns this
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.prototype.setCookiesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetCookiesForScanJobResponse} returns this
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.prototype.addCookies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetCookiesForScanJobResponse} returns this
 */
proto.sp.scan_service.GetCookiesForScanJobResponse.prototype.clearCookiesList = function() {
  return this.setCookiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GateScanJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GateScanJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GateScanJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GateScanJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GateScanJobsRequest}
 */
proto.sp.scan_service.GateScanJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GateScanJobsRequest;
  return proto.sp.scan_service.GateScanJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GateScanJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GateScanJobsRequest}
 */
proto.sp.scan_service.GateScanJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GateScanJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GateScanJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GateScanJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GateScanJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 limit = 1;
 * @return {number}
 */
proto.sp.scan_service.GateScanJobsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GateScanJobsRequest} returns this
 */
proto.sp.scan_service.GateScanJobsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GateScanJobsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GateScanJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GateScanJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GateScanJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GateScanJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GateScanJobsResponse}
 */
proto.sp.scan_service.GateScanJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GateScanJobsResponse;
  return proto.sp.scan_service.GateScanJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GateScanJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GateScanJobsResponse}
 */
proto.sp.scan_service.GateScanJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanJobIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GateScanJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GateScanJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GateScanJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GateScanJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string scan_job_ids = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GateScanJobsResponse.prototype.getScanJobIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GateScanJobsResponse} returns this
 */
proto.sp.scan_service.GateScanJobsResponse.prototype.setScanJobIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GateScanJobsResponse} returns this
 */
proto.sp.scan_service.GateScanJobsResponse.prototype.addScanJobIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GateScanJobsResponse} returns this
 */
proto.sp.scan_service.GateScanJobsResponse.prototype.clearScanJobIdsList = function() {
  return this.setScanJobIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateGatingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateGatingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateGatingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gatingType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    remark: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateGatingRequest}
 */
proto.sp.scan_service.UpdateGatingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateGatingRequest;
  return proto.sp.scan_service.UpdateGatingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateGatingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateGatingRequest}
 */
proto.sp.scan_service.UpdateGatingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} */ (reader.readEnum());
      msg.setGatingType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateGatingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateGatingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateGatingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGatingType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateGatingRequest} returns this
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ScanJob.ScanJobGating.GatingType gating_type = 3;
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType}
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.getGatingType = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} value
 * @return {!proto.sp.scan_service.UpdateGatingRequest} returns this
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.setGatingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string remark = 2;
 * @return {string}
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateGatingRequest} returns this
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.setRemark = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateGatingRequest} returns this
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.clearRemark = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateGatingRequest.prototype.hasRemark = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateGatingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateGatingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateGatingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateGatingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateGatingResponse}
 */
proto.sp.scan_service.UpdateGatingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateGatingResponse;
  return proto.sp.scan_service.UpdateGatingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateGatingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateGatingResponse}
 */
proto.sp.scan_service.UpdateGatingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateGatingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateGatingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateGatingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateGatingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.sp.scan_service);
