import React, {ReactElement, FC, useState, useRef} from "react";
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { ScanServiceInternalClient } from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import { StreamLogsRequest, StreamLogsResponse } from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import { generateAuthHeader } from '../../lib/authorizationUtils';
import {SxProps} from "@mui/system";
import SimpleTable from "../SimpleTable";
import PageviewIcon from "@mui/icons-material/Pageview";
import {ClientReadableStream, RpcError} from "grpc-web";
import {ScanJob} from "../../generated/sp/scan_service/scan_service_pb";

interface ScanJobPodLogsButtonProps {
  scanJobId: string | undefined;
  scanJobStatus: ScanJob.ScanJobStatus | undefined;
  sx?: SxProps<Theme>;
}

const ScanJobPodLogsButton: FC<ScanJobPodLogsButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // View logs dialog
    const [viewLogsDialogOpen, setViewLogsDialogOpen] = useState(false);
    const [viewLogsDialogRows, setViewLogsDialogRows] = useState<string[][]>([]);
    const logStreamEl = useRef<ClientReadableStream<StreamLogsResponse> | null>(null);

    const handleViewLogsDialogClickOpen = () => {
        console.log(`Fetching pod logs for ${props.scanJobId}`)
        if (props.scanJobId === undefined) {
            return;
        }
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        setLoading(true);
        var req = new StreamLogsRequest();
        req.setScanJobId(props.scanJobId);
        logStreamEl.current = scanServiceInternal.streamLogs(req, generateAuthHeader());
        const logStream = logStreamEl.current;
        logStream.on('data', function(response) {
            console.log("Received log streaming content.");
            const lines = [response.getLogLinesList().map((line) => line.getLine())];
            setViewLogsDialogRows(old => [...old, ...lines]);
        });
        logStream.on('error', function(err: RpcError) {
            console.log(`Stream of logs errored out ${err.code} ${err.message}.`);
            let lines = [[`Stream of logs errored out ${err.code} ${err.message}.`]];
            setViewLogsDialogRows(old => [...old, ...lines]);
        });
        logStream.on('end', function() {
            // stream end signal
            console.log("Stream of logs ended.");
            let lines = [["streaming logs complete"]];
            setViewLogsDialogRows(old => [...old, ...lines]);
        });
        setViewLogsDialogOpen(true);
    };
    const handleViewLogsDialogClose = () => () => {
        setLoading(false);
        setViewLogsDialogOpen(false);
        setViewLogsDialogRows([]);
        const logStream = logStreamEl.current;
        console.log("Stopping log stream.")
        logStream?.cancel();
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"xl"} open={viewLogsDialogOpen} onClose={handleViewLogsDialogClose()} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"View Pod Logs"}</DialogTitle>
                <DialogContent >
                    <SimpleTable rows={viewLogsDialogRows} headerNames={["Logs"]} maxHeight={300}/>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" autoFocus onClick={handleViewLogsDialogClose()}>Exit</Button>
                </DialogActions>
            </Dialog>
            <LoadingButton
                sx={props.sx}
                size="small"
                color="secondary"
                onClick={handleViewLogsDialogClickOpen}
                loading={loading}
                disabled={props.scanJobStatus !== ScanJob.ScanJobStatus.SCAN_JOB_STATUS_RUNNING}
                loadingPosition="start"
                startIcon={<PageviewIcon />}
                variant="contained">
                Pod Logs
            </LoadingButton>
        </div>
    );
};

export default ScanJobPodLogsButton;