// source: sp/service_common/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.sp.service_common.AuditData', null, global);
goog.exportSymbol('proto.sp.service_common.AuditDataV2', null, global);
goog.exportSymbol('proto.sp.service_common.GetByIdRequest', null, global);
goog.exportSymbol('proto.sp.service_common.NumberFilter', null, global);
goog.exportSymbol('proto.sp.service_common.NumberFilter.FilterTypeCase', null, global);
goog.exportSymbol('proto.sp.service_common.NumberList', null, global);
goog.exportSymbol('proto.sp.service_common.PagingParameters', null, global);
goog.exportSymbol('proto.sp.service_common.RpcContext', null, global);
goog.exportSymbol('proto.sp.service_common.RpcError', null, global);
goog.exportSymbol('proto.sp.service_common.SortDirection', null, global);
goog.exportSymbol('proto.sp.service_common.StringFilter', null, global);
goog.exportSymbol('proto.sp.service_common.StringFilter.FilterTypeCase', null, global);
goog.exportSymbol('proto.sp.service_common.StringList', null, global);
goog.exportSymbol('proto.sp.service_common.TimestampFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.GetByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.GetByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.GetByIdRequest.displayName = 'proto.sp.service_common.GetByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.PagingParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.PagingParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.PagingParameters.displayName = 'proto.sp.service_common.PagingParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.StringList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.service_common.StringList.repeatedFields_, null);
};
goog.inherits(proto.sp.service_common.StringList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.StringList.displayName = 'proto.sp.service_common.StringList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.StringFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.service_common.StringFilter.oneofGroups_);
};
goog.inherits(proto.sp.service_common.StringFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.StringFilter.displayName = 'proto.sp.service_common.StringFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.NumberList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.service_common.NumberList.repeatedFields_, null);
};
goog.inherits(proto.sp.service_common.NumberList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.NumberList.displayName = 'proto.sp.service_common.NumberList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.NumberFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.service_common.NumberFilter.oneofGroups_);
};
goog.inherits(proto.sp.service_common.NumberFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.NumberFilter.displayName = 'proto.sp.service_common.NumberFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.TimestampFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.TimestampFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.TimestampFilter.displayName = 'proto.sp.service_common.TimestampFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.AuditData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.AuditData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.AuditData.displayName = 'proto.sp.service_common.AuditData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.AuditDataV2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.AuditDataV2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.AuditDataV2.displayName = 'proto.sp.service_common.AuditDataV2';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.RpcContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.RpcContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.RpcContext.displayName = 'proto.sp.service_common.RpcContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.service_common.RpcError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.service_common.RpcError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.service_common.RpcError.displayName = 'proto.sp.service_common.RpcError';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.GetByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.GetByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.GetByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.GetByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.GetByIdRequest}
 */
proto.sp.service_common.GetByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.GetByIdRequest;
  return proto.sp.service_common.GetByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.GetByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.GetByIdRequest}
 */
proto.sp.service_common.GetByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.GetByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.GetByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.GetByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.GetByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.service_common.GetByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.service_common.GetByIdRequest} returns this
 */
proto.sp.service_common.GetByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.PagingParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.PagingParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.PagingParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.PagingParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    continuationToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.PagingParameters}
 */
proto.sp.service_common.PagingParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.PagingParameters;
  return proto.sp.service_common.PagingParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.PagingParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.PagingParameters}
 */
proto.sp.service_common.PagingParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.PagingParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.PagingParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.PagingParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.PagingParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 limit = 1;
 * @return {number}
 */
proto.sp.service_common.PagingParameters.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.service_common.PagingParameters} returns this
 */
proto.sp.service_common.PagingParameters.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.service_common.PagingParameters} returns this
 */
proto.sp.service_common.PagingParameters.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.PagingParameters.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string continuation_token = 2;
 * @return {string}
 */
proto.sp.service_common.PagingParameters.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.service_common.PagingParameters} returns this
 */
proto.sp.service_common.PagingParameters.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.service_common.PagingParameters} returns this
 */
proto.sp.service_common.PagingParameters.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.PagingParameters.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.service_common.StringList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.StringList.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.StringList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.StringList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.StringList.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.StringList}
 */
proto.sp.service_common.StringList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.StringList;
  return proto.sp.service_common.StringList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.StringList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.StringList}
 */
proto.sp.service_common.StringList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.StringList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.StringList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.StringList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.StringList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.sp.service_common.StringList.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.service_common.StringList} returns this
 */
proto.sp.service_common.StringList.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringList} returns this
 */
proto.sp.service_common.StringList.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.service_common.StringList} returns this
 */
proto.sp.service_common.StringList.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.service_common.StringFilter.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.sp.service_common.StringFilter.FilterTypeCase = {
  FILTER_TYPE_NOT_SET: 0,
  EXACT_MATCH: 1,
  STARTS_WITH: 2,
  ENDS_WITH: 3,
  CONTAINS: 4
};

/**
 * @return {proto.sp.service_common.StringFilter.FilterTypeCase}
 */
proto.sp.service_common.StringFilter.prototype.getFilterTypeCase = function() {
  return /** @type {proto.sp.service_common.StringFilter.FilterTypeCase} */(jspb.Message.computeOneofCase(this, proto.sp.service_common.StringFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.StringFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.StringFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.StringFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.StringFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    exactMatch: (f = msg.getExactMatch()) && proto.sp.service_common.StringList.toObject(includeInstance, f),
    startsWith: (f = msg.getStartsWith()) && proto.sp.service_common.StringList.toObject(includeInstance, f),
    endsWith: (f = msg.getEndsWith()) && proto.sp.service_common.StringList.toObject(includeInstance, f),
    contains: (f = msg.getContains()) && proto.sp.service_common.StringList.toObject(includeInstance, f),
    caseInsensitive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.service_common.StringFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.StringFilter;
  return proto.sp.service_common.StringFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.StringFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.service_common.StringFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.service_common.StringList;
      reader.readMessage(value,proto.sp.service_common.StringList.deserializeBinaryFromReader);
      msg.setExactMatch(value);
      break;
    case 2:
      var value = new proto.sp.service_common.StringList;
      reader.readMessage(value,proto.sp.service_common.StringList.deserializeBinaryFromReader);
      msg.setStartsWith(value);
      break;
    case 3:
      var value = new proto.sp.service_common.StringList;
      reader.readMessage(value,proto.sp.service_common.StringList.deserializeBinaryFromReader);
      msg.setEndsWith(value);
      break;
    case 4:
      var value = new proto.sp.service_common.StringList;
      reader.readMessage(value,proto.sp.service_common.StringList.deserializeBinaryFromReader);
      msg.setContains(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCaseInsensitive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.StringFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.StringFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.StringFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.StringFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExactMatch();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.service_common.StringList.serializeBinaryToWriter
    );
  }
  f = message.getStartsWith();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.service_common.StringList.serializeBinaryToWriter
    );
  }
  f = message.getEndsWith();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.service_common.StringList.serializeBinaryToWriter
    );
  }
  f = message.getContains();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sp.service_common.StringList.serializeBinaryToWriter
    );
  }
  f = message.getCaseInsensitive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional StringList exact_match = 1;
 * @return {?proto.sp.service_common.StringList}
 */
proto.sp.service_common.StringFilter.prototype.getExactMatch = function() {
  return /** @type{?proto.sp.service_common.StringList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.StringList, 1));
};


/**
 * @param {?proto.sp.service_common.StringList|undefined} value
 * @return {!proto.sp.service_common.StringFilter} returns this
*/
proto.sp.service_common.StringFilter.prototype.setExactMatch = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sp.service_common.StringFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.StringFilter} returns this
 */
proto.sp.service_common.StringFilter.prototype.clearExactMatch = function() {
  return this.setExactMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.StringFilter.prototype.hasExactMatch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StringList starts_with = 2;
 * @return {?proto.sp.service_common.StringList}
 */
proto.sp.service_common.StringFilter.prototype.getStartsWith = function() {
  return /** @type{?proto.sp.service_common.StringList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.StringList, 2));
};


/**
 * @param {?proto.sp.service_common.StringList|undefined} value
 * @return {!proto.sp.service_common.StringFilter} returns this
*/
proto.sp.service_common.StringFilter.prototype.setStartsWith = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.sp.service_common.StringFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.StringFilter} returns this
 */
proto.sp.service_common.StringFilter.prototype.clearStartsWith = function() {
  return this.setStartsWith(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.StringFilter.prototype.hasStartsWith = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StringList ends_with = 3;
 * @return {?proto.sp.service_common.StringList}
 */
proto.sp.service_common.StringFilter.prototype.getEndsWith = function() {
  return /** @type{?proto.sp.service_common.StringList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.StringList, 3));
};


/**
 * @param {?proto.sp.service_common.StringList|undefined} value
 * @return {!proto.sp.service_common.StringFilter} returns this
*/
proto.sp.service_common.StringFilter.prototype.setEndsWith = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.sp.service_common.StringFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.StringFilter} returns this
 */
proto.sp.service_common.StringFilter.prototype.clearEndsWith = function() {
  return this.setEndsWith(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.StringFilter.prototype.hasEndsWith = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StringList contains = 4;
 * @return {?proto.sp.service_common.StringList}
 */
proto.sp.service_common.StringFilter.prototype.getContains = function() {
  return /** @type{?proto.sp.service_common.StringList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.StringList, 4));
};


/**
 * @param {?proto.sp.service_common.StringList|undefined} value
 * @return {!proto.sp.service_common.StringFilter} returns this
*/
proto.sp.service_common.StringFilter.prototype.setContains = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.sp.service_common.StringFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.StringFilter} returns this
 */
proto.sp.service_common.StringFilter.prototype.clearContains = function() {
  return this.setContains(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.StringFilter.prototype.hasContains = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool case_insensitive = 5;
 * @return {boolean}
 */
proto.sp.service_common.StringFilter.prototype.getCaseInsensitive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.service_common.StringFilter} returns this
 */
proto.sp.service_common.StringFilter.prototype.setCaseInsensitive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.service_common.NumberList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.NumberList.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.NumberList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.NumberList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.NumberList.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.NumberList}
 */
proto.sp.service_common.NumberList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.NumberList;
  return proto.sp.service_common.NumberList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.NumberList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.NumberList}
 */
proto.sp.service_common.NumberList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.NumberList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.NumberList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.NumberList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.NumberList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 values = 1;
 * @return {!Array<number>}
 */
proto.sp.service_common.NumberList.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.service_common.NumberList} returns this
 */
proto.sp.service_common.NumberList.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.NumberList} returns this
 */
proto.sp.service_common.NumberList.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.service_common.NumberList} returns this
 */
proto.sp.service_common.NumberList.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.service_common.NumberFilter.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.sp.service_common.NumberFilter.FilterTypeCase = {
  FILTER_TYPE_NOT_SET: 0,
  EQUALS: 1,
  GREATER_THAN: 2,
  LESS_THAN: 3
};

/**
 * @return {proto.sp.service_common.NumberFilter.FilterTypeCase}
 */
proto.sp.service_common.NumberFilter.prototype.getFilterTypeCase = function() {
  return /** @type {proto.sp.service_common.NumberFilter.FilterTypeCase} */(jspb.Message.computeOneofCase(this, proto.sp.service_common.NumberFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.NumberFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.NumberFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.NumberFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.NumberFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    equals: (f = msg.getEquals()) && proto.sp.service_common.NumberList.toObject(includeInstance, f),
    greaterThan: (f = msg.getGreaterThan()) && proto.sp.service_common.NumberList.toObject(includeInstance, f),
    lessThan: (f = msg.getLessThan()) && proto.sp.service_common.NumberList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.NumberFilter}
 */
proto.sp.service_common.NumberFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.NumberFilter;
  return proto.sp.service_common.NumberFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.NumberFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.NumberFilter}
 */
proto.sp.service_common.NumberFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.service_common.NumberList;
      reader.readMessage(value,proto.sp.service_common.NumberList.deserializeBinaryFromReader);
      msg.setEquals(value);
      break;
    case 2:
      var value = new proto.sp.service_common.NumberList;
      reader.readMessage(value,proto.sp.service_common.NumberList.deserializeBinaryFromReader);
      msg.setGreaterThan(value);
      break;
    case 3:
      var value = new proto.sp.service_common.NumberList;
      reader.readMessage(value,proto.sp.service_common.NumberList.deserializeBinaryFromReader);
      msg.setLessThan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.NumberFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.NumberFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.NumberFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.NumberFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEquals();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.service_common.NumberList.serializeBinaryToWriter
    );
  }
  f = message.getGreaterThan();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.service_common.NumberList.serializeBinaryToWriter
    );
  }
  f = message.getLessThan();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.service_common.NumberList.serializeBinaryToWriter
    );
  }
};


/**
 * optional NumberList equals = 1;
 * @return {?proto.sp.service_common.NumberList}
 */
proto.sp.service_common.NumberFilter.prototype.getEquals = function() {
  return /** @type{?proto.sp.service_common.NumberList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.NumberList, 1));
};


/**
 * @param {?proto.sp.service_common.NumberList|undefined} value
 * @return {!proto.sp.service_common.NumberFilter} returns this
*/
proto.sp.service_common.NumberFilter.prototype.setEquals = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sp.service_common.NumberFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.NumberFilter} returns this
 */
proto.sp.service_common.NumberFilter.prototype.clearEquals = function() {
  return this.setEquals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.NumberFilter.prototype.hasEquals = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NumberList greater_than = 2;
 * @return {?proto.sp.service_common.NumberList}
 */
proto.sp.service_common.NumberFilter.prototype.getGreaterThan = function() {
  return /** @type{?proto.sp.service_common.NumberList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.NumberList, 2));
};


/**
 * @param {?proto.sp.service_common.NumberList|undefined} value
 * @return {!proto.sp.service_common.NumberFilter} returns this
*/
proto.sp.service_common.NumberFilter.prototype.setGreaterThan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.sp.service_common.NumberFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.NumberFilter} returns this
 */
proto.sp.service_common.NumberFilter.prototype.clearGreaterThan = function() {
  return this.setGreaterThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.NumberFilter.prototype.hasGreaterThan = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NumberList less_than = 3;
 * @return {?proto.sp.service_common.NumberList}
 */
proto.sp.service_common.NumberFilter.prototype.getLessThan = function() {
  return /** @type{?proto.sp.service_common.NumberList} */ (
    jspb.Message.getWrapperField(this, proto.sp.service_common.NumberList, 3));
};


/**
 * @param {?proto.sp.service_common.NumberList|undefined} value
 * @return {!proto.sp.service_common.NumberFilter} returns this
*/
proto.sp.service_common.NumberFilter.prototype.setLessThan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.sp.service_common.NumberFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.NumberFilter} returns this
 */
proto.sp.service_common.NumberFilter.prototype.clearLessThan = function() {
  return this.setLessThan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.NumberFilter.prototype.hasLessThan = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.TimestampFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.TimestampFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.TimestampFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.TimestampFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    onOrAfter: (f = msg.getOnOrAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    onOrBefore: (f = msg.getOnOrBefore()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.TimestampFilter}
 */
proto.sp.service_common.TimestampFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.TimestampFilter;
  return proto.sp.service_common.TimestampFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.TimestampFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.TimestampFilter}
 */
proto.sp.service_common.TimestampFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOnOrAfter(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOnOrBefore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.TimestampFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.TimestampFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.TimestampFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.TimestampFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOnOrAfter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOnOrBefore();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp on_or_after = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.service_common.TimestampFilter.prototype.getOnOrAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.service_common.TimestampFilter} returns this
*/
proto.sp.service_common.TimestampFilter.prototype.setOnOrAfter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.TimestampFilter} returns this
 */
proto.sp.service_common.TimestampFilter.prototype.clearOnOrAfter = function() {
  return this.setOnOrAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.TimestampFilter.prototype.hasOnOrAfter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp on_or_before = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.service_common.TimestampFilter.prototype.getOnOrBefore = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.service_common.TimestampFilter} returns this
*/
proto.sp.service_common.TimestampFilter.prototype.setOnOrBefore = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.TimestampFilter} returns this
 */
proto.sp.service_common.TimestampFilter.prototype.clearOnOrBefore = function() {
  return this.setOnOrBefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.TimestampFilter.prototype.hasOnOrBefore = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.AuditData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.AuditData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.AuditData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.AuditData.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.AuditData}
 */
proto.sp.service_common.AuditData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.AuditData;
  return proto.sp.service_common.AuditData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.AuditData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.AuditData}
 */
proto.sp.service_common.AuditData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.AuditData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.AuditData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.AuditData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.AuditData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.service_common.AuditData.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.service_common.AuditData} returns this
*/
proto.sp.service_common.AuditData.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.AuditData} returns this
 */
proto.sp.service_common.AuditData.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.AuditData.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.service_common.AuditData.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.service_common.AuditData} returns this
*/
proto.sp.service_common.AuditData.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.AuditData} returns this
 */
proto.sp.service_common.AuditData.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.AuditData.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.AuditDataV2.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.AuditDataV2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.AuditDataV2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.AuditDataV2.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.AuditDataV2}
 */
proto.sp.service_common.AuditDataV2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.AuditDataV2;
  return proto.sp.service_common.AuditDataV2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.AuditDataV2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.AuditDataV2}
 */
proto.sp.service_common.AuditDataV2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.AuditDataV2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.AuditDataV2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.AuditDataV2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.AuditDataV2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.service_common.AuditDataV2.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.service_common.AuditDataV2} returns this
*/
proto.sp.service_common.AuditDataV2.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.AuditDataV2} returns this
 */
proto.sp.service_common.AuditDataV2.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.AuditDataV2.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.service_common.AuditDataV2.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.service_common.AuditDataV2} returns this
*/
proto.sp.service_common.AuditDataV2.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.service_common.AuditDataV2} returns this
 */
proto.sp.service_common.AuditDataV2.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.AuditDataV2.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string created_by = 3;
 * @return {string}
 */
proto.sp.service_common.AuditDataV2.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.service_common.AuditDataV2} returns this
 */
proto.sp.service_common.AuditDataV2.prototype.setCreatedBy = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.service_common.AuditDataV2} returns this
 */
proto.sp.service_common.AuditDataV2.prototype.clearCreatedBy = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.AuditDataV2.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string updated_by = 4;
 * @return {string}
 */
proto.sp.service_common.AuditDataV2.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.service_common.AuditDataV2} returns this
 */
proto.sp.service_common.AuditDataV2.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.service_common.AuditDataV2} returns this
 */
proto.sp.service_common.AuditDataV2.prototype.clearUpdatedBy = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.service_common.AuditDataV2.prototype.hasUpdatedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.RpcContext.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.RpcContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.RpcContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.RpcContext.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.RpcContext}
 */
proto.sp.service_common.RpcContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.RpcContext;
  return proto.sp.service_common.RpcContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.RpcContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.RpcContext}
 */
proto.sp.service_common.RpcContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.RpcContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.RpcContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.RpcContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.RpcContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.service_common.RpcError.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.service_common.RpcError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.service_common.RpcError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.RpcError.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.service_common.RpcError}
 */
proto.sp.service_common.RpcError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.service_common.RpcError;
  return proto.sp.service_common.RpcError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.service_common.RpcError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.service_common.RpcError}
 */
proto.sp.service_common.RpcError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.service_common.RpcError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.service_common.RpcError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.service_common.RpcError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.service_common.RpcError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.sp.service_common.SortDirection = {
  SORT_DIRECTION_UNSET: 0,
  SORT_DIRECTION_ASC: 1,
  SORT_DIRECTION_DESC: 2
};

goog.object.extend(exports, proto.sp.service_common);
