const DEFAULT_MAX_LENGTH = 60;
const SNIPPET_SEPARATOR = " .. ";
const formatStringMaxLength = (str: undefined | String, maxLength?: number) => {
  let length = maxLength === undefined ? DEFAULT_MAX_LENGTH : maxLength;

  if (str === undefined) {
    return "";
  } else if (str.length >= length) {
    // we must cap the string.
    return str.substring(0, length) + "..";
  } else {
    return str;
  }
}

const formatStringWithLeadingAndTrailingSnippets = (str: undefined | String, maxLength?: number) => {
  let length = maxLength === undefined ? DEFAULT_MAX_LENGTH : maxLength;

  if (str === undefined) {
    return "";
  } else if (str.length >= length) {
    // we must cap the string, find leading/starting snippets
    let snippetSize = length / 2;
    let snippetSeparatorPadding = SNIPPET_SEPARATOR.length/2;
    return str.substring(0, snippetSize-snippetSeparatorPadding) + SNIPPET_SEPARATOR + str.substring(str.length-snippetSize+snippetSeparatorPadding, str.length);
  } else {
    return str;
  }
}

class StringAndSelection {
  str: String;
  selectionStart: number;
  selectionEnd: number;

  constructor(str: String, selectionStart: number, selectionEnd: number) {
    this.str = str;
    this.selectionStart = selectionStart;
    this.selectionEnd = selectionEnd;
  }

  getSelectedText = (): string => {
    return this.str.substring(this.selectionStart, this.selectionEnd);
  }
}
const trimWhiteSpace = (str: String, selectionStart: number, selectionEnd: number): StringAndSelection => {
  let stringAndSelection = new StringAndSelection(str, selectionStart, selectionEnd);
  // attempt to remove whitespace, while re-calculating start/end
  let selectedText = stringAndSelection.getSelectedText();
  // determine prefix-trim
  let prefixTrimmed = selectedText.trimStart();
  let revisedStart = prefixTrimmed.length < selectedText.length ? (stringAndSelection.selectionStart + (selectedText.length - prefixTrimmed.length )) : stringAndSelection.selectionStart;

  // determine suffix-trim
  let suffixTrimmed = selectedText.trimEnd();
  let revisedEnd = suffixTrimmed.length < selectedText.length ? (stringAndSelection.selectionEnd - (selectedText.length - suffixTrimmed.length )) : stringAndSelection.selectionEnd;

  let revisedSelection= new StringAndSelection(stringAndSelection.str, revisedStart, revisedEnd);
  let trimmed = selectedText.trimStart().trimEnd();
  if (revisedSelection.getSelectedText() !== trimmed) {
    throw Error('trimWhiteSpace() did not match expected value!')
  }
  return revisedSelection
}

export { formatStringMaxLength, formatStringWithLeadingAndTrailingSnippets, trimWhiteSpace };
