// localstorage routines
import {SourcePointTokens} from "./authorizationUtils";
import {IabIdName} from "./iabUtils";

const LOCAL_STORAGE_SOURCE_POINT_TOKENS_KEY = 'SOURCE_POINT_TOKENS';
const LOCAL_STORAGE_IAB_VENDOR_LIST_KEY = 'IAB_VENDOR_LIST_V3.3';
const LOCAL_STORAGE_IAB_CMP_LIST_KEY = 'IAB_CMP_LIST_V2023-06-01';

const setSourcePointTokensIntoStorage = (sourcePointTokens: SourcePointTokens | undefined) => {
    if (sourcePointTokens !== undefined) {
        localStorage.setItem(LOCAL_STORAGE_SOURCE_POINT_TOKENS_KEY, JSON.stringify(sourcePointTokens));
    }
}
const getSourcePointTokensFromStorage = (): SourcePointTokens | undefined  => {
    let json: string | null = localStorage.getItem(LOCAL_STORAGE_SOURCE_POINT_TOKENS_KEY)
    if (json != null) {
        return JSON.parse(json);
    } else {
        return undefined;
    }
}

const getSourcePointTokensFromStorageWithExpiry = () => {
    let fromLocalStorage: SourcePointTokens | undefined = getSourcePointTokensFromStorage();
    if (fromLocalStorage !== undefined) {
        // Check expiry before returning.
        let now: number = new Date().getTime();
        let tokenEndTime: number | undefined = fromLocalStorage.tokenEndTime;
        if (tokenEndTime !== undefined && tokenEndTime >= now) {
            console.log(`Found existing profile from storage which will expire at=[${new Date(tokenEndTime)}]`);
            return fromLocalStorage;
        } else if (tokenEndTime !== undefined) {
            console.log(`Found existing profile from storage but it has already expired at=[${new Date(tokenEndTime)}]`);
            return undefined;
        }
    }
    return undefined;
}

const removeSourcePointTokensFromStorage = () => {
    return localStorage.removeItem(LOCAL_STORAGE_SOURCE_POINT_TOKENS_KEY);
}

const setIabVendorListIntoStorage = (iabVendorList: IabIdName[] | undefined) => {
    if (iabVendorList !== undefined) {
        localStorage.setItem(LOCAL_STORAGE_IAB_VENDOR_LIST_KEY, JSON.stringify(iabVendorList));
    }
}
const getIabVendorListFromStorage = (): IabIdName[] | undefined  => {
    console.log("Fetching iab vendor list from local storage...")
    let json: string | null = localStorage.getItem(LOCAL_STORAGE_IAB_VENDOR_LIST_KEY)
    if (json != null) {
        return JSON.parse(json);
    } else {
        return undefined;
    }
}

const setIabCmpListIntoStorage = (iabCmpList: IabIdName[] | undefined) => {
    if (iabCmpList !== undefined) {
        localStorage.setItem(LOCAL_STORAGE_IAB_CMP_LIST_KEY, JSON.stringify(iabCmpList));
    }
}
const getIabCmpListFromStorage = (): IabIdName[] | undefined  => {
    console.log("Fetching iab cmp list from local storage...")
    let json: string | null = localStorage.getItem(LOCAL_STORAGE_IAB_CMP_LIST_KEY)
    if (json != null) {
        return JSON.parse(json);
    } else {
        return undefined;
    }
}
export { setSourcePointTokensIntoStorage, getSourcePointTokensFromStorage, getSourcePointTokensFromStorageWithExpiry, removeSourcePointTokensFromStorage, setIabVendorListIntoStorage, getIabVendorListFromStorage, setIabCmpListIntoStorage, getIabCmpListFromStorage };
