import React, {FC, ReactElement, useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import {SxProps} from "@mui/system";
import {styled, Theme, useTheme} from "@mui/material/styles";
import {TextResult} from "../../generated/sp/scan_runner/common_pb";
import {ScanServiceInternalClient} from "../../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {
    GetRandomTrainingDataInputRequest,
    GetTrainingDataInputRequest,
    GetTrainingDataInputResponse,
    UpdateTrainingDataRequest
} from "../../generated/sp/scan_service/scan_service_internal_pb";
import {generateAuthHeader} from "../../lib/authorizationUtils";
import {Buffer} from "buffer";
import {StyledTableCell, StyledTableRow} from "../../lib/tableUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import {Base64ImageAndMetadata, generateBase64ImageAndMetadata} from "../../lib/imageUtils";
import ScanTimelineImageArtifactViewButton from "../buttons/ScanTimelineImageArtifactViewButton";
import {ScanArtifact} from "../../generated/sp/scan_runner/scan_runner_pb";
import HighlightWithinTextarea, {Highlight, Selection} from "react-highlight-within-textarea";
import {CustomObject, Range} from "react-highlight-within-textarea/lib/esm/highlightToStrategyAndComponents";
import {ScanJob, ScanJobArtifact} from "../../generated/sp/scan_service/scan_service_pb";
import SaveIcon from '@mui/icons-material/Save';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import RobotAngryIcon from '@mui-extra/icons/RobotAngryIcon';
import RobotAngryOutlineIcon from '@mui-extra/icons/RobotAngryOutlineIcon';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoadingButton from "@mui/lab/LoadingButton";
import {regionSelectorItems} from "../../lib/regionUtils";
import {Link} from "react-router-dom";
import {groupByToMap} from "../../lib/collectionUtils";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import BackspaceIcon from '@mui/icons-material/Backspace';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RectangleIcon from '@mui/icons-material/Rectangle';
import FlagIcon from '@mui/icons-material/Flag';
import EditIcon from '@mui/icons-material/Edit';
import {SELECTOR_ALL_ID} from "../../lib/selectorUtils";
import {SkynetServiceClient} from "../../generated/sp/skynet_service/skynet_service_grpc_web_pb";
import {GetTextResultsRequest, GetTextResultsResponse} from "../../generated/sp/skynet_service/skynet_service_pb";
import TrainingMetadataButton from "../buttons/TrainingMetadataButton";
import {idGenerator, RejectedTextResultRow, renderTextTypeText, TrainingRange} from "../../lib/trainingUtils";
import TrainingRowMetadataButton from "../buttons/TrainingRowMetadataButton";
import InputTextType = GetTextResultsRequest.InputTextType;
import Priority = GetTextResultsRequest.Priority;
import {trimWhiteSpace} from "../../lib/stringUtils";
import DownloadTrainingModelButton from "../buttons/DownloadTrainingModelButton";

interface TrainingInput {
    scanJob: ScanJob | undefined,
    scanJobArtifactId: string | undefined,
    policyTextArtifact: ScanArtifact | undefined,
    policyImageArtifact: ScanArtifact | undefined,
    policyTextContent: string | undefined,
    policyImageAndMetadata: Base64ImageAndMetadata | undefined,
}

interface ScanJobArtifactTrainingCardProps {
    initialScanJobArtifactId: string | undefined;
    initialPropertySetId: string | undefined;
    useLoadingButton?: boolean | undefined;
    sx?: SxProps<Theme>;
}

const InnerTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#FFFFFF',
    },
    '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const InnerTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 10,
    },
}));

const TEXT_TYPE_MENU_ITEMS = [
    TextResult.TextType.TEXT_TYPE_UNSET,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_TITLE,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_UPDATED_DATE,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_CA_RESIDENT_RIGHTS_DESCRIPTION,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_HOW_DATA_WILL_BE_USED,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_WITH_WHOM_DATA_WILL_BE_SHARED,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA,
    TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA,
    TextResult.TextType.TEXT_TYPE_GLOBAL_PRIVACY_CONTROL_RESPECTED
];

const renderTextTypeHexColor = (textType: TextResult.TextType): string => {
    switch(textType) {
        case TextResult.TextType.TEXT_TYPE_UNSET:
            // return "#CAECDE"
            return "#E71F1E"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY:
            return "#EBF5D4"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_TITLE:
            return "#C3CCE1"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_UPDATED_DATE:
            return "#FBE6CE"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_CA_RESIDENT_RIGHTS_DESCRIPTION:
            return "#D4DBE2"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_HOW_DATA_WILL_BE_USED:
            return "#F7E6F5"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_WITH_WHOM_DATA_WILL_BE_SHARED:
            return "#EADFF5"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA:
            return "#F7A072"
        case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA:
            return "#FF9B42"
        case TextResult.TextType.TEXT_TYPE_GLOBAL_PRIVACY_CONTROL_RESPECTED:
            return "#D5F2EE"
        default: {
            return "#EDDEA4";
        }
    }
}

const convertToTextType = (textTypeName: String): TextResult.TextType => {
    //TODO: programatically map this.
    switch (textTypeName) {
        case "PRIVACY_POLICY":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY
        case "COOKIE_POLICY":
            return TextResult.TextType.TEXT_TYPE_COOKIE_POLICY
        case "DELETE_MY_DATA":
            return TextResult.TextType.TEXT_TYPE_DELETE_MY_DATA
        case "DELETE_MY_DATA_PHONE_AVAILABLE":
            return TextResult.TextType.TEXT_TYPE_DELETE_MY_DATA_PHONE_AVAILABLE
        case "INFORMED_OF_CONSENT_OUTCOME":
            return TextResult.TextType.TEXT_TYPE_INFORMED_OF_CONSENT_OUTCOME
        case "INFORMED_OF_ABILITY_TO_WITHDRAW":
            return TextResult.TextType.TEXT_TYPE_INFORMED_OF_ABILITY_TO_WITHDRAW
        case "INFORMED_OF_LEGITIMATE_INTEREST_FIRST":
            return TextResult.TextType.TEXT_TYPE_INFORMED_OF_LEGITIMATE_INTEREST_FIRST
        case "INFORMED_OF_LEGITIMATE_INTEREST_SECOND":
            return TextResult.TextType.TEXT_TYPE_INFORMED_OF_LEGITIMATE_INTEREST_SECOND
        case "PRIVACY_POLICY_INCLUDES_CA_RESIDENT_RIGHTS_DESCRIPTION":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_CA_RESIDENT_RIGHTS_DESCRIPTION
        case "PRIVACY_POLICY_INCLUDES_HOW_DATA_WILL_BE_USED":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_HOW_DATA_WILL_BE_USED
        case "PRIVACY_POLICY_INCLUDES_WITH_WHOM_DATA_WILL_BE_SHARED":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_WITH_WHOM_DATA_WILL_BE_SHARED
        case "PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA
        case "PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA
        case "GLOBAL_PRIVACY_CONTROL_RESPECTED":
            return TextResult.TextType.TEXT_TYPE_GLOBAL_PRIVACY_CONTROL_RESPECTED
        case "PRIVACY_POLICY_TITLE":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_TITLE
        case "PRIVACY_POLICY_UPDATED_DATE":
            return TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_UPDATED_DATE
        default:
            return TextResult.TextType.TEXT_TYPE_UNSET
    }
}

const ScanJobArtifactTrainingCard: FC<ScanJobArtifactTrainingCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [continuousMode, setContinuousMode] = useState<boolean>(false);
    const [trainingInput, setTrainingInput] = useState<TrainingInput | undefined>(undefined);
    const [ranges, setRanges] = useState<TrainingRange[]>([]);
    const [rejectedTextResults, setRejectedTextResults] = useState<RejectedTextResultRow[]>([]);
    const [imageScaleFactor] = useState<number>(0.5);
    const [selection] = useState<Selection>(new Selection(0, 0));
    const [regionFilter, setRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);
    const [visibilityToggle, setVisibilityToggle] = useState<boolean>(true);
    const [skynetStats, setSkynetStats] = useState<string[] | undefined>(undefined);

    // dialog related
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [rejectedTextResultInput, setRejectedTextResultInput] = useState<TextResult.TextType>(TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY);

    useEffect(() => {
        (async () => {
            setLoading(true);
            setContinuousMode(props.initialScanJobArtifactId === undefined);
            let maybeScanJobArtifactId = props.initialScanJobArtifactId;
            let maybePropertySetId = props.initialPropertySetId;
            console.log(`Loading scanjob artifact training data for scanJobArtifactId=[${maybeScanJobArtifactId!}], propertySetId=[${maybePropertySetId}]`);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const trainingDataInputResponse = await getTrainingDataInput(scanServiceInternal, maybeScanJobArtifactId, maybePropertySetId, regionFilter);
            const scanJob = trainingDataInputResponse.getScanJob();
            if (scanJob !== undefined) {
                const scanArtifactPolicyText = trainingDataInputResponse.getScanArtifactPrivacyPolicyText();
                //TODO: figure out how to deal with multiple images, just grab first for now
                const scanArtifactPolicyImage = trainingDataInputResponse.getScanArtifactPrivacyPolicyImagesList().length > 0  ? trainingDataInputResponse.getScanArtifactPrivacyPolicyImagesList()[0] : undefined;
                const scanJobArtifact = trainingDataInputResponse.getScanJobArtifact();
                const trainingInput: TrainingInput = {
                    scanJob: scanJob,
                    scanJobArtifactId: scanJobArtifact?.getId(),
                    policyTextArtifact: scanArtifactPolicyText,
                    policyImageArtifact: scanArtifactPolicyImage,
                    policyTextContent: scanArtifactPolicyText !== undefined ? Buffer.from(scanArtifactPolicyText?.getContent_asB64(), 'base64').toString() : undefined,
                    policyImageAndMetadata: scanArtifactPolicyImage !== undefined ? generateBase64ImageAndMetadata(imageScaleFactor, scanArtifactPolicyImage) : undefined,
                };
                setTrainingInput(trainingInput);

                let scanJobTrainingData = scanJobArtifact?.getScanJobTrainingData();
                if (scanJobTrainingData !== undefined) {
                    // create ranges here based on existing training data...
                    let ranges = scanJobTrainingData.getSelectedTextPositionsList().flatMap((selectedTextPosition) => {
                        return selectedTextPosition.getTextPositionsList().map((textPosition) => {
                            let start = textPosition.getStartPosition() || 0;
                            let end = textPosition.getStopPosition() || 0;
                            let name = trainingInput.policyTextContent?.substring(start, end).toString() || "";
                            let audit = textPosition.getAudit();
                            return new TrainingRange(name, start, end, selectedTextPosition.getTextType(), audit, true, undefined);
                        });
                    }).sort(TrainingRange.sortFn);
                    setRanges(ranges);
                    // now determine the rejected entries
                    let rejectedTextResults = scanJobTrainingData.getRejectedTextResultsList().map((rtr) => new RejectedTextResultRow(rtr.getTextType(), rtr.getAudit(), true))
                      .sort(RejectedTextResultRow.sortFn);
                    setRejectedTextResults(rejectedTextResults);
                } else {
                    setRanges([]);
                    setRejectedTextResults([]);
                }
            } else {
                setTrainingInput(undefined);
            }
            setLoading(false);
        })();
    }, [props.initialScanJobArtifactId, props.initialPropertySetId, imageScaleFactor, regionFilter, refreshToggle]);

    const convertExtractedDates = (extractedDates: GetTextResultsResponse.ExtractedDates | undefined) : Array<Date> | undefined => {
        if ( extractedDates !== undefined) {
            return extractedDates.getDatesList().map((extractedDate) => {
                // NOTE: zero based index for month
                return new Date(extractedDate.getYear(), extractedDate.getMonth()-1, extractedDate.getDay())
            });
        } else {
            return undefined;
        }
    }

    const loadSkynetResults = async () => {
        if (trainingInput !== undefined && trainingInput.policyTextContent !== undefined) {
            const skynetServiceClient = new SkynetServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            console.log("Loading results from skynet....")
            const skynetResults = await getSkynetTextResult(skynetServiceClient, trainingInput.policyTextContent.toString());
            // create map of existing ranges & existing rejections
            let existingRangeIds = ranges.map((range) => range.id);
            let existingRejectionTextTypes = rejectedTextResults.map((rejection) => rejection.textType);

            let rangesFromSkynet = skynetResults.getSelectedTextPositionsList()
              .filter((selectedTextPosition) => selectedTextPosition.getTextPositionsList().length > 0)
              .flatMap((selectedTextPosition) => {
                  return selectedTextPosition.getTextPositionsList()
                    .map((textPosition) => {
                      // generate range with id so we can filter aftet (if necessary)
                      let start = textPosition.getStartPosition() || 0;
                      let end = textPosition.getStopPosition() || 0;
                      let name = trainingInput.policyTextContent?.substring(start, end).toString() || "";
                      let textType = convertToTextType(selectedTextPosition.getTextTypeName());
                      let maybeDateList = convertExtractedDates(textPosition.getDates())
                      let range = new TrainingRange(name, start, end, textType, undefined, false, maybeDateList);
                      range.skynetModelMetadata = selectedTextPosition.getModelData();
                      return range;
                  }).filter((range) => {
                      // remove this range if it exists already...
                      return !existingRangeIds.includes(range.id);
                  })
              });
            let rejectionsFromSkynet = skynetResults.getSelectedTextPositionsList()
              .filter((selectedTextPosition) => selectedTextPosition.getTextPositionsList().length === 0)
              .map((selectedTextPosition) => {
                  let rejectedRow = new RejectedTextResultRow(convertToTextType(selectedTextPosition.getTextTypeName()), undefined, false);
                  rejectedRow.skynetModelMetadata = selectedTextPosition.getModelData();
                  return rejectedRow;
              }).filter((rejection) => !existingRejectionTextTypes.includes(rejection.textType));
            // store the results
            setRejectedTextResults([...rejectedTextResults, ...rejectionsFromSkynet].sort(RejectedTextResultRow.sortFn));
            setRanges([...ranges, ...rangesFromSkynet].sort(TrainingRange.sortFn));
            console.log(`skynet stats: ${skynetResults.getStatsList().length}`);
            setSkynetStats(skynetResults.getStatsList());
        }
    }

    const getSkynetTextResult = async (skynetServiceClient: SkynetServiceClient, text: string): Promise<GetTextResultsResponse> => {
        return new Promise<GetTextResultsResponse>((resolve, reject) => {
            let req = new GetTextResultsRequest().setPriority(Priority.PRIORITY_REALTIME);
            req.addData(new GetTextResultsRequest.TextData().setText(text).setTextType(InputTextType.INPUT_TEXT_TYPE_PRIVACY_POLICY_TEXT));
            skynetServiceClient.getTextResults(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response)
            });
        });
    }
    const getTrainingDataInput = async (scanServiceInternal: ScanServiceInternalClient, scanJobArtifactId: string | undefined, propertySetId: string | undefined, regionFilter: string): Promise<GetTrainingDataInputResponse> => {
        if (scanJobArtifactId === undefined) {
            return new Promise<GetTrainingDataInputResponse>((resolve, reject) => {
                let req = new GetRandomTrainingDataInputRequest();
                if( regionFilter.length > 0 && regionFilter !== SELECTOR_ALL_ID) {
                    req.setRegionId(regionFilter);
                }
                if( propertySetId !== undefined) {
                    req.setPropertySetId(propertySetId);
                }
                scanServiceInternal.getRandomTrainingDataInput(req, generateAuthHeader(), (err, response) => {
                    if (err) reject(err);
                    else resolve(response)
                });
            });
        } else {
            return new Promise<GetTrainingDataInputResponse>((resolve, reject) => {
                scanServiceInternal.getTrainingDataInput(new GetTrainingDataInputRequest().setArtifactId(scanJobArtifactId), generateAuthHeader(), (err, response) => {
                    if (err) reject(err);
                    else resolve(response)
                });
            });
        }
    }

    const updateTrainingData = async (scanServiceInternal: ScanServiceInternalClient, scanJobArtifactId: string, scanJobTrainingData: ScanJobArtifact.ScanJobTrainingData): Promise<number> => {
        var req = new UpdateTrainingDataRequest();
        req.setScanJobArtifactId(scanJobArtifactId);
        req.setScanJobTrainingData(scanJobTrainingData)

        return new Promise<number>((resolve, reject) => {
            scanServiceInternal.updateTrainingData(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getRecordsUpdated())
            });
        });
    }

    const renderTextTypeSelect = (rangeId: number, selectedTextType: TextResult.TextType, identifier: string) => {
        let uniqueIdentifier = `${rangeId}-identifier`
        return <FormControl sx={{ m: 1, minWidth: 120 }} error = {selectedTextType === TextResult.TextType.TEXT_TYPE_UNSET}>
            <InputLabel id={identifier}>Text Type</InputLabel>
            <Select
                size="small"
                label="Text Type"
                labelId={uniqueIdentifier}
                id={uniqueIdentifier}
                value={selectedTextType.toString()}
                onChange={(onChangeEvent) => handleTextTypeChange(rangeId, onChangeEvent)}>
                {
                    TEXT_TYPE_MENU_ITEMS.map(textType => {
                        return <MenuItem key={textType} value={textType}>{renderTextTypeText(textType)}</MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    }
    //TODO: figure out how to import DraftDecoratorComponentProps (https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecorator.js
    const ToolTip = (props: any) => {
        // console.log(props);
        let rangeStart = props.matchStart;
        let rangeEnd = props.matchEnd;
        let matchText = props.matchText;
        // console.log(props.className);
        let textType = props.className !== undefined ? parseInt(props.className) : TextResult.TextType.TEXT_TYPE_UNSET; // this is a hack to get the text type into here
        let rangeId = idGenerator(matchText, rangeStart, rangeEnd, textType);
        let matches = ranges.filter((range) => (range.visible && range.id === rangeId));
        // console.log(`Looking for range with id = [${rangeId}]`)
        if (matches.length > 0) {
            // let selectedRange = matches.length > 1 ? matches.filter((range) => range.visible)
            let selectedRange = matches[0];
            let hexColor = renderTextTypeHexColor(textType);
            return (
                <Tooltip
                    componentsProps={{
                        arrow: {
                            sx: {
                                color: 'rgba(220, 0, 78, 0.8)'
                            }
                        },
                        tooltip: {
                            sx: {
                                maxWidth: 300,
                                border: '1px solid #dadde9',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            },
                        },
                    }}
                    arrow={true}
                    id={`tooltip-${rangeId}`}
                    // leaveDelay={1000}
                    placement={"top"}
                    disableFocusListener title={
                        <>
                            <Box display="flex" alignItems="right" justifyContent="right">
                                {renderTextTypeSelect(rangeId,selectedRange.textType, "tooltip")}
                                <IconButton aria-label="delete" size="small" onClick={() => handleRemoveRange(rangeId)}><DeleteIcon/></IconButton>
                            </Box>

                        </>
                    }>
                    <mark style={{backgroundColor: hexColor, opacity:1.0}}>{props.children}</mark>
                </Tooltip>
            );
        } else {
            return ( <mark>{props.children}</mark>);
        }
    };
    const convertToHighlightArray = (): Highlight[] => {
        return Array.from(ranges.filter((trainingRange) => trainingRange.visible).map((trainingRange) => {
            let range: Range = [trainingRange.start || 0, trainingRange.end || 0];
            let customComponent : CustomObject = {
                component: ToolTip,
                highlight: range,
                className: trainingRange.textType.toString()
            }
            return customComponent;
        }));
    }
    const handleClear = () => {
        setRanges([]);
        setRejectedTextResults([]);
    }

    const handleToggleVisibility = () => {
      console.log("Toggling all visible rows");
      const newRanges = ranges.map(range => {
        // Toggle the visibility flag...
        return {...range, visible: !visibilityToggle};
      });
      setRanges(newRanges);
      setVisibilityToggle(!visibilityToggle);
    }

    const handleSkip = () => {
        setTrainingInput(undefined);
        setRanges([]);
        setRejectedTextResults([]);
        setRefreshToggle(prev => !prev);
    }

    const handleSkynetLoad = async () => {
        setLoading(true);
        await loadSkynetResults();
        setLoading(false);
    }

    const handleRegionFilterChange = (event: SelectChangeEvent) => {
        let revised = event.target.value;
        if ( revised !== regionFilter) {
            setRegionFilter(revised);
        }
    };

    const handleSubmit = async () => {
        // convert ranges to ScanJobArtifact.ScanJobTrainingData
        if ( trainingInput !== undefined && trainingInput?.scanJob !== undefined && trainingInput.scanJobArtifactId !== undefined) {
            let scanJobId = trainingInput.scanJob.getId();
            console.log(`Saving scanjob artifact training data for scanJobId=[${scanJobId}], scanJobArtifactId=[${trainingInput.scanJobArtifactId}]`);
            setLoading(true);
            let scanJobTrainingData = new ScanJobArtifact.ScanJobTrainingData();
            const rangeMap: Map<TextResult.TextType, TrainingRange[]> = groupByToMap(ranges.filter((range) => range.humanAcceptance), (range: TrainingRange) => range.textType);
            // determine range data
            rangeMap.forEach((value:TrainingRange[], key: TextResult.TextType) => {
                let textPositions = value.map((trainingRange) => {
                    let textPosition = new ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition().setStartPosition(trainingRange.start).setStopPosition(trainingRange.end);
                    if (trainingRange.skynetModelMetadata !== undefined) {
                        textPosition.setAudit(new ScanJobArtifact.ScanJobTrainingData.CreatedAuditData().setModelMetadata(trainingRange.skynetModelMetadata));
                    }
                    return textPosition;
                });
                let selectedTextPosition = new ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition()
                    .setTextType(key)
                    .setTextPositionsList(textPositions)
                scanJobTrainingData.addSelectedTextPositions(selectedTextPosition);
            });
            // determine rejected entries
            rejectedTextResults.filter((rejection) => rejection.humanAcceptance).forEach((row) => {
                let rejectedResult = new ScanJobArtifact.ScanJobTrainingData.RejectedTextResult().setTextType(row.textType);
                if (row.skynetModelMetadata !== undefined) {
                    rejectedResult.setAudit(new ScanJobArtifact.ScanJobTrainingData.CreatedAuditData().setModelMetadata(row.skynetModelMetadata));
                }
                scanJobTrainingData.addRejectedTextResults(rejectedResult)
            })
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const recordsUpdated = await updateTrainingData(scanServiceInternal, trainingInput.scanJobArtifactId, scanJobTrainingData);
            console.log(`Updated scanjob artifact training data: ${recordsUpdated} recordsUpdated`);
            if (continuousMode) {
                setTrainingInput(undefined);
                setRanges([]);
                setRejectedTextResults([]);
                setRefreshToggle(prev => !prev);
            }
            setLoading(false);
        } else {
            console.log("Error saving scanjob artifact training data!");
        }
    }

    const handleRemoveRange = (rangeId: number) => {
        console.log(`Removing range with id: ${rangeId}`);
        setRanges(ranges.filter((range) => (range.id !== rangeId)));
    }

    const handleRemoveRejectedTextResult = (textType: TextResult.TextType) => {
        console.log(`Removing missing input with textType: ${textType}`);
        setRejectedTextResults(rejectedTextResults.filter((row) => row.textType !== textType));
    }

    const handleRangeHumanAcceptanceToggle = (rangeId: number) => {
      console.log(`Adjusting humanAcceptance to range with id: ${rangeId}`);
      const newRanges = ranges.map(range => {
        if (range.id === rangeId) {
          // we found the corresponding range, now check rejections and see if there is any textType dupes to delete
          setRejectedTextResults(rejectedTextResults.filter((rejectionRow) => rejectionRow.textType !== range.textType));
          // Toggle the humanAcceptance flag...
          return {...range, humanAcceptance: !range.humanAcceptance};
        } else {
          return range;
        }
      });
      setRanges(newRanges);
    }
    const handleRejectionHumanAcceptanceToggle = (textType: TextResult.TextType) => {
        console.log(`Adjusting humanAcceptance to rejection with textType: ${textType}`);
        const newRejectionRows = rejectedTextResults.map(rejectionRow => {
            if (rejectionRow.textType === textType) {
                // we found the corresponding row, now check ranges and see if there is any textType dupes to delete
                setRanges(ranges.filter((range) => range.textType !== rejectionRow.textType));
                // Toggle the humanAcceptance flag...
                return {...rejectionRow, humanAcceptance: !rejectionRow.humanAcceptance};
            } else {
                return rejectionRow;
            }
        });
        setRejectedTextResults(newRejectionRows);
    }
    const handleRangeEditMode = (rangeId: number) => {
        console.log(`Adjusting editMode to range with id: ${rangeId}`);
        const newRanges = ranges.map(range => {
            // set range to editable and visible and mark all others is invisible.
            if (range.id === rangeId) {
                return {...range, editMode: !range.editMode, visible: true};
            } else {
                return {...range, editMode: false, visible: false};
            }
        });
        setRanges(newRanges);
    }

    const handleRangeVisibility = (rangeId: number) => {
        console.log(`Adjusting visibility to range with id: ${rangeId}`);
        const newRanges = ranges.map(range => {
            if (range.id === rangeId) {
                // toggle visibility
                return {...range, visible: !range.visible};
            } else {
                return range;
            }
        });
        setRanges(newRanges);
    }

    const handleTextTypeChange = (rangeId: number, event: SelectChangeEvent) => {
        let textType = parseInt(event.target.value);
        console.log(`Modifying text type on range with id: ${rangeId} with textType: ${textType}`);
        setLoading(true);
        // make a copy of ranges and mutate the specific entity inside.
        const newRanges = ranges.map(range => {
            if (range.id === rangeId) {
                return {...range, id: range.regenerateId(textType), textType: textType, visible: true};
            } else if (range.textType !== TextResult.TextType.TEXT_TYPE_UNSET && range.textType !== textType) {
                // we want to hide other text types
                return {...range, visible: false};
            } else {
                return range;
            }
        });
        setRanges(newRanges);
        // check to see if we have any missing input data related to this text type, remove if present
        setRejectedTextResults( old => old.filter((row => row.textType !== textType)));
        setLoading(false);
    }

    let handleHighlightSelectionChange = (nextValue: string, selection?: Selection | undefined) => {
        if (selection) {
            if ( selection.start !== selection.end ) {
                // grab the actual text and attempt to remove whitespace, while re-calculating start/end
                let stringAndSelection = trimWhiteSpace(nextValue, selection.start, selection.end);
                console.log(`Handling selection change - (${selection.start},${selection.end}) trimmed -> (${stringAndSelection.selectionStart},${stringAndSelection.selectionEnd})`);
                const start = stringAndSelection.selectionStart;
                const end = stringAndSelection.selectionEnd;
                // check to see if any range is in edit mode, if so, modify the start/end of that range and turn off edit mode
                if (ranges.filter((range) => (range.editMode)).length > 0) {
                    console.log('Editing existing range...');
                    const newRanges = ranges.map(range => {
                        if (range.editMode) {
                            return new TrainingRange(stringAndSelection.getSelectedText(), start, end, range.textType, range.audit, range.humanAcceptance, range.extractedDates);
                        } else {
                            return {...range};
                        }
                    }).sort(TrainingRange.sortFn);
                    setRanges(newRanges);
                } else {
                    console.log('Creating new range...');
                    // remove any existing TEXT_TYPE_UNSET entries and then persist new range and sort
                    setRanges([
                        ...ranges.filter((range) => (range.textType !== TextResult.TextType.TEXT_TYPE_UNSET)),
                        new TrainingRange(stringAndSelection.getSelectedText(), start, end, TextResult.TextType.TEXT_TYPE_UNSET, undefined, true, undefined),
                    ].sort(TrainingRange.sortFn));
                }
            }
        }
    };

    const handleRejectedTextResultInputChange = (event: SelectChangeEvent) => {
        let textType = parseInt(event.target.value);
        setRejectedTextResultInput(textType);
    }

    const handleRejectedTextResultInputSubmit = () => {
        let isAlreadyContained = rejectedTextResults.filter(row => {
            return row.textType === rejectedTextResultInput ? 1 : 0;
        }).length > 0
        if(!isAlreadyContained) {
            console.log(`Mark input data missing for textType: ${rejectedTextResultInput}`);
            setRejectedTextResults(old => [...old, new RejectedTextResultRow(rejectedTextResultInput, undefined, true)]);
            // check to see if we have any missing range data related to this text type, remove if present
            setRanges( old => old.filter((range => range.textType !== rejectedTextResultInput)));
        }
        setDialogOpen(false);
    }

    const handleDialogClickOpen = async () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"sm"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{"Reject Text Result?"}</DialogTitle>
                    <DialogContent>
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Toolbar disableGutters={true}>
                                    <FormControl size="small">
                                        <InputLabel size="small" id="rejectTextResult">Text Type</InputLabel>
                                        <Select
                                            size="small"
                                            label="Text Type"
                                            labelId="rejectTextResult"
                                            id="rejectTextResult"
                                            value={rejectedTextResultInput.toString()}
                                            onChange={(changeEvent) => handleRejectedTextResultInputChange(changeEvent)}>
                                            {
                                                TEXT_TYPE_MENU_ITEMS.filter((item) => item !== TextResult.TextType.TEXT_TYPE_UNSET).map(textType => {
                                                    return <MenuItem key={textType} value={textType}>{renderTextTypeText(textType)}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <LoadingButton size="small"
                                                   sx={{ ml: 1 }}
                                                   onClick={handleRejectedTextResultInputSubmit}
                                                   autoFocus
                                                   color="secondary"
                                                   startIcon={<ReportProblemIcon />}
                                                   variant="contained">
                                        Submit
                                    </LoadingButton>
                                </Toolbar>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1} alignItems="center">
                    { props.initialPropertySetId !== undefined &&
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                Exclusively using data from Property Set: <Link target='_blank' to={`/sets/property/${props.initialPropertySetId}`} >{props.initialPropertySetId|| ''}</Link>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={5}>
                        <Toolbar disableGutters={true}>
                            { continuousMode &&
                                <FormControl size="small">
                                    <InputLabel size="small" id="region">Region</InputLabel>
                                    <Select size="small" labelId="region" id="region" value={regionFilter} label="Region" onChange={handleRegionFilterChange}>
                                        {regionSelectorItems().map((region) => (
                                            <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            {continuousMode &&
                                <LoadingButton size="small"
                                               sx={{ ml: 1 }}
                                               onClick={handleSkip}
                                               autoFocus
                                               color="secondary"
                                               startIcon={<SkipNextIcon />}
                                               variant="contained">
                                    Skip
                                </LoadingButton>
                            }
                            <ScanTimelineImageArtifactViewButton sx={{ ml: 1 }}
                                                                 useLoadingButton={true}
                                                                 buttonTitle="Image"
                                                                 scanJobId={trainingInput?.scanJob?.getId()}
                                                                 artifactId={trainingInput?.policyImageArtifact?.getArtifactId()}/>

                            <LoadingButton sx={{ ml: 1 }}
                              size="small"
                              color="secondary"
                              onClick={handleSkynetLoad}
                              loading={loading}
                              loadingPosition="start"
                              startIcon={<RobotAngryOutlineIcon />}
                              variant="contained"
                            >
                                Skynet
                            </LoadingButton>
                        </Toolbar>
                    </Grid>
                    <Grid item xs={7}>
                        <Box display="flex" justifyContent="flex-end">
                            <TrainingMetadataButton scanJob={trainingInput?.scanJob} scanJobArtifactId={trainingInput?.scanJobArtifactId} stats={skynetStats}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                        {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> :
                            <Box sx={{height: 10}}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12} style={{ maxHeight: "50vh", overflow: 'auto'}}>
                        { trainingInput !== undefined &&
                            <HighlightWithinTextarea
                                value={trainingInput?.policyTextContent?.toString() || ''}
                                highlight={convertToHighlightArray()}
                                onChange={handleHighlightSelectionChange}
                                selection={selection}
                            />
                        }
                        { trainingInput === undefined &&
                            <Typography style={{fontWeight: 600}} variant="subtitle2" component="div">No more untrained jobs left for this search criteria.</Typography>
                        }
                    </Grid>
                    {trainingInput !== undefined &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <hr/>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} style={{maxHeight: "30vh"}}>
                                    <Table stickyHeader size="small" aria-label="ranges">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Text Type</StyledTableCell>
                                                <StyledTableCell align="center">Range</StyledTableCell>
                                                <StyledTableCell align="center">Selection</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rejectedTextResults.map((row) => (
                                                <InnerTableRow key={row.textType}>
                                                    <StyledTableCell align="left" colSpan={2}>{renderTextTypeText(row.textType)}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <TableContainer>
                                                            <Table stickyHeader size="small" aria-label="rejectedTextResultInput" padding="none">
                                                                <TableBody>
                                                                    <InnerTableRow>
                                                                        <InnerTableCell align="left" width="7%">&nbsp;</InnerTableCell>
                                                                        <InnerTableCell align="center" width="5%">
                                                                            { row.skynetModelMetadata !== undefined ?
                                                                              <Tooltip title={row.skynetModelMetadata?.getModel() + " " + row.skynetModelMetadata?.getVersion()} placement="top"><RobotAngryIcon sx={{color:"blue"}} /></Tooltip>
                                                                              :
                                                                              <React.Fragment>&nbsp;</React.Fragment>
                                                                            }
                                                                        </InnerTableCell>
                                                                        <InnerTableCell align="left" width="78%">
                                                                            <Typography sx={{color: "red"}} variant="inherit" component="div">Rejected</Typography>
                                                                        </InnerTableCell>
                                                                        <InnerTableCell align="right" width="10%">
                                                                            <Box display="flex" alignItems="right" justifyContent="right">
                                                                                { !row.humanAcceptance ?
                                                                                  <Tooltip title="Human Acceptance?" placement="top">
                                                                                      <IconButton aria-label="humanAcceptance" size="small" onClick={() => handleRejectionHumanAcceptanceToggle(row.textType)}>
                                                                                          <FlagIcon sx={{color:"red"}}/>
                                                                                      </IconButton>
                                                                                  </Tooltip> :
                                                                                  <Tooltip title="Human Accepted" placement="top">
                                                                                      <IconButton aria-label="humanAcceptance" size="small" onClick={() => handleRejectionHumanAcceptanceToggle(row.textType)}>
                                                                                          <FlagIcon sx={{color:"black"}}/>
                                                                                      </IconButton>
                                                                                  </Tooltip>
                                                                                }
                                                                                <IconButton size="small" disabled><Icon/></IconButton>
                                                                                <IconButton size="small" disabled><Icon/></IconButton>
                                                                                <TrainingRowMetadataButton rejectedRow={row}/>
                                                                                <Tooltip title="Delete" placement="top">
                                                                                    <IconButton aria-label="delete" size="small" onClick={() => handleRemoveRejectedTextResult(row.textType)}><DeleteIcon/></IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </InnerTableCell>
                                                                    </InnerTableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </StyledTableCell>
                                                </InnerTableRow>
                                            ))}
                                            {Array.from(groupByToMap(ranges, (range: TrainingRange) => range.textType)).map((entry: [TextResult.TextType, TrainingRange[]]) => (
                                                <InnerTableRow key={`${entry[0]}`}>
                                                    <StyledTableCell align="left">
                                                        {
                                                            entry[0] === TextResult.TextType.TEXT_TYPE_UNSET ? <Box sx={{ color: 'red' }}>{ renderTextTypeText(entry[0])}</Box> : renderTextTypeText(entry[0])
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <RectangleIcon sx={{color:renderTextTypeHexColor(entry[0])}}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <TableContainer>
                                                            <Table stickyHeader size="small" aria-label="ranges" padding="none">
                                                                <TableBody>
                                                                    {entry[1].map((trainingRange) => (
                                                                        <InnerTableRow key={`${trainingRange.id}`}>
                                                                            <InnerTableCell align="left" width="7%">{`${trainingRange.start}-${trainingRange.end}`}</InnerTableCell>
                                                                            <InnerTableCell align="center" width="5%">
                                                                                { trainingRange.skynetModelMetadata !== undefined ?
                                                                                  <Tooltip title={trainingRange.skynetModelMetadata?.getModel() + " " + trainingRange.skynetModelMetadata?.getVersion()} placement="top"><RobotAngryIcon sx={{color:"blue"}} /></Tooltip>
                                                                                  :
                                                                                  <React.Fragment>&nbsp;</React.Fragment>
                                                                                }
                                                                            </InnerTableCell>
                                                                            <InnerTableCell align="left" width="78%">{trainingRange.displayName}</InnerTableCell>
                                                                            <InnerTableCell align="right" width="10%">
                                                                                <Box display="flex" alignItems="right" justifyContent="right">
                                                                                    { !trainingRange.humanAcceptance ?
                                                                                      <Tooltip title="Human Acceptance?" placement="top">
                                                                                          <IconButton aria-label="humanAcceptance" size="small" onClick={() => handleRangeHumanAcceptanceToggle(trainingRange.id)}>
                                                                                            <FlagIcon sx={{color:"red"}}/>
                                                                                          </IconButton>
                                                                                      </Tooltip> :
                                                                                      <Tooltip title="Human Accepted" placement="top">
                                                                                          <IconButton aria-label="humanAcceptance" size="small" onClick={() => handleRangeHumanAcceptanceToggle(trainingRange.id)}>
                                                                                              <FlagIcon sx={{color:"black"}}/>
                                                                                          </IconButton>
                                                                                      </Tooltip>
                                                                                    }
                                                                                    <Tooltip title="Toggle Edit Mode" placement="top">
                                                                                        <IconButton aria-label="editMode" size="small" onClick={() => handleRangeEditMode(trainingRange.id)}>
                                                                                            {trainingRange.editMode ? <EditIcon sx={{color:"blue"}}/> : <EditIcon sx={{color:"grey"}}/>}
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Toggle Visibility" placement="top">
                                                                                        <IconButton aria-label="visible" size="small" onClick={() => handleRangeVisibility(trainingRange.id)}>
                                                                                            {trainingRange.visible ? <VisibilityIcon sx={{color:"blue"}}/> : <VisibilityOffIcon sx={{color:"grey"}}/>}
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <TrainingRowMetadataButton trainingRange={trainingRange}/>
                                                                                    <Tooltip title="Delete" placement="top">
                                                                                        <IconButton aria-label="delete" size="small" onClick={() => handleRemoveRange(trainingRange.id)}><DeleteIcon/></IconButton>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            </InnerTableCell>
                                                                        </InnerTableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </StyledTableCell>
                                                </InnerTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} >
                                <Box display="flex" alignItems="right" justifyContent="right">
                                    <LoadingButton size="small"
                                                   onClick={handleClear}
                                                   autoFocus
                                                   color="secondary"
                                                   startIcon={<BackspaceIcon />}
                                                   variant="contained"
                                                   disabled={ranges.length === 0 && rejectedTextResults.length === 0}>
                                        Clear
                                    </LoadingButton>
                                    <LoadingButton size="small"
                                                   sx={{ ml: 1 }}
                                                   onClick={handleToggleVisibility}
                                                   color="secondary"
                                                   startIcon={visibilityToggle ? <VisibilityOffIcon />: <VisibilityIcon />}
                                                   variant="contained"
                                                   disabled={ranges.length === 0 && rejectedTextResults.length === 0}>
                                        {visibilityToggle ? "Hide" : "Show"}
                                    </LoadingButton>
                                    <LoadingButton size="small"
                                                   sx={{ ml: 1 }}
                                                   onClick={handleDialogClickOpen}
                                                   color="secondary"
                                                   startIcon={<ReportProblemIcon />}
                                                   variant="contained"
                                                   disabled={loading}>
                                        Reject Data
                                    </LoadingButton>
                                    <LoadingButton size="small"
                                                   sx={{ ml: 1 }}
                                                   onClick={handleSubmit}
                                                   color="secondary"
                                                   startIcon={<SaveIcon />}
                                                   variant="contained"
                                                   disabled={(ranges.length === 0 && rejectedTextResults.length === 0) || ranges.filter((trainingRange) => trainingRange.textType === TextResult.TextType.TEXT_TYPE_UNSET).length > 0}>
                                        {continuousMode ? "Save&Next" : "Save"}
                                    </LoadingButton>
                                    { trainingInput.scanJobArtifactId !== undefined && !loading &&
                                      <DownloadTrainingModelButton sx={{ ml: 1 }} scanJobArtifactId={trainingInput.scanJobArtifactId}/>
                                    }
                                </Box>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanJobArtifactTrainingCard;