import React, {ReactElement, FC} from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import CmpScanJobStatusReportInfinityTable from "../CmpScanJobStatusReportInfinityTable";

interface CmpScanJobStatusReportsCardProps {
    maxHeight: number;
    showFilterUi: boolean;
    sx?: SxProps<Theme>;
}

const CmpScanJobStatusReportsCard: FC<CmpScanJobStatusReportsCardProps> = (props): ReactElement => {
    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1, mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">CMP Scan Report</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CmpScanJobStatusReportInfinityTable maxHeight={props.maxHeight} showFilterUi={props.showFilterUi}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CmpScanJobStatusReportsCard;