/**
 * @fileoverview gRPC-Web generated client stub for sp.scan_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var sp_service_common_common_pb = require('../../sp/service_common/common_pb.js')

var sp_scan_runner_common_pb = require('../../sp/scan_runner/common_pb.js')

var sp_scan_runner_scan_runner_pb = require('../../sp/scan_runner/scan_runner_pb.js')
const proto = {};
proto.sp = {};
proto.sp.scan_service = require('./scan_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.scan_service.ScanServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.scan_service.ScanServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.CreateAccountSettingsRequest,
 *   !proto.sp.scan_service.CreateAccountSettingsResponse>}
 */
const methodDescriptor_ScanService_CreateAccountSettings = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/CreateAccountSettings',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.CreateAccountSettingsRequest,
  proto.sp.scan_service.CreateAccountSettingsResponse,
  /**
   * @param {!proto.sp.scan_service.CreateAccountSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.CreateAccountSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.CreateAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.CreateAccountSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.CreateAccountSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.createAccountSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreateAccountSettings',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreateAccountSettings,
      callback);
};


/**
 * @param {!proto.sp.scan_service.CreateAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.CreateAccountSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.createAccountSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreateAccountSettings',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreateAccountSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UpdateAccountSettingsRequest,
 *   !proto.sp.scan_service.UpdateAccountSettingsResponse>}
 */
const methodDescriptor_ScanService_UpdateAccountSettings = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/UpdateAccountSettings',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UpdateAccountSettingsRequest,
  proto.sp.scan_service.UpdateAccountSettingsResponse,
  /**
   * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UpdateAccountSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UpdateAccountSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UpdateAccountSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.updateAccountSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/UpdateAccountSettings',
      request,
      metadata || {},
      methodDescriptor_ScanService_UpdateAccountSettings,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UpdateAccountSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.updateAccountSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/UpdateAccountSettings',
      request,
      metadata || {},
      methodDescriptor_ScanService_UpdateAccountSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetAccountSettingsByIdRequest,
 *   !proto.sp.scan_service.GetAccountSettingsByIdResponse>}
 */
const methodDescriptor_ScanService_GetAccountSettingsById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetAccountSettingsById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetAccountSettingsByIdRequest,
  proto.sp.scan_service.GetAccountSettingsByIdResponse,
  /**
   * @param {!proto.sp.scan_service.GetAccountSettingsByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetAccountSettingsByIdResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetAccountSettingsByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetAccountSettingsByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getAccountSettingsById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetAccountSettingsById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetAccountSettingsById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetAccountSettingsByIdResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getAccountSettingsById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetAccountSettingsById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetAccountSettingsById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetAccountSettingsRequest,
 *   !proto.sp.scan_service.GetAccountSettingsResponse>}
 */
const methodDescriptor_ScanService_GetAccountSettings = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetAccountSettings',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetAccountSettingsRequest,
  proto.sp.scan_service.GetAccountSettingsResponse,
  /**
   * @param {!proto.sp.scan_service.GetAccountSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetAccountSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetAccountSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetAccountSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getAccountSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetAccountSettings',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetAccountSettings,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetAccountSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getAccountSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetAccountSettings',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetAccountSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.service_common.GetByIdRequest,
 *   !proto.sp.scan_service.GetRegionResponse>}
 */
const methodDescriptor_ScanService_GetRegionById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetRegionById',
  grpc.web.MethodType.UNARY,
  sp_service_common_common_pb.GetByIdRequest,
  proto.sp.scan_service.GetRegionResponse,
  /**
   * @param {!proto.sp.service_common.GetByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetRegionResponse.deserializeBinary
);


/**
 * @param {!proto.sp.service_common.GetByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetRegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetRegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getRegionById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetRegionById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetRegionById,
      callback);
};


/**
 * @param {!proto.sp.service_common.GetByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetRegionResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getRegionById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetRegionById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetRegionById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetRegionsRequest,
 *   !proto.sp.scan_service.GetRegionsResponse>}
 */
const methodDescriptor_ScanService_GetRegions = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetRegions',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetRegionsRequest,
  proto.sp.scan_service.GetRegionsResponse,
  /**
   * @param {!proto.sp.scan_service.GetRegionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetRegionsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetRegionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetRegionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetRegionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getRegions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetRegions',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetRegions,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetRegionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetRegionsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getRegions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetRegions',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetRegions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanRuleByIdRequest,
 *   !proto.sp.scan_service.GetScanRuleByIdResponse>}
 */
const methodDescriptor_ScanService_GetScanRuleById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanRuleById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanRuleByIdRequest,
  proto.sp.scan_service.GetScanRuleByIdResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanRuleByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanRuleByIdResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanRuleByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanRuleByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanRuleByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanRuleById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRuleById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRuleById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanRuleByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanRuleByIdResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanRuleById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRuleById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRuleById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanRulesRequest,
 *   !proto.sp.scan_service.GetScanRulesResponse>}
 */
const methodDescriptor_ScanService_GetScanRules = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanRules',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanRulesRequest,
  proto.sp.scan_service.GetScanRulesResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanRulesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanRulesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanRulesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanRulesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanRulesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanRules =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRules',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRules,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanRulesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanRulesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanRules =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRules',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRules);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanJobByIdRequest,
 *   !proto.sp.scan_service.GetScanJobResponse>}
 */
const methodDescriptor_ScanService_GetScanJobById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanJobById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanJobByIdRequest,
  proto.sp.scan_service.GetScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanJobByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanJobByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanJobById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanJobById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanJobsRequest,
 *   !proto.sp.scan_service.GetScanJobsResponse>}
 */
const methodDescriptor_ScanService_GetScanJobs = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanJobs',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanJobsRequest,
  proto.sp.scan_service.GetScanJobsResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanJobsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanJobsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanJobsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanJobsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanJobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobs,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanJobsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanJobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetTimelineRequest,
 *   !proto.sp.scan_service.GetTimelineResponse>}
 */
const methodDescriptor_ScanService_GetTimeline = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetTimeline',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetTimelineRequest,
  proto.sp.scan_service.GetTimelineResponse,
  /**
   * @param {!proto.sp.scan_service.GetTimelineRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetTimelineResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetTimelineRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetTimelineResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetTimelineResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getTimeline =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetTimeline',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetTimeline,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetTimelineRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetTimelineResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getTimeline =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetTimeline',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetTimeline);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetTimelineArtifactRequest,
 *   !proto.sp.scan_service.GetTimelineArtifactResponse>}
 */
const methodDescriptor_ScanService_GetTimelineArtifact = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetTimelineArtifact',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetTimelineArtifactRequest,
  proto.sp.scan_service.GetTimelineArtifactResponse,
  /**
   * @param {!proto.sp.scan_service.GetTimelineArtifactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetTimelineArtifactResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetTimelineArtifactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetTimelineArtifactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetTimelineArtifactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getTimelineArtifact =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetTimelineArtifact',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetTimelineArtifact,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetTimelineArtifactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetTimelineArtifactResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getTimelineArtifact =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetTimelineArtifact',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetTimelineArtifact);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanJobArtifactByIdRequest,
 *   !proto.sp.scan_service.GetScanJobArtifactResponse>}
 */
const methodDescriptor_ScanService_GetScanJobArtifactById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanJobArtifactById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanJobArtifactByIdRequest,
  proto.sp.scan_service.GetScanJobArtifactResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanJobArtifactResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanJobArtifactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanJobArtifactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanJobArtifactById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobArtifactById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobArtifactById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanJobArtifactResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanJobArtifactById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobArtifactById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobArtifactById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanJobArtifactsRequest,
 *   !proto.sp.scan_service.GetScanJobArtifactsResponse>}
 */
const methodDescriptor_ScanService_GetScanJobArtifacts = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanJobArtifacts',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanJobArtifactsRequest,
  proto.sp.scan_service.GetScanJobArtifactsResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanJobArtifactsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanJobArtifactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanJobArtifactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanJobArtifacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobArtifacts',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobArtifacts,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanJobArtifactsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanJobArtifacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanJobArtifacts',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanJobArtifacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.CreatePropertyRequest,
 *   !proto.sp.scan_service.CreatePropertyResponse>}
 */
const methodDescriptor_ScanService_CreateProperty = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/CreateProperty',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.CreatePropertyRequest,
  proto.sp.scan_service.CreatePropertyResponse,
  /**
   * @param {!proto.sp.scan_service.CreatePropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.CreatePropertyResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.CreatePropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.CreatePropertyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.CreatePropertyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.createProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreateProperty',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreateProperty,
      callback);
};


/**
 * @param {!proto.sp.scan_service.CreatePropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.CreatePropertyResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.createProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreateProperty',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreateProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetPropertyByIdOrNameRequest,
 *   !proto.sp.scan_service.GetPropertyByIdOrNameResponse>}
 */
const methodDescriptor_ScanService_GetPropertyById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetPropertyById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetPropertyByIdOrNameRequest,
  proto.sp.scan_service.GetPropertyByIdOrNameResponse,
  /**
   * @param {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetPropertyByIdOrNameResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetPropertyByIdOrNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetPropertyByIdOrNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getPropertyById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertyById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertyById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetPropertyByIdOrNameResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getPropertyById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertyById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertyById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetPropertiesRequest,
 *   !proto.sp.scan_service.GetPropertiesResponse>}
 */
const methodDescriptor_ScanService_GetProperties = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetProperties',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetPropertiesRequest,
  proto.sp.scan_service.GetPropertiesResponse,
  /**
   * @param {!proto.sp.scan_service.GetPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetPropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetPropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetPropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetProperties',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetProperties,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetPropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetProperties',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.CreatePropertySetRequest,
 *   !proto.sp.scan_service.CreatePropertySetResponse>}
 */
const methodDescriptor_ScanService_CreatePropertySet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/CreatePropertySet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.CreatePropertySetRequest,
  proto.sp.scan_service.CreatePropertySetResponse,
  /**
   * @param {!proto.sp.scan_service.CreatePropertySetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.CreatePropertySetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.CreatePropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.CreatePropertySetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.CreatePropertySetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.createPropertySet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreatePropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreatePropertySet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.CreatePropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.CreatePropertySetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.createPropertySet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreatePropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreatePropertySet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UpdatePropertySetRequest,
 *   !proto.sp.scan_service.UpdatePropertySetResponse>}
 */
const methodDescriptor_ScanService_UpdatePropertySet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/UpdatePropertySet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UpdatePropertySetRequest,
  proto.sp.scan_service.UpdatePropertySetResponse,
  /**
   * @param {!proto.sp.scan_service.UpdatePropertySetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UpdatePropertySetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UpdatePropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UpdatePropertySetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UpdatePropertySetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.updatePropertySet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/UpdatePropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_UpdatePropertySet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UpdatePropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UpdatePropertySetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.updatePropertySet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/UpdatePropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_UpdatePropertySet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.AddItemsToPropertySetRequest,
 *   !proto.sp.scan_service.AddItemsToPropertySetResponse>}
 */
const methodDescriptor_ScanService_AddItemsToPropertySet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/AddItemsToPropertySet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.AddItemsToPropertySetRequest,
  proto.sp.scan_service.AddItemsToPropertySetResponse,
  /**
   * @param {!proto.sp.scan_service.AddItemsToPropertySetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.AddItemsToPropertySetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.AddItemsToPropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.AddItemsToPropertySetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.AddItemsToPropertySetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.addItemsToPropertySet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/AddItemsToPropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_AddItemsToPropertySet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.AddItemsToPropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.AddItemsToPropertySetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.addItemsToPropertySet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/AddItemsToPropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_AddItemsToPropertySet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.RemoveItemsFromPropertySetRequest,
 *   !proto.sp.scan_service.RemoveItemsFromPropertySetResponse>}
 */
const methodDescriptor_ScanService_RemoveItemsFromPropertySet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/RemoveItemsFromPropertySet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.RemoveItemsFromPropertySetRequest,
  proto.sp.scan_service.RemoveItemsFromPropertySetResponse,
  /**
   * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.RemoveItemsFromPropertySetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.RemoveItemsFromPropertySetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.RemoveItemsFromPropertySetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.removeItemsFromPropertySet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/RemoveItemsFromPropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_RemoveItemsFromPropertySet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.RemoveItemsFromPropertySetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.removeItemsFromPropertySet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/RemoveItemsFromPropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanService_RemoveItemsFromPropertySet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetPropertySetByIdRequest,
 *   !proto.sp.scan_service.GetPropertySetByIdResponse>}
 */
const methodDescriptor_ScanService_GetPropertySetById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetPropertySetById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetPropertySetByIdRequest,
  proto.sp.scan_service.GetPropertySetByIdResponse,
  /**
   * @param {!proto.sp.scan_service.GetPropertySetByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetPropertySetByIdResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetPropertySetByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetPropertySetByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetPropertySetByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getPropertySetById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySetById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySetById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySetByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetPropertySetByIdResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getPropertySetById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySetById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySetById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetPropertySetsRequest,
 *   !proto.sp.scan_service.GetPropertySetsResponse>}
 */
const methodDescriptor_ScanService_GetPropertySets = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetPropertySets',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetPropertySetsRequest,
  proto.sp.scan_service.GetPropertySetsResponse,
  /**
   * @param {!proto.sp.scan_service.GetPropertySetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetPropertySetsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetPropertySetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetPropertySetsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetPropertySetsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getPropertySets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySets',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySets,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySetsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetPropertySetsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getPropertySets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySets',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetPropertySnapshotByIdRequest,
 *   !proto.sp.scan_service.GetPropertySnapshotByIdResponse>}
 */
const methodDescriptor_ScanService_GetPropertySnapshotById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetPropertySnapshotById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetPropertySnapshotByIdRequest,
  proto.sp.scan_service.GetPropertySnapshotByIdResponse,
  /**
   * @param {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetPropertySnapshotByIdResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetPropertySnapshotByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetPropertySnapshotByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getPropertySnapshotById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySnapshotById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySnapshotById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetPropertySnapshotByIdResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getPropertySnapshotById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySnapshotById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySnapshotById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest,
 *   !proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse>}
 */
const methodDescriptor_ScanService_GetLatestPropertySnapshotByNameAndRegion = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetLatestPropertySnapshotByNameAndRegion',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest,
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse,
  /**
   * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getLatestPropertySnapshotByNameAndRegion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetLatestPropertySnapshotByNameAndRegion',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetLatestPropertySnapshotByNameAndRegion,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getLatestPropertySnapshotByNameAndRegion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetLatestPropertySnapshotByNameAndRegion',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetLatestPropertySnapshotByNameAndRegion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetPropertySnapshotsRequest,
 *   !proto.sp.scan_service.GetPropertySnapshotsResponse>}
 */
const methodDescriptor_ScanService_GetPropertySnapshots = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetPropertySnapshots',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetPropertySnapshotsRequest,
  proto.sp.scan_service.GetPropertySnapshotsResponse,
  /**
   * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetPropertySnapshotsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetPropertySnapshotsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetPropertySnapshotsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getPropertySnapshots =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySnapshots',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySnapshots,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetPropertySnapshotsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getPropertySnapshots =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetPropertySnapshots',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetPropertySnapshots);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.CreateScanRuleSetRequest,
 *   !proto.sp.scan_service.CreateScanRuleSetResponse>}
 */
const methodDescriptor_ScanService_CreateScanRuleSet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/CreateScanRuleSet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.CreateScanRuleSetRequest,
  proto.sp.scan_service.CreateScanRuleSetResponse,
  /**
   * @param {!proto.sp.scan_service.CreateScanRuleSetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.CreateScanRuleSetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.CreateScanRuleSetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.CreateScanRuleSetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.CreateScanRuleSetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.createScanRuleSet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreateScanRuleSet',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreateScanRuleSet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.CreateScanRuleSetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.CreateScanRuleSetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.createScanRuleSet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/CreateScanRuleSet',
      request,
      metadata || {},
      methodDescriptor_ScanService_CreateScanRuleSet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UpdateScanRuleSetRequest,
 *   !proto.sp.scan_service.UpdateScanRuleSetResponse>}
 */
const methodDescriptor_ScanService_UpdateScanRuleSet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/UpdateScanRuleSet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UpdateScanRuleSetRequest,
  proto.sp.scan_service.UpdateScanRuleSetResponse,
  /**
   * @param {!proto.sp.scan_service.UpdateScanRuleSetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UpdateScanRuleSetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UpdateScanRuleSetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UpdateScanRuleSetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UpdateScanRuleSetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.updateScanRuleSet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/UpdateScanRuleSet',
      request,
      metadata || {},
      methodDescriptor_ScanService_UpdateScanRuleSet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UpdateScanRuleSetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UpdateScanRuleSetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.updateScanRuleSet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/UpdateScanRuleSet',
      request,
      metadata || {},
      methodDescriptor_ScanService_UpdateScanRuleSet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanRuleSetByIdRequest,
 *   !proto.sp.scan_service.GetScanRuleSetByIdResponse>}
 */
const methodDescriptor_ScanService_GetScanRuleSetById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanRuleSetById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanRuleSetByIdRequest,
  proto.sp.scan_service.GetScanRuleSetByIdResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanRuleSetByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanRuleSetByIdResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanRuleSetByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanRuleSetByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanRuleSetById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRuleSetById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRuleSetById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanRuleSetByIdResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanRuleSetById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRuleSetById',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRuleSetById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanRuleSetsRequest,
 *   !proto.sp.scan_service.GetScanRuleSetsResponse>}
 */
const methodDescriptor_ScanService_GetScanRuleSets = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanService/GetScanRuleSets',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanRuleSetsRequest,
  proto.sp.scan_service.GetScanRuleSetsResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanRuleSetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanRuleSetsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanRuleSetsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanRuleSetsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceClient.prototype.getScanRuleSets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRuleSets',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRuleSets,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanRuleSetsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServicePromiseClient.prototype.getScanRuleSets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanService/GetScanRuleSets',
      request,
      metadata || {},
      methodDescriptor_ScanService_GetScanRuleSets);
};


module.exports = proto.sp.scan_service;

