import React, {FC} from "react";
import {Container} from '@mui/material';
import {Breakpoint} from "@mui/system";


type MasterContainerProps = {
    fullWidth?: boolean | string | undefined;
    children?: React.ReactNode;
}

const MasterContainer: FC<MasterContainerProps> = (props) => {
    let maxWidth: false | Breakpoint = props.fullWidth !== undefined && props.fullWidth ? false : 'lg';
    return (
        <Container sx={{
            flexGrow: 1,
            backgroundColor: 'whitesmoke',
            justifyContent: 'center',
            alignItems: 'center'
        }}
                   maxWidth={maxWidth}>
            {props.children}
        </Container>
    );
}

export default MasterContainer;