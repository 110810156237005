import React, {ReactElement, FC} from "react";
import {Box, Paper, Table, TableBody, TableContainer, TableHead, TableSortLabel} from '@mui/material';
import {Property} from '../generated/sp/scan_service/scan_service_pb';
import { enumName } from '../lib/enumUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import {Order, sortNumber, sortString, TableHeader} from "../lib/sortUtils";
import {useUpdateEffect} from "usehooks-ts";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {fetchScanRuleMapByName} from "../lib/scanServiceUtils";

interface RuleResultsTableProps {
  ruleResults: Property.RuleResult[] | undefined;
  maxHeight: number;
}

interface TableRow {
    scanRuleShortName: string;
    scanRuleShortNameSortable: number;
    scanRuleName: string;
    relatedScanJobs: string[];
    resultType: Property.ResultType;
    resultTypeName: string;
    accuracy: Property.RuleAccuracy;
    accuracyName: string;
}

const HEADER_SCAN_RULE_SHORT_NAME = "Rule ID";
const HEADER_SCAN_RULE_NAME = "Rule Name";
const HEADER_RELATED_SCAN_JOBS = "Related ScanJobs";
const HEADER_RESULT = "Result";
const HEADER_ACCURACY = "Accuracy";

const headers: TableHeader[] = [
    {headerName: HEADER_SCAN_RULE_SHORT_NAME, align: 'left'},
    {headerName: HEADER_SCAN_RULE_NAME, align: 'left'},
    {headerName: HEADER_RELATED_SCAN_JOBS, align: 'center'},
    {headerName: HEADER_RESULT, align: 'center'},
    {headerName: HEADER_ACCURACY, align: 'center'}]

const getResultTypeColor = (resultType: Property.ResultType) => {
    switch(resultType) {
        case Property.ResultType.RESULT_TYPE_FALSE:
            return "error.main";
        case Property.ResultType.RESULT_TYPE_TRUE:
            return "success.main";
        default:
            return "info.main";
    }
}

const getRuleAccuracyColor = (resultType: Property.RuleAccuracy) => {
    switch(resultType) {
        case Property.RuleAccuracy.RULE_ACCURACY_CORRECT:
            return "success.main";
        case Property.RuleAccuracy.RULE_ACCURACY_FALSE_POSITIVE:
        case Property.RuleAccuracy.RULE_ACCURACY_FALSE_NEGATIVE:
            return "warning.main";
        default:
            return "info.main";
    }
}

const RuleResultsTable: FC<RuleResultsTableProps> = (props): ReactElement => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>(HEADER_SCAN_RULE_SHORT_NAME);
    const [rows, setRows] = React.useState<TableRow[]>([]);

    useUpdateEffect(() => {
        (async () => {
            if( props.ruleResults !== undefined) {
                const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
                const scanRuleMap = await fetchScanRuleMapByName(scanService);
                const tableRows = props.ruleResults?.map((ruleResult) => {
                    return {
                        scanRuleShortName: ruleResult.getScanRuleShortName(),
                        scanRuleShortNameSortable: parseInt(ruleResult.getScanRuleShortName().substring(1) || ''), // assume ruleId is formatted like  'r17'
                        scanRuleName: scanRuleMap?.get(ruleResult.getScanRuleShortName())?.getName() || '',
                        relatedScanJobs: ruleResult.getRelevantScanJobIdsList(),
                        resultType: ruleResult.getResultType(),
                        resultTypeName: enumName(Property.ResultType, ruleResult.getResultType(), false),
                        accuracy: ruleResult.getRuleAccuracy2(),
                        accuracyName: enumName(Property.RuleAccuracy, ruleResult.getRuleAccuracy2(), false),
                    };
                });
                setRows(tableRows);
                console.log(`Generated ${tableRows.length} table rows`);
            }
        })();
    }, [props]);

    const sortRows = (rows: TableRow[], orderBy: string, order: Order): TableRow[] => {
        if (orderBy === HEADER_SCAN_RULE_SHORT_NAME) {
            rows.sort(function (a, b) {
                return sortNumber(a.scanRuleShortNameSortable, b.scanRuleShortNameSortable, order);
            });
        } else if (orderBy === HEADER_SCAN_RULE_NAME) {
            rows.sort(function (a, b) {
                return sortString(a.scanRuleName, b.scanRuleName, order);
            });
        } else if (orderBy === HEADER_RELATED_SCAN_JOBS) {
            rows.sort(function (a, b) {
                return sortNumber(a.relatedScanJobs.length, b.relatedScanJobs.length, order);
            });
        } else if (orderBy === HEADER_RESULT) {
            rows.sort(function (a, b) {
                return sortString(a.resultTypeName, b.resultTypeName, order);
            });
        } else if (orderBy === HEADER_ACCURACY) {
            rows.sort(function (a, b) {
                return sortString(a.accuracyName, b.accuracyName, order);
            });
        }
        return rows;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, header: string) => {
        const isAsc = orderBy === header && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header);
    };

    return (
      <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }}>
          <Table stickyHeader size="small" aria-label="Rule Results">
              <TableHead>
                  <StyledTableRow>
                      {headers.map(tableHeader => (
                          <StyledTableCell key={tableHeader.headerName} align={tableHeader.align}>
                              <TableSortLabel active={orderBy === tableHeader.headerName}
                                              direction={orderBy === tableHeader.headerName ? order : 'asc'}
                                              onClick={e => handleRequestSort(e, tableHeader.headerName)}
                              >
                                  {tableHeader.headerName}
                              </TableSortLabel>
                          </StyledTableCell>
                      ))}
                  </StyledTableRow>
              </TableHead>
              <TableBody>
                  {sortRows(rows, orderBy, order).map(row => (
                      <StyledTableRow hover key={row.scanRuleShortName}>
                          <StyledTableCell align="left">{row.scanRuleShortName}</StyledTableCell>
                          <StyledTableCell align="left">{row.scanRuleName}</StyledTableCell>
                          <StyledTableCell align="center">{row.relatedScanJobs.length}</StyledTableCell>
                          <StyledTableCell align="center"><Box sx={{ color: getResultTypeColor(row.resultType) }}>{row.resultTypeName}</Box></StyledTableCell>
                          <StyledTableCell align="center"><Box sx={{ color: getRuleAccuracyColor(row.accuracy) }}>{row.accuracyName}</Box></StyledTableCell>
                      </StyledTableRow>
                  ))}
              </TableBody>
          </Table>
      </TableContainer>
    );
}

export default RuleResultsTable;