import React, {ReactElement, FC, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography, useMediaQuery} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import RadarIcon from "@mui/icons-material/Radar";
import ScanJobInfinityTable from "../ScanJobInfinityTable";
import {formatRegion} from "../../lib/regionUtils";
import {useUpdateEffect} from "usehooks-ts";
import {fetchIabCmpMap} from "../../lib/iabUtils";

interface ScanJobsCmpPopupButtonProps {
  buttonPrefix: string;
  regionId: string;
  cmpId: number;
  newerThanDaysFilter: number;
  sx?: SxProps<Theme>;
}

const ScanJobsCmpPopupButton: FC<ScanJobsCmpPopupButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [cmpIdLookup, setCmpIdLookup] = useState<Map<number,string>>(new Map());
    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setDialogOpen(true);
      setLoading(true);
      setLoading(false);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    useUpdateEffect(() => {
      (async () => {
        let cmpLookup = await fetchIabCmpMap();
        setCmpIdLookup(cmpLookup);
      })();
    }, [dialogOpen]);

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={false} maxWidth={"lg"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Scan Jobs for {cmpIdLookup.get(props.cmpId)  || ''}</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                  <Grid item xs={2}>
                    <TextField size="small" label="Region" type="string" value={formatRegion(props.regionId)} inputProps={{ readOnly: true}} fullWidth/>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField size="small" label="Consent Platform" type="string" value={props.cmpId.toString() + " - " + (cmpIdLookup.get(props.cmpId)  || '')} inputProps={{ readOnly: true}} fullWidth/>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField size="small" label="Days Back" type="string" value={props.newerThanDaysFilter} inputProps={{ readOnly: true}} fullWidth/>
                  </Grid>
                  <Grid item xs={12}>
                    <ScanJobInfinityTable maxHeight={400} embeddedMode={false} showFilterUi={false} showExportUi={true} regionFilter={props.regionId} iabCmpFilter={props.cmpId.toString()} newerThanDaysFilter={props.newerThanDaysFilter}/>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="View Scan Jobs">
                <IconButton
                    sx={props.sx}
                    size="small"
                    color="secondary"
                    disabled={loading}
                    onClick={handleDialogClickOpen}
                    aria-label="View Scan Jobs"
                    component="span">
                  {props.buttonPrefix} <RadarIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default ScanJobsCmpPopupButton;