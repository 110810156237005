// source: sp/scan_runner/common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.sp.scan_runner.BoundingBox', null, global);
goog.exportSymbol('proto.sp.scan_runner.ConsentApiData', null, global);
goog.exportSymbol('proto.sp.scan_runner.ConsentApiData.TcfApiData', null, global);
goog.exportSymbol('proto.sp.scan_runner.ConsentApiData.UspApiData', null, global);
goog.exportSymbol('proto.sp.scan_runner.CookieData', null, global);
goog.exportSymbol('proto.sp.scan_runner.DisplayOption', null, global);
goog.exportSymbol('proto.sp.scan_runner.ElementData', null, global);
goog.exportSymbol('proto.sp.scan_runner.ElementDescription', null, global);
goog.exportSymbol('proto.sp.scan_runner.ElementLocation', null, global);
goog.exportSymbol('proto.sp.scan_runner.ElementLocation.ElementType', null, global);
goog.exportSymbol('proto.sp.scan_runner.ElementLocation.LocationTypeCase', null, global);
goog.exportSymbol('proto.sp.scan_runner.LanguageDetectionResult', null, global);
goog.exportSymbol('proto.sp.scan_runner.LinkResult', null, global);
goog.exportSymbol('proto.sp.scan_runner.LinkResult.LinkType', null, global);
goog.exportSymbol('proto.sp.scan_runner.LocalStorageDump', null, global);
goog.exportSymbol('proto.sp.scan_runner.PageSearchResult', null, global);
goog.exportSymbol('proto.sp.scan_runner.PageSearchResult.PageType', null, global);
goog.exportSymbol('proto.sp.scan_runner.Point', null, global);
goog.exportSymbol('proto.sp.scan_runner.TextResult', null, global);
goog.exportSymbol('proto.sp.scan_runner.TextResult.TextType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.Point.displayName = 'proto.sp.scan_runner.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.BoundingBox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.BoundingBox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.BoundingBox.displayName = 'proto.sp.scan_runner.BoundingBox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ElementDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ElementDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ElementDescription.displayName = 'proto.sp.scan_runner.ElementDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ElementData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ElementData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ElementData.displayName = 'proto.sp.scan_runner.ElementData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ElementLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.scan_runner.ElementLocation.oneofGroups_);
};
goog.inherits(proto.sp.scan_runner.ElementLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ElementLocation.displayName = 'proto.sp.scan_runner.ElementLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.LanguageDetectionResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.LanguageDetectionResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.LanguageDetectionResult.displayName = 'proto.sp.scan_runner.LanguageDetectionResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.TextResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.TextResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.TextResult.displayName = 'proto.sp.scan_runner.TextResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.LinkResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.LinkResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.LinkResult.displayName = 'proto.sp.scan_runner.LinkResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.PageSearchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.PageSearchResult.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.PageSearchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.PageSearchResult.displayName = 'proto.sp.scan_runner.PageSearchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.CookieData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.CookieData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.CookieData.displayName = 'proto.sp.scan_runner.CookieData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.LocalStorageDump = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.LocalStorageDump.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.LocalStorageDump, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.LocalStorageDump.displayName = 'proto.sp.scan_runner.LocalStorageDump';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ConsentApiData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ConsentApiData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ConsentApiData.displayName = 'proto.sp.scan_runner.ConsentApiData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ConsentApiData.TcfApiData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ConsentApiData.TcfApiData.displayName = 'proto.sp.scan_runner.ConsentApiData.TcfApiData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ConsentApiData.UspApiData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ConsentApiData.UspApiData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ConsentApiData.UspApiData.displayName = 'proto.sp.scan_runner.ConsentApiData.UspApiData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFieldWithDefault(msg, 1, 0),
    y: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.Point}
 */
proto.sp.scan_runner.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.Point;
  return proto.sp.scan_runner.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.Point}
 */
proto.sp.scan_runner.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 x = 1;
 * @return {number}
 */
proto.sp.scan_runner.Point.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.Point} returns this
 */
proto.sp.scan_runner.Point.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 y = 2;
 * @return {number}
 */
proto.sp.scan_runner.Point.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.Point} returns this
 */
proto.sp.scan_runner.Point.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.BoundingBox.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.BoundingBox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.BoundingBox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.BoundingBox.toObject = function(includeInstance, msg) {
  var f, obj = {
    upperLeft: (f = msg.getUpperLeft()) && proto.sp.scan_runner.Point.toObject(includeInstance, f),
    lowerRight: (f = msg.getLowerRight()) && proto.sp.scan_runner.Point.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.BoundingBox}
 */
proto.sp.scan_runner.BoundingBox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.BoundingBox;
  return proto.sp.scan_runner.BoundingBox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.BoundingBox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.BoundingBox}
 */
proto.sp.scan_runner.BoundingBox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.Point;
      reader.readMessage(value,proto.sp.scan_runner.Point.deserializeBinaryFromReader);
      msg.setUpperLeft(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.Point;
      reader.readMessage(value,proto.sp.scan_runner.Point.deserializeBinaryFromReader);
      msg.setLowerRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.BoundingBox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.BoundingBox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.BoundingBox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.BoundingBox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpperLeft();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_runner.Point.serializeBinaryToWriter
    );
  }
  f = message.getLowerRight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_runner.Point.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point upper_left = 1;
 * @return {?proto.sp.scan_runner.Point}
 */
proto.sp.scan_runner.BoundingBox.prototype.getUpperLeft = function() {
  return /** @type{?proto.sp.scan_runner.Point} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.Point, 1));
};


/**
 * @param {?proto.sp.scan_runner.Point|undefined} value
 * @return {!proto.sp.scan_runner.BoundingBox} returns this
*/
proto.sp.scan_runner.BoundingBox.prototype.setUpperLeft = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.BoundingBox} returns this
 */
proto.sp.scan_runner.BoundingBox.prototype.clearUpperLeft = function() {
  return this.setUpperLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.BoundingBox.prototype.hasUpperLeft = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point lower_right = 2;
 * @return {?proto.sp.scan_runner.Point}
 */
proto.sp.scan_runner.BoundingBox.prototype.getLowerRight = function() {
  return /** @type{?proto.sp.scan_runner.Point} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.Point, 2));
};


/**
 * @param {?proto.sp.scan_runner.Point|undefined} value
 * @return {!proto.sp.scan_runner.BoundingBox} returns this
*/
proto.sp.scan_runner.BoundingBox.prototype.setLowerRight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.BoundingBox} returns this
 */
proto.sp.scan_runner.BoundingBox.prototype.clearLowerRight = function() {
  return this.setLowerRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.BoundingBox.prototype.hasLowerRight = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ElementDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ElementDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ElementDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ElementDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.sp.scan_runner.ElementLocation.toObject(includeInstance, f),
    source: jspb.Message.getFieldWithDefault(msg, 3, ""),
    data: (f = msg.getData()) && proto.sp.scan_runner.ElementData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ElementDescription}
 */
proto.sp.scan_runner.ElementDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ElementDescription;
  return proto.sp.scan_runner.ElementDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ElementDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ElementDescription}
 */
proto.sp.scan_runner.ElementDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.ElementLocation;
      reader.readMessage(value,proto.sp.scan_runner.ElementLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 4:
      var value = new proto.sp.scan_runner.ElementData;
      reader.readMessage(value,proto.sp.scan_runner.ElementData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ElementDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ElementDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ElementDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ElementDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_runner.ElementLocation.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sp.scan_runner.ElementData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.scan_runner.ElementDescription.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
 */
proto.sp.scan_runner.ElementDescription.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ElementLocation location = 2;
 * @return {?proto.sp.scan_runner.ElementLocation}
 */
proto.sp.scan_runner.ElementDescription.prototype.getLocation = function() {
  return /** @type{?proto.sp.scan_runner.ElementLocation} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ElementLocation, 2));
};


/**
 * @param {?proto.sp.scan_runner.ElementLocation|undefined} value
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
*/
proto.sp.scan_runner.ElementDescription.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
 */
proto.sp.scan_runner.ElementDescription.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementDescription.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string source = 3;
 * @return {string}
 */
proto.sp.scan_runner.ElementDescription.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
 */
proto.sp.scan_runner.ElementDescription.prototype.setSource = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
 */
proto.sp.scan_runner.ElementDescription.prototype.clearSource = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementDescription.prototype.hasSource = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ElementData data = 4;
 * @return {?proto.sp.scan_runner.ElementData}
 */
proto.sp.scan_runner.ElementDescription.prototype.getData = function() {
  return /** @type{?proto.sp.scan_runner.ElementData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ElementData, 4));
};


/**
 * @param {?proto.sp.scan_runner.ElementData|undefined} value
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
*/
proto.sp.scan_runner.ElementDescription.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ElementDescription} returns this
 */
proto.sp.scan_runner.ElementDescription.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementDescription.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ElementData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ElementData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ElementData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ElementData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    boundingBox: (f = msg.getBoundingBox()) && proto.sp.scan_runner.BoundingBox.toObject(includeInstance, f),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    screenshot: msg.getScreenshot_asB64(),
    href: jspb.Message.getFieldWithDefault(msg, 5, ""),
    html: jspb.Message.getFieldWithDefault(msg, 6, ""),
    attrsMap: (f = msg.getAttrsMap()) ? f.toObject(includeInstance, undefined) : [],
    isVisible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    frameId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ElementData}
 */
proto.sp.scan_runner.ElementData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ElementData;
  return proto.sp.scan_runner.ElementData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ElementData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ElementData}
 */
proto.sp.scan_runner.ElementData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.BoundingBox;
      reader.readMessage(value,proto.sp.scan_runner.BoundingBox.deserializeBinaryFromReader);
      msg.setBoundingBox(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setScreenshot(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHref(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtml(value);
      break;
    case 7:
      var value = msg.getAttrsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVisible(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrameId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ElementData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ElementData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ElementData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ElementData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBoundingBox();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_runner.BoundingBox.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAttrsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFrameId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BoundingBox bounding_box = 2;
 * @return {?proto.sp.scan_runner.BoundingBox}
 */
proto.sp.scan_runner.ElementData.prototype.getBoundingBox = function() {
  return /** @type{?proto.sp.scan_runner.BoundingBox} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.BoundingBox, 2));
};


/**
 * @param {?proto.sp.scan_runner.BoundingBox|undefined} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
*/
proto.sp.scan_runner.ElementData.prototype.setBoundingBox = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearBoundingBox = function() {
  return this.setBoundingBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.hasBoundingBox = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setText = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearText = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.hasText = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes screenshot = 4;
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getScreenshot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes screenshot = 4;
 * This is a type-conversion wrapper around `getScreenshot()`
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getScreenshot_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getScreenshot()));
};


/**
 * optional bytes screenshot = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getScreenshot()`
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ElementData.prototype.getScreenshot_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getScreenshot()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setScreenshot = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearScreenshot = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.hasScreenshot = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string href = 5;
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getHref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setHref = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearHref = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.hasHref = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string html = 6;
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setHtml = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearHtml = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.hasHtml = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * map<string, string> attrs = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sp.scan_runner.ElementData.prototype.getAttrsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearAttrsMap = function() {
  this.getAttrsMap().clear();
  return this;};


/**
 * optional bool is_visible = 8;
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.getIsVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setIsVisible = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.clearIsVisible = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementData.prototype.hasIsVisible = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string frame_id = 9;
 * @return {string}
 */
proto.sp.scan_runner.ElementData.prototype.getFrameId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementData} returns this
 */
proto.sp.scan_runner.ElementData.prototype.setFrameId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.scan_runner.ElementLocation.oneofGroups_ = [[5]];

/**
 * @enum {number}
 */
proto.sp.scan_runner.ElementLocation.LocationTypeCase = {
  LOCATION_TYPE_NOT_SET: 0,
  UNIQUE_XPATH: 5
};

/**
 * @return {proto.sp.scan_runner.ElementLocation.LocationTypeCase}
 */
proto.sp.scan_runner.ElementLocation.prototype.getLocationTypeCase = function() {
  return /** @type {proto.sp.scan_runner.ElementLocation.LocationTypeCase} */(jspb.Message.computeOneofCase(this, proto.sp.scan_runner.ElementLocation.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ElementLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ElementLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ElementLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ElementLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    uniqueXpath: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ElementLocation}
 */
proto.sp.scan_runner.ElementLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ElementLocation;
  return proto.sp.scan_runner.ElementLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ElementLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ElementLocation}
 */
proto.sp.scan_runner.ElementLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.sp.scan_runner.ElementLocation.ElementType} */ (reader.readEnum());
      msg.setElementType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueXpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ElementLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ElementLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ElementLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ElementLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.sp.scan_runner.ElementLocation.ElementType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ElementLocation.ElementType = {
  ELEMENT_TYPE_UNSET: 0,
  ELEMENT_TYPE_CONSENT_OUTER_FRAME: 1,
  ELEMENT_TYPE_CONSENT_MAIN_ELEMENT: 2,
  ELEMENT_TYPE_CONSENT_ACCEPT_ALL: 3,
  ELEMENT_TYPE_CONSENT_ACCEPT_NECESSARY: 4,
  ELEMENT_TYPE_CONSENT_REJECT_ALL: 5,
  ELEMENT_TYPE_CONSENT_COOKIE_SETTINGS: 7,
  ELEMENT_TYPE_CONSENT_VENDOR_SETTINGS: 8,
  ELEMENT_TYPE_CONSENT_PRIVACY_POLICY: 9,
  ELEMENT_TYPE_CONSENT_COOKIE_POLICY: 10,
  ELEMENT_TYPE_PRIVACY_POLICY_LINK: 11,
  ELEMENT_TYPE_COOKIE_POLICY_LINK: 12,
  ELEMENT_TYPE_CCPA_OPTOUT_LINK: 13
};

/**
 * optional ElementType element_type = 2;
 * @return {!proto.sp.scan_runner.ElementLocation.ElementType}
 */
proto.sp.scan_runner.ElementLocation.prototype.getElementType = function() {
  return /** @type {!proto.sp.scan_runner.ElementLocation.ElementType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.scan_runner.ElementLocation.ElementType} value
 * @return {!proto.sp.scan_runner.ElementLocation} returns this
 */
proto.sp.scan_runner.ElementLocation.prototype.setElementType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementLocation} returns this
 */
proto.sp.scan_runner.ElementLocation.prototype.clearElementType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementLocation.prototype.hasElementType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string unique_xpath = 5;
 * @return {string}
 */
proto.sp.scan_runner.ElementLocation.prototype.getUniqueXpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ElementLocation} returns this
 */
proto.sp.scan_runner.ElementLocation.prototype.setUniqueXpath = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.sp.scan_runner.ElementLocation.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ElementLocation} returns this
 */
proto.sp.scan_runner.ElementLocation.prototype.clearUniqueXpath = function() {
  return jspb.Message.setOneofField(this, 5, proto.sp.scan_runner.ElementLocation.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ElementLocation.prototype.hasUniqueXpath = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.LanguageDetectionResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.LanguageDetectionResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.LanguageDetectionResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LanguageDetectionResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    lang: jspb.Message.getFieldWithDefault(msg, 1, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.LanguageDetectionResult}
 */
proto.sp.scan_runner.LanguageDetectionResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.LanguageDetectionResult;
  return proto.sp.scan_runner.LanguageDetectionResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.LanguageDetectionResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.LanguageDetectionResult}
 */
proto.sp.scan_runner.LanguageDetectionResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.LanguageDetectionResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.LanguageDetectionResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.LanguageDetectionResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LanguageDetectionResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string lang = 1;
 * @return {string}
 */
proto.sp.scan_runner.LanguageDetectionResult.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.LanguageDetectionResult} returns this
 */
proto.sp.scan_runner.LanguageDetectionResult.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float score = 2;
 * @return {number}
 */
proto.sp.scan_runner.LanguageDetectionResult.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.LanguageDetectionResult} returns this
 */
proto.sp.scan_runner.LanguageDetectionResult.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.TextResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.TextResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.TextResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.TextResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    textType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detectionResult: (f = msg.getDetectionResult()) && proto.sp.scan_runner.LanguageDetectionResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.TextResult}
 */
proto.sp.scan_runner.TextResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.TextResult;
  return proto.sp.scan_runner.TextResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.TextResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.TextResult}
 */
proto.sp.scan_runner.TextResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.TextResult.TextType} */ (reader.readEnum());
      msg.setTextType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = new proto.sp.scan_runner.LanguageDetectionResult;
      reader.readMessage(value,proto.sp.scan_runner.LanguageDetectionResult.deserializeBinaryFromReader);
      msg.setDetectionResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.TextResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.TextResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.TextResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.TextResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetectionResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.scan_runner.LanguageDetectionResult.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.TextResult.TextType = {
  TEXT_TYPE_UNSET: 0,
  TEXT_TYPE_PRIVACY_POLICY: 1,
  TEXT_TYPE_COOKIE_POLICY: 2,
  TEXT_TYPE_DELETE_MY_DATA: 3,
  TEXT_TYPE_DELETE_MY_DATA_PHONE_AVAILABLE: 4,
  TEXT_TYPE_INFORMED_OF_CONSENT_OUTCOME: 5,
  TEXT_TYPE_INFORMED_OF_ABILITY_TO_WITHDRAW: 6,
  TEXT_TYPE_INFORMED_OF_LEGITIMATE_INTEREST_FIRST: 7,
  TEXT_TYPE_INFORMED_OF_LEGITIMATE_INTEREST_SECOND: 8,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_CA_RESIDENT_RIGHTS_DESCRIPTION: 9,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_HOW_DATA_WILL_BE_USED: 10,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_WITH_WHOM_DATA_WILL_BE_SHARED: 11,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA: 12,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA: 13,
  TEXT_TYPE_GLOBAL_PRIVACY_CONTROL_RESPECTED: 14,
  TEXT_TYPE_PRIVACY_POLICY_TITLE: 15,
  TEXT_TYPE_PRIVACY_POLICY_UPDATED_DATE: 16
};

/**
 * optional TextType text_type = 1;
 * @return {!proto.sp.scan_runner.TextResult.TextType}
 */
proto.sp.scan_runner.TextResult.prototype.getTextType = function() {
  return /** @type {!proto.sp.scan_runner.TextResult.TextType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.TextResult.TextType} value
 * @return {!proto.sp.scan_runner.TextResult} returns this
 */
proto.sp.scan_runner.TextResult.prototype.setTextType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.sp.scan_runner.TextResult.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.TextResult} returns this
 */
proto.sp.scan_runner.TextResult.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LanguageDetectionResult detection_result = 3;
 * @return {?proto.sp.scan_runner.LanguageDetectionResult}
 */
proto.sp.scan_runner.TextResult.prototype.getDetectionResult = function() {
  return /** @type{?proto.sp.scan_runner.LanguageDetectionResult} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.LanguageDetectionResult, 3));
};


/**
 * @param {?proto.sp.scan_runner.LanguageDetectionResult|undefined} value
 * @return {!proto.sp.scan_runner.TextResult} returns this
*/
proto.sp.scan_runner.TextResult.prototype.setDetectionResult = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.TextResult} returns this
 */
proto.sp.scan_runner.TextResult.prototype.clearDetectionResult = function() {
  return this.setDetectionResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.TextResult.prototype.hasDetectionResult = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.LinkResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.LinkResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.LinkResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LinkResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    href: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.LinkResult}
 */
proto.sp.scan_runner.LinkResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.LinkResult;
  return proto.sp.scan_runner.LinkResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.LinkResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.LinkResult}
 */
proto.sp.scan_runner.LinkResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.LinkResult.LinkType} */ (reader.readEnum());
      msg.setLinkType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHref(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.LinkResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.LinkResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.LinkResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LinkResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHref();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.LinkResult.LinkType = {
  LINK_TYPE_UNSET: 0,
  LINK_TYPE_PRIVACY_POLICY: 1,
  LINK_TYPE_COOKIE_POLICY: 2
};

/**
 * optional LinkType link_type = 1;
 * @return {!proto.sp.scan_runner.LinkResult.LinkType}
 */
proto.sp.scan_runner.LinkResult.prototype.getLinkType = function() {
  return /** @type {!proto.sp.scan_runner.LinkResult.LinkType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.LinkResult.LinkType} value
 * @return {!proto.sp.scan_runner.LinkResult} returns this
 */
proto.sp.scan_runner.LinkResult.prototype.setLinkType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string href = 2;
 * @return {string}
 */
proto.sp.scan_runner.LinkResult.prototype.getHref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.LinkResult} returns this
 */
proto.sp.scan_runner.LinkResult.prototype.setHref = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.PageSearchResult.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.PageSearchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.PageSearchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.PageSearchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.PageSearchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    elementDescriptionsList: jspb.Message.toObjectList(msg.getElementDescriptionsList(),
    proto.sp.scan_runner.ElementDescription.toObject, includeInstance),
    textResultsList: jspb.Message.toObjectList(msg.getTextResultsList(),
    proto.sp.scan_runner.TextResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.PageSearchResult}
 */
proto.sp.scan_runner.PageSearchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.PageSearchResult;
  return proto.sp.scan_runner.PageSearchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.PageSearchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.PageSearchResult}
 */
proto.sp.scan_runner.PageSearchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.PageSearchResult.PageType} */ (reader.readEnum());
      msg.setPageType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageUrl(value);
      break;
    case 3:
      var value = new proto.sp.scan_runner.ElementDescription;
      reader.readMessage(value,proto.sp.scan_runner.ElementDescription.deserializeBinaryFromReader);
      msg.addElementDescriptions(value);
      break;
    case 4:
      var value = new proto.sp.scan_runner.TextResult;
      reader.readMessage(value,proto.sp.scan_runner.TextResult.deserializeBinaryFromReader);
      msg.addTextResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.PageSearchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.PageSearchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.PageSearchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.PageSearchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getElementDescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_runner.ElementDescription.serializeBinaryToWriter
    );
  }
  f = message.getTextResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sp.scan_runner.TextResult.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.PageSearchResult.PageType = {
  PAGE_TYPE_UNSET: 0,
  PAGE_TYPE_CONSENT_TOOL: 1,
  PAGE_TYPE_MAIN_PAGE: 2,
  PAGE_TYPE_PRIVACY_POLICY: 3,
  PAGE_TYPE_COOKIE_POLICY: 4
};

/**
 * optional PageType page_type = 1;
 * @return {!proto.sp.scan_runner.PageSearchResult.PageType}
 */
proto.sp.scan_runner.PageSearchResult.prototype.getPageType = function() {
  return /** @type {!proto.sp.scan_runner.PageSearchResult.PageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.PageSearchResult.PageType} value
 * @return {!proto.sp.scan_runner.PageSearchResult} returns this
 */
proto.sp.scan_runner.PageSearchResult.prototype.setPageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string page_url = 2;
 * @return {string}
 */
proto.sp.scan_runner.PageSearchResult.prototype.getPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.PageSearchResult} returns this
 */
proto.sp.scan_runner.PageSearchResult.prototype.setPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ElementDescription element_descriptions = 3;
 * @return {!Array<!proto.sp.scan_runner.ElementDescription>}
 */
proto.sp.scan_runner.PageSearchResult.prototype.getElementDescriptionsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ElementDescription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.ElementDescription, 3));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ElementDescription>} value
 * @return {!proto.sp.scan_runner.PageSearchResult} returns this
*/
proto.sp.scan_runner.PageSearchResult.prototype.setElementDescriptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_runner.ElementDescription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ElementDescription}
 */
proto.sp.scan_runner.PageSearchResult.prototype.addElementDescriptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_runner.ElementDescription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.PageSearchResult} returns this
 */
proto.sp.scan_runner.PageSearchResult.prototype.clearElementDescriptionsList = function() {
  return this.setElementDescriptionsList([]);
};


/**
 * repeated TextResult text_results = 4;
 * @return {!Array<!proto.sp.scan_runner.TextResult>}
 */
proto.sp.scan_runner.PageSearchResult.prototype.getTextResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.TextResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.TextResult, 4));
};


/**
 * @param {!Array<!proto.sp.scan_runner.TextResult>} value
 * @return {!proto.sp.scan_runner.PageSearchResult} returns this
*/
proto.sp.scan_runner.PageSearchResult.prototype.setTextResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sp.scan_runner.TextResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.TextResult}
 */
proto.sp.scan_runner.PageSearchResult.prototype.addTextResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sp.scan_runner.TextResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.PageSearchResult} returns this
 */
proto.sp.scan_runner.PageSearchResult.prototype.clearTextResultsList = function() {
  return this.setTextResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.CookieData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.CookieData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.CookieData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.CookieData.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    path: jspb.Message.getFieldWithDefault(msg, 4, ""),
    expires: jspb.Message.getFieldWithDefault(msg, 5, 0),
    httpOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    secure: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.CookieData}
 */
proto.sp.scan_runner.CookieData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.CookieData;
  return proto.sp.scan_runner.CookieData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.CookieData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.CookieData}
 */
proto.sp.scan_runner.CookieData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpires(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHttpOnly(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSecure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.CookieData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.CookieData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.CookieData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.CookieData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getHttpOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSecure();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.sp.scan_runner.CookieData.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.sp.scan_runner.CookieData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.sp.scan_runner.CookieData.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string path = 4;
 * @return {string}
 */
proto.sp.scan_runner.CookieData.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 expires = 5;
 * @return {number}
 */
proto.sp.scan_runner.CookieData.prototype.getExpires = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setExpires = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.clearExpires = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.CookieData.prototype.hasExpires = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool http_only = 6;
 * @return {boolean}
 */
proto.sp.scan_runner.CookieData.prototype.getHttpOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setHttpOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool secure = 7;
 * @return {boolean}
 */
proto.sp.scan_runner.CookieData.prototype.getSecure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_runner.CookieData} returns this
 */
proto.sp.scan_runner.CookieData.prototype.setSecure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.LocalStorageDump.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.LocalStorageDump.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.LocalStorageDump.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.LocalStorageDump} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LocalStorageDump.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.sp.scan_runner.CookieData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.LocalStorageDump}
 */
proto.sp.scan_runner.LocalStorageDump.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.LocalStorageDump;
  return proto.sp.scan_runner.LocalStorageDump.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.LocalStorageDump} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.LocalStorageDump}
 */
proto.sp.scan_runner.LocalStorageDump.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.CookieData;
      reader.readMessage(value,proto.sp.scan_runner.CookieData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.LocalStorageDump.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.LocalStorageDump.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.LocalStorageDump} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LocalStorageDump.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_runner.CookieData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CookieData data = 1;
 * @return {!Array<!proto.sp.scan_runner.CookieData>}
 */
proto.sp.scan_runner.LocalStorageDump.prototype.getDataList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.CookieData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.CookieData, 1));
};


/**
 * @param {!Array<!proto.sp.scan_runner.CookieData>} value
 * @return {!proto.sp.scan_runner.LocalStorageDump} returns this
*/
proto.sp.scan_runner.LocalStorageDump.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_runner.CookieData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.CookieData}
 */
proto.sp.scan_runner.LocalStorageDump.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_runner.CookieData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.LocalStorageDump} returns this
 */
proto.sp.scan_runner.LocalStorageDump.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ConsentApiData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ConsentApiData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ConsentApiData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ConsentApiData.toObject = function(includeInstance, msg) {
  var f, obj = {
    tcfData: (f = msg.getTcfData()) && proto.sp.scan_runner.ConsentApiData.TcfApiData.toObject(includeInstance, f),
    uspData: (f = msg.getUspData()) && proto.sp.scan_runner.ConsentApiData.UspApiData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ConsentApiData}
 */
proto.sp.scan_runner.ConsentApiData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ConsentApiData;
  return proto.sp.scan_runner.ConsentApiData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ConsentApiData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ConsentApiData}
 */
proto.sp.scan_runner.ConsentApiData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.ConsentApiData.TcfApiData;
      reader.readMessage(value,proto.sp.scan_runner.ConsentApiData.TcfApiData.deserializeBinaryFromReader);
      msg.setTcfData(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.ConsentApiData.UspApiData;
      reader.readMessage(value,proto.sp.scan_runner.ConsentApiData.UspApiData.deserializeBinaryFromReader);
      msg.setUspData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ConsentApiData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ConsentApiData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ConsentApiData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ConsentApiData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTcfData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_runner.ConsentApiData.TcfApiData.serializeBinaryToWriter
    );
  }
  f = message.getUspData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_runner.ConsentApiData.UspApiData.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ConsentApiData.TcfApiData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ConsentApiData.TcfApiData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.toObject = function(includeInstance, msg) {
  var f, obj = {
    tcfPolicyVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tcString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cmpId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cmpVersion: jspb.Message.getFieldWithDefault(msg, 4, 0),
    json: jspb.Message.getFieldWithDefault(msg, 5, ""),
    consentLanguage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gdprApplies: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ConsentApiData.TcfApiData;
  return proto.sp.scan_runner.ConsentApiData.TcfApiData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ConsentApiData.TcfApiData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTcfPolicyVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTcString(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCmpId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCmpVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsentLanguage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGdprApplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ConsentApiData.TcfApiData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ConsentApiData.TcfApiData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 tcf_policy_version = 1;
 * @return {number}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getTcfPolicyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setTcfPolicyVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.clearTcfPolicyVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.hasTcfPolicyVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tc_string = 2;
 * @return {string}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getTcString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setTcString = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.clearTcString = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.hasTcString = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 cmp_id = 3;
 * @return {number}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getCmpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setCmpId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.clearCmpId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.hasCmpId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 cmp_version = 4;
 * @return {number}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getCmpVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setCmpVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.clearCmpVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.hasCmpVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string json = 5;
 * @return {string}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string consent_language = 6;
 * @return {string}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getConsentLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setConsentLanguage = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.clearConsentLanguage = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.hasConsentLanguage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool gdpr_applies = 7;
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.getGdprApplies = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.setGdprApplies = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.TcfApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.clearGdprApplies = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.TcfApiData.prototype.hasGdprApplies = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ConsentApiData.UspApiData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ConsentApiData.UspApiData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uspString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    json: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ConsentApiData.UspApiData;
  return proto.sp.scan_runner.ConsentApiData.UspApiData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ConsentApiData.UspApiData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUspString(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ConsentApiData.UspApiData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ConsentApiData.UspApiData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 version = 1;
 * @return {number}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string usp_string = 2;
 * @return {string}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.getUspString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.setUspString = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.clearUspString = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.hasUspString = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string json = 3;
 * @return {string}
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ConsentApiData.UspApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.UspApiData.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TcfApiData tcf_data = 1;
 * @return {?proto.sp.scan_runner.ConsentApiData.TcfApiData}
 */
proto.sp.scan_runner.ConsentApiData.prototype.getTcfData = function() {
  return /** @type{?proto.sp.scan_runner.ConsentApiData.TcfApiData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ConsentApiData.TcfApiData, 1));
};


/**
 * @param {?proto.sp.scan_runner.ConsentApiData.TcfApiData|undefined} value
 * @return {!proto.sp.scan_runner.ConsentApiData} returns this
*/
proto.sp.scan_runner.ConsentApiData.prototype.setTcfData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.prototype.clearTcfData = function() {
  return this.setTcfData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.prototype.hasTcfData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UspApiData usp_data = 2;
 * @return {?proto.sp.scan_runner.ConsentApiData.UspApiData}
 */
proto.sp.scan_runner.ConsentApiData.prototype.getUspData = function() {
  return /** @type{?proto.sp.scan_runner.ConsentApiData.UspApiData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ConsentApiData.UspApiData, 2));
};


/**
 * @param {?proto.sp.scan_runner.ConsentApiData.UspApiData|undefined} value
 * @return {!proto.sp.scan_runner.ConsentApiData} returns this
*/
proto.sp.scan_runner.ConsentApiData.prototype.setUspData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ConsentApiData} returns this
 */
proto.sp.scan_runner.ConsentApiData.prototype.clearUspData = function() {
  return this.setUspData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ConsentApiData.prototype.hasUspData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.DisplayOption = {
  DISPLAY_OPTION_UNSET: 0,
  DISPLAY_OPTION_HOST_DISPLAY: 1,
  DISPLAY_OPTION_XVFB: 2,
  DISPLAY_OPTION_XVNC: 3
};

goog.object.extend(exports, proto.sp.scan_runner);
