// source: sp/skynet_service/skynet_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.sp.skynet_service.GetLanguageRequest', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetLanguageResponse', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetSimilarityRequest', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetSimilarityResponse', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsRequest', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsRequest.InputTextType', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsRequest.Priority', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsRequest.TextData', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse.ModelData', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition', null, global);
goog.exportSymbol('proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.ExtractedDataCase', null, global);
goog.exportSymbol('proto.sp.skynet_service.TextType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetSimilarityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetSimilarityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetSimilarityRequest.displayName = 'proto.sp.skynet_service.GetSimilarityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetSimilarityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetSimilarityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetSimilarityResponse.displayName = 'proto.sp.skynet_service.GetSimilarityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetLanguageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetLanguageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetLanguageRequest.displayName = 'proto.sp.skynet_service.GetLanguageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetLanguageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetLanguageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetLanguageResponse.displayName = 'proto.sp.skynet_service.GetLanguageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.skynet_service.GetTextResultsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsRequest.displayName = 'proto.sp.skynet_service.GetTextResultsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsRequest.TextData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsRequest.TextData.displayName = 'proto.sp.skynet_service.GetTextResultsRequest.TextData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.skynet_service.GetTextResultsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsResponse.displayName = 'proto.sp.skynet_service.GetTextResultsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.displayName = 'proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.repeatedFields_, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.displayName = 'proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.repeatedFields_, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.displayName = 'proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.oneofGroups_);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.displayName = 'proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.skynet_service.GetTextResultsResponse.ModelData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.skynet_service.GetTextResultsResponse.ModelData.displayName = 'proto.sp.skynet_service.GetTextResultsResponse.ModelData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetSimilarityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetSimilarityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetSimilarityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetSimilarityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    otherText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetSimilarityRequest}
 */
proto.sp.skynet_service.GetSimilarityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetSimilarityRequest;
  return proto.sp.skynet_service.GetSimilarityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetSimilarityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetSimilarityRequest}
 */
proto.sp.skynet_service.GetSimilarityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetSimilarityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetSimilarityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetSimilarityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetSimilarityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOtherText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.sp.skynet_service.GetSimilarityRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetSimilarityRequest} returns this
 */
proto.sp.skynet_service.GetSimilarityRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string other_text = 2;
 * @return {string}
 */
proto.sp.skynet_service.GetSimilarityRequest.prototype.getOtherText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetSimilarityRequest} returns this
 */
proto.sp.skynet_service.GetSimilarityRequest.prototype.setOtherText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetSimilarityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetSimilarityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetSimilarityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetSimilarityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    similarity: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetSimilarityResponse}
 */
proto.sp.skynet_service.GetSimilarityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetSimilarityResponse;
  return proto.sp.skynet_service.GetSimilarityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetSimilarityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetSimilarityResponse}
 */
proto.sp.skynet_service.GetSimilarityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSimilarity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetSimilarityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetSimilarityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetSimilarityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetSimilarityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimilarity();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double similarity = 1;
 * @return {number}
 */
proto.sp.skynet_service.GetSimilarityResponse.prototype.getSimilarity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.sp.skynet_service.GetSimilarityResponse} returns this
 */
proto.sp.skynet_service.GetSimilarityResponse.prototype.setSimilarity = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetLanguageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetLanguageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetLanguageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetLanguageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetLanguageRequest}
 */
proto.sp.skynet_service.GetLanguageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetLanguageRequest;
  return proto.sp.skynet_service.GetLanguageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetLanguageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetLanguageRequest}
 */
proto.sp.skynet_service.GetLanguageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetLanguageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetLanguageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetLanguageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetLanguageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.sp.skynet_service.GetLanguageRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetLanguageRequest} returns this
 */
proto.sp.skynet_service.GetLanguageRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetLanguageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetLanguageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetLanguageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetLanguageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetLanguageResponse}
 */
proto.sp.skynet_service.GetLanguageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetLanguageResponse;
  return proto.sp.skynet_service.GetLanguageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetLanguageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetLanguageResponse}
 */
proto.sp.skynet_service.GetLanguageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetLanguageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetLanguageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetLanguageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetLanguageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string language = 1;
 * @return {string}
 */
proto.sp.skynet_service.GetLanguageResponse.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetLanguageResponse} returns this
 */
proto.sp.skynet_service.GetLanguageResponse.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.skynet_service.GetTextResultsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.sp.skynet_service.GetTextResultsRequest.TextData.toObject, includeInstance),
    priority: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsRequest}
 */
proto.sp.skynet_service.GetTextResultsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsRequest;
  return proto.sp.skynet_service.GetTextResultsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsRequest}
 */
proto.sp.skynet_service.GetTextResultsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.skynet_service.GetTextResultsRequest.TextData;
      reader.readMessage(value,proto.sp.skynet_service.GetTextResultsRequest.TextData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.skynet_service.GetTextResultsRequest.Priority} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.skynet_service.GetTextResultsRequest.TextData.serializeBinaryToWriter
    );
  }
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.skynet_service.GetTextResultsRequest.InputTextType = {
  INPUT_TEXT_TYPE_UNSET: 0,
  INPUT_TEXT_TYPE_PRIVACY_POLICY_TEXT: 1,
  INPUT_TEXT_TYPE_CONSENT_TEXT: 2
};

/**
 * @enum {number}
 */
proto.sp.skynet_service.GetTextResultsRequest.Priority = {
  PRIORITY_UNSET: 0,
  PRIORITY_BATCH: 1,
  PRIORITY_REALTIME: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsRequest.TextData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsRequest.TextData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.TextData}
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsRequest.TextData;
  return proto.sp.skynet_service.GetTextResultsRequest.TextData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsRequest.TextData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.TextData}
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.skynet_service.GetTextResultsRequest.InputTextType} */ (reader.readEnum());
      msg.setTextType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsRequest.TextData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsRequest.TextData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.TextData} returns this
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional InputTextType text_type = 2;
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.InputTextType}
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.prototype.getTextType = function() {
  return /** @type {!proto.sp.skynet_service.GetTextResultsRequest.InputTextType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsRequest.InputTextType} value
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.TextData} returns this
 */
proto.sp.skynet_service.GetTextResultsRequest.TextData.prototype.setTextType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated TextData data = 1;
 * @return {!Array<!proto.sp.skynet_service.GetTextResultsRequest.TextData>}
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.getDataList = function() {
  return /** @type{!Array<!proto.sp.skynet_service.GetTextResultsRequest.TextData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.skynet_service.GetTextResultsRequest.TextData, 1));
};


/**
 * @param {!Array<!proto.sp.skynet_service.GetTextResultsRequest.TextData>} value
 * @return {!proto.sp.skynet_service.GetTextResultsRequest} returns this
*/
proto.sp.skynet_service.GetTextResultsRequest.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsRequest.TextData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.TextData}
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.skynet_service.GetTextResultsRequest.TextData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.skynet_service.GetTextResultsRequest} returns this
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional Priority priority = 2;
 * @return {!proto.sp.skynet_service.GetTextResultsRequest.Priority}
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.getPriority = function() {
  return /** @type {!proto.sp.skynet_service.GetTextResultsRequest.Priority} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsRequest.Priority} value
 * @return {!proto.sp.skynet_service.GetTextResultsRequest} returns this
 */
proto.sp.skynet_service.GetTextResultsRequest.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.skynet_service.GetTextResultsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedTextPositionsList: jspb.Message.toObjectList(msg.getSelectedTextPositionsList(),
    proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.toObject, includeInstance),
    statsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse}
 */
proto.sp.skynet_service.GetTextResultsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsResponse;
  return proto.sp.skynet_service.GetTextResultsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse}
 */
proto.sp.skynet_service.GetTextResultsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition;
      reader.readMessage(value,proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.deserializeBinaryFromReader);
      msg.addSelectedTextPositions(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedTextPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.serializeBinaryToWriter
    );
  }
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate;
  return proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.setDay = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.clearDay = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.prototype.hasDay = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.toObject = function(includeInstance, msg) {
  var f, obj = {
    datesList: jspb.Message.toObjectList(msg.getDatesList(),
    proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates;
  return proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate;
      reader.readMessage(value,proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.deserializeBinaryFromReader);
      msg.addDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExtractedDate dates = 1;
 * @return {!Array<!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate>}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.prototype.getDatesList = function() {
  return /** @type{!Array<!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate, 1));
};


/**
 * @param {!Array<!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate>} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates} returns this
*/
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.prototype.setDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate}
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.prototype.addDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.skynet_service.GetTextResultsResponse.ExtractedDate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.prototype.clearDatesList = function() {
  return this.setDatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    textType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    textPositionsList: jspb.Message.toObjectList(msg.getTextPositionsList(),
    proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.toObject, includeInstance),
    modelData: (f = msg.getModelData()) && proto.sp.skynet_service.GetTextResultsResponse.ModelData.toObject(includeInstance, f),
    textTypeName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition;
  return proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.skynet_service.TextType} */ (reader.readEnum());
      msg.setTextType(value);
      break;
    case 2:
      var value = new proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition;
      reader.readMessage(value,proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.deserializeBinaryFromReader);
      msg.addTextPositions(value);
      break;
    case 3:
      var value = new proto.sp.skynet_service.GetTextResultsResponse.ModelData;
      reader.readMessage(value,proto.sp.skynet_service.GetTextResultsResponse.ModelData.deserializeBinaryFromReader);
      msg.setModelData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextTypeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTextPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.serializeBinaryToWriter
    );
  }
  f = message.getModelData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.skynet_service.GetTextResultsResponse.ModelData.serializeBinaryToWriter
    );
  }
  f = message.getTextTypeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.ExtractedDataCase = {
  EXTRACTED_DATA_NOT_SET: 0,
  DATES: 3
};

/**
 * @return {proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.ExtractedDataCase}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.getExtractedDataCase = function() {
  return /** @type {proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.ExtractedDataCase} */(jspb.Message.computeOneofCase(this, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    startPosition: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stopPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dates: (f = msg.getDates()) && proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition;
  return proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopPosition(value);
      break;
    case 3:
      var value = new proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates;
      reader.readMessage(value,proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.deserializeBinaryFromReader);
      msg.setDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStopPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 start_position = 1;
 * @return {number}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.getStartPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.setStartPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 stop_position = 2;
 * @return {number}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.getStopPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.setStopPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ExtractedDates dates = 3;
 * @return {?proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.getDates = function() {
  return /** @type{?proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates} */ (
    jspb.Message.getWrapperField(this, proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates, 3));
};


/**
 * @param {?proto.sp.skynet_service.GetTextResultsResponse.ExtractedDates|undefined} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} returns this
*/
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.setDates = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.clearDates = function() {
  return this.setDates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition.prototype.hasDates = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TextType text_type = 1;
 * @return {!proto.sp.skynet_service.TextType}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.getTextType = function() {
  return /** @type {!proto.sp.skynet_service.TextType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.skynet_service.TextType} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.setTextType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated TextPosition text_positions = 2;
 * @return {!Array<!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition>}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.getTextPositionsList = function() {
  return /** @type{!Array<!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition, 2));
};


/**
 * @param {!Array<!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition>} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} returns this
*/
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.setTextPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.addTextPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.TextPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.clearTextPositionsList = function() {
  return this.setTextPositionsList([]);
};


/**
 * optional ModelData model_data = 3;
 * @return {?proto.sp.skynet_service.GetTextResultsResponse.ModelData}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.getModelData = function() {
  return /** @type{?proto.sp.skynet_service.GetTextResultsResponse.ModelData} */ (
    jspb.Message.getWrapperField(this, proto.sp.skynet_service.GetTextResultsResponse.ModelData, 3));
};


/**
 * @param {?proto.sp.skynet_service.GetTextResultsResponse.ModelData|undefined} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} returns this
*/
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.setModelData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.clearModelData = function() {
  return this.setModelData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.hasModelData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string text_type_name = 4;
 * @return {string}
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.getTextTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition.prototype.setTextTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.skynet_service.GetTextResultsResponse.ModelData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ModelData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ModelData}
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.skynet_service.GetTextResultsResponse.ModelData;
  return proto.sp.skynet_service.GetTextResultsResponse.ModelData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ModelData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ModelData}
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.skynet_service.GetTextResultsResponse.ModelData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.ModelData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string model = 1;
 * @return {string}
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ModelData} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.ModelData} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.ModelData.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SelectedTextPosition selected_text_positions = 1;
 * @return {!Array<!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition>}
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.getSelectedTextPositionsList = function() {
  return /** @type{!Array<!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition, 1));
};


/**
 * @param {!Array<!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition>} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse} returns this
*/
proto.sp.skynet_service.GetTextResultsResponse.prototype.setSelectedTextPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition}
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.addSelectedTextPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.skynet_service.GetTextResultsResponse.SelectedTextPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.clearSelectedTextPositionsList = function() {
  return this.setSelectedTextPositionsList([]);
};


/**
 * repeated string stats = 2;
 * @return {!Array<string>}
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.getStatsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.skynet_service.GetTextResultsResponse} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.setStatsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.skynet_service.GetTextResultsResponse} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.addStats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.skynet_service.GetTextResultsResponse} returns this
 */
proto.sp.skynet_service.GetTextResultsResponse.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};


/**
 * @enum {number}
 */
proto.sp.skynet_service.TextType = {
  TEXT_TYPE_UNSET: 0,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA: 12,
  TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA: 13,
  TEXT_TYPE_GCP_MENTIONED: 100
};

goog.object.extend(exports, proto.sp.skynet_service);
