const downloadByteArray = (arrayBuffer: Uint8Array, fileName: string, fileType: string) => {
    const a = document.createElement('a')
    a.href = URL.createObjectURL(new Blob(
        [arrayBuffer],
        {type: fileType}
    ))
    a.download = fileName
    a.click()
}

export {downloadByteArray};