import React, {FC, ReactElement, useContext, useRef, useState} from "react";
import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from '@mui/material';
import {VncScreen} from 'react-vnc';
import LoadingButton from "@mui/lab/LoadingButton";
import {getWssUri} from "../../lib/networkUtils";
import {regionSelectorItems} from "../../lib/regionUtils";
import isFQDN from "validator/lib/isFQDN";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {ProxyType, StartChromeRequest} from '../../generated/sp/scan_runner/scan_runner_pb';
import {generateAuthHeader, getStoredAuthResponse, isUserAdminRole} from "../../lib/authorizationUtils";
import {ScanRunnerServiceClient} from "../../generated/sp/scan_runner/scan_runner_grpc_web_pb";
import {SourcePointTokensContext} from "../../App";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

const wss_endpoint = `/vnc/vnc-test-`

interface VncCardProps {
    sx?: SxProps<Theme>;
}

const VncCard: FC<VncCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [nodeId, setNodeId] = useState<number>(1);
    const [proxyType, setProxyType] = useState<ProxyType>(ProxyType.PROXY_TYPE_SOURCEPOINT_DATACENTER);
    const vncScreenRef = useRef<React.ElementRef<typeof VncScreen>>(null);
    const [vncPassword, setVncPassword] = useState<string | undefined>(undefined);
    const [vncConnected, setVncConnected] = useState<boolean>(false);
    const [region, setRegion] = useState<string>("US-CA");
    const [errorText, setErrorText] = useState<string>();
    const [warningText, setWarningText] = useState<string>();
    const [websiteUri, setWebsiteUri] = useState<string>("");
    const [desktopName, setDesktopName] = useState<string | undefined>(undefined);
    const sourcePointTokens = useContext(SourcePointTokensContext);

    const generateWebSocketUrl = (node: number): string => {
        return `${getWssUri()}${wss_endpoint}${node}`;
    }

    const generateRfbOptions = () => {
        return {
            "shared": true,
            "credentials": {
                "password": vncPassword
            }
        };
    }

    const onVncConnect = () => {
        console.log("Connected to vnc..");
        setVncConnected(true);
    }

    const onVncDisconnect = () => {
        console.log("Disconnected from vnc..");
        setVncConnected(false);
        setDesktopName(undefined);
    }

    const onDesktopName = (e?: { detail: { name: string; } } | undefined) => {
        if (e !== undefined) {
            console.log(`Desktop connected: ${e.detail.name}`);
            setDesktopName(e.detail.name);
        }
    }

    const handleNodeChange = (event: SelectChangeEvent) => {
        setNodeId(parseInt(event.target.value));
    };

    const handleProxyTypeChange = (event: SelectChangeEvent) => {
        let proxyTypeToBeSet = parseInt(event.target.value);
        setProxyType(proxyTypeToBeSet);
        if (proxyTypeToBeSet === ProxyType.PROXY_TYPE_BRIGHTDATA_RESIDENTIAL) {
            setWarningText('Warning: Usage of residential proxies is highly expensive!')
        } else {
            setWarningText('')
        }
    };

    const handleRegionChange = (event: SelectChangeEvent) => {
        setRegion(event.target.value);
    };

    const handleWebsiteUriChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let website = event.target.value;
        setWebsiteUri(website);
        if (isFQDN(website)) {
            setErrorText('')
        } else {
            setErrorText('Is Not Valid FQDN')
        }
    }

    const handleLaunchChrome = async () => {
        if (vncConnected) {
            const {connected, disconnect} = vncScreenRef.current ?? {};
            if (connected) {
                disconnect?.();
            }
            setVncPassword(undefined);
        } else {
            setLoading(true);
            const scanRunnerServiceClient = new ScanRunnerServiceClient(`${process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!}/vnc/${nodeId}`);
            Promise.resolve(startChrome(scanRunnerServiceClient, proxyType, region, websiteUri)).then(function (vncPassword) {
                console.log(`Starting chrome in the remote vnc session and received vncPassword=${vncPassword}`);
                setVncPassword(vncPassword); // this will trigger the react element to present itself
                // wait for the React vnc element to become available
                (async () => {
                    console.log("Waiting for vncScreenRef to become available...");
                    while (vncScreenRef === undefined || vncScreenRef.current === null) {
                        console.log("vncScreenRef is not ready yet!");
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                    console.log("vncScreenRef is ready");
                })();

                const {connect, connected} = vncScreenRef.current ?? {};
                if (!connected) {
                    connect?.();
                }
                setLoading(false);
            }, function (error) {
                console.log(`Error launching chrome: ${error}`);
                //TODO: do something with this error
                setLoading(false);
            });
        }
    };

    const startChrome = async (scanRunnerServiceClient: ScanRunnerServiceClient, proxyType: ProxyType, regionId: string, domain: string): Promise<string> => {
        var req = new StartChromeRequest();
        let authToken = getStoredAuthResponse();
        req.setProxyType(proxyType)
        req.setRegionId(regionId);
        req.setDomain(domain);
        req.setAuthToken(authToken!);
        return new Promise<string>((resolve, reject) => {
            scanRunnerServiceClient.startChrome(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getVncPassword());
            });
        });
    }
    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Typography style={{fontWeight: 600}} variant="subtitle2" component="div">
                                    Launch Chrome
                                </Typography>
                            </Grid>
                            {isUserAdminRole(sourcePointTokens) &&
                                <Grid item xs={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="nodeId">Node</InputLabel>
                                        <Select size="small"
                                                labelId="nodeId"
                                                id="nodeId"
                                                disabled={vncConnected || loading}
                                                value={nodeId.toString()}
                                                label="Node"
                                                onChange={handleNodeChange}>
                                            <MenuItem key="1" value="1">1</MenuItem>
                                            <MenuItem key="2" value="2">2</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="displayOption">Proxy Type</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="proxyType"
                                        id="proxyType"
                                        disabled={vncConnected || loading}
                                        value={proxyType.toString()} label="Proxy Type"
                                        onChange={x => handleProxyTypeChange(x)}>
                                        <MenuItem value={ProxyType.PROXY_TYPE_SOURCEPOINT_DATACENTER}>Sourcepoint DC</MenuItem>
                                        <MenuItem value={ProxyType.PROXY_TYPE_BRIGHTDATA_DATACENTER}>BrightData DC</MenuItem>
                                        <MenuItem value={ProxyType.PROXY_TYPE_BRIGHTDATA_RESIDENTIAL}>BrightData Residential</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="status">Region</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="region"
                                        id="region"
                                        disabled={vncConnected || loading}
                                        value={region}
                                        label="Region"
                                        onChange={handleRegionChange}>
                                        {regionSelectorItems().map((region) => (
                                            <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    label="Website Domain"
                                    type="string"
                                    id="websiteDomain"
                                    disabled={vncConnected || loading}
                                    value={websiteUri || ''}
                                    onChange={x => handleWebsiteUriChange(x)} helperText={errorText}
                                    error={!!errorText}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="small"
                                    color="secondary"
                                    onClick={handleLaunchChrome}
                                    loading={loading}
                                    disabled={errorText === undefined || errorText.length > 0}
                                    loadingPosition="start"
                                    startIcon={<PlayArrowIcon/>}
                                    variant="contained"
                                >
                                    {!vncConnected ? "Launch" : "Disconnect"}
                                </LoadingButton>
                            </Grid>
                            {warningText !== undefined &&
                                <Grid item xs={12}>
                                    <Typography sx={{color: "orange"}} component="div">{warningText}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {vncConnected && desktopName !== undefined &&
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: 100}} align="center" variant="subtitle2" component="div">
                                Connected to: {desktopName}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {vncPassword !== undefined &&
                            <VncScreen
                                url={generateWebSocketUrl(nodeId)}
                                scaleViewport={true}
                                background="#FFFFFF"
                                style={{
                                    width: '100vw',
                                    height: '100vh',
                                }}
                                debug={true}
                                autoConnect={true}
                                retryDuration={2000}
                                rfbOptions={generateRfbOptions()}
                                onConnect={onVncConnect}
                                onDisconnect={onVncDisconnect}
                                onDesktopName={onDesktopName}
                                loadingUI={<LinearProgress color="secondary"/>}
                                ref={vncScreenRef}
                            />
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default VncCard;