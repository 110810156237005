import {TextResult} from "../generated/sp/scan_runner/common_pb";
import {ScanJobArtifact} from "../generated/sp/scan_service/scan_service_pb";
import {formatStringWithLeadingAndTrailingSnippets} from "./stringUtils";
import {hashCode} from "./encodeUtils";

const MAX_STRING_LENGTH = 150;

class TrainingRange {
  id: number;
  text: string;
  displayName: string;
  start: number;
  end: number;
  textType: TextResult.TextType;
  audit: ScanJobArtifact.ScanJobTrainingData.CreatedAuditData | undefined;
  visible: boolean;
  editMode: boolean;
  skynetModelMetadata: ScanJobArtifact.ScanJobTrainingData.ModelMetadata | undefined;
  extractedDates: Array<Date> | undefined;
  humanAcceptance: boolean;

  constructor(text: string,
              start: number,
              end: number,
              textType: TextResult.TextType,
              audit: ScanJobArtifact.ScanJobTrainingData.CreatedAuditData | undefined,
              humanAcceptance: boolean,
              extractedDates: Array<Date> | undefined
              ) {
    // hash the variables to provide a pseudo unique identifier.  This will be used for lookups.
    this.id = idGenerator(text, start, end, textType);
    this.text = text;
    this.displayName = formatStringWithLeadingAndTrailingSnippets(text, MAX_STRING_LENGTH).toString();
    this.start = start;
    this.end = end;
    this.textType = textType;
    this.audit = audit;
    if (audit?.hasModelMetadata()) {
      this.skynetModelMetadata = audit?.getModelMetadata();
    } else {
      this.skynetModelMetadata = undefined;
    }
    this.visible = true;
    this.editMode = false;
    this.humanAcceptance = humanAcceptance;
    this.extractedDates = extractedDates;
  }

  regenerateId = (textType: TextResult.TextType): number => {
    return idGenerator(this.text, this.start, this.end, textType);
  }
  static sortFn = (a: TrainingRange, b: TrainingRange): number => (a.start || 0) - (b.start || 0);
}

class RejectedTextResultRow {
  textType: TextResult.TextType;
  audit: ScanJobArtifact.ScanJobTrainingData.CreatedAuditData | undefined;
  skynetModelMetadata: ScanJobArtifact.ScanJobTrainingData.ModelMetadata | undefined;
  humanAcceptance: boolean;

  constructor(textType: TextResult.TextType, audit: ScanJobArtifact.ScanJobTrainingData.CreatedAuditData | undefined, humanAcceptance: boolean) {
    this.textType = textType;
    this.audit = audit;
    if (audit?.hasModelMetadata()) {
      this.skynetModelMetadata = audit?.getModelMetadata();
    } else {
      this.skynetModelMetadata = undefined;
    }
    this.humanAcceptance = humanAcceptance;
  }

  static sortFn = (a: RejectedTextResultRow, b: RejectedTextResultRow): number => -1 * a.textType.toString().localeCompare(b.textType.toString());
}

const idGenerator = (name: string, start: number, end: number, textType: TextResult.TextType): number => {
  return hashCode(start.toString() + "%%" + name + "%%" + end.toString() + "%%" + textType);
}

const renderTextTypeText = (textType: TextResult.TextType): string => {
  switch(textType) {
    case TextResult.TextType.TEXT_TYPE_UNSET:
      return "Unset"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY:
      return "Privacy policy text"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_TITLE:
      return "Privacy policy title"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_UPDATED_DATE:
      return "Privacy policy updated date"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_CA_RESIDENT_RIGHTS_DESCRIPTION:
      return "Includes CA resident rights description"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_HOW_DATA_WILL_BE_USED:
      return "Includes how data will be used"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_WITH_WHOM_DATA_WILL_BE_SHARED:
      return "Includes with whom data will be shared"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_REQUEST_OWN_DATA:
      return "Includes ability to request own data"
    case TextResult.TextType.TEXT_TYPE_PRIVACY_POLICY_INCLUDES_ABILITY_TO_DELETE_OWN_DATA:
      return "Includes ability to delete own data"
    case TextResult.TextType.TEXT_TYPE_GLOBAL_PRIVACY_CONTROL_RESPECTED:
      return "Global Privacy Control respected"
    default: {
      return "Unknown";
    }
  }
}

export { TrainingRange, RejectedTextResultRow, idGenerator, renderTextTypeText };
