// pages
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import ScanJobs from "./pages/ScanJobs";
import ScanRules from "./pages/ScanRules";
import ScanRuleDetail from "./pages/ScanRuleDetail";
import ScanJobDetail from "./pages/ScanJobDetail";
import PropertySetDetail from "./pages/PropertySetDetail";
import Sets from "./pages/Sets";
import ScanRuleSetDetail from "./pages/ScanRuleSetDetail";
import AccountSettingDetail from "./pages/AccountSettingDetail";
import Remote from "./pages/Remote";

// other
import {FC} from "react";
import ScanJobArtifactTraining from "./pages/ScanJobArtifactTraining";
import PropertySnapshotDetail from "./pages/PropertySnapshotDetail";
import ScanJobArtifacts from "./pages/ScanJobArtifacts";
import Properties from "./pages/Properties";
import PropertyDetail from "./pages/PropertyDetail";
import Todo from "./pages/Todo";

// interface
interface Route {
    key: string,
    path: string,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        path: '/',
        component: Home
    },
    {
        key: 'admin-route',
        path: '/admin',
        component: Admin
    },
    {
        key: 'propertysnapshot-route',
        path: '/properties/:propertyName/propertySnapshots/:propertySnapshotId',
        component: PropertySnapshotDetail
    },
    {
        key: 'property-route',
        path: '/properties/:propertyId',
        component: PropertyDetail
    },
    {
        key: 'properties-route',
        path: '/properties',
        component: Properties
    },
    {
        key: 'sets-route',
        path: '/sets',
        component: Sets
    },
    {
        key: 'account-settings-route',
        path: '/sets/accountSettings/:accountSettingsId',
        component: AccountSettingDetail
    },
    {
        key: 'propertyset-route',
        path: '/sets/property/:propertySetId',
        component: PropertySetDetail
    },
    {
        key: 'scanruleset-route',
        path: '/sets/scanRule/:scanRuleSetId',
        component: ScanRuleSetDetail
    },
    {
        key: 'scanjob-route',
        path: '/scanJobs/:scanJobId',
        component: ScanJobDetail
    },
    {
        key: 'scanjobs-route',
        path: '/scanJobs',
        component: ScanJobs
    },
    {
        key: 'scanrules-route',
        path: '/scanRules',
        component: ScanRules
    },
    {
        key: 'scanrule-route',
        path: '/scanRules/:scanRuleId',
        component: ScanRuleDetail
    },
    {
        key: 'scanjobartifacts-route',
        path: '/scanJobArtifacts',
        component: ScanJobArtifacts
    },
    {
        key: 'todo-route-with-scanjob-idparam',
        path: '/todo/scanJob/:scanJobId',
        component: Todo
    },
    {
        key: 'todo-route-with-propertysetid-idparam',
        path: '/todo/propertySet/:propertySetId',
        component: Todo
    },
    {
        key: 'todo-route',
        path: '/todo',
        component: Todo
    },
    {
        key: 'remote-route',
        path: '/remote',
        component: Remote
    },
    {
        key: 'scan-job-training-route-with-scanjob-artifact-idparam',
        path: '/scanJobTraining/scanJobArtifact/:scanJobArtifactId',
        component: ScanJobArtifactTraining
    },
    {
        key: 'scan-job-training-route-with-propertysetid-idparam',
        path: '/scanJobTraining/propertySet/:propertySetId',
        component: ScanJobArtifactTraining
    },
    {
        key: 'scan-job-training-route',
        path: '/scanJobTraining',
        component: ScanJobArtifactTraining
    },
]