/**
 * @fileoverview gRPC-Web generated client stub for sp.scan_runner
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var sp_scan_runner_common_pb = require('../../sp/scan_runner/common_pb.js')
const proto = {};
proto.sp = {};
proto.sp.scan_runner = require('./scan_runner_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.scan_runner.ScanRunnerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.scan_runner.ScanRunnerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_runner.StartChromeRequest,
 *   !proto.sp.scan_runner.StartChromeResponse>}
 */
const methodDescriptor_ScanRunnerService_StartChrome = new grpc.web.MethodDescriptor(
  '/sp.scan_runner.ScanRunnerService/StartChrome',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_runner.StartChromeRequest,
  proto.sp.scan_runner.StartChromeResponse,
  /**
   * @param {!proto.sp.scan_runner.StartChromeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_runner.StartChromeResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_runner.StartChromeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_runner.StartChromeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_runner.StartChromeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_runner.ScanRunnerServiceClient.prototype.startChrome =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_runner.ScanRunnerService/StartChrome',
      request,
      metadata || {},
      methodDescriptor_ScanRunnerService_StartChrome,
      callback);
};


/**
 * @param {!proto.sp.scan_runner.StartChromeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_runner.StartChromeResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_runner.ScanRunnerServicePromiseClient.prototype.startChrome =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_runner.ScanRunnerService/StartChrome',
      request,
      metadata || {},
      methodDescriptor_ScanRunnerService_StartChrome);
};


module.exports = proto.sp.scan_runner;

