import React, {ReactElement, FC} from "react";
import { Grid } from '@mui/material';
import MasterContainer from "../components/containers/MasterContainer";
import ScanJobArtifactsCard from "../components/cards/ScanJobArtifactsCard";

const ScanJobArtifacts: FC<any> = (): ReactElement => {
    return (
        <MasterContainer>
          <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} sx={{ mt: 2}}>
                    <ScanJobArtifactsCard maxHeight={750} showFilterUi={true} showScanJobLink={true}/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};
export default ScanJobArtifacts;