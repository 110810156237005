import React, {ReactElement, FC, useState, useEffect} from "react";
import {Box, Grid, LinearProgress, Table, TableBody, TableContainer, TableHead} from '@mui/material';
import Paper from '@mui/material/Paper';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {TCModel} from "@iabtcf/core/lib/mjs/TCModel";
import {fetchIabVendorList} from "../lib/iabUtils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";

interface VendorListTableProps {
    tcModel: TCModel | undefined,
    maxHeight: number;
}

interface VendorRow {
    vendorId: number;
    vendorName: string;
    vendorConsents: boolean;
    vendorAllowed: boolean;
    vendorDisclosed: boolean;
    vendorLegitimateInterests: boolean;
}

const IABVendorListTable: FC<VendorListTableProps> = (props): ReactElement => {
    const [rows, setRows] = useState<VendorRow[]>([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            if (props.tcModel !== undefined) {
                setLoading(true);
                let iabVendorList = await fetchIabVendorList();
                let rows: VendorRow[] | undefined = iabVendorList.map((iabVendor) => {
                    // for each vendor, determine if consents
                    let vendorId: number = iabVendor.id;
                    let vendorConsents: boolean = props.tcModel!.vendorConsents.has(vendorId);
                    let vendorAllowed: boolean = props.tcModel!.vendorsAllowed.has(vendorId);
                    let vendorDisclosed: boolean = props.tcModel!.vendorsDisclosed.has(vendorId);
                    let vendorLegitimateInterests: boolean = props.tcModel!.vendorLegitimateInterests.has(vendorId);
                    let row : VendorRow =  {
                        vendorId: vendorId,
                        vendorName: iabVendor.name,
                        vendorConsents: vendorConsents,
                        vendorAllowed: vendorAllowed,
                        vendorDisclosed: vendorDisclosed,
                        vendorLegitimateInterests: vendorLegitimateInterests,
                    }
                    return row;
                });
                if( rows !== undefined) {
                    setRows(rows);
                }
                setLoading(false);
            }
        })();
    }, [props.tcModel]);

    return (
        <React.Fragment>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
            </Grid>
            { !loading &&
                <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}}>
                    <Table stickyHeader size="small" aria-label="Vendor List">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="left">Id</StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="left">Consents</StyledTableCell>
                                <StyledTableCell align="left">Allowed</StyledTableCell>
                                <StyledTableCell align="left">Disclosed</StyledTableCell>
                                <StyledTableCell align="left">Legitimate Interests</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow hover key={row.vendorId}>
                                    <StyledTableCell align="left">{row.vendorId}</StyledTableCell>
                                    <StyledTableCell align="left">{row.vendorName}</StyledTableCell>
                                    <StyledTableCell align="left">{row.vendorConsents ?  <CheckIcon htmlColor={"green"}/> : <RemoveIcon htmlColor={"red"}/>}</StyledTableCell>
                                    <StyledTableCell align="left">{row.vendorAllowed ?  <CheckIcon htmlColor={"green"}/> : <RemoveIcon htmlColor={"red"}/>}</StyledTableCell>
                                    <StyledTableCell align="left">{row.vendorDisclosed ?  <CheckIcon htmlColor={"green"}/> : <RemoveIcon htmlColor={"red"}/>}</StyledTableCell>
                                    <StyledTableCell align="left">{row.vendorLegitimateInterests ?  <CheckIcon htmlColor={"green"}/> : <RemoveIcon htmlColor={"red"}/>}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </React.Fragment>

    );
}

export default IABVendorListTable;