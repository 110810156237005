// source: sp/bff_service/bff_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.sp.bff_service.AuthRequest', null, global);
goog.exportSymbol('proto.sp.bff_service.AuthResponse', null, global);
goog.exportSymbol('proto.sp.bff_service.BrowserExtension', null, global);
goog.exportSymbol('proto.sp.bff_service.BrowserExtension.BrowserExtensionField', null, global);
goog.exportSymbol('proto.sp.bff_service.GetBrowserExtensionByIdRequest', null, global);
goog.exportSymbol('proto.sp.bff_service.GetBrowserExtensionByIdResponse', null, global);
goog.exportSymbol('proto.sp.bff_service.GetBrowserExtensionsRequest', null, global);
goog.exportSymbol('proto.sp.bff_service.GetBrowserExtensionsResponse', null, global);
goog.exportSymbol('proto.sp.bff_service.GetLatestBrowserExtensionRequest', null, global);
goog.exportSymbol('proto.sp.bff_service.GetLatestBrowserExtensionResponse', null, global);
goog.exportSymbol('proto.sp.bff_service.SourcePointToken', null, global);
goog.exportSymbol('proto.sp.bff_service.UserProfile', null, global);
goog.exportSymbol('proto.sp.bff_service.UserRole', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.AuthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.AuthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.AuthRequest.displayName = 'proto.sp.bff_service.AuthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.AuthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.AuthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.AuthResponse.displayName = 'proto.sp.bff_service.AuthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.BrowserExtension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.BrowserExtension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.BrowserExtension.displayName = 'proto.sp.bff_service.BrowserExtension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.GetBrowserExtensionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.GetBrowserExtensionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.GetBrowserExtensionsRequest.displayName = 'proto.sp.bff_service.GetBrowserExtensionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.GetBrowserExtensionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.bff_service.GetBrowserExtensionsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.bff_service.GetBrowserExtensionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.GetBrowserExtensionsResponse.displayName = 'proto.sp.bff_service.GetBrowserExtensionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.bff_service.GetLatestBrowserExtensionRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.bff_service.GetLatestBrowserExtensionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.GetLatestBrowserExtensionRequest.displayName = 'proto.sp.bff_service.GetLatestBrowserExtensionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.GetLatestBrowserExtensionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.GetLatestBrowserExtensionResponse.displayName = 'proto.sp.bff_service.GetLatestBrowserExtensionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.bff_service.GetBrowserExtensionByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.bff_service.GetBrowserExtensionByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.GetBrowserExtensionByIdRequest.displayName = 'proto.sp.bff_service.GetBrowserExtensionByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.GetBrowserExtensionByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.GetBrowserExtensionByIdResponse.displayName = 'proto.sp.bff_service.GetBrowserExtensionByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.SourcePointToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.bff_service.SourcePointToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.SourcePointToken.displayName = 'proto.sp.bff_service.SourcePointToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.bff_service.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.bff_service.UserProfile.repeatedFields_, null);
};
goog.inherits(proto.sp.bff_service.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.bff_service.UserProfile.displayName = 'proto.sp.bff_service.UserProfile';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.AuthRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.AuthRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.AuthRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.AuthRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.AuthRequest}
 */
proto.sp.bff_service.AuthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.AuthRequest;
  return proto.sp.bff_service.AuthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.AuthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.AuthRequest}
 */
proto.sp.bff_service.AuthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.AuthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.AuthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.AuthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.AuthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRedirectUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.sp.bff_service.AuthRequest.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.AuthRequest} returns this
 */
proto.sp.bff_service.AuthRequest.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_uri = 2;
 * @return {string}
 */
proto.sp.bff_service.AuthRequest.prototype.getRedirectUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.AuthRequest} returns this
 */
proto.sp.bff_service.AuthRequest.prototype.setRedirectUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.AuthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.AuthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.AuthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.AuthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    encryptedSourcePointToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userProfile: (f = msg.getUserProfile()) && proto.sp.bff_service.UserProfile.toObject(includeInstance, f),
    authToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.AuthResponse}
 */
proto.sp.bff_service.AuthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.AuthResponse;
  return proto.sp.bff_service.AuthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.AuthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.AuthResponse}
 */
proto.sp.bff_service.AuthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptedSourcePointToken(value);
      break;
    case 2:
      var value = new proto.sp.bff_service.UserProfile;
      reader.readMessage(value,proto.sp.bff_service.UserProfile.deserializeBinaryFromReader);
      msg.setUserProfile(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.AuthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.AuthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.AuthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.AuthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncryptedSourcePointToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.bff_service.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string encrypted_source_point_token = 1;
 * @return {string}
 */
proto.sp.bff_service.AuthResponse.prototype.getEncryptedSourcePointToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.AuthResponse} returns this
 */
proto.sp.bff_service.AuthResponse.prototype.setEncryptedSourcePointToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserProfile user_profile = 2;
 * @return {?proto.sp.bff_service.UserProfile}
 */
proto.sp.bff_service.AuthResponse.prototype.getUserProfile = function() {
  return /** @type{?proto.sp.bff_service.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.sp.bff_service.UserProfile, 2));
};


/**
 * @param {?proto.sp.bff_service.UserProfile|undefined} value
 * @return {!proto.sp.bff_service.AuthResponse} returns this
*/
proto.sp.bff_service.AuthResponse.prototype.setUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.bff_service.AuthResponse} returns this
 */
proto.sp.bff_service.AuthResponse.prototype.clearUserProfile = function() {
  return this.setUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.AuthResponse.prototype.hasUserProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string auth_token = 3;
 * @return {string}
 */
proto.sp.bff_service.AuthResponse.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.AuthResponse} returns this
 */
proto.sp.bff_service.AuthResponse.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.BrowserExtension.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.BrowserExtension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.BrowserExtension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.BrowserExtension.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastModified: (f = msg.getLastModified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fileSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.BrowserExtension}
 */
proto.sp.bff_service.BrowserExtension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.BrowserExtension;
  return proto.sp.bff_service.BrowserExtension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.BrowserExtension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.BrowserExtension}
 */
proto.sp.bff_service.BrowserExtension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileSize(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.BrowserExtension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.BrowserExtension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.BrowserExtension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.BrowserExtension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.bff_service.BrowserExtension.BrowserExtensionField = {
  BROWSER_EXTENSION_FIELD_UNSET: 0,
  BROWSER_EXTENSION_FIELD_DATA: 1
};

/**
 * optional string filename = 1;
 * @return {string}
 */
proto.sp.bff_service.BrowserExtension.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.sp.bff_service.BrowserExtension.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_modified = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.bff_service.BrowserExtension.prototype.getLastModified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
*/
proto.sp.bff_service.BrowserExtension.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.BrowserExtension.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 file_size = 4;
 * @return {number}
 */
proto.sp.bff_service.BrowserExtension.prototype.getFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.setFileSize = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.clearFileSize = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.BrowserExtension.prototype.hasFileSize = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes data = 5;
 * @return {string}
 */
proto.sp.bff_service.BrowserExtension.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes data = 5;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.sp.bff_service.BrowserExtension.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.sp.bff_service.BrowserExtension.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.setData = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.bff_service.BrowserExtension} returns this
 */
proto.sp.bff_service.BrowserExtension.prototype.clearData = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.BrowserExtension.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.GetBrowserExtensionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.GetBrowserExtensionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.GetBrowserExtensionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.GetBrowserExtensionsRequest}
 */
proto.sp.bff_service.GetBrowserExtensionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.GetBrowserExtensionsRequest;
  return proto.sp.bff_service.GetBrowserExtensionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.GetBrowserExtensionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.GetBrowserExtensionsRequest}
 */
proto.sp.bff_service.GetBrowserExtensionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.GetBrowserExtensionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.GetBrowserExtensionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.GetBrowserExtensionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.GetBrowserExtensionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.GetBrowserExtensionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    browserExtensionsList: jspb.Message.toObjectList(msg.getBrowserExtensionsList(),
    proto.sp.bff_service.BrowserExtension.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.GetBrowserExtensionsResponse}
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.GetBrowserExtensionsResponse;
  return proto.sp.bff_service.GetBrowserExtensionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.GetBrowserExtensionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.GetBrowserExtensionsResponse}
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.bff_service.BrowserExtension;
      reader.readMessage(value,proto.sp.bff_service.BrowserExtension.deserializeBinaryFromReader);
      msg.addBrowserExtensions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.GetBrowserExtensionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.GetBrowserExtensionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrowserExtensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.bff_service.BrowserExtension.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BrowserExtension browser_extensions = 1;
 * @return {!Array<!proto.sp.bff_service.BrowserExtension>}
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.prototype.getBrowserExtensionsList = function() {
  return /** @type{!Array<!proto.sp.bff_service.BrowserExtension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.bff_service.BrowserExtension, 1));
};


/**
 * @param {!Array<!proto.sp.bff_service.BrowserExtension>} value
 * @return {!proto.sp.bff_service.GetBrowserExtensionsResponse} returns this
*/
proto.sp.bff_service.GetBrowserExtensionsResponse.prototype.setBrowserExtensionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.bff_service.BrowserExtension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.bff_service.BrowserExtension}
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.prototype.addBrowserExtensions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.bff_service.BrowserExtension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.bff_service.GetBrowserExtensionsResponse} returns this
 */
proto.sp.bff_service.GetBrowserExtensionsResponse.prototype.clearBrowserExtensionsList = function() {
  return this.setBrowserExtensionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.GetLatestBrowserExtensionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionRequest}
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.GetLatestBrowserExtensionRequest;
  return proto.sp.bff_service.GetLatestBrowserExtensionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionRequest}
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.GetLatestBrowserExtensionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated BrowserExtension.BrowserExtensionField fields = 1;
 * @return {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>}
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>} value
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} returns this
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.sp.bff_service.BrowserExtension.BrowserExtensionField} value
 * @param {number=} opt_index
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} returns this
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionRequest} returns this
 */
proto.sp.bff_service.GetLatestBrowserExtensionRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.GetLatestBrowserExtensionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    browserExtension: (f = msg.getBrowserExtension()) && proto.sp.bff_service.BrowserExtension.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionResponse}
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.GetLatestBrowserExtensionResponse;
  return proto.sp.bff_service.GetLatestBrowserExtensionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionResponse}
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.bff_service.BrowserExtension;
      reader.readMessage(value,proto.sp.bff_service.BrowserExtension.deserializeBinaryFromReader);
      msg.setBrowserExtension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.GetLatestBrowserExtensionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.GetLatestBrowserExtensionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrowserExtension();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.bff_service.BrowserExtension.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrowserExtension browser_extension = 1;
 * @return {?proto.sp.bff_service.BrowserExtension}
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.prototype.getBrowserExtension = function() {
  return /** @type{?proto.sp.bff_service.BrowserExtension} */ (
    jspb.Message.getWrapperField(this, proto.sp.bff_service.BrowserExtension, 1));
};


/**
 * @param {?proto.sp.bff_service.BrowserExtension|undefined} value
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionResponse} returns this
*/
proto.sp.bff_service.GetLatestBrowserExtensionResponse.prototype.setBrowserExtension = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.bff_service.GetLatestBrowserExtensionResponse} returns this
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.prototype.clearBrowserExtension = function() {
  return this.setBrowserExtension(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.GetLatestBrowserExtensionResponse.prototype.hasBrowserExtension = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.GetBrowserExtensionByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdRequest}
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.GetBrowserExtensionByIdRequest;
  return proto.sp.bff_service.GetBrowserExtensionByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdRequest}
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.GetBrowserExtensionByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated BrowserExtension.BrowserExtensionField fields = 1;
 * @return {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>}
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.sp.bff_service.BrowserExtension.BrowserExtensionField>} value
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} returns this
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.sp.bff_service.BrowserExtension.BrowserExtensionField} value
 * @param {number=} opt_index
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} returns this
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} returns this
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdRequest} returns this
 */
proto.sp.bff_service.GetBrowserExtensionByIdRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.GetBrowserExtensionByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    browserExtension: (f = msg.getBrowserExtension()) && proto.sp.bff_service.BrowserExtension.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdResponse}
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.GetBrowserExtensionByIdResponse;
  return proto.sp.bff_service.GetBrowserExtensionByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdResponse}
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.bff_service.BrowserExtension;
      reader.readMessage(value,proto.sp.bff_service.BrowserExtension.deserializeBinaryFromReader);
      msg.setBrowserExtension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.GetBrowserExtensionByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.GetBrowserExtensionByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrowserExtension();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.bff_service.BrowserExtension.serializeBinaryToWriter
    );
  }
};


/**
 * optional BrowserExtension browser_extension = 1;
 * @return {?proto.sp.bff_service.BrowserExtension}
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.prototype.getBrowserExtension = function() {
  return /** @type{?proto.sp.bff_service.BrowserExtension} */ (
    jspb.Message.getWrapperField(this, proto.sp.bff_service.BrowserExtension, 1));
};


/**
 * @param {?proto.sp.bff_service.BrowserExtension|undefined} value
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdResponse} returns this
*/
proto.sp.bff_service.GetBrowserExtensionByIdResponse.prototype.setBrowserExtension = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.bff_service.GetBrowserExtensionByIdResponse} returns this
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.prototype.clearBrowserExtension = function() {
  return this.setBrowserExtension(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.GetBrowserExtensionByIdResponse.prototype.hasBrowserExtension = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.SourcePointToken.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.SourcePointToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.SourcePointToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.SourcePointToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.SourcePointToken}
 */
proto.sp.bff_service.SourcePointToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.SourcePointToken;
  return proto.sp.bff_service.SourcePointToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.SourcePointToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.SourcePointToken}
 */
proto.sp.bff_service.SourcePointToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.SourcePointToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.SourcePointToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.SourcePointToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.SourcePointToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.sp.bff_service.SourcePointToken.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.SourcePointToken} returns this
 */
proto.sp.bff_service.SourcePointToken.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.sp.bff_service.SourcePointToken.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.SourcePointToken} returns this
 */
proto.sp.bff_service.SourcePointToken.prototype.setEmailAddress = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.bff_service.SourcePointToken} returns this
 */
proto.sp.bff_service.SourcePointToken.prototype.clearEmailAddress = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.SourcePointToken.prototype.hasEmailAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.bff_service.UserProfile.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.bff_service.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.bff_service.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.bff_service.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userRolesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.bff_service.UserProfile}
 */
proto.sp.bff_service.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.bff_service.UserProfile;
  return proto.sp.bff_service.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.bff_service.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.bff_service.UserProfile}
 */
proto.sp.bff_service.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPictureUrl(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.bff_service.UserRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserRoles(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.bff_service.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.bff_service.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.bff_service.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.bff_service.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.bff_service.UserProfile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.UserProfile.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.sp.bff_service.UserProfile.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.setEmailAddress = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.clearEmailAddress = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.UserProfile.prototype.hasEmailAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string picture_url = 3;
 * @return {string}
 */
proto.sp.bff_service.UserProfile.prototype.getPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.setPictureUrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.clearPictureUrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.bff_service.UserProfile.prototype.hasPictureUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated UserRole user_roles = 4;
 * @return {!Array<!proto.sp.bff_service.UserRole>}
 */
proto.sp.bff_service.UserProfile.prototype.getUserRolesList = function() {
  return /** @type {!Array<!proto.sp.bff_service.UserRole>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.bff_service.UserRole>} value
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.setUserRolesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.bff_service.UserRole} value
 * @param {number=} opt_index
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.addUserRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.bff_service.UserProfile} returns this
 */
proto.sp.bff_service.UserProfile.prototype.clearUserRolesList = function() {
  return this.setUserRolesList([]);
};


/**
 * @enum {number}
 */
proto.sp.bff_service.UserRole = {
  USER_ROLE_UNSET: 0,
  USER_ROLE_ADMIN: 1,
  USER_ROLE_STANDARD: 2,
  USER_ROLE_VNC_ACCESS: 3,
  USER_ROLE_MODEL_TRAINING_ACCESS: 4
};

goog.object.extend(exports, proto.sp.bff_service);
