import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Card,
    CardContent, Divider,
    Grid, LinearProgress, TextField,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {ConsentApiData} from "../../generated/sp/scan_runner/common_pb";
import {TCModel} from "@iabtcf/core/lib/mjs/TCModel";
import {TCString} from "@iabtcf/core";
import IABVendorListTable from "../IABVendorListTable";
import IABSpecialFeatureOptinsTable from "../IABSpecialFeatureOptinsTable";
import IABPurposeTable from "../IABPurposeTable";
import {fetchScanArtifact} from "../../lib/scanServiceUtils";

const parseTCFString = (tcfString: string | undefined): TCModel | undefined => {
    if (tcfString !== undefined && tcfString.length > 0) {
        try {
            return TCString.decode(tcfString);
        } catch (error) {
            console.log(`Failed to parse tcfString from: ${tcfString}`);
        }
    } else {
        return undefined;
    }
}
interface ScanTimelineConsentApiData {
    name: string;
    consentApiData: ConsentApiData | undefined,
    tcModel: TCModel | undefined,
}
interface ScanTimelineConsentApiDataArtifactCardProps {
    scanJobId: string | undefined;
    artifactId: string | undefined;
    sx?: SxProps<Theme>;
}
const ScanTimelineConsentApiDataArtifactCard: FC<ScanTimelineConsentApiDataArtifactCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanTimelineConsentApiData, setScanTimelineConsentApiData] = useState<ScanTimelineConsentApiData  | undefined>(undefined);

    const reloadScanJobArtifact = useCallback(async () => {
        console.log(`Loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanArtifact = await fetchScanArtifact(scanService, props.scanJobId!, props.artifactId!);
        const contents : Uint8Array = scanArtifact!.getContent_asU8();
        const consentApiData = ConsentApiData.deserializeBinary(contents);
        const tcModel = parseTCFString(consentApiData.getTcfData()?.getTcString());
        const data: ScanTimelineConsentApiData = {
            name: scanArtifact.getName(),
            consentApiData,
            tcModel
        };
        setScanTimelineConsentApiData(data);
        console.log(`Finished loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(false);
    }, [props.scanJobId, props.artifactId]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined && props.artifactId !== undefined) {
                await reloadScanJobArtifact();
            }
        })();
    }, [props.scanJobId, props.artifactId, reloadScanJobArtifact]);

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1}}  style={{ fontWeight: 600 }} variant="subtitle1" component="div">{scanTimelineConsentApiData?.name} for ScanJobId: [{props.scanJobId}]</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">TCF Api Data</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="TCF Policy Version" type="string" value={scanTimelineConsentApiData?.consentApiData?.getTcfData()?.getTcfPolicyVersion() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Cmp ID" type="string" value={scanTimelineConsentApiData?.consentApiData?.getTcfData()?.getCmpId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Cmp Version" type="string" value={scanTimelineConsentApiData?.consentApiData?.getTcfData()?.getCmpVersion() || ''} inputProps={{ readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">TCF Api Data (Decoded)</Typography>
                    </Grid>
                    {scanTimelineConsentApiData?.tcModel !== undefined &&
                      <React.Fragment>
                          <Grid item xs={6}>
                              <TextField size="small" label="Created" type="string" value={scanTimelineConsentApiData?.tcModel.created || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField size="small" label="Last Updated" type="string" value={scanTimelineConsentApiData?.tcModel.lastUpdated || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={2}>
                              <TextField size="small" label="Consent Language" type="string" value={scanTimelineConsentApiData?.tcModel.consentLanguage || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={2}>
                              <TextField size="small" label="Consent Screen" type="string" value={scanTimelineConsentApiData?.tcModel.consentScreen || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={2}>
                              <TextField size="small" label="Vendor List Version" type="string" value={scanTimelineConsentApiData?.tcModel.vendorListVersion || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={2}>
                              <TextField size="small" label="Publisher Country Code" type="string" value={scanTimelineConsentApiData?.tcModel.publisherCountryCode || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <Divider>GVL</Divider>
                          </Grid>
                          {scanTimelineConsentApiData?.tcModel.gvl !== undefined &&
                            <React.Fragment>
                                <Grid item xs={2}>
                                    <TextField size="small" label="GVL Spec Version" type="string" value={scanTimelineConsentApiData?.tcModel.gvl.gvlSpecificationVersion || ''} inputProps={{ readOnly: true}} fullWidth/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField size="small" label="Vendor List Version" type="string" value={scanTimelineConsentApiData?.tcModel.gvl.vendorListVersion || ''} inputProps={{ readOnly: true}} fullWidth/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField size="small" label="TCF Policy Version" type="string" value={scanTimelineConsentApiData?.tcModel.gvl.tcfPolicyVersion || ''} inputProps={{ readOnly: true}} fullWidth/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField size="small" label="Language" type="string" value={scanTimelineConsentApiData?.tcModel.gvl.language || ''} inputProps={{ readOnly: true}} fullWidth/>
                                </Grid>
                            </React.Fragment>
                          }
                          {scanTimelineConsentApiData?.tcModel.gvl === undefined &&
                            <Grid item xs={12}>
                                <Typography sx={{color: "red"}} style={{ fontWeight: 600 }} variant="subtitle2" component="div">GVL data was not set</Typography>
                            </Grid>
                          }
                          <Grid item xs={12}>
                              <Divider>Vendor</Divider>
                          </Grid>
                          <Grid item xs={12}>
                              <IABVendorListTable tcModel={scanTimelineConsentApiData?.tcModel} maxHeight={250}/>
                          </Grid>
                          <Grid item xs={12}>
                              <Divider>Special Feature Optins</Divider>
                          </Grid>
                          <Grid item xs={12}>
                              <IABSpecialFeatureOptinsTable tcModel={scanTimelineConsentApiData?.tcModel} maxHeight={150}/>
                          </Grid>
                          <Grid item xs={12}>
                              <Divider>Purpose</Divider>
                          </Grid>
                          <Grid item xs={12}>
                              <IABPurposeTable tcModel={scanTimelineConsentApiData?.tcModel} maxHeight={150}/>
                          </Grid>
                          <Grid item xs={12}>
                              <Divider>Publisher</Divider>
                          </Grid>
                          <Grid item xs={3}>
                              <TextField size="small" label="Consents Size" type="number" value={scanTimelineConsentApiData?.tcModel.publisherConsents.size || 0} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                              <TextField size="small" label="Custom Consents Size" type="number" value={scanTimelineConsentApiData?.tcModel.publisherCustomConsents.size || 0} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                              <TextField size="small" label="Legitimate Interests Size" type="number" value={scanTimelineConsentApiData?.tcModel.publisherLegitimateInterests.size || 0} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={3}>
                              <TextField size="small" label="Custom Legitimate Interests Size" type="number" value={scanTimelineConsentApiData?.tcModel.publisherCustomLegitimateInterests.size || 0} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <Divider/>
                          </Grid>
                      </React.Fragment>
                    }
                    {scanTimelineConsentApiData?.tcModel === undefined &&
                      <Grid item xs={12}>
                          <Typography sx={{color: "red"}} style={{ fontWeight: 600 }} variant="subtitle2" component="div">TCF String was not set or is not parsable.</Typography>
                      </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">USP API Data</Typography>
                    </Grid>
                    {scanTimelineConsentApiData?.consentApiData?.getUspData() !== undefined &&
                      <React.Fragment>
                          <Grid item xs={2}>
                              <TextField size="small" label="USP Version" type="string" value={scanTimelineConsentApiData?.consentApiData?.getUspData()?.getVersion() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField size="small" multiline rows={2} label="JSON" type="string" value={scanTimelineConsentApiData?.consentApiData?.getUspData()?.getJson() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                      </React.Fragment>
                    }
                    {scanTimelineConsentApiData?.consentApiData?.getUspData() === undefined &&
                      <Grid item xs={12}>
                          <Typography sx={{color: "red"}} style={{ fontWeight: 600 }} variant="subtitle2" component="div">USP data was not set</Typography>
                      </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanTimelineConsentApiDataArtifactCard;