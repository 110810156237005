import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {GetAccountSettingsRequest, GetAccountSettingsResponse, AccountSettings} from '../generated/sp/scan_service/scan_service_pb';
import { PagingParameters, SortDirection } from '../generated/sp/service_common/common_pb';
import { useNavigate} from "react-router-dom";
import { enumName } from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {CsvHeader} from "../lib/exportCsvUtils";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";

const generateGetAccountSettingsRequest = (continuationToken: string | undefined, limit: number, sortField: GetAccountSettingsRequest.SortField, sortDirection: SortDirection): GetAccountSettingsRequest => {
  var req = new GetAccountSettingsRequest();
  let filters = new GetAccountSettingsRequest.Filters();
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetAccountSettingsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchAccountSettings = async (scanService: ScanServiceClient, request: GetAccountSettingsRequest): Promise<GetAccountSettingsResponse> => {
  return new Promise<GetAccountSettingsResponse>((resolve, reject) => {
    scanService.getAccountSettings(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface AccountSettingInfinityTableProps {
  maxHeight: number;
  forceRefresh?: number;
}

const AccountSettingInfinityTable: FC<AccountSettingInfinityTableProps> = (props): ReactElement => {
    const [accountSettings, setAccountSettings] = useState<AccountSettings[]>([]);
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetAccountSettingsRequest.SortField>(GetAccountSettingsRequest.SortField.SORT_FIELD_NAME);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_ASC);
    const [limit] = useState<number>(50);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToAccountSettings = (accountSettingsId: String) => {
        navigate(`/sets/accountSettings/${accountSettingsId}`);
    };

    useEffect(() => {
      (async () => {
        setAccountSettings([]);
        setRefreshToggle(true);
      })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading account settings list data`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetAccountSettingsRequest(currentContinuationToken, limit, sortField, sortDirection);
        const response = await fetchAccountSettings(scanService, req);
        let continuationToken = response.getContinuationToken().length > 0 ? response.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        // to support scrolling, we need to append to whatever is currently loaded
        setAccountSettings(old => [...old, ...response.getAccountSettingsList()]);
        setLoading(false);
      })();
    }, [currentContinuationToken, limit, sortDirection, sortField, refreshToggle]);

    const exportCsvHeaders = (): CsvHeader[] => {
      return [
        { label: "Account Name", key: "name" },
        { label: "Account Id", key: "accountId" },
        { label: "Status", key: "status" },
        { label: "Has API Key", key: "hasApiKey" },
        { label: "Created", key: "created" },
      ]
    }

    interface CsvExportData {
      name: string;
      accountId: number;
      status: string;
      hasApiKey: string
      created: string;
    }

    const exportCsvData = (): CsvExportData[] => {
      return accountSettings.map((accountSetting) => {
        return {
          name: accountSetting.getName(),
          accountId: accountSetting.getAccountId(),
          status: enumName(AccountSettings.AccountSettingsStatus, accountSetting.getStatus(), false),
          hasApiKey: (accountSetting.getApiKeyId() !== "").toString(),
          created: accountSetting.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''
        };
      });
    }

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
        setAccountSettings([]); // wipe out data such that we dont keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handleSortRequest = (sortField: GetAccountSettingsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                      <LoadingButton
                        size="small"
                        color="secondary"
                        onClick={handleRefresh}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<RefreshIcon />}
                        variant="contained"
                      >
                        Refresh
                      </LoadingButton>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {accountSettings.length} Account Settings</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" alignItems="right" justifyContent="right">
                      <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"AccountSettings-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                        <TableArrowDownIcon sx={{color: "secondary"}}/>
                      </CSVLink>
                    </Box>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
            </Grid>
            <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader size="small" aria-label="Scan Rules">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" onClick={() => handleSortRequest(GetAccountSettingsRequest.SortField.SORT_FIELD_NAME)}>
                    <TableSortLabel active={sortField === GetAccountSettingsRequest.SortField.SORT_FIELD_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Account Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetAccountSettingsRequest.SortField.SORT_FIELD_ACCOUNT_ID)}>
                    <TableSortLabel active={sortField === GetAccountSettingsRequest.SortField.SORT_FIELD_ACCOUNT_ID} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Account ID</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetAccountSettingsRequest.SortField.SORT_FIELD_STATUS)}>
                      <TableSortLabel active={sortField === GetAccountSettingsRequest.SortField.SORT_FIELD_STATUS} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Status</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetAccountSettingsRequest.SortField.SORT_FIELD_HAS_API_KEY)}>
                      <TableSortLabel active={sortField === GetAccountSettingsRequest.SortField.SORT_FIELD_HAS_API_KEY} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Has API Key</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSortRequest(GetAccountSettingsRequest.SortField.SORT_FIELD_CREATED_TIME)}>
                    <TableSortLabel active={sortField === GetAccountSettingsRequest.SortField.SORT_FIELD_CREATED_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Created</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {accountSettings?.map((row) => (
                  <StyledTableRow hover key={row.getId()} onClick={() => navigateToAccountSettings(`${row.getId()}`)} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                    <StyledTableCell align="left">{row.getName()}</StyledTableCell>
                    <StyledTableCell align="center">{row.getAccountId()}</StyledTableCell>
                    <StyledTableCell align="center">{enumName(AccountSettings.AccountSettingsStatus, row.getStatus(), false)}</StyledTableCell>
                    <StyledTableCell align="center">{(row.getApiKeyId() !== "").toString()}</StyledTableCell>
                    <StyledTableCell align="right">{row.getAuditData()?.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    );
}

export default AccountSettingInfinityTable;