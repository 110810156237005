import React, {ReactElement, FC} from "react";
import {Grid, Typography} from '@mui/material';
import MasterContainer from "../components/containers/MasterContainer";
import {useParams} from "react-router-dom";
import TodoCard from "../components/cards/TodoCard";

const Todo: FC<any> = (): ReactElement => {
    const { scanJobId, propertySetId } = useParams();
    return (
        <MasterContainer fullWidth={true}>
            <Grid container spacing={1}>
                <Grid item xs={12}>&nbsp;</Grid>
                <Grid item xs={4}>
                    <Typography style={{fontWeight: 600}} variant="subtitle1" component="div">
                        Todo List
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TodoCard initialScanJobId={scanJobId} initialPropertySetId={propertySetId}/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};

export default Todo;