import React, {ReactElement, FC, useState, useEffect} from "react";
import {Property} from '../generated/sp/scan_service/scan_service_pb';
import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {RegionIdName, regionSelectorItems} from "../lib/regionUtils";
import RuleResultsTable from "./RuleResultsTable";

interface RegionResultsTableProps {
  regionResults: Property.RegionResult[] | undefined;
  maxHeight: number;
}

const RegionResultsTable: FC<RegionResultsTableProps> = (props): ReactElement => {
    const [region, setRegion] = useState<string>("");
    const [availableRegions, setAvailableRegions] = useState<RegionIdName[]>([]);

    useEffect(() => {
        (() => {
            if(props.regionResults !== undefined) {
                let regionIds = props.regionResults.map((regionResult) => regionResult.getScanRegionId());
                let filtered = regionSelectorItems().filter((regionItem) => regionIds.includes(regionItem.id));
                if (filtered.length > 0) {
                  setRegion(filtered[0].id);
                  setAvailableRegions(filtered);
                }

            }
        })();
    }, [props.regionResults]);

    const handleRegionChange = (event: SelectChangeEvent) => {
        setRegion(event.target.value);
    };

    const getFilteredRuleResults = (): Property.RuleResult[] => {
        if (props.regionResults !== undefined) {
            let result = props.regionResults
              .filter((regionResult) => regionResult.getScanRegionId() === region)
              .map((regionResult) => regionResult.getRuleResultsList());
            return result.flat();
        }
        return [];
    }

    return (
      <Grid container spacing={1} alignItems="center">
          { props.regionResults !== undefined && props.regionResults.length > 0 &&
            <React.Fragment>
              <Grid item xs={2}>
                  <FormControl fullWidth>
                      <InputLabel id="region">Region</InputLabel>
                      <Select size="small" labelId="region" id="region" value={region} label="Region"
                              onChange={handleRegionChange}>
                          {availableRegions.map((region) => (
                            <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
                  <RuleResultsTable ruleResults={getFilteredRuleResults()} maxHeight={250}/>
              </Grid>
            </React.Fragment>
        }
      </Grid>
    );
}


export default RegionResultsTable;