import React, {ReactElement, FC, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid, IconButton,
    TextField, Tooltip,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from '@mui/material';
import {ScanServiceInternalClient} from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {GenerateApiKeyRequest, RevokeApiKeyRequest} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import {generateAuthHeader} from '../../lib/authorizationUtils';
import {SxProps} from "@mui/system";

interface GenerateApiKeyCardProps {
    accountId: number | undefined;
    currentApiKey: string | undefined;
    generationCompleteFunction: () => void;
    sx?: SxProps<Theme>;
}

const GenerateApiKeyCard: FC<GenerateApiKeyCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [apiKeyId, setApiKeyId] = useState<string | undefined>(undefined);
    const [apiKeySecret, setApiKeySecret] = useState<string | undefined>(undefined);
    const [copyFeedback, setCopyFeedback] = useState<string | undefined>(undefined);

    // generate/revoke API key dialogs
    const [generateApiKeyOpen, setGenerateApiKeyOpen] = React.useState(false);
    const [revokeApiKeyOpen, setRevokeApiKeyOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickGenerateApiKey = () => {
        setGenerateApiKeyOpen(true);
    };
    const handleClickRevokeApiKey = () => {
        setRevokeApiKeyOpen(true);
    };

    const getGenerateHasAccessStatement = () : string => {
        return props.currentApiKey !== undefined ? "API Access is currently ENABLED for this account" : "API Access has not been set for this account";
    }

    const generateApiKey = async (scanServiceInternal: ScanServiceInternalClient): Promise<[string,string]> => {
        var req = new GenerateApiKeyRequest();
        req.setAccountId(props.accountId!);
        return new Promise<[string,string]>((resolve, reject) => {
            scanServiceInternal.generateApiKey(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve([response.getApiKeyId(), response.getApiKeySecret()])
            });
        });
    }

    const revokeApiKey = async (scanServiceInternal: ScanServiceInternalClient, apiKeyId: string): Promise<number> => {
        var req = new RevokeApiKeyRequest();
        req.setAccountId(props.accountId!);
        req.setApiKeyId(apiKeyId)
        return new Promise<number>((resolve, reject) => {
            scanServiceInternal.revokeApiKey(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getRecordsUpdated())
            });
        });
    }

    const handleCopyToClipboard = async (data: string) => {
        await navigator.clipboard.writeText(data);
        setCopyFeedback("Copied!");
        console.log("Copied key to clipboard!");
    }

    const handleGenerateApiKeyDialogClose = (shouldGenerateKey: boolean) => () => {
        if (shouldGenerateKey) {
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            Promise.resolve(generateApiKey(scanServiceInternal)).then(function ([apiKeyId, apiKeySecret]) {
                console.log(`Key Generated -> [${apiKeyId}][${apiKeySecret}]`);
                setApiKeyId(apiKeyId);
                setApiKeySecret(apiKeySecret);
                setCopyFeedback(undefined);
                props.generationCompleteFunction();
                setLoading(false);
            }, function (value) {
                // not called
            });
        }
        setGenerateApiKeyOpen(false);
    };

    const handleRevokeApiKeyDialogClose = (shouldRevokeKey: boolean) => () => {
        if (shouldRevokeKey) {
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            Promise.resolve(revokeApiKey(scanServiceInternal, props.currentApiKey!)).then(function (rowsUpdated) {
                console.log(`Revoked key -> [${apiKeyId}][${rowsUpdated}]`);
                setApiKeyId(undefined);
                setApiKeySecret(undefined);
                setCopyFeedback(undefined);
                props.generationCompleteFunction();
                setLoading(false);
            }, function (value) {
                // not called
            });
        }
        setRevokeApiKeyOpen(false);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Dialog fullScreen={fullScreen} open={generateApiKeyOpen} onClose={handleGenerateApiKeyDialogClose(false)} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Generate API Key</DialogTitle>
                    <DialogContent>
                        <DialogContentText> Are you sure you want to Generate an API Key?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" autoFocus onClick={handleGenerateApiKeyDialogClose(false)}>Cancel</Button>
                        <Button size="small" variant="contained" onClick={handleGenerateApiKeyDialogClose(true)} autoFocus color="secondary">Generate</Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullScreen={fullScreen} open={revokeApiKeyOpen} onClose={handleRevokeApiKeyDialogClose(false)} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Revoke API Key</DialogTitle>
                    <DialogContent>
                        <DialogContentText> Are you sure you want to revoke this API Key?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" autoFocus onClick={handleRevokeApiKeyDialogClose(false)}>Cancel</Button>
                        <Button size="small" variant="contained" onClick={handleRevokeApiKeyDialogClose(true)} autoFocus color="secondary">Revoke</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1, mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">API Key</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 1}} variant="subtitle2" component="div">{getGenerateHasAccessStatement()}</Typography>
                    </Grid>
                    { (apiKeyId === undefined && apiKeySecret === undefined && props.currentApiKey !== undefined) &&
                        <React.Fragment>
                            <Grid item xs={9}>
                                <TextField size="small" label="Current API Key ID" type="string" value={props.currentApiKey} inputProps={{ readOnly: true}} fullWidth/>
                            </Grid>
                            <Grid item xs={3}>
                                <LoadingButton
                                size="small"
                                color="secondary"
                                onClick={handleClickRevokeApiKey}
                                loading={loading}
                                disabled={props.accountId === undefined}
                                loadingPosition="start"
                                startIcon={<RemoveIcon/>}
                                variant="contained"
                                >
                                Revoke
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                            </Grid>
                        </React.Fragment>
                    }
                    {(apiKeyId !== undefined && apiKeySecret !== undefined) &&
                        <React.Fragment>
                            <Grid item xs={10}>
                                <TextField size="small" label="API Key ID" type="string" value={apiKeyId} inputProps={{ readOnly: true}} fullWidth/>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip
                                    title={copyFeedback}
                                    leaveDelay={500}
                                >
                                    <IconButton aria-label="copy" onClick={() => handleCopyToClipboard(apiKeyId)}><ContentCopyIcon/></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField size="small" label="API Key Secret" type="string" value={apiKeySecret} inputProps={{ readOnly: true}} fullWidth/>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip
                                    title={copyFeedback}
                                    leaveDelay={500}
                                >
                                    <IconButton aria-label="copy" onClick={() => handleCopyToClipboard(apiKeySecret)}><ContentCopyIcon/></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ color: "warning.main" }}><Typography sx={{mb: 1}} variant="subtitle2" component="div">Please keep this in a safe place as it will not be shown again</Typography></Box>
                            </Grid>
                        </React.Fragment>
                    }
                    {(apiKeyId === undefined && apiKeySecret === undefined && props.currentApiKey === undefined) &&
                        <Grid item xs={6}>
                            <LoadingButton
                                size="small"
                                color="secondary"
                                onClick={handleClickGenerateApiKey}
                                loading={loading}
                                disabled={props.accountId === undefined}
                                loadingPosition="start"
                                startIcon={<AddIcon/>}
                                variant="contained"
                            >
                                Generate
                            </LoadingButton>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export default GenerateApiKeyCard;