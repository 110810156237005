import React, {ReactElement, FC} from "react";
import {
    Card,
    CardContent,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import DiagnoseHttpEventTable from "../DiagnoseHttpEventTable";

interface ScanTimelineDiagnoseEventsArtifactCardProps {
    scanJobId: string | undefined;
    artifactId: string | undefined;
    sx?: SxProps<Theme>;
}

const ScanTimelineDiagnoseEventsArtifactCard: FC<ScanTimelineDiagnoseEventsArtifactCardProps> = (props): ReactElement => {
    return (
        <Card>
            <CardContent sx={props.sx}>
                <DiagnoseHttpEventTable scanJobId={props.scanJobId} artifactId={props.artifactId} maxHeight={500}/>
            </CardContent>
        </Card>
    );
};

export default ScanTimelineDiagnoseEventsArtifactCard;