import React, {ReactElement, FC, useState, useEffect, useContext} from "react";
import {Box, Grid, LinearProgress, TextField, Toolbar, Typography} from '@mui/material';
import {ScanServiceClient} from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {PropertySet, GetPropertySetByIdRequest} from '../generated/sp/scan_service/scan_service_pb';
import {useNavigate, useParams} from 'react-router-dom';
import {enumName} from '../lib/enumUtils';
import {formatRegion} from '../lib/regionUtils';
import {generateAuthHeader, isUserAdminRole, isUserModelTrainingRole} from '../lib/authorizationUtils';
import PropertySetItemTable from "../components/PropertySetItemTable";
import PropertySetAccuracyTable from "../components/PropertySetAccuracyTable";
import BulkScanStalePropertiesCard from "../components/cards/BulkScanStalePropertiesCard";
import {SourcePointTokensContext} from "../App";
import MasterContainer from "../components/containers/MasterContainer";
import LoadingButton from "@mui/lab/LoadingButton";
import SchoolOutlineIcon from "@mui-extra/icons/SchoolOutlineIcon";
import ScanJobArtifactInfinityTable from "../components/ScanJobArtifactInfinityTable";
import RuleIcon from "@mui/icons-material/Rule";

const PropertySetDetail: FC<any> = (): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [propertySet, setPropertySet] = useState<PropertySet>();
    const {propertySetId} = useParams();
    const sourcePointTokens = useContext(SourcePointTokensContext);

    useEffect(() => {
        (async () => {
            console.log(`Loading data for property set id=[${propertySetId!}]`);
            setLoading(true);
            const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const propertySet = await getPropertySet(scanService, propertySetId!);
            setPropertySet(propertySet);
            setLoading(false);
        })();
    }, [propertySetId]);

    const getPropertySet = async (scanService: ScanServiceClient, propertySetId: string): Promise<PropertySet> => {
        var req = new GetPropertySetByIdRequest();
        req.addFields(PropertySet.PropertySetField.PROPERTY_SET_FIELD_PROPERTY_SET_ITEMS);
        req.addFields(PropertySet.PropertySetField.PROPERTY_SET_FIELD_PROPERTY_COUNT);
        req.setId(propertySetId);
        return new Promise<PropertySet>((resolve, reject) => {
            scanService.getPropertySetById(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getPropertySet()!)
            });
        });
    };

    const navigate = useNavigate();
    const navigateToScanJobTraining = (propertySetId: string | undefined) => {
        navigate(`/scanJobTraining/propertySet/${propertySetId}`);
    };
    const navigateToTodo = (propertySetId: string | undefined) => {
        navigate(`/todo/propertySet/${propertySetId}`);
    };
    return (
        <MasterContainer>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Property
                            Set</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField size="small" label="ID" type="string" value={propertySet?.getId() || ''}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField size="small" label="Name" type="string" value={propertySet?.getName() || ''}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField multiline maxRows={6} size="small" label="Description" type="string"
                                   value={propertySet?.getDescription() || ''} inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Status" type="string"
                                   value={enumName(PropertySet.PropertySetStatus, propertySet?.getStatus(), false)}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField size="small" label="Scan Rule Set ID" type="string"
                                   value={propertySet?.getScanRuleSetId() || ''} inputProps={{readOnly: true}}
                                   fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Region" type="string"
                                   value={formatRegion(propertySet?.getRegionId()) || ''} inputProps={{readOnly: true}}
                                   fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Account ID" type="number"
                                   value={propertySet?.getAccountId() || ''} inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1"
                                    component="div">Audit</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="Created" type="string"
                                   value={propertySet?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="Updated" type="string"
                                   value={propertySet?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>&nbsp;</Grid>
                    <Grid item xs={8}>
                        {isUserAdminRole(sourcePointTokens) &&
                            <BulkScanStalePropertiesCard propertySetId={propertySet?.getId()}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Toolbar disableGutters={true}>
                            {isUserModelTrainingRole(sourcePointTokens) &&
                                <LoadingButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => navigateToScanJobTraining(propertySet?.getId())}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SchoolOutlineIcon />}
                                    variant="contained">
                                    Train this Set
                                </LoadingButton>
                            }
                            <LoadingButton
                              size="small"
                              sx={{ ml: 1 }}
                              color="secondary"
                              onClick={() => navigateToTodo(propertySet?.getId())}
                              loading={loading}
                              loadingPosition="start"
                              startIcon={<RuleIcon />}
                              variant="contained">
                                TODO this set
                            </LoadingButton>
                        </Toolbar>
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> :
                            <Box sx={{height: 10}}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Stats</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="Number of Scanned Properties" type="number"
                                   value={propertySet?.getStats()?.getScannedPropertyCount() || 0}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="Number of Properties" type="number"
                                   value={propertySet?.getPropertyCount() || 0}
                                   inputProps={{readOnly: true}} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Accuracy</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PropertySetAccuracyTable accuracyMetrics={propertySet?.getStats()?.getPerRuleMetricsMap()} maxHeight={250}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Property
                            Set Items</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PropertySetItemTable propertySetItems={propertySet?.getPropertySetItemsList()} maxHeight={250}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ScanJobArtifactInfinityTable maxHeight={250} showFilterUi={true} showScanJobLink={true} showArtifactId={false} embeddedPropertySetIdFilter={propertySetId}/>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12}><br/></Grid>
                </Grid>
            </Box>
        </MasterContainer>
    );
};

export default PropertySetDetail;