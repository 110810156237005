import React, {ReactElement, FC} from "react";
import {Grid, Typography} from '@mui/material';
import VncCard from "../components/cards/VncCard";
import MasterContainer from "../components/containers/MasterContainer";

const Remote: FC<any> = (): ReactElement => {

    return (
        <MasterContainer fullWidth={true}>
            <Grid container spacing={1}>
                <Grid item xs={12}>&nbsp;</Grid>
                <Grid item xs={3}>
                    <Typography style={{fontWeight: 600}} variant="subtitle1" component="div">
                        Remote Control Chrome
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <VncCard/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};

export default Remote;