import React, {ReactElement, FC, useState, useEffect, useCallback, useContext} from "react";
import {Box, Grid, LinearProgress, TextField, Typography} from '@mui/material';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {AccountSettings, AccountSettingsData, GetAccountSettingsByIdRequest, ScanRule} from '../generated/sp/scan_service/scan_service_pb';
import { useParams } from 'react-router-dom';
import { enumName } from '../lib/enumUtils';
import {generateAuthHeader, isUserAdminRole} from '../lib/authorizationUtils';
import MasterContainer from "../components/containers/MasterContainer";
import GenerateApiKeyCard from "../components/cards/GenerateApiKeyCard";
import {formatRegion} from "../lib/regionUtils";
import SimpleTable from "../components/SimpleTable";
import {SourcePointTokensContext} from "../App";
import {fetchScanRuleMapById} from "../lib/scanServiceUtils";

const AccountSettingDetail: FC<any> = (): ReactElement => {
    const sourcePointTokens = useContext(SourcePointTokensContext);
    const [loading, setLoading] = useState(false)
    const [accountSettings, setAccountSettings] = useState<AccountSettings>();
    const [scanRuleMap, setScanRuleMap] = useState<Map<string,ScanRule> | undefined>(undefined)
    const { accountSettingsId } = useParams();

    // 👇️ get memoized callback
    const reloadAccountSettings = useCallback(async () => {
        console.log(`Loading data for account setting id=[${accountSettingsId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const accountSettings = await getAccountSettings(scanService, accountSettingsId!);
        setScanRuleMap(await fetchScanRuleMapById(scanService));
        console.log(`Finished reloading account setting=${accountSettingsId}`);
        setAccountSettings(accountSettings);
        setLoading(false);
    }, [accountSettingsId]);

    useEffect(() => {
        (() => {
            reloadAccountSettings();
        })();
    }, [reloadAccountSettings]);

    const getAccountSettings = async (scanService: ScanServiceClient, accountSettingsId: string): Promise<AccountSettings> => {
      var req = new GetAccountSettingsByIdRequest();
      req.setId(accountSettingsId);
      return new Promise<AccountSettings>((resolve, reject) => {
        scanService.getAccountSettingsById(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response.getAccountSettings()!)
        });
      });
    };

    return (
        <MasterContainer>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Account Setting</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField size="small" label="ID" type="string" value={accountSettings?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Status" type="string" value={enumName(AccountSettings.AccountSettingsStatus, accountSettings?.getStatus(), false)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="AccountID" type="string" value={accountSettings?.getAccountId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" label="AccountName" type="string" value={accountSettings?.getName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <SimpleTable rows={accountSettings?.getData()?.getPermissionsList().map(permission => [enumName(AccountSettingsData.Permission, permission, false, "PERMISSION")])} headerNames={["Permissions"]} maxHeight={300}/>
                </Grid>
                <Grid item xs={12}>
                  <SimpleTable rows={accountSettings?.getData()?.getAvailableRuleIdsList().map(x => [scanRuleMap?.get(x)?.getName() || x])} headerNames={["Available Scan Rules"]} maxHeight={300}/>
                </Grid>
                <Grid item xs={12}>
                  <SimpleTable rows={accountSettings?.getData()?.getAvailableRegionIdsList().map(x => [formatRegion(x)])} headerNames={["Available Regions"]} maxHeight={300}/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Unique Region Property Count" type="string" value={accountSettings?.getData()?.getUniqueRegionPropertyCount() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Audit</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Created" type="string" value={accountSettings?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Updated" type="string" value={accountSettings?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                { isUserAdminRole(sourcePointTokens) &&
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">External API</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <GenerateApiKeyCard accountId={accountSettings?.getAccountId()} currentApiKey={accountSettings?.getApiKeyId() === "" ? undefined : accountSettings?.getApiKeyId()} generationCompleteFunction={() => reloadAccountSettings()}/>
                    </Grid>
                  </React.Fragment>
                }
                <Grid item xs={12}>
                  {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
                <Grid item xs={12}><br/></Grid>
            </Grid>
            </MasterContainer>
    );
};

export default AccountSettingDetail;