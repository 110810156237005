import React, {FC, ReactElement, useCallback, useEffect, useState} from "react";
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceInternalClient} from "../../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {ComputeScanJobStatisticsRequest, ComputeScanJobStatisticsResponse,} from "../../generated/sp/scan_service/scan_service_internal_pb";
import {generateAuthHeader} from "../../lib/authorizationUtils";
import {enumName} from "../../lib/enumUtils";
import {ScanJob} from "../../generated/sp/scan_service/scan_service_pb";
import {TimestampFilter} from "../../generated/sp/service_common/common_pb";
import {getCurrentTimestampMinusDays} from "../../lib/timestampUtils";
import {Box, LinearProgress} from "@mui/material";
import type {ChartData} from 'chart.js';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const generateChartData = (stats: ComputeScanJobStatisticsResponse.ScanJobStatistic[]): ChartData<'bar'> => {
    let labels = stats.map((stat) => {
        return enumName(ScanJob.ScanJobStatus, stat.getStatus(), false);
    });
    let data = stats.map((stat) => {
        return stat.getCount();
    });
    let backgroundColor = stats.map((stat) => {
        switch(stat.getStatus()) {
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_UNSET:
                return "#4A8544";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_QUEUED:
                return "#177E89";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_RUNNING:
                return "#B0F7F2";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_COMPLETE:
                return "#3F248F";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_ERROR:
                return "#DE8526";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_FAILED:
                return "#DE8526";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_POD_MISSING:
                return "#F5C287";
            case ScanJob.ScanJobStatus.SCAN_JOB_STATUS_KILLED:
                return "#FF0000";
            default: {
                return "#FFFDFA";
            }
        }
    });

    return ({
        labels: labels,
        datasets: [
            {
                label: 'Status',
                borderWidth: 1,
                data: data,
                backgroundColor: backgroundColor
            }
        ]
    });
}

interface ScanJobStatisticsBarChartProps {
    daysBack: number
    maxHeight: number;
    sx?: SxProps<Theme>;
}

const ScanJobStatisticsBarChart: FC<ScanJobStatisticsBarChartProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanJobStatistics, setScanJobStatistics] = useState<ComputeScanJobStatisticsResponse.ScanJobStatistic[]>([]);

    const getScanJobStatistics = async (scanServiceInternal: ScanServiceInternalClient, newerThanDaysFilter: number): Promise<ComputeScanJobStatisticsResponse.ScanJobStatistic[]> => {
        var req = new ComputeScanJobStatisticsRequest();
        let filters = new ComputeScanJobStatisticsRequest.Filters();
        // convert newerThanDaysFilter to TimestampFilter
        filters.setCreationTimeFilter(new TimestampFilter().setOnOrAfter(getCurrentTimestampMinusDays(newerThanDaysFilter)))
        req.setFilters(filters);
        return new Promise<ComputeScanJobStatisticsResponse.ScanJobStatistic[]>((resolve, reject) => {
            scanServiceInternal.computeScanJobStatistics(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getStatisticsList())
            });
        });
    }
    const refreshScanJobStatistics = useCallback(() => {
        console.log("Loading scan job stats...")
        setLoading(true);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(getScanJobStatistics(scanServiceInternal, props.daysBack)).then(function (scanJobStatistics) {
            console.log(`Found scanJobStatistics of size=${scanJobStatistics.length}`);
            setScanJobStatistics(scanJobStatistics);
            setLoading(false);
        }, function (rejected) {
            console.error(rejected);
            setLoading(false);
        });
    }, [props.daysBack]);

    useEffect(() => {
        (() => {
            refreshScanJobStatistics();
        })();
    }, [refreshScanJobStatistics]);

    return (
      <React.Fragment>
          {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> :
            <Box sx={{height: 10}}>&nbsp;</Box>}
          <Bar style={{maxHeight: props.maxHeight + "px"}} options={{
              responsive: true,
              plugins: {
                  legend: {
                      display: false,
                      position: 'top' as const,
                  },
                  title: {
                      display: true,
                      text: 'Scan Jobs',
                  },
              },
          }}
               data={generateChartData(scanJobStatistics)} />
      </React.Fragment>
    );
};

export default ScanJobStatisticsBarChart;