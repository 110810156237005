import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Card,
    CardContent, CardMedia,
    Grid, LinearProgress, Paper, Table, TableBody, TableContainer, TableHead,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {StyledTableCell, StyledTableRow} from "../../lib/tableUtils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {formatStringMaxLength} from "../../lib/stringUtils";
import {ScanArtifact} from "../../generated/sp/scan_runner/scan_runner_pb";
import {generateOverlayBox, OverlayBox} from "../../lib/overlayBoxUtils";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {Base64ImageAndMetadata, generateBase64ImageAndMetadata} from "../../lib/imageUtils";
import {fetchScanArtifact} from "../../lib/scanServiceUtils";

const determineOverlayBoxes = (metadata: ScanArtifact.ImageMetadata, scaleFactor: number): OverlayBox[] => {
    var colorChoices: string[] = ['#FFB570', '#FF5D52', '#FFEF8a', '#B8FFC6', '#F3FFD1'];
    return metadata.getRegionsList()
      .filter((region) => region !== undefined)
      .filter((region) => region.getBoundingBox() !== undefined)
      .map((region, index) => {
          let color = colorChoices.length > 0 ? colorChoices[Math.floor(Math.random() * colorChoices.length)] : "black";
          const colorIndex = colorChoices.indexOf(color, 0);
          // remove the color from the choices so we don't get a dupe
          if (colorIndex > -1) {
              colorChoices.splice(colorIndex, 1);
          }
          return generateOverlayBox(index + 1, region.getName(), region.getText(), region.getXpath(), color, scaleFactor, region.getBoundingBox()!, metadata!.getWidth(), metadata!.getHeight());
      });
}

interface ScanTimelineImageArtifactCardProps {
    scanJobId: string | undefined;
    artifactId: string | undefined;
    sx?: SxProps<Theme>;
}

const ScanTimelineImageArtifactCard: FC<ScanTimelineImageArtifactCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanTimelineImage, setScanTimelineImage] = useState<Base64ImageAndMetadata | undefined>(undefined);
    const [overlayBoxes, setOverlayBoxes] = useState<OverlayBox[]>([]);
    const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
    const [scaleFactor] = useState<number>(0.7);


    const reloadScanJobArtifact = useCallback(async () => {
        console.log(`Loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanArtifact = await fetchScanArtifact(scanService, props.scanJobId!, props.artifactId!);
        if (scanArtifact !== undefined && scanArtifact.getArtifactId() !== '') {
            const data = generateBase64ImageAndMetadata(scaleFactor, scanArtifact);
            console.log(`Finished loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
            setScanTimelineImage(data);
            setOverlayBoxes(determineOverlayBoxes(data.metadata!, scaleFactor))
            setLoading(false);
        } else {
            console.info(`Failed to load artifact for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        }

    }, [props.scanJobId, props.artifactId, scaleFactor]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined && props.artifactId !== undefined) {
                await reloadScanJobArtifact();
            }
        })();
    }, [props.scanJobId, props.artifactId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOverlayVisibilityToggle = (overlayName: string) => {
        let revisedOverlays : OverlayBox[] = overlayBoxes.map((overlay) => {
            if(overlay.name === overlayName) {
                overlay.hidden = !overlay.hidden;
            }
            return overlay;
        });
        setOverlayBoxes(revisedOverlays);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1}} style={{ fontWeight: 600 }} variant="subtitle1" component="div">{scanTimelineImage?.base64Image?.name || ''} ({scanTimelineImage?.metadata?.getWidth()} x {scanTimelineImage?.metadata?.getHeight()}) for ScanJobId: [{props.scanJobId}]</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} style={{ maxHeight: "150px" }}>
                            <Table stickyHeader size="small" aria-label="events">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center" width="3%">Visibility</StyledTableCell>
                                        <StyledTableCell align="left" width="27%">Bounding Box</StyledTableCell>
                                        <StyledTableCell align="left" width="10%">Name</StyledTableCell>
                                        <StyledTableCell align="left" width="15%">Text</StyledTableCell>
                                        <StyledTableCell align="left">XPath</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {overlayBoxes.map((row) => (
                                      <StyledTableRow hover key={row.id} onClick={() => { setSelectedRow(row.id); }} selected={row.id === selectedRow} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                                          <StyledTableCell align="center" onClick={() => { handleOverlayVisibilityToggle(row.name); }}>
                                              {row.hidden ?
                                                <CheckBoxOutlineBlankIcon color="secondary"/>
                                                :
                                                <CheckBoxIcon color="secondary" />
                                              }
                                          </StyledTableCell>
                                          <StyledTableCell align="left" sx={{ bgcolor:row.color}}>Top:{row.top}, Bottom:{row.bottom}, Left:{row.left}, Right:{row.right}</StyledTableCell>
                                          <StyledTableCell align="left">{row.name}</StyledTableCell>
                                          <StyledTableCell align="left">{formatStringMaxLength(row.text)}</StyledTableCell>
                                          <StyledTableCell align="left">{row.xpath}</StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                    {overlayBoxes.length === 0 &&
                                      <StyledTableRow>
                                          <StyledTableCell align="center" colSpan={5}><Typography style={{ fontWeight: 600 }} variant="subtitle2">No Data</Typography></StyledTableCell>
                                      </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sx={{mt: 1}}>
                        {scanTimelineImage?.base64Image !== undefined &&
                          <Card sx={{ maxWidth: scanTimelineImage.base64Image?.scaledWidth, maxHeight: scanTimelineImage.base64Image?.scaledHeight}}>
                              <Box sx={{ position: 'relative' }}>
                                  <CardMedia
                                    component='img'
                                    style={{ width: scanTimelineImage.base64Image.scaledWidth, height: scanTimelineImage.base64Image?.scaledHeight }}
                                    src={`data:image/jpeg;base64,${scanTimelineImage?.base64Image.base64Content}`}
                                    alt={scanTimelineImage.base64Image?.name}
                                    title={scanTimelineImage.base64Image?.name}
                                  />
                                  {overlayBoxes.filter((x) => !x.hidden).map((overlayBox) => (
                                    <Box
                                      key={overlayBox!.name}
                                      onClick={() => { setSelectedRow(overlayBox!.id); }}
                                      sx={{
                                          position: 'absolute',
                                          top: overlayBox!.top,
                                          bottom: overlayBox!.bottom,
                                          left: overlayBox!.left,
                                          right: overlayBox!.right,
                                          bgcolor: overlayBox!.color,
                                          opacity: 0.45,
                                          transition: "transform 0.15s ease-in-out",
                                          transform: overlayBox!.id === selectedRow ?  "scale3d(1.05, 1.05, 1)"  : "",
                                          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }
                                      }}
                                    >
                                    </Box>
                                  ))}
                              </Box>
                          </Card>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanTimelineImageArtifactCard;