import React, {FC, ReactElement} from "react";
import {Container, Grid, Typography} from "@mui/material";

export const Footer: FC = (): ReactElement => {
    return (
        <Container
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "#2E2E2E",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}>
            <Container>
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography color="white" variant="subtitle1">
                            PL Eng Console
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="white" variant="subtitle2">
                            © SOURCEPOINT {`${new Date().getFullYear()} ALL RIGHTS RESERVED`}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default Footer;