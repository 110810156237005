import React, {ReactElement, FC} from "react";
import {
    Card,
    CardContent,
    Grid, TextField
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import ScanJobResultTable from "../ScanJobResultTable";
import {enumName} from "../../lib/enumUtils";
import {ScanError} from "../../generated/sp/scan_runner/scan_runner_pb";
import {ScanJob} from "../../generated/sp/scan_service/scan_service_pb";

interface ScanResultsCardProps {
    maxHeight: number;
    scanJobId: string | undefined;
    scanJobResult: ScanJob.ScanJobResult | undefined;
    sx?: SxProps<Theme>;
}

const ScanResultsCard: FC<ScanResultsCardProps> = (props): ReactElement => {
    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12}>
                        { props.scanJobResult !== undefined && !props.scanJobResult?.hasScanError() &&
                          <ScanJobResultTable scanJobId={props.scanJobId} maxHeight={props.maxHeight}/>
                        }
                        { props.scanJobResult !== undefined && props.scanJobResult?.hasScanError() &&
                          <Grid container spacing={2}>
                              <Grid item xs={4}>
                                  <TextField size="small" label="Scan Error" type="string" value={enumName(ScanError.ErrorType, props.scanJobResult?.getScanError()?.getErrorType(), false, "ERROR_TYPE")} inputProps={{  readOnly: true, style: { color: "red" }}} fullWidth/>
                              </Grid>
                              <Grid item xs={12}>
                                  <TextField multiline minRows={6} maxRows={6} size="small" label="Error Message" type="string" value={props.scanJobResult?.getScanError()?.getErrorMessage() || ''} inputProps={{ readOnly: true, style: { color: "red" }}} fullWidth/>
                              </Grid>
                          </Grid>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanResultsCard;