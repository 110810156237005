import React, {FC, ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {ScanServiceClient} from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {GetScanJobsRequest, GetScanJobsResponse, ScanJob} from '../generated/sp/scan_service/scan_service_pb';
import {PagingParameters, SortDirection, StringFilter, StringList, TimestampFilter} from '../generated/sp/service_common/common_pb';
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableSortLabel,
    TextField,
    Typography
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {BooleanEnum, enumName} from '../lib/enumUtils';
import {formatRegion, regionSelectorItems} from '../lib/regionUtils';
import {generateAuthHeader} from '../lib/authorizationUtils';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {getScanJobStatusColor} from '../lib/statusUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useUpdateEffect} from 'usehooks-ts'
import {SELECTOR_ALL_ID, SELECTOR_ALL_ID_NAME, SelectorIdName} from "../lib/selectorUtils";
import {iabCmpSelectorList} from "../lib/iabUtils";
import {formatGatingType, gatingTypeSelectorItems} from "../lib/gatingUtils";
import {getCurrentTimestampMinusDays} from "../lib/timestampUtils";
import {CsvHeader} from "../lib/exportCsvUtils";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";

const generateGetScanJobsRequest = (continuationToken: string | undefined, limit: number, sortField: GetScanJobsRequest.SortField, sortDirection: SortDirection, scanJobIdsFilter: string[] | undefined, newerThanDaysFilter: number | undefined, propertyNameFilter: string | undefined, precisePropertyNameSearch: boolean, scanJobStatusFilter: ScanJob.ScanJobStatus, scanJobScanTypeFilter: ScanJob.ScanType, podTerminationStatusFilter: ScanJob.PodTerminationStatus, trainingDataFilter: BooleanEnum, regionFilter: string, iabCmpFilter: string, gatingFilter: ScanJob.ScanJobGating.GatingType): GetScanJobsRequest => {
  // determine filtering.
  var req = new GetScanJobsRequest();
  let filters = new GetScanJobsRequest.Filters();
  if( scanJobStatusFilter > 0) {
    filters.addStatusFilter(scanJobStatusFilter);
  }
  if( scanJobScanTypeFilter > 0) {
    filters.addScanTypeFilter(scanJobScanTypeFilter);
  }
  if( podTerminationStatusFilter > 0) {
    filters.addPodTerminationStatusFilter(podTerminationStatusFilter)
  }
  if (trainingDataFilter != null) {
    let artifactFilters = new GetScanJobsRequest.Filters.ArtifactFilters();
    switch(trainingDataFilter) {
        case BooleanEnum.TRUE: {
            filters.addArtifactFilters(artifactFilters.setTrainingDataSetFilter(true));
            break;
        }
        case BooleanEnum.FALSE: {
            filters.addArtifactFilters(artifactFilters.setTrainingDataSetFilter(false));
            break;
        }
        default: {
            // do nothing.. filter is unset.
            break;
        }
    }
  }
  if ( scanJobIdsFilter !== undefined && scanJobIdsFilter.length > 0) {
    scanJobIdsFilter.forEach((scanJobId) => filters.addIdFilter(scanJobId));
  }
  if (newerThanDaysFilter !== undefined && newerThanDaysFilter > 0) {
    filters.setCompletionTimeFilter(new TimestampFilter().setOnOrAfter(getCurrentTimestampMinusDays(newerThanDaysFilter)))
  }
  if( propertyNameFilter !== undefined && propertyNameFilter.length > 0) {
    if (precisePropertyNameSearch) {
      filters.addPropertyNameFilter(new StringFilter().setCaseInsensitive(false).setExactMatch(new StringList().addValues(propertyNameFilter)));
    } else {
      filters.addPropertyNameFilter(new StringFilter().setCaseInsensitive(true).setContains(new StringList().addValues(propertyNameFilter)));
    }

  }
  if( regionFilter.length > 0 && regionFilter !== SELECTOR_ALL_ID) {
    filters.addRegionIdFilter(regionFilter);
  }
  if( iabCmpFilter.length > 0 && iabCmpFilter !== SELECTOR_ALL_ID) {
    filters.addConsentCmpIdFilter(parseInt(iabCmpFilter));
  }
  if( gatingFilter > 0) {
    filters.addGatingFilter(gatingFilter)
  }
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetScanJobsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchScanJobs = async (scanService: ScanServiceClient, request: GetScanJobsRequest): Promise<GetScanJobsResponse> => {
  return new Promise<GetScanJobsResponse>((resolve, reject) => {
    scanService.getScanJobs(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface ScanJobInfinityTableProps {
  maxHeight: number;
  showFilterUi: boolean;
  showExportUi: boolean;
  embeddedMode: boolean; // if true, table with render without infinity mode
  scanJobIdsFilter?: string[] | undefined;
  propertyNameFilter?: string | undefined;
  regionFilter?: string | undefined;
  iabCmpFilter? : string | undefined;
  newerThanDaysFilter? : number | undefined;
  forceRefresh?: number;
}

const ScanJobInfinityTable: FC<ScanJobInfinityTableProps> = (props): ReactElement => {
    const [scanJobs, setScanJobs] = useState<ScanJob[]>([]);
    const [propertyNameFilter, setPropertyNameFilter] = useState<string | undefined>(undefined);
    const [scanJobStatusFilter, setScanJobStatusFilter] = useState<ScanJob.ScanJobStatus>(ScanJob.ScanJobStatus.SCAN_JOB_STATUS_UNSET);
    const [scanJobScanTypeFilter, setScanJobScanTypeFilter] = useState<ScanJob.ScanType>(ScanJob.ScanType.SCAN_TYPE_UNSET);
    const [podTerminationStatusFilter, setPodTerminationStatusFilter] = useState<ScanJob.PodTerminationStatus>(ScanJob.PodTerminationStatus.POD_TERMINATION_STATUS_UNSET);
    const [trainingDataFilter, setTrainingDataFilter] = useState<BooleanEnum>(BooleanEnum.UNSET);
    const [regionFilter, setRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [iabCmpFilter, setIabVendorFilter] = useState<string>(SELECTOR_ALL_ID);
    const [gatingFilter, setGatingFilter] = useState<ScanJob.ScanJobGating.GatingType>(ScanJob.ScanJobGating.GatingType.GATING_TYPE_UNSET);
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetScanJobsRequest.SortField>(GetScanJobsRequest.SortField.SORT_FIELD_CREATED_TIME);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_DESC);
    const [limit] = useState<number>(50);
    const [iabCmps, setIabCmps] = useState<SelectorIdName[]>([SELECTOR_ALL_ID_NAME]);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToScanJob = (scanJobId: String) => {
        navigate(`/scanJobs/${scanJobId}`);
    };

    useEffect(() => {
        (async () => {
            setScanJobs([]);
            setRefreshToggle(true);
        })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading scan job list data, props.scanJobIdsFilter=[${props.scanJobIdsFilter}]`);
        setLoading(true);
        let consolidatedPropertyNameFilter = props.propertyNameFilter !== undefined ? props.propertyNameFilter : propertyNameFilter; // allow props specification to override state variable
        let precisePropertyNameSearchFilter = props.propertyNameFilter !== undefined;
        let consolidatedRegionFilter = props.regionFilter !== undefined ? props.regionFilter : regionFilter; // allow props specification to override state variable
        let consolidatedCmpIdFilter = props.iabCmpFilter !== undefined ? props.iabCmpFilter : iabCmpFilter; // allow props specification to override state variable

        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetScanJobsRequest(currentContinuationToken, limit, sortField, sortDirection, props.scanJobIdsFilter, props.newerThanDaysFilter, consolidatedPropertyNameFilter, precisePropertyNameSearchFilter, scanJobStatusFilter, scanJobScanTypeFilter, podTerminationStatusFilter, trainingDataFilter, consolidatedRegionFilter, consolidatedCmpIdFilter, gatingFilter);
        const scanJobsResponse = await fetchScanJobs(scanService, req);
        let continuationToken = scanJobsResponse.getContinuationToken().length > 0 ? scanJobsResponse.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        if (props.embeddedMode) {
          if (props.scanJobIdsFilter?.length === 0
            && props.propertyNameFilter?.length === 0) {
              // this is a safety to prevent false loading of an unfiltered list of scan jobs. one of the prop filters should be set here
              setScanJobs([]);
          } else {
              // always set the entire list, not infinity mode
              setScanJobs(scanJobsResponse.getScanJobsList());
          }
        } else {
          // to support scrolling, we need to append to whatever is currently loaded, infinity mode
          setScanJobs(old => [...old, ...scanJobsResponse.getScanJobsList()]);
        }
        let iabCmps = await iabCmpSelectorList();
        setIabCmps(iabCmps);
        setLoading(false);
      })();
    }, [props.scanJobIdsFilter, currentContinuationToken, limit, scanJobStatusFilter, scanJobScanTypeFilter, podTerminationStatusFilter, trainingDataFilter, regionFilter, iabCmpFilter, gatingFilter, sortDirection, sortField, refreshToggle]);

    const exportCsvHeaders = (): CsvHeader[] => {
      return [
        { label: "Property Name", key: "propertyName" },
        { label: "Scan Type", key: "scanJobType" },
        { label: "Gating", key: "gating" },
        { label: "Region", key: "region" },
        { label: "Status", key: "status" },
        { label: "Created", key: "created" },
        { label: "Completed", key: "completionTime" },
      ]
    }

    interface CsvExportData {
      propertyName: string;
      scanJobType: string;
      gating: string;
      region: string;
      status: string
      created: string;
      completionTime: string;
    }

    const exportCsvData = (): CsvExportData[] => {
      return scanJobs.map((scanJob) => {
        return {
          propertyName: scanJob.getParameters()?.getPropertyName() || '',
          scanJobType: enumName(ScanJob.ScanType, scanJob.getScanType(), false, "SCAN_TYPE"),
          gating: formatGatingType(scanJob.getGatingType()),
          region: formatRegion(scanJob.getParameters()?.getRegion()),
          status: enumName(ScanJob.ScanJobStatus, scanJob.getStatus(), false),
          created: scanJob.getAuditData()?.getCreated()?.toDate().toLocaleString() || '',
          completionTime: scanJob.getCompletionTime()?.toDate().toLocaleString() || ''
        };
      });
    }

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
        setScanJobs([]); // wipe out data such that we dont keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handleScanJobStatusFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== scanJobStatusFilter) {
            startFreshSearch();
            setScanJobStatusFilter(revised);
        }
    };

    const handleScanJobScanTypeFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== scanJobScanTypeFilter) {
            startFreshSearch();
            setScanJobScanTypeFilter(revised);
        }
    };

    const handlePodTerminationStatusFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== podTerminationStatusFilter) {
            startFreshSearch();
            setPodTerminationStatusFilter(revised);
        }
    };

    const handleTrainingFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== trainingDataFilter.valueOf()) {
            startFreshSearch();
            setTrainingDataFilter(revised);
        }
    };

    const handleRegionFilterChange = (event: SelectChangeEvent) => {
        let revised = event.target.value;
        if ( revised !== regionFilter) {
            startFreshSearch();
            setRegionFilter(revised);
        }
    };

    const handleIabCmpFilterChange = (event: SelectChangeEvent) => {
      let revised = event.target.value;
      if ( revised !== iabCmpFilter) {
        startFreshSearch();
        setIabVendorFilter(revised);
      }
    };

    const handleGatingFilterChange = (event: SelectChangeEvent) => {
      let revised = parseInt(event.target.value);
      if ( revised !== gatingFilter) {
        startFreshSearch();
        setGatingFilter(revised);
      }
    };

    const handlePropertyNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPropertyNameFilter(event.target.value);
    };

    const handlePropertyNameFilterKeyChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if( event.key === 'Enter' ){
        handleRefresh();
      }
    };

    const handleSortRequest = (sortField: GetScanJobsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
        <div>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
               { props.showFilterUi &&
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter ScanJobs</Typography>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                          <InputLabel id="region">Region</InputLabel>
                          <Select size="small" labelId="region" id="region" value={regionFilter} label="Region" onChange={handleRegionFilterChange}>
                            {regionSelectorItems().map((region) => (
                              <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                            ))}
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel id="status">Status</InputLabel>
                        <Select size="small" labelId="status" id="status" value={scanJobStatusFilter.toString()} label="status" onChange={handleScanJobStatusFilterChange}>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_UNSET}>All</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_QUEUED}>Queued</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_RUNNING}>Running</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_FAILED}>Failed</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_ERROR}>Error</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_COMPLETE}>Complete</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_POD_MISSING}>Pod Missing</MenuItem>
                          <MenuItem value={ScanJob.ScanJobStatus.SCAN_JOB_STATUS_KILLED}>Killed</MenuItem>
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel id="scanType">Scan Type</InputLabel>
                        <Select size="small" labelId="scanType" id="scanType" value={scanJobScanTypeFilter.toString()} label="scanType" onChange={handleScanJobScanTypeFilterChange}>
                          <MenuItem value={ScanJob.ScanType.SCAN_TYPE_UNSET}>All</MenuItem>
                          <MenuItem value={ScanJob.ScanType.SCAN_TYPE_SCANNER_V1_SCAN}>Scanner V1</MenuItem>
                          <MenuItem value={ScanJob.ScanType.SCAN_TYPE_MANUAL_SCAN}>Manual</MenuItem>
                          <MenuItem value={ScanJob.ScanType.SCAN_TYPE_AUTOMATIC_SCAN}>Automatic</MenuItem>
                          <MenuItem value={ScanJob.ScanType.SCAN_TYPE_EXTENSION}>Extension</MenuItem>
                            <MenuItem value={ScanJob.ScanType.SCAN_TYPE_DIAGNOSE}>Diagnose</MenuItem>
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                          <InputLabel id="podTerminationStatus">Pod Termination Status</InputLabel>
                          <Select size="small" labelId="podTerminationStatus" id="podTerminationStatus" value={podTerminationStatusFilter.toString()} label="podTerminationStatus" onChange={handlePodTerminationStatusFilterChange}>
                              <MenuItem value={ScanJob.PodTerminationStatus.POD_TERMINATION_STATUS_UNSET}>All</MenuItem>
                              <MenuItem value={ScanJob.PodTerminationStatus.POD_TERMINATION_STATUS_PENDING_COMPLETED}>Pending Completed</MenuItem>
                              <MenuItem value={ScanJob.PodTerminationStatus.POD_TERMINATION_STATUS_PENDING_RUNNING}>Pending Running</MenuItem>
                              <MenuItem value={ScanJob.PodTerminationStatus.POD_TERMINATION_STATUS_PENDING_ERROR}>Pending Error</MenuItem>
                              <MenuItem value={ScanJob.PodTerminationStatus.POD_TERMINATION_STATUS_COMPLETED}>Completed</MenuItem>
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                          <InputLabel id="trainingData">Training Data?</InputLabel>
                          <Select size="small" labelId="trainingData" id="trainingData" value={trainingDataFilter.toString()} label="trainingData" onChange={handleTrainingFilterChange}>
                              <MenuItem value={BooleanEnum.UNSET}>All</MenuItem>
                              <MenuItem value={BooleanEnum.FALSE}>Exists</MenuItem>
                              <MenuItem value={BooleanEnum.TRUE}>Does not Exist</MenuItem>
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                          <InputLabel id="iabVendor">Iab CMP</InputLabel>
                          <Select size="small" labelId="iabCMP" id="iabCMP" value={iabCmpFilter} label="Vendor" onChange={handleIabCmpFilterChange}>
                            {iabCmps.map((iabCmp) => (
                              <MenuItem key={iabCmp.id} value={iabCmp.id}>{iabCmp.name} ({iabCmp.id})</MenuItem>
                            ))}
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                          <InputLabel id="gating">Gating</InputLabel>
                          <Select size="small" labelId="gating" id="gating" value={gatingFilter.toString()} label="gating" onChange={handleGatingFilterChange}>
                            {gatingTypeSelectorItems().map((gatingType) => (
                              <MenuItem key={gatingType.id} value={gatingType.id}>{gatingType.name}</MenuItem>
                            ))}
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField size="small" label="Search by Property Name" type="string" value={propertyNameFilter || ''} onChange={x=> handlePropertyNameFilterChange(x)}  onKeyPress={x=> handlePropertyNameFilterKeyChange(x)} fullWidth/>
                  </Grid>
                  <Grid item xs={4}>
                      <LoadingButton
                        size="small"
                        color="secondary"
                        onClick={handleRefresh}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<RefreshIcon />}
                        variant="contained"
                      >
                        Refresh
                      </LoadingButton>
                  </Grid>
                </Grid>
              }
              { props.showExportUi &&
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">
                      Found {scanJobs.length} scan jobs
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                      <Box display="flex" alignItems="right" justifyContent="right">
                          <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"ScanJob-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                              <TableArrowDownIcon sx={{color: "secondary"}}/>
                          </CSVLink>
                      </Box>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
            </Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Scan Jobs">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_PROPERTY_NAME)}>
                    <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_PROPERTY_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_SCAN_JOB_TYPE)}>
                    <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_SCAN_JOB_TYPE} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Scan Type</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_GATING)}>
                    <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_GATING} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Gating</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_REGION)}>
                    <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_REGION} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Region</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_STATUS)}>
                    <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_STATUS} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Status</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_CREATED_TIME)}>
                    <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_CREATED_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Created</TableSortLabel>
                  </StyledTableCell>
                    <StyledTableCell align="right" onClick={() => handleSortRequest(GetScanJobsRequest.SortField.SORT_FIELD_COMPLETION_TIME)}>
                        <TableSortLabel active={sortField === GetScanJobsRequest.SortField.SORT_FIELD_COMPLETION_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Completed</TableSortLabel>
                    </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {scanJobs.map((row) => (
                  <StyledTableRow hover key={row.getId()} onClick={() => navigateToScanJob(`${row.getId()}`)} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                    <StyledTableCell align="left">{row.getParameters()?.getPropertyName()}</StyledTableCell>
                    <StyledTableCell align="center">{enumName(ScanJob.ScanType, row.getScanType(), false, "SCAN_TYPE")}</StyledTableCell>
                    <StyledTableCell align="center">{formatGatingType(row.getGatingType())}</StyledTableCell>
                    <StyledTableCell align="right">{formatRegion(row.getParameters()?.getRegion())}</StyledTableCell>
                    <StyledTableCell align="right"><Box sx={{ color: getScanJobStatusColor(row.getStatus()) }}>{enumName(ScanJob.ScanJobStatus, row.getStatus(), false)}</Box></StyledTableCell>
                    <StyledTableCell align="right">{row.getAuditData()?.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                    <StyledTableCell align="right">{row.getCompletionTime()?.toDate().toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    );
};

export default ScanJobInfinityTable;