import React, {ReactElement, FC} from "react";
import {Box, IconButton, Table, TableBody, TableContainer, TableHead, TablePagination} from '@mui/material';
import Paper from '@mui/material/Paper';
import {Property, PropertySet} from '../generated/sp/scan_service/scan_service_pb';
import {enumName} from '../lib/enumUtils';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {useNavigate} from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";


interface PropertySetItemTableProps {
    propertySetItems: PropertySet.PropertySetItem[] | undefined;
    maxHeight: number;
}

const PropertySetItemTable: FC<PropertySetItemTableProps> = (props): ReactElement => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const navigate = useNavigate();
    const navigateToPropertySnapshot = (propertyName: String, propertySnapshotId: String) => {
        navigate(`/properties/${propertyName}/propertySnapshots/${propertySnapshotId}`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer style={{maxHeight: props.maxHeight + "px"}}>
                <Table stickyHeader size="small" aria-label="Scan Rules">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Property Name</StyledTableCell>
                            <StyledTableCell align="center">Property Type</StyledTableCell>
                            <StyledTableCell align="right">Actions</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {props.propertySetItems?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <StyledTableRow hover key={row.getPropertyName()}>
                                <StyledTableCell align="left">{row.getPropertyName()}</StyledTableCell>
                                <StyledTableCell align="center">{enumName(Property.PropertyType, row.getPropertyType(), false)}</StyledTableCell>
                                <StyledTableCell align="right">
                                    { row.hasPropertySnapshotId() &&
                                        <Box display="flex" alignItems="right" justifyContent="right">
                                            <IconButton aria-label="propertySnapshot" size="small" onClick={() => navigateToPropertySnapshot(`${row.getPropertyName()}`, `${row.getPropertySnapshotId()}`)}><LinkIcon/></IconButton>
                                        </Box>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.propertySetItems === undefined ? 0 : props.propertySetItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default PropertySetItemTable;