import React, {ReactElement, FC} from "react";
import {Box, Table, TableBody, TableContainer, TableHead, TablePagination} from '@mui/material';
import Paper from '@mui/material/Paper';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {UploadCsvRequest} from "../generated/sp/scan_service/scan_service_internal_pb";
import {formatRegion} from "../lib/regionUtils";

interface CsvLineTableProps {
    csvLines: UploadCsvRequest.CsvLine[] | undefined;
    maxHeight: number;
}

const CsvLineTable: FC<CsvLineTableProps> = (props): ReactElement => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const ruleResultSort = (rr1: UploadCsvRequest.CsvLine.RuleResult, rr2: UploadCsvRequest.CsvLine.RuleResult): number => {
        let rr1Numeric: number = parseInt(rr1.getRuleShortName()!.split('r').pop()!);
        let rr2Numeric: number = parseInt(rr2.getRuleShortName()!.split('r').pop()!);
        return rr1Numeric - rr2Numeric;
    }

    let headerNames: string[] | undefined;
    if (props.csvLines !== undefined && props.csvLines.length > 0) {
        // grab the first item to extract the headers
        let firstItem: UploadCsvRequest.CsvLine = props.csvLines[0];
        headerNames = firstItem.getRuleResultsList()
            .sort(ruleResultSort)
            .map(ruleResult => ruleResult.getRuleShortName());
    }

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer style={{maxHeight: props.maxHeight + "px"}}>
                <Table stickyHeader size="small" aria-label="Data">
                    {headerNames !== undefined &&
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell key="property">Property</StyledTableCell>
                                <StyledTableCell key="region">Region</StyledTableCell>
                                {headerNames.map((headerName) => (
                                    <StyledTableCell key={headerName}>{headerName}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                    }
                    {headerNames !== undefined && props.csvLines !== undefined &&
                        <TableBody>
                            {props
                                .csvLines.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((csvLine, idx) => {
                                    let ruleResultsLookup = new Map(csvLine.getRuleResultsList().map(rr => [rr.getRuleShortName(), rr]));
                                    return <StyledTableRow hover key={idx}>
                                        <StyledTableCell align="left">{csvLine.getProperty()}</StyledTableCell>
                                        <StyledTableCell
                                            align="right">{formatRegion(csvLine.getRegionId())}</StyledTableCell>
                                        {headerNames!.map((headerName) => {
                                            let ruleResult = ruleResultsLookup.get(headerName);
                                            if (ruleResult === undefined) {
                                                return <StyledTableCell key={headerName}><Box
                                                    sx={{color: 'lightgrey'}}>NA</Box></StyledTableCell>
                                            } else {
                                                return <StyledTableCell key={headerName}>{
                                                    ruleResult.getPassed() ?
                                                        <Box sx={{color: 'green'}}>P</Box> :
                                                        <Box sx={{color: 'red'}}>F</Box>}
                                                </StyledTableCell>
                                            }
                                        })}
                                    </StyledTableRow>
                                })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.csvLines === undefined ? 0 : props.csvLines.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default CsvLineTable;