import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    LinearProgress,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {
    GetLatestPropertySnapshotByNameAndRegionRequest,
    GetPropertySnapshotByIdRequest, Property,
    PropertySnapshot
} from '../generated/sp/scan_service/scan_service_pb';
import { useParams } from 'react-router-dom';
import { enumName } from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import ScanJobInfinityTable from "../components/ScanJobInfinityTable";
import RebuildPropertySnapshotButton from "../components/buttons/RebuildPropertySnapshotButton";
import MasterContainer from "../components/containers/MasterContainer";
import {formatRegion} from "../lib/regionUtils";
import RuleResultsTable from "../components/RuleResultsTable";
import {parse as uuidParse} from 'uuid';

const PropertySnapshotDetail: FC<any> = (): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [propertySnapshot, setPropertySnapshot] = useState<PropertySnapshot>();
    const { propertySnapshotId } = useParams();
    const { regionId } = useParams();

    // 👇️ get memoized callback
    const reloadPropertySnapshot = useCallback(async () => {
        console.log(`Loading data for propertySnapshotId=[${propertySnapshotId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const propertySnapShot = await getPropertySnapshot(scanService, propertySnapshotId!, regionId);
        // determine related scanJobIds
        let relatedScanJobIds = Array.from(new Set(propertySnapShot.getData()?.getRuleResultsList().map((ruleResult) => ruleResult.getRelevantScanJobIdsList()).flat()));
        console.log(`Found unique scan job ids: [${relatedScanJobIds}]`);
        console.log(`Finished reloading propertySnapshotId=${propertySnapshotId}`);
        setPropertySnapshot(propertySnapShot);
        setLoading(false);
    }, [propertySnapshotId, regionId]);

    useEffect(() => {
        (() => {
            reloadPropertySnapshot();
        })();
    }, [reloadPropertySnapshot]);

    const getPropertySnapshot = async (scanService: ScanServiceClient, propertySnapshotIdOrName: string, regionId: string | undefined): Promise<PropertySnapshot> => {
        try {
            uuidParse(propertySnapshotIdOrName);
            var getByIdRequest = new GetPropertySnapshotByIdRequest().setId(propertySnapshotIdOrName);
            return new Promise<PropertySnapshot>((resolve, reject) => {
                scanService.getPropertySnapshotById(getByIdRequest, generateAuthHeader(), (err, response) => {
                    if (err) reject(err);
                    else resolve(response.getSnapshot()!)
                });
            });
        } catch (error) {
            var request = new GetLatestPropertySnapshotByNameAndRegionRequest()
              .setPropertyName(propertySnapshotIdOrName);
            if (regionId) {
              request.setRegionId(regionId);
            }
            return new Promise<PropertySnapshot>((resolve, reject) => {
                scanService.getLatestPropertySnapshotByNameAndRegion(request, generateAuthHeader(), (err, response) => {
                    if (err) reject(err);
                    else resolve(response.getSnapshot()!)
                });
            });
        }
    };

    const getUniqueScanJobIds = (propertySnapshot: PropertySnapshot | undefined): Array<string> => {
        return Array.from(new Set(propertySnapshot?.getData()?.getRuleResultsList().map((ruleResult) => ruleResult.getRelevantScanJobIdsList()).flat()));
    }

    return (
        <MasterContainer>
           <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Property</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField size="small" label="ID" type="string" value={propertySnapshot?.getPropertyId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextField size="small" label="Snapshot ID" type="string" value={propertySnapshot?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <TextField size="small" label="Region" type="string" value={formatRegion(propertySnapshot?.getRegionId()) || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                  <TextField size="small" label="Name" type="string" value={propertySnapshot?.getPropertyName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Property Type" type="string" value={enumName(Property.PropertyType, propertySnapshot?.getPropertyType(), false)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        label="Is Latest?"
                        labelPlacement="start"
                        control={
                            <Checkbox
                                checked={propertySnapshot?.getIsLatest() || false}
                                inputProps={{ readOnly: true }}/>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Statistics</Typography>
                </Grid>
                <Grid item xs={3}>
                    <TextField size="small" label="Cache Time" type="string" value={propertySnapshot?.getCacheTime()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Last Scanned Date" type="string" value={propertySnapshot?.getData()?.getLastScannedDate()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Audit</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Created" type="string" value={propertySnapshot?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Updated" type="string" value={propertySnapshot?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Toolbar disableGutters={true}>
                      <RebuildPropertySnapshotButton propertySnapshotId={propertySnapshot?.getId()} rebuildCompleteFunction={() => reloadPropertySnapshot()}  />
                  </Toolbar>
                </Grid>
                <Grid item xs={12}>
                  {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Rule Results</Typography>
                </Grid>
                <Grid item xs={12}>
                    <RuleResultsTable ruleResults={propertySnapshot?.getData()?.getRuleResultsList()} maxHeight={250}/>
                </Grid>
                { propertySnapshot !== undefined &&
                  <React.Fragment>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Scan Jobs Sources</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        { getUniqueScanJobIds(propertySnapshot).length > 0 &&
                          <ScanJobInfinityTable maxHeight={400} embeddedMode={true} showFilterUi={false} showExportUi={true} scanJobIdsFilter={getUniqueScanJobIds(propertySnapshot)}/>
                        }
                    </Grid>
                  </React.Fragment>
                }
            </Grid>
            </Box>
        </MasterContainer>
    );
};

export default PropertySnapshotDetail;