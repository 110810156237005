// source: sp/scan_runner/scan_runner.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var sp_scan_runner_common_pb = require('../../sp/scan_runner/common_pb.js');
goog.object.extend(proto, sp_scan_runner_common_pb);
goog.exportSymbol('proto.sp.scan_runner.ArtifactName', null, global);
goog.exportSymbol('proto.sp.scan_runner.CompressedHeader', null, global);
goog.exportSymbol('proto.sp.scan_runner.DiagnoseEventsArtifact', null, global);
goog.exportSymbol('proto.sp.scan_runner.DiagnoseMetadata', null, global);
goog.exportSymbol('proto.sp.scan_runner.DomainData', null, global);
goog.exportSymbol('proto.sp.scan_runner.HttpRequestData', null, global);
goog.exportSymbol('proto.sp.scan_runner.HttpResponseData', null, global);
goog.exportSymbol('proto.sp.scan_runner.HttpTransaction', null, global);
goog.exportSymbol('proto.sp.scan_runner.JsCookie', null, global);
goog.exportSymbol('proto.sp.scan_runner.LogLine', null, global);
goog.exportSymbol('proto.sp.scan_runner.ProxyType', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.ArtifactMetadata', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.MetadataTypeCase', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.ArtifactType', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.ImageMetadata', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.ImageRegionData', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.TextArtifact', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanArtifact.TextMetadata', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanError', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanError.ErrorType', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanRuleReport', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanRuleReports', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanRunnerResponse', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanRunnerResponse.ResultTypeCase', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanRunnerStartRequest', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.ArtifactEvent', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.Event', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.Event.EventTypeCase', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.HttpMethod', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.LogEvent', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.LogEvent.Severity', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.NetworkEvent', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.NetworkEventType', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.PageMetadata', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScanTimeline.ScanPhase', null, global);
goog.exportSymbol('proto.sp.scan_runner.ScannerInternalState', null, global);
goog.exportSymbol('proto.sp.scan_runner.StartChromeRequest', null, global);
goog.exportSymbol('proto.sp.scan_runner.StartChromeResponse', null, global);
goog.exportSymbol('proto.sp.scan_runner.Website', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanError.displayName = 'proto.sp.scan_runner.ScanError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanRuleReports = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.ScanRuleReports.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.ScanRuleReports, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanRuleReports.displayName = 'proto.sp.scan_runner.ScanRuleReports';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanRuleReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanRuleReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanRuleReport.displayName = 'proto.sp.scan_runner.ScanRuleReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.Website = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.Website, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.Website.displayName = 'proto.sp.scan_runner.Website';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.DomainData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.DomainData.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.DomainData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.DomainData.displayName = 'proto.sp.scan_runner.DomainData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanRunnerStartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanRunnerStartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanRunnerStartRequest.displayName = 'proto.sp.scan_runner.ScanRunnerStartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanRunnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.scan_runner.ScanRunnerResponse.oneofGroups_);
};
goog.inherits(proto.sp.scan_runner.ScanRunnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanRunnerResponse.displayName = 'proto.sp.scan_runner.ScanRunnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.LogLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.LogLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.LogLine.displayName = 'proto.sp.scan_runner.LogLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.ScanTimeline.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.displayName = 'proto.sp.scan_runner.ScanTimeline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline.LogEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline.LogEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.LogEvent.displayName = 'proto.sp.scan_runner.ScanTimeline.LogEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline.ArtifactEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.ArtifactEvent.displayName = 'proto.sp.scan_runner.ScanTimeline.ArtifactEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline.NetworkEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.NetworkEvent.displayName = 'proto.sp.scan_runner.ScanTimeline.NetworkEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.scan_runner.ScanTimeline.Event.oneofGroups_);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.Event.displayName = 'proto.sp.scan_runner.ScanTimeline.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.ScanTimeline.ScanPhase.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline.ScanPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.ScanPhase.displayName = 'proto.sp.scan_runner.ScanTimeline.ScanPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanTimeline.PageMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanTimeline.PageMetadata.displayName = 'proto.sp.scan_runner.ScanTimeline.PageMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanArtifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanArtifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanArtifact.displayName = 'proto.sp.scan_runner.ScanArtifact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanArtifact.ImageRegionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanArtifact.ImageRegionData.displayName = 'proto.sp.scan_runner.ScanArtifact.ImageRegionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.ScanArtifact.ImageMetadata.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.ScanArtifact.ImageMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanArtifact.ImageMetadata.displayName = 'proto.sp.scan_runner.ScanArtifact.ImageMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanArtifact.TextMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanArtifact.TextMetadata.displayName = 'proto.sp.scan_runner.ScanArtifact.TextMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.ScanArtifact.TextArtifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanArtifact.TextArtifact.displayName = 'proto.sp.scan_runner.ScanArtifact.TextArtifact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.oneofGroups_);
};
goog.inherits(proto.sp.scan_runner.ScanArtifact.ArtifactMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.displayName = 'proto.sp.scan_runner.ScanArtifact.ArtifactMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.CompressedHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.CompressedHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.CompressedHeader.displayName = 'proto.sp.scan_runner.CompressedHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.HttpRequestData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.HttpRequestData.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.HttpRequestData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.HttpRequestData.displayName = 'proto.sp.scan_runner.HttpRequestData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.HttpResponseData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.HttpResponseData.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.HttpResponseData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.HttpResponseData.displayName = 'proto.sp.scan_runner.HttpResponseData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.HttpTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.HttpTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.HttpTransaction.displayName = 'proto.sp.scan_runner.HttpTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.JsCookie = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.JsCookie, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.JsCookie.displayName = 'proto.sp.scan_runner.JsCookie';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.DiagnoseMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.DiagnoseMetadata.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.DiagnoseMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.DiagnoseMetadata.displayName = 'proto.sp.scan_runner.DiagnoseMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.DiagnoseEventsArtifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.DiagnoseEventsArtifact.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.DiagnoseEventsArtifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.DiagnoseEventsArtifact.displayName = 'proto.sp.scan_runner.DiagnoseEventsArtifact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.ScannerInternalState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_runner.ScannerInternalState.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_runner.ScannerInternalState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.ScannerInternalState.displayName = 'proto.sp.scan_runner.ScannerInternalState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.StartChromeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.StartChromeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.StartChromeRequest.displayName = 'proto.sp.scan_runner.StartChromeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_runner.StartChromeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_runner.StartChromeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_runner.StartChromeResponse.displayName = 'proto.sp.scan_runner.StartChromeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanError.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanError.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanError}
 */
proto.sp.scan_runner.ScanError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanError;
  return proto.sp.scan_runner.ScanError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanError}
 */
proto.sp.scan_runner.ScanError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.ScanError.ErrorType} */ (reader.readEnum());
      msg.setErrorType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanError.ErrorType = {
  ERROR_TYPE_UNSET: 0,
  ERROR_TYPE_DETECTED_AS_BOT: 1,
  ERROR_TYPE_WEBSITE_UNAVAILABLE_IN_REGION: 2,
  ERROR_TYPE_UNKNOWN: 3,
  ERROR_TYPE_NO_DNS_ENTRY: 4
};

/**
 * optional ErrorType error_type = 1;
 * @return {!proto.sp.scan_runner.ScanError.ErrorType}
 */
proto.sp.scan_runner.ScanError.prototype.getErrorType = function() {
  return /** @type {!proto.sp.scan_runner.ScanError.ErrorType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanError.ErrorType} value
 * @return {!proto.sp.scan_runner.ScanError} returns this
 */
proto.sp.scan_runner.ScanError.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanError.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanError} returns this
 */
proto.sp.scan_runner.ScanError.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.ScanRuleReports.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanRuleReports.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanRuleReports.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanRuleReports} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRuleReports.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleReportsList: jspb.Message.toObjectList(msg.getScanRuleReportsList(),
    proto.sp.scan_runner.ScanRuleReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanRuleReports}
 */
proto.sp.scan_runner.ScanRuleReports.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanRuleReports;
  return proto.sp.scan_runner.ScanRuleReports.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanRuleReports} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanRuleReports}
 */
proto.sp.scan_runner.ScanRuleReports.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.ScanRuleReport;
      reader.readMessage(value,proto.sp.scan_runner.ScanRuleReport.deserializeBinaryFromReader);
      msg.addScanRuleReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanRuleReports.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanRuleReports.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanRuleReports} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRuleReports.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_runner.ScanRuleReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ScanRuleReport scan_rule_reports = 1;
 * @return {!Array<!proto.sp.scan_runner.ScanRuleReport>}
 */
proto.sp.scan_runner.ScanRuleReports.prototype.getScanRuleReportsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ScanRuleReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.ScanRuleReport, 1));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanRuleReport>} value
 * @return {!proto.sp.scan_runner.ScanRuleReports} returns this
*/
proto.sp.scan_runner.ScanRuleReports.prototype.setScanRuleReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_runner.ScanRuleReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanRuleReport}
 */
proto.sp.scan_runner.ScanRuleReports.prototype.addScanRuleReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_runner.ScanRuleReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.ScanRuleReports} returns this
 */
proto.sp.scan_runner.ScanRuleReports.prototype.clearScanRuleReportsList = function() {
  return this.setScanRuleReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanRuleReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanRuleReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRuleReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    originalStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanRuleReport}
 */
proto.sp.scan_runner.ScanRuleReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanRuleReport;
  return proto.sp.scan_runner.ScanRuleReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanRuleReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanRuleReport}
 */
proto.sp.scan_runner.ScanRuleReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleShortName(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus} */ (reader.readEnum());
      msg.setOriginalStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanRuleReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanRuleReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRuleReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOriginalStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus = {
  SCAN_RULE_STATUS_UNSET: 0,
  SCAN_RULE_STATUS_PASS: 1,
  SCAN_RULE_STATUS_FAIL: 2
};

/**
 * optional string scan_rule_short_name = 1;
 * @return {string}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.getScanRuleShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanRuleReport} returns this
 */
proto.sp.scan_runner.ScanRuleReport.prototype.setScanRuleShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ScanRuleStatus status = 3;
 * @return {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus} value
 * @return {!proto.sp.scan_runner.ScanRuleReport} returns this
 */
proto.sp.scan_runner.ScanRuleReport.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ScanRuleStatus original_status = 4;
 * @return {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.getOriginalStatus = function() {
  return /** @type {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanRuleReport.ScanRuleStatus} value
 * @return {!proto.sp.scan_runner.ScanRuleReport} returns this
 */
proto.sp.scan_runner.ScanRuleReport.prototype.setOriginalStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanRuleReport} returns this
 */
proto.sp.scan_runner.ScanRuleReport.prototype.setComment = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRuleReport} returns this
 */
proto.sp.scan_runner.ScanRuleReport.prototype.clearComment = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRuleReport.prototype.hasComment = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.Website.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.Website.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.Website} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.Website.toObject = function(includeInstance, msg) {
  var f, obj = {
    websiteUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.Website}
 */
proto.sp.scan_runner.Website.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.Website;
  return proto.sp.scan_runner.Website.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.Website} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.Website}
 */
proto.sp.scan_runner.Website.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.Website.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.Website.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.Website} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.Website.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWebsiteUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string website_uri = 1;
 * @return {string}
 */
proto.sp.scan_runner.Website.prototype.getWebsiteUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.Website} returns this
 */
proto.sp.scan_runner.Website.prototype.setWebsiteUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.DomainData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.DomainData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.DomainData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.DomainData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.DomainData.toObject = function(includeInstance, msg) {
  var f, obj = {
    previousElementsList: jspb.Message.toObjectList(msg.getPreviousElementsList(),
    sp_scan_runner_common_pb.ElementLocation.toObject, includeInstance),
    previousCmpId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    previousCmpTemplateId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.DomainData}
 */
proto.sp.scan_runner.DomainData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.DomainData;
  return proto.sp.scan_runner.DomainData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.DomainData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.DomainData}
 */
proto.sp.scan_runner.DomainData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_runner_common_pb.ElementLocation;
      reader.readMessage(value,sp_scan_runner_common_pb.ElementLocation.deserializeBinaryFromReader);
      msg.addPreviousElements(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreviousCmpId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousCmpTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.DomainData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.DomainData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.DomainData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.DomainData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreviousElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sp_scan_runner_common_pb.ElementLocation.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated ElementLocation previous_elements = 1;
 * @return {!Array<!proto.sp.scan_runner.ElementLocation>}
 */
proto.sp.scan_runner.DomainData.prototype.getPreviousElementsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ElementLocation>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_scan_runner_common_pb.ElementLocation, 1));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ElementLocation>} value
 * @return {!proto.sp.scan_runner.DomainData} returns this
*/
proto.sp.scan_runner.DomainData.prototype.setPreviousElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_runner.ElementLocation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ElementLocation}
 */
proto.sp.scan_runner.DomainData.prototype.addPreviousElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_runner.ElementLocation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.DomainData} returns this
 */
proto.sp.scan_runner.DomainData.prototype.clearPreviousElementsList = function() {
  return this.setPreviousElementsList([]);
};


/**
 * optional int32 previous_cmp_id = 2;
 * @return {number}
 */
proto.sp.scan_runner.DomainData.prototype.getPreviousCmpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.DomainData} returns this
 */
proto.sp.scan_runner.DomainData.prototype.setPreviousCmpId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.DomainData} returns this
 */
proto.sp.scan_runner.DomainData.prototype.clearPreviousCmpId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DomainData.prototype.hasPreviousCmpId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string previous_cmp_template_id = 3;
 * @return {string}
 */
proto.sp.scan_runner.DomainData.prototype.getPreviousCmpTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.DomainData} returns this
 */
proto.sp.scan_runner.DomainData.prototype.setPreviousCmpTemplateId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.DomainData} returns this
 */
proto.sp.scan_runner.DomainData.prototype.clearPreviousCmpTemplateId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DomainData.prototype.hasPreviousCmpTemplateId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanRunnerStartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanRunnerStartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRunnerStartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    website: (f = msg.getWebsite()) && proto.sp.scan_runner.Website.toObject(includeInstance, f),
    displayOption: jspb.Message.getFieldWithDefault(msg, 6, 0),
    domainData: (f = msg.getDomainData()) && proto.sp.scan_runner.DomainData.toObject(includeInstance, f),
    proxyType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    script: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanRunnerStartRequest;
  return proto.sp.scan_runner.ScanRunnerStartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanRunnerStartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 3:
      var value = new proto.sp.scan_runner.Website;
      reader.readMessage(value,proto.sp.scan_runner.Website.deserializeBinaryFromReader);
      msg.setWebsite(value);
      break;
    case 6:
      var value = /** @type {!proto.sp.scan_runner.DisplayOption} */ (reader.readEnum());
      msg.setDisplayOption(value);
      break;
    case 7:
      var value = new proto.sp.scan_runner.DomainData;
      reader.readMessage(value,proto.sp.scan_runner.DomainData.deserializeBinaryFromReader);
      msg.setDomainData(value);
      break;
    case 8:
      var value = /** @type {!proto.sp.scan_runner.ProxyType} */ (reader.readEnum());
      msg.setProxyType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setScript(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanRunnerStartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanRunnerStartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRunnerStartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWebsite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.scan_runner.Website.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOption();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDomainData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.sp.scan_runner.DomainData.serializeBinaryToWriter
    );
  }
  f = message.getProxyType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Website Website = 3;
 * @return {?proto.sp.scan_runner.Website}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.getWebsite = function() {
  return /** @type{?proto.sp.scan_runner.Website} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.Website, 3));
};


/**
 * @param {?proto.sp.scan_runner.Website|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
*/
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.setWebsite = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.clearWebsite = function() {
  return this.setWebsite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DisplayOption display_option = 6;
 * @return {!proto.sp.scan_runner.DisplayOption}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.getDisplayOption = function() {
  return /** @type {!proto.sp.scan_runner.DisplayOption} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.sp.scan_runner.DisplayOption} value
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.setDisplayOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional DomainData domain_data = 7;
 * @return {?proto.sp.scan_runner.DomainData}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.getDomainData = function() {
  return /** @type{?proto.sp.scan_runner.DomainData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.DomainData, 7));
};


/**
 * @param {?proto.sp.scan_runner.DomainData|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
*/
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.setDomainData = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.clearDomainData = function() {
  return this.setDomainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.hasDomainData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ProxyType proxy_type = 8;
 * @return {!proto.sp.scan_runner.ProxyType}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.getProxyType = function() {
  return /** @type {!proto.sp.scan_runner.ProxyType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.sp.scan_runner.ProxyType} value
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.setProxyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string script = 9;
 * @return {string}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.getScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.setScript = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerStartRequest} returns this
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.clearScript = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerStartRequest.prototype.hasScript = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.scan_runner.ScanRunnerResponse.oneofGroups_ = [[7,8]];

/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanRunnerResponse.ResultTypeCase = {
  RESULT_TYPE_NOT_SET: 0,
  ERROR: 7,
  REPORTS: 8
};

/**
 * @return {proto.sp.scan_runner.ScanRunnerResponse.ResultTypeCase}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.getResultTypeCase = function() {
  return /** @type {proto.sp.scan_runner.ScanRunnerResponse.ResultTypeCase} */(jspb.Message.computeOneofCase(this, proto.sp.scan_runner.ScanRunnerResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanRunnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanRunnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRunnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    stopTime: (f = msg.getStopTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timeline: (f = msg.getTimeline()) && proto.sp.scan_runner.ScanTimeline.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.sp.scan_runner.ScanError.toObject(includeInstance, f),
    reports: (f = msg.getReports()) && proto.sp.scan_runner.ScanRuleReports.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse}
 */
proto.sp.scan_runner.ScanRunnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanRunnerResponse;
  return proto.sp.scan_runner.ScanRunnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanRunnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse}
 */
proto.sp.scan_runner.ScanRunnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStopTime(value);
      break;
    case 6:
      var value = new proto.sp.scan_runner.ScanTimeline;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.deserializeBinaryFromReader);
      msg.setTimeline(value);
      break;
    case 7:
      var value = new proto.sp.scan_runner.ScanError;
      reader.readMessage(value,proto.sp.scan_runner.ScanError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 8:
      var value = new proto.sp.scan_runner.ScanRuleReports;
      reader.readMessage(value,proto.sp.scan_runner.ScanRuleReports.deserializeBinaryFromReader);
      msg.setReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanRunnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanRunnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanRunnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStopTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeline();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sp.scan_runner.ScanTimeline.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.sp.scan_runner.ScanError.serializeBinaryToWriter
    );
  }
  f = message.getReports();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.sp.scan_runner.ScanRuleReports.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
*/
proto.sp.scan_runner.ScanRunnerResponse.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp stop_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.getStopTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
*/
proto.sp.scan_runner.ScanRunnerResponse.prototype.setStopTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.clearStopTime = function() {
  return this.setStopTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.hasStopTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ScanTimeline timeline = 6;
 * @return {?proto.sp.scan_runner.ScanTimeline}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.getTimeline = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanTimeline, 6));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
*/
proto.sp.scan_runner.ScanRunnerResponse.prototype.setTimeline = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.clearTimeline = function() {
  return this.setTimeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.hasTimeline = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ScanError error = 7;
 * @return {?proto.sp.scan_runner.ScanError}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.getError = function() {
  return /** @type{?proto.sp.scan_runner.ScanError} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanError, 7));
};


/**
 * @param {?proto.sp.scan_runner.ScanError|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
*/
proto.sp.scan_runner.ScanRunnerResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.sp.scan_runner.ScanRunnerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ScanRuleReports reports = 8;
 * @return {?proto.sp.scan_runner.ScanRuleReports}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.getReports = function() {
  return /** @type{?proto.sp.scan_runner.ScanRuleReports} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanRuleReports, 8));
};


/**
 * @param {?proto.sp.scan_runner.ScanRuleReports|undefined} value
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
*/
proto.sp.scan_runner.ScanRunnerResponse.prototype.setReports = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.sp.scan_runner.ScanRunnerResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanRunnerResponse} returns this
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.clearReports = function() {
  return this.setReports(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanRunnerResponse.prototype.hasReports = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.LogLine.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.LogLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.LogLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LogLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    ts: (f = msg.getTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    line: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.LogLine}
 */
proto.sp.scan_runner.LogLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.LogLine;
  return proto.sp.scan_runner.LogLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.LogLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.LogLine}
 */
proto.sp.scan_runner.LogLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.LogLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.LogLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.LogLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.LogLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp ts = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_runner.LogLine.prototype.getTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_runner.LogLine} returns this
*/
proto.sp.scan_runner.LogLine.prototype.setTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.LogLine} returns this
 */
proto.sp.scan_runner.LogLine.prototype.clearTs = function() {
  return this.setTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.LogLine.prototype.hasTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string line = 2;
 * @return {string}
 */
proto.sp.scan_runner.LogLine.prototype.getLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.LogLine} returns this
 */
proto.sp.scan_runner.LogLine.prototype.setLine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.ScanTimeline.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.toObject = function(includeInstance, msg) {
  var f, obj = {
    phaseMapMap: (f = msg.getPhaseMapMap()) ? f.toObject(includeInstance, proto.sp.scan_runner.ScanTimeline.ScanPhase.toObject) : [],
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.sp.scan_runner.ScanTimeline.Event.toObject, includeInstance),
    pageMapMap: (f = msg.getPageMapMap()) ? f.toObject(includeInstance, proto.sp.scan_runner.ScanTimeline.PageMetadata.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline}
 */
proto.sp.scan_runner.ScanTimeline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline;
  return proto.sp.scan_runner.ScanTimeline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline}
 */
proto.sp.scan_runner.ScanTimeline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPhaseMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.sp.scan_runner.ScanTimeline.ScanPhase.deserializeBinaryFromReader, 0, new proto.sp.scan_runner.ScanTimeline.ScanPhase());
         });
      break;
    case 2:
      var value = new proto.sp.scan_runner.ScanTimeline.Event;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 3:
      var value = msg.getPageMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.sp.scan_runner.ScanTimeline.PageMetadata.deserializeBinaryFromReader, 0, new proto.sp.scan_runner.ScanTimeline.PageMetadata());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhaseMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.sp.scan_runner.ScanTimeline.ScanPhase.serializeBinaryToWriter);
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_runner.ScanTimeline.Event.serializeBinaryToWriter
    );
  }
  f = message.getPageMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.sp.scan_runner.ScanTimeline.PageMetadata.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanTimeline.HttpMethod = {
  HTTP_METHOD_UNSET: 0,
  HTTP_METHOD_GET: 1,
  HTTP_METHOD_POST: 2,
  HTTP_METHOD_PUT: 3,
  HTTP_METHOD_DELETE: 4,
  HTTP_METHOD_PATCH: 5,
  HTTP_METHOD_OPTIONS: 6,
  HTTP_METHOD_HEAD: 7,
  HTTP_METHOD_TRACE: 8,
  HTTP_METHOD_CONNECT: 9,
  HTTP_METHOD_OTHER: 10
};

/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEventType = {
  NETWORK_EVENT_TYPE_UNSET: 0,
  NETWORK_EVENT_TYPE_REQUEST: 1,
  NETWORK_EVENT_TYPE_RESPONSE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.LogEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline.LogEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    severity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline.LogEvent}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline.LogEvent;
  return proto.sp.scan_runner.ScanTimeline.LogEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline.LogEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline.LogEvent}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.LogEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline.LogEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.Severity = {
  SEVERITY_UNSET: 0,
  SEVERITY_DEBUG: 1,
  SEVERITY_INFO: 2,
  SEVERITY_WARN: 3,
  SEVERITY_ERROR: 4
};

/**
 * optional Severity severity = 1;
 * @return {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.prototype.getSeverity = function() {
  return /** @type {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} value
 * @return {!proto.sp.scan_runner.ScanTimeline.LogEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanTimeline.LogEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.LogEvent.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.ArtifactEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    artifactType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    artifactId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageOffset: jspb.Message.getFieldWithDefault(msg, 4, 0),
    groupOffset: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline.ArtifactEvent;
  return proto.sp.scan_runner.ScanTimeline.ArtifactEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (reader.readEnum());
      msg.setArtifactType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArtifactId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageOffset(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.ArtifactEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArtifactType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getArtifactId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageOffset();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGroupOffset();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional ScanArtifact.ArtifactType artifact_type = 1;
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactType}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.getArtifactType = function() {
  return /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactType} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.setArtifactType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string artifact_id = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.getArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.setArtifactId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 page_offset = 4;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.getPageOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.setPageOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 group_offset = 5;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.getGroupOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ArtifactEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.ArtifactEvent.prototype.setGroupOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.NetworkEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payloadSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    duration: (f = msg.getDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    method: jspb.Message.getFieldWithDefault(msg, 5, 0),
    responseSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    reqNum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline.NetworkEvent;
  return proto.sp.scan_runner.ScanTimeline.NetworkEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPayloadSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatusCode(value);
      break;
    case 4:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 5:
      var value = /** @type {!proto.sp.scan_runner.ScanTimeline.HttpMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResponseSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReqNum(value);
      break;
    case 8:
      var value = /** @type {!proto.sp.scan_runner.ScanTimeline.NetworkEventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.NetworkEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayloadSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatusCode();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getResponseSize();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getReqNum();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 payload_size = 2;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getPayloadSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setPayloadSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 status_code = 3;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getStatusCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Duration duration = 4;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getDuration = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 4));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
*/
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HttpMethod method = 5;
 * @return {!proto.sp.scan_runner.ScanTimeline.HttpMethod}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getMethod = function() {
  return /** @type {!proto.sp.scan_runner.ScanTimeline.HttpMethod} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanTimeline.HttpMethod} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint32 response_size = 6;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getResponseSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setResponseSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 req_num = 7;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getReqNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setReqNum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional NetworkEventType event_type = 8;
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEventType}
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.getEventType = function() {
  return /** @type {!proto.sp.scan_runner.ScanTimeline.NetworkEventType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanTimeline.NetworkEventType} value
 * @return {!proto.sp.scan_runner.ScanTimeline.NetworkEvent} returns this
 */
proto.sp.scan_runner.ScanTimeline.NetworkEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.scan_runner.ScanTimeline.Event.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanTimeline.Event.EventTypeCase = {
  EVENT_TYPE_NOT_SET: 0,
  LOG: 3,
  ARTIFACT: 4,
  NETWORK: 5
};

/**
 * @return {proto.sp.scan_runner.ScanTimeline.Event.EventTypeCase}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.getEventTypeCase = function() {
  return /** @type {proto.sp.scan_runner.ScanTimeline.Event.EventTypeCase} */(jspb.Message.computeOneofCase(this, proto.sp.scan_runner.ScanTimeline.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    ts: (f = msg.getTs()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    log: (f = msg.getLog()) && proto.sp.scan_runner.ScanTimeline.LogEvent.toObject(includeInstance, f),
    artifact: (f = msg.getArtifact()) && proto.sp.scan_runner.ScanTimeline.ArtifactEvent.toObject(includeInstance, f),
    network: (f = msg.getNetwork()) && proto.sp.scan_runner.ScanTimeline.NetworkEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline.Event}
 */
proto.sp.scan_runner.ScanTimeline.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline.Event;
  return proto.sp.scan_runner.ScanTimeline.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline.Event}
 */
proto.sp.scan_runner.ScanTimeline.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhase(value);
      break;
    case 3:
      var value = new proto.sp.scan_runner.ScanTimeline.LogEvent;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.LogEvent.deserializeBinaryFromReader);
      msg.setLog(value);
      break;
    case 4:
      var value = new proto.sp.scan_runner.ScanTimeline.ArtifactEvent;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.ArtifactEvent.deserializeBinaryFromReader);
      msg.setArtifact(value);
      break;
    case 5:
      var value = new proto.sp.scan_runner.ScanTimeline.NetworkEvent;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.NetworkEvent.deserializeBinaryFromReader);
      msg.setNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhase();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLog();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.scan_runner.ScanTimeline.LogEvent.serializeBinaryToWriter
    );
  }
  f = message.getArtifact();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sp.scan_runner.ScanTimeline.ArtifactEvent.serializeBinaryToWriter
    );
  }
  f = message.getNetwork();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sp.scan_runner.ScanTimeline.NetworkEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp ts = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.getTs = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
*/
proto.sp.scan_runner.ScanTimeline.Event.prototype.setTs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.clearTs = function() {
  return this.setTs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.hasTs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 phase = 2;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.getPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.setPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional LogEvent log = 3;
 * @return {?proto.sp.scan_runner.ScanTimeline.LogEvent}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.getLog = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline.LogEvent} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanTimeline.LogEvent, 3));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline.LogEvent|undefined} value
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
*/
proto.sp.scan_runner.ScanTimeline.Event.prototype.setLog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.sp.scan_runner.ScanTimeline.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.clearLog = function() {
  return this.setLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.hasLog = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ArtifactEvent artifact = 4;
 * @return {?proto.sp.scan_runner.ScanTimeline.ArtifactEvent}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.getArtifact = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline.ArtifactEvent} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanTimeline.ArtifactEvent, 4));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline.ArtifactEvent|undefined} value
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
*/
proto.sp.scan_runner.ScanTimeline.Event.prototype.setArtifact = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.sp.scan_runner.ScanTimeline.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.clearArtifact = function() {
  return this.setArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.hasArtifact = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NetworkEvent network = 5;
 * @return {?proto.sp.scan_runner.ScanTimeline.NetworkEvent}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.getNetwork = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline.NetworkEvent} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanTimeline.NetworkEvent, 5));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline.NetworkEvent|undefined} value
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
*/
proto.sp.scan_runner.ScanTimeline.Event.prototype.setNetwork = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.sp.scan_runner.ScanTimeline.Event.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanTimeline.Event} returns this
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.clearNetwork = function() {
  return this.setNetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanTimeline.Event.prototype.hasNetwork = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.ScanPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline.ScanPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanRuleShortNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline.ScanPhase}
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline.ScanPhase;
  return proto.sp.scan_runner.ScanTimeline.ScanPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline.ScanPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline.ScanPhase}
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanRuleShortNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.ScanPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline.ScanPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanRuleShortNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ScanPhase} returns this
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string scan_rule_short_names = 2;
 * @return {!Array<string>}
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.getScanRuleShortNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_runner.ScanTimeline.ScanPhase} returns this
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.setScanRuleShortNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanTimeline.ScanPhase} returns this
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.addScanRuleShortNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.ScanTimeline.ScanPhase} returns this
 */
proto.sp.scan_runner.ScanTimeline.ScanPhase.prototype.clearScanRuleShortNamesList = function() {
  return this.setScanRuleShortNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanTimeline.PageMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanTimeline.PageMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanTimeline.PageMetadata}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanTimeline.PageMetadata;
  return proto.sp.scan_runner.ScanTimeline.PageMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanTimeline.PageMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanTimeline.PageMetadata}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.PageSearchResult.PageType} */ (reader.readEnum());
      msg.setPageType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanTimeline.PageMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanTimeline.PageMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional PageSearchResult.PageType page_type = 1;
 * @return {!proto.sp.scan_runner.PageSearchResult.PageType}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.getPageType = function() {
  return /** @type {!proto.sp.scan_runner.PageSearchResult.PageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.PageSearchResult.PageType} value
 * @return {!proto.sp.scan_runner.ScanTimeline.PageMetadata} returns this
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.setPageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string page_url = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.getPageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanTimeline.PageMetadata} returns this
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.setPageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 offset = 3;
 * @return {number}
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanTimeline.PageMetadata} returns this
 */
proto.sp.scan_runner.ScanTimeline.PageMetadata.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<uint32, ScanPhase> phase_map = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.sp.scan_runner.ScanTimeline.ScanPhase>}
 */
proto.sp.scan_runner.ScanTimeline.prototype.getPhaseMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.sp.scan_runner.ScanTimeline.ScanPhase>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.sp.scan_runner.ScanTimeline.ScanPhase));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sp.scan_runner.ScanTimeline} returns this
 */
proto.sp.scan_runner.ScanTimeline.prototype.clearPhaseMapMap = function() {
  this.getPhaseMapMap().clear();
  return this;};


/**
 * repeated Event events = 2;
 * @return {!Array<!proto.sp.scan_runner.ScanTimeline.Event>}
 */
proto.sp.scan_runner.ScanTimeline.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ScanTimeline.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.ScanTimeline.Event, 2));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanTimeline.Event>} value
 * @return {!proto.sp.scan_runner.ScanTimeline} returns this
*/
proto.sp.scan_runner.ScanTimeline.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_runner.ScanTimeline.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanTimeline.Event}
 */
proto.sp.scan_runner.ScanTimeline.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_runner.ScanTimeline.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.ScanTimeline} returns this
 */
proto.sp.scan_runner.ScanTimeline.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * map<uint32, PageMetadata> page_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.sp.scan_runner.ScanTimeline.PageMetadata>}
 */
proto.sp.scan_runner.ScanTimeline.prototype.getPageMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.sp.scan_runner.ScanTimeline.PageMetadata>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.sp.scan_runner.ScanTimeline.PageMetadata));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sp.scan_runner.ScanTimeline} returns this
 */
proto.sp.scan_runner.ScanTimeline.prototype.clearPageMapMap = function() {
  this.getPageMapMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanArtifact.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanArtifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanArtifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.toObject = function(includeInstance, msg) {
  var f, obj = {
    artifactType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    artifactId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: msg.getContent_asB64(),
    mimeType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    artifactMetadata: (f = msg.getArtifactMetadata()) && proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.toObject(includeInstance, f),
    pageOffset: jspb.Message.getFieldWithDefault(msg, 7, 0),
    groupOffset: jspb.Message.getFieldWithDefault(msg, 8, 0),
    artifactName: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_runner.ScanArtifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanArtifact;
  return proto.sp.scan_runner.ScanArtifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanArtifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_runner.ScanArtifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (reader.readEnum());
      msg.setArtifactType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArtifactId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    case 6:
      var value = new proto.sp.scan_runner.ScanArtifact.ArtifactMetadata;
      reader.readMessage(value,proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.deserializeBinaryFromReader);
      msg.setArtifactMetadata(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageOffset(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupOffset(value);
      break;
    case 9:
      var value = /** @type {!proto.sp.scan_runner.ArtifactName} */ (reader.readEnum());
      msg.setArtifactName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanArtifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanArtifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArtifactType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getArtifactId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArtifactMetadata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.serializeBinaryToWriter
    );
  }
  f = message.getPageOffset();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getGroupOffset();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getArtifactName();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactType = {
  ARTIFACT_TYPE_UNSET: 0,
  ARTIFACT_TYPE_IMAGE: 1,
  ARTIFACT_TYPE_TEXT: 2,
  ARTIFACT_TYPE_LOCAL_STORE_DUMP: 3,
  ARTIFACT_TYPE_PAGE_SEARCH_RESULT: 4,
  ARTIFACT_TYPE_CONSENT_API_DATA: 5,
  ARTIFACT_TYPE_DOM_SNAPSHOT: 6,
  ARTIFACT_TYPE_DIAGNOSE_EVENTS: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanArtifact.ImageRegionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    xpath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    boundingBox: (f = msg.getBoundingBox()) && sp_scan_runner_common_pb.BoundingBox.toObject(includeInstance, f),
    source: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanArtifact.ImageRegionData;
  return proto.sp.scan_runner.ScanArtifact.ImageRegionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setXpath(value);
      break;
    case 3:
      var value = new sp_scan_runner_common_pb.BoundingBox;
      reader.readMessage(value,sp_scan_runner_common_pb.BoundingBox.deserializeBinaryFromReader);
      msg.setBoundingBox(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanArtifact.ImageRegionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBoundingBox();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_scan_runner_common_pb.BoundingBox.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string xpath = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.getXpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.setXpath = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.clearXpath = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.hasXpath = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BoundingBox bounding_box = 3;
 * @return {?proto.sp.scan_runner.BoundingBox}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.getBoundingBox = function() {
  return /** @type{?proto.sp.scan_runner.BoundingBox} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_common_pb.BoundingBox, 3));
};


/**
 * @param {?proto.sp.scan_runner.BoundingBox|undefined} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
*/
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.setBoundingBox = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.clearBoundingBox = function() {
  return this.setBoundingBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.hasBoundingBox = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string source = 4;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.setSource = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.clearSource = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.hasSource = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.setText = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.clearText = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.ImageRegionData.prototype.hasText = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanArtifact.ImageMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0),
    regionsList: jspb.Message.toObjectList(msg.getRegionsList(),
    proto.sp.scan_runner.ScanArtifact.ImageRegionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageMetadata}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanArtifact.ImageMetadata;
  return proto.sp.scan_runner.ScanArtifact.ImageMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageMetadata}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 3:
      var value = new proto.sp.scan_runner.ScanArtifact.ImageRegionData;
      reader.readMessage(value,proto.sp.scan_runner.ScanArtifact.ImageRegionData.deserializeBinaryFromReader);
      msg.addRegions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanArtifact.ImageMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_runner.ScanArtifact.ImageRegionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 width = 1;
 * @return {number}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 height = 2;
 * @return {number}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ImageRegionData regions = 3;
 * @return {!Array<!proto.sp.scan_runner.ScanArtifact.ImageRegionData>}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.getRegionsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ScanArtifact.ImageRegionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.ScanArtifact.ImageRegionData, 3));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanArtifact.ImageRegionData>} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} returns this
*/
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.setRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact.ImageRegionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageRegionData}
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.addRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_runner.ScanArtifact.ImageRegionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.ScanArtifact.ImageMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.ImageMetadata.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanArtifact.TextMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanArtifact.TextMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    nlpScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    nlpText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanArtifact.TextMetadata}
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanArtifact.TextMetadata;
  return proto.sp.scan_runner.ScanArtifact.TextMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanArtifact.TextMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanArtifact.TextMetadata}
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNlpScore(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNlpText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanArtifact.TextMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanArtifact.TextMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNlpScore();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getNlpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional float nlp_score = 1;
 * @return {number}
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.prototype.getNlpScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanArtifact.TextMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.prototype.setNlpScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string nlp_text = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.prototype.getNlpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact.TextMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.TextMetadata.prototype.setNlpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanArtifact.TextArtifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanArtifact.TextArtifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanArtifact.TextArtifact}
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanArtifact.TextArtifact;
  return proto.sp.scan_runner.ScanArtifact.TextArtifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanArtifact.TextArtifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanArtifact.TextArtifact}
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanArtifact.TextArtifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanArtifact.TextArtifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string content = 1;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact.TextArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.TextArtifact.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.MetadataTypeCase = {
  METADATA_TYPE_NOT_SET: 0,
  IMAGE: 1,
  TEXT: 2
};

/**
 * @return {proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.MetadataTypeCase}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.getMetadataTypeCase = function() {
  return /** @type {proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.MetadataTypeCase} */(jspb.Message.computeOneofCase(this, proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: (f = msg.getImage()) && proto.sp.scan_runner.ScanArtifact.ImageMetadata.toObject(includeInstance, f),
    text: (f = msg.getText()) && proto.sp.scan_runner.ScanArtifact.TextMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScanArtifact.ArtifactMetadata;
  return proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.ScanArtifact.ImageMetadata;
      reader.readMessage(value,proto.sp.scan_runner.ScanArtifact.ImageMetadata.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.ScanArtifact.TextMetadata;
      reader.readMessage(value,proto.sp.scan_runner.ScanArtifact.TextMetadata.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_runner.ScanArtifact.ImageMetadata.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_runner.ScanArtifact.TextMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImageMetadata image = 1;
 * @return {?proto.sp.scan_runner.ScanArtifact.ImageMetadata}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.getImage = function() {
  return /** @type{?proto.sp.scan_runner.ScanArtifact.ImageMetadata} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanArtifact.ImageMetadata, 1));
};


/**
 * @param {?proto.sp.scan_runner.ScanArtifact.ImageMetadata|undefined} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} returns this
*/
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.setImage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.hasImage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextMetadata text = 2;
 * @return {?proto.sp.scan_runner.ScanArtifact.TextMetadata}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.getText = function() {
  return /** @type{?proto.sp.scan_runner.ScanArtifact.TextMetadata} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanArtifact.TextMetadata, 2));
};


/**
 * @param {?proto.sp.scan_runner.ScanArtifact.TextMetadata|undefined} value
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} returns this
*/
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.setText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} returns this
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.ArtifactMetadata.prototype.hasText = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ArtifactType artifact_type = 1;
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactType}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getArtifactType = function() {
  return /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactType} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setArtifactType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string artifact_id = 2;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setArtifactId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bytes content = 4;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes content = 4;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional string mime_type = 5;
 * @return {string}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setMimeType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.clearMimeType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ArtifactMetadata artifact_metadata = 6;
 * @return {?proto.sp.scan_runner.ScanArtifact.ArtifactMetadata}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getArtifactMetadata = function() {
  return /** @type{?proto.sp.scan_runner.ScanArtifact.ArtifactMetadata} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanArtifact.ArtifactMetadata, 6));
};


/**
 * @param {?proto.sp.scan_runner.ScanArtifact.ArtifactMetadata|undefined} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
*/
proto.sp.scan_runner.ScanArtifact.prototype.setArtifactMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.clearArtifactMetadata = function() {
  return this.setArtifactMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScanArtifact.prototype.hasArtifactMetadata = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 page_offset = 7;
 * @return {number}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getPageOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setPageOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 group_offset = 8;
 * @return {number}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getGroupOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setGroupOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional ArtifactName artifact_name = 9;
 * @return {!proto.sp.scan_runner.ArtifactName}
 */
proto.sp.scan_runner.ScanArtifact.prototype.getArtifactName = function() {
  return /** @type {!proto.sp.scan_runner.ArtifactName} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.sp.scan_runner.ArtifactName} value
 * @return {!proto.sp.scan_runner.ScanArtifact} returns this
 */
proto.sp.scan_runner.ScanArtifact.prototype.setArtifactName = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.CompressedHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.CompressedHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.CompressedHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.CompressedHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.CompressedHeader}
 */
proto.sp.scan_runner.CompressedHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.CompressedHeader;
  return proto.sp.scan_runner.CompressedHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.CompressedHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.CompressedHeader}
 */
proto.sp.scan_runner.CompressedHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.CompressedHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.CompressedHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.CompressedHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.CompressedHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 key = 1;
 * @return {number}
 */
proto.sp.scan_runner.CompressedHeader.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.CompressedHeader} returns this
 */
proto.sp.scan_runner.CompressedHeader.prototype.setKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 value = 2;
 * @return {number}
 */
proto.sp.scan_runner.CompressedHeader.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.CompressedHeader} returns this
 */
proto.sp.scan_runner.CompressedHeader.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.HttpRequestData.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.HttpRequestData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.HttpRequestData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.HttpRequestData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.HttpRequestData.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, 0),
    method: jspb.Message.getFieldWithDefault(msg, 4, 0),
    payloadSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    headersList: jspb.Message.toObjectList(msg.getHeadersList(),
    proto.sp.scan_runner.CompressedHeader.toObject, includeInstance),
    initiator: jspb.Message.getFieldWithDefault(msg, 7, 0),
    startTimeMs: jspb.Message.getFieldWithDefault(msg, 8, 0),
    durationMs: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.HttpRequestData}
 */
proto.sp.scan_runner.HttpRequestData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.HttpRequestData;
  return proto.sp.scan_runner.HttpRequestData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.HttpRequestData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.HttpRequestData}
 */
proto.sp.scan_runner.HttpRequestData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReqId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMethod(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPayloadSize(value);
      break;
    case 6:
      var value = new proto.sp.scan_runner.CompressedHeader;
      reader.readMessage(value,proto.sp.scan_runner.CompressedHeader.deserializeBinaryFromReader);
      msg.addHeaders(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInitiator(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimeMs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.HttpRequestData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.HttpRequestData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.HttpRequestData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.HttpRequestData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPayloadSize();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.sp.scan_runner.CompressedHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getStartTimeMs();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getDurationMs();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional uint32 req_id = 1;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 url = 3;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getUrl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 method = 4;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getMethod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setMethod = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 payload_size = 5;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getPayloadSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setPayloadSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated CompressedHeader headers = 6;
 * @return {!Array<!proto.sp.scan_runner.CompressedHeader>}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getHeadersList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.CompressedHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.CompressedHeader, 6));
};


/**
 * @param {!Array<!proto.sp.scan_runner.CompressedHeader>} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
*/
proto.sp.scan_runner.HttpRequestData.prototype.setHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sp.scan_runner.CompressedHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.CompressedHeader}
 */
proto.sp.scan_runner.HttpRequestData.prototype.addHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sp.scan_runner.CompressedHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * optional uint32 initiator = 7;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getInitiator = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setInitiator = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.clearInitiator = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.HttpRequestData.prototype.hasInitiator = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 start_time_ms = 8;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getStartTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setStartTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 duration_ms = 9;
 * @return {number}
 */
proto.sp.scan_runner.HttpRequestData.prototype.getDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpRequestData} returns this
 */
proto.sp.scan_runner.HttpRequestData.prototype.setDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.HttpResponseData.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.HttpResponseData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.HttpResponseData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.HttpResponseData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.HttpResponseData.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    payloadSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    headersList: jspb.Message.toObjectList(msg.getHeadersList(),
    proto.sp.scan_runner.CompressedHeader.toObject, includeInstance),
    protocol: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startTimeMs: jspb.Message.getFieldWithDefault(msg, 7, 0),
    durationMs: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.HttpResponseData}
 */
proto.sp.scan_runner.HttpResponseData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.HttpResponseData;
  return proto.sp.scan_runner.HttpResponseData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.HttpResponseData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.HttpResponseData}
 */
proto.sp.scan_runner.HttpResponseData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReqId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatusCode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPayloadSize(value);
      break;
    case 5:
      var value = new proto.sp.scan_runner.CompressedHeader;
      reader.readMessage(value,proto.sp.scan_runner.CompressedHeader.deserializeBinaryFromReader);
      msg.addHeaders(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProtocol(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimeMs(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDurationMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.HttpResponseData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.HttpResponseData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.HttpResponseData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.HttpResponseData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatusCode();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPayloadSize();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sp.scan_runner.CompressedHeader.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getStartTimeMs();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getDurationMs();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 req_id = 1;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 url = 2;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getUrl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setUrl = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 status_code = 3;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getStatusCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 payload_size = 4;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getPayloadSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setPayloadSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated CompressedHeader headers = 5;
 * @return {!Array<!proto.sp.scan_runner.CompressedHeader>}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getHeadersList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.CompressedHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.CompressedHeader, 5));
};


/**
 * @param {!Array<!proto.sp.scan_runner.CompressedHeader>} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
*/
proto.sp.scan_runner.HttpResponseData.prototype.setHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sp.scan_runner.CompressedHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.CompressedHeader}
 */
proto.sp.scan_runner.HttpResponseData.prototype.addHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sp.scan_runner.CompressedHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * optional uint32 protocol = 6;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getProtocol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setProtocol = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.clearProtocol = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.HttpResponseData.prototype.hasProtocol = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 start_time_ms = 7;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getStartTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setStartTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 duration_ms = 8;
 * @return {number}
 */
proto.sp.scan_runner.HttpResponseData.prototype.getDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.HttpResponseData} returns this
 */
proto.sp.scan_runner.HttpResponseData.prototype.setDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.HttpTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.HttpTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.HttpTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.HttpTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestData: (f = msg.getRequestData()) && proto.sp.scan_runner.HttpRequestData.toObject(includeInstance, f),
    responseData: (f = msg.getResponseData()) && proto.sp.scan_runner.HttpResponseData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.HttpTransaction}
 */
proto.sp.scan_runner.HttpTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.HttpTransaction;
  return proto.sp.scan_runner.HttpTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.HttpTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.HttpTransaction}
 */
proto.sp.scan_runner.HttpTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.HttpRequestData;
      reader.readMessage(value,proto.sp.scan_runner.HttpRequestData.deserializeBinaryFromReader);
      msg.setRequestData(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.HttpResponseData;
      reader.readMessage(value,proto.sp.scan_runner.HttpResponseData.deserializeBinaryFromReader);
      msg.setResponseData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.HttpTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.HttpTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.HttpTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.HttpTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_runner.HttpRequestData.serializeBinaryToWriter
    );
  }
  f = message.getResponseData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_runner.HttpResponseData.serializeBinaryToWriter
    );
  }
};


/**
 * optional HttpRequestData request_data = 1;
 * @return {?proto.sp.scan_runner.HttpRequestData}
 */
proto.sp.scan_runner.HttpTransaction.prototype.getRequestData = function() {
  return /** @type{?proto.sp.scan_runner.HttpRequestData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.HttpRequestData, 1));
};


/**
 * @param {?proto.sp.scan_runner.HttpRequestData|undefined} value
 * @return {!proto.sp.scan_runner.HttpTransaction} returns this
*/
proto.sp.scan_runner.HttpTransaction.prototype.setRequestData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.HttpTransaction} returns this
 */
proto.sp.scan_runner.HttpTransaction.prototype.clearRequestData = function() {
  return this.setRequestData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.HttpTransaction.prototype.hasRequestData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HttpResponseData response_data = 2;
 * @return {?proto.sp.scan_runner.HttpResponseData}
 */
proto.sp.scan_runner.HttpTransaction.prototype.getResponseData = function() {
  return /** @type{?proto.sp.scan_runner.HttpResponseData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.HttpResponseData, 2));
};


/**
 * @param {?proto.sp.scan_runner.HttpResponseData|undefined} value
 * @return {!proto.sp.scan_runner.HttpTransaction} returns this
*/
proto.sp.scan_runner.HttpTransaction.prototype.setResponseData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.HttpTransaction} returns this
 */
proto.sp.scan_runner.HttpTransaction.prototype.clearResponseData = function() {
  return this.setResponseData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.HttpTransaction.prototype.hasResponseData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.JsCookie.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.JsCookie.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.JsCookie} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.JsCookie.toObject = function(includeInstance, msg) {
  var f, obj = {
    cookie: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.JsCookie}
 */
proto.sp.scan_runner.JsCookie.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.JsCookie;
  return proto.sp.scan_runner.JsCookie.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.JsCookie} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.JsCookie}
 */
proto.sp.scan_runner.JsCookie.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCookie(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.JsCookie.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.JsCookie.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.JsCookie} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.JsCookie.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCookie();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string cookie = 1;
 * @return {string}
 */
proto.sp.scan_runner.JsCookie.prototype.getCookie = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.JsCookie} returns this
 */
proto.sp.scan_runner.JsCookie.prototype.setCookie = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.sp.scan_runner.JsCookie.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.JsCookie} returns this
 */
proto.sp.scan_runner.JsCookie.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.DiagnoseMetadata.repeatedFields_ = [2,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.DiagnoseMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.DiagnoseMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.DiagnoseMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    wildcardLink: jspb.Message.getFieldWithDefault(msg, 1, ""),
    jsCookiesList: jspb.Message.toObjectList(msg.getJsCookiesList(),
    proto.sp.scan_runner.JsCookie.toObject, includeInstance),
    originalMetaCommand: jspb.Message.getFieldWithDefault(msg, 4, ""),
    consentString: jspb.Message.getFieldWithDefault(msg, 5, ""),
    spCustomVendorsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    gdprApplies: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata}
 */
proto.sp.scan_runner.DiagnoseMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.DiagnoseMetadata;
  return proto.sp.scan_runner.DiagnoseMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.DiagnoseMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata}
 */
proto.sp.scan_runner.DiagnoseMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWildcardLink(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.JsCookie;
      reader.readMessage(value,proto.sp.scan_runner.JsCookie.deserializeBinaryFromReader);
      msg.addJsCookies(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalMetaCommand(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsentString(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpCustomVendors(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGdprApplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.DiagnoseMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.DiagnoseMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.DiagnoseMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJsCookiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_runner.JsCookie.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSpCustomVendorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string wildcard_link = 1;
 * @return {string}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.getWildcardLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.setWildcardLink = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.clearWildcardLink = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.hasWildcardLink = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated JsCookie js_cookies = 2;
 * @return {!Array<!proto.sp.scan_runner.JsCookie>}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.getJsCookiesList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.JsCookie>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.JsCookie, 2));
};


/**
 * @param {!Array<!proto.sp.scan_runner.JsCookie>} value
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
*/
proto.sp.scan_runner.DiagnoseMetadata.prototype.setJsCookiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_runner.JsCookie=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.JsCookie}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.addJsCookies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_runner.JsCookie, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.clearJsCookiesList = function() {
  return this.setJsCookiesList([]);
};


/**
 * optional string original_meta_command = 4;
 * @return {string}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.getOriginalMetaCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.setOriginalMetaCommand = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.clearOriginalMetaCommand = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.hasOriginalMetaCommand = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string consent_string = 5;
 * @return {string}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.getConsentString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.setConsentString = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.clearConsentString = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.hasConsentString = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string sp_custom_vendors = 6;
 * @return {!Array<string>}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.getSpCustomVendorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.setSpCustomVendorsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.addSpCustomVendors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.clearSpCustomVendorsList = function() {
  return this.setSpCustomVendorsList([]);
};


/**
 * optional bool gdpr_applies = 7;
 * @return {boolean}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.getGdprApplies = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.setGdprApplies = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_runner.DiagnoseMetadata} returns this
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.clearGdprApplies = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DiagnoseMetadata.prototype.hasGdprApplies = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.DiagnoseEventsArtifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.DiagnoseEventsArtifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.toObject = function(includeInstance, msg) {
  var f, obj = {
    metadata: (f = msg.getMetadata()) && proto.sp.scan_runner.DiagnoseMetadata.toObject(includeInstance, f),
    dictionaryList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.sp.scan_runner.HttpTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.DiagnoseEventsArtifact;
  return proto.sp.scan_runner.DiagnoseEventsArtifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.DiagnoseEventsArtifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.DiagnoseMetadata;
      reader.readMessage(value,proto.sp.scan_runner.DiagnoseMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDictionary(value);
      break;
    case 3:
      var value = new proto.sp.scan_runner.HttpTransaction;
      reader.readMessage(value,proto.sp.scan_runner.HttpTransaction.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.DiagnoseEventsArtifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.DiagnoseEventsArtifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_runner.DiagnoseMetadata.serializeBinaryToWriter
    );
  }
  f = message.getDictionaryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_runner.HttpTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiagnoseMetadata metadata = 1;
 * @return {?proto.sp.scan_runner.DiagnoseMetadata}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.getMetadata = function() {
  return /** @type{?proto.sp.scan_runner.DiagnoseMetadata} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.DiagnoseMetadata, 1));
};


/**
 * @param {?proto.sp.scan_runner.DiagnoseMetadata|undefined} value
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
*/
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string dictionary = 2;
 * @return {!Array<string>}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.getDictionaryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.setDictionaryList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.addDictionary = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.clearDictionaryList = function() {
  return this.setDictionaryList([]);
};


/**
 * repeated HttpTransaction events = 3;
 * @return {!Array<!proto.sp.scan_runner.HttpTransaction>}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.HttpTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.HttpTransaction, 3));
};


/**
 * @param {!Array<!proto.sp.scan_runner.HttpTransaction>} value
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
*/
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_runner.HttpTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.HttpTransaction}
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_runner.HttpTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.DiagnoseEventsArtifact} returns this
 */
proto.sp.scan_runner.DiagnoseEventsArtifact.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_runner.ScannerInternalState.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.ScannerInternalState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.ScannerInternalState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScannerInternalState.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeline: (f = msg.getTimeline()) && proto.sp.scan_runner.ScanTimeline.toObject(includeInstance, f),
    artifactsList: jspb.Message.toObjectList(msg.getArtifactsList(),
    proto.sp.scan_runner.ScanArtifact.toObject, includeInstance),
    groupOffset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phase: (f = msg.getPhase()) && proto.sp.scan_runner.ScanTimeline.ScanPhase.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.ScannerInternalState}
 */
proto.sp.scan_runner.ScannerInternalState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.ScannerInternalState;
  return proto.sp.scan_runner.ScannerInternalState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.ScannerInternalState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.ScannerInternalState}
 */
proto.sp.scan_runner.ScannerInternalState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_runner.ScanTimeline;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.deserializeBinaryFromReader);
      msg.setTimeline(value);
      break;
    case 2:
      var value = new proto.sp.scan_runner.ScanArtifact;
      reader.readMessage(value,proto.sp.scan_runner.ScanArtifact.deserializeBinaryFromReader);
      msg.addArtifacts(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupOffset(value);
      break;
    case 4:
      var value = new proto.sp.scan_runner.ScanTimeline.ScanPhase;
      reader.readMessage(value,proto.sp.scan_runner.ScanTimeline.ScanPhase.deserializeBinaryFromReader);
      msg.setPhase(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.ScannerInternalState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.ScannerInternalState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.ScannerInternalState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_runner.ScanTimeline.serializeBinaryToWriter
    );
  }
  f = message.getArtifactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_runner.ScanArtifact.serializeBinaryToWriter
    );
  }
  f = message.getGroupOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPhase();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sp.scan_runner.ScanTimeline.ScanPhase.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanTimeline timeline = 1;
 * @return {?proto.sp.scan_runner.ScanTimeline}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.getTimeline = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanTimeline, 1));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline|undefined} value
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
*/
proto.sp.scan_runner.ScannerInternalState.prototype.setTimeline = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
 */
proto.sp.scan_runner.ScannerInternalState.prototype.clearTimeline = function() {
  return this.setTimeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.hasTimeline = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScanArtifact artifacts = 2;
 * @return {!Array<!proto.sp.scan_runner.ScanArtifact>}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.getArtifactsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ScanArtifact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_runner.ScanArtifact, 2));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanArtifact>} value
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
*/
proto.sp.scan_runner.ScannerInternalState.prototype.setArtifactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.addArtifacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_runner.ScanArtifact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
 */
proto.sp.scan_runner.ScannerInternalState.prototype.clearArtifactsList = function() {
  return this.setArtifactsList([]);
};


/**
 * optional uint32 group_offset = 3;
 * @return {number}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.getGroupOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
 */
proto.sp.scan_runner.ScannerInternalState.prototype.setGroupOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ScanTimeline.ScanPhase phase = 4;
 * @return {?proto.sp.scan_runner.ScanTimeline.ScanPhase}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.getPhase = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline.ScanPhase} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_runner.ScanTimeline.ScanPhase, 4));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline.ScanPhase|undefined} value
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
*/
proto.sp.scan_runner.ScannerInternalState.prototype.setPhase = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_runner.ScannerInternalState} returns this
 */
proto.sp.scan_runner.ScannerInternalState.prototype.clearPhase = function() {
  return this.setPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_runner.ScannerInternalState.prototype.hasPhase = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.StartChromeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.StartChromeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.StartChromeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.StartChromeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    proxyType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.StartChromeRequest}
 */
proto.sp.scan_runner.StartChromeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.StartChromeRequest;
  return proto.sp.scan_runner.StartChromeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.StartChromeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.StartChromeRequest}
 */
proto.sp.scan_runner.StartChromeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_runner.ProxyType} */ (reader.readEnum());
      msg.setProxyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.StartChromeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.StartChromeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.StartChromeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.StartChromeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProxyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string region_id = 1;
 * @return {string}
 */
proto.sp.scan_runner.StartChromeRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.StartChromeRequest} returns this
 */
proto.sp.scan_runner.StartChromeRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domain = 2;
 * @return {string}
 */
proto.sp.scan_runner.StartChromeRequest.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.StartChromeRequest} returns this
 */
proto.sp.scan_runner.StartChromeRequest.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string auth_token = 3;
 * @return {string}
 */
proto.sp.scan_runner.StartChromeRequest.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.StartChromeRequest} returns this
 */
proto.sp.scan_runner.StartChromeRequest.prototype.setAuthToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ProxyType proxy_type = 4;
 * @return {!proto.sp.scan_runner.ProxyType}
 */
proto.sp.scan_runner.StartChromeRequest.prototype.getProxyType = function() {
  return /** @type {!proto.sp.scan_runner.ProxyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_runner.ProxyType} value
 * @return {!proto.sp.scan_runner.StartChromeRequest} returns this
 */
proto.sp.scan_runner.StartChromeRequest.prototype.setProxyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_runner.StartChromeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_runner.StartChromeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_runner.StartChromeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.StartChromeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vncPassword: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_runner.StartChromeResponse}
 */
proto.sp.scan_runner.StartChromeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_runner.StartChromeResponse;
  return proto.sp.scan_runner.StartChromeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_runner.StartChromeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_runner.StartChromeResponse}
 */
proto.sp.scan_runner.StartChromeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVncPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_runner.StartChromeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_runner.StartChromeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_runner.StartChromeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_runner.StartChromeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVncPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vnc_password = 1;
 * @return {string}
 */
proto.sp.scan_runner.StartChromeResponse.prototype.getVncPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_runner.StartChromeResponse} returns this
 */
proto.sp.scan_runner.StartChromeResponse.prototype.setVncPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.sp.scan_runner.ArtifactName = {
  ARTIFACT_NAME_UNSET: 0,
  ARTIFACT_NAME_CONSENT_PAGE_VIEWPORT: 1,
  ARTIFACT_NAME_CONSENT_OUTER_FRAME_ELEMENT: 2,
  ARTIFACT_NAME_CONSENT_MAIN_ELEMENT: 3,
  ARTIFACT_NAME_ACCEPT_ALL_ELEMENT: 4,
  ARTIFACT_NAME_ACCEPT_NECESSARY_ELEMENT: 5,
  ARTIFACT_NAME_REJECT_ALL_ELEMENT: 6,
  ARTIFACT_NAME_COOKIE_SETTINGS_ELEMENT: 7,
  ARTIFACT_NAME_VENDOR_SETTINGS_ELEMENT: 8,
  ARTIFACT_NAME_PRIVACY_POLICY_LINK_ELEMENT: 9,
  ARTIFACT_NAME_COOKIE_POLICY_LINK_ELEMENT: 10,
  ARTIFACT_NAME_CCPA_OPTOUT_LINK_ELEMENT: 11,
  ARTIFACT_NAME_MAIN_PAGE_VIEWPORT: 12,
  ARTIFACT_NAME_MAIN_PAGE_FULL: 13,
  ARTIFACT_NAME_CONSENT_TEXT: 14,
  ARTIFACT_NAME_PRIVACY_POLICY_PAGE_FULL: 15,
  ARTIFACT_NAME_PRIVACY_POLICY_PAGE_TEXT: 16,
  ARTIFACT_NAME_CONSENT_TOOL_PAGE_SEARCH_RESULT: 17,
  ARTIFACT_NAME_MAIN_PAGE_SEARCH_RESULT: 18,
  ARTIFACT_NAME_PRIVACY_POLICY_PAGE_SEARCH_RESULT: 19,
  ARTIFACT_NAME_COOKIE_POLICY_PAGE_SEARCH_RESULT: 20,
  ARTIFACT_NAME_COOKIES_BEFORE_CONSENT: 21,
  ARTIFACT_NAME_COOKIES_AFTER_CONSENT: 22,
  ARTIFACT_NAME_CONSENT_API_DATA_BEFORE_CONSENT: 23,
  ARTIFACT_NAME_CONSENT_API_DATA_AFTER_CONSENT: 24,
  ARTIFACT_NAME_MAIN_PAGE_DOM: 25,
  ARTIFACT_NAME_DIAGNOSE_EVENTS: 26,
  ARTIFACT_NAME_WILDCARD_PAGE_VIEWPORT: 37
};

/**
 * @enum {number}
 */
proto.sp.scan_runner.ProxyType = {
  PROXY_TYPE_UNSET: 0,
  PROXY_TYPE_SOURCEPOINT_DATACENTER: 1,
  PROXY_TYPE_BRIGHTDATA_DATACENTER: 2,
  PROXY_TYPE_BRIGHTDATA_RESIDENTIAL: 3
};

goog.object.extend(exports, proto.sp.scan_runner);
