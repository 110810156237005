import React, {ReactElement, FC, useEffect, useState, useCallback} from "react";
import {
    Box,
    FormControl, Grid, LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableSortLabel, TextField,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {ScanJob} from '../generated/sp/scan_service/scan_service_pb';
import {enumName} from '../lib/enumUtils';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {getScanRuleStatusColor} from "../lib/statusUtils";
import {ScanRuleReport} from "../generated/sp/scan_runner/scan_runner_pb";
import {fetchScanRuleMapByName, correctScanJobResults, getScanJobById} from "../lib/scanServiceUtils";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {Order, sortNumber, sortString, TableHeader} from "../lib/sortUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from '@mui/icons-material/Clear';
import {ScanServiceInternalClient} from "../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {CorrectScanJobResultsRequest} from "../generated/sp/scan_service/scan_service_internal_pb";
import ScanJobUpdateGatingButton from "./buttons/ScanJobUpdateGatingButton";
import RuleIcon from "@mui/icons-material/Rule";

interface ScanJobResultTableProps {
    scanJobId: string | undefined;
    maxHeight: number;
}

interface TableRow {
    scanRuleShortName: string;
    scanRuleShortNameSortable: number;
    scanRuleName: string;
    originalStatus: string;
    status: string;
    correction: ScanRuleReport.ScanRuleStatus;
    originalStatusColor: string;
    statusColor: string;
}

const HEADER_SCAN_RULE_SHORT_NAME = "Rule";
const HEADER_SCAN_RULE_NAME = "Name";
const HEADER_STATUS = "Status";
const HEADER_CORRECTION = "Correction";
const HEADER_ORIGINAL_STATUS = "Original Status";

const headers: TableHeader[] = [
    {headerName: HEADER_SCAN_RULE_SHORT_NAME, align: 'left'},
    {headerName: HEADER_SCAN_RULE_NAME, align: 'left'},
    {headerName: HEADER_ORIGINAL_STATUS, align: 'center', hidden: true},
    {headerName: HEADER_STATUS, align: 'center'},
    {headerName: HEADER_CORRECTION, align: 'center', hidden: true},
]

const ScanJobResultTable: FC<ScanJobResultTableProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [correctionMode, setCorrectionMode] = useState(false);
    const [correctionMade, setCorrectionMade] = useState(false);
    const [currentGatingType, setCurrentGatingType] = useState<ScanJob.ScanJobGating.GatingType | undefined>(undefined);
    const [remarkText, setRemarkText] = useState<string | undefined>(undefined);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [successText, setSuccessText] = useState<string | undefined>(undefined);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>(HEADER_SCAN_RULE_SHORT_NAME);
    const [rows, setRows] = React.useState<TableRow[]>([]);

    const reloadScanJob = useCallback(async () => {
        setLoading(true);
        if (props.scanJobId !== undefined) {
            const scanServiceClient = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            console.log("Loading scan rule name data...");
            let scanRuleMap = await fetchScanRuleMapByName(scanServiceClient);
            console.log("Finished loading scan rule name data...");
            console.log(`Loading results data for scanJobId=[${props.scanJobId!}]`);
            const scanJob = await getScanJobById(scanServiceClient, props.scanJobId!, [ScanJob.ScanJobField.SCAN_JOB_FIELD_RESULT_REPORTS]);
            if (scanJob.getScanJobResult() !== undefined) {
                const tableRows: Array<TableRow> = scanJob.getScanJobResult()!.getScanRuleReportsList()?.map((scanRuleReport) => {
                    return {
                        scanRuleShortName: scanRuleReport.getScanRuleShortName(),
                        scanRuleShortNameSortable: parseInt(scanRuleReport.getScanRuleShortName().substring(1) || ''), // assume ruleId is formatted like  'r17'
                        scanRuleName: scanRuleMap?.get(scanRuleReport.getScanRuleShortName())?.getName() || '',
                        originalStatus: enumName(ScanRuleReport.ScanRuleStatus, scanRuleReport.getOriginalStatus(), false),
                        status: enumName(ScanRuleReport.ScanRuleStatus, scanRuleReport.getStatus(), false),
                        correction: scanRuleReport.getStatus(),
                        originalStatusColor: getScanRuleStatusColor(scanRuleReport.getOriginalStatus()),
                        statusColor: getScanRuleStatusColor(scanRuleReport.getStatus()),
                    };
                });
                setRows(tableRows);
                setCurrentGatingType(scanJob.getGatingType());
            }
            console.log(`Finished reloading results data for scanJobId=${props.scanJobId}`);
            setLoading(false);
        }
    }, [props.scanJobId]);

    useEffect(() => {
        (async () => {
            if( props.scanJobId !== undefined) {
                await reloadScanJob();
            }
        })();
    }, [props.scanJobId]); // eslint-disable-line react-hooks/exhaustive-deps

    const sortRows = (rows: TableRow[], orderBy: string, order: Order): TableRow[] => {
        if (orderBy === HEADER_SCAN_RULE_SHORT_NAME) {
            rows.sort(function (a, b) {
                return sortNumber(a.scanRuleShortNameSortable, b.scanRuleShortNameSortable, order);
            });
        } else if (orderBy === HEADER_SCAN_RULE_NAME) {
            rows.sort(function (a, b) {
                return sortString(a.scanRuleName, b.scanRuleName, order);
            });
        } else if (orderBy === HEADER_ORIGINAL_STATUS) {
            rows.sort(function (a, b) {
                return sortString(a.originalStatus, b.originalStatus, order);
            });
        } else if (orderBy === HEADER_STATUS) {
            rows.sort(function (a, b) {
                return sortString(a.status, b.status, order);
            });
        } else if (orderBy === HEADER_CORRECTION) {
            rows.sort(function (a, b) {
                return sortNumber(a.correction, b.correction, order);
            });
        }
        return rows;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, header: string) => {
        const isAsc = orderBy === header && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header);
    };

    const handleCorrectionChange = (scanRuleShortName: string, event: SelectChangeEvent<ScanRuleReport.ScanRuleStatus>) => {
        let scanRuleStatus = event.target.value as ScanRuleReport.ScanRuleStatus;
        let foundMatchingRow = false;
        const newRows = rows.map(row => {
            if (row.scanRuleShortName === scanRuleShortName) {
                foundMatchingRow = true;
                return {...row, correction: scanRuleStatus};
            } else {
                return row;
            }
        });

        if (foundMatchingRow) {
            setRows(newRows);
            setCorrectionMade(true);
        }
    };

    const handleRemarkChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRemarkText(event.target.value);
    };

    const handleClickCancel = async () => {
        // reset rows and everything back to original state
        setCorrectionMode(false);
        setCorrectionMade(false);
        setSuccessText(undefined);
        setErrorText(undefined);
        await reloadScanJob();
    }
    const handleClickSaveCorrections = () => {
        if (!correctionMode) {
            setCorrectionMode(true);
            setSuccessText(undefined);
            setErrorText(undefined);
        } else {
            // convert to CorrectScanJobResultsRequest.CorrectedResult and upload.
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            let corrections = rows.map((row) => {
                let req =  new CorrectScanJobResultsRequest.CorrectedResult()
                  .setRuleShortName(row.scanRuleShortName)
                  .setPassed(row.correction === ScanRuleReport.ScanRuleStatus.SCAN_RULE_STATUS_PASS);
                if (remarkText !== undefined && remarkText.length > 0) {
                    req.setComment(remarkText);
                }
                return req;
            });
            Promise.resolve(correctScanJobResults(scanServiceInternal, props.scanJobId!, corrections)).then(function (recordsUpdated) {
                console.log(`RecordsUpdated ${recordsUpdated}`);
                setSuccessText(`${recordsUpdated} records updated`);
                setCorrectionMode(false);
                setCorrectionMade(false);
                setLoading(false);
            }, function (rejected) {
                setErrorText(rejected.toString());
                setLoading(false);
            }).then((result) => {
                if (correctionMade) {
                    (async () => {
                        console.log("Reloading scan job results...")
                        await reloadScanJob();
                    })();
                }
            });
        }
    };

    return (
      <div>
          <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                  {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
              </Grid>
          </Grid>
          <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}}>
              <Table stickyHeader size="small" aria-label="Scan Rules">
                  <TableHead>
                      <StyledTableRow>
                          {headers.filter((header) => correctionMode? true: (header.hidden === undefined || !header.hidden)).map(tableHeader => (
                            <StyledTableCell key={tableHeader.headerName} align={tableHeader.align}>
                                <TableSortLabel active={orderBy === tableHeader.headerName}
                                                direction={orderBy === tableHeader.headerName ? order : 'asc'}
                                                onClick={e => handleRequestSort(e, tableHeader.headerName)}
                                >
                                    {tableHeader.headerName}
                                </TableSortLabel>
                            </StyledTableCell>
                          ))}
                      </StyledTableRow>
                  </TableHead>
                  <TableBody>
                      {sortRows(rows, orderBy, order).map(row => (
                        <StyledTableRow hover key={row.scanRuleShortName}>
                            <StyledTableCell align="left">{row.scanRuleShortName}</StyledTableCell>
                            <StyledTableCell align="left">{row.scanRuleName}</StyledTableCell>
                            { correctionMode &&
                              <StyledTableCell align="center">
                                  <Box sx={{color: row.originalStatusColor}}>{row.originalStatus}</Box>
                              </StyledTableCell>
                            }
                            <StyledTableCell align="center">
                                <Box sx={{color: row.statusColor}}>{row.status}</Box>
                            </StyledTableCell>
                            { correctionMode &&
                               <StyledTableCell align="center">
                                   <FormControl fullWidth>
                                       <Select
                                         size="small"
                                         id="correction"
                                         value={row.correction}
                                         onChange={x => handleCorrectionChange(row.scanRuleShortName, x)}>
                                           <MenuItem value={ScanRuleReport.ScanRuleStatus.SCAN_RULE_STATUS_FAIL}>Fail</MenuItem>
                                           <MenuItem value={ScanRuleReport.ScanRuleStatus.SCAN_RULE_STATUS_PASS}>Pass</MenuItem>
                                       </Select>
                                   </FormControl>
                               </StyledTableCell>
                            }
                        </StyledTableRow>
                      ))}
                      {rows.length === 0 &&
                        <StyledTableRow>
                            <StyledTableCell align="center" colSpan={4}><Typography style={{ fontWeight: 600 }} variant="subtitle2">No Data, still processing?</Typography></StyledTableCell>
                        </StyledTableRow>
                      }
                  </TableBody>
              </Table>
          </TableContainer>
          <Grid container spacing={1} alignItems="center">
              {errorText !== undefined &&
                <Grid item xs={12} sx={{"mt": 1}}>
                    <Box display="flex" alignItems="right" justifyContent="right">
                        <Typography sx={{color: "red"}} component="div">Failed to upload corrections: {errorText}</Typography>
                    </Box>
                </Grid>
              }
              {successText !== undefined &&
                <Grid item xs={12} sx={{"mt": 1}}>
                    <Box display="flex" alignItems="right" justifyContent="right">
                        <Typography sx={{color: "green"}} component="div">Successfully uploaded corrections: {successText}</Typography>
                    </Box>
                </Grid>
              }
                  <Grid item xs={7} sx={{"mt": 1}}>&nbsp;</Grid>
                  <Grid item xs={5} sx={{"mt": 1}}>
                    {correctionMode &&
                     <TextField size="small" multiline rows={2} label="Remark" placeholder="Enter remarks here.." id="remark" type="string" value={remarkText || ''} onChange={handleRemarkChange} fullWidth={true}/>
                    }
                    {!correctionMode &&
                      <Typography sx={{color: "green"}} component="div">&nbsp;</Typography>
                    }
                  </Grid>
              <Grid item xs={12} sx={{"mt": 1}}>
                  <Box display="flex" alignItems="right" justifyContent="right">
                      { correctionMode &&
                        <LoadingButton
                          size="small"
                          color="secondary"
                          onClick={handleClickCancel}
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<ClearIcon/>}
                          variant="contained"
                          sx={{"mr": 1}}
                        >
                            Cancel
                        </LoadingButton>
                      }
                      { !correctionMode && currentGatingType !== undefined &&
                        <ScanJobUpdateGatingButton sx={{"mr": 1}} scanJobId={props.scanJobId} currentGatingType={currentGatingType}  updateGatingCompleteFunction={reloadScanJob}/>
                      }
                      <LoadingButton
                        size="small"
                        color="secondary"
                        onClick={handleClickSaveCorrections}
                        loading={loading}
                        disabled={(correctionMode && !correctionMade)}
                        loadingPosition="start"
                        startIcon={!correctionMode ? <RuleIcon/> : <SaveIcon/>}
                        variant="contained"
                      >
                          { correctionMode ? "Save" : "Correct" }
                      </LoadingButton>
                  </Box>
              </Grid>
          </Grid>
      </div>
    );
}

export default ScanJobResultTable;