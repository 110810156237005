import React, {ReactElement, FC} from "react";
import { Grid } from '@mui/material';
import MasterContainer from "../components/containers/MasterContainer";
import ScanRulesCard from "../components/cards/ScanRulesCard";

const ScanRules: FC<any> = (): ReactElement => {
    return (
        <MasterContainer>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} sx={{ mt: 2}}>
                <ScanRulesCard maxHeight={750} showFilterUi={true}/>
              </Grid>
            </Grid>
        </MasterContainer>
    );
};
export default ScanRules;