import React, {ReactElement, FC, useState} from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip, TextField, Typography, useMediaQuery } from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import ViewListIcon from '@mui/icons-material/ViewList';
import {RejectedTextResultRow, renderTextTypeText, TrainingRange} from "../../lib/trainingUtils";
import RobotAngryIcon from "@mui-extra/icons/RobotAngryIcon";
import RobotAngryOutlineIcon from '@mui-extra/icons/RobotAngryOutlineIcon';
import {ScanJobArtifact} from "../../generated/sp/scan_service/scan_service_pb";
import FlagIcon from "@mui/icons-material/Flag";
import SimpleTable from "../SimpleTable";

interface TrainingRowMetadataButtonProps {
  trainingRange?: TrainingRange | undefined,
  rejectedRow?: RejectedTextResultRow | undefined,
  sx?: SxProps<Theme>;
}

const TrainingRowMetadataButton: FC<TrainingRowMetadataButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setDialogOpen(true);
      setLoading(true);
      setLoading(false);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const renderHumanAcceptance = (isHumanAcceptance: boolean) => {
      if(isHumanAcceptance) {
        return <React.Fragment>
          <Grid item xs={1}>
            <Tooltip title="Skynet Generated" placement="top"><FlagIcon sx={{color:"black"}}/></Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{color:"blue"}} variant="body1" component="div" >A Human has accepted this data</Typography>
          </Grid>
        </React.Fragment>;
      }
      else {
        return <React.Fragment>
          <Grid item xs={1}>
            <Tooltip title="NOT Skynet Generated" placement="top"><FlagIcon sx={{color: "red"}}/></Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1" component="div">A Human has NOT accepted this data</Typography>
          </Grid>
        </React.Fragment>
      }
    }
    const renderSkynetGenerated = (isSkynetDataDefined: boolean) => {
      if(isSkynetDataDefined) {
        return <React.Fragment>
          <Grid item xs={1}>
            <Tooltip title="Skynet Generated" placement="top"><RobotAngryIcon sx={{color: "blue"}}/></Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{color:"blue"}} variant="body1" component="div" >Skynet was used for this data</Typography>
          </Grid>
        </React.Fragment>;
      }
      else {
        return <React.Fragment>
          <Grid item xs={1}>
            <Tooltip title="NOT Skynet Generated" placement="top"><RobotAngryOutlineIcon sx={{color: "blue"}}/></Tooltip>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1" component="div">Skynet was NOT used for this data</Typography>
          </Grid>
        </React.Fragment>
      }
    }

  const renderSkynetModelVersion= (modelMetadata: ScanJobArtifact.ScanJobTrainingData.ModelMetadata | undefined) => {
    if(modelMetadata !== undefined) {
      return <React.Fragment>
        <Grid item xs={6}>
          <TextField size="small" label="Skynet Model" type="string" value={(modelMetadata.getModel()) || ''} inputProps={{ readOnly: true}} fullWidth/>
        </Grid>
        <Grid item xs={6}>
          <TextField size="small" label="Skynet Model Version" type="string" value={(modelMetadata.getVersion()) || ''} inputProps={{ readOnly: true}} fullWidth/>
        </Grid>
      </React.Fragment>
    }
    else {
      return <React.Fragment></React.Fragment>
    }
  }

  const renderAudit= (auditData: ScanJobArtifact.ScanJobTrainingData.CreatedAuditData | undefined) => {
    if(auditData !== undefined) {
      return <React.Fragment>
        <Grid item xs={12}><Typography sx={{mb: 1, mt: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Audit Data</Typography></Grid>
        <Grid item xs={6}>
          <TextField size="small" label="Rejected Time" type="string" value={auditData.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
        </Grid>
        <Grid item xs={6}>
          <TextField size="small" label="Rejected By" type="string" value={auditData.getCreatedBy() || ''} inputProps={{ readOnly: true}} fullWidth/>
        </Grid>
      </React.Fragment>
    }
    else {
      return <React.Fragment></React.Fragment>
    }
  }

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={false} maxWidth={"sm"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogContent>
                <Grid container spacing={2} alignItems="center">
                    { props.trainingRange !== undefined &&
                      <React.Fragment>
                          <Grid item xs={12}><Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Range Data</Typography></Grid>
                          { renderHumanAcceptance(props.trainingRange.humanAcceptance) }
                          <Grid item xs={12}>
                              <TextField size="small" label="Text Type" type="string" value={renderTextTypeText(props.trainingRange.textType)} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField size="small" label="Start of Text Range" type="string" value={props.trainingRange.start || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField size="small" label="End of Text Range" type="string" value={props.trainingRange.end || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField multiline minRows={6} maxRows={6} size="small" label="Selected Text" type="string" value={props.trainingRange?.text || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}><Typography sx={{mb: 1, mt: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Skynet Data</Typography></Grid>
                          { renderSkynetGenerated(props.trainingRange.skynetModelMetadata !== undefined)}
                          { renderSkynetModelVersion(props.trainingRange.skynetModelMetadata)}
                          { props.trainingRange.extractedDates !== undefined &&
                              <Grid item xs={12}>
                                <SimpleTable rows={props.trainingRange.extractedDates.map((date) => [date.toLocaleString().toString() || ""]) } headerNames={["Extracted Dates"]} maxHeight={100}/>
                              </Grid>
                          }
                          { renderAudit(props.trainingRange.audit)}
                      </React.Fragment>
                    }
                    { props.rejectedRow !== undefined &&
                      <React.Fragment>
                          <Grid item xs={12}><Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Rejection Data</Typography></Grid>
                          { renderHumanAcceptance(props.rejectedRow.humanAcceptance) }
                          <Grid item xs={12}>
                              <TextField size="small" label="Text Type" type="string" value={renderTextTypeText(props.rejectedRow.textType)} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}><Typography sx={{mb: 1, mt: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Skynet Data</Typography></Grid>
                          { renderSkynetGenerated(props.rejectedRow.skynetModelMetadata !== undefined)}
                          { renderSkynetModelVersion(props.rejectedRow.skynetModelMetadata)}
                          { renderAudit(props.rejectedRow.audit)}
                      </React.Fragment>
                    }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="View Training Row Metadata">
                <IconButton
                    sx={props.sx}
                    color="secondary"
                    disabled={loading}
                    onClick={handleDialogClickOpen}
                    aria-label="View Training Row Metadata"
                    component="span">
                    <ViewListIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default TrainingRowMetadataButton;