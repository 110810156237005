function enumName(e: Object, x: number | undefined, isUpperCased: boolean, trimPrefix?: string | undefined): string {
    if (x === undefined) {
        return '';
    }
    // TODO: this is not correct.  List of property name array index does not necessarily match the proto field number.
    const res = Object.getOwnPropertyNames(e)[x];
    if (res === undefined) {
        return "unknown_val " + x;
    }
    let val = trimPrefix !== undefined ? res.replace(trimPrefix!, "").replaceAll("_", " ").trim() : trimFromUnderscore(res);
    return isUpperCased ? val.toUpperCase() : titleCase(val);
}

function trimFromUnderscore(input: string) {
    const result = input.split('_').pop();
    if (result === undefined) {
        return input;
    } else {
      return result;
    }
}

function titleCase(str: string) : string {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

enum BooleanEnum {
    UNSET = 1,
    TRUE,
    FALSE = 3,
}

export { enumName, BooleanEnum };
