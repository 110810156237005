import React, {ReactElement, FC, useState} from "react";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { ScanServiceInternalClient } from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import { UpdateGatingRequest, UpdateGatingResponse} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import { generateAuthHeader } from '../../lib/authorizationUtils';
import {SxProps} from "@mui/system";
import {formatGatingType, gatingTypeSelectorItems} from "../../lib/gatingUtils";
import {ScanJob} from "../../generated/sp/scan_service/scan_service_pb";
import VerifiedIcon from '@mui/icons-material/Verified';
import ForwardIcon from '@mui/icons-material/Forward';

interface ScanJobUpdateGatingButtonProps {
  scanJobId: string | undefined;
  currentGatingType: ScanJob.ScanJobGating.GatingType | undefined;
  updateGatingCompleteFunction: () => void;
  sx?: SxProps<Theme>;
}

const ScanJobUpdateGatingButton: FC<ScanJobUpdateGatingButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [revisedGatingType, setRevisedGatingType] = useState<ScanJob.ScanJobGating.GatingType>(ScanJob.ScanJobGating.GatingType.GATING_TYPE_UNSET);
    const [remark, setRemark] = useState<string>("");

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [updateGatingScanJobDialogOpen, setUpdateGatingScanJobDialogOpen] = useState(false);
    const handleUpdatingGatingForScanJobDialogClickOpen = () => {
        setUpdateGatingScanJobDialogOpen(true);
    };

    const handleRevisedGatingTypeChange = (event: SelectChangeEvent) => {
      let revised = parseInt(event.target.value);
      if ( revised !== revisedGatingType) {
        setRevisedGatingType(revised);
      }
    };

  const handleRemarkChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRemark(event.target.value);
  };

    const handleUpdateGatingScanJobDialogClose = (shouldProcess: boolean) => () => {
      if ( shouldProcess ) {
        setLoading(true);
        console.log(`Updating Gating for ScanJob with id=${props.scanJobId}`);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(updateGatingForScanJob(scanServiceInternal, props.scanJobId!, revisedGatingType, remark)).then(async function(response) {
          console.log(`Updated gating for scanJobId=${props.scanJobId} with response=${response}`);
          props.updateGatingCompleteFunction();
          setLoading(false);
        }, function(value) {
          // not called
        });
      }
      setUpdateGatingScanJobDialogOpen(false);
    };

    const updateGatingForScanJob = async (scanServiceInternal: ScanServiceInternalClient, scanJobId: string, gatingType: ScanJob.ScanJobGating.GatingType, remark: string | undefined): Promise<UpdateGatingResponse> => {
      var req = new UpdateGatingRequest();
      req.setScanJobId(scanJobId);
      req.setGatingType(gatingType);
      if (remark !== undefined && remark !== "") {
        req.setRemark(remark);
      }
      return new Promise<UpdateGatingResponse>((resolve, reject) => {
        scanServiceInternal.updateGating(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response)
        });
      });
    }

    return (
        <div>
           <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"sm"} open={updateGatingScanJobDialogOpen} onClose={handleUpdateGatingScanJobDialogClose(false)} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">Approve or Reject</DialogTitle>
              <DialogContent>
                <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
                  <Grid item xs={5}>
                    <TextField size="small" label="Current Status" type="string" value={formatGatingType(props.currentGatingType)} inputProps={{ readOnly: true}} fullWidth/>
                  </Grid>
                  <Grid item xs={2} style={{textAlign: "center"}} ><ForwardIcon color="disabled" /></Grid>

                  <Grid item xs={5}>
                    <FormControl fullWidth>
                      <InputLabel id="gating">Revised Status</InputLabel>
                      { revisedGatingType !== undefined &&
                        <Select size="small" labelId="gating" id="gating" value={revisedGatingType.toString()} label="Revised Status" onChange={handleRevisedGatingTypeChange}>
                          {gatingTypeSelectorItems("---", true).map((gatingType) => (
                            <MenuItem key={gatingType.id} value={gatingType.id}>{gatingType.name}</MenuItem>
                          ))}
                        </Select>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField size="small" multiline rows={2} label="Remark" placeholder="Enter remark here.." id="remark" type="string" value={remark || ''} onChange={handleRemarkChange} fullWidth/>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button size="small" variant="contained" autoFocus onClick={handleUpdateGatingScanJobDialogClose(false)}>Cancel</Button>
                <Button size="small" variant="contained" disabled= {revisedGatingType === props.currentGatingType || revisedGatingType === ScanJob.ScanJobGating.GatingType.GATING_TYPE_UNSET} onClick={handleUpdateGatingScanJobDialogClose(true)} autoFocus color="secondary">Update Gating</Button>
              </DialogActions>
            </Dialog>
            <LoadingButton
              sx={props.sx}
              size="small"
              color="secondary"
              onClick={handleUpdatingGatingForScanJobDialogClickOpen}
              loading={loading}
              loadingPosition="start"
              startIcon={<VerifiedIcon />}
              variant="contained">
                Approve/Reject
            </LoadingButton>
        </div>
    );
};

export default ScanJobUpdateGatingButton;