import React, {FC, ReactElement, useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import ImageIcon from '@mui/icons-material/Image';
import {SxProps} from "@mui/system";
import ScanTimelineImageArtifactCard from "../cards/ScanTimelineImageArtifactCard";

interface ScanTimelineImageViewButtonProps {
  scanJobId: string | undefined;
  artifactId: string | undefined;
  useLoadingButton?: boolean | undefined;
  buttonTitle?: string | undefined
  sx?: SxProps<Theme>;
}

const ScanTimelineImageArtifactViewButton: FC<ScanTimelineImageViewButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setLoading(true);
      setDialogOpen(true);
      setLoading(false);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const determineButton = (): ReactElement => {
        return (props.useLoadingButton === undefined || props.useLoadingButton) ?
            <LoadingButton
              sx={props.sx}
              size="small"
              color="secondary"
              onClick={handleDialogClickOpen}
              loading={loading}
              loadingPosition="start"
              disabled={loading || props.artifactId === undefined}
              startIcon={<ImageIcon />}
              variant="contained">
                {props.buttonTitle || "View"}
            </LoadingButton>
        :
            <Tooltip title="View Image">
                <IconButton
                    sx={props.sx}
                    color="secondary"
                    disabled={loading || props.artifactId === undefined}
                    onClick={handleDialogClickOpen}
                    aria-label="View Image"
                    component="span">
                  <ImageIcon />
                </IconButton>
            </Tooltip>;
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"xl"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Image Artifact</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <ScanTimelineImageArtifactCard scanJobId={props.scanJobId} artifactId={props.artifactId} sx={{ mt: 1 }}/>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            {determineButton()}
        </div>
    );
};

export default ScanTimelineImageArtifactViewButton;