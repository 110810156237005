import {BoundingBox} from "../generated/sp/scan_runner/common_pb";
import {scaleDimension} from "./imageUtils";

export interface OverlayBox {
    id: number,
    name: string;
    text: string,
    color: string;
    top: number;
    bottom: number;
    left: number;
    right: number;
    xpath: string,
    hidden : boolean,
}

const generateOverlayBox = (id: number, name: string, text: string, xpath: string, color: string, scaleFactor: number, boundingBox: BoundingBox, width: number, height:number) : OverlayBox => {
    const coordinates = [
        boundingBox.getUpperLeft()!.getY(),
        height - boundingBox.getLowerRight()!.getY(),
        boundingBox.getUpperLeft()!.getX(),
        width - boundingBox.getLowerRight()!.getX()
    ];
    const [top, bottom, left, right] = coordinates;
    return {
        id: id,
        name: name,
        text: text,
        color: color,
        top: scaleDimension(scaleFactor, top),
        bottom: scaleDimension(scaleFactor, bottom),
        left: scaleDimension(scaleFactor, left),
        right: scaleDimension(scaleFactor, right),
        xpath: xpath,
        hidden: false,
    };
}

export { generateOverlayBox };