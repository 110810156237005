import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import {SELECTOR_ALL_ID} from "./selectorUtils";

const REGION_WORLD_UNICODE_ICON = '\u{1F310}';

export interface RegionIdName {
  id: string,
  name: string;
}

const formatRegion = (regionCode:String | undefined) => {
    if( regionCode !== undefined) {
        if(regionCode === SELECTOR_ALL_ID) {
            // display the world icon
            return `${REGION_WORLD_UNICODE_ICON} (${regionCode})`;
        }
        var splits = regionCode.split("-");
        let unicodeFlag = getUnicodeFlagIcon(splits[0]);
        return `${unicodeFlag} (${regionCode})`;
    } else {
        return "";
    }
}

const regionSelectorItems = (): RegionIdName[] => {
    return [
      {
        id: SELECTOR_ALL_ID,
        name: formatRegion(SELECTOR_ALL_ID)
      },
      {
        id: 'CA',
        name: formatRegion('CA')
      },
      {
        id: 'DE',
        name: formatRegion('DE')
      },
      {
        id: 'ES',
        name: formatRegion('ES')
      },
      {
        id: 'FR',
        name: formatRegion('FR')
      },
      {
        id: 'GB',
        name: formatRegion('GB')
      },
      {
        id: 'IT',
        name: formatRegion('IT')
      },
      {
        id: 'US',
        name: formatRegion('US')
      },
      {
        id: 'US-CA',
        name: formatRegion('US-CA')
      },
    ];
}

export { formatRegion, regionSelectorItems };
