import React, {ReactElement, FC, useState} from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, TextField, Typography, useMediaQuery } from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ElementData} from "../../generated/sp/scan_runner/common_pb";
import ViewListIcon from '@mui/icons-material/ViewList';
import SimpleTable from "../SimpleTable";

interface ElementDataViewButtonProps {
  elementData: ElementData | undefined;
  sx?: SxProps<Theme>;
}

const ElementDataViewButton: FC<ElementDataViewButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setDialogOpen(true);
      setLoading(true);
      // TODO : convert overlay boxes and prepare image to base64
      setLoading(false);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };
    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"xl"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Element Data: {props.elementData?.getId()}</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
                    { props.elementData !== undefined &&
                      <React.Fragment>
                          <Grid item xs={2}>
                            <TextField size="small" label="ID" type="string" value={props.elementData?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={2}>
                              <TextField size="small" label="Frame ID" type="string" value={props.elementData?.getFrameId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={2}>
                              <TextField size="small" label="Visible" type="bool" value={props.elementData?.getIsVisible() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={6}>
                              <TextField size="small" label="Text" type="string" value={props.elementData?.getText() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField size="small" multiline rows={2} label="HREF" type="string" value={props.elementData?.getHref() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                          <Grid item xs={12}>
                              <TextField size="small"  multiline rows={2} label="Html" type="string" value={props.elementData?.getHtml() || ''} inputProps={{ readOnly: true}} fullWidth/>
                          </Grid>
                      </React.Fragment>
                    }
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Attributes</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTable rows={props.elementData?.getAttrsMap()?.getEntryList() || []} headerNames={["Key", "Value"]} maxHeight={200}/>
                    </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="View Element Data">
                <IconButton
                    sx={props.sx}
                    color="secondary"
                    disabled={loading}
                    onClick={handleDialogClickOpen}
                    aria-label="View Element Data"
                    component="span">
                    <ViewListIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default ElementDataViewButton;