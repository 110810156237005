const encodeBase64FromArray = function encode(uint8array: Uint8Array) {
    var output = [];
    const fromCharCode = String.fromCharCode;
    for (var i = 0, length = uint8array.length; i < length; i++) {
        output.push(fromCharCode(uint8array[i]));
    }
    return encodeBase64FromStr(output.join(''));
};

const encodeBase64FromStr = function encode(input: string) {
    return btoa(input);
};

const hashCode = (str: string) =>
    str
        .split('')
        .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0)

export {
    hashCode,
    encodeBase64FromArray,
    encodeBase64FromStr,
};