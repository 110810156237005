import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import { GetPropertiesRequest, GetPropertiesResponse, Property } from '../generated/sp/scan_service/scan_service_pb';
import {PagingParameters, SortDirection, StringFilter, StringList} from '../generated/sp/service_common/common_pb';
import { Box, Grid, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import {useNavigate} from "react-router-dom";
import { enumName } from '../lib/enumUtils';
import { regionSelectorItems } from '../lib/regionUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {SELECTOR_ALL_ID} from "../lib/selectorUtils";
import {CsvHeader} from "../lib/exportCsvUtils";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";

const generateGetPropertiesRequest = (continuationToken: string | undefined, limit: number, sortField: GetPropertiesRequest.SortField, sortDirection: SortDirection, resultRegionFilter: string, propertyNameFilter: string): GetPropertiesRequest => {
  // determine filtering.
  var req = new GetPropertiesRequest();
  let filters = new GetPropertiesRequest.Filters();
  if( resultRegionFilter.length > 0 && resultRegionFilter !== SELECTOR_ALL_ID) {
    filters.addResultRegionIdFilter(resultRegionFilter);
  }
  if( propertyNameFilter !== undefined && propertyNameFilter.length > 0) {
    filters.addNameFilter(new StringFilter().setCaseInsensitive(true).setContains(new StringList().addValues(propertyNameFilter)));
  }
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetPropertiesRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchProperties = async (scanService: ScanServiceClient, request: GetPropertiesRequest): Promise<GetPropertiesResponse> => {
  return new Promise<GetPropertiesResponse>((resolve, reject) => {
    scanService.getProperties(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

const getInconsistencyCell = (property: Property | undefined) => {
  let propertyStats = property?.getPropertyStats();
  if(propertyStats === undefined && property?.getMaxScanJobProcessTime() === undefined) {
    return <Box sx={{ color: "info.main" }}>Processing</Box>;
  } else if(propertyStats === undefined) {
    return <Box sx={{ color: "error.main" }}>Error</Box>;
  } else {
    // determine color
    let color = (propertyStats?.getTotalDiscrepancyCount() === 0) ? "success.main" : "error.main";
    return <Box sx={{ color: color }}>({propertyStats?.getTotalDiscrepancyCount()}/{propertyStats?.getTotalRuleResults()})</Box>;
  }
}

interface PropertyTableProps {
  maxHeight: number;
  forceRefresh?: number;
}

const PropertyInfinityTable: FC<PropertyTableProps> = (props): ReactElement => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [resultRegionFilter, setResultRegionFilter] = useState<string>(SELECTOR_ALL_ID);
  const [propertyNameFilter, setPropertyNameFilter] = useState<string>("");
  const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
  const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
  const [sortField, setSortField] = React.useState<GetPropertiesRequest.SortField>(GetPropertiesRequest.SortField.SORT_FIELD_MAX_SCAN_JOB_PROCESS_TIME);
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_DESC);
  const [limit] = useState<number>(50);
  const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

  // infinity scroll state
  const tableEl = useRef<any>(null);
  const [loading, setLoading] = useState(true)
  const [distanceBottom, setDistanceBottom] = useState(0)

  const navigate = useNavigate();
  const navigateToProperty = (propertyId: String) => {
    navigate(`/properties/${propertyId}`);
  };

  useEffect(() => {
    (async () => {
      setProperties([]);
      setRefreshToggle(true);
    })();
  }, []);

  useUpdateEffect(() => {
    (() => {
      console.log("Marking table as stale...")
      handleRefresh();
    })();
  }, [props.forceRefresh]);

  useUpdateEffect(() => {
    (async () => {
      console.log("Loading property list data...");
      setLoading(true);
      const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
      const req = generateGetPropertiesRequest(currentContinuationToken, limit, sortField, sortDirection, resultRegionFilter, propertyNameFilter);
      const propertiesResponse = await fetchProperties(scanService, req);
      let continuationToken = propertiesResponse.getContinuationToken().length > 0 ? propertiesResponse.getContinuationToken() : undefined;
      setNextContinuationToken(continuationToken);
      // to support scrolling, we need to append to whatever is currently loaded
      setProperties(old => [...old, ...propertiesResponse.getPropertiesList()]);
      setLoading(false);
    })();
  }, [currentContinuationToken, limit, resultRegionFilter, sortDirection, sortField, refreshToggle]);

  const exportCsvHeaders = (): CsvHeader[] => {
    return [
      { label: "Property Name", key: "propertyName" },
      { label: "Total Discrepancy Count", key: "totalDiscrepancyCount" },
      { label: "Property Type", key: "propertyType" },
      { label: "Max Scan Job Process Time", key: "maxScanJobProcessTime" },
    ]
  }

  interface CsvExportData {
    propertyName: string;
    totalDiscrepancyCount: string;
    propertyType: string;
    maxScanJobProcessTime: string;
  }

  const exportCsvData = (): CsvExportData[] => {
    return properties.map((property) => {
      return {
        propertyName: property.getName(),
        totalDiscrepancyCount: (property.getPropertyStats()?.getTotalDiscrepancyCount() || "0") + "/" + (property.getPropertyStats()?.getTotalRuleResults() || ""),
        propertyType: enumName(Property.PropertyType, property.getPropertyType(), false),
        maxScanJobProcessTime: property.getMaxScanJobProcessTime()?.toDate().toLocaleString() || ''
      };
    });
  }

  const scrollListener = useCallback(() => {
    const tableRef = tableEl.current
    if (!tableRef) throw Error("tableRef is not assigned");
    let bottom = tableRef.scrollHeight - tableRef.clientHeight
    //TODO: maybe set Distance bottom everytime.
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 20))
    }
    let hasMore = nextContinuationToken !== undefined;
    if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
      //trigger data reload by swapping currentContinuationToken with nextContinuationToken
      setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
    }
  }, [loading, distanceBottom, nextContinuationToken])

  useLayoutEffect(() => {
    const tableRef = tableEl.current
    if (!tableRef) throw Error("tableRef is not assigned");
    tableRef.addEventListener('scroll', scrollListener)
    return () => {
      tableRef.removeEventListener('scroll', scrollListener)
    }
  }, [scrollListener])

  const startFreshSearch = () => {
    setProperties([]); // wipe out data such that we dont keep appending to existing list.
    setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
  }

  const handleResultRegionFilterChange = (event: SelectChangeEvent) => {
    let revised = event.target.value;
    if ( revised !== resultRegionFilter) {
      startFreshSearch();
      setResultRegionFilter(revised);
    }
  };

  const handlePropertyNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPropertyNameFilter(event.target.value);
  };

  const handlePropertyNameFilterKeyChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if( event.key === 'Enter' ){
      handleRefresh();
    }
  };

  const handleSortRequest = (sortField: GetPropertiesRequest.SortField) => {
    setSortField(sortField);
    setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
    startFreshSearch();
  };

  const handleRefresh = () => {
    startFreshSearch();
    setRefreshToggle(prev => !prev);
  };

  return (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Filter Properties</Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="region">Result Region</InputLabel>
            <Select size="small" labelId="region" id="region" value={resultRegionFilter} label="Result Region" onChange={handleResultRegionFilterChange}>
              {regionSelectorItems().map((region) => (
                <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField size="small" label="Search by Property Name" type="string" value={propertyNameFilter || ''} onChange={x=> handlePropertyNameFilterChange(x)}   onKeyPress={x=> handlePropertyNameFilterKeyChange(x)} fullWidth/>
        </Grid>
        <Grid item xs={4}>
          <LoadingButton
            size="small"
            color="secondary"
            onClick={handleRefresh}
            loading={loading}
            loadingPosition="start"
            startIcon={<RefreshIcon />}
            variant="contained"
          >
            Refresh
          </LoadingButton>
        </Grid>
        <Grid item xs={10}>
          <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {properties.length} properties</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" alignItems="right" justifyContent="right">
            <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"Property-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
              <TableArrowDownIcon sx={{color: "secondary"}}/>
            </CSVLink>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
        </Grid>
        <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
      </Grid>
      <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
        <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Properties">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left" onClick={() => handleSortRequest(GetPropertiesRequest.SortField.SORT_FIELD_NAME)}>
                <TableSortLabel active={sortField === GetPropertiesRequest.SortField.SORT_FIELD_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Name</TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" onClick={() => handleSortRequest(GetPropertiesRequest.SortField.SORT_FIELD_TOTAL_DISCREPANCY_COUNT)}>
                <TableSortLabel active={sortField === GetPropertiesRequest.SortField.SORT_FIELD_TOTAL_DISCREPANCY_COUNT} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Inconsistency</TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" onClick={() => handleSortRequest(GetPropertiesRequest.SortField.SORT_FIELD_PROPERTY_TYPE)}>
                <TableSortLabel active={sortField === GetPropertiesRequest.SortField.SORT_FIELD_PROPERTY_TYPE} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Type</TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="right" onClick={() => handleSortRequest(GetPropertiesRequest.SortField.SORT_FIELD_MAX_SCAN_JOB_PROCESS_TIME)}>
                <TableSortLabel active={sortField === GetPropertiesRequest.SortField.SORT_FIELD_MAX_SCAN_JOB_PROCESS_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Last Processed</TableSortLabel>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {properties.map((row) => (
              <StyledTableRow hover key={row.getId()} onClick={() => navigateToProperty(`${row.getId()}`)} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                <StyledTableCell align="left">{row.getName()}</StyledTableCell>
                <StyledTableCell align="center">{getInconsistencyCell(row)}</StyledTableCell>
                <StyledTableCell align="center">{enumName(Property.PropertyType, row.getPropertyType(), false)}</StyledTableCell>
                <StyledTableCell align="right">{row.getMaxScanJobProcessTime()?.toDate().toLocaleString()}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PropertyInfinityTable;