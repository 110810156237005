import React, {FC, ReactElement, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid, IconButton, LinearProgress,
    Paper,
    Table, TableBody,
    TableContainer,
    TableHead, TablePagination, TableSortLabel, Tooltip,
    Typography
} from '@mui/material';
import {generateAuthHeader} from "../../lib/authorizationUtils";
import {BffServiceClient} from "../../generated/sp/bff_service/bff_service_grpc_web_pb";
import {
    BrowserExtension,
    GetBrowserExtensionByIdRequest,
    GetBrowserExtensionByIdResponse,
    GetBrowserExtensionsRequest,
    GetBrowserExtensionsResponse,
} from "../../generated/sp/bff_service/bff_service_pb";
import DownloadIcon from '@mui/icons-material/Download';
import {downloadByteArray} from "../../lib/downloadUtils";
import {StyledTableCell, StyledTableRow} from "../../lib/tableUtils";
import {Order, sortNumber, sortString, TableHeader} from "../../lib/sortUtils";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

const HEADER_FILENAME = "Filename";
const HEADER_FILESIZE = "Size";
const HEADER_MODIFIED_DATE = "Date";

const headers: TableHeader[] = [
    {headerName: HEADER_FILENAME, align: 'left'},
    {headerName: HEADER_FILESIZE, align: 'center'},
    {headerName: HEADER_MODIFIED_DATE, align: 'center'}
]

const fetchBrowserExtensions = async (bffService: BffServiceClient): Promise<GetBrowserExtensionsResponse> => {
    const request = new GetBrowserExtensionsRequest();
    return new Promise<GetBrowserExtensionsResponse>((resolve, reject) => {
        bffService.getBrowserExtensions(request, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response)
        });
    });
}

const fetchBrowserExtensionById = async (bffService: BffServiceClient, filename: string, withContent: boolean): Promise<GetBrowserExtensionByIdResponse> => {
    const request = new GetBrowserExtensionByIdRequest();
    request.setFilename(filename);
    if (withContent) {
        request.addFields(BrowserExtension.BrowserExtensionField.BROWSER_EXTENSION_FIELD_DATA)
    }
    return new Promise<GetBrowserExtensionByIdResponse>((resolve, reject) => {
        bffService.getBrowserExtensionById(request, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response)
        });
    });
}

interface ScanRunnerChromeExtensionsCardProps {
    maxHeight: number;
    sx?: SxProps<Theme>;
}

const ScanRunnerChromeExtensionsCard: FC<ScanRunnerChromeExtensionsCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<string>(HEADER_MODIFIED_DATE);
    const [browserExtensions, setBrowserExtensions] = useState<BrowserExtension[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const bffService = new BffServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const extensions = (await fetchBrowserExtensions(bffService)).getBrowserExtensionsList();
            console.log(`Found ${extensions.length} browser extensions`);
            setBrowserExtensions(extensions)
            setLoading(false);
        })();
    }, []);

    const sortRows = (rows: BrowserExtension[], orderBy: string, order: Order): BrowserExtension[] => {
        if (orderBy === HEADER_FILENAME) {
            rows.sort(function (a, b) {
                return sortString(a.getFilename(), b.getFilename(), order);
            });
        } else if (orderBy === HEADER_FILESIZE) {
            rows.sort(function (a, b) {
                return sortNumber(a.getFileSize(), b.getFileSize(), order);
            });
        } else if (orderBy === HEADER_MODIFIED_DATE) {
            rows.sort(function (a, b) {
                return sortNumber(a.getLastModified()!.getSeconds(), b.getLastModified()!.getSeconds(), order);
            });
        }
        return rows;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, header: string) => {
        const isAsc = orderBy === header && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header);
    };

    const handleDownload = async (fileName: string) => {
        console.log("Handling download...");
        setLoading(true);
        const bffService = new BffServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const response = await fetchBrowserExtensionById(bffService, fileName, true);
        if (response.hasBrowserExtension()) {
            let browserExtension = response.getBrowserExtension();
            console.log(`Fetch latest browser extension: ${browserExtension?.getFilename()} with bytes: ${browserExtension?.getFileSize()}`);
            let body: Uint8Array = browserExtension?.getData() as Uint8Array;
            downloadByteArray(body, browserExtension?.getFilename()!, "application.zip")
        }
        setLoading(false);
    };

    const bytesToMegaBytes = (bytes: number): string => {
        return `${(bytes / (1024 ** 2)).toFixed(2)} MB`;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="left">
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 600}}
                                    variant="subtitle1" component="div">Scan Runner Chrome Extensions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> :
                            <Box sx={{height: 10}}>&nbsp;</Box>}
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }}>
                    <Table stickyHeader size="small" aria-label="Scan Runner Extension">
                        <TableHead>
                            <StyledTableRow>
                                {headers.map(tableHeader => (
                                    <StyledTableCell key={tableHeader.headerName} align={tableHeader.align}>
                                        <TableSortLabel active={orderBy === tableHeader.headerName}
                                                        direction={orderBy === tableHeader.headerName ? order : 'asc'}
                                                        onClick={e => handleRequestSort(e, tableHeader.headerName)}
                                        >
                                            {tableHeader.headerName}
                                        </TableSortLabel>
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {sortRows(browserExtensions, orderBy, order).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                                <StyledTableRow hover key={row.getFilename()} onClick={() => handleDownload(row.getFilename())} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                                    <StyledTableCell align="left">
                                        <Tooltip title="Download file">
                                            <IconButton
                                                size="small"
                                                color="secondary">
                                                <DownloadIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        &nbsp;{row.getFilename()}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{bytesToMegaBytes(row.getFileSize())}</StyledTableCell>
                                    <StyledTableCell
                                        align="center">{row.getLastModified()?.toDate().toLocaleString()}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={browserExtensions === undefined ? 0 : browserExtensions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CardContent>
        </Card>
    );
};

export default ScanRunnerChromeExtensionsCard;