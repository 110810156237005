export type Order = 'asc' | 'desc';

export type Align = 'right' | 'left' | 'center';

export interface TableHeader {
  headerName: string;
  align: Align;
  hidden?: boolean;
}

const sortString = (str1: string, str2: string, order: Order): number => {
  if(order === 'asc') {
    if (str1 < str2) {
      return -1;
    }
    if (str1 > str2) {
      return 1;
    }
    // names must be equal
    return 0;
  } else {
    if (str1 < str2) {
      return 1;
    }
    if (str1 > str2) {
      return -1;
    }
    // names must be equal
    return 0;
  }
}

const sortNumber = (num1: number, num2: number, order: Order): number => {
  if(order === 'asc') {
    return num1 - num2;
  } else {
    return num2 - num1;
  }
}

export { sortString, sortNumber };
