import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid, LinearProgress, Paper, Table, TableBody, TableContainer, TableHead,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {LocalStorageDump} from "../../generated/sp/scan_runner/common_pb";
import {fetchScanArtifact} from "../../lib/scanServiceUtils";
import {StyledTableCell, StyledTableRow} from "../../lib/tableUtils";

interface ScanTimelineLocalStorageDump {
    name: string;
    localStorageDump: LocalStorageDump | undefined,
}

interface ScanTimelineLocalStorageDumpArtifactCardProps {
    scanJobId: string | undefined;
    artifactId: string | undefined;
    sx?: SxProps<Theme>;
}
const ScanTimelineLocalStorageDumpArtifactCard: FC<ScanTimelineLocalStorageDumpArtifactCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanTimelineLocalStorageDump, setScanTimelineLocalStorageDump] = useState<ScanTimelineLocalStorageDump  | undefined>(undefined);


    const reloadScanJobArtifact = useCallback(async () => {
        console.log(`Loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanArtifact = await fetchScanArtifact(scanService, props.scanJobId!, props.artifactId!);
        const contents : Uint8Array = scanArtifact!.getContent_asU8();
        const localStorageDump = LocalStorageDump.deserializeBinary(contents);
        const data: ScanTimelineLocalStorageDump = {
            name: scanArtifact.getName(),
            localStorageDump
        };
        setScanTimelineLocalStorageDump(data);
        console.log(`Finished loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(false);
    }, [props.scanJobId, props.artifactId]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined && props.artifactId !== undefined) {
                await reloadScanJobArtifact();
            }
        })();
    }, [props.scanJobId, props.artifactId, reloadScanJobArtifact]);

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1}}  style={{ fontWeight: 600 }} variant="subtitle1" component="div">{scanTimelineLocalStorageDump?.name} for ScanJobId: [{props.scanJobId}]</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
                            <Table stickyHeader size="small" aria-label="events">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="left">Domain</StyledTableCell>
                                        <StyledTableCell align="left">Name</StyledTableCell>
                                        <StyledTableCell align="left">Value</StyledTableCell>
                                        <StyledTableCell align="left">Path</StyledTableCell>
                                        <StyledTableCell align="left">Expires</StyledTableCell>
                                        <StyledTableCell align="left">HTTP Only</StyledTableCell>
                                        <StyledTableCell align="left">Secure</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {scanTimelineLocalStorageDump?.localStorageDump?.getDataList().map((row) => (
                                      <StyledTableRow hover key={row.getDomain + row.getName() + Math.random()}>
                                          <StyledTableCell align="left">{row.getDomain()}</StyledTableCell>
                                          <StyledTableCell align="left">{row.getName()}</StyledTableCell>
                                          <StyledTableCell align="left">{row.getValue()}</StyledTableCell>
                                          <StyledTableCell align="left">{row.getPath()}</StyledTableCell>
                                          <StyledTableCell align="left">{row.getExpires()}</StyledTableCell>
                                          <StyledTableCell align="left">{row.getHttpOnly().toString()}</StyledTableCell>
                                          <StyledTableCell align="left">{row.getSecure().toString()}</StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanTimelineLocalStorageDumpArtifactCard;