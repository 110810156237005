import React, {ReactElement, FC, useState} from "react";
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import { ScanServiceInternalClient } from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {
    RebuildPropertySnapshotByIdRequest
} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import { generateAuthHeader } from '../../lib/authorizationUtils';
import ReplayIcon from '@mui/icons-material/Replay';
import {SxProps} from "@mui/system";

interface RebuildPropertySnapshotButtonProps {
  propertySnapshotId: string | undefined;
  rebuildCompleteFunction: () => void;
  sx?: SxProps<Theme>;
}

const RebuildPropertySnapshotButton: FC<RebuildPropertySnapshotButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [processPropertyDialogOpen, setProcessPropertyDialogOpen] = useState(false);
    const handleRebuildPropertyDialogClickOpen = () => {
      setProcessPropertyDialogOpen(true);
    };

    const handleRebuildPropertySnapshotDialogClose = (shouldProcess: boolean) => () => {
      if ( shouldProcess ) {
        setLoading(true);
        console.log(`Rebuilding propertySnapshot with id=${props.propertySnapshotId}`);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(rebuildPropertySnapshot(scanServiceInternal, props.propertySnapshotId!)).then(async function(propertySnapshotId) {
          console.log(`Rebuilt propertySnapshotId=${props.propertySnapshotId} with propertySnapshotId=${propertySnapshotId}`);
          props.rebuildCompleteFunction();
          setLoading(false);
        }, function(value) {
          // not called
        });
      }
      setProcessPropertyDialogOpen(false);
    };

    const rebuildPropertySnapshot = async (scanServiceInternal: ScanServiceInternalClient, propertySnapshotId: string): Promise<string> => {
      var req = new RebuildPropertySnapshotByIdRequest();
      req.setPropertySnapshotId(propertySnapshotId);
      return new Promise<string>((resolve, reject) => {
        scanServiceInternal.rebuildPropertySnapshotById(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response.getPropertySnapshotId()!)
        });
      });
    }

    return (
        <div>
           <Dialog fullScreen={fullScreen} open={processPropertyDialogOpen} onClose={handleRebuildPropertySnapshotDialogClose(false)} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">{"Process Property"}</DialogTitle>
              <DialogContent>
                <DialogContentText> Are you sure you want to rebuild this property snapshot?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button size="small" variant="contained" autoFocus onClick={handleRebuildPropertySnapshotDialogClose(false)}>Cancel</Button>
                <Button size="small" variant="contained" onClick={handleRebuildPropertySnapshotDialogClose(true)} autoFocus color="secondary">Rebuild Snapshot</Button>
              </DialogActions>
            </Dialog>
            <LoadingButton
              sx={props.sx}
              size="small"
              color="secondary"
              onClick={handleRebuildPropertyDialogClickOpen}
              loading={loading}
              loadingPosition="start"
              startIcon={<ReplayIcon />}
              variant="contained">
              Rebuild
            </LoadingButton>
        </div>
    );
};

export default RebuildPropertySnapshotButton;