import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import { Box, Grid, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {GetScanRulesRequest, GetScanRulesResponse, ScanRule} from '../generated/sp/scan_service/scan_service_pb';
import { PagingParameters, SortDirection } from '../generated/sp/service_common/common_pb';
import {useNavigate} from "react-router-dom";
import { enumName } from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {getProductionStatusColor} from "../lib/statusUtils";
import {CsvHeader} from "../lib/exportCsvUtils";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";

const generateGetScanRulesRequest = (continuationToken: string | undefined, limit: number, sortField: GetScanRulesRequest.SortField, sortDirection: SortDirection,  scanRuleIdFilter: Array<string> | undefined, productionStatusFilter: ScanRule.ProductionStatus, categoryFilter: ScanRule.Category): GetScanRulesRequest => {
  var req = new GetScanRulesRequest();
  let filters = new GetScanRulesRequest.Filters();
  if ( scanRuleIdFilter !== undefined && scanRuleIdFilter.length > 0) {
    if( scanRuleIdFilter.length > 0) {
      filters.setIdFilterList(scanRuleIdFilter);
    }
  }
  if ( productionStatusFilter > 0) {
    filters.addProductionStatusFilter(productionStatusFilter);
  }
  if ( categoryFilter > 0) {
    filters.addCategoryFilter(categoryFilter);
  }
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetScanRulesRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchScanRules = async (scanService: ScanServiceClient, request: GetScanRulesRequest): Promise<GetScanRulesResponse> => {
  return new Promise<GetScanRulesResponse>((resolve, reject) => {
    scanService.getScanRules(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface ScanRuleInfinityTableProps {
  maxHeight: number;
  showFilterUi: boolean;
  embeddedModeScanRuleIds?: string[] | undefined; // if defined, the table will render without infinity mode
  forceRefresh?: number;
}

const ScanRuleInfinityTable: FC<ScanRuleInfinityTableProps> = (props): ReactElement => {
    const [scanRules, setScanRules] = useState<ScanRule[]>([]);
    const [productionStatusFilter, setProductionStatusFilter] = useState<ScanRule.ProductionStatus>(ScanRule.ProductionStatus.PRODUCTION_STATUS_UNSET);
    const [categoryFilter, setCategoryFilter] = useState<ScanRule.Category>(ScanRule.Category.CATEGORY_UNSET);
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetScanRulesRequest.SortField>(GetScanRulesRequest.SortField.SORT_FIELD_SHORT_NAME);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_ASC);
    const [limit] = useState<number>(50);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToScanRule = (scanRuleId: String) => {
        navigate(`/scanRules/${scanRuleId}`);
    };

    useEffect(() => {
        (async () => {
            setScanRules([]);
            setRefreshToggle(true);
        })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading scan rule list data, embeddedModeScanRuleIds=[${props.embeddedModeScanRuleIds}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetScanRulesRequest(currentContinuationToken, limit, sortField, sortDirection, props.embeddedModeScanRuleIds, productionStatusFilter, categoryFilter);
        const scanRulesResponse = await fetchScanRules(scanService, req);
        let continuationToken = scanRulesResponse.getContinuationToken().length > 0 ? scanRulesResponse.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        if (props.embeddedModeScanRuleIds !== undefined) {
          // always set the entire list, not infinity mode
          setScanRules(scanRulesResponse.getScanRulesList());
        } else {
          // to support scrolling, we need to append to whatever is currently loaded, infinity mode
          setScanRules(old => [...old, ...scanRulesResponse.getScanRulesList()]);
        }
        setLoading(false);
      })();
    }, [props.embeddedModeScanRuleIds, currentContinuationToken, limit, productionStatusFilter, categoryFilter, sortDirection, sortField, refreshToggle]);

    const exportCsvHeaders = (): CsvHeader[] => {
      return [
        { label: "Id", key: "shortName" },
        { label: "Rule Name", key: "name" },
        { label: "Status", key: "productionStatus" },
        { label: "Compliance", key: "complianceStandards" },
        { label: "Created", key: "created" },
      ]
    }

    interface CsvExportData {
      shortName: string;
      name: string;
      productionStatus: string;
      complianceStandards: string
      created: string;
    }

    const exportCsvData = (): CsvExportData[] => {
      return scanRules.map((scanRule) => {
        return {
          shortName: scanRule.getShortName(),
          name: scanRule.getName(),
          productionStatus: enumName(ScanRule.ProductionStatus, scanRule.getProductionStatus(), true),
          complianceStandards: scanRule.getComplianceStandardsList().join(','),
          created: scanRule.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''
        };
      });
    }

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
        setScanRules([]); // wipe out data such that we don't keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token, so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token, so we start with a fresh paging session
    }

    const handleProductionStatusFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== productionStatusFilter) {
            startFreshSearch();
            setProductionStatusFilter(revised);
        }
    };

    const handleCategoryFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== categoryFilter) {
            startFreshSearch();
            setCategoryFilter(revised);
        }
    };

    const handleSortRequest = (sortField: GetScanRulesRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
      <div>
           { props.showFilterUi &&
             <Grid container spacing={1} alignItems="center">
               <Grid item xs={12}>
                 <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter Scan Rules</Typography>
               </Grid>
               <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="scanType">Production Status</InputLabel>
                    <Select size="small" labelId="ProductionStatus" id="ProductionStatus" value={productionStatusFilter.toString()} label="ProductionStatus" onChange={handleProductionStatusFilterChange}>
                      <MenuItem value={ScanRule.ProductionStatus.PRODUCTION_STATUS_UNSET}>All</MenuItem>
                      <MenuItem value={ScanRule.ProductionStatus.PRODUCTION_STATUS_ALPHA}>Alpha</MenuItem>
                      <MenuItem value={ScanRule.ProductionStatus.PRODUCTION_STATUS_BETA}>Beta</MenuItem>
                      <MenuItem value={ScanRule.ProductionStatus.PRODUCTION_STATUS_GA}>GA</MenuItem>
                    </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="scanType">Category</InputLabel>
                    <Select size="small" labelId="Category" id="Category" value={categoryFilter.toString()} label="Category" onChange={handleCategoryFilterChange}>
                      <MenuItem value={ScanRule.Category.CATEGORY_UNSET}>All</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_OPT_OUT}>Opt Out</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_PRIVACY_POLICY}>Privacy Policy</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_CONSENT_ACTION}>Consent Action</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_CONSENT_NOTICE}>Consent Notice</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_INFORMATION_STORAGE_AND_ACCESS}>Information Storage</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_DATA_SUBJECT_REQUEST}>Data Subject Request</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_IAB_TRANSPARENCY_AND_CONSENT_FRAMEWORK}>IAB Transparency</MenuItem>
                      <MenuItem value={ScanRule.Category.CATEGORY_IAB_US_PRIVACY_FRAMEWORK}>IAB US Privacy</MenuItem>
                    </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                        <LoadingButton
                          size="small"
                          color="secondary"
                          onClick={handleRefresh}
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<RefreshIcon />}
                          variant="contained"
                        >
                          Refresh
                        </LoadingButton>
               </Grid>
               <Grid item xs={10}>
                 <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {scanRules.length} Scan Rules</Typography>
               </Grid>
               <Grid item xs={2}>
                   <Box display="flex" alignItems="right" justifyContent="right">
                       <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"ScanRules-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                           <TableArrowDownIcon sx={{color: "secondary"}}/>
                       </CSVLink>
                   </Box>
               </Grid>
               <Grid item xs={12}>
                 {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
               </Grid>
               <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
            </Grid>
          }
          <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader size="small" aria-label="Scan Rules">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center" width="5%" onClick={() => handleSortRequest(GetScanRulesRequest.SortField.SORT_FIELD_SHORT_NAME)}>
                    <TableSortLabel active={sortField === GetScanRulesRequest.SortField.SORT_FIELD_SHORT_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>ID</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left" width="50%">Rule Name</StyledTableCell>
                  <StyledTableCell align="center" width="10%">Status</StyledTableCell>
                  <StyledTableCell align="center" width="10%">Compliance</StyledTableCell>
                  <StyledTableCell align="right" width="25%" onClick={() => handleSortRequest(GetScanRulesRequest.SortField.SORT_FIELD_CREATED_TIME)}>
                    <TableSortLabel active={sortField === GetScanRulesRequest.SortField.SORT_FIELD_CREATED_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Created</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {scanRules?.map((row) => (
                  <StyledTableRow hover key={row.getId()} onClick={() => navigateToScanRule(`${row.getId()}`)} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                    <StyledTableCell align="center">{row.getShortName()}</StyledTableCell>
                    <StyledTableCell align="left">{row.getName()}</StyledTableCell>
                    <StyledTableCell align="center"><Box sx={{ color: getProductionStatusColor(row.getProductionStatus()) }}>{enumName(ScanRule.ProductionStatus, row.getProductionStatus(), true)}</Box></StyledTableCell>
                    <StyledTableCell align="center">{row.getComplianceStandardsList().join(',')}</StyledTableCell>
                    <StyledTableCell align="right">{row.getAuditData()?.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    );
}

export default ScanRuleInfinityTable;