import React, {ReactElement, FC, useCallback, useState, useEffect} from "react";
import {
    Box,
    Grid, LinearProgress, Table, TableBody, TableContainer, TableHead,
} from '@mui/material';
import {formatGatingType} from "../lib/gatingUtils";
import Paper from "@mui/material/Paper";
import {StyledTableCell, StyledTableRow} from "../lib/tableUtils";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {ScanJob} from "../generated/sp/scan_service/scan_service_pb";
import {getScanJobById} from "../lib/scanServiceUtils";

interface ScanJobGatingLogTableProps {
    maxHeight: number;
    scanJobId: string | undefined;
}

const ScanJobGatingLogTable: FC<ScanJobGatingLogTableProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [currentGatingType, setCurrentGatingType] = useState<ScanJob.ScanJobGating.GatingType | undefined>(undefined);
    const [gatingLog, setGatingLog] = useState<ScanJob.ScanJobGating[] | undefined>(undefined);

    const reloadScanJob = useCallback(async () => {
        if (props.scanJobId !== undefined) {
            console.log(`Loading gating data for scanJobId=[${props.scanJobId!}]`);
            setLoading(true);
            const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const scanJob = await getScanJobById(scanService, props.scanJobId!, [ScanJob.ScanJobField.SCAN_JOB_FIELD_GATING_LOG]);
            console.log(`Finished reloading gating data for scanJobId=${props.scanJobId}`);
            if (scanJob !== undefined) {
                setGatingLog(scanJob.getGatingLogList());
                setCurrentGatingType(scanJob.getGatingType());
            }
            setLoading(false);
        }
    }, [props.scanJobId]);

    useEffect(() => {
        (async () => {
            if ( props.scanJobId !== undefined) {
                await reloadScanJob();
            }
        })();
    }, [props.scanJobId, reloadScanJob]);

    return (
      <div>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
            </Grid>
            <Grid item xs={12}>
                { gatingLog !== undefined &&
                  <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}}>
                      <Table stickyHeader size="small" aria-label="Vendor List">
                          <TableHead>
                              <StyledTableRow>
                                  <StyledTableCell align="left">Created</StyledTableCell>
                                  <StyledTableCell align="left">Gating Type</StyledTableCell>
                                  <StyledTableCell align="left">Created By</StyledTableCell>
                                  <StyledTableCell align="left">Remark</StyledTableCell>
                              </StyledTableRow>
                          </TableHead>
                          <TableBody>
                              {gatingLog.map((row) => (
                                <StyledTableRow hover key={row.getId()}>
                                    <StyledTableCell align="left">{row.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                                    <StyledTableCell align="left">{formatGatingType(currentGatingType)}</StyledTableCell>
                                    <StyledTableCell align="left">{row.getCreatedBy()}</StyledTableCell>
                                    <StyledTableCell align="left">{row.getRemark()}</StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
                }
            </Grid>
        </Grid>
      </div>
    );
};

export default ScanJobGatingLogTable;