import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {Box, Card, CardContent, Grid, LinearProgress, TextField, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import {ScanServiceInternalClient} from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {
    DeploymentSettings, GetDeploymentSettingsRequest, UpsertDeploymentSettingsRequest
} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {generateAuthHeader} from '../../lib/authorizationUtils';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface ScanRunnerDeploymentSettingsCardProps {
    sx?: SxProps<Theme>;
}

const ScanRunnerDeploymentSettingsCard: FC<ScanRunnerDeploymentSettingsCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [successText, setSuccessText] = useState<string | undefined>(undefined);
    const [inputText, setInputText] = useState<string>("");
    const [currentDeploymentSettings, setCurrentDeploymentSettings] = useState<DeploymentSettings | undefined>(undefined);

    // 👇️ get memoized callback
    const refreshDeploymentSettings = useCallback(() => {
        setLoading(true);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(getDeploymentSettings(scanServiceInternal)).then(function (deploymentSettings) {
            console.log(`Found deploymentSettings=${deploymentSettings}`);
            setCurrentDeploymentSettings(deploymentSettings);
            setInputText(deploymentSettings?.getScanRunnerImageName() || "");
            setLoading(false);
        }, function (rejected) {
            setErrorText(rejected.toString());
            setSuccessText(undefined);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        (() => {
            refreshDeploymentSettings();
        })();
    }, [refreshDeploymentSettings]);

    const handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let val = event.target.value;
        setInputText(val);
    }

    const handleInputTextKeyChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleUpdateScanRunnerImageName();
        }
    };

    const getDeploymentSettings = async (scanServiceInternal: ScanServiceInternalClient): Promise<DeploymentSettings | undefined> => {
        var req = new GetDeploymentSettingsRequest();
        return new Promise<DeploymentSettings | undefined>((resolve, reject) => {
            scanServiceInternal.getDeploymentSettings(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getDeploymentSettings())
            });
        });
    }

    const updateScanRunnerImageName = async (scanServiceInternal: ScanServiceInternalClient, scanRunnerImageName: string): Promise<number> => {
        var req = new UpsertDeploymentSettingsRequest();
        req.setScanRunnerImageName(scanRunnerImageName)
        return new Promise<number>((resolve, reject) => {
            scanServiceInternal.upsertDeploymentSettings(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getRecordsUpdated())
            });
        });
    }

    const handleUpdateScanRunnerImageName = () => {
        setLoading(true);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(updateScanRunnerImageName(scanServiceInternal, inputText)).then(function (recordsUpdated) {
            console.log(`Records updated=${recordsUpdated}`);
            if (recordsUpdated > 0) {
                setSuccessText('Successfully updated');
                setErrorText(undefined);
                refreshDeploymentSettings();
            } else {
                setSuccessText(undefined);
                setErrorText("Failed to update!");
            }
            setLoading(false);
        }, function (rejected) {
            setErrorText(rejected.toString());
            setSuccessText(undefined);
            setLoading(false);
        });
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> :
                            <Box sx={{height: 10}}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 600}}
                                    variant="subtitle1" component="div">Update Scan Runner Deployment
                            Version</Typography>
                    </Grid>
                    {errorText !== undefined &&
                        <Grid item xs={12}>
                            <Typography sx={{color: "red"}} component="div">{errorText}</Typography>
                        </Grid>
                    }
                    {successText !== undefined &&
                        <Grid item xs={12}>
                            <Typography sx={{color: "blue"}} component="div">{successText}</Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField size="small" label="Scan Runner Image" type="string" value={inputText || ''}
                                   onChange={x => handleInputTextChange(x)}
                                   onKeyPress={x => handleInputTextKeyChange(x)}
                                   fullWidth/>
                    </Grid>
                    <Grid item xs={2}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleUpdateScanRunnerImageName}
                            loading={loading}
                            disabled={(errorText !== undefined && errorText.length > 0) || (currentDeploymentSettings?.getScanRunnerImageName() === inputText)}
                            loadingPosition="start"
                            startIcon={<UpgradeIcon/>}
                            variant="contained"
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanRunnerDeploymentSettingsCard;