import React, {ReactElement, FC} from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./GoogleLoginButton";
import { SourcePointTokens } from '../../lib/authorizationUtils';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || "";

interface AuthorizeProps {
	setSourcePointTokens: React.Dispatch<React.SetStateAction<SourcePointTokens | undefined>>;
}

const Authorize: FC<AuthorizeProps> = (props): ReactElement => {
	return (
		<div id="authorizeButton">
			<GoogleOAuthProvider clientId={CLIENT_ID}>
				<GoogleLoginButton setSourcePointTokens={props.setSourcePointTokens}/>
			</GoogleOAuthProvider>
		</div>
	);
};

export default Authorize;