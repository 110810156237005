import React, {ReactElement, FC, useState} from "react";
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import { ScanServiceInternalClient } from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {
    ProcessScanArtifactsRequest
} from '../../generated/sp/scan_service/scan_service_internal_pb';
import { ScanJob } from '../../generated/sp/scan_service/scan_service_pb';
import {Theme, useTheme} from '@mui/material/styles';
import { generateAuthHeader } from '../../lib/authorizationUtils';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {SxProps} from "@mui/system";

interface ProcessScanJobArtifactsButtonProps {
  scanJobId: string | undefined;
  scanJobStatus: ScanJob.ScanJobStatus | undefined;
  sx?: SxProps<Theme>;
}


const ProcessScanJobArtifactsButton: FC<ProcessScanJobArtifactsButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [processScanJobArtifactsDialogOpen, setProcessScanJobArtifactsDialogOpen] = useState(false);
    const handleProcessScanJobArtifactsDialogClickOpen = () => {
        setProcessScanJobArtifactsDialogOpen(true);
    };
    const handleProcessScanJobArtifactsDialogClose = (shouldProcess: boolean) => () => {
      if ( shouldProcess ) {
        setLoading(true);
        console.log("Processing Scan Job Artifacts for scan_job with id=" + props.scanJobId);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(processScanJobArtifacts(scanServiceInternal, props.scanJobId!)).then(function(artifactsProcessed) {
          console.log("Processed Artifacts for scanJobId=" + props.scanJobId + " with artifactsProcessed=" + artifactsProcessed);
          setLoading(false);
        }, function(value) {
          // not called
        });
        //TODO: refresh the page since status may have updated.
      }
        setProcessScanJobArtifactsDialogOpen(false);
    };

    const processScanJobArtifacts = async (scanServiceInternal: ScanServiceInternalClient, scanJobId: string): Promise<number> => {
      var req = new ProcessScanArtifactsRequest();
      req.setScanJobId(scanJobId);
      return new Promise<number>((resolve, reject) => {
        scanServiceInternal.processScanArtifacts(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response.getProcessedScanArtifacts()?.getArtifactsProcessed()!)
        });
      });
    }

    return (
        <div>
           <Dialog fullScreen={fullScreen} open={processScanJobArtifactsDialogOpen} onClose={handleProcessScanJobArtifactsDialogClose(false)} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">{"Process Scan Job Artifacts?"}</DialogTitle>
              <DialogContent>
                <DialogContentText> Are you sure you want to process artifacts for this scan job?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleProcessScanJobArtifactsDialogClose(false)}>Cancel</Button>
                <Button variant="contained" onClick={handleProcessScanJobArtifactsDialogClose(true)} autoFocus color="secondary">Process Artifacts</Button>
              </DialogActions>
            </Dialog>
            <LoadingButton
              sx={props.sx}
              size="small"
              color="secondary"
              onClick={handleProcessScanJobArtifactsDialogClickOpen}
              loading={loading}
              disabled={props.scanJobStatus !== ScanJob.ScanJobStatus.SCAN_JOB_STATUS_COMPLETE}
              loadingPosition="start"
              startIcon={<RestartAltIcon />}
              variant="contained">
              Process Artifacts
            </LoadingButton>
        </div>
    );
};

export default ProcessScanJobArtifactsButton;