import React, {ReactElement, FC, useState} from "react";
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import { ScanServiceInternalClient } from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import { KillScanJobRequest } from '../../generated/sp/scan_service/scan_service_internal_pb';
import { ScanJob } from '../../generated/sp/scan_service/scan_service_pb';
import {Theme, useTheme} from '@mui/material/styles';
import { generateAuthHeader } from '../../lib/authorizationUtils';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {SxProps} from "@mui/system";

interface KillJobButtonProps {
  scanJobId: string | undefined;
  scanJobStatus: ScanJob.ScanJobStatus | undefined;
  sx?: SxProps<Theme>;
}


const KillJobButton: FC<KillJobButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [killJobDialogOpen, setKillJobDialogOpen] = useState(false);
    const handleKillJobDialogClickOpen = () => {
      setKillJobDialogOpen(true);
    };
    const handleKillJobDialogClose = (shouldKill: boolean) => () => {
      if ( shouldKill ) {
        setLoading(true);
        console.log("Killing scan_job with id=" + props.scanJobId);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        Promise.resolve(killScanJob(scanServiceInternal, props.scanJobId!)).then(function(success) {
          console.log("Killed scanJobId=" + props.scanJobId + " with success=" + success);
          setLoading(false);
        }, function(value) {
          // not called
        });
        //TODO: refresh the page since status may have updated.
      }
      setKillJobDialogOpen(false);
    };

    const killScanJob = async (scanServiceInternal: ScanServiceInternalClient, scanJobId: string): Promise<boolean> => {
      var req = new KillScanJobRequest();
      req.setScanJobId(scanJobId);
      return new Promise<boolean>((resolve, reject) => {
        scanServiceInternal.killScanJob(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response.getUpdated()!)
        });
      });
    }

    return (
        <div>
           <Dialog fullScreen={fullScreen} open={killJobDialogOpen} onClose={handleKillJobDialogClose(false)} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">{"Kill Job?"}</DialogTitle>
              <DialogContent>
                <DialogContentText> Are you sure you want to kill this scan job?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleKillJobDialogClose(false)}>Cancel</Button>
                <Button variant="contained" onClick={handleKillJobDialogClose(true)} autoFocus color="error">Kill</Button>
              </DialogActions>
            </Dialog>
            <LoadingButton
              sx={props.sx}
              size="small"
              color="error"
              onClick={handleKillJobDialogClickOpen}
              loading={loading}
              disabled={props.scanJobStatus !== ScanJob.ScanJobStatus.SCAN_JOB_STATUS_RUNNING}
              loadingPosition="start"
              startIcon={<DeleteForeverIcon />}
              variant="contained">
              Kill Job
            </LoadingButton>
        </div>
    );
};

export default KillJobButton;