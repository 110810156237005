/**
 * @fileoverview gRPC-Web generated client stub for sp.skynet_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.sp = {};
proto.sp.skynet_service = require('./skynet_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.skynet_service.SkynetServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.skynet_service.SkynetServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.skynet_service.GetTextResultsRequest,
 *   !proto.sp.skynet_service.GetTextResultsResponse>}
 */
const methodDescriptor_SkynetService_getTextResults = new grpc.web.MethodDescriptor(
  '/sp.skynet_service.SkynetService/getTextResults',
  grpc.web.MethodType.UNARY,
  proto.sp.skynet_service.GetTextResultsRequest,
  proto.sp.skynet_service.GetTextResultsResponse,
  /**
   * @param {!proto.sp.skynet_service.GetTextResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.skynet_service.GetTextResultsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.skynet_service.GetTextResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.skynet_service.GetTextResultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.skynet_service.GetTextResultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.skynet_service.SkynetServiceClient.prototype.getTextResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.skynet_service.SkynetService/getTextResults',
      request,
      metadata || {},
      methodDescriptor_SkynetService_getTextResults,
      callback);
};


/**
 * @param {!proto.sp.skynet_service.GetTextResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.skynet_service.GetTextResultsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.skynet_service.SkynetServicePromiseClient.prototype.getTextResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.skynet_service.SkynetService/getTextResults',
      request,
      metadata || {},
      methodDescriptor_SkynetService_getTextResults);
};


module.exports = proto.sp.skynet_service;

