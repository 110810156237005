import React, {ReactElement, FC, useState, useEffect, useCallback, useRef, useLayoutEffect} from "react";
import {
    Box,
    Grid,
    LinearProgress,
    Paper,
    Table, TableBody,
    TableContainer,
    TableHead, TextField,
    Typography
} from "@mui/material";
import {DiagnoseEventsArtifact, DiagnoseMetadata, HttpTransaction} from "../generated/sp/scan_runner/scan_runner_pb";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {fetchScanArtifact} from "../lib/scanServiceUtils";
import {StyledTableCell, StyledTableRow} from "../lib/tableUtils";
import {formatStringMaxLength} from "../lib/stringUtils";
import DiagnoseHttpTransactionViewButton from "./buttons/DiagnoseHttpTransactionViewButton";
import {useUpdateEffect} from "usehooks-ts";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";
import {CsvHeader} from "../lib/exportCsvUtils";
import SimpleTable from "./SimpleTable";

interface DiagnoseHttpEventTableProps {
  scanJobId: string | undefined;
  artifactId: string | undefined;
  maxHeight: number;
}

const PAGE_SIZE = 50;

const DiagnoseHttpEventTable: FC<DiagnoseHttpEventTableProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [masterEventList, setMasterEventList] = useState<HttpTransaction[]>([]);
    const [eventList, setEventList] = useState<HttpTransaction[]>([]);
    const [dictionary, setDictionary] = useState<string[]>([]);
    const [diagnoseMetadata, setDiagnoseMetadata] = useState<DiagnoseMetadata | undefined>(undefined);

    // infinity scroll state
    const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);
    const [nextScrollPosition, setNextScrollPosition] = useState<number|undefined>(PAGE_SIZE);
    const tableEl = useRef<any>(null);
    const [distanceBottom, setDistanceBottom] = useState(0)

    const reloadScanJobArtifact = useCallback(async () => {
        console.log(`Loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanArtifact = await fetchScanArtifact(scanService, props.scanJobId!, props.artifactId!);
        const contents : Uint8Array = scanArtifact!.getContent_asU8();
        const diagnoseArtifact = DiagnoseEventsArtifact.deserializeBinary(contents);
        setMasterEventList(diagnoseArtifact.getEventsList());
        setDictionary(diagnoseArtifact.getDictionaryList());
        setDiagnoseMetadata(diagnoseArtifact.getMetadata());
        console.log(`Finished loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(false);
    }, [props.scanJobId, props.artifactId]);

    useUpdateEffect(() => {
        (async () => {
            if (currentScrollPosition === 0) {
                // only run during first render.
              maybeExposeMoreEvents();
            }

        })();
    }, [masterEventList]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined && props.artifactId !== undefined) {
                await reloadScanJobArtifact();
            }
        })();
    }, [props.scanJobId, props.artifactId, reloadScanJobArtifact]);

    const exportCsvHeaders = (): CsvHeader[] => {
        return [
            { label: "Request ID", key: "requestId" },
            { label: "URL", key: "url" },
        ]
    }

    interface CsvExportData {
        requestId: string;
        url: string;
    }

    const exportCsvData = (): CsvExportData[] => {
        return eventList.map((event) => {
            return {
                requestId: lookupString(event.getRequestData()?.getReqId()) || "unknown",
                url: lookupString(event.getRequestData()?.getUrl()) || "unknown",
            };
        });
    }

    const lookupString = (position: number|undefined): string|undefined => {
        if(position === undefined || typeof dictionary[position] === 'undefined') {
            return undefined;
        }
        else {
            return dictionary[position];
        }
    };

    const maybeExposeMoreEvents = useCallback(() => {
        let currentListSize = masterEventList.length;
        if (nextScrollPosition === undefined) {
            console.log(`Already at end of list of size ${currentListSize}`);
        } else {
            console.log(`Infinite scroll triggered -- using currentScrollPosition: ${currentScrollPosition}, nextScrollPosition: ${nextScrollPosition}`);
            let newEvents = masterEventList.slice(currentScrollPosition, nextScrollPosition);
            setEventList(old => [...old, ...newEvents]);
            setCurrentScrollPosition(nextScrollPosition);
            // calculate next scroll position
            if (currentListSize === nextScrollPosition) {
                // no more pages
                setNextScrollPosition(undefined);
            } else {
                // pre-calculate next position
                let nextPosition = currentListSize > nextScrollPosition+PAGE_SIZE ? nextScrollPosition+PAGE_SIZE : currentListSize;
                setNextScrollPosition(nextPosition);
            }
        }
    }, [masterEventList, currentScrollPosition, nextScrollPosition]);

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
            setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        if (tableRef.scrollTop > bottom - distanceBottom && !loading) {
            maybeExposeMoreEvents();
        }
    }, [loading, distanceBottom, maybeExposeMoreEvents])

    useLayoutEffect(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        tableRef.addEventListener('scroll', scrollListener)
        return () => {
            tableRef.removeEventListener('scroll', scrollListener)
        }
    }, [scrollListener])

    return (
      <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600 }} variant="subtitle2" component="div">Identifiers</Typography>
          </Grid>
          <Grid item xs={4}>
              <TextField size="small" label="Scan Job Id" type="string" value={props.scanJobId || " "} inputProps={{ readOnly: true}} fullWidth/>
          </Grid>
          <Grid item xs={4}>
              <TextField size="small" label="Artifact Id" type="string" value={props.artifactId || " "} inputProps={{ readOnly: true}} fullWidth/>
          </Grid>
          { diagnoseMetadata !== undefined &&
            <React.Fragment>
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Metadata</Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField size="small" label="Wildcard Link" type="string" value={diagnoseMetadata?.getWildcardLink() || ' '} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                    <TextField size="small" label="GDPR Applies?" type="string" value={diagnoseMetadata?.getGdprApplies() || ' '} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField size="small" multiline rows={4} label="Consent String" type="string" value={diagnoseMetadata?.getConsentString() || ' '} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField size="small" multiline rows={4} label="Original Meta Command" type="string" value={diagnoseMetadata.getOriginalMetaCommand() || ' '} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Cookies</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SimpleTable rows={diagnoseMetadata?.getJsCookiesList()?.map((cookie) => [cookie.getUrl(), cookie.getCookie()]) || []} headerNames={["Url", "Cookie"]} maxHeight={150}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Vendors</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SimpleTable rows={diagnoseMetadata?.getSpCustomVendorsList()?.map((vendor) => [vendor]) || []} headerNames={["Vendor"]} maxHeight={150}/>
                </Grid>
            </React.Fragment>
          }
          <Grid item xs={12}>
              {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
          </Grid>
          <Grid item xs={12}>
              <Grid container spacing={1}>
                  <Grid item xs={12}>
                      <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Events</Typography>
                  </Grid>
                  <Grid item xs={10}>
                      <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {eventList.length} Events</Typography>
                  </Grid>
                  <Grid item xs={2}>
                      <Box display="flex" alignItems="right" justifyContent="right">
                          <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"DiagnoseEvents-" + props.artifactId + "-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                              <TableArrowDownIcon sx={{color: "secondary"}}/>
                          </CSVLink>
                      </Box>
                  </Grid>
                  <Grid item xs={12}>
                      <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}} ref={tableEl}>
                          <Table stickyHeader size="small" aria-label="events">
                              <TableHead>
                                  <StyledTableRow>
                                      <StyledTableCell align="left">Request Id</StyledTableCell>
                                      <StyledTableCell align="left">Url</StyledTableCell>
                                      <StyledTableCell align="right">Actions</StyledTableCell>
                                  </StyledTableRow>
                              </TableHead>
                              <TableBody>
                                  {eventList.filter((httpTransaction) => httpTransaction.hasRequestData()).map((row) => (
                                    <StyledTableRow hover key={(row.getRequestData()?.getReqId() || 0) + Math.random()}>
                                        <StyledTableCell align="left">{lookupString(row.getRequestData()?.getReqId()) || "unknown"}</StyledTableCell>
                                        <StyledTableCell align="left">{formatStringMaxLength(lookupString(row.getRequestData()?.getUrl()) || "unknown", 100)}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Box display="flex" alignItems="right" justifyContent="right">
                                                <DiagnoseHttpTransactionViewButton httpTransaction={row} dictionary={dictionary}/>
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
    );
}


export default DiagnoseHttpEventTable;