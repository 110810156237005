import React, {ReactElement, FC, useState, useEffect} from "react";
import {Box, Grid, LinearProgress, Table, TableBody, TableContainer, TableHead} from '@mui/material';
import Paper from '@mui/material/Paper';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {TCModel} from "@iabtcf/core/lib/mjs/TCModel";
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import {iabSpecialFeatureOptinList} from "../lib/iabUtils";

interface IABSpecialFeatureOptinsTableProps {
    tcModel: TCModel | undefined,
    maxHeight: number;
}

interface SpecialFeaturesOptinRow {
    id: number;
    name: string;
    optedIn: boolean;
}

const IABSpecialFeatureOptinsTable: FC<IABSpecialFeatureOptinsTableProps> = (props): ReactElement => {
    const [rows, setRows] = useState<SpecialFeaturesOptinRow[]>([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            if (props.tcModel !== undefined) {
                setLoading(true);
                let rows = iabSpecialFeatureOptinList.map((specialFeature) => {
                    let row: SpecialFeaturesOptinRow = {
                        id: specialFeature.id,
                        name: specialFeature.name,
                        optedIn: props.tcModel!.specialFeatureOptins.has(specialFeature.id),
                    }
                    return row;
                });
                if( rows !== undefined) {
                    setRows(rows);
                }
                setLoading(false);
            }
        })();
    }, [props.tcModel]);

    return (
        <React.Fragment>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
            </Grid>
            { !loading &&
                <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}}>
                    <Table stickyHeader size="small" aria-label="Vendor List">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="left">Id</StyledTableCell>
                                <StyledTableCell align="left">Name</StyledTableCell>
                                <StyledTableCell align="center">Opted-In</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow hover key={row.id}>
                                    <StyledTableCell align="left">{row.id}</StyledTableCell>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="center">{row.optedIn ?  <CheckIcon htmlColor={"green"}/> : <RemoveIcon htmlColor={"red"}/>}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </React.Fragment>

    );
}

export default IABSpecialFeatureOptinsTable;