
const getWssUri = () => {
    let wss_uri: string = `${process.env.REACT_APP_SOURCE_POINT_WSS_ENDPOINT!}`;
    if (wss_uri.startsWith("wss://") || wss_uri.startsWith("ws://")) {
        return wss_uri;
    } else if (wss_uri.startsWith("/")){
        // we need to assemble the url by hand
        return `wss://${window.location.host}${wss_uri}`
    }
}

export { getWssUri };