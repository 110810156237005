import React, {ReactElement, FC, useState, useEffect, useCallback} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid, LinearProgress, Paper, Table, TableBody, TableContainer, TableHead, TextField,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {ElementLocation, PageSearchResult, TextResult} from "../../generated/sp/scan_runner/common_pb";
import {fetchScanArtifact} from "../../lib/scanServiceUtils";
import {StyledTableCell, StyledTableRow} from "../../lib/tableUtils";
import {enumName} from "../../lib/enumUtils";
import ElementDataViewButton from "../buttons/ElementDataViewButton";
import {formatStringMaxLength} from "../../lib/stringUtils";

interface ScanTimelinePageSearchResult {
    name: string;
    pageSearchResult: PageSearchResult | undefined,
}

interface ScanTimelinePageSearchArtifactCardProps {
    scanJobId: string | undefined;
    artifactId: string | undefined;
    sx?: SxProps<Theme>;
}

const ScanTimelinePageSearchArtifactCard: FC<ScanTimelinePageSearchArtifactCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanTimelinePageSearchResult, setScanTimelinePageSearchResult] = useState<ScanTimelinePageSearchResult  | undefined>(undefined);

    const reloadScanJobArtifact = useCallback(async () => {
        console.log(`Loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanArtifact = await fetchScanArtifact(scanService, props.scanJobId!, props.artifactId!);
        const contents : Uint8Array = scanArtifact!.getContent_asU8();
        const pageSearchResult = PageSearchResult.deserializeBinary(contents);
        const data: ScanTimelinePageSearchResult = {
            name: scanArtifact.getName(),
            pageSearchResult
        };
        setScanTimelinePageSearchResult(data);
        console.log(`Finished loading artifact data for scanJobId=[${props.scanJobId!}], artifactId=[${props.artifactId!}]`);
        setLoading(false);
    }, [props.scanJobId, props.artifactId]);

    useEffect(() => {
        (async () => {
            if(props.scanJobId !== undefined && props.artifactId !== undefined) {
                await reloadScanJobArtifact();
            }
        })();
    }, [props.scanJobId, props.artifactId, reloadScanJobArtifact]);

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1}}  style={{ fontWeight: 600 }} variant="subtitle1" component="div">ScanJobId: [{props.scanJobId}]</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={2}>
                                <TextField size="small" label="PageType" type="string" value={enumName(PageSearchResult.PageType, scanTimelinePageSearchResult?.pageSearchResult?.getPageType(), false)} inputProps={{ readOnly: true}} fullWidth/>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField size="small" label="Page URL" type="string" value={scanTimelinePageSearchResult?.pageSearchResult?.getPageUrl() || 'Not Set'} inputProps={{ readOnly: true}} fullWidth/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Element Descriptions</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} style={{ maxHeight: "250px" }}>
                                    <Table stickyHeader size="small" aria-label="events">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Name</StyledTableCell>
                                                <StyledTableCell align="left">Location Type</StyledTableCell>
                                                <StyledTableCell align="left">Location XPath</StyledTableCell>
                                                <StyledTableCell align="left">Source</StyledTableCell>
                                                <StyledTableCell align="right">Actions</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {scanTimelinePageSearchResult?.pageSearchResult?.getElementDescriptionsList().map((row) => (
                                              <StyledTableRow hover key={row.getName() + "|" + row.getLocation()?.getUniqueXpath()}>
                                                  <StyledTableCell align="left">{row.getName()}</StyledTableCell>
                                                  <StyledTableCell align="left">{enumName(ElementLocation.ElementType, row.getLocation()?.getElementType(), false)}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.getLocation()?.getUniqueXpath()}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.getSource()}</StyledTableCell>
                                                  <StyledTableCell align="right">
                                                      <Box display="flex" alignItems="right" justifyContent="right">
                                                          <ElementDataViewButton elementData={row.getData()}/>
                                                      </Box>
                                                  </StyledTableCell>
                                              </StyledTableRow>
                                            ))}
                                            {scanTimelinePageSearchResult?.pageSearchResult?.getElementDescriptionsList().length === 0 &&
                                              <StyledTableRow>
                                                  <StyledTableCell align="center" colSpan={5}><Typography style={{ fontWeight: 600 }} variant="subtitle2">No Data</Typography></StyledTableCell>
                                              </StyledTableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ fontWeight: 600 }} variant="subtitle2" component="div">Text Results</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} style={{ maxHeight: "250px" }}>
                                    <Table stickyHeader size="small" aria-label="events">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Text Type</StyledTableCell>
                                                <StyledTableCell align="left">Language</StyledTableCell>
                                                <StyledTableCell align="left">Language Score</StyledTableCell>
                                                <StyledTableCell align="left">Text</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {scanTimelinePageSearchResult?.pageSearchResult?.getTextResultsList().map((row) => (
                                              <StyledTableRow hover key={row.getTextType()}>
                                                  <StyledTableCell align="left">{enumName(TextResult.TextType, row.getTextType(), false)}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.getDetectionResult()?.getLang()}</StyledTableCell>
                                                  <StyledTableCell align="left">{row.getDetectionResult()?.getScore()}</StyledTableCell>
                                                  <StyledTableCell align="left">{formatStringMaxLength(row.getText())}</StyledTableCell>
                                              </StyledTableRow>
                                            ))}
                                            {scanTimelinePageSearchResult?.pageSearchResult?.getTextResultsList().length === 0 &&
                                              <StyledTableRow>
                                                  <StyledTableCell align="center" colSpan={4}><Typography style={{ fontWeight: 600 }} variant="subtitle2">No Data</Typography></StyledTableCell>
                                              </StyledTableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ScanTimelinePageSearchArtifactCard;