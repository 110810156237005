import React, {ReactElement, FC} from "react";
import { Grid } from '@mui/material';
import BulkPropertyProcessingCard from "../components/cards/BulkPropertyProcessingCard";
import MasterContainer from "../components/containers/MasterContainer";
import ScanRunnerDeploymentSettingsCard from "../components/cards/ScanRunnerDeploymentSettingsCard";
import ScanRunnerChromeExtensionsCard from "../components/cards/ScanRunnerChromeExtensionsCard";

const Admin: FC<any> = (): ReactElement => {
    return (
        <MasterContainer>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} sx={{ mt: 2}}>
                    <ScanRunnerDeploymentSettingsCard/>
                </Grid>
                <Grid item xs={6} sx={{ mt: 2}}>
                    <BulkPropertyProcessingCard/>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2}}>
                    <ScanRunnerChromeExtensionsCard maxHeight={400}/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};

export default Admin;