import React, {ReactElement, FC} from "react";
import {Table, TableBody, TableContainer, TableHead, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';


interface SimpleTableProps {
    rows: string[][]|undefined;
    headerNames: string[]|undefined;
    maxHeight: number;
}

const SimpleTable: FC<SimpleTableProps> = (props): ReactElement => {
    return (
        <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}}>
            <Table stickyHeader size="small" aria-label="Data">
                {props.headerNames !== undefined &&
                    <TableHead>
                        <StyledTableRow>
                            {props.headerNames.map((headerName) => (
                                <StyledTableCell key={headerName}>{headerName}</StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                }
                {props.rows !== undefined &&
                    <TableBody>
                        {props.rows.map((row, idx) => (
                            <StyledTableRow hover key={idx}>
                                {row.map(cell => (
                                    <StyledTableCell align="left" key={cell}>{cell}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                        {(props.rows === undefined || props.rows.length === 0) &&
                            <StyledTableRow>
                                <StyledTableCell align="center" colSpan={4}><Typography style={{ fontWeight: 600 }} variant="subtitle2">No Data</Typography></StyledTableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                }
            </Table>
        </TableContainer>
    );
}

export default SimpleTable;