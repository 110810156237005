import React, {ReactElement, FC, useState} from "react";
import {
    Button,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    CardContent, Card, Checkbox, FormControlLabel
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from '@mui/material';
import {DisplayOption} from '../../generated/sp/scan_runner/common_pb';
import {ScanServiceInternalClient} from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {StartDebugScanRequest} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import {regionSelectorItems} from '../../lib/regionUtils';
import {generateAuthHeader} from '../../lib/authorizationUtils';
import isFQDN from 'validator/lib/isFQDN';
import {ProxyType} from "../../generated/sp/scan_runner/scan_runner_pb";
import {SxProps} from "@mui/system";

interface CreateScanCardProps {
    sx?: SxProps<Theme>;
    createScanCompleteFunction: () => void;
}

const CreateScanCard: FC<CreateScanCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState<string>("US-CA");
    const [proxyType, setProxyType] = useState<ProxyType>(ProxyType.PROXY_TYPE_SOURCEPOINT_DATACENTER);
    const [websiteUri, setWebsiteUri] = useState<string>("");
    const [diagnoseScan, setDiagnoseScan] = useState<boolean>(false);
    const [script, setScript] = useState<string>("");
    const [websiteUriErrorText, setWebsiteUriErrorText] = useState<string | undefined>( undefined);

    const handleRegionChange = (event: SelectChangeEvent) => {
        setRegion(event.target.value);
    };

    const handleProxyTypeChange = (event: SelectChangeEvent) => {
        let proxyTypeToBeSet = parseInt(event.target.value);
        setProxyType(proxyTypeToBeSet);
    };

    const handleWebsiteUriChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let website = event.target.value;
        setWebsiteUri(website);
        if (isFQDN(website)) {
            setWebsiteUriErrorText('')
        } else {
            setWebsiteUriErrorText('Is Not Valid FQDN')
        }
    }

    const handleDiagnoseScanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiagnoseScan(event.target.checked);
    };

    const handleScriptChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setScript(event.target.value);
    };

    const createScanJob = async (scanServiceInternal: ScanServiceInternalClient, region: string, proxyType: ProxyType, websiteUri: string, script: string): Promise<string> => {
        var req = new StartDebugScanRequest();
        req.setRegion(region);
        req.setDisplayOption(DisplayOption.DISPLAY_OPTION_XVFB);
        req.setProxyType(proxyType)
        req.setPropertyName(websiteUri);
        if (script.length > 0) {
            req.setScript(script);
        }

        return new Promise<string>((resolve, reject) => {
            scanServiceInternal.startDebugScan(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getScanJobId()!)
            });
        });
    }

    // create scan job dialog
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickCreateScan = () => {
        setOpen(true);
    };

    const handleClose = (shouldCreateScan: boolean) => () => {
        if (shouldCreateScan) {
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            Promise.resolve(createScanJob(scanServiceInternal, region, proxyType, websiteUri, script)).then(function (scanJobId) {
                console.log("Created scanJobId=" + scanJobId);
                setLoading(false);
            }, function (rejected) {
                setWebsiteUriErrorText(rejected.toString());
                setLoading(false);
            });
            //refresh the page since status may have updated.
            props.createScanCompleteFunction();
        }
        setOpen(false);
    };


    return (
        <Card>
            <CardContent sx={props.sx}>
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose(false)}
                        aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{"Create Scan"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText> Are you sure you want to create this scan?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" autoFocus onClick={handleClose(false)}>Cancel</Button>
                        <Button variant="contained" onClick={handleClose(true)} autoFocus
                                color="secondary">Create</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 600}}
                                    variant="subtitle1" component="div">Create Scan</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="region">Region</InputLabel>
                            <Select size="small" labelId="region" id="region" value={region} label="Region"
                                    onChange={handleRegionChange}>
                                {regionSelectorItems().map((region) => (
                                    <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="proxyType">Proxy Type</InputLabel>
                            <Select
                                size="small"
                                labelId="proxyType"
                                id="proxyType"
                                value={proxyType.toString()} label="Proxy Type"
                                onChange={x => handleProxyTypeChange(x)}>
                                <MenuItem value={ProxyType.PROXY_TYPE_SOURCEPOINT_DATACENTER}>Sourcepoint DC</MenuItem>
                                <MenuItem value={ProxyType.PROXY_TYPE_BRIGHTDATA_DATACENTER}>BrightData DC</MenuItem>
                                <MenuItem value={ProxyType.PROXY_TYPE_BRIGHTDATA_RESIDENTIAL}>BrightData Residential</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField size="small" label="Website Domain" type="string" value={websiteUri || ''}
                                   onChange={x => handleWebsiteUriChange(x)} helperText={websiteUriErrorText} error={!!websiteUriErrorText}
                                   fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox
                            checked={diagnoseScan}
                            onChange={handleDiagnoseScanChange}
                          />
                          }
                          label="Diagnose Scan?"
                        />
                    </Grid>
                    { diagnoseScan &&
                      <Grid item xs={12}>
                        <TextField size="small" multiline rows={6} label="Script" placeholder="Enter script here" id="script" type="string" value={script || ''} onChange={handleScriptChange} fullWidth={true}/>
                      </Grid>
                    }
                    <Grid item xs={2}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleClickCreateScan}
                            loading={loading}
                            disabled={(websiteUriErrorText === undefined || websiteUriErrorText.length > 0) || (diagnoseScan && script.length <=0)}
                            loadingPosition="start"
                            startIcon={<SaveIcon/>}
                            variant="contained"
                        >
                            Create
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CreateScanCard;