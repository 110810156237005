import React, {ReactElement, FC, useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {Theme, useTheme} from '@mui/material/styles';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {SxProps} from "@mui/system";
import ScanTimelinePageSearchArtifactCard from "../cards/ScanTimelinePageSearchArtifactCard";

interface ScanTimelinePageSearchResultArtifactViewButtonProps {
  scanJobId: string | undefined;
  artifactId: string | undefined;
  useLoadingButton?: boolean | undefined;
  sx?: SxProps<Theme>;
}

const ScanTimelinePageSearchResultArtifactViewButton: FC<ScanTimelinePageSearchResultArtifactViewButtonProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    // dialog stuff...
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleDialogClickOpen = async () => {
      setLoading(true);
      setDialogOpen(true);
      setLoading(false);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const determineButton = (): ReactElement => {
        return (props.useLoadingButton === undefined || props.useLoadingButton) ?
            <LoadingButton
              sx={props.sx}
              size="small"
              color="secondary"
              onClick={handleDialogClickOpen}
              loading={loading}
              loadingPosition="start"
              startIcon={<FindInPageIcon />}
              variant="contained">
              View
            </LoadingButton>
        :
            <Tooltip title="View Page Search Results">
                <IconButton
                    sx={props.sx}
                    color="secondary"
                    disabled={loading}
                    onClick={handleDialogClickOpen}
                    aria-label="View Text"
                    component="span">
                  <FindInPageIcon />
                </IconButton>
            </Tooltip>;
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} fullWidth={true} maxWidth={"xl"} open={dialogOpen} onClose={handleDialogClose} aria-labelledby="responsive-dialog-title">
              <DialogTitle id="responsive-dialog-title">
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography style={{ fontWeight: 600 }} variant="subtitle1" component="div">Page Search Result Artifact</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <ScanTimelinePageSearchArtifactCard scanJobId={props.scanJobId} artifactId={props.artifactId}/>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDialogClose}>Exit</Button>
              </DialogActions>
            </Dialog>
            {determineButton()}
        </div>
    );
};

export default ScanTimelinePageSearchResultArtifactViewButton;