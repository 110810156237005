import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import {Box, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField, Typography} from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import {ScanServiceInternalClient} from "../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {
  GetScanJobReportsRequest, GetScanJobReportsResponse,
  ScanJobReport
} from '../generated/sp/scan_service/scan_service_internal_pb';
import {NumberFilter, NumberList, PagingParameters, SortDirection, TimestampFilter} from '../generated/sp/service_common/common_pb';
import {generateAuthHeader} from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {formatRegion, regionSelectorItems} from "../lib/regionUtils";
import {getCurrentTimestampMinusDays} from "../lib/timestampUtils";
import {SELECTOR_ALL_ID, SELECTOR_ALL_ID_NAME, SelectorIdName} from "../lib/selectorUtils";
import AggregationParameters = GetScanJobReportsRequest.AggregationParameters;
import ScanJobReportField = GetScanJobReportsRequest.AggregationParameters.ScanJobReportField;
import AggregateFunction = GetScanJobReportsRequest.AggregationParameters.AggregateFunction;
import {getScanJobStatusColor} from "../lib/statusUtils";
import {enumName} from "../lib/enumUtils";
import {ScanJob} from "../generated/sp/scan_service/scan_service_pb";
import {fetchIabCmpMap, iabCmpSelectorList} from "../lib/iabUtils";
import ScanJobsCmpPopupButton from "./buttons/ScanJobsCmpPopupButton";
import {CsvHeader} from "../lib/exportCsvUtils";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";
import { CSVLink } from "react-csv";

const generateGetScanJobReportsRequest = (continuationToken: string | undefined, limit: number, sortField: GetScanJobReportsRequest.SortField, sortDirection: SortDirection, regionFilter: string, iabCmpFilter: string, newerThanDaysFilter: number): GetScanJobReportsRequest => {
  var req = new GetScanJobReportsRequest();
  let filters = new GetScanJobReportsRequest.Filters();

  if( regionFilter.length > 0 && regionFilter !== SELECTOR_ALL_ID) {
    filters.addRegionIdFilter(regionFilter);
  }
  // convert older than days to date filter
  filters.setCompletionTimeFilter(new TimestampFilter().setOnOrAfter(getCurrentTimestampMinusDays(newerThanDaysFilter)))
  if( iabCmpFilter.length > 0 && iabCmpFilter !== SELECTOR_ALL_ID) {
    filters.setConsentCmpIdFilter(new NumberFilter().setEquals(new NumberList().setValuesList([parseInt(iabCmpFilter)])))
  } else {
    filters.setConsentCmpIdFilter(new NumberFilter().setGreaterThan(new NumberList().setValuesList([0])))
  }
  // determine aggregation
  let aggregationParameters = new AggregationParameters()
    .setSelectFieldsList([ScanJobReportField.SCAN_JOB_REPORT_FIELD_REGION_ID, ScanJobReportField.SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID, ScanJobReportField.SCAN_JOB_REPORT_FIELD_SCAN_JOB_STATUS])
    .setAggregateFnc(AggregateFunction.AGGREGATE_FUNCTION_COUNT)
    .setAggregateFieldsList([ScanJobReportField.SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID])
    .setGroupByFieldsList([ScanJobReportField.SCAN_JOB_REPORT_FIELD_REGION_ID, ScanJobReportField.SCAN_JOB_REPORT_FIELD_CONSENT_CMP_ID, ScanJobReportField.SCAN_JOB_REPORT_FIELD_SCAN_JOB_STATUS]);
  req.setAggregationParameters(aggregationParameters);
  req.setFilters(filters);

  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetScanJobReportsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchScanJobReports = async (scanServiceInternal: ScanServiceInternalClient, request: GetScanJobReportsRequest): Promise<GetScanJobReportsResponse> => {
  return new Promise<GetScanJobReportsResponse>((resolve, reject) => {
    scanServiceInternal.getScanJobReports(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface CmpScanJobStatusReportInfinityTableProps {
  maxHeight: number;
  showFilterUi: boolean;
  forceRefresh?: number;
}

const CmpScanJobStatusReportInfinityTable: FC<CmpScanJobStatusReportInfinityTableProps> = (props): ReactElement => {
    const [scanJobReports, setScanJobReports] = useState<ScanJobReport[]>([]);
    const [regionFilter, setRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [iabCmpFilter, setIabVendorFilter] = useState<string>(SELECTOR_ALL_ID);
    const [newerThanDaysFilter, setNewerThanDaysFilter] = useState<number>(1);
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetScanJobReportsRequest.SortField>(GetScanJobReportsRequest.SortField.SORT_FIELD_AGGREGATE_COUNT);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_DESC);
    const [limit] = useState<number>(250);
    const [iabCmps, setIabCmps] = useState<SelectorIdName[]>([SELECTOR_ALL_ID_NAME]);
    const [cmpIdLookup, setCmpIdLookup] = useState<Map<number,string>>(new Map());
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    useEffect(() => {
      (async () => {
        setScanJobReports([]);
        setRefreshToggle(true);
      })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading scan job reports list data`);
        setLoading(true);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetScanJobReportsRequest(currentContinuationToken, limit, sortField, sortDirection, regionFilter, iabCmpFilter, newerThanDaysFilter);
        const response = await fetchScanJobReports(scanServiceInternal, req);
        let continuationToken = response.getContinuationToken().length > 0 ? response.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        // to support scrolling, we need to append to whatever is currently loaded
        setScanJobReports(old => [...old, ...response.getScanJobReportsList()]);
        let iabCmps = await iabCmpSelectorList();
        setIabCmps(iabCmps);
        //TODO : combine these somehow?
        let cmpLookup = await fetchIabCmpMap();
        setCmpIdLookup(cmpLookup);
        setLoading(false);
      })();
    }, [currentContinuationToken, limit, regionFilter, iabCmpFilter, sortDirection, sortField, refreshToggle]);

    const exportCsvHeaders = (): CsvHeader[] => {
      return [
        { label: "Region", key: "region" },
        { label: "Cmp Id", key: "cmpId" },
        { label: "Cmp Name", key: "cmpName" },
        { label: "Status", key: "status" },
        { label: "Count", key: "count" },
      ]
    }

    interface CsvExportData {
      region: string;
      cmpId: number;
      status: string;
      count: number;
    }

    const exportCsvData = (): CsvExportData[] => {
      return scanJobReports.map((scanJobReport) => {
        return {
          region: formatRegion(scanJobReport.getRegionId()),
          cmpId: scanJobReport.getConsentCmpId(),
          cmpName: cmpIdLookup.get(scanJobReport.getConsentCmpId())  || '',
          status: enumName(ScanJob.ScanJobStatus, scanJobReport.getStatus(), false),
          count: scanJobReport.getCount()
        };
      });
    }

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
      setScanJobReports([]); // wipe out data such that we dont keep appending to existing list.
      setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
      setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handleSortRequest = (sortField: GetScanJobReportsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRegionFilterChange = (event: SelectChangeEvent) => {
      let revised = event.target.value;
      if ( revised !== regionFilter) {
        startFreshSearch();
        setRegionFilter(revised);
      }
    };

    const handleIabCmpFilterChange = (event: SelectChangeEvent) => {
      let revised = event.target.value;
      if ( revised !== iabCmpFilter) {
        startFreshSearch();
        setIabVendorFilter(revised);
      }
    };

    const handleNewerThanDaysFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let revised = parseInt(event.target.value);
      setNewerThanDaysFilter(revised);
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            { props.showFilterUi &&
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter CMP Scan Reports</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="region">Region</InputLabel>
                            <Select size="small" labelId="region" id="region" value={regionFilter} label="Region" onChange={handleRegionFilterChange}>
                              {regionSelectorItems().map((region) => (
                                <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                              ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="iabVendor">Iab CMP</InputLabel>
                            <Select size="small" labelId="iabCMP" id="iabCMP" value={iabCmpFilter} label="Vendor" onChange={handleIabCmpFilterChange}>
                              {iabCmps.map((iabCmp) => (
                                <MenuItem key={iabCmp.id} value={iabCmp.id}>{iabCmp.name} ({iabCmp.id})</MenuItem>
                              ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Newer than (days)" type="number" value={newerThanDaysFilter || ''} onChange={x=> handleNewerThanDaysFilterChange(x)}/>
                    </Grid>
                    <Grid item xs={4}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleRefresh}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<RefreshIcon />}
                            variant="contained"
                        >
                            Refresh
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {scanJobReports.length} CMP Scan Reports</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" alignItems="right" justifyContent="right">
                            <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"cmpScanReport-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                                <TableArrowDownIcon/>
                            </CSVLink>
                        </Box>
                    </Grid>
                </Grid>
            }
          </Grid>
          <Grid item xs={12}>
            {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
          </Grid>
          <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader size="small" aria-label="Latest Active Scan Reports">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" width="10%" onClick={() => handleSortRequest(GetScanJobReportsRequest.SortField.SORT_FIELD_REGION_ID)}>
                    <TableSortLabel active={sortField === GetScanJobReportsRequest.SortField.SORT_FIELD_REGION_ID} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Region</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left" width="50%" onClick={() => handleSortRequest(GetScanJobReportsRequest.SortField.SORT_FIELD_CONSENT_CMP_ID)}>
                    <TableSortLabel active={sortField === GetScanJobReportsRequest.SortField.SORT_FIELD_CONSENT_CMP_ID} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Consent CMP ID</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left" width="20%" onClick={() => handleSortRequest(GetScanJobReportsRequest.SortField.SORT_FIELD_SCAN_JOB_STATUS)}>
                    <TableSortLabel active={sortField === GetScanJobReportsRequest.SortField.SORT_FIELD_SCAN_JOB_STATUS} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Status</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="20%" onClick={() => handleSortRequest(GetScanJobReportsRequest.SortField.SORT_FIELD_AGGREGATE_COUNT)}>
                      <TableSortLabel active={sortField === GetScanJobReportsRequest.SortField.SORT_FIELD_AGGREGATE_COUNT} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Count</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {scanJobReports?.map((row) => (
                  <StyledTableRow hover key={row.getRegionId() + row.getConsentCmpId()}>
                    <StyledTableCell align="left">{formatRegion(row.getRegionId())}</StyledTableCell>
                    <StyledTableCell align="left">{row.getConsentCmpId() + " - " + (cmpIdLookup.get(row.getConsentCmpId())  || '')}</StyledTableCell>
                    <StyledTableCell align="left">{<Box sx={{ color: getScanJobStatusColor(row.getStatus()) }}>{enumName(ScanJob.ScanJobStatus, row.getStatus(), false)}</Box>}</StyledTableCell>
                    <StyledTableCell align="center"><ScanJobsCmpPopupButton buttonPrefix={row.getCount().toString()} regionId={row.getRegionId()} cmpId={row.getConsentCmpId()} newerThanDaysFilter={newerThanDaysFilter}></ScanJobsCmpPopupButton></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    );
}

export default CmpScanJobStatusReportInfinityTable;