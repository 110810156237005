import {ScanArtifact} from "../generated/sp/scan_runner/scan_runner_pb";

const extractImageMetadata = (artifactMetadata: ScanArtifact.ArtifactMetadata | undefined): ScanArtifact.ImageMetadata | undefined => {
    if (artifactMetadata !== undefined) {
        switch(artifactMetadata.getMetadataTypeCase()) {
            case ScanArtifact.ArtifactMetadata.MetadataTypeCase.IMAGE:
                return artifactMetadata.getImage();
            default:
                return undefined;
        }
    }
};

export { extractImageMetadata };
