import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {
    GetPropertySetsRequest,
    GetPropertySetsResponse, GetScanJobByIdRequest,
    GetScanRulesRequest,
    GetScanRulesResponse, GetTimelineArtifactRequest,
    PropertySet, ScanJob,
    ScanRule
} from "../generated/sp/scan_service/scan_service_pb";
import {generateAuthHeader} from "./authorizationUtils";
import {PagingParameters, SortDirection} from "../generated/sp/service_common/common_pb";
import {ScanArtifact} from "../generated/sp/scan_runner/scan_runner_pb";
import {ScanServiceInternalClient} from "../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {CorrectScanJobResultsRequest, UploadCsvRequest} from "../generated/sp/scan_service/scan_service_internal_pb";

const fetchPropertySets = async (scanService: ScanServiceClient): Promise<GetPropertySetsResponse> => {
    console.log("Loading property sets data...");
    const filters = new GetPropertySetsRequest.Filters();
    filters.addStatusFilter(PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_ACTIVE);
    const request = new GetPropertySetsRequest()
      .setFilters(filters)
      .setPagingParameters(new PagingParameters().setLimit(100));
    request.addSortParameters(new GetPropertySetsRequest.SortParameter().setDirection(SortDirection.SORT_DIRECTION_ASC).setField(GetPropertySetsRequest.SortField.SORT_FIELD_NAME))

    return new Promise<GetPropertySetsResponse>((resolve, reject) => {
        scanService.getPropertySets(request, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response)
        });
    });
}

const fetchPropertySetsMapById = async (scanService: ScanServiceClient): Promise<Map<string,PropertySet>> => {
    const scanRulesResponse = await fetchPropertySets(scanService);
    return new Map(scanRulesResponse.getPropertySetsList().map(propertySet => [propertySet.getId(), propertySet]));
}

const fetchScanRules = async (scanService: ScanServiceClient): Promise<GetScanRulesResponse> => {
    const request = new GetScanRulesRequest().setPagingParameters(new PagingParameters().setLimit(500));
    return new Promise<GetScanRulesResponse>((resolve, reject) => {
        scanService.getScanRules(request, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response)
        });
    });
}

const fetchScanRuleMapByName = async (scanService: ScanServiceClient): Promise<Map<string,ScanRule>> => {
    const scanRulesResponse = await fetchScanRules(scanService);
    return new Map(scanRulesResponse.getScanRulesList().map(scanRule => [scanRule.getShortName(), scanRule]));
}

const fetchScanRuleMapById = async (scanService: ScanServiceClient): Promise<Map<string,ScanRule>> => {
    const scanRulesResponse = await fetchScanRules(scanService);
    return new Map(scanRulesResponse.getScanRulesList().map(scanRule => [scanRule.getId(), scanRule]));
}


const fetchScanArtifact = async (scanService: ScanServiceClient, scanJobId: string, artifactId: string): Promise<ScanArtifact> => {
    var req = new GetTimelineArtifactRequest();
    req.setScanJobId(scanJobId);
    req.setArtifactId(artifactId);

    return new Promise<ScanArtifact>((resolve, reject) => {
        scanService.getTimelineArtifact(req, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response.getArtifact()!)
        });
    });
}

const uploadCsv = async (scanServiceInternal: ScanServiceInternalClient, csvLines: Array<UploadCsvRequest.CsvLine>): Promise<Array<string>> => {
    var req = new UploadCsvRequest();
    req.setLinesList(csvLines);
    return new Promise<Array<string>>((resolve, reject) => {
        scanServiceInternal.uploadCsv(req, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response.getScanJobsIdsAddedList())
        });
    });
}

const correctScanJobResults = async (scanServiceInternal: ScanServiceInternalClient, scanJobId: string, correctedResults: Array<CorrectScanJobResultsRequest.CorrectedResult>): Promise<number> => {
    var req = new CorrectScanJobResultsRequest();
    req.setScanJobId(scanJobId);
    req.setCorrectedResultsList(correctedResults);
    return new Promise<number>((resolve, reject) => {
        scanServiceInternal.correctScanJobResults(req, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response.getRecordsUpdated())
        });
    });
}

const getScanJobById = async (scanService: ScanServiceClient, scanJobId: string, fieldsToGet: Array<ScanJob.ScanJobField>): Promise<ScanJob> => {
    var req = new GetScanJobByIdRequest();
    if( fieldsToGet.length > 0) {
        req.setFieldsList(fieldsToGet);
    }
    req.setId(scanJobId);
    return new Promise<ScanJob>((resolve, reject) => {
        scanService.getScanJobById(req, generateAuthHeader(), (err, response) => {
            if (err) reject(err);
            else resolve(response.getScanJob()!)
        });
    });
}

export { fetchScanRuleMapByName, fetchScanRuleMapById, fetchPropertySetsMapById, fetchScanArtifact, uploadCsv, correctScanJobResults, getScanJobById };