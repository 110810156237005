import React, {ReactElement, FC, useState, useEffect} from "react";
import {
    Box,
    Card,
    CardContent, FormControl,
    Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableContainer, TableHead,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import {ScanArtifact, ScanTimeline} from "../../generated/sp/scan_runner/scan_runner_pb";
import {GetTimelineRequest} from "../../generated/sp/scan_service/scan_service_pb";
import {PagingParameters} from "../../generated/sp/service_common/common_pb";
import {generateAuthHeader} from "../../lib/authorizationUtils";
import {v4 as uuidv4} from "uuid";
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
import ScanTimelineImageArtifactViewButton from "../buttons/ScanTimelineImageArtifactViewButton";
import ScanTimelineTextArtifactViewButton from "../buttons/ScanTimelineTextArtifactViewButton";
import ScanTimelineLocalStorageDumpArtifactViewButton from "../buttons/ScanTimelineLocalStorageDumpArtifactViewButton";
import ScanTimelinePageSearchResultArtifactViewButton from "../buttons/ScanTimelinePageSearchResultArtifactViewButton";
import ScanTimelineConsentApiDataArtifactViewButton from "../buttons/ScanTimelineConsentApiDataArtifactViewButton";
import ScanTimelineDiagnoseEventsArtifactViewButton from "../buttons/ScanTimelineDiagnoseEventsArtifactViewButton";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {StyledTableCell, StyledTableRow} from "../../lib/tableUtils";
import {getScanTimeLineSeverityColor} from "../../lib/statusUtils";
import {enumName} from "../../lib/enumUtils";

interface ScanTimelineEvent {
    id: string;
    ts: google_protobuf_timestamp_pb.Timestamp | undefined;
    scanPhase: ScanTimeline.ScanPhase | undefined;
    logEvent: ScanTimeline.LogEvent | undefined;
    artifactEvent: ScanTimeline.ArtifactEvent | undefined;
    networkEvent: ScanTimeline.NetworkEvent | undefined;
}
interface ScanJobTimelineListCardProps {
    scanJobId: string | undefined;
    scanRuleShortName?: string | undefined;
    maxHeight: number;
    sx?: SxProps<Theme>;
}

const ScanJobTimelineListCard: FC<ScanJobTimelineListCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [scanTimelineEvents, setScanTimelineEvents] = useState<ScanTimelineEvent[]>([]);
    const [eventTypeFilter, setEventTypeFilter] = useState<GetTimelineRequest.ScanEventType>(GetTimelineRequest.ScanEventType.SCAN_EVENT_TYPE_UNSET);
    const [logEventSeverityFilter, setLogEventSeverityFilter] = useState<ScanTimeline.LogEvent.Severity>(ScanTimeline.LogEvent.Severity.SEVERITY_UNSET);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);


    const getTimeline = async (scanService: ScanServiceClient, scanJobId: string, scanRuleShortName: string | undefined, eventTypeFilter: GetTimelineRequest.ScanEventType, logEventSeverityFilter: ScanTimeline.LogEvent.Severity): Promise<ScanTimeline> => {
        var req = new GetTimelineRequest();
        req.setScanJobId(scanJobId);
        let filters = new GetTimelineRequest.Filters();
        if( eventTypeFilter > 0) {
            filters.addEventTypes(eventTypeFilter);
        }
        if( logEventSeverityFilter > 0) {
            filters.setMinSeverity(logEventSeverityFilter);
        }
        if(scanRuleShortName !== undefined) {
            filters.addScanRuleShortNameFilter(scanRuleShortName);
        }
        req.setFilters(filters);

        // determine paging
        var pagingParams = new PagingParameters();
        //TODO: set this limit somewhere
        pagingParams.setLimit(50);
        req.setPagingParameters(pagingParams);

        return new Promise<ScanTimeline>((resolve, reject) => {
            scanService.getTimeline(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getTimeline()!)
            });
        });
    }

    useEffect(() => {
        (async () => {
            if ( props.scanJobId !== undefined) {
                console.log(`Loading timeline log data for scanJobId=[${props.scanJobId!} && scanRuleShortName=${props.scanRuleShortName}]`);
                setLoading(true);
                const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
                const timeline = await getTimeline(scanService, props.scanJobId, props.scanRuleShortName, eventTypeFilter, logEventSeverityFilter);
                const phaseLookup = timeline.getPhaseMapMap();
                const scanTimelineEvents = timeline.getEventsList()
                  .map(event => {
                      let maybeLogEvent = event.getLog() !== undefined ? event.getLog() : undefined;
                      let maybeArtifactEvent = event.getArtifact() !== undefined ? event.getArtifact() : undefined;
                      let maybeNetworkEvent = event.getNetwork() !== undefined ? event.getNetwork() : undefined;
                      let scanPhase = phaseLookup.get(event.getPhase());
                      const data: ScanTimelineEvent = {
                          id: uuidv4(),
                          ts: event.getTs(),
                          scanPhase,
                          logEvent: maybeLogEvent,
                          artifactEvent: maybeArtifactEvent,
                          networkEvent: maybeNetworkEvent,
                      };
                      return data;
                  })
                setScanTimelineEvents(scanTimelineEvents);
                setLoading(false);
            }
        })();
    }, [props.scanJobId, props.scanRuleShortName, eventTypeFilter, logEventSeverityFilter, refreshToggle]);


    const determineArtifactButton = (artifactEvent: ScanTimeline.ArtifactEvent): ReactElement | undefined => {
        switch (artifactEvent.getArtifactType()) {
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_IMAGE:
                return <ScanTimelineImageArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_TEXT:
                return <ScanTimelineTextArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_LOCAL_STORE_DUMP:
                return <ScanTimelineLocalStorageDumpArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_PAGE_SEARCH_RESULT:
                return <ScanTimelinePageSearchResultArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_CONSENT_API_DATA:
                return <ScanTimelineConsentApiDataArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_DOM_SNAPSHOT:
                return <ScanTimelineTextArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            case ScanArtifact.ArtifactType.ARTIFACT_TYPE_DIAGNOSE_EVENTS:
                return <ScanTimelineDiagnoseEventsArtifactViewButton useLoadingButton={false} scanJobId={props.scanJobId} artifactId={artifactEvent.getArtifactId()}/>;
            default:
                return undefined;
        }
    };

    const renderNetworkEvent = (networkEvent: ScanTimeline.NetworkEvent): ReactElement | undefined => {
        return (
          <Box display="flex" alignItems="center" justifyContent="left">
              <Typography variant="inherit" component="div">
                  {networkEvent.getUrl()} (duration={networkEvent.getDuration()?.getSeconds()}.{networkEvent.getDuration()?.getNanos()} secs, status={networkEvent.getStatusCode()})
              </Typography>
          </Box>
        );
    };

    const startFreshSearch = () => {
        setScanTimelineEvents([]); // wipe out data such that we dont keep appending to existing list.
    }

    const handleEventTypeFilterChange = (event: SelectChangeEvent) => {
        startFreshSearch();
        setEventTypeFilter(parseInt(event.target.value));
    };

    const handleLogEventSeverityFilterChange = (event: SelectChangeEvent) => {
        startFreshSearch();
        setLogEventSeverityFilter(parseInt(event.target.value));
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter Timeline Events</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="status">Event Type</InputLabel>
                                    <Select size="small" labelId="eventType" id="eventType" value={eventTypeFilter.toString()} label="eventType" onChange={handleEventTypeFilterChange}>
                                        <MenuItem value={GetTimelineRequest.ScanEventType.SCAN_EVENT_TYPE_UNSET}>All</MenuItem>
                                        <MenuItem value={GetTimelineRequest.ScanEventType.SCAN_EVENT_TYPE_LOG}>Log Event</MenuItem>
                                        <MenuItem value={GetTimelineRequest.ScanEventType.SCAN_EVENT_TYPE_ARTIFACT}>Artifact Event</MenuItem>
                                        <MenuItem value={GetTimelineRequest.ScanEventType.SCAN_EVENT_TYPE_NETWORK}>Network Event</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="status">Log Event Severity</InputLabel>
                                    <Select size="small" labelId="logEventSeverity" id="logEventSeverity" value={logEventSeverityFilter.toString()} label="logEventSeverity" onChange={handleLogEventSeverityFilterChange}>
                                        <MenuItem value={ScanTimeline.LogEvent.Severity.SEVERITY_UNSET}>All</MenuItem>
                                        <MenuItem value={ScanTimeline.LogEvent.Severity.SEVERITY_DEBUG}>Debug</MenuItem>
                                        <MenuItem value={ScanTimeline.LogEvent.Severity.SEVERITY_INFO}>Info</MenuItem>
                                        <MenuItem value={ScanTimeline.LogEvent.Severity.SEVERITY_WARN}>Warn</MenuItem>
                                        <MenuItem value={ScanTimeline.LogEvent.Severity.SEVERITY_ERROR}>Error</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <LoadingButton
                                  size="small"
                                  color="secondary"
                                  onClick={handleRefresh}
                                  loading={loading}
                                  loadingPosition="start"
                                  startIcon={<RefreshIcon />}
                                  variant="contained"
                                >
                                    Refresh
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">
                                    Found {scanTimelineEvents.length} timeline events
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
                </Grid>
                <TableContainer component={Paper} style={{ maxHeight: "300px" }}>
                    <Table stickyHeader size="small" aria-label="events">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="left" width="15%">Created</StyledTableCell>
                                <StyledTableCell align="left">Phase</StyledTableCell>
                                <StyledTableCell align="left" width="50%">&nbsp;</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {scanTimelineEvents.map((row) => (
                              <StyledTableRow hover key={row.id}>
                                  <StyledTableCell align="left">{row.ts?.toDate().toLocaleString()}</StyledTableCell>
                                  <StyledTableCell align="left">{row.scanPhase?.getName()}</StyledTableCell>
                                  { row.logEvent !== undefined &&
                                    <StyledTableCell align="left"><Box sx={{ color: getScanTimeLineSeverityColor(row.logEvent.getSeverity()) }}>[{enumName(ScanTimeline.LogEvent.Severity, row.logEvent.getSeverity(), false)}] {row.logEvent.getMessage()}</Box></StyledTableCell>
                                  }
                                  { row.artifactEvent !== undefined &&
                                    <StyledTableCell align="left" colSpan={2}>
                                        <Box display="flex" alignItems="center" justifyContent="left">
                                            <Typography variant="inherit" component="div">{row.artifactEvent.getName()}</Typography>
                                            {determineArtifactButton(row.artifactEvent)}
                                        </Box>
                                    </StyledTableCell>
                                  }
                                  { row.networkEvent !== undefined &&
                                    <StyledTableCell align="left" colSpan={2}>
                                        <Box display="flex" alignItems="center" justifyContent="left">
                                            {renderNetworkEvent(row.networkEvent)}
                                        </Box>
                                    </StyledTableCell>
                                  }
                              </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default ScanJobTimelineListCard;