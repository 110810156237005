import {ScanArtifact} from "../generated/sp/scan_runner/scan_runner_pb";
import {extractImageMetadata} from "./scanRunnerUtils";

export interface Base64Image {
    name: string;
    base64Content: string;
    scaledWidth: number,
    scaledHeight: number,
}
export interface Base64ImageAndMetadata {
    base64Image: Base64Image,
    metadata: ScanArtifact.ImageMetadata | undefined,
}

const scaleDimension = (scaleFactor: number, num: number) : number =>  {
    return parseFloat((num * scaleFactor).toFixed(2));
};

const generateBase64ImageAndMetadata = (scaleFactor: number, scanArtifact: ScanArtifact): Base64ImageAndMetadata => {
    const imageMetadata = extractImageMetadata(scanArtifact.getArtifactMetadata());
    let base64Image: Base64Image = {
        name: scanArtifact?.getName(),
        base64Content: scanArtifact.getContent_asB64(),
        scaledWidth: scaleDimension(scaleFactor, imageMetadata!.getWidth()),
        scaledHeight: scaleDimension(scaleFactor, imageMetadata!.getHeight())
    };
    return {
        base64Image: base64Image,
        metadata: imageMetadata,
    };
}
export { scaleDimension, generateBase64ImageAndMetadata };
