import React, {ReactElement, FC, useState, useEffect} from "react";
import { Box, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import { ScanRuleSet, GetScanRuleSetByIdRequest} from '../generated/sp/scan_service/scan_service_pb';
import { useParams } from 'react-router-dom';
import { enumName } from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import ScanRuleSetItemTable from "../components/ScanRuleSetItemTable";
import MasterContainer from "../components/containers/MasterContainer";

const ScanRuleSetDetail: FC<any> = (): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [scanRuleSetSet, setScanRuleSetSet] = useState<ScanRuleSet>();
    const { scanRuleSetId } = useParams();

    useEffect(() => {
      (async () => {
        console.log(`Loading data for scan rule set id=[${scanRuleSetId!}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanRuleSet = await getScanRuleSet(scanService, scanRuleSetId!);
        setScanRuleSetSet(scanRuleSet);
        setLoading(false);
      })();
    }, [scanRuleSetId]);

    const getScanRuleSet = async (scanService: ScanServiceClient, scanRuleSetId: string): Promise<ScanRuleSet> => {
      var req = new GetScanRuleSetByIdRequest();
      req.addFields(ScanRuleSet.ScanRuleSetField.SCAN_RULE_SET_FIELD_SCAN_RULE_SET_ITEMS);
      req.setId(scanRuleSetId);
      return new Promise<ScanRuleSet>((resolve, reject) => {
        scanService.getScanRuleSetById(req, generateAuthHeader(), (err, response) => {
          if (err) reject(err);
          else resolve(response.getScanRuleSet()!)
        });
      });
    };

    return (
        <MasterContainer>
           <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Scan Rule Set</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField size="small" label="ID" type="string" value={scanRuleSetSet?.getId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <TextField size="small" label=" Name" type="string" value={scanRuleSetSet?.getName() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline maxRows={6} size="small" label="Description" type="string" value={scanRuleSetSet?.getDescription() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Status" type="string" value={enumName(ScanRuleSet.ScanRuleSetStatus, scanRuleSetSet?.getStatus(), false)} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Account ID" type="number" value={scanRuleSetSet?.getAccountId() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={2}>
                  <TextField size="small" label="Published?" type="string" value={scanRuleSetSet?.getPublished().toString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Audit</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Created" type="string" value={scanRuleSetSet?.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={3}>
                  <TextField size="small" label="Updated" type="string" value={scanRuleSetSet?.getAuditData()?.getUpdated()?.toDate().toLocaleString() || ''} inputProps={{ readOnly: true}} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle1" component="div">Scan Rule Set Items</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ScanRuleSetItemTable scanRuleSetItems={scanRuleSetSet?.getScanRuleSetItemsList()} maxHeight={250}/>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}><br/></Grid>
            </Grid>
            </Box>
        </MasterContainer>
    );
};

export default ScanRuleSetDetail;