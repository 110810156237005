import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import { GetScanJobArtifactsRequest, GetScanJobArtifactsResponse, ScanJobArtifact } from '../generated/sp/scan_service/scan_service_pb';
import {PagingParameters, SortDirection} from '../generated/sp/service_common/common_pb';
import {ArtifactName, ScanArtifact} from "../generated/sp/scan_runner/scan_runner_pb";
import {
    Box,
    Grid,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
    IconButton, Tooltip
} from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import {BooleanEnum, enumName} from '../lib/enumUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {useNavigate} from "react-router-dom";
import SchoolOutlineIcon from "@mui-extra/icons/SchoolOutlineIcon";
import LinkIcon from '@mui/icons-material/Link';
import ScanTimelineImageArtifactViewButton from "./buttons/ScanTimelineImageArtifactViewButton";
import ScanTimelineTextArtifactViewButton from "./buttons/ScanTimelineTextArtifactViewButton";
import ScanTimelineLocalStorageDumpArtifactViewButton from "./buttons/ScanTimelineLocalStorageDumpArtifactViewButton";
import ScanTimelinePageSearchResultArtifactViewButton from "./buttons/ScanTimelinePageSearchResultArtifactViewButton";
import ScanTimelineConsentApiDataArtifactViewButton from "./buttons/ScanTimelineConsentApiDataArtifactViewButton";
import ScanTimelineDiagnoseEventsArtifactViewButton from "./buttons/ScanTimelineDiagnoseEventsArtifactViewButton";

const generateGetScanJobArtifactsRequest = (continuationToken: string | undefined, limit: number, sortField: GetScanJobArtifactsRequest.SortField, sortDirection: SortDirection, artifactTypeFilter: ScanArtifact.ArtifactType, artifactNameFilter: ArtifactName, trainingDataFilter: BooleanEnum, scanJobIdFilter: string | undefined, propertySetIdFilter: string |undefined): GetScanJobArtifactsRequest => {
  // determine filtering.
  var req = new GetScanJobArtifactsRequest();
  let filters = new GetScanJobArtifactsRequest.Filters();
  if( artifactTypeFilter > 0) {
    filters.addArtifactTypeFilter(artifactTypeFilter);
  }
  if( artifactNameFilter > 0) {
      filters.addArtifactNameFilter(artifactNameFilter);
  }
  if (trainingDataFilter != null) {
      switch(trainingDataFilter) {
          case BooleanEnum.TRUE: {
              filters.setTrainingDataSetFilter(true);
              break;
          }
          case BooleanEnum.FALSE: {
              filters.setTrainingDataSetFilter(false);
              break;
          }
          default: {
              // do nothing.. filter is unset.
              break;
          }
      }
  }
  if( scanJobIdFilter !== undefined && scanJobIdFilter.length > 0) {
    filters.addScanJobIdFilter(scanJobIdFilter);
  }
  if( propertySetIdFilter !== undefined && propertySetIdFilter.length > 0) {
    filters.addPropertySetIdFilter(propertySetIdFilter);
  }
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetScanJobArtifactsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchScanJobArtifacts = async (scanService: ScanServiceClient, request: GetScanJobArtifactsRequest): Promise<GetScanJobArtifactsResponse> => {
  return new Promise<GetScanJobArtifactsResponse>((resolve, reject) => {
    scanService.getScanJobArtifacts(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface ScanJobArtifactInfinityTableProps {
  maxHeight: number;
  showFilterUi: boolean;
  showScanJobLink: boolean;
  showArtifactId: boolean;
  embeddedModeScanJobIdFilter?: string | undefined; // if defined, the table will render without infinity mode
  embeddedPropertySetIdFilter?: string | undefined;
  forceRefresh?: number;
}

const ScanJobArtifactInfinityTable: FC<ScanJobArtifactInfinityTableProps> = (props): ReactElement => {
    const [scanJobArtifacts, setScanJobArtifacts] = useState<ScanJobArtifact[]>([]);
    const [scanArtifactTypeFilter, setScanArtifactTypeFilter] = useState<ScanArtifact.ArtifactType>(ScanArtifact.ArtifactType.ARTIFACT_TYPE_UNSET);
    const [scanArtifactNameFilter, setScanArtifactNameFilter] = useState<ArtifactName>(ArtifactName.ARTIFACT_NAME_UNSET);
    const [trainingDataFilter, setTrainingDataFilter] = useState<BooleanEnum>(BooleanEnum.UNSET);
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetScanJobArtifactsRequest.SortField>(GetScanJobArtifactsRequest.SortField.SORT_FIELD_CREATED_TIME);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_DESC);
    const [limit] = useState<number>(50);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToScanJobArtifactTraining = (scanJobArtifactId: String) => {
        window.open(`/scanJobTraining/scanJobArtifact/${scanJobArtifactId}`,'_blank');
    };

    const navigateToScanJob = (scanJobId: String) => {
        navigate(`/scanJobs/${scanJobId}`);
    };

    useEffect(() => {
        (async () => {
            setScanJobArtifacts([]);
            setRefreshToggle(true);
        })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading scan job artifact list data, scanJobId=[${props.embeddedModeScanJobIdFilter}]`);
        setLoading(true);
        let maybePropertySetIdFilter = props.embeddedPropertySetIdFilter;
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetScanJobArtifactsRequest(currentContinuationToken, limit, sortField, sortDirection, scanArtifactTypeFilter, scanArtifactNameFilter, trainingDataFilter, props.embeddedModeScanJobIdFilter, maybePropertySetIdFilter);
        const scanJobArtifactsResponse = await fetchScanJobArtifacts(scanService, req);
        let continuationToken = scanJobArtifactsResponse.getContinuationToken().length > 0 ? scanJobArtifactsResponse.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        if (props.embeddedModeScanJobIdFilter !== undefined) {
          // always set the entire list, not infinity mode
          setScanJobArtifacts(scanJobArtifactsResponse.getScanJobArtifactsList());
        } else {
          // to support scrolling, we need to append to whatever is currently loaded, infinity mode
          setScanJobArtifacts(old => [...old, ...scanJobArtifactsResponse.getScanJobArtifactsList()]);
        }
        setLoading(false);
      })();
    }, [currentContinuationToken, limit, scanArtifactTypeFilter, scanArtifactNameFilter, trainingDataFilter, sortDirection, sortField, refreshToggle]);

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
        setScanJobArtifacts([]); // wipe out data such that we don't keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handleScanArtifactTypeFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== scanArtifactTypeFilter) {
            startFreshSearch();
            setScanArtifactTypeFilter(revised);
        }
    };

    const handleScanArtifactNameFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== scanArtifactNameFilter) {
            startFreshSearch();
            setScanArtifactNameFilter(revised);
        }
    };

    const handleTrainingFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== trainingDataFilter.valueOf()) {
            startFreshSearch();
            setTrainingDataFilter(revised);
        }
    };

    const handleSortRequest = (sortField: GetScanJobArtifactsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    const determineArtifactButton = (scanJobId: string, scanArtifact: ScanArtifact | undefined): ReactElement | undefined => {
        if (scanArtifact !== undefined) {
            switch (scanArtifact.getArtifactType()) {
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_IMAGE:
                    return <ScanTimelineImageArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_TEXT:
                    return <ScanTimelineTextArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_LOCAL_STORE_DUMP:
                    return <ScanTimelineLocalStorageDumpArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_PAGE_SEARCH_RESULT:
                    return <ScanTimelinePageSearchResultArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_CONSENT_API_DATA:
                    return <ScanTimelineConsentApiDataArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_DOM_SNAPSHOT:
                    return <ScanTimelineTextArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                case ScanArtifact.ArtifactType.ARTIFACT_TYPE_DIAGNOSE_EVENTS:
                    return <ScanTimelineDiagnoseEventsArtifactViewButton useLoadingButton={false} scanJobId={scanJobId} artifactId={scanArtifact.getArtifactId()}/>;
                default:
                    return undefined;
            }
        }
        return undefined;
    };
    return (
        <div>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
               { props.showFilterUi &&
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter ScanJobArtifacts</Typography>
                  </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel id="artifactType">Artifact Type</InputLabel>
                        <Select size="small" labelId="artifactType" id="artifactType" value={scanArtifactTypeFilter.toString()} label="artifactType" onChange={handleScanArtifactTypeFilterChange}>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_UNSET}>All</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_IMAGE}>Image</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_TEXT}>Text</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_LOCAL_STORE_DUMP}>Local Store Dump</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_PAGE_SEARCH_RESULT}>Page Search Result</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_CONSENT_API_DATA}>Consent API Data</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_DOM_SNAPSHOT}>DOM Snapshot</MenuItem>
                          <MenuItem value={ScanArtifact.ArtifactType.ARTIFACT_TYPE_DIAGNOSE_EVENTS}>Diagnose Events</MenuItem>
                        </Select>
                      </FormControl>
                  </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="artifactName">Artifact Name</InputLabel>
                            <Select size="small" labelId="artifactName" id="artifactName" value={scanArtifactNameFilter.toString()} label="artifactName" onChange={handleScanArtifactNameFilterChange}>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_UNSET}>All</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_PAGE_VIEWPORT}>Consent Page Viewport</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_OUTER_FRAME_ELEMENT}>Consent Outer Frame Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_MAIN_ELEMENT}>Consent Main Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_ACCEPT_ALL_ELEMENT}>Accept All Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_ACCEPT_NECESSARY_ELEMENT}>Accept Necessary Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_REJECT_ALL_ELEMENT}>Reject All Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_COOKIE_SETTINGS_ELEMENT}>Cookie Settings Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_VENDOR_SETTINGS_ELEMENT}>Vendor Settings Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_PRIVACY_POLICY_LINK_ELEMENT}>Privacy Policy Link Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_COOKIE_POLICY_LINK_ELEMENT}>Cookie Policy Link Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CCPA_OPTOUT_LINK_ELEMENT}>CCPA Optout Link Element</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_MAIN_PAGE_VIEWPORT}>Main Page Viewport</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_MAIN_PAGE_FULL}>Main Page Full</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_TEXT}>Consent Text</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_PRIVACY_POLICY_PAGE_FULL}>Privacy Policy Page Full</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_PRIVACY_POLICY_PAGE_TEXT}>Privacy Policy Page Text</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_TOOL_PAGE_SEARCH_RESULT}>Consent Tool Page Search Result</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_MAIN_PAGE_SEARCH_RESULT}>Main Page Search Result</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_PRIVACY_POLICY_PAGE_SEARCH_RESULT}>Privacy Policy Page Search Result</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_COOKIE_POLICY_PAGE_SEARCH_RESULT}>Cookie Policy Page Search Result</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_COOKIES_BEFORE_CONSENT}>Cookies Before Consent</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_COOKIES_AFTER_CONSENT}>Cookies After Consent</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_API_DATA_BEFORE_CONSENT}>Consent API Data Before Consent</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_CONSENT_API_DATA_AFTER_CONSENT}>Consent API Data After Consent</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_MAIN_PAGE_DOM}>Main Page DOM</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_DIAGNOSE_EVENTS}>Diagnose Events</MenuItem>
                                <MenuItem value={ArtifactName.ARTIFACT_NAME_WILDCARD_PAGE_VIEWPORT}>Wildcard Page Viewport</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                  <Grid item xs={2}>
                      <FormControl fullWidth>
                          <InputLabel id="trainingData">Training Data?</InputLabel>
                          <Select size="small" labelId="trainingData" id="trainingData" value={trainingDataFilter.toString()} label="trainingData" onChange={handleTrainingFilterChange}>
                              <MenuItem value={BooleanEnum.UNSET}>All</MenuItem>
                              <MenuItem value={BooleanEnum.FALSE}>Exists</MenuItem>
                              <MenuItem value={BooleanEnum.TRUE}>Does not Exist</MenuItem>
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                      <LoadingButton
                        size="small"
                        color="secondary"
                        onClick={handleRefresh}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<RefreshIcon />}
                        variant="contained"
                      >
                        Refresh
                      </LoadingButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">
                      Found {scanJobArtifacts.length} scan job artifacts
                    </Typography>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
            </Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="Scan Jobs">
              <TableHead>
                <StyledTableRow>
                  { props.showArtifactId &&
                  <StyledTableCell align="left">ID</StyledTableCell>
                  }
                  <StyledTableCell align="left">Artifact Type</StyledTableCell>
                  <StyledTableCell align="left" onClick={() => handleSortRequest(GetScanJobArtifactsRequest.SortField.SORT_FIELD_ARTIFACT_NAME)}>
                    <TableSortLabel active={sortField === GetScanJobArtifactsRequest.SortField.SORT_FIELD_ARTIFACT_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Artifact Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">Mime Type</StyledTableCell>
                  <StyledTableCell align="center" onClick={() => handleSortRequest(GetScanJobArtifactsRequest.SortField.SORT_FIELD_CREATED_TIME)}>
                    <TableSortLabel active={sortField === GetScanJobArtifactsRequest.SortField.SORT_FIELD_CREATED_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Created</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right">Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {scanJobArtifacts.map((row) => (
                  <StyledTableRow hover key={row.getScanJobId() + "-" + row.getId()}>
                      { props.showArtifactId &&
                          <StyledTableCell align="left">{row.getId()}</StyledTableCell>
                      }
                      <StyledTableCell align="left">{enumName(ScanArtifact.ArtifactType, row.getScanArtifact()?.getArtifactType(), false, "ARTIFACT_TYPE")}</StyledTableCell>
                      <StyledTableCell align="left">{enumName(ArtifactName, row.getScanArtifact()?.getArtifactName(), false, "ARTIFACT_NAME")}</StyledTableCell>
                      <StyledTableCell align="left">{row.getScanArtifact()?.getMimeType()}</StyledTableCell>
                      <StyledTableCell align="center">{row.getAuditData()?.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                      <StyledTableCell align="right">
                          <Box display="flex" alignItems="right" justifyContent="right">
                              {row.hasScanJobTrainingData() &&
                                <Tooltip title="Scan Job Training">
                                  <IconButton component="span" aria-label="Scan Job Artifact Training" color="secondary" size="small" onClick={() => navigateToScanJobArtifactTraining(row.getId()!)}><SchoolOutlineIcon/></IconButton>
                                </Tooltip>
                              }
                              { props.showScanJobLink &&
                                  <Tooltip title="Scan Job">
                                      <IconButton component="span" aria-label="Scan Job" color="secondary" size="small" onClick={() => navigateToScanJob(row.getScanJobId())}><LinkIcon/></IconButton>
                                  </Tooltip>
                              }
                              {determineArtifactButton(row.getScanJobId(), row.getScanArtifact())}
                          </Box>
                      </StyledTableCell>
                  </StyledTableRow>
                ))}
                {scanJobArtifacts.length === 0 &&
                    <StyledTableRow>
                        <StyledTableCell align="center" colSpan={5}><Typography style={{ fontWeight: 600 }} variant="subtitle2">No Data, still processing?</Typography></StyledTableCell>
                    </StyledTableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    );
};

export default ScanJobArtifactInfinityTable;