import React, {ReactElement, FC} from "react";
import {Table, TableBody, TableContainer, TableHead, TableSortLabel} from '@mui/material';
import Paper from '@mui/material/Paper';
import {AccuracyMetrics} from '../generated/sp/scan_service/scan_service_pb';
import {StyledTableCell, StyledTableRow} from '../lib/tableUtils';
import {Order, sortNumber, sortString, TableHeader} from "../lib/sortUtils";
import * as jspb from "google-protobuf";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {fetchScanRuleMapByName} from "../lib/scanServiceUtils";
import {useUpdateEffect} from "usehooks-ts";

interface PropertySetItemTableProps {
    accuracyMetrics: jspb.Map<string, AccuracyMetrics> | undefined;
    maxHeight: number;
}

interface TableRow {
    scanRuleShortName: string;
    scanRuleName: string;
    scanRuleShortNameSortable: number;
    correctCount: number;
    totalCount: number;
    falseNegatives: number;
    falsePositives: number;
    successRate: number;
}

const HEADER_SCAN_RULE_SHORT_NAME = "Rule";
const HEADER_SCAN_RULE_NAME = "Name";
const HEADER_CORRECT_COUNT = "Correct";
const HEADER_TOTAL_COUNT = "Total";
const HEADER_FALSE_NEGATIVES = "False (-)";
const HEADER_FALSE_POSITIVES = "False (+)";
const HEADER_SUCCESS_RATE = "Success Rate";

const headers: TableHeader[] = [
    {headerName: HEADER_SCAN_RULE_SHORT_NAME, align: 'left'},
    {headerName: HEADER_SCAN_RULE_NAME, align: 'left'},
    {headerName: HEADER_CORRECT_COUNT, align: 'center'},
    {headerName: HEADER_TOTAL_COUNT, align: 'center'},
    {headerName: HEADER_FALSE_NEGATIVES, align: 'center'},
    {headerName: HEADER_FALSE_POSITIVES, align: 'center'},
    {headerName: HEADER_SUCCESS_RATE, align: 'right'}]

const PropertySetItemTable: FC<PropertySetItemTableProps> = (props): ReactElement => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>(HEADER_SCAN_RULE_SHORT_NAME);
    const [rows, setRows] = React.useState<TableRow[]>([]);

    useUpdateEffect(() => {
        (async () => {
            const entries = props.accuracyMetrics?.entries();
            if( entries !== undefined) {
                const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
                const scanRuleMap = await fetchScanRuleMapByName(scanService);
                let tableRows = Array.from(entries).map(([scanRuleShortName, stats]) => {
                    return {
                        scanRuleShortName: scanRuleShortName,
                        scanRuleName: scanRuleMap?.get(scanRuleShortName)?.getName() || '',
                        scanRuleShortNameSortable: parseInt(scanRuleShortName.substring(1)), // assume ruleId is formatted like  'r17'
                        correctCount: stats.getCorrectCount(),
                        totalCount: stats.getTotalCount(),
                        falseNegatives: stats.getFalseNegatives(),
                        falsePositives: stats.getFalsePositives(),
                        successRate: ((stats.getCorrectCount() / stats.getTotalCount()) * 100)
                    };
                });
                setRows(tableRows);
                console.log(`Generated ${tableRows.length} table rows`);
            }
        })();
    }, [props]);

    const sortRows = (rows: TableRow[], orderBy: string, order: Order): TableRow[] => {
        if (orderBy === HEADER_SCAN_RULE_SHORT_NAME) {
            rows.sort(function (a, b) {
                return sortNumber(a.scanRuleShortNameSortable, b.scanRuleShortNameSortable, order);
            });
        } else if (orderBy === HEADER_SCAN_RULE_NAME) {
            rows.sort(function (a, b) {
                return sortString(a.scanRuleName, b.scanRuleName, order);
            });
        } else if (orderBy === HEADER_CORRECT_COUNT) {
            rows.sort(function (a, b) {
                return sortNumber(a.correctCount, b.correctCount, order);
            });
        } else if (orderBy === HEADER_TOTAL_COUNT) {
            rows.sort(function (a, b) {
                return sortNumber(a.totalCount, b.totalCount, order);
            });
        } else if (orderBy === HEADER_FALSE_NEGATIVES) {
            rows.sort(function (a, b) {
                return sortNumber(a.falseNegatives, b.falseNegatives, order);
            });
        } else if (orderBy === HEADER_FALSE_POSITIVES) {
            rows.sort(function (a, b) {
                return sortNumber(a.falsePositives, b.falsePositives, order);
            });
        } else if (orderBy === HEADER_SUCCESS_RATE) {
            rows.sort(function (a, b) {
                return sortNumber(a.successRate, b.successRate, order);
            });
        }
        return rows;
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, header: string) => {
        const isAsc = orderBy === header && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(header);
    };

    return (
        <TableContainer component={Paper} style={{maxHeight: props.maxHeight + "px"}}>
            <Table stickyHeader size="small" aria-label="Scan Rules">
                <TableHead>
                    <StyledTableRow>
                        {headers.map(tableHeader => (
                            <StyledTableCell key={tableHeader.headerName} align={tableHeader.align}>
                                <TableSortLabel active={orderBy === tableHeader.headerName}
                                                direction={orderBy === tableHeader.headerName ? order : 'asc'}
                                                onClick={e => handleRequestSort(e, tableHeader.headerName)}
                                >
                                    {tableHeader.headerName}
                                </TableSortLabel>
                            </StyledTableCell>
                        ))}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {sortRows(rows, orderBy, order).map(row => (
                        <StyledTableRow hover key={row.scanRuleShortName}>
                            <StyledTableCell align="left">{row.scanRuleShortName}</StyledTableCell>
                            <StyledTableCell align="left">{row.scanRuleName}</StyledTableCell>
                            <StyledTableCell align="center">{row.correctCount}</StyledTableCell>
                            <StyledTableCell align="center">{row.totalCount}</StyledTableCell>
                            <StyledTableCell align="center">{row.falseNegatives}</StyledTableCell>
                            <StyledTableCell align="center">{row.falsePositives}</StyledTableCell>
                            <StyledTableCell align="right">{row.successRate.toFixed(2)}%</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PropertySetItemTable;