import React, {ReactElement, FC} from "react";
import { Button } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { SourcePointTokens, fetchSourcePointTokens } from '../../lib/authorizationUtils';
import {removeSourcePointTokensFromStorage, setSourcePointTokensIntoStorage} from "../../lib/localStorageUtils";

interface GoogleLoginButtonProps {
    setSourcePointTokens: React.Dispatch<React.SetStateAction<SourcePointTokens | undefined>>;
}

const GoogleLoginButton: FC<GoogleLoginButtonProps> = (props): ReactElement => {
    const loginSuccess =  async (codeResponse: CodeResponse) => {
      await fetchSourcePointTokens(codeResponse)
      .then(sourcePointTokens => {
        props.setSourcePointTokens(sourcePointTokens); // this sets the source point tokens back up the parent chain.
        setSourcePointTokensIntoStorage(sourcePointTokens);
      })
      .catch(error => {
         console.log("Failed to grab oauth2 userInfo", error);
         removeSourcePointTokensFromStorage();
      });
    };

    const loginFailed =  async () => {
      console.log("Failed to login!");
      props.setSourcePointTokens(undefined); // this sets the source point tokens back up the parent chain.
      removeSourcePointTokensFromStorage();
    };

	const login = useGoogleLogin({
	    flow: 'auth-code',
		onSuccess: (codeResponse) => loginSuccess(codeResponse),
		onError: () => loginFailed(),
	});

	return (
		<div>
		    <Button variant="contained" size="small" color="secondary" onClick={() => login()} endIcon={<LoginIcon />}>Login</Button>
		</div>
	);
};

export default GoogleLoginButton;