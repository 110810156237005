import React, {ReactElement, FC, useContext} from "react";
import {Grid, Typography} from "@mui/material";
import MasterContainer from "../components/containers/MasterContainer";
import LatestScanReportCard from "../components/cards/LatestActiveScanReportsCard";
import {isUserStandardRole} from "../lib/authorizationUtils";
import {SourcePointTokensContext} from "../App";
import ScanJobReportCard from "../components/cards/ScanJobReportCard";
import CmpScanJobReportCard from "../components/cards/CmpScanJobReportCard";
import CmpScanJobStatusReportsCard from "../components/cards/CmpScanJobStatusReportsCard";

const Home: FC<any> = (): ReactElement => {
    const sourcePointTokens = useContext(SourcePointTokensContext);
    return (
        <MasterContainer>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sx={{ mt: 2}}>
                    <Typography style={{fontWeight: 600}}
                                variant="subtitle1" component="div">Welcome to the Engineering Console</Typography>
                </Grid>
                {isUserStandardRole(sourcePointTokens) &&
                  <Grid item xs={6} sx={{ mt: 2}}>
                      <ScanJobReportCard maxHeight={250}/>
                  </Grid>
                }
                {isUserStandardRole(sourcePointTokens) &&
                  <Grid item xs={6} sx={{ mt: 2}}>
                      <CmpScanJobReportCard maxHeight={250}/>
                  </Grid>
                }
                {isUserStandardRole(sourcePointTokens) &&
                  <Grid item xs={12} sx={{ mt: 2}}>
                      <CmpScanJobStatusReportsCard maxHeight={250} showFilterUi={true}/>
                  </Grid>
                }
                {isUserStandardRole(sourcePointTokens) &&
                  <Grid item xs={12} sx={{ mt: 2}}>
                    <LatestScanReportCard maxHeight={600} showFilterUi={true}/>
                  </Grid>
                }
            </Grid>
        </MasterContainer>
    );
};

export default Home;