import React, {ReactElement, FC} from "react";
import {Table, TableBody, TableContainer, TableHead, TableSortLabel} from '@mui/material';
import Paper from '@mui/material/Paper';
import {ScanRuleSet} from '../generated/sp/scan_service/scan_service_pb';
import { enumName } from '../lib/enumUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import {Link} from "react-router-dom";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {fetchScanRuleMapById} from "../lib/scanServiceUtils";
import {Order, sortNumber, sortString, TableHeader} from "../lib/sortUtils";
import {useUpdateEffect} from "usehooks-ts";


interface ScanRuleSetItemTableProps {
  scanRuleSetItems: ScanRuleSet.ScanRuleSetItem[] | undefined;
  maxHeight: number;
}

interface TableRow {
  scanRuleId: string;
  scanRuleIdSortable: number;
  scanRuleShortName: string;
  scanRuleName: string;
  preference: string;
  weighting: number;
}

const HEADER_SCAN_RULE_SHORT_NAME = "Rule";
const HEADER_SCAN_RULE_NAME = "Name";
const HEADER_PREFERENCE = "Preference";
const HEADER_WEIGHTING = "Weighting";

const headers: TableHeader[] = [
  {headerName: HEADER_SCAN_RULE_SHORT_NAME, align: 'left'},
  {headerName: HEADER_SCAN_RULE_NAME, align: 'left'},
  {headerName: HEADER_PREFERENCE, align: 'center'},
  {headerName: HEADER_WEIGHTING, align: 'center'}]

const ScanRuleSetItemTable: FC<ScanRuleSetItemTableProps> = (props): ReactElement => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>(HEADER_SCAN_RULE_SHORT_NAME);
  const [rows, setRows] = React.useState<TableRow[]>([]);

  useUpdateEffect(() => {
    (async () => {
      if( props.scanRuleSetItems !== undefined) {
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanRuleMap = await fetchScanRuleMapById(scanService);
        const tableRows = props.scanRuleSetItems?.map((item) => {
          let scanRule = scanRuleMap?.get(item.getScanRuleId());
          return {
            scanRuleId: item.getScanRuleId(),
            scanRuleIdSortable: parseInt(scanRule?.getShortName().substring(1) || ''), // assume ruleId is formatted like  'r17'
            scanRuleShortName: scanRule?.getShortName() || '',
            scanRuleName: scanRule?.getName() || '',
            preference: enumName(ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference, item.getItemPreference(), false, "SCAN_RULE_SET_ITEM_PREFERENCE"),
            weighting: item.getWeight(),
          };
        });
        setRows(tableRows);
        console.log(`Generated ${tableRows.length} table rows`);
      }
    })();
  }, [props]);

  const sortRows = (rows: TableRow[], orderBy: string, order: Order): TableRow[] => {
    if (orderBy === HEADER_SCAN_RULE_SHORT_NAME) {
      rows.sort(function (a, b) {
        return sortNumber(a.scanRuleIdSortable, b.scanRuleIdSortable, order);
      });
    } else if (orderBy === HEADER_SCAN_RULE_NAME) {
      rows.sort(function (a, b) {
        return sortString(a.scanRuleName, b.scanRuleName, order);
      });
    } else if (orderBy === HEADER_PREFERENCE) {
      rows.sort(function (a, b) {
        return sortString(a.preference, b.preference, order);
      });
    } else if (orderBy === HEADER_WEIGHTING) {
      rows.sort(function (a, b) {
        return sortNumber(a.weighting, b.weighting, order);
      });
    }
    return rows;
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, header: string) => {
    const isAsc = orderBy === header && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header);
  };

  return (
      <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }}>
        <Table stickyHeader size="small" aria-label="Scan Rules">
          <TableHead>
            <StyledTableRow>
              {headers.map(tableHeader => (
                  <StyledTableCell key={tableHeader.headerName} align={tableHeader.align}>
                    <TableSortLabel active={orderBy === tableHeader.headerName}
                                    direction={orderBy === tableHeader.headerName ? order : 'asc'}
                                    onClick={e => handleRequestSort(e, tableHeader.headerName)}
                    >
                      {tableHeader.headerName}
                    </TableSortLabel>
                  </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortRows(rows, orderBy, order).map(row => (
                <StyledTableRow hover key={row.scanRuleId}>
                  <StyledTableCell align="left">
                    <Link to={`/scanRules/${row.scanRuleId}`} style={{ textDecoration: 'none', color: 'black' }}>
                      {row.scanRuleShortName}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.scanRuleName}</StyledTableCell>
                  <StyledTableCell align="center">{row.preference}</StyledTableCell>
                  <StyledTableCell align="center">{row.weighting}</StyledTableCell>
                </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}

export default ScanRuleSetItemTable;