import React, {ReactElement, FC} from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from "@mui/system";
import CmpScanJobDoughnutChart from "../charts/CmpScanJobDoughnutChart";

interface ScanJobReportCardProps {
    maxHeight: number;
    sx?: SxProps<Theme>;
}

const CmpScanJobReportCard: FC<ScanJobReportCardProps> = (props): ReactElement => {
    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1, mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">CMP Report - Past 24 hours</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CmpScanJobDoughnutChart maxHeight={props.maxHeight} daysBack={1} minCount={5}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CmpScanJobReportCard;