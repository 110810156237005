import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import {Box, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField, Typography} from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import {ScanServiceInternalClient} from "../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {
  GetLatestActiveScanReportsRequest,
  GetLatestActiveScanReportsResponse,
  LatestActiveScanReport
} from '../generated/sp/scan_service/scan_service_internal_pb';
import {PagingParameters, SortDirection, StringFilter, StringList, TimestampFilter} from '../generated/sp/service_common/common_pb';
import { useNavigate} from "react-router-dom";
import {generateAuthHeader} from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import {formatRegion, regionSelectorItems} from "../lib/regionUtils";
import {getCurrentTimestampMinusDays} from "../lib/timestampUtils";
import {fetchPropertySetsMapById} from "../lib/scanServiceUtils";
import {ScanServiceClient} from "../generated/sp/scan_service/scan_service_grpc_web_pb";
import {PropertySet} from "../generated/sp/scan_service/scan_service_pb";
import {SELECTOR_ALL_ID} from "../lib/selectorUtils";
import {CsvHeader} from "../lib/exportCsvUtils";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";

const generateGetLatestScanReportsRequest = (continuationToken: string | undefined, limit: number, sortField: GetLatestActiveScanReportsRequest.SortField, sortDirection: SortDirection, regionFilter: string, olderThanDaysFilter: number, propertyNameFilter: string | undefined, propertySetFilter: string): GetLatestActiveScanReportsRequest => {
  var req = new GetLatestActiveScanReportsRequest();
  let filters = new GetLatestActiveScanReportsRequest.Filters();
  if( regionFilter.length > 0 && regionFilter !== SELECTOR_ALL_ID) {
    filters.addRegionIdFilter(regionFilter);
  }
  if( propertySetFilter.length > 0 && propertySetFilter !== SELECTOR_ALL_ID) {
    filters.addPropertySetIdFilter(propertySetFilter);
  }
  // convert older than days to date filter
  filters.setLastScannedFilter(new TimestampFilter().setOnOrBefore(getCurrentTimestampMinusDays(olderThanDaysFilter)))
  if( propertyNameFilter !== undefined && propertyNameFilter.length > 0) {
    filters.addPropertyNameFilter(new StringFilter().setCaseInsensitive(true).setContains(new StringList().addValues(propertyNameFilter)));
  }
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetLatestActiveScanReportsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchLatestActiveScanReports = async (scanServiceInternal: ScanServiceInternalClient, request: GetLatestActiveScanReportsRequest): Promise<GetLatestActiveScanReportsResponse> => {
  return new Promise<GetLatestActiveScanReportsResponse>((resolve, reject) => {
      scanServiceInternal.getLatestActiveScanReports(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface LatestScanReportInfinityTableProps {
  maxHeight: number;
  showFilterUi: boolean;
  forceRefresh?: number;
}

const LatestActiveScanReportInfinityTable: FC<LatestScanReportInfinityTableProps> = (props): ReactElement => {
    const [latestScanReports, setLatestScanReports] = useState<LatestActiveScanReport[]>([]);
    const [propertySets, setPropertySets] = useState<PropertySet[] | undefined>([]);
    const [regionFilter, setRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [propertySetFilter, setPropertySetFilter] = useState<string>(SELECTOR_ALL_ID);
    const [olderThanDaysFilter, setOlderThanDaysFilter] = useState<number>(200);
    const [propertyNameFilter, setPropertyNameFilter] = useState<string | undefined>(undefined);
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetLatestActiveScanReportsRequest.SortField>(GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_LAST_SCANNED);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_ASC);
    const [limit] = useState<number>(250);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToProperty = (propertyName: String) => {
      navigate(`/properties/${propertyName}`);
    };
    const navigateToPropertySet = (propertySetId: String) => {
      navigate(`/sets/property/${propertySetId}`);
    };

    const navigateToAccountSetting = (accountSettingId: String) => {
      navigate(`/sets/accountSettings/${accountSettingId}`);
    };

    useEffect(() => {
      (async () => {
        setLatestScanReports([]);
        setRefreshToggle(true);
      })();
    }, []);

    useUpdateEffect(() => {
        (() => {
            console.log("Marking table as stale...")
            handleRefresh();
        })();
    }, [props.forceRefresh]);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading latest scan reports list data`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const propertySetMap = await fetchPropertySetsMapById(scanService);
        setPropertySets(Array.from(propertySetMap.values()));
        const req = generateGetLatestScanReportsRequest(currentContinuationToken, limit, sortField, sortDirection, regionFilter, olderThanDaysFilter, propertyNameFilter, propertySetFilter);
        const response = await fetchLatestActiveScanReports(scanServiceInternal, req);
        let continuationToken = response.getContinuationToken().length > 0 ? response.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        // to support scrolling, we need to append to whatever is currently loaded
        setLatestScanReports(old => [...old, ...response.getLatestScanReportsList()]);
        setLoading(false);
      })();
    }, [currentContinuationToken, limit, regionFilter, propertySetFilter, sortDirection, sortField, refreshToggle]);

    const exportCsvHeaders = (): CsvHeader[] => {
      return [
        { label: "Region", key: "region" },
        { label: "Property Name", key: "propertyName" },
        { label: "Property Set Name", key: "propertySetName" },
        { label: "Account Id", key: "accountId" },
        { label: "Last Scanned", key: "lastScanned" },
      ]
    }

    interface CsvExportData {
      propertyName: string;
      propertySetName: string;
      accountId: number;
      region: string;
      lastScanned: string;
    }

    const exportCsvData = (): CsvExportData[] => {
      return latestScanReports.map((report) => {
        return {
          propertyName: report.getPropertyName(),
          propertySetName: report.getPropertySetName(),
          accountId: report.getAccountId(),
          region: formatRegion(report.getRegionId()),
          lastScanned: report.getLastScanned()?.toDate().toLocaleString() || ''
        };
      });
    }

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
      tableRef.addEventListener('scroll', scrollListener)
      return () => {
        tableRef.removeEventListener('scroll', scrollListener)
      }
    }, [scrollListener])

    const startFreshSearch = () => {
        setLatestScanReports([]); // wipe out data such that we dont keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handleSortRequest = (sortField: GetLatestActiveScanReportsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRegionFilterChange = (event: SelectChangeEvent) => {
      let revised = event.target.value;
      if ( revised !== regionFilter) {
        startFreshSearch();
        setRegionFilter(revised);
      }
    };

    const handlePropertSetFilterChange = (event: SelectChangeEvent) => {
      let revised = event.target.value;
      if ( revised !== propertySetFilter) {
        startFreshSearch();
        setPropertySetFilter(revised);
      }
    };

    const handleOlderThanDaysFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let revised = parseInt(event.target.value);
      setOlderThanDaysFilter(revised);
    };

    const handlePropertyNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPropertyNameFilter(event.target.value);
    };

    const handlePropertyNameFilterKeyChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if( event.key === 'Enter' ){
        handleRefresh();
      }
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            { props.showFilterUi &&
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter Latest Active Scan Reports</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="region">Region</InputLabel>
                            <Select size="small" labelId="region" id="region" value={regionFilter} label="Region" onChange={handleRegionFilterChange}>
                              {regionSelectorItems().map((region) => (
                                <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                              ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="region">Property Set</InputLabel>
                            <Select size="small" labelId="propertySet" id="propertySet" value={propertySetFilter} label="PropertySet" onChange={handlePropertSetFilterChange}>
                              <MenuItem key={SELECTOR_ALL_ID} value={SELECTOR_ALL_ID}>ALL</MenuItem>
                              {propertySets?.map((propertySet) => (
                                <MenuItem key={propertySet.getId()} value={propertySet.getId()}>{propertySet.getName()}</MenuItem>
                              ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField size="small" label="Older than (days)" type="number" value={olderThanDaysFilter || ''} onChange={x=> handleOlderThanDaysFilterChange(x)}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="Search by Property Name" type="string" value={propertyNameFilter || ''} onChange={x=> handlePropertyNameFilterChange(x)}  onKeyPress={x=> handlePropertyNameFilterKeyChange(x)} fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleRefresh}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<RefreshIcon />}
                            variant="contained"
                        >
                            Refresh
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {latestScanReports.length} Latest Active Scan Reports</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" alignItems="right" justifyContent="right">
                            <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"latestActiveScanReport-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                                <TableArrowDownIcon sx={{color: "secondary"}}/>
                            </CSVLink>
                        </Box>
                    </Grid>
                </Grid>
            }
          </Grid>
          <Grid item xs={12}>
            {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
          </Grid>
          <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader size="small" aria-label="Latest Active Scan Reports">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" width="20%" onClick={() => handleSortRequest(GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_PROPERTY_NAME)}>
                    <TableSortLabel active={sortField === GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_PROPERTY_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left" width="30%" onClick={() => handleSortRequest(GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_PROPERTY_SET_NAME)}>
                    <TableSortLabel active={sortField === GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_PROPERTY_SET_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Property Set</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left" width="5%" onClick={() => handleSortRequest(GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_ACCOUNT_ID)}>
                    <TableSortLabel active={sortField === GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_ACCOUNT_ID} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Account</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="5%" onClick={() => handleSortRequest(GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_REGION_ID)}>
                      <TableSortLabel active={sortField === GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_REGION_ID} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Region</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" width="20%" onClick={() => handleSortRequest(GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_LAST_SCANNED)}>
                      <TableSortLabel active={sortField === GetLatestActiveScanReportsRequest.SortField.SORT_FIELD_LAST_SCANNED} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Last Scanned</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {latestScanReports?.map((row) => (
                  <StyledTableRow hover key={row.getPropertyId() + row.getRegionId()}>
                    <StyledTableCell align="left" onClick={() => navigateToProperty(`${row.getPropertyName()}`)} sx= {{ cursor: "pointer" }}>{row.getPropertyName()}</StyledTableCell>
                    <StyledTableCell align="left" onClick={() => navigateToPropertySet(`${row.getPropertySetId()}`)} sx= {{ cursor: "pointer" }}>{row.getPropertySetName()}</StyledTableCell>
                    <StyledTableCell align="left" onClick={() => navigateToAccountSetting(`${row.getAccountSettingId()}`)} sx= {{ cursor: "pointer" }}>{row.getAccountId()}</StyledTableCell>
                    <StyledTableCell align="center">{formatRegion(row.getRegionId())}</StyledTableCell>
                    <StyledTableCell align="right">{row.getLastScanned()?.toDate().toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    );
}

export default LatestActiveScanReportInfinityTable;