// source: sp/scan_service/scan_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var sp_service_common_common_pb = require('../../sp/service_common/common_pb.js');
goog.object.extend(proto, sp_service_common_common_pb);
var sp_scan_runner_common_pb = require('../../sp/scan_runner/common_pb.js');
goog.object.extend(proto, sp_scan_runner_common_pb);
var sp_scan_runner_scan_runner_pb = require('../../sp/scan_runner/scan_runner_pb.js');
goog.object.extend(proto, sp_scan_runner_scan_runner_pb);
goog.exportSymbol('proto.sp.scan_service.AccountSettings', null, global);
goog.exportSymbol('proto.sp.scan_service.AccountSettings.AccountSettingsStatus', null, global);
goog.exportSymbol('proto.sp.scan_service.AccountSettingsData', null, global);
goog.exportSymbol('proto.sp.scan_service.AccountSettingsData.Permission', null, global);
goog.exportSymbol('proto.sp.scan_service.AccuracyMetrics', null, global);
goog.exportSymbol('proto.sp.scan_service.AddItemsToPropertySetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.AddItemsToPropertySetResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ComplianceStandard', null, global);
goog.exportSymbol('proto.sp.scan_service.CreateAccountSettingsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.CreateAccountSettingsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.CreatePropertyRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.CreatePropertyResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.CreatePropertySetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.CreatePropertySetResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.CreateScanRuleSetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.CreateScanRuleSetResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsByIdResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetAccountSettingsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertiesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertiesRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertiesRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertiesRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertiesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertyByIdOrNameRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertyByIdOrNameRequest.IdOrNameCase', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertyByIdOrNameResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetByIdResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySetsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotByIdResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetPropertySnapshotsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetRegionResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetRegionsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetRegionsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobArtifactsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanJobsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleByIdResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetByIdRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetByIdResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetsRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetsRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRuleSetsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRulesRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRulesRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRulesRequest.SortField', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRulesRequest.SortParameter', null, global);
goog.exportSymbol('proto.sp.scan_service.GetScanRulesResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTimelineArtifactRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTimelineArtifactResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTimelineRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTimelineRequest.Filters', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTimelineRequest.ScanEventType', null, global);
goog.exportSymbol('proto.sp.scan_service.GetTimelineResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.Property', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.PropertyStats', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.PropertyType', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.RegionResult', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.RegionalResults', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.RelevantScanJob', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.RelevantScanJob.Relevancy', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.ResultType', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.RuleAccuracy', null, global);
goog.exportSymbol('proto.sp.scan_service.Property.RuleResult', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySet', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySet.PropertySetField', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySet.PropertySetItem', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySet.PropertySetStats', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySet.PropertySetStatus', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySnapshot', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySnapshot.Data', null, global);
goog.exportSymbol('proto.sp.scan_service.PropertySnapshot.PropertySnapshotField', null, global);
goog.exportSymbol('proto.sp.scan_service.Region', null, global);
goog.exportSymbol('proto.sp.scan_service.RemoveItemsFromPropertySetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.RemoveItemsFromPropertySetResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ConsentResult', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.Parameters', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.PodTerminationStatus', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanJobDebugData', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanJobField', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanJobGating', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanJobGating.GatingType', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanJobResult', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanJobStatus', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJob.ScanType', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRule', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRule.Category', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRule.ProductionStatus', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRule.ScanRuleField', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRuleSet', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRuleSet.ScanRuleSetField', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference', null, global);
goog.exportSymbol('proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateAccountSettingsRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateAccountSettingsResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdatePropertySetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdatePropertySetResponse', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateScanRuleSetRequest', null, global);
goog.exportSymbol('proto.sp.scan_service.UpdateScanRuleSetResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.AccountSettingsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.AccountSettingsData.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.AccountSettingsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.AccountSettingsData.displayName = 'proto.sp.scan_service.AccountSettingsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.AccountSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.AccountSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.AccountSettings.displayName = 'proto.sp.scan_service.AccountSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetAccountSettingsByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountSettingsByIdRequest.displayName = 'proto.sp.scan_service.GetAccountSettingsByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetAccountSettingsByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountSettingsByIdResponse.displayName = 'proto.sp.scan_service.GetAccountSettingsByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetAccountSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetAccountSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountSettingsRequest.displayName = 'proto.sp.scan_service.GetAccountSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetAccountSettingsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetAccountSettingsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetAccountSettingsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetAccountSettingsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountSettingsRequest.Filters.displayName = 'proto.sp.scan_service.GetAccountSettingsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetAccountSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetAccountSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetAccountSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetAccountSettingsResponse.displayName = 'proto.sp.scan_service.GetAccountSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreateAccountSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.CreateAccountSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.CreateAccountSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreateAccountSettingsRequest.displayName = 'proto.sp.scan_service.CreateAccountSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreateAccountSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CreateAccountSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreateAccountSettingsResponse.displayName = 'proto.sp.scan_service.CreateAccountSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateAccountSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateAccountSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateAccountSettingsRequest.displayName = 'proto.sp.scan_service.UpdateAccountSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.displayName = 'proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateAccountSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateAccountSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateAccountSettingsResponse.displayName = 'proto.sp.scan_service.UpdateAccountSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ComplianceStandard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ComplianceStandard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ComplianceStandard.displayName = 'proto.sp.scan_service.ComplianceStandard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Region = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.Region, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Region.displayName = 'proto.sp.scan_service.Region';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.displayName = 'proto.sp.scan_service.ScanJobArtifact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.displayName = 'proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.displayName = 'proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.displayName = 'proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.displayName = 'proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.displayName = 'proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.displayName = 'proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ScanJob.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ScanJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJob.displayName = 'proto.sp.scan_service.ScanJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJob.ScanJobGating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJob.ScanJobGating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJob.ScanJobGating.displayName = 'proto.sp.scan_service.ScanJob.ScanJobGating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJob.Parameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJob.Parameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJob.Parameters.displayName = 'proto.sp.scan_service.ScanJob.Parameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJob.ConsentResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJob.ConsentResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJob.ConsentResult.displayName = 'proto.sp.scan_service.ScanJob.ConsentResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJob.ScanJobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ScanJob.ScanJobResult.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ScanJob.ScanJobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJob.ScanJobResult.displayName = 'proto.sp.scan_service.ScanJob.ScanJobResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanJob.ScanJobDebugData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanJob.ScanJobDebugData.displayName = 'proto.sp.scan_service.ScanJob.ScanJobDebugData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobByIdRequest.displayName = 'proto.sp.scan_service.GetScanJobByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobResponse.displayName = 'proto.sp.scan_service.GetScanJobResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobsRequest.displayName = 'proto.sp.scan_service.GetScanJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetScanJobsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobsRequest.Filters.displayName = 'proto.sp.scan_service.GetScanJobsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.displayName = 'proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobsResponse.displayName = 'proto.sp.scan_service.GetScanJobsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobArtifactByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobArtifactByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobArtifactByIdRequest.displayName = 'proto.sp.scan_service.GetScanJobArtifactByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobArtifactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobArtifactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobArtifactResponse.displayName = 'proto.sp.scan_service.GetScanJobArtifactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobArtifactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobArtifactsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobArtifactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobArtifactsRequest.displayName = 'proto.sp.scan_service.GetScanJobArtifactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobArtifactsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.displayName = 'proto.sp.scan_service.GetScanJobArtifactsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanJobArtifactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanJobArtifactsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanJobArtifactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanJobArtifactsResponse.displayName = 'proto.sp.scan_service.GetScanJobArtifactsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTimelineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetTimelineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTimelineRequest.displayName = 'proto.sp.scan_service.GetTimelineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTimelineRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetTimelineRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetTimelineRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTimelineRequest.Filters.displayName = 'proto.sp.scan_service.GetTimelineRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTimelineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetTimelineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTimelineResponse.displayName = 'proto.sp.scan_service.GetTimelineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTimelineArtifactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetTimelineArtifactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTimelineArtifactRequest.displayName = 'proto.sp.scan_service.GetTimelineArtifactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetTimelineArtifactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetTimelineArtifactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetTimelineArtifactResponse.displayName = 'proto.sp.scan_service.GetTimelineArtifactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Property = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Property.displayName = 'proto.sp.scan_service.Property';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Property.RelevantScanJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.Property.RelevantScanJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Property.RelevantScanJob.displayName = 'proto.sp.scan_service.Property.RelevantScanJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Property.RuleResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.Property.RuleResult.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.Property.RuleResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Property.RuleResult.displayName = 'proto.sp.scan_service.Property.RuleResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Property.RegionResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.Property.RegionResult.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.Property.RegionResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Property.RegionResult.displayName = 'proto.sp.scan_service.Property.RegionResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Property.RegionalResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.Property.RegionalResults.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.Property.RegionalResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Property.RegionalResults.displayName = 'proto.sp.scan_service.Property.RegionalResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.Property.PropertyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.Property.PropertyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.Property.PropertyStats.displayName = 'proto.sp.scan_service.Property.PropertyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertySnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySnapshot.displayName = 'proto.sp.scan_service.PropertySnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySnapshot.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.PropertySnapshot.Data.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.PropertySnapshot.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySnapshot.Data.displayName = 'proto.sp.scan_service.PropertySnapshot.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySnapshotByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotByIdRequest.displayName = 'proto.sp.scan_service.GetPropertySnapshotByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotByIdResponse.displayName = 'proto.sp.scan_service.GetPropertySnapshotByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.displayName = 'proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.displayName = 'proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySnapshotsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotsRequest.displayName = 'proto.sp.scan_service.GetPropertySnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.displayName = 'proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.displayName = 'proto.sp.scan_service.GetPropertySnapshotsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySnapshotsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySnapshotsResponse.displayName = 'proto.sp.scan_service.GetPropertySnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.AccuracyMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.AccuracyMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.AccuracyMetrics.displayName = 'proto.sp.scan_service.AccuracyMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.PropertySet.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.PropertySet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySet.displayName = 'proto.sp.scan_service.PropertySet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySet.PropertySetItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertySet.PropertySetItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySet.PropertySetItem.displayName = 'proto.sp.scan_service.PropertySet.PropertySetItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.PropertySet.PropertySetStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.PropertySet.PropertySetStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.PropertySet.PropertySetStats.displayName = 'proto.sp.scan_service.PropertySet.PropertySetStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_);
};
goog.inherits(proto.sp.scan_service.GetPropertyByIdOrNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertyByIdOrNameRequest.displayName = 'proto.sp.scan_service.GetPropertyByIdOrNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertyByIdOrNameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertyByIdOrNameResponse.displayName = 'proto.sp.scan_service.GetPropertyByIdOrNameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreatePropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CreatePropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreatePropertyRequest.displayName = 'proto.sp.scan_service.CreatePropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreatePropertyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CreatePropertyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreatePropertyResponse.displayName = 'proto.sp.scan_service.CreatePropertyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertiesRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertiesRequest.displayName = 'proto.sp.scan_service.GetPropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertiesRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertiesRequest.SortParameter.displayName = 'proto.sp.scan_service.GetPropertiesRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertiesRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertiesRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertiesRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertiesRequest.Filters.displayName = 'proto.sp.scan_service.GetPropertiesRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertiesResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertiesResponse.displayName = 'proto.sp.scan_service.GetPropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreatePropertySetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.CreatePropertySetRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.CreatePropertySetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreatePropertySetRequest.displayName = 'proto.sp.scan_service.CreatePropertySetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreatePropertySetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CreatePropertySetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreatePropertySetResponse.displayName = 'proto.sp.scan_service.CreatePropertySetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdatePropertySetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UpdatePropertySetRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UpdatePropertySetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdatePropertySetRequest.displayName = 'proto.sp.scan_service.UpdatePropertySetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdatePropertySetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdatePropertySetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdatePropertySetResponse.displayName = 'proto.sp.scan_service.UpdatePropertySetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.AddItemsToPropertySetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.AddItemsToPropertySetRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.AddItemsToPropertySetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.AddItemsToPropertySetRequest.displayName = 'proto.sp.scan_service.AddItemsToPropertySetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.AddItemsToPropertySetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.AddItemsToPropertySetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.AddItemsToPropertySetResponse.displayName = 'proto.sp.scan_service.AddItemsToPropertySetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.RemoveItemsFromPropertySetRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.RemoveItemsFromPropertySetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RemoveItemsFromPropertySetRequest.displayName = 'proto.sp.scan_service.RemoveItemsFromPropertySetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.RemoveItemsFromPropertySetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.RemoveItemsFromPropertySetResponse.displayName = 'proto.sp.scan_service.RemoveItemsFromPropertySetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySetByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySetByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySetByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySetByIdRequest.displayName = 'proto.sp.scan_service.GetPropertySetByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySetByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySetByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySetByIdResponse.displayName = 'proto.sp.scan_service.GetPropertySetByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySetsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySetsRequest.displayName = 'proto.sp.scan_service.GetPropertySetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySetsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySetsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetPropertySetsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySetsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySetsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySetsRequest.Filters.displayName = 'proto.sp.scan_service.GetPropertySetsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetPropertySetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetPropertySetsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetPropertySetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetPropertySetsResponse.displayName = 'proto.sp.scan_service.GetPropertySetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetRegionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetRegionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetRegionResponse.displayName = 'proto.sp.scan_service.GetRegionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetRegionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetRegionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetRegionsRequest.displayName = 'proto.sp.scan_service.GetRegionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetRegionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetRegionsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetRegionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetRegionsResponse.displayName = 'proto.sp.scan_service.GetRegionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRuleByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleByIdRequest.displayName = 'proto.sp.scan_service.GetScanRuleByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleByIdResponse.displayName = 'proto.sp.scan_service.GetScanRuleByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRulesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRulesRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRulesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRulesRequest.displayName = 'proto.sp.scan_service.GetScanRulesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanRulesRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRulesRequest.SortParameter.displayName = 'proto.sp.scan_service.GetScanRulesRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRulesRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRulesRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRulesRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRulesRequest.Filters.displayName = 'proto.sp.scan_service.GetScanRulesRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRulesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRulesResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRulesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRulesResponse.displayName = 'proto.sp.scan_service.GetScanRulesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ScanRule.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ScanRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanRule.displayName = 'proto.sp.scan_service.ScanRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreateScanRuleSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.CreateScanRuleSetRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.CreateScanRuleSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreateScanRuleSetRequest.displayName = 'proto.sp.scan_service.CreateScanRuleSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.CreateScanRuleSetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.CreateScanRuleSetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.CreateScanRuleSetResponse.displayName = 'proto.sp.scan_service.CreateScanRuleSetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateScanRuleSetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.UpdateScanRuleSetRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.UpdateScanRuleSetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateScanRuleSetRequest.displayName = 'proto.sp.scan_service.UpdateScanRuleSetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.UpdateScanRuleSetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.UpdateScanRuleSetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.UpdateScanRuleSetResponse.displayName = 'proto.sp.scan_service.UpdateScanRuleSetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRuleSetByIdRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleSetByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleSetByIdRequest.displayName = 'proto.sp.scan_service.GetScanRuleSetByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleSetByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleSetByIdResponse.displayName = 'proto.sp.scan_service.GetScanRuleSetByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleSetsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRuleSetsRequest.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleSetsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleSetsRequest.displayName = 'proto.sp.scan_service.GetScanRuleSetsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.displayName = 'proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRuleSetsRequest.Filters.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleSetsRequest.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleSetsRequest.Filters.displayName = 'proto.sp.scan_service.GetScanRuleSetsRequest.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.GetScanRuleSetsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.GetScanRuleSetsResponse.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.GetScanRuleSetsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.GetScanRuleSetsResponse.displayName = 'proto.sp.scan_service.GetScanRuleSetsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanRuleSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sp.scan_service.ScanRuleSet.repeatedFields_, null);
};
goog.inherits(proto.sp.scan_service.ScanRuleSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanRuleSet.displayName = 'proto.sp.scan_service.ScanRuleSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.displayName = 'proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.AccountSettingsData.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.AccountSettingsData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.AccountSettingsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.AccountSettingsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AccountSettingsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    uniqueRegionPropertyCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    availableRuleIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    availableRegionIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.AccountSettingsData}
 */
proto.sp.scan_service.AccountSettingsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.AccountSettingsData;
  return proto.sp.scan_service.AccountSettingsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.AccountSettingsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.AccountSettingsData}
 */
proto.sp.scan_service.AccountSettingsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueRegionPropertyCount(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableRuleIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableRegionIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.AccountSettingsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.AccountSettingsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.AccountSettingsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AccountSettingsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUniqueRegionPropertyCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getAvailableRuleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAvailableRegionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.AccountSettingsData.Permission = {
  PERMISSION_UNSET: 0,
  PERMISSION_CAN_CREATE_RULE_SETS: 1
};

/**
 * optional int32 unique_region_property_count = 1;
 * @return {number}
 */
proto.sp.scan_service.AccountSettingsData.prototype.getUniqueRegionPropertyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.setUniqueRegionPropertyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Permission permissions = 2;
 * @return {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>}
 */
proto.sp.scan_service.AccountSettingsData.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} value
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.AccountSettingsData.Permission} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated string available_rule_ids = 3;
 * @return {!Array<string>}
 */
proto.sp.scan_service.AccountSettingsData.prototype.getAvailableRuleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.setAvailableRuleIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.addAvailableRuleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.clearAvailableRuleIdsList = function() {
  return this.setAvailableRuleIdsList([]);
};


/**
 * repeated string available_region_ids = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.AccountSettingsData.prototype.getAvailableRegionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.setAvailableRegionIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.addAvailableRegionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.AccountSettingsData} returns this
 */
proto.sp.scan_service.AccountSettingsData.prototype.clearAvailableRegionIdsList = function() {
  return this.setAvailableRegionIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.AccountSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.AccountSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.AccountSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AccountSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    data: (f = msg.getData()) && proto.sp.scan_service.AccountSettingsData.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    apiKeyId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.AccountSettings}
 */
proto.sp.scan_service.AccountSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.AccountSettings;
  return proto.sp.scan_service.AccountSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.AccountSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.AccountSettings}
 */
proto.sp.scan_service.AccountSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.AccountSettings.AccountSettingsStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 5:
      var value = new proto.sp.scan_service.AccountSettingsData;
      reader.readMessage(value,proto.sp.scan_service.AccountSettingsData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.AccountSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.AccountSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.AccountSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AccountSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sp.scan_service.AccountSettingsData.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.AccountSettings.AccountSettingsStatus = {
  ACCOUNT_SETTINGS_STATUS_UNSET: 0,
  ACCOUNT_SETTINGS_STATUS_ACTIVE: 1,
  ACCOUNT_SETTINGS_STATUS_DISABLED: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.AccountSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.AccountSettings.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
*/
proto.sp.scan_service.AccountSettings.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.AccountSettings.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AccountSettingsStatus status = 3;
 * @return {!proto.sp.scan_service.AccountSettings.AccountSettingsStatus}
 */
proto.sp.scan_service.AccountSettings.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.AccountSettings.AccountSettingsStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.AccountSettings.AccountSettingsStatus} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 account_id = 4;
 * @return {number}
 */
proto.sp.scan_service.AccountSettings.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AccountSettingsData data = 5;
 * @return {?proto.sp.scan_service.AccountSettingsData}
 */
proto.sp.scan_service.AccountSettings.prototype.getData = function() {
  return /** @type{?proto.sp.scan_service.AccountSettingsData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.AccountSettingsData, 5));
};


/**
 * @param {?proto.sp.scan_service.AccountSettingsData|undefined} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
*/
proto.sp.scan_service.AccountSettings.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.AccountSettings.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.sp.scan_service.AccountSettings.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp start_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.AccountSettings.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
*/
proto.sp.scan_service.AccountSettings.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.AccountSettings.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.AccountSettings.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
*/
proto.sp.scan_service.AccountSettings.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.AccountSettings.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string api_key_id = 9;
 * @return {string}
 */
proto.sp.scan_service.AccountSettings.prototype.getApiKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.setApiKeyId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.AccountSettings} returns this
 */
proto.sp.scan_service.AccountSettings.prototype.clearApiKeyId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.AccountSettings.prototype.hasApiKeyId = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountSettingsByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdRequest}
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountSettingsByIdRequest;
  return proto.sp.scan_service.GetAccountSettingsByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdRequest}
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountSettingsByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountSettingsByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountSettings: (f = msg.getAccountSettings()) && proto.sp.scan_service.AccountSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdResponse}
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountSettingsByIdResponse;
  return proto.sp.scan_service.GetAccountSettingsByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdResponse}
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.AccountSettings;
      reader.readMessage(value,proto.sp.scan_service.AccountSettings.deserializeBinaryFromReader);
      msg.setAccountSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountSettingsByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountSettingsByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.AccountSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional AccountSettings account_settings = 1;
 * @return {?proto.sp.scan_service.AccountSettings}
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.prototype.getAccountSettings = function() {
  return /** @type{?proto.sp.scan_service.AccountSettings} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.AccountSettings, 1));
};


/**
 * @param {?proto.sp.scan_service.AccountSettings|undefined} value
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdResponse} returns this
*/
proto.sp.scan_service.GetAccountSettingsByIdResponse.prototype.setAccountSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountSettingsByIdResponse} returns this
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.prototype.clearAccountSettings = function() {
  return this.setAccountSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountSettingsByIdResponse.prototype.hasAccountSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetAccountSettingsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetAccountSettingsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest}
 */
proto.sp.scan_service.GetAccountSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountSettingsRequest;
  return proto.sp.scan_service.GetAccountSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest}
 */
proto.sp.scan_service.GetAccountSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetAccountSettingsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetAccountSettingsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetAccountSettingsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetAccountSettingsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_ID: 1,
  SORT_FIELD_CREATED_TIME: 2,
  SORT_FIELD_UPDATED_TIME: 3,
  SORT_FIELD_ACCOUNT_ID: 4,
  SORT_FIELD_HAS_API_KEY: 5,
  SORT_FIELD_STATUS: 6,
  SORT_FIELD_NAME: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountSettingsRequest.SortParameter;
  return proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetAccountSettingsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.SortField}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetAccountSettingsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.repeatedFields_ = [1,2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountSettingsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusFilterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    accountIdFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountSettingsRequest.Filters;
  return proto.sp.scan_service.GetAccountSettingsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.AccountSettings.AccountSettingsStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusFilter(values[i]);
      }
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 4:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountIdFilter(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountSettingsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getAccountIdFilterList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * repeated AccountSettings.AccountSettingsStatus status_filter = 2;
 * @return {!Array<!proto.sp.scan_service.AccountSettings.AccountSettingsStatus>}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.getStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.AccountSettings.AccountSettingsStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.AccountSettings.AccountSettingsStatus>} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.setStatusFilterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.AccountSettings.AccountSettingsStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.addStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.clearStatusFilterList = function() {
  return this.setStatusFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
*/
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 4;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 4));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
*/
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated int32 account_id_filter = 5;
 * @return {!Array<number>}
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.getAccountIdFilterList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.setAccountIdFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.addAccountIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.Filters} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.Filters.prototype.clearAccountIdFilterList = function() {
  return this.setAccountIdFilterList([]);
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
*/
proto.sp.scan_service.GetAccountSettingsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetAccountSettingsRequest.Filters}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetAccountSettingsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetAccountSettingsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetAccountSettingsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
*/
proto.sp.scan_service.GetAccountSettingsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter>}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetAccountSettingsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
*/
proto.sp.scan_service.GetAccountSettingsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest.SortParameter}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetAccountSettingsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated ScanRuleSet.ScanRuleSetField fields = 4;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>}
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} value
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountSettingsRequest} returns this
 */
proto.sp.scan_service.GetAccountSettingsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetAccountSettingsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetAccountSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetAccountSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountSettingsList: jspb.Message.toObjectList(msg.getAccountSettingsList(),
    proto.sp.scan_service.AccountSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetAccountSettingsResponse}
 */
proto.sp.scan_service.GetAccountSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetAccountSettingsResponse;
  return proto.sp.scan_service.GetAccountSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetAccountSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetAccountSettingsResponse}
 */
proto.sp.scan_service.GetAccountSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.AccountSettings;
      reader.readMessage(value,proto.sp.scan_service.AccountSettings.deserializeBinaryFromReader);
      msg.addAccountSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetAccountSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetAccountSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetAccountSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.AccountSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetAccountSettingsResponse} returns this
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetAccountSettingsResponse} returns this
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AccountSettings account_settings = 2;
 * @return {!Array<!proto.sp.scan_service.AccountSettings>}
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.getAccountSettingsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.AccountSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.AccountSettings, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.AccountSettings>} value
 * @return {!proto.sp.scan_service.GetAccountSettingsResponse} returns this
*/
proto.sp.scan_service.GetAccountSettingsResponse.prototype.setAccountSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.AccountSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.AccountSettings}
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.addAccountSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.AccountSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetAccountSettingsResponse} returns this
 */
proto.sp.scan_service.GetAccountSettingsResponse.prototype.clearAccountSettingsList = function() {
  return this.setAccountSettingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.CreateAccountSettingsRequest.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreateAccountSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreateAccountSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateAccountSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uniqueRegionPropertyCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    availableRuleIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    availableRegionIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreateAccountSettingsRequest;
  return proto.sp.scan_service.CreateAccountSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreateAccountSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueRegionPropertyCount(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableRuleIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addAvailableRegionIds(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreateAccountSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreateAccountSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateAccountSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUniqueRegionPropertyCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getAvailableRuleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getAvailableRegionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 account_id = 1;
 * @return {number}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 unique_region_property_count = 2;
 * @return {number}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getUniqueRegionPropertyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setUniqueRegionPropertyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AccountSettingsData.Permission permissions = 3;
 * @return {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sp.scan_service.AccountSettingsData.Permission} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated string available_rule_ids = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getAvailableRuleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setAvailableRuleIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.addAvailableRuleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.clearAvailableRuleIdsList = function() {
  return this.setAvailableRuleIdsList([]);
};


/**
 * repeated string available_region_ids = 5;
 * @return {!Array<string>}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getAvailableRegionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setAvailableRegionIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.addAvailableRegionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.clearAvailableRegionIdsList = function() {
  return this.setAvailableRegionIdsList([]);
};


/**
 * optional google.protobuf.Timestamp start_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.CreateAccountSettingsRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreateAccountSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreateAccountSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreateAccountSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateAccountSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreateAccountSettingsResponse}
 */
proto.sp.scan_service.CreateAccountSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreateAccountSettingsResponse;
  return proto.sp.scan_service.CreateAccountSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreateAccountSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreateAccountSettingsResponse}
 */
proto.sp.scan_service.CreateAccountSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreateAccountSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreateAccountSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreateAccountSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateAccountSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.CreateAccountSettingsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreateAccountSettingsResponse} returns this
 */
proto.sp.scan_service.CreateAccountSettingsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateAccountSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uniqueRegionPropertyCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    permissionList: (f = msg.getPermissionList()) && proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.toObject(includeInstance, f),
    availableRuleIds: (f = msg.getAvailableRuleIds()) && sp_service_common_common_pb.StringList.toObject(includeInstance, f),
    availableRegionIds: (f = msg.getAvailableRegionIds()) && sp_service_common_common_pb.StringList.toObject(includeInstance, f),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateAccountSettingsRequest;
  return proto.sp.scan_service.UpdateAccountSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueRegionPropertyCount(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList;
      reader.readMessage(value,proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.deserializeBinaryFromReader);
      msg.setPermissionList(value);
      break;
    case 4:
      var value = new sp_service_common_common_pb.StringList;
      reader.readMessage(value,sp_service_common_common_pb.StringList.deserializeBinaryFromReader);
      msg.setAvailableRuleIds(value);
      break;
    case 5:
      var value = new sp_service_common_common_pb.StringList;
      reader.readMessage(value,sp_service_common_common_pb.StringList.deserializeBinaryFromReader);
      msg.setAvailableRegionIds(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateAccountSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPermissionList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.serializeBinaryToWriter
    );
  }
  f = message.getAvailableRuleIds();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_service_common_common_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getAvailableRegionIds();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      sp_service_common_common_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList;
  return proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated AccountSettingsData.Permission permissions = 1;
 * @return {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.AccountSettingsData.Permission>} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.sp.scan_service.AccountSettingsData.Permission} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 unique_region_property_count = 2;
 * @return {number}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getUniqueRegionPropertyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setUniqueRegionPropertyCount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearUniqueRegionPropertyCount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasUniqueRegionPropertyCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PermissionList permission_list = 3;
 * @return {?proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getPermissionList = function() {
  return /** @type{?proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList, 3));
};


/**
 * @param {?proto.sp.scan_service.UpdateAccountSettingsRequest.PermissionList|undefined} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setPermissionList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearPermissionList = function() {
  return this.setPermissionList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasPermissionList = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.service_common.StringList available_rule_ids = 4;
 * @return {?proto.sp.service_common.StringList}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getAvailableRuleIds = function() {
  return /** @type{?proto.sp.service_common.StringList} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.StringList, 4));
};


/**
 * @param {?proto.sp.service_common.StringList|undefined} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setAvailableRuleIds = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearAvailableRuleIds = function() {
  return this.setAvailableRuleIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasAvailableRuleIds = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sp.service_common.StringList available_region_ids = 5;
 * @return {?proto.sp.service_common.StringList}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getAvailableRegionIds = function() {
  return /** @type{?proto.sp.service_common.StringList} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.StringList, 5));
};


/**
 * @param {?proto.sp.service_common.StringList|undefined} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setAvailableRegionIds = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearAvailableRegionIds = function() {
  return this.setAvailableRegionIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasAvailableRegionIds = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp start_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
*/
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsRequest} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateAccountSettingsRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateAccountSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateAccountSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsResponse}
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateAccountSettingsResponse;
  return proto.sp.scan_service.UpdateAccountSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateAccountSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateAccountSettingsResponse}
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateAccountSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateAccountSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UpdateAccountSettingsResponse} returns this
 */
proto.sp.scan_service.UpdateAccountSettingsResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ComplianceStandard.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ComplianceStandard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ComplianceStandard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComplianceStandard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ComplianceStandard}
 */
proto.sp.scan_service.ComplianceStandard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ComplianceStandard;
  return proto.sp.scan_service.ComplianceStandard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ComplianceStandard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ComplianceStandard}
 */
proto.sp.scan_service.ComplianceStandard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ComplianceStandard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ComplianceStandard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ComplianceStandard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ComplianceStandard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.ComplianceStandard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComplianceStandard} returns this
 */
proto.sp.scan_service.ComplianceStandard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.ComplianceStandard.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.ComplianceStandard} returns this
*/
proto.sp.scan_service.ComplianceStandard.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ComplianceStandard} returns this
 */
proto.sp.scan_service.ComplianceStandard.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ComplianceStandard.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.sp.scan_service.ComplianceStandard.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ComplianceStandard} returns this
 */
proto.sp.scan_service.ComplianceStandard.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Region.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Region.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Region} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Region.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subRegionCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Region}
 */
proto.sp.scan_service.Region.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Region;
  return proto.sp.scan_service.Region.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Region} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Region}
 */
proto.sp.scan_service.Region.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubRegionCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Region.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Region.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Region} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Region.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.Region.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Region} returns this
 */
proto.sp.scan_service.Region.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_code = 2;
 * @return {string}
 */
proto.sp.scan_service.Region.prototype.getRegionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Region} returns this
 */
proto.sp.scan_service.Region.prototype.setRegionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_region_code = 3;
 * @return {string}
 */
proto.sp.scan_service.Region.prototype.getSubRegionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Region} returns this
 */
proto.sp.scan_service.Region.prototype.setSubRegionCode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.Region} returns this
 */
proto.sp.scan_service.Region.prototype.clearSubRegionCode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Region.prototype.hasSubRegionCode = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    scanJobId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scanArtifact: (f = msg.getScanArtifact()) && sp_scan_runner_scan_runner_pb.ScanArtifact.toObject(includeInstance, f),
    scanJobTrainingData: (f = msg.getScanJobTrainingData()) && proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.ScanJobArtifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact;
  return proto.sp.scan_service.ScanJobArtifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.ScanJobArtifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 4:
      var value = new sp_scan_runner_scan_runner_pb.ScanArtifact;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanArtifact.deserializeBinaryFromReader);
      msg.setScanArtifact(value);
      break;
    case 100:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.deserializeBinaryFromReader);
      msg.setScanJobTrainingData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScanArtifact();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_scan_runner_scan_runner_pb.ScanArtifact.serializeBinaryToWriter
    );
  }
  f = message.getScanJobTrainingData();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField = {
  SCAN_JOB_ARTIFACT_FIELD_UNSET: 0,
  SCAN_JOB_ARTIFACT_FIELD_CONTENT: 1,
  SCAN_JOB_ARTIFACT_FIELD_TRAINING_DATA: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectedTextPositionsList: jspb.Message.toObjectList(msg.getSelectedTextPositionsList(),
    proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.toObject, includeInstance),
    rejectedTextResultsList: jspb.Message.toObjectList(msg.getRejectedTextResultsList(),
    proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData;
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.deserializeBinaryFromReader);
      msg.addSelectedTextPositions(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.deserializeBinaryFromReader);
      msg.addRejectedTextResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectedTextPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.serializeBinaryToWriter
    );
  }
  f = message.getRejectedTextResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    model: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata;
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string model = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.toObject = function(includeInstance, msg) {
  var f, obj = {
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelMetadata: (f = msg.getModelMetadata()) && proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData;
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.deserializeBinaryFromReader);
      msg.setModelMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelMetadata();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string created_by = 3;
 * @return {string}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.setCreatedBy = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.clearCreatedBy = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ModelMetadata model_metadata = 4;
 * @return {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.getModelMetadata = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata, 4));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.ModelMetadata|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.setModelMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.clearModelMetadata = function() {
  return this.setModelMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.prototype.hasModelMetadata = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    textType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    textPositionsList: jspb.Message.toObjectList(msg.getTextPositionsList(),
    proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition;
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.TextResult.TextType} */ (reader.readEnum());
      msg.setTextType(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.deserializeBinaryFromReader);
      msg.addTextPositions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTextPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    startPosition: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stopPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    audit: (f = msg.getAudit()) && proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition;
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartPosition(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopPosition(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.deserializeBinaryFromReader);
      msg.setAudit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStopPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAudit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 start_position = 1;
 * @return {number}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.getStartPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.setStartPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 stop_position = 2;
 * @return {number}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.getStopPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.setStopPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CreatedAuditData audit = 3;
 * @return {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.getAudit = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData, 3));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.setAudit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.clearAudit = function() {
  return this.setAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition.prototype.hasAudit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.scan_runner.TextResult.TextType text_type = 1;
 * @return {!proto.sp.scan_runner.TextResult.TextType}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.getTextType = function() {
  return /** @type {!proto.sp.scan_runner.TextResult.TextType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.TextResult.TextType} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.setTextType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated TextPosition text_positions = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition>}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.getTextPositionsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition>} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.setTextPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.addTextPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.TextPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition.prototype.clearTextPositionsList = function() {
  return this.setTextPositionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    textType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    audit: (f = msg.getAudit()) && proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult;
  return proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.TextResult.TextType} */ (reader.readEnum());
      msg.setTextType(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.deserializeBinaryFromReader);
      msg.setAudit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAudit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData.serializeBinaryToWriter
    );
  }
};


/**
 * optional sp.scan_runner.TextResult.TextType text_type = 1;
 * @return {!proto.sp.scan_runner.TextResult.TextType}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.getTextType = function() {
  return /** @type {!proto.sp.scan_runner.TextResult.TextType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.TextResult.TextType} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.setTextType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional CreatedAuditData audit = 2;
 * @return {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.getAudit = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData, 2));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.CreatedAuditData|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.setAudit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.clearAudit = function() {
  return this.setAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult.prototype.hasAudit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SelectedTextPosition selected_text_positions = 1;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition>}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.getSelectedTextPositionsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition>} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.setSelectedTextPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.addSelectedTextPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.SelectedTextPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.clearSelectedTextPositionsList = function() {
  return this.setSelectedTextPositionsList([]);
};


/**
 * repeated RejectedTextResult rejected_text_results = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult>}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.getRejectedTextResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult>} value
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} returns this
*/
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.setRejectedTextResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult}
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.addRejectedTextResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.RejectedTextResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} returns this
 */
proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData.prototype.clearRejectedTextResultsList = function() {
  return this.setRejectedTextResultsList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
 */
proto.sp.scan_service.ScanJobArtifact.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
*/
proto.sp.scan_service.ScanJobArtifact.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
 */
proto.sp.scan_service.ScanJobArtifact.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string scan_job_id = 3;
 * @return {string}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
 */
proto.sp.scan_service.ScanJobArtifact.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional sp.scan_runner.ScanArtifact scan_artifact = 4;
 * @return {?proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.getScanArtifact = function() {
  return /** @type{?proto.sp.scan_runner.ScanArtifact} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_scan_runner_pb.ScanArtifact, 4));
};


/**
 * @param {?proto.sp.scan_runner.ScanArtifact|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
*/
proto.sp.scan_service.ScanJobArtifact.prototype.setScanArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
 */
proto.sp.scan_service.ScanJobArtifact.prototype.clearScanArtifact = function() {
  return this.setScanArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.hasScanArtifact = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ScanJobTrainingData scan_job_training_data = 100;
 * @return {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.getScanJobTrainingData = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData, 100));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact.ScanJobTrainingData|undefined} value
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
*/
proto.sp.scan_service.ScanJobArtifact.prototype.setScanJobTrainingData = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJobArtifact} returns this
 */
proto.sp.scan_service.ScanJobArtifact.prototype.clearScanJobTrainingData = function() {
  return this.setScanJobTrainingData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJobArtifact.prototype.hasScanJobTrainingData = function() {
  return jspb.Message.getField(this, 100) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ScanJob.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJob.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditDataV2.toObject(includeInstance, f),
    parameters: (f = msg.getParameters()) && proto.sp.scan_service.ScanJob.Parameters.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    scanJobResult: (f = msg.getScanJobResult()) && proto.sp.scan_service.ScanJob.ScanJobResult.toObject(includeInstance, f),
    scanType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    completionTime: (f = msg.getCompletionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    promoted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    promotedBy: jspb.Message.getFieldWithDefault(msg, 9, ""),
    podTerminationStatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    priority: jspb.Message.getFieldWithDefault(msg, 11, 0),
    gatingLogList: jspb.Message.toObjectList(msg.getGatingLogList(),
    proto.sp.scan_service.ScanJob.ScanJobGating.toObject, includeInstance),
    gatingType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    script: jspb.Message.getFieldWithDefault(msg, 14, ""),
    scanJobDebugData: (f = msg.getScanJobDebugData()) && proto.sp.scan_service.ScanJob.ScanJobDebugData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.ScanJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJob;
  return proto.sp.scan_service.ScanJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.ScanJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditDataV2;
      reader.readMessage(value,sp_service_common_common_pb.AuditDataV2.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.ScanJob.Parameters;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.Parameters.deserializeBinaryFromReader);
      msg.setParameters(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.sp.scan_service.ScanJob.ScanJobResult;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.ScanJobResult.deserializeBinaryFromReader);
      msg.setScanJobResult(value);
      break;
    case 6:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanType} */ (reader.readEnum());
      msg.setScanType(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompletionTime(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPromoted(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotedBy(value);
      break;
    case 10:
      var value = /** @type {!proto.sp.scan_service.ScanJob.PodTerminationStatus} */ (reader.readEnum());
      msg.setPodTerminationStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriority(value);
      break;
    case 12:
      var value = new proto.sp.scan_service.ScanJob.ScanJobGating;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.ScanJobGating.deserializeBinaryFromReader);
      msg.addGatingLog(value);
      break;
    case 13:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} */ (reader.readEnum());
      msg.setGatingType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setScript(value);
      break;
    case 100:
      var value = new proto.sp.scan_service.ScanJob.ScanJobDebugData;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.ScanJobDebugData.deserializeBinaryFromReader);
      msg.setScanJobDebugData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditDataV2.serializeBinaryToWriter
    );
  }
  f = message.getParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sp.scan_service.ScanJob.Parameters.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getScanJobResult();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sp.scan_service.ScanJob.ScanJobResult.serializeBinaryToWriter
    );
  }
  f = message.getScanType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCompletionTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPromoted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {!proto.sp.scan_service.ScanJob.PodTerminationStatus} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getGatingLogList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.sp.scan_service.ScanJob.ScanJobGating.serializeBinaryToWriter
    );
  }
  f = message.getGatingType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getScanJobDebugData();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.sp.scan_service.ScanJob.ScanJobDebugData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJob.ScanJobStatus = {
  SCAN_JOB_STATUS_UNSET: 0,
  SCAN_JOB_STATUS_QUEUED: 1,
  SCAN_JOB_STATUS_RUNNING: 2,
  SCAN_JOB_STATUS_FAILED: 3,
  SCAN_JOB_STATUS_COMPLETE: 4,
  SCAN_JOB_STATUS_POD_MISSING: 5,
  SCAN_JOB_STATUS_KILLED: 6,
  SCAN_JOB_STATUS_ERROR: 7
};

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJob.PodTerminationStatus = {
  POD_TERMINATION_STATUS_UNSET: 0,
  POD_TERMINATION_STATUS_PENDING_COMPLETED: 1,
  POD_TERMINATION_STATUS_PENDING_RUNNING: 2,
  POD_TERMINATION_STATUS_PENDING_ERROR: 3,
  POD_TERMINATION_STATUS_COMPLETED: 4
};

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJob.ScanJobField = {
  SCAN_JOB_FIELD_UNSET: 0,
  SCAN_JOB_FIELD_RESULT_REPORTS: 1,
  SCAN_JOB_FIELD_RESULT_ARTIFACTS: 2,
  SCAN_JOB_FIELD_GATING_LOG: 3
};

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJob.ScanType = {
  SCAN_TYPE_UNSET: 0,
  SCAN_TYPE_SCANNER_V1_SCAN: 1,
  SCAN_TYPE_MANUAL_SCAN: 2,
  SCAN_TYPE_AUTOMATIC_SCAN: 3,
  SCAN_TYPE_EXTENSION: 4,
  SCAN_TYPE_DIAGNOSE: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJob.ScanJobGating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ScanJobGating.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gatingType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    remark: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJob.ScanJobGating;
  return proto.sp.scan_service.ScanJob.ScanJobGating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} */ (reader.readEnum());
      msg.setGatingType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJob.ScanJobGating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ScanJobGating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGatingType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.GatingType = {
  GATING_TYPE_UNSET: 0,
  GATING_TYPE_AWAITING: 1,
  GATING_TYPE_AUTO_APPROVED: 2,
  GATING_TYPE_AUTO_REJECTED: 3,
  GATING_TYPE_AUTO_NEEDS_HUMAN_REVIEW: 4,
  GATING_TYPE_HUMAN_APPROVED: 5,
  GATING_TYPE_HUMAN_REJECTED: 6
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
*/
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GatingType gating_type = 3;
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.getGatingType = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.setGatingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string created_by = 4;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.setCreatedBy = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.clearCreatedBy = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string remark = 5;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.setRemark = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.clearRemark = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobGating.prototype.hasRemark = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJob.Parameters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJob.Parameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJob.Parameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.Parameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    region: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJob.Parameters}
 */
proto.sp.scan_service.ScanJob.Parameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJob.Parameters;
  return proto.sp.scan_service.ScanJob.Parameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJob.Parameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJob.Parameters}
 */
proto.sp.scan_service.ScanJob.Parameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJob.Parameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJob.Parameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJob.Parameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.Parameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.Parameters.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.Parameters} returns this
 */
proto.sp.scan_service.ScanJob.Parameters.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region = 2;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.Parameters.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.Parameters} returns this
 */
proto.sp.scan_service.ScanJob.Parameters.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJob.ConsentResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJob.ConsentResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ConsentResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    tcfPolicyVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cmpId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cmpVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    consentLanguage: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult}
 */
proto.sp.scan_service.ScanJob.ConsentResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJob.ConsentResult;
  return proto.sp.scan_service.ScanJob.ConsentResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJob.ConsentResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult}
 */
proto.sp.scan_service.ScanJob.ConsentResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTcfPolicyVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCmpId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCmpVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsentLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJob.ConsentResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJob.ConsentResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ConsentResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 tcf_policy_version = 1;
 * @return {number}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.getTcfPolicyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.setTcfPolicyVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.clearTcfPolicyVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.hasTcfPolicyVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 cmp_id = 2;
 * @return {number}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.getCmpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.setCmpId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.clearCmpId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.hasCmpId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 cmp_version = 3;
 * @return {number}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.getCmpVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.setCmpVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.clearCmpVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.hasCmpVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string consent_language = 4;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.getConsentLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.setConsentLanguage = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ConsentResult} returns this
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.clearConsentLanguage = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ConsentResult.prototype.hasConsentLanguage = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ScanJob.ScanJobResult.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJob.ScanJobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJob.ScanJobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ScanJobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanError: (f = msg.getScanError()) && sp_scan_runner_scan_runner_pb.ScanError.toObject(includeInstance, f),
    scanRuleReportsList: jspb.Message.toObjectList(msg.getScanRuleReportsList(),
    sp_scan_runner_scan_runner_pb.ScanRuleReport.toObject, includeInstance),
    scanJobArtifactsList: jspb.Message.toObjectList(msg.getScanJobArtifactsList(),
    proto.sp.scan_service.ScanJobArtifact.toObject, includeInstance),
    consentResult: (f = msg.getConsentResult()) && proto.sp.scan_service.ScanJob.ConsentResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJob.ScanJobResult;
  return proto.sp.scan_service.ScanJob.ScanJobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJob.ScanJobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new sp_scan_runner_scan_runner_pb.ScanError;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanError.deserializeBinaryFromReader);
      msg.setScanError(value);
      break;
    case 2:
      var value = new sp_scan_runner_scan_runner_pb.ScanRuleReport;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanRuleReport.deserializeBinaryFromReader);
      msg.addScanRuleReports(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.ScanJobArtifact;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.deserializeBinaryFromReader);
      msg.addScanJobArtifacts(value);
      break;
    case 5:
      var value = new proto.sp.scan_service.ScanJob.ConsentResult;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.ConsentResult.deserializeBinaryFromReader);
      msg.setConsentResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJob.ScanJobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJob.ScanJobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ScanJobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_scan_runner_scan_runner_pb.ScanError.serializeBinaryToWriter
    );
  }
  f = message.getScanRuleReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      sp_scan_runner_scan_runner_pb.ScanRuleReport.serializeBinaryToWriter
    );
  }
  f = message.getScanJobArtifactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sp.scan_service.ScanJobArtifact.serializeBinaryToWriter
    );
  }
  f = message.getConsentResult();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sp.scan_service.ScanJob.ConsentResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional sp.scan_runner.ScanError scan_error = 3;
 * @return {?proto.sp.scan_runner.ScanError}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.getScanError = function() {
  return /** @type{?proto.sp.scan_runner.ScanError} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_scan_runner_pb.ScanError, 3));
};


/**
 * @param {?proto.sp.scan_runner.ScanError|undefined} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
*/
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.setScanError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.clearScanError = function() {
  return this.setScanError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.hasScanError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated sp.scan_runner.ScanRuleReport scan_rule_reports = 2;
 * @return {!Array<!proto.sp.scan_runner.ScanRuleReport>}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.getScanRuleReportsList = function() {
  return /** @type{!Array<!proto.sp.scan_runner.ScanRuleReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_scan_runner_scan_runner_pb.ScanRuleReport, 2));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanRuleReport>} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
*/
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.setScanRuleReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_runner.ScanRuleReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_runner.ScanRuleReport}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.addScanRuleReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_runner.ScanRuleReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.clearScanRuleReportsList = function() {
  return this.setScanRuleReportsList([]);
};


/**
 * repeated ScanJobArtifact scan_job_artifacts = 4;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact>}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.getScanJobArtifactsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobArtifact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobArtifact, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact>} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
*/
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.setScanJobArtifactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.addScanJobArtifacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sp.scan_service.ScanJobArtifact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.clearScanJobArtifactsList = function() {
  return this.setScanJobArtifactsList([]);
};


/**
 * optional ConsentResult consent_result = 5;
 * @return {?proto.sp.scan_service.ScanJob.ConsentResult}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.getConsentResult = function() {
  return /** @type{?proto.sp.scan_service.ScanJob.ConsentResult} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJob.ConsentResult, 5));
};


/**
 * @param {?proto.sp.scan_service.ScanJob.ConsentResult|undefined} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
*/
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.setConsentResult = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobResult} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.clearConsentResult = function() {
  return this.setConsentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobResult.prototype.hasConsentResult = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanJob.ScanJobDebugData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanJob.ScanJobDebugData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outputUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayOption: jspb.Message.getFieldWithDefault(msg, 3, 0),
    proxyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    jobCompletionTimeSecs: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanJob.ScanJobDebugData;
  return proto.sp.scan_service.ScanJob.ScanJobDebugData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanJob.ScanJobDebugData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputUri(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_runner.DisplayOption} */ (reader.readEnum());
      msg.setDisplayOption(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_runner.ProxyType} */ (reader.readEnum());
      msg.setProxyType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobCompletionTimeSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanJob.ScanJobDebugData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanJob.ScanJobDebugData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayOption();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProxyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string job_name = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.getJobName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.setJobName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.clearJobName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.hasJobName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string output_uri = 2;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.getOutputUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.setOutputUri = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.clearOutputUri = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.hasOutputUri = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sp.scan_runner.DisplayOption display_option = 3;
 * @return {!proto.sp.scan_runner.DisplayOption}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.getDisplayOption = function() {
  return /** @type {!proto.sp.scan_runner.DisplayOption} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_runner.DisplayOption} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.setDisplayOption = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional sp.scan_runner.ProxyType proxy_type = 4;
 * @return {!proto.sp.scan_runner.ProxyType}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.getProxyType = function() {
  return /** @type {!proto.sp.scan_runner.ProxyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_runner.ProxyType} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.setProxyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 job_completion_time_secs = 5;
 * @return {number}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.getJobCompletionTimeSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.setJobCompletionTimeSecs = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob.ScanJobDebugData} returns this
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.clearJobCompletionTimeSecs = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.ScanJobDebugData.prototype.hasJobCompletionTimeSecs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditDataV2 audit_data = 2;
 * @return {?proto.sp.service_common.AuditDataV2}
 */
proto.sp.scan_service.ScanJob.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditDataV2} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditDataV2, 2));
};


/**
 * @param {?proto.sp.service_common.AuditDataV2|undefined} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
*/
proto.sp.scan_service.ScanJob.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Parameters parameters = 3;
 * @return {?proto.sp.scan_service.ScanJob.Parameters}
 */
proto.sp.scan_service.ScanJob.prototype.getParameters = function() {
  return /** @type{?proto.sp.scan_service.ScanJob.Parameters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJob.Parameters, 3));
};


/**
 * @param {?proto.sp.scan_service.ScanJob.Parameters|undefined} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
*/
proto.sp.scan_service.ScanJob.prototype.setParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearParameters = function() {
  return this.setParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ScanJobStatus status = 4;
 * @return {!proto.sp.scan_service.ScanJob.ScanJobStatus}
 */
proto.sp.scan_service.ScanJob.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanJobStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobStatus} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ScanJobResult scan_job_result = 5;
 * @return {?proto.sp.scan_service.ScanJob.ScanJobResult}
 */
proto.sp.scan_service.ScanJob.prototype.getScanJobResult = function() {
  return /** @type{?proto.sp.scan_service.ScanJob.ScanJobResult} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJob.ScanJobResult, 5));
};


/**
 * @param {?proto.sp.scan_service.ScanJob.ScanJobResult|undefined} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
*/
proto.sp.scan_service.ScanJob.prototype.setScanJobResult = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearScanJobResult = function() {
  return this.setScanJobResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasScanJobResult = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ScanType scan_type = 6;
 * @return {!proto.sp.scan_service.ScanJob.ScanType}
 */
proto.sp.scan_service.ScanJob.prototype.getScanType = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanType} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setScanType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp completion_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.ScanJob.prototype.getCompletionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
*/
proto.sp.scan_service.ScanJob.prototype.setCompletionTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearCompletionTime = function() {
  return this.setCompletionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasCompletionTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool promoted = 8;
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.getPromoted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setPromoted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string promoted_by = 9;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.prototype.getPromotedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setPromotedBy = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearPromotedBy = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasPromotedBy = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PodTerminationStatus pod_termination_status = 10;
 * @return {!proto.sp.scan_service.ScanJob.PodTerminationStatus}
 */
proto.sp.scan_service.ScanJob.prototype.getPodTerminationStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.PodTerminationStatus} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.PodTerminationStatus} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setPodTerminationStatus = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearPodTerminationStatus = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasPodTerminationStatus = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 priority = 11;
 * @return {number}
 */
proto.sp.scan_service.ScanJob.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated ScanJobGating gating_log = 12;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanJobGating>}
 */
proto.sp.scan_service.ScanJob.prototype.getGatingLogList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJob.ScanJobGating>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJob.ScanJobGating, 12));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanJobGating>} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
*/
proto.sp.scan_service.ScanJob.prototype.setGatingLogList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating}
 */
proto.sp.scan_service.ScanJob.prototype.addGatingLog = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.sp.scan_service.ScanJob.ScanJobGating, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearGatingLogList = function() {
  return this.setGatingLogList([]);
};


/**
 * optional ScanJobGating.GatingType gating_type = 13;
 * @return {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType}
 */
proto.sp.scan_service.ScanJob.prototype.getGatingType = function() {
  return /** @type {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setGatingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string script = 14;
 * @return {string}
 */
proto.sp.scan_service.ScanJob.prototype.getScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.setScript = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearScript = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasScript = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ScanJobDebugData scan_job_debug_data = 100;
 * @return {?proto.sp.scan_service.ScanJob.ScanJobDebugData}
 */
proto.sp.scan_service.ScanJob.prototype.getScanJobDebugData = function() {
  return /** @type{?proto.sp.scan_service.ScanJob.ScanJobDebugData} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJob.ScanJobDebugData, 100));
};


/**
 * @param {?proto.sp.scan_service.ScanJob.ScanJobDebugData|undefined} value
 * @return {!proto.sp.scan_service.ScanJob} returns this
*/
proto.sp.scan_service.ScanJob.prototype.setScanJobDebugData = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanJob} returns this
 */
proto.sp.scan_service.ScanJob.prototype.clearScanJobDebugData = function() {
  return this.setScanJobDebugData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanJob.prototype.hasScanJobDebugData = function() {
  return jspb.Message.getField(this, 100) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobByIdRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobByIdRequest}
 */
proto.sp.scan_service.GetScanJobByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobByIdRequest;
  return proto.sp.scan_service.GetScanJobByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobByIdRequest}
 */
proto.sp.scan_service.GetScanJobByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanJobByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ScanJob.ScanJobField fields = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>}
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>} value
 * @return {!proto.sp.scan_service.GetScanJobByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJob: (f = msg.getScanJob()) && proto.sp.scan_service.ScanJob.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobResponse}
 */
proto.sp.scan_service.GetScanJobResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobResponse;
  return proto.sp.scan_service.GetScanJobResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobResponse}
 */
proto.sp.scan_service.GetScanJobResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanJob;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.deserializeBinaryFromReader);
      msg.setScanJob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanJob scan_job = 1;
 * @return {?proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.GetScanJobResponse.prototype.getScanJob = function() {
  return /** @type{?proto.sp.scan_service.ScanJob} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJob, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanJob|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobResponse} returns this
*/
proto.sp.scan_service.GetScanJobResponse.prototype.setScanJob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobResponse} returns this
 */
proto.sp.scan_service.GetScanJobResponse.prototype.clearScanJob = function() {
  return this.setScanJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobResponse.prototype.hasScanJob = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetScanJobsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetScanJobsRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobsRequest}
 */
proto.sp.scan_service.GetScanJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobsRequest;
  return proto.sp.scan_service.GetScanJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobsRequest}
 */
proto.sp.scan_service.GetScanJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetScanJobsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetScanJobsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetScanJobsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetScanJobsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanJobsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_CREATED_TIME: 1,
  SORT_FIELD_UPDATED_TIME: 2,
  SORT_FIELD_STATUS: 3,
  SORT_FIELD_PROPERTY_NAME: 4,
  SORT_FIELD_REGION: 5,
  SORT_FIELD_ID: 6,
  SORT_FIELD_COMPLETION_TIME: 7,
  SORT_FIELD_PROXY_TYPE: 8,
  SORT_FIELD_SCAN_JOB_TYPE: 9,
  SORT_FIELD_PRIORITY: 10,
  SORT_FIELD_GATING: 11
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobsRequest.SortParameter;
  return proto.sp.scan_service.GetScanJobsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetScanJobsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetScanJobsRequest.SortField}
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetScanJobsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetScanJobsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.repeatedFields_ = [1,2,4,5,6,8,10,11,12,13,14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusFilterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    regionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    propertyTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    propertyNameFilterList: jspb.Message.toObjectList(msg.getPropertyNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    maxResultsByPropertyType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    scanTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    podTerminationStatusFilterList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    artifactFiltersList: jspb.Message.toObjectList(msg.getArtifactFiltersList(),
    proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.toObject, includeInstance),
    consentTcfVersionFilterList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    consentCmpIdFilterList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    consentCmpVersionFilterList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    consentLanguageFilterList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    gatingFilterList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    completionTimeFilter: (f = msg.getCompletionTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobsRequest.Filters;
  return proto.sp.scan_service.GetScanJobsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusFilter(values[i]);
      }
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 9:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionIdFilter(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.sp.scan_service.Property.PropertyType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPropertyTypeFilter(values[i]);
      }
      break;
    case 6:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addPropertyNameFilter(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxResultsByPropertyType(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScanTypeFilter(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.PodTerminationStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPodTerminationStatusFilter(values[i]);
      }
      break;
    case 11:
      var value = new proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.deserializeBinaryFromReader);
      msg.addArtifactFilters(value);
      break;
    case 12:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConsentTcfVersionFilter(values[i]);
      }
      break;
    case 13:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConsentCmpIdFilter(values[i]);
      }
      break;
    case 14:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addConsentCmpVersionFilter(values[i]);
      }
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addConsentLanguageFilter(value);
      break;
    case 16:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGatingFilter(values[i]);
      }
      break;
    case 17:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCompletionTimeFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPropertyTypeFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getPropertyNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getMaxResultsByPropertyType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getScanTypeFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getPodTerminationStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getArtifactFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.serializeBinaryToWriter
    );
  }
  f = message.getConsentTcfVersionFilterList();
  if (f.length > 0) {
    writer.writePackedInt32(
      12,
      f
    );
  }
  f = message.getConsentCmpIdFilterList();
  if (f.length > 0) {
    writer.writePackedInt32(
      13,
      f
    );
  }
  f = message.getConsentCmpVersionFilterList();
  if (f.length > 0) {
    writer.writePackedInt32(
      14,
      f
    );
  }
  f = message.getConsentLanguageFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getGatingFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      16,
      f
    );
  }
  f = message.getCompletionTimeFilter();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    artifactTypeFilter: jspb.Message.getFieldWithDefault(msg, 1, 0),
    artifactNameFilter: (f = msg.getArtifactNameFilter()) && sp_service_common_common_pb.StringFilter.toObject(includeInstance, f),
    trainingDataSetFilter: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters;
  return proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (reader.readEnum());
      msg.setArtifactTypeFilter(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.setArtifactNameFilter(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrainingDataSetFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getArtifactNameFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional sp.scan_runner.ScanArtifact.ArtifactType artifact_type_filter = 1;
 * @return {!proto.sp.scan_runner.ScanArtifact.ArtifactType}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.getArtifactTypeFilter = function() {
  return /** @type {!proto.sp.scan_runner.ScanArtifact.ArtifactType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactType} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.setArtifactTypeFilter = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.clearArtifactTypeFilter = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.hasArtifactTypeFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sp.service_common.StringFilter artifact_name_filter = 2;
 * @return {?proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.getArtifactNameFilter = function() {
  return /** @type{?proto.sp.service_common.StringFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.StringFilter, 2));
};


/**
 * @param {?proto.sp.service_common.StringFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.setArtifactNameFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.clearArtifactNameFilter = function() {
  return this.setArtifactNameFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.hasArtifactNameFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool training_data_set_filter = 3;
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.getTrainingDataSetFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.setTrainingDataSetFilter = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.clearTrainingDataSetFilter = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters.prototype.hasTrainingDataSetFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * repeated ScanJob.ScanJobStatus status_filter = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanJobStatus>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setStatusFilterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearStatusFilterList = function() {
  return this.setStatusFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 9;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 9));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string region_id_filter = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearRegionIdFilterList = function() {
  return this.setRegionIdFilterList([]);
};


/**
 * repeated Property.PropertyType property_type_filter = 5;
 * @return {!Array<!proto.sp.scan_service.Property.PropertyType>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getPropertyTypeFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.Property.PropertyType>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.PropertyType>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setPropertyTypeFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addPropertyTypeFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearPropertyTypeFilterList = function() {
  return this.setPropertyTypeFilterList([]);
};


/**
 * repeated sp.service_common.StringFilter property_name_filter = 6;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getPropertyNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 6));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setPropertyNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addPropertyNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearPropertyNameFilterList = function() {
  return this.setPropertyNameFilterList([]);
};


/**
 * optional int32 max_results_by_property_type = 7;
 * @return {number}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getMaxResultsByPropertyType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setMaxResultsByPropertyType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated ScanJob.ScanType scan_type_filter = 8;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanType>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getScanTypeFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanType>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanType>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setScanTypeFilterList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addScanTypeFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearScanTypeFilterList = function() {
  return this.setScanTypeFilterList([]);
};


/**
 * repeated ScanJob.PodTerminationStatus pod_termination_status_filter = 10;
 * @return {!Array<!proto.sp.scan_service.ScanJob.PodTerminationStatus>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getPodTerminationStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.PodTerminationStatus>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.PodTerminationStatus>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setPodTerminationStatusFilterList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.PodTerminationStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addPodTerminationStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearPodTerminationStatusFilterList = function() {
  return this.setPodTerminationStatusFilterList([]);
};


/**
 * repeated ArtifactFilters artifact_filters = 11;
 * @return {!Array<!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getArtifactFiltersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters, 11));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setArtifactFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addArtifactFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.sp.scan_service.GetScanJobsRequest.Filters.ArtifactFilters, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearArtifactFiltersList = function() {
  return this.setArtifactFiltersList([]);
};


/**
 * repeated int32 consent_tcf_version_filter = 12;
 * @return {!Array<number>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getConsentTcfVersionFilterList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setConsentTcfVersionFilterList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addConsentTcfVersionFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearConsentTcfVersionFilterList = function() {
  return this.setConsentTcfVersionFilterList([]);
};


/**
 * repeated int32 consent_cmp_id_filter = 13;
 * @return {!Array<number>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getConsentCmpIdFilterList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setConsentCmpIdFilterList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addConsentCmpIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearConsentCmpIdFilterList = function() {
  return this.setConsentCmpIdFilterList([]);
};


/**
 * repeated int32 consent_cmp_version_filter = 14;
 * @return {!Array<number>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getConsentCmpVersionFilterList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setConsentCmpVersionFilterList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addConsentCmpVersionFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearConsentCmpVersionFilterList = function() {
  return this.setConsentCmpVersionFilterList([]);
};


/**
 * repeated string consent_language_filter = 15;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getConsentLanguageFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setConsentLanguageFilterList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addConsentLanguageFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearConsentLanguageFilterList = function() {
  return this.setConsentLanguageFilterList([]);
};


/**
 * repeated ScanJob.ScanJobGating.GatingType gating_filter = 16;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType>}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getGatingFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setGatingFilterList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobGating.GatingType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.addGatingFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearGatingFilterList = function() {
  return this.setGatingFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter completion_time_filter = 17;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.getCompletionTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 17));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.setCompletionTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.clearCompletionTimeFilter = function() {
  return this.setCompletionTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.Filters.prototype.hasCompletionTimeFilter = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetScanJobsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetScanJobsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetScanJobsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetScanJobsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetScanJobsRequest.SortParameter>}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetScanJobsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetScanJobsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
*/
proto.sp.scan_service.GetScanJobsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetScanJobsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated ScanJob.ScanJobField fields = 4;
 * @return {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>}
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob.ScanJobField>} value
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJob.ScanJobField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsRequest} returns this
 */
proto.sp.scan_service.GetScanJobsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanJobsList: jspb.Message.toObjectList(msg.getScanJobsList(),
    proto.sp.scan_service.ScanJob.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobsResponse}
 */
proto.sp.scan_service.GetScanJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobsResponse;
  return proto.sp.scan_service.GetScanJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobsResponse}
 */
proto.sp.scan_service.GetScanJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJob;
      reader.readMessage(value,proto.sp.scan_service.ScanJob.deserializeBinaryFromReader);
      msg.addScanJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanJobsResponse} returns this
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobsResponse} returns this
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScanJob scan_jobs = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJob>}
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.getScanJobsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJob>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJob, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJob>} value
 * @return {!proto.sp.scan_service.GetScanJobsResponse} returns this
*/
proto.sp.scan_service.GetScanJobsResponse.prototype.setScanJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJob=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJob}
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.addScanJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanJob, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobsResponse} returns this
 */
proto.sp.scan_service.GetScanJobsResponse.prototype.clearScanJobsList = function() {
  return this.setScanJobsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobArtifactByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobArtifactByIdRequest}
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobArtifactByIdRequest;
  return proto.sp.scan_service.GetScanJobArtifactByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobArtifactByIdRequest}
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobArtifactByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ScanJobArtifact.ScanJobArtifactField fields = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>}
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactByIdRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobArtifactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobArtifactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobArtifactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobArtifact: (f = msg.getScanJobArtifact()) && proto.sp.scan_service.ScanJobArtifact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobArtifactResponse}
 */
proto.sp.scan_service.GetScanJobArtifactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobArtifactResponse;
  return proto.sp.scan_service.GetScanJobArtifactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobArtifactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobArtifactResponse}
 */
proto.sp.scan_service.GetScanJobArtifactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanJobArtifact;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.deserializeBinaryFromReader);
      msg.setScanJobArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobArtifactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobArtifactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobArtifactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobArtifact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ScanJobArtifact.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanJobArtifact scan_job_artifact = 1;
 * @return {?proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.GetScanJobArtifactResponse.prototype.getScanJobArtifact = function() {
  return /** @type{?proto.sp.scan_service.ScanJobArtifact} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanJobArtifact, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanJobArtifact|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactResponse} returns this
*/
proto.sp.scan_service.GetScanJobArtifactResponse.prototype.setScanJobArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactResponse} returns this
 */
proto.sp.scan_service.GetScanJobArtifactResponse.prototype.clearScanJobArtifact = function() {
  return this.setScanJobArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactResponse.prototype.hasScanJobArtifact = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobArtifactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobArtifactsRequest;
  return proto.sp.scan_service.GetScanJobArtifactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetScanJobArtifactsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobArtifactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_CREATED_TIME: 1,
  SORT_FIELD_UPDATED_TIME: 2,
  SORT_FIELD_ARTIFACT_NAME: 3,
  SORT_FIELD_ID: 4,
  SORT_FIELD_HAS_TRAINING_DATA: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter;
  return proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortField}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.repeatedFields_ = [1,4,5,6,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    artifactNameFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    scanJobIdFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    artifactTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    mimeTypeFilterList: jspb.Message.toObjectList(msg.getMimeTypeFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    trainingDataSetFilter: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    propertySetIdFilterList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobArtifactsRequest.Filters;
  return proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_runner.ArtifactName>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addArtifactNameFilter(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanJobIdFilter(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.sp.scan_runner.ScanArtifact.ArtifactType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addArtifactTypeFilter(values[i]);
      }
      break;
    case 7:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addMimeTypeFilter(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrainingDataSetFilter(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addPropertySetIdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getArtifactNameFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getScanJobIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getArtifactTypeFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getMimeTypeFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPropertySetIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 2;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 2));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated sp.scan_runner.ArtifactName artifact_name_filter = 4;
 * @return {!Array<!proto.sp.scan_runner.ArtifactName>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getArtifactNameFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_runner.ArtifactName>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ArtifactName>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setArtifactNameFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_runner.ArtifactName} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.addArtifactNameFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearArtifactNameFilterList = function() {
  return this.setArtifactNameFilterList([]);
};


/**
 * repeated string scan_job_id_filter = 5;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getScanJobIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setScanJobIdFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.addScanJobIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearScanJobIdFilterList = function() {
  return this.setScanJobIdFilterList([]);
};


/**
 * repeated sp.scan_runner.ScanArtifact.ArtifactType artifact_type_filter = 6;
 * @return {!Array<!proto.sp.scan_runner.ScanArtifact.ArtifactType>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getArtifactTypeFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_runner.ScanArtifact.ArtifactType>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.sp.scan_runner.ScanArtifact.ArtifactType>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setArtifactTypeFilterList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.sp.scan_runner.ScanArtifact.ArtifactType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.addArtifactTypeFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearArtifactTypeFilterList = function() {
  return this.setArtifactTypeFilterList([]);
};


/**
 * repeated sp.service_common.StringFilter mime_type_filter = 7;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getMimeTypeFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 7));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setMimeTypeFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.addMimeTypeFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearMimeTypeFilterList = function() {
  return this.setMimeTypeFilterList([]);
};


/**
 * optional bool training_data_set_filter = 8;
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getTrainingDataSetFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setTrainingDataSetFilter = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearTrainingDataSetFilter = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.hasTrainingDataSetFilter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string property_set_id_filter = 9;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.getPropertySetIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.setPropertySetIdFilterList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.addPropertySetIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.Filters.prototype.clearPropertySetIdFilterList = function() {
  return this.setPropertySetIdFilterList([]);
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetScanJobArtifactsRequest.Filters}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetScanJobArtifactsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetScanJobArtifactsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetScanJobArtifactsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetScanJobArtifactsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated ScanJobArtifact.ScanJobArtifactField fields = 4;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>}
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact.ScanJobArtifactField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsRequest} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanJobArtifactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanJobArtifactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanJobArtifactsList: jspb.Message.toObjectList(msg.getScanJobArtifactsList(),
    proto.sp.scan_service.ScanJobArtifact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsResponse}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanJobArtifactsResponse;
  return proto.sp.scan_service.GetScanJobArtifactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsResponse}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJobArtifact;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.deserializeBinaryFromReader);
      msg.addScanJobArtifacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanJobArtifactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanJobArtifactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanJobArtifactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanJobArtifact.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsResponse} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsResponse} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScanJobArtifact scan_job_artifacts = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact>}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.getScanJobArtifactsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobArtifact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobArtifact, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact>} value
 * @return {!proto.sp.scan_service.GetScanJobArtifactsResponse} returns this
*/
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.setScanJobArtifactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.addScanJobArtifacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanJobArtifact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanJobArtifactsResponse} returns this
 */
proto.sp.scan_service.GetScanJobArtifactsResponse.prototype.clearScanJobArtifactsList = function() {
  return this.setScanJobArtifactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTimelineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTimelineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetTimelineRequest.Filters.toObject(includeInstance, f),
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTimelineRequest}
 */
proto.sp.scan_service.GetTimelineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTimelineRequest;
  return proto.sp.scan_service.GetTimelineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTimelineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTimelineRequest}
 */
proto.sp.scan_service.GetTimelineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetTimelineRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetTimelineRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTimelineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTimelineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetTimelineRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetTimelineRequest.ScanEventType = {
  SCAN_EVENT_TYPE_UNSET: 0,
  SCAN_EVENT_TYPE_LOG: 1,
  SCAN_EVENT_TYPE_ARTIFACT: 2,
  SCAN_EVENT_TYPE_NETWORK: 3
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetTimelineRequest.Filters.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTimelineRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTimelineRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampFilter: (f = msg.getTimestampFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    minSeverity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    scanRuleShortNameFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTimelineRequest.Filters;
  return proto.sp.scan_service.GetTimelineRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTimelineRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setTimestampFilter(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} */ (reader.readEnum());
      msg.setMinSeverity(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.sp.scan_service.GetTimelineRequest.ScanEventType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTypes(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScanRuleShortNameFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTimelineRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTimelineRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEventTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getScanRuleShortNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional sp.service_common.TimestampFilter timestamp_filter = 1;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.getTimestampFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 1));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
*/
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.setTimestampFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.clearTimestampFilter = function() {
  return this.setTimestampFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.hasTimestampFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sp.scan_runner.ScanTimeline.LogEvent.Severity min_severity = 2;
 * @return {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.getMinSeverity = function() {
  return /** @type {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.scan_runner.ScanTimeline.LogEvent.Severity} value
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.setMinSeverity = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.clearMinSeverity = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.hasMinSeverity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ScanEventType event_types = 3;
 * @return {!Array<!proto.sp.scan_service.GetTimelineRequest.ScanEventType>}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.getEventTypesList = function() {
  return /** @type {!Array<!proto.sp.scan_service.GetTimelineRequest.ScanEventType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetTimelineRequest.ScanEventType>} value
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.setEventTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.sp.scan_service.GetTimelineRequest.ScanEventType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.addEventTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.clearEventTypesList = function() {
  return this.setEventTypesList([]);
};


/**
 * repeated string scan_rule_short_name_filter = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.getScanRuleShortNameFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.setScanRuleShortNameFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.addScanRuleShortNameFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetTimelineRequest.Filters} returns this
 */
proto.sp.scan_service.GetTimelineRequest.Filters.prototype.clearScanRuleShortNameFilterList = function() {
  return this.setScanRuleShortNameFilterList([]);
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetTimelineRequest} returns this
 */
proto.sp.scan_service.GetTimelineRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetTimelineRequest.Filters}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetTimelineRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetTimelineRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetTimelineRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetTimelineRequest} returns this
*/
proto.sp.scan_service.GetTimelineRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTimelineRequest} returns this
 */
proto.sp.scan_service.GetTimelineRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 3;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 3));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetTimelineRequest} returns this
*/
proto.sp.scan_service.GetTimelineRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTimelineRequest} returns this
 */
proto.sp.scan_service.GetTimelineRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTimelineRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTimelineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTimelineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTimelineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeline: (f = msg.getTimeline()) && sp_scan_runner_scan_runner_pb.ScanTimeline.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTimelineResponse}
 */
proto.sp.scan_service.GetTimelineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTimelineResponse;
  return proto.sp.scan_service.GetTimelineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTimelineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTimelineResponse}
 */
proto.sp.scan_service.GetTimelineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_runner_scan_runner_pb.ScanTimeline;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanTimeline.deserializeBinaryFromReader);
      msg.setTimeline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTimelineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTimelineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTimelineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeline();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_scan_runner_scan_runner_pb.ScanTimeline.serializeBinaryToWriter
    );
  }
};


/**
 * optional sp.scan_runner.ScanTimeline timeline = 1;
 * @return {?proto.sp.scan_runner.ScanTimeline}
 */
proto.sp.scan_service.GetTimelineResponse.prototype.getTimeline = function() {
  return /** @type{?proto.sp.scan_runner.ScanTimeline} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_scan_runner_pb.ScanTimeline, 1));
};


/**
 * @param {?proto.sp.scan_runner.ScanTimeline|undefined} value
 * @return {!proto.sp.scan_service.GetTimelineResponse} returns this
*/
proto.sp.scan_service.GetTimelineResponse.prototype.setTimeline = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTimelineResponse} returns this
 */
proto.sp.scan_service.GetTimelineResponse.prototype.clearTimeline = function() {
  return this.setTimeline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTimelineResponse.prototype.hasTimeline = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTimelineArtifactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTimelineArtifactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTimelineArtifactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineArtifactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    artifactId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTimelineArtifactRequest}
 */
proto.sp.scan_service.GetTimelineArtifactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTimelineArtifactRequest;
  return proto.sp.scan_service.GetTimelineArtifactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTimelineArtifactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTimelineArtifactRequest}
 */
proto.sp.scan_service.GetTimelineArtifactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setArtifactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTimelineArtifactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTimelineArtifactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTimelineArtifactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineArtifactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArtifactId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetTimelineArtifactRequest.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetTimelineArtifactRequest} returns this
 */
proto.sp.scan_service.GetTimelineArtifactRequest.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string artifact_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetTimelineArtifactRequest.prototype.getArtifactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetTimelineArtifactRequest} returns this
 */
proto.sp.scan_service.GetTimelineArtifactRequest.prototype.setArtifactId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetTimelineArtifactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetTimelineArtifactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetTimelineArtifactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineArtifactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    artifact: (f = msg.getArtifact()) && sp_scan_runner_scan_runner_pb.ScanArtifact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetTimelineArtifactResponse}
 */
proto.sp.scan_service.GetTimelineArtifactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetTimelineArtifactResponse;
  return proto.sp.scan_service.GetTimelineArtifactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetTimelineArtifactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetTimelineArtifactResponse}
 */
proto.sp.scan_service.GetTimelineArtifactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_scan_runner_scan_runner_pb.ScanArtifact;
      reader.readMessage(value,sp_scan_runner_scan_runner_pb.ScanArtifact.deserializeBinaryFromReader);
      msg.setArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetTimelineArtifactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetTimelineArtifactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetTimelineArtifactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetTimelineArtifactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArtifact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_scan_runner_scan_runner_pb.ScanArtifact.serializeBinaryToWriter
    );
  }
};


/**
 * optional sp.scan_runner.ScanArtifact artifact = 1;
 * @return {?proto.sp.scan_runner.ScanArtifact}
 */
proto.sp.scan_service.GetTimelineArtifactResponse.prototype.getArtifact = function() {
  return /** @type{?proto.sp.scan_runner.ScanArtifact} */ (
    jspb.Message.getWrapperField(this, sp_scan_runner_scan_runner_pb.ScanArtifact, 1));
};


/**
 * @param {?proto.sp.scan_runner.ScanArtifact|undefined} value
 * @return {!proto.sp.scan_service.GetTimelineArtifactResponse} returns this
*/
proto.sp.scan_service.GetTimelineArtifactResponse.prototype.setArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetTimelineArtifactResponse} returns this
 */
proto.sp.scan_service.GetTimelineArtifactResponse.prototype.clearArtifact = function() {
  return this.setArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetTimelineArtifactResponse.prototype.hasArtifact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Property.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Property.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Property} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    propertyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    regionalResults: (f = msg.getRegionalResults()) && proto.sp.scan_service.Property.RegionalResults.toObject(includeInstance, f),
    propertyStats: (f = msg.getPropertyStats()) && proto.sp.scan_service.Property.PropertyStats.toObject(includeInstance, f),
    maxScanJobProcessTime: (f = msg.getMaxScanJobProcessTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Property}
 */
proto.sp.scan_service.Property.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Property;
  return proto.sp.scan_service.Property.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Property}
 */
proto.sp.scan_service.Property.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.Property.PropertyType} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new proto.sp.scan_service.Property.RegionalResults;
      reader.readMessage(value,proto.sp.scan_service.Property.RegionalResults.deserializeBinaryFromReader);
      msg.setRegionalResults(value);
      break;
    case 6:
      var value = new proto.sp.scan_service.Property.PropertyStats;
      reader.readMessage(value,proto.sp.scan_service.Property.PropertyStats.deserializeBinaryFromReader);
      msg.setPropertyStats(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setMaxScanJobProcessTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Property.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRegionalResults();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.sp.scan_service.Property.RegionalResults.serializeBinaryToWriter
    );
  }
  f = message.getPropertyStats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.sp.scan_service.Property.PropertyStats.serializeBinaryToWriter
    );
  }
  f = message.getMaxScanJobProcessTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.Property.PropertyType = {
  PROPERTY_TYPE_UNSET: 0,
  PROPERTY_TYPE_DOMAIN: 1
};

/**
 * @enum {number}
 */
proto.sp.scan_service.Property.ResultType = {
  RESULT_TYPE_UNSET: 0,
  RESULT_TYPE_FALSE: 1,
  RESULT_TYPE_TRUE: 2
};

/**
 * @enum {number}
 */
proto.sp.scan_service.Property.RuleAccuracy = {
  RULE_ACCURACY_UNSET: 0,
  RULE_ACCURACY_CORRECT: 1,
  RULE_ACCURACY_FALSE_POSITIVE: 2,
  RULE_ACCURACY_FALSE_NEGATIVE: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Property.RelevantScanJob.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Property.RelevantScanJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Property.RelevantScanJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RelevantScanJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanJobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relevancy: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Property.RelevantScanJob}
 */
proto.sp.scan_service.Property.RelevantScanJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Property.RelevantScanJob;
  return proto.sp.scan_service.Property.RelevantScanJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Property.RelevantScanJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Property.RelevantScanJob}
 */
proto.sp.scan_service.Property.RelevantScanJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanJobId(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.scan_service.Property.RelevantScanJob.Relevancy} */ (reader.readEnum());
      msg.setRelevancy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Property.RelevantScanJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Property.RelevantScanJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Property.RelevantScanJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RelevantScanJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelevancy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.Property.RelevantScanJob.Relevancy = {
  RELEVANCY_UNSET: 0,
  RELEVANCY_INITIAL: 1,
  RELEVANCY_PROMOTED: 2,
  RELEVANCY_ACCURACY: 3,
  RELEVANCY_IRRELEVANT: 4,
  RELEVANCY_PROMOTED_IGNORED: 5
};

/**
 * optional string scan_job_id = 1;
 * @return {string}
 */
proto.sp.scan_service.Property.RelevantScanJob.prototype.getScanJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Property.RelevantScanJob} returns this
 */
proto.sp.scan_service.Property.RelevantScanJob.prototype.setScanJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Relevancy relevancy = 2;
 * @return {!proto.sp.scan_service.Property.RelevantScanJob.Relevancy}
 */
proto.sp.scan_service.Property.RelevantScanJob.prototype.getRelevancy = function() {
  return /** @type {!proto.sp.scan_service.Property.RelevantScanJob.Relevancy} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.RelevantScanJob.Relevancy} value
 * @return {!proto.sp.scan_service.Property.RelevantScanJob} returns this
 */
proto.sp.scan_service.Property.RelevantScanJob.prototype.setRelevancy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.Property.RuleResult.repeatedFields_ = [3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Property.RuleResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Property.RuleResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Property.RuleResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RuleResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resultType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    relevantScanJobIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    mostRecentResultType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ruleAccuracy: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    ruleAccuracy2: jspb.Message.getFieldWithDefault(msg, 6, 0),
    relevantScanJobsList: jspb.Message.toObjectList(msg.getRelevantScanJobsList(),
    proto.sp.scan_service.Property.RelevantScanJob.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Property.RuleResult}
 */
proto.sp.scan_service.Property.RuleResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Property.RuleResult;
  return proto.sp.scan_service.Property.RuleResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Property.RuleResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Property.RuleResult}
 */
proto.sp.scan_service.Property.RuleResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleShortName(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.scan_service.Property.ResultType} */ (reader.readEnum());
      msg.setResultType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelevantScanJobIds(value);
      break;
    case 4:
      var value = /** @type {!proto.sp.scan_service.Property.ResultType} */ (reader.readEnum());
      msg.setMostRecentResultType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRuleAccuracy(value);
      break;
    case 6:
      var value = /** @type {!proto.sp.scan_service.Property.RuleAccuracy} */ (reader.readEnum());
      msg.setRuleAccuracy2(value);
      break;
    case 7:
      var value = new proto.sp.scan_service.Property.RelevantScanJob;
      reader.readMessage(value,proto.sp.scan_service.Property.RelevantScanJob.deserializeBinaryFromReader);
      msg.addRelevantScanJobs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Property.RuleResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Property.RuleResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Property.RuleResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RuleResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResultType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getRelevantScanJobIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getMostRecentResultType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRuleAccuracy2();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRelevantScanJobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sp.scan_service.Property.RelevantScanJob.serializeBinaryToWriter
    );
  }
};


/**
 * optional string scan_rule_short_name = 1;
 * @return {string}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getScanRuleShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.setScanRuleShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ResultType result_type = 2;
 * @return {!proto.sp.scan_service.Property.ResultType}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getResultType = function() {
  return /** @type {!proto.sp.scan_service.Property.ResultType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.ResultType} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.setResultType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated string relevant_scan_job_ids = 3;
 * @return {!Array<string>}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getRelevantScanJobIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.setRelevantScanJobIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.addRelevantScanJobIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.clearRelevantScanJobIdsList = function() {
  return this.setRelevantScanJobIdsList([]);
};


/**
 * optional ResultType most_recent_result_type = 4;
 * @return {!proto.sp.scan_service.Property.ResultType}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getMostRecentResultType = function() {
  return /** @type {!proto.sp.scan_service.Property.ResultType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.ResultType} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.setMostRecentResultType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool rule_accuracy = 5;
 * @return {boolean}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getRuleAccuracy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.setRuleAccuracy = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.clearRuleAccuracy = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.RuleResult.prototype.hasRuleAccuracy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RuleAccuracy rule_accuracy_2 = 6;
 * @return {!proto.sp.scan_service.Property.RuleAccuracy}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getRuleAccuracy2 = function() {
  return /** @type {!proto.sp.scan_service.Property.RuleAccuracy} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.RuleAccuracy} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.setRuleAccuracy2 = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated RelevantScanJob relevant_scan_jobs = 7;
 * @return {!Array<!proto.sp.scan_service.Property.RelevantScanJob>}
 */
proto.sp.scan_service.Property.RuleResult.prototype.getRelevantScanJobsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.Property.RelevantScanJob>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.Property.RelevantScanJob, 7));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.RelevantScanJob>} value
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
*/
proto.sp.scan_service.Property.RuleResult.prototype.setRelevantScanJobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sp.scan_service.Property.RelevantScanJob=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Property.RelevantScanJob}
 */
proto.sp.scan_service.Property.RuleResult.prototype.addRelevantScanJobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sp.scan_service.Property.RelevantScanJob, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.Property.RuleResult} returns this
 */
proto.sp.scan_service.Property.RuleResult.prototype.clearRelevantScanJobsList = function() {
  return this.setRelevantScanJobsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.Property.RegionResult.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Property.RegionResult.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Property.RegionResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Property.RegionResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RegionResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRegionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ruleResultsList: jspb.Message.toObjectList(msg.getRuleResultsList(),
    proto.sp.scan_service.Property.RuleResult.toObject, includeInstance),
    lastScanJobExecutionTime: (f = msg.getLastScanJobExecutionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Property.RegionResult}
 */
proto.sp.scan_service.Property.RegionResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Property.RegionResult;
  return proto.sp.scan_service.Property.RegionResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Property.RegionResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Property.RegionResult}
 */
proto.sp.scan_service.Property.RegionResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRegionId(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.Property.RuleResult;
      reader.readMessage(value,proto.sp.scan_service.Property.RuleResult.deserializeBinaryFromReader);
      msg.addRuleResults(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastScanJobExecutionTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Property.RegionResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Property.RegionResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Property.RegionResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RegionResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRegionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRuleResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.Property.RuleResult.serializeBinaryToWriter
    );
  }
  f = message.getLastScanJobExecutionTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string scan_region_id = 1;
 * @return {string}
 */
proto.sp.scan_service.Property.RegionResult.prototype.getScanRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Property.RegionResult} returns this
 */
proto.sp.scan_service.Property.RegionResult.prototype.setScanRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RuleResult rule_results = 2;
 * @return {!Array<!proto.sp.scan_service.Property.RuleResult>}
 */
proto.sp.scan_service.Property.RegionResult.prototype.getRuleResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.Property.RuleResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.Property.RuleResult, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.RuleResult>} value
 * @return {!proto.sp.scan_service.Property.RegionResult} returns this
*/
proto.sp.scan_service.Property.RegionResult.prototype.setRuleResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.Property.RuleResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Property.RuleResult}
 */
proto.sp.scan_service.Property.RegionResult.prototype.addRuleResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.Property.RuleResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.Property.RegionResult} returns this
 */
proto.sp.scan_service.Property.RegionResult.prototype.clearRuleResultsList = function() {
  return this.setRuleResultsList([]);
};


/**
 * optional google.protobuf.Timestamp last_scan_job_execution_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.Property.RegionResult.prototype.getLastScanJobExecutionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.Property.RegionResult} returns this
*/
proto.sp.scan_service.Property.RegionResult.prototype.setLastScanJobExecutionTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.Property.RegionResult} returns this
 */
proto.sp.scan_service.Property.RegionResult.prototype.clearLastScanJobExecutionTime = function() {
  return this.setLastScanJobExecutionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.RegionResult.prototype.hasLastScanJobExecutionTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.Property.RegionalResults.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Property.RegionalResults.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Property.RegionalResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Property.RegionalResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RegionalResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionResultsList: jspb.Message.toObjectList(msg.getRegionResultsList(),
    proto.sp.scan_service.Property.RegionResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Property.RegionalResults}
 */
proto.sp.scan_service.Property.RegionalResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Property.RegionalResults;
  return proto.sp.scan_service.Property.RegionalResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Property.RegionalResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Property.RegionalResults}
 */
proto.sp.scan_service.Property.RegionalResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.Property.RegionResult;
      reader.readMessage(value,proto.sp.scan_service.Property.RegionResult.deserializeBinaryFromReader);
      msg.addRegionResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Property.RegionalResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Property.RegionalResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Property.RegionalResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.RegionalResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.Property.RegionResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RegionResult region_results = 1;
 * @return {!Array<!proto.sp.scan_service.Property.RegionResult>}
 */
proto.sp.scan_service.Property.RegionalResults.prototype.getRegionResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.Property.RegionResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.Property.RegionResult, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.RegionResult>} value
 * @return {!proto.sp.scan_service.Property.RegionalResults} returns this
*/
proto.sp.scan_service.Property.RegionalResults.prototype.setRegionResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.Property.RegionResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Property.RegionResult}
 */
proto.sp.scan_service.Property.RegionalResults.prototype.addRegionResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.Property.RegionResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.Property.RegionalResults} returns this
 */
proto.sp.scan_service.Property.RegionalResults.prototype.clearRegionResultsList = function() {
  return this.setRegionResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.Property.PropertyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.Property.PropertyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.Property.PropertyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.PropertyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalDiscrepancyCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalRuleResults: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastScanJobExecutionTime: (f = msg.getLastScanJobExecutionTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.Property.PropertyStats}
 */
proto.sp.scan_service.Property.PropertyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.Property.PropertyStats;
  return proto.sp.scan_service.Property.PropertyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.Property.PropertyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.Property.PropertyStats}
 */
proto.sp.scan_service.Property.PropertyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalDiscrepancyCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalRuleResults(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastScanJobExecutionTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.Property.PropertyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.Property.PropertyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.Property.PropertyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.Property.PropertyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalDiscrepancyCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalRuleResults();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastScanJobExecutionTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_discrepancy_count = 1;
 * @return {number}
 */
proto.sp.scan_service.Property.PropertyStats.prototype.getTotalDiscrepancyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.Property.PropertyStats} returns this
 */
proto.sp.scan_service.Property.PropertyStats.prototype.setTotalDiscrepancyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_rule_results = 2;
 * @return {number}
 */
proto.sp.scan_service.Property.PropertyStats.prototype.getTotalRuleResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.Property.PropertyStats} returns this
 */
proto.sp.scan_service.Property.PropertyStats.prototype.setTotalRuleResults = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_scan_job_execution_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.Property.PropertyStats.prototype.getLastScanJobExecutionTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.Property.PropertyStats} returns this
*/
proto.sp.scan_service.Property.PropertyStats.prototype.setLastScanJobExecutionTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.Property.PropertyStats} returns this
 */
proto.sp.scan_service.Property.PropertyStats.prototype.clearLastScanJobExecutionTime = function() {
  return this.setLastScanJobExecutionTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.PropertyStats.prototype.hasLastScanJobExecutionTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.Property.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.Property.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.Property} returns this
*/
proto.sp.scan_service.Property.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PropertyType property_type = 3;
 * @return {!proto.sp.scan_service.Property.PropertyType}
 */
proto.sp.scan_service.Property.prototype.getPropertyType = function() {
  return /** @type {!proto.sp.scan_service.Property.PropertyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.sp.scan_service.Property.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RegionalResults regional_results = 5;
 * @return {?proto.sp.scan_service.Property.RegionalResults}
 */
proto.sp.scan_service.Property.prototype.getRegionalResults = function() {
  return /** @type{?proto.sp.scan_service.Property.RegionalResults} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.Property.RegionalResults, 5));
};


/**
 * @param {?proto.sp.scan_service.Property.RegionalResults|undefined} value
 * @return {!proto.sp.scan_service.Property} returns this
*/
proto.sp.scan_service.Property.prototype.setRegionalResults = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.clearRegionalResults = function() {
  return this.setRegionalResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.prototype.hasRegionalResults = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PropertyStats property_stats = 6;
 * @return {?proto.sp.scan_service.Property.PropertyStats}
 */
proto.sp.scan_service.Property.prototype.getPropertyStats = function() {
  return /** @type{?proto.sp.scan_service.Property.PropertyStats} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.Property.PropertyStats, 6));
};


/**
 * @param {?proto.sp.scan_service.Property.PropertyStats|undefined} value
 * @return {!proto.sp.scan_service.Property} returns this
*/
proto.sp.scan_service.Property.prototype.setPropertyStats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.clearPropertyStats = function() {
  return this.setPropertyStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.prototype.hasPropertyStats = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp max_scan_job_process_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.Property.prototype.getMaxScanJobProcessTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.Property} returns this
*/
proto.sp.scan_service.Property.prototype.setMaxScanJobProcessTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.Property} returns this
 */
proto.sp.scan_service.Property.prototype.clearMaxScanJobProcessTime = function() {
  return this.setMaxScanJobProcessTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.Property.prototype.hasMaxScanJobProcessTime = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    propertyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cacheTime: (f = msg.getCacheTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isLatest: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    data: (f = msg.getData()) && proto.sp.scan_service.PropertySnapshot.Data.toObject(includeInstance, f),
    propertyName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    propertyType: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySnapshot}
 */
proto.sp.scan_service.PropertySnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySnapshot;
  return proto.sp.scan_service.PropertySnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySnapshot}
 */
proto.sp.scan_service.PropertySnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCacheTime(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLatest(value);
      break;
    case 9:
      var value = new proto.sp.scan_service.PropertySnapshot.Data;
      reader.readMessage(value,proto.sp.scan_service.PropertySnapshot.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 11:
      var value = /** @type {!proto.sp.scan_service.Property.PropertyType} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getPropertyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCacheTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsLatest();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.sp.scan_service.PropertySnapshot.Data.serializeBinaryToWriter
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.PropertySnapshot.PropertySnapshotField = {
  PROPERTY_SNAPSHOT_FIELD_UNSET: 0,
  PROPERTY_SNAPSHOT_FIELD_SCAN_ARTIFACTS: 1
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.PropertySnapshot.Data.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySnapshot.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySnapshot.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySnapshot.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleResultsList: jspb.Message.toObjectList(msg.getRuleResultsList(),
    proto.sp.scan_service.Property.RuleResult.toObject, includeInstance),
    scanArtifactsList: jspb.Message.toObjectList(msg.getScanArtifactsList(),
    proto.sp.scan_service.ScanJobArtifact.toObject, includeInstance),
    lastScannedDate: (f = msg.getLastScannedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySnapshot.Data}
 */
proto.sp.scan_service.PropertySnapshot.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySnapshot.Data;
  return proto.sp.scan_service.PropertySnapshot.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySnapshot.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySnapshot.Data}
 */
proto.sp.scan_service.PropertySnapshot.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.Property.RuleResult;
      reader.readMessage(value,proto.sp.scan_service.Property.RuleResult.deserializeBinaryFromReader);
      msg.addRuleResults(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanJobArtifact;
      reader.readMessage(value,proto.sp.scan_service.ScanJobArtifact.deserializeBinaryFromReader);
      msg.addScanArtifacts(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastScannedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySnapshot.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySnapshot.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySnapshot.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.Property.RuleResult.serializeBinaryToWriter
    );
  }
  f = message.getScanArtifactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanJobArtifact.serializeBinaryToWriter
    );
  }
  f = message.getLastScannedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Property.RuleResult rule_results = 1;
 * @return {!Array<!proto.sp.scan_service.Property.RuleResult>}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.getRuleResultsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.Property.RuleResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.Property.RuleResult, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.RuleResult>} value
 * @return {!proto.sp.scan_service.PropertySnapshot.Data} returns this
*/
proto.sp.scan_service.PropertySnapshot.Data.prototype.setRuleResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.Property.RuleResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Property.RuleResult}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.addRuleResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.Property.RuleResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.PropertySnapshot.Data} returns this
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.clearRuleResultsList = function() {
  return this.setRuleResultsList([]);
};


/**
 * repeated ScanJobArtifact scan_artifacts = 2;
 * @return {!Array<!proto.sp.scan_service.ScanJobArtifact>}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.getScanArtifactsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanJobArtifact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanJobArtifact, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanJobArtifact>} value
 * @return {!proto.sp.scan_service.PropertySnapshot.Data} returns this
*/
proto.sp.scan_service.PropertySnapshot.Data.prototype.setScanArtifactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanJobArtifact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanJobArtifact}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.addScanArtifacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanJobArtifact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.PropertySnapshot.Data} returns this
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.clearScanArtifactsList = function() {
  return this.setScanArtifactsList([]);
};


/**
 * optional google.protobuf.Timestamp last_scanned_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.getLastScannedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.PropertySnapshot.Data} returns this
*/
proto.sp.scan_service.PropertySnapshot.Data.prototype.setLastScannedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySnapshot.Data} returns this
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.clearLastScannedDate = function() {
  return this.setLastScannedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySnapshot.Data.prototype.hasLastScannedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
*/
proto.sp.scan_service.PropertySnapshot.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySnapshot.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string property_id = 3;
 * @return {string}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getPropertyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.setPropertyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string region_id = 4;
 * @return {string}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp cache_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getCacheTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
*/
proto.sp.scan_service.PropertySnapshot.prototype.setCacheTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.clearCacheTime = function() {
  return this.setCacheTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySnapshot.prototype.hasCacheTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_latest = 7;
 * @return {boolean}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getIsLatest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.setIsLatest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Data data = 9;
 * @return {?proto.sp.scan_service.PropertySnapshot.Data}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getData = function() {
  return /** @type{?proto.sp.scan_service.PropertySnapshot.Data} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.PropertySnapshot.Data, 9));
};


/**
 * @param {?proto.sp.scan_service.PropertySnapshot.Data|undefined} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
*/
proto.sp.scan_service.PropertySnapshot.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySnapshot.prototype.hasData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string property_name = 10;
 * @return {string}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Property.PropertyType property_type = 11;
 * @return {!proto.sp.scan_service.Property.PropertyType}
 */
proto.sp.scan_service.PropertySnapshot.prototype.getPropertyType = function() {
  return /** @type {!proto.sp.scan_service.Property.PropertyType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @return {!proto.sp.scan_service.PropertySnapshot} returns this
 */
proto.sp.scan_service.PropertySnapshot.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdRequest}
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotByIdRequest;
  return proto.sp.scan_service.GetPropertySnapshotByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdRequest}
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PropertySnapshot.PropertySnapshotField fields = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>}
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.sp.scan_service.PropertySnapshot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdResponse}
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotByIdResponse;
  return proto.sp.scan_service.GetPropertySnapshotByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdResponse}
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.PropertySnapshot;
      reader.readMessage(value,proto.sp.scan_service.PropertySnapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.PropertySnapshot.serializeBinaryToWriter
    );
  }
};


/**
 * optional PropertySnapshot snapshot = 1;
 * @return {?proto.sp.scan_service.PropertySnapshot}
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.prototype.getSnapshot = function() {
  return /** @type{?proto.sp.scan_service.PropertySnapshot} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.PropertySnapshot, 1));
};


/**
 * @param {?proto.sp.scan_service.PropertySnapshot|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdResponse} returns this
*/
proto.sp.scan_service.GetPropertySnapshotByIdResponse.prototype.setSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySnapshotByIdResponse} returns this
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotByIdResponse.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest;
  return proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 100:
      var values = /** @type {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      100,
      f
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} returns this
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string region_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} returns this
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PropertySnapshot.PropertySnapshotField fields = 100;
 * @return {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} value
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} returns this
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} returns this
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest} returns this
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshot: (f = msg.getSnapshot()) && proto.sp.scan_service.PropertySnapshot.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse;
  return proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.PropertySnapshot;
      reader.readMessage(value,proto.sp.scan_service.PropertySnapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.PropertySnapshot.serializeBinaryToWriter
    );
  }
};


/**
 * optional PropertySnapshot snapshot = 1;
 * @return {?proto.sp.scan_service.PropertySnapshot}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.prototype.getSnapshot = function() {
  return /** @type{?proto.sp.scan_service.PropertySnapshot} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.PropertySnapshot, 1));
};


/**
 * @param {?proto.sp.scan_service.PropertySnapshot|undefined} value
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse} returns this
*/
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.prototype.setSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse} returns this
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetLatestPropertySnapshotByNameAndRegionResponse.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotsRequest;
  return proto.sp.scan_service.GetPropertySnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetPropertySnapshotsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_ID: 1,
  SORT_FIELD_CREATED_TIME: 2,
  SORT_FIELD_UPDATED_TIME: 3,
  SORT_FIELD_PROPERTY_TYPE: 4,
  SORT_FIELD_NAME: 5,
  SORT_FIELD_REGION: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter;
  return proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortField}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    propertySetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter;
  return proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 account_id = 1;
 * @return {number}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string property_set_id = 2;
 * @return {string}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.repeatedFields_ = [1,4,5,6,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    regionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    propertyTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    nameFilterList: jspb.Message.toObjectList(msg.getNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    propertySetFilterList: jspb.Message.toObjectList(msg.getPropertySetFilterList(),
    proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.toObject, includeInstance),
    latestOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    propertyIdFilterList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotsRequest.Filters;
  return proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionIdFilter(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.sp.scan_service.Property.PropertyType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPropertyTypeFilter(values[i]);
      }
      break;
    case 6:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addNameFilter(value);
      break;
    case 7:
      var value = new proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter;
      reader.readMessage(value,proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.deserializeBinaryFromReader);
      msg.addPropertySetFilter(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLatestOnly(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addPropertyIdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPropertyTypeFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getPropertySetFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter.serializeBinaryToWriter
    );
  }
  f = message.getLatestOnly();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPropertyIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 2;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 2));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string region_id_filter = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.addRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearRegionIdFilterList = function() {
  return this.setRegionIdFilterList([]);
};


/**
 * repeated Property.PropertyType property_type_filter = 5;
 * @return {!Array<!proto.sp.scan_service.Property.PropertyType>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getPropertyTypeFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.Property.PropertyType>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.PropertyType>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setPropertyTypeFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.addPropertyTypeFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearPropertyTypeFilterList = function() {
  return this.setPropertyTypeFilterList([]);
};


/**
 * repeated sp.service_common.StringFilter name_filter = 6;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 6));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.addNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearNameFilterList = function() {
  return this.setNameFilterList([]);
};


/**
 * repeated PropertySetFilter property_set_filter = 7;
 * @return {!Array<!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getPropertySetFilterList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter, 7));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setPropertySetFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.addPropertySetFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sp.scan_service.GetPropertySnapshotsRequest.PropertySetFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearPropertySetFilterList = function() {
  return this.setPropertySetFilterList([]);
};


/**
 * optional bool latest_only = 8;
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getLatestOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setLatestOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated string property_id_filter = 9;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.getPropertyIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.setPropertyIdFilterList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.addPropertyIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.Filters.prototype.clearPropertyIdFilterList = function() {
  return this.setPropertyIdFilterList([]);
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetPropertySnapshotsRequest.Filters}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetPropertySnapshotsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetPropertySnapshotsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetPropertySnapshotsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetPropertySnapshotsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated PropertySnapshot.PropertySnapshotField fields = 4;
 * @return {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>}
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.PropertySnapshot.PropertySnapshotField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsRequest} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    snapshotsList: jspb.Message.toObjectList(msg.getSnapshotsList(),
    proto.sp.scan_service.PropertySnapshot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsResponse}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySnapshotsResponse;
  return proto.sp.scan_service.GetPropertySnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsResponse}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.PropertySnapshot;
      reader.readMessage(value,proto.sp.scan_service.PropertySnapshot.deserializeBinaryFromReader);
      msg.addSnapshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSnapshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.PropertySnapshot.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsResponse} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsResponse} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PropertySnapshot snapshots = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySnapshot>}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.getSnapshotsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySnapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySnapshot, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySnapshot>} value
 * @return {!proto.sp.scan_service.GetPropertySnapshotsResponse} returns this
*/
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.setSnapshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySnapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySnapshot}
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.addSnapshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.PropertySnapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySnapshotsResponse} returns this
 */
proto.sp.scan_service.GetPropertySnapshotsResponse.prototype.clearSnapshotsList = function() {
  return this.setSnapshotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.AccuracyMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.AccuracyMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.AccuracyMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AccuracyMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    correctCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    falseNegatives: jspb.Message.getFieldWithDefault(msg, 3, 0),
    falsePositives: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.AccuracyMetrics}
 */
proto.sp.scan_service.AccuracyMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.AccuracyMetrics;
  return proto.sp.scan_service.AccuracyMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.AccuracyMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.AccuracyMetrics}
 */
proto.sp.scan_service.AccuracyMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorrectCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFalseNegatives(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFalsePositives(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.AccuracyMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.AccuracyMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.AccuracyMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AccuracyMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrectCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFalseNegatives();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFalsePositives();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 correct_count = 1;
 * @return {number}
 */
proto.sp.scan_service.AccuracyMetrics.prototype.getCorrectCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AccuracyMetrics} returns this
 */
proto.sp.scan_service.AccuracyMetrics.prototype.setCorrectCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 total_count = 2;
 * @return {number}
 */
proto.sp.scan_service.AccuracyMetrics.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AccuracyMetrics} returns this
 */
proto.sp.scan_service.AccuracyMetrics.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 false_negatives = 3;
 * @return {number}
 */
proto.sp.scan_service.AccuracyMetrics.prototype.getFalseNegatives = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AccuracyMetrics} returns this
 */
proto.sp.scan_service.AccuracyMetrics.prototype.setFalseNegatives = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 false_positives = 4;
 * @return {number}
 */
proto.sp.scan_service.AccuracyMetrics.prototype.getFalsePositives = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AccuracyMetrics} returns this
 */
proto.sp.scan_service.AccuracyMetrics.prototype.setFalsePositives = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.PropertySet.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySet.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    propertySetItemsList: jspb.Message.toObjectList(msg.getPropertySetItemsList(),
    proto.sp.scan_service.PropertySet.PropertySetItem.toObject, includeInstance),
    scanRuleSetId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    activationDate: (f = msg.getActivationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deactivationDate: (f = msg.getDeactivationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && proto.sp.scan_service.PropertySet.PropertySetStats.toObject(includeInstance, f),
    propertyCount: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySet}
 */
proto.sp.scan_service.PropertySet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySet;
  return proto.sp.scan_service.PropertySet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySet}
 */
proto.sp.scan_service.PropertySet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = new proto.sp.scan_service.PropertySet.PropertySetItem;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader);
      msg.addPropertySetItems(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleSetId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivationDate(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeactivationDate(value);
      break;
    case 12:
      var value = new proto.sp.scan_service.PropertySet.PropertySetStats;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.PropertySetStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPropertyCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPropertySetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter
    );
  }
  f = message.getScanRuleSetId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActivationDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeactivationDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.sp.scan_service.PropertySet.PropertySetStats.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.PropertySet.PropertySetStatus = {
  PROPERTY_SET_STATUS_UNSET: 0,
  PROPERTY_SET_STATUS_ACTIVE: 1,
  PROPERTY_SET_STATUS_DISABLED: 2,
  PROPERTY_SET_STATUS_DELETED: 3,
  PROPERTY_SET_STATUS_DISABLED_PENDING: 4,
  PROPERTY_SET_STATUS_DELETED_PENDING: 5
};

/**
 * @enum {number}
 */
proto.sp.scan_service.PropertySet.PropertySetField = {
  PROPERTY_SET_FIELD_UNSET: 0,
  PROPERTY_SET_FIELD_PROPERTY_SET_ITEMS: 1,
  PROPERTY_SET_FIELD_PROPERTY_COUNT: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySet.PropertySetItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySet.PropertySetItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    propertyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertySnapshotId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySet.PropertySetItem;
  return proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.Property.PropertyType} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySnapshotId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Property.PropertyType property_type = 1;
 * @return {!proto.sp.scan_service.Property.PropertyType}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.getPropertyType = function() {
  return /** @type {!proto.sp.scan_service.Property.PropertyType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem} returns this
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string property_name = 2;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem} returns this
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string property_snapshot_id = 3;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.getPropertySnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem} returns this
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.setPropertySnapshotId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem} returns this
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.clearPropertySnapshotId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.PropertySetItem.prototype.hasPropertySnapshotId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.PropertySet.PropertySetStats.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.PropertySet.PropertySetStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySet.PropertySetStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    perRuleMetricsMap: (f = msg.getPerRuleMetricsMap()) ? f.toObject(includeInstance, proto.sp.scan_service.AccuracyMetrics.toObject) : [],
    scannedPropertyCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStats}
 */
proto.sp.scan_service.PropertySet.PropertySetStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.PropertySet.PropertySetStats;
  return proto.sp.scan_service.PropertySet.PropertySetStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStats}
 */
proto.sp.scan_service.PropertySet.PropertySetStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPerRuleMetricsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.sp.scan_service.AccuracyMetrics.deserializeBinaryFromReader, "", new proto.sp.scan_service.AccuracyMetrics());
         });
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScannedPropertyCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.PropertySet.PropertySetStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.PropertySet.PropertySetStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.PropertySet.PropertySetStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerRuleMetricsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.sp.scan_service.AccuracyMetrics.serializeBinaryToWriter);
  }
  f = message.getScannedPropertyCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * map<string, AccuracyMetrics> per_rule_metrics = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sp.scan_service.AccuracyMetrics>}
 */
proto.sp.scan_service.PropertySet.PropertySetStats.prototype.getPerRuleMetricsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sp.scan_service.AccuracyMetrics>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.sp.scan_service.AccuracyMetrics));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStats} returns this
 */
proto.sp.scan_service.PropertySet.PropertySetStats.prototype.clearPerRuleMetricsMap = function() {
  this.getPerRuleMetricsMap().clear();
  return this;};


/**
 * optional int32 scanned_property_count = 2;
 * @return {number}
 */
proto.sp.scan_service.PropertySet.PropertySetStats.prototype.getScannedPropertyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStats} returns this
 */
proto.sp.scan_service.PropertySet.PropertySetStats.prototype.setScannedPropertyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.PropertySet.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
*/
proto.sp.scan_service.PropertySet.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PropertySetStatus status = 3;
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStatus}
 */
proto.sp.scan_service.PropertySet.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStatus} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 account_id = 5;
 * @return {number}
 */
proto.sp.scan_service.PropertySet.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated PropertySetItem property_set_items = 7;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>}
 */
proto.sp.scan_service.PropertySet.prototype.getPropertySetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySet.PropertySetItem, 7));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
*/
proto.sp.scan_service.PropertySet.prototype.setPropertySetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.PropertySet.prototype.addPropertySetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.sp.scan_service.PropertySet.PropertySetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearPropertySetItemsList = function() {
  return this.setPropertySetItemsList([]);
};


/**
 * optional string scan_rule_set_id = 8;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.prototype.getScanRuleSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setScanRuleSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string region_id = 9;
 * @return {string}
 */
proto.sp.scan_service.PropertySet.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp activation_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.PropertySet.prototype.getActivationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
*/
proto.sp.scan_service.PropertySet.prototype.setActivationDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearActivationDate = function() {
  return this.setActivationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.prototype.hasActivationDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp deactivation_date = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.PropertySet.prototype.getDeactivationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
*/
proto.sp.scan_service.PropertySet.prototype.setDeactivationDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearDeactivationDate = function() {
  return this.setDeactivationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.prototype.hasDeactivationDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PropertySetStats stats = 12;
 * @return {?proto.sp.scan_service.PropertySet.PropertySetStats}
 */
proto.sp.scan_service.PropertySet.prototype.getStats = function() {
  return /** @type{?proto.sp.scan_service.PropertySet.PropertySetStats} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.PropertySet.PropertySetStats, 12));
};


/**
 * @param {?proto.sp.scan_service.PropertySet.PropertySetStats|undefined} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
*/
proto.sp.scan_service.PropertySet.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.prototype.hasStats = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int32 property_count = 13;
 * @return {number}
 */
proto.sp.scan_service.PropertySet.prototype.getPropertyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.setPropertyCount = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.PropertySet} returns this
 */
proto.sp.scan_service.PropertySet.prototype.clearPropertyCount = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.PropertySet.prototype.hasPropertyCount = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.IdOrNameCase = {
  ID_OR_NAME_NOT_SET: 0,
  ID: 1,
  NAME: 2
};

/**
 * @return {proto.sp.scan_service.GetPropertyByIdOrNameRequest.IdOrNameCase}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.getIdOrNameCase = function() {
  return /** @type {proto.sp.scan_service.GetPropertyByIdOrNameRequest.IdOrNameCase} */(jspb.Message.computeOneofCase(this, proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertyByIdOrNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameRequest}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertyByIdOrNameRequest;
  return proto.sp.scan_service.GetPropertyByIdOrNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameRequest}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertyByIdOrNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} returns this
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.setId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} returns this
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.clearId = function() {
  return jspb.Message.setOneofField(this, 1, proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} returns this
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.setName = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameRequest} returns this
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.clearName = function() {
  return jspb.Message.setOneofField(this, 2, proto.sp.scan_service.GetPropertyByIdOrNameRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertyByIdOrNameRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertyByIdOrNameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: (f = msg.getProperty()) && proto.sp.scan_service.Property.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameResponse}
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertyByIdOrNameResponse;
  return proto.sp.scan_service.GetPropertyByIdOrNameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameResponse}
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.Property;
      reader.readMessage(value,proto.sp.scan_service.Property.deserializeBinaryFromReader);
      msg.setProperty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertyByIdOrNameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertyByIdOrNameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.Property.serializeBinaryToWriter
    );
  }
};


/**
 * optional Property property = 1;
 * @return {?proto.sp.scan_service.Property}
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.prototype.getProperty = function() {
  return /** @type{?proto.sp.scan_service.Property} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.Property, 1));
};


/**
 * @param {?proto.sp.scan_service.Property|undefined} value
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameResponse} returns this
*/
proto.sp.scan_service.GetPropertyByIdOrNameResponse.prototype.setProperty = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertyByIdOrNameResponse} returns this
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.prototype.clearProperty = function() {
  return this.setProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertyByIdOrNameResponse.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreatePropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreatePropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreatePropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreatePropertyRequest}
 */
proto.sp.scan_service.CreatePropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreatePropertyRequest;
  return proto.sp.scan_service.CreatePropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreatePropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreatePropertyRequest}
 */
proto.sp.scan_service.CreatePropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.Property.PropertyType} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreatePropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreatePropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreatePropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Property.PropertyType property_type = 1;
 * @return {!proto.sp.scan_service.Property.PropertyType}
 */
proto.sp.scan_service.CreatePropertyRequest.prototype.getPropertyType = function() {
  return /** @type {!proto.sp.scan_service.Property.PropertyType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @return {!proto.sp.scan_service.CreatePropertyRequest} returns this
 */
proto.sp.scan_service.CreatePropertyRequest.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertyRequest} returns this
 */
proto.sp.scan_service.CreatePropertyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreatePropertyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreatePropertyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreatePropertyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreatePropertyResponse}
 */
proto.sp.scan_service.CreatePropertyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreatePropertyResponse;
  return proto.sp.scan_service.CreatePropertyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreatePropertyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreatePropertyResponse}
 */
proto.sp.scan_service.CreatePropertyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreatePropertyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreatePropertyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreatePropertyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertyResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertyResponse} returns this
 */
proto.sp.scan_service.CreatePropertyResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertiesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetPropertiesRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetPropertiesRequest.SortParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertiesRequest}
 */
proto.sp.scan_service.GetPropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertiesRequest;
  return proto.sp.scan_service.GetPropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertiesRequest}
 */
proto.sp.scan_service.GetPropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetPropertiesRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetPropertiesRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetPropertiesRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetPropertiesRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetPropertiesRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetPropertiesRequest.SortParameter.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetPropertiesRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_ID: 1,
  SORT_FIELD_CREATED_TIME: 2,
  SORT_FIELD_UPDATED_TIME: 3,
  SORT_FIELD_PROPERTY_TYPE: 4,
  SORT_FIELD_NAME: 5,
  SORT_FIELD_MAX_SCAN_JOB_PROCESS_TIME: 6,
  SORT_FIELD_TOTAL_DISCREPANCY_COUNT: 7,
  SORT_FIELD_TOTAL_RULE_RESULTS: 8
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertiesRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertiesRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertiesRequest.SortParameter;
  return proto.sp.scan_service.GetPropertiesRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertiesRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetPropertiesRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertiesRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertiesRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetPropertiesRequest.SortField}
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetPropertiesRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetPropertiesRequest.SortField} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.repeatedFields_ = [1,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertiesRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertiesRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    resultRegionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    propertyTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    nameFilterList: jspb.Message.toObjectList(msg.getNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    propertySetIdFilter: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertiesRequest.Filters;
  return proto.sp.scan_service.GetPropertiesRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertiesRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addResultRegionIdFilter(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.sp.scan_service.Property.PropertyType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPropertyTypeFilter(values[i]);
      }
      break;
    case 6:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addNameFilter(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetIdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertiesRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertiesRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getResultRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPropertyTypeFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 2;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 2));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string result_region_id_filter = 4;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getResultRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setResultRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.addResultRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearResultRegionIdFilterList = function() {
  return this.setResultRegionIdFilterList([]);
};


/**
 * repeated Property.PropertyType property_type_filter = 5;
 * @return {!Array<!proto.sp.scan_service.Property.PropertyType>}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getPropertyTypeFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.Property.PropertyType>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property.PropertyType>} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setPropertyTypeFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.sp.scan_service.Property.PropertyType} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.addPropertyTypeFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearPropertyTypeFilterList = function() {
  return this.setPropertyTypeFilterList([]);
};


/**
 * repeated sp.service_common.StringFilter name_filter = 6;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 6));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.addNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearNameFilterList = function() {
  return this.setNameFilterList([]);
};


/**
 * optional string property_set_id_filter = 7;
 * @return {string}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.getPropertySetIdFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.setPropertySetIdFilter = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertiesRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.clearPropertySetIdFilter = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertiesRequest.Filters.prototype.hasPropertySetIdFilter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest} returns this
*/
proto.sp.scan_service.GetPropertiesRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertiesRequest} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetPropertiesRequest.Filters}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetPropertiesRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetPropertiesRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetPropertiesRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest} returns this
*/
proto.sp.scan_service.GetPropertiesRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertiesRequest} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetPropertiesRequest.SortParameter>}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetPropertiesRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetPropertiesRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetPropertiesRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetPropertiesRequest} returns this
*/
proto.sp.scan_service.GetPropertiesRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetPropertiesRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertiesRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetPropertiesRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertiesRequest} returns this
 */
proto.sp.scan_service.GetPropertiesRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertiesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.sp.scan_service.Property.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertiesResponse}
 */
proto.sp.scan_service.GetPropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertiesResponse;
  return proto.sp.scan_service.GetPropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertiesResponse}
 */
proto.sp.scan_service.GetPropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.Property;
      reader.readMessage(value,proto.sp.scan_service.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.Property.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertiesResponse} returns this
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertiesResponse} returns this
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Property properties = 2;
 * @return {!Array<!proto.sp.scan_service.Property>}
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.sp.scan_service.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.Property, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.Property>} value
 * @return {!proto.sp.scan_service.GetPropertiesResponse} returns this
*/
proto.sp.scan_service.GetPropertiesResponse.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Property}
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertiesResponse} returns this
 */
proto.sp.scan_service.GetPropertiesResponse.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.CreatePropertySetRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreatePropertySetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreatePropertySetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertySetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    propertySetItemsList: jspb.Message.toObjectList(msg.getPropertySetItemsList(),
    proto.sp.scan_service.PropertySet.PropertySetItem.toObject, includeInstance),
    scanRuleSetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    regionId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deactivationDate: (f = msg.getDeactivationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    activationDate: (f = msg.getActivationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest}
 */
proto.sp.scan_service.CreatePropertySetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreatePropertySetRequest;
  return proto.sp.scan_service.CreatePropertySetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreatePropertySetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest}
 */
proto.sp.scan_service.CreatePropertySetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.PropertySet.PropertySetItem;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader);
      msg.addPropertySetItems(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleSetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegionId(value);
      break;
    case 7:
      var value = /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeactivationDate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreatePropertySetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreatePropertySetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertySetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPropertySetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter
    );
  }
  f = message.getScanRuleSetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRegionId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDeactivationDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivationDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 account_id = 3;
 * @return {number}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated PropertySet.PropertySetItem property_set_items = 4;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getPropertySetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySet.PropertySetItem, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
*/
proto.sp.scan_service.CreatePropertySetRequest.prototype.setPropertySetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.addPropertySetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sp.scan_service.PropertySet.PropertySetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.clearPropertySetItemsList = function() {
  return this.setPropertySetItemsList([]);
};


/**
 * optional string scan_rule_set_id = 5;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getScanRuleSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.setScanRuleSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string region_id = 6;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getRegionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.setRegionId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PropertySet.PropertySetStatus status = 7;
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStatus}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStatus} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp deactivation_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getDeactivationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
*/
proto.sp.scan_service.CreatePropertySetRequest.prototype.setDeactivationDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.clearDeactivationDate = function() {
  return this.setDeactivationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.hasDeactivationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp activation_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.getActivationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
*/
proto.sp.scan_service.CreatePropertySetRequest.prototype.setActivationDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.CreatePropertySetRequest} returns this
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.clearActivationDate = function() {
  return this.setActivationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreatePropertySetRequest.prototype.hasActivationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreatePropertySetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreatePropertySetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreatePropertySetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertySetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreatePropertySetResponse}
 */
proto.sp.scan_service.CreatePropertySetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreatePropertySetResponse;
  return proto.sp.scan_service.CreatePropertySetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreatePropertySetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreatePropertySetResponse}
 */
proto.sp.scan_service.CreatePropertySetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreatePropertySetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreatePropertySetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreatePropertySetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreatePropertySetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.CreatePropertySetResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreatePropertySetResponse} returns this
 */
proto.sp.scan_service.CreatePropertySetResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UpdatePropertySetRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdatePropertySetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdatePropertySetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdatePropertySetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertySetItemsList: jspb.Message.toObjectList(msg.getPropertySetItemsList(),
    proto.sp.scan_service.PropertySet.PropertySetItem.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    scanRuleSetId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    deactivationDate: (f = msg.getDeactivationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    activationDate: (f = msg.getActivationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest}
 */
proto.sp.scan_service.UpdatePropertySetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdatePropertySetRequest;
  return proto.sp.scan_service.UpdatePropertySetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdatePropertySetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest}
 */
proto.sp.scan_service.UpdatePropertySetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.sp.scan_service.PropertySet.PropertySetItem;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader);
      msg.addPropertySetItems(value);
      break;
    case 5:
      var value = /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleSetId(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeactivationDate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdatePropertySetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdatePropertySetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdatePropertySetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertySetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDeactivationDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivationDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PropertySet.PropertySetItem property_set_items = 4;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getPropertySetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySet.PropertySetItem, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
*/
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setPropertySetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.addPropertySetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.sp.scan_service.PropertySet.PropertySetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearPropertySetItemsList = function() {
  return this.setPropertySetItemsList([]);
};


/**
 * optional PropertySet.PropertySetStatus status = 5;
 * @return {!proto.sp.scan_service.PropertySet.PropertySetStatus}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.PropertySet.PropertySetStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStatus} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string scan_rule_set_id = 6;
 * @return {string}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getScanRuleSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setScanRuleSetId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearScanRuleSetId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.hasScanRuleSetId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp deactivation_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getDeactivationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
*/
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setDeactivationDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearDeactivationDate = function() {
  return this.setDeactivationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.hasDeactivationDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp activation_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.getActivationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
*/
proto.sp.scan_service.UpdatePropertySetRequest.prototype.setActivationDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.UpdatePropertySetRequest} returns this
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.clearActivationDate = function() {
  return this.setActivationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdatePropertySetRequest.prototype.hasActivationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdatePropertySetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdatePropertySetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdatePropertySetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdatePropertySetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdatePropertySetResponse}
 */
proto.sp.scan_service.UpdatePropertySetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdatePropertySetResponse;
  return proto.sp.scan_service.UpdatePropertySetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdatePropertySetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdatePropertySetResponse}
 */
proto.sp.scan_service.UpdatePropertySetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdatePropertySetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdatePropertySetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdatePropertySetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdatePropertySetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.UpdatePropertySetResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UpdatePropertySetResponse} returns this
 */
proto.sp.scan_service.UpdatePropertySetResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.AddItemsToPropertySetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.AddItemsToPropertySetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertySetItemsList: jspb.Message.toObjectList(msg.getPropertySetItemsList(),
    proto.sp.scan_service.PropertySet.PropertySetItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.AddItemsToPropertySetRequest}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.AddItemsToPropertySetRequest;
  return proto.sp.scan_service.AddItemsToPropertySetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.AddItemsToPropertySetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.AddItemsToPropertySetRequest}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.PropertySet.PropertySetItem;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader);
      msg.addPropertySetItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.AddItemsToPropertySetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.AddItemsToPropertySetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertySetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_set_id = 1;
 * @return {string}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.AddItemsToPropertySetRequest} returns this
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PropertySet.PropertySetItem property_set_items = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.getPropertySetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySet.PropertySetItem, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} value
 * @return {!proto.sp.scan_service.AddItemsToPropertySetRequest} returns this
*/
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.setPropertySetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.addPropertySetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.PropertySet.PropertySetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.AddItemsToPropertySetRequest} returns this
 */
proto.sp.scan_service.AddItemsToPropertySetRequest.prototype.clearPropertySetItemsList = function() {
  return this.setPropertySetItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.AddItemsToPropertySetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.AddItemsToPropertySetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.AddItemsToPropertySetResponse}
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.AddItemsToPropertySetResponse;
  return proto.sp.scan_service.AddItemsToPropertySetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.AddItemsToPropertySetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.AddItemsToPropertySetResponse}
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.AddItemsToPropertySetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.AddItemsToPropertySetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.AddItemsToPropertySetResponse} returns this
 */
proto.sp.scan_service.AddItemsToPropertySetResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RemoveItemsFromPropertySetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertySetItemsList: jspb.Message.toObjectList(msg.getPropertySetItemsList(),
    proto.sp.scan_service.PropertySet.PropertySetItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RemoveItemsFromPropertySetRequest;
  return proto.sp.scan_service.RemoveItemsFromPropertySetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertySetId(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.PropertySet.PropertySetItem;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.PropertySetItem.deserializeBinaryFromReader);
      msg.addPropertySetItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RemoveItemsFromPropertySetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertySetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.PropertySet.PropertySetItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string property_set_id = 1;
 * @return {string}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.getPropertySetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} returns this
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.setPropertySetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PropertySet.PropertySetItem property_set_items = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.getPropertySetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySet.PropertySetItem, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetItem>} value
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} returns this
*/
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.setPropertySetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySet.PropertySetItem}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.addPropertySetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.PropertySet.PropertySetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetRequest} returns this
 */
proto.sp.scan_service.RemoveItemsFromPropertySetRequest.prototype.clearPropertySetItemsList = function() {
  return this.setPropertySetItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.RemoveItemsFromPropertySetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetResponse}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.RemoveItemsFromPropertySetResponse;
  return proto.sp.scan_service.RemoveItemsFromPropertySetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetResponse}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.RemoveItemsFromPropertySetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.RemoveItemsFromPropertySetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.RemoveItemsFromPropertySetResponse} returns this
 */
proto.sp.scan_service.RemoveItemsFromPropertySetResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySetByIdRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySetByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySetByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySetByIdRequest}
 */
proto.sp.scan_service.GetPropertySetByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySetByIdRequest;
  return proto.sp.scan_service.GetPropertySetByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySetByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySetByIdRequest}
 */
proto.sp.scan_service.GetPropertySetByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySetByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySetByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertySetByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PropertySet.PropertySetField fields = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>}
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>} value
 * @return {!proto.sp.scan_service.GetPropertySetByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetByIdRequest} returns this
 */
proto.sp.scan_service.GetPropertySetByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySetByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySetByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySetByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertySet: (f = msg.getPropertySet()) && proto.sp.scan_service.PropertySet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySetByIdResponse}
 */
proto.sp.scan_service.GetPropertySetByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySetByIdResponse;
  return proto.sp.scan_service.GetPropertySetByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySetByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySetByIdResponse}
 */
proto.sp.scan_service.GetPropertySetByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.PropertySet;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.deserializeBinaryFromReader);
      msg.setPropertySet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySetByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySetByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySetByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertySet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.PropertySet.serializeBinaryToWriter
    );
  }
};


/**
 * optional PropertySet property_set = 1;
 * @return {?proto.sp.scan_service.PropertySet}
 */
proto.sp.scan_service.GetPropertySetByIdResponse.prototype.getPropertySet = function() {
  return /** @type{?proto.sp.scan_service.PropertySet} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.PropertySet, 1));
};


/**
 * @param {?proto.sp.scan_service.PropertySet|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySetByIdResponse} returns this
*/
proto.sp.scan_service.GetPropertySetByIdResponse.prototype.setPropertySet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySetByIdResponse} returns this
 */
proto.sp.scan_service.GetPropertySetByIdResponse.prototype.clearPropertySet = function() {
  return this.setPropertySet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySetByIdResponse.prototype.hasPropertySet = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySetsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetPropertySetsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetPropertySetsRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest}
 */
proto.sp.scan_service.GetPropertySetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySetsRequest;
  return proto.sp.scan_service.GetPropertySetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest}
 */
proto.sp.scan_service.GetPropertySetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetPropertySetsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetPropertySetsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetPropertySetsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetPropertySetsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetPropertySetsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetPropertySetsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_ID: 1,
  SORT_FIELD_CREATED_TIME: 2,
  SORT_FIELD_UPDATED_TIME: 3,
  SORT_FIELD_STATUS: 4,
  SORT_FIELD_NAME: 5,
  SORT_FIELD_REGION: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySetsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySetsRequest.SortParameter;
  return proto.sp.scan_service.GetPropertySetsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetPropertySetsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySetsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.SortField}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetPropertySetsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.repeatedFields_ = [1,2,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySetsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusFilterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    nameFilterList: jspb.Message.toObjectList(msg.getNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    regionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    hasPropertyIdFilterList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    accountIdFilterList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySetsRequest.Filters;
  return proto.sp.scan_service.GetPropertySetsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.PropertySet.PropertySetStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusFilter(values[i]);
      }
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 4:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 5:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addNameFilter(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionIdFilter(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addHasPropertyIdFilter(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountIdFilter(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySetsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getHasPropertyIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getAccountIdFilterList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * repeated PropertySet.PropertySetStatus status_filter = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetStatus>}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.PropertySet.PropertySetStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetStatus>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setStatusFilterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.addStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearStatusFilterList = function() {
  return this.setStatusFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 4;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 4));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated sp.service_common.StringFilter name_filter = 5;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 5));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
*/
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.addNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearNameFilterList = function() {
  return this.setNameFilterList([]);
};


/**
 * repeated string region_id_filter = 6;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.addRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearRegionIdFilterList = function() {
  return this.setRegionIdFilterList([]);
};


/**
 * repeated string has_property_id_filter = 7;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getHasPropertyIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setHasPropertyIdFilterList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.addHasPropertyIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearHasPropertyIdFilterList = function() {
  return this.setHasPropertyIdFilterList([]);
};


/**
 * repeated int32 account_id_filter = 8;
 * @return {!Array<number>}
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.getAccountIdFilterList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.setAccountIdFilterList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.addAccountIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.Filters.prototype.clearAccountIdFilterList = function() {
  return this.setAccountIdFilterList([]);
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
*/
proto.sp.scan_service.GetPropertySetsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetPropertySetsRequest.Filters}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetPropertySetsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetPropertySetsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetPropertySetsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
*/
proto.sp.scan_service.GetPropertySetsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetPropertySetsRequest.SortParameter>}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetPropertySetsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetPropertySetsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetPropertySetsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
*/
proto.sp.scan_service.GetPropertySetsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest.SortParameter}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetPropertySetsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated PropertySet.PropertySetField fields = 4;
 * @return {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>}
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet.PropertySetField>} value
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.PropertySet.PropertySetField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsRequest} returns this
 */
proto.sp.scan_service.GetPropertySetsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetPropertySetsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetPropertySetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetPropertySetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertySetsList: jspb.Message.toObjectList(msg.getPropertySetsList(),
    proto.sp.scan_service.PropertySet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetPropertySetsResponse}
 */
proto.sp.scan_service.GetPropertySetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetPropertySetsResponse;
  return proto.sp.scan_service.GetPropertySetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetPropertySetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetPropertySetsResponse}
 */
proto.sp.scan_service.GetPropertySetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.PropertySet;
      reader.readMessage(value,proto.sp.scan_service.PropertySet.deserializeBinaryFromReader);
      msg.addPropertySets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetPropertySetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetPropertySetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetPropertySetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertySetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.PropertySet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetPropertySetsResponse} returns this
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetPropertySetsResponse} returns this
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PropertySet property_sets = 2;
 * @return {!Array<!proto.sp.scan_service.PropertySet>}
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.getPropertySetsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.PropertySet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.PropertySet, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.PropertySet>} value
 * @return {!proto.sp.scan_service.GetPropertySetsResponse} returns this
*/
proto.sp.scan_service.GetPropertySetsResponse.prototype.setPropertySetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.PropertySet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.PropertySet}
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.addPropertySets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.PropertySet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetPropertySetsResponse} returns this
 */
proto.sp.scan_service.GetPropertySetsResponse.prototype.clearPropertySetsList = function() {
  return this.setPropertySetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetRegionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetRegionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetRegionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRegionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: (f = msg.getRegion()) && proto.sp.scan_service.Region.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetRegionResponse}
 */
proto.sp.scan_service.GetRegionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetRegionResponse;
  return proto.sp.scan_service.GetRegionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetRegionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetRegionResponse}
 */
proto.sp.scan_service.GetRegionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.Region;
      reader.readMessage(value,proto.sp.scan_service.Region.deserializeBinaryFromReader);
      msg.setRegion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetRegionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetRegionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetRegionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRegionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.Region.serializeBinaryToWriter
    );
  }
};


/**
 * optional Region region = 1;
 * @return {?proto.sp.scan_service.Region}
 */
proto.sp.scan_service.GetRegionResponse.prototype.getRegion = function() {
  return /** @type{?proto.sp.scan_service.Region} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.Region, 1));
};


/**
 * @param {?proto.sp.scan_service.Region|undefined} value
 * @return {!proto.sp.scan_service.GetRegionResponse} returns this
*/
proto.sp.scan_service.GetRegionResponse.prototype.setRegion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetRegionResponse} returns this
 */
proto.sp.scan_service.GetRegionResponse.prototype.clearRegion = function() {
  return this.setRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetRegionResponse.prototype.hasRegion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetRegionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetRegionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetRegionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRegionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetRegionsRequest}
 */
proto.sp.scan_service.GetRegionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetRegionsRequest;
  return proto.sp.scan_service.GetRegionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetRegionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetRegionsRequest}
 */
proto.sp.scan_service.GetRegionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetRegionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetRegionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetRegionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRegionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetRegionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetRegionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetRegionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetRegionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRegionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    regionsList: jspb.Message.toObjectList(msg.getRegionsList(),
    proto.sp.scan_service.Region.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetRegionsResponse}
 */
proto.sp.scan_service.GetRegionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetRegionsResponse;
  return proto.sp.scan_service.GetRegionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetRegionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetRegionsResponse}
 */
proto.sp.scan_service.GetRegionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.Region;
      reader.readMessage(value,proto.sp.scan_service.Region.deserializeBinaryFromReader);
      msg.addRegions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetRegionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetRegionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetRegionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetRegionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sp.scan_service.Region.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Region regions = 1;
 * @return {!Array<!proto.sp.scan_service.Region>}
 */
proto.sp.scan_service.GetRegionsResponse.prototype.getRegionsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.Region>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.Region, 1));
};


/**
 * @param {!Array<!proto.sp.scan_service.Region>} value
 * @return {!proto.sp.scan_service.GetRegionsResponse} returns this
*/
proto.sp.scan_service.GetRegionsResponse.prototype.setRegionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sp.scan_service.Region=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.Region}
 */
proto.sp.scan_service.GetRegionsResponse.prototype.addRegions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sp.scan_service.Region, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetRegionsResponse} returns this
 */
proto.sp.scan_service.GetRegionsResponse.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRuleByIdRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleByIdRequest}
 */
proto.sp.scan_service.GetScanRuleByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleByIdRequest;
  return proto.sp.scan_service.GetScanRuleByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleByIdRequest}
 */
proto.sp.scan_service.GetScanRuleByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanRuleByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ScanRule.ScanRuleField fields = 2;
 * @return {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>}
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>} value
 * @return {!proto.sp.scan_service.GetScanRuleByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRule.ScanRuleField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRule: (f = msg.getScanRule()) && proto.sp.scan_service.ScanRule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleByIdResponse}
 */
proto.sp.scan_service.GetScanRuleByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleByIdResponse;
  return proto.sp.scan_service.GetScanRuleByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleByIdResponse}
 */
proto.sp.scan_service.GetScanRuleByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanRule;
      reader.readMessage(value,proto.sp.scan_service.ScanRule.deserializeBinaryFromReader);
      msg.setScanRule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRule();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ScanRule.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanRule scan_rule = 1;
 * @return {?proto.sp.scan_service.ScanRule}
 */
proto.sp.scan_service.GetScanRuleByIdResponse.prototype.getScanRule = function() {
  return /** @type{?proto.sp.scan_service.ScanRule} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanRule, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanRule|undefined} value
 * @return {!proto.sp.scan_service.GetScanRuleByIdResponse} returns this
*/
proto.sp.scan_service.GetScanRuleByIdResponse.prototype.setScanRule = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleByIdResponse} returns this
 */
proto.sp.scan_service.GetScanRuleByIdResponse.prototype.clearScanRule = function() {
  return this.setScanRule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleByIdResponse.prototype.hasScanRule = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRulesRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRulesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRulesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetScanRulesRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetScanRulesRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRulesRequest}
 */
proto.sp.scan_service.GetScanRulesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRulesRequest;
  return proto.sp.scan_service.GetScanRulesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRulesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRulesRequest}
 */
proto.sp.scan_service.GetScanRulesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetScanRulesRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetScanRulesRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetScanRulesRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetScanRulesRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRulesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRulesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetScanRulesRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetScanRulesRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanRulesRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_ID: 1,
  SORT_FIELD_CREATED_TIME: 2,
  SORT_FIELD_UPDATED_TIME: 3,
  SORT_FIELD_SHORT_NAME: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRulesRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRulesRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.SortParameter}
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRulesRequest.SortParameter;
  return proto.sp.scan_service.GetScanRulesRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRulesRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.SortParameter}
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetScanRulesRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRulesRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRulesRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetScanRulesRequest.SortField}
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetScanRulesRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetScanRulesRequest.SortField} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.repeatedFields_ = [1,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRulesRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRulesRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    shortNameFilterList: jspb.Message.toObjectList(msg.getShortNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    regionIdFilterList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    productionStatusFilterList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    categoryFilterList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRulesRequest.Filters;
  return proto.sp.scan_service.GetScanRulesRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRulesRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 4:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 5:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addShortNameFilter(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionIdFilter(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRule.ProductionStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProductionStatusFilter(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRule.Category>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryFilter(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRulesRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRulesRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getShortNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getRegionIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getProductionStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getCategoryFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 4;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 4));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated sp.service_common.StringFilter short_name_filter = 5;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getShortNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 5));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setShortNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.addShortNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearShortNameFilterList = function() {
  return this.setShortNameFilterList([]);
};


/**
 * repeated string region_id_filter = 6;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getRegionIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setRegionIdFilterList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.addRegionIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearRegionIdFilterList = function() {
  return this.setRegionIdFilterList([]);
};


/**
 * repeated ScanRule.ProductionStatus production_status_filter = 7;
 * @return {!Array<!proto.sp.scan_service.ScanRule.ProductionStatus>}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getProductionStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRule.ProductionStatus>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRule.ProductionStatus>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setProductionStatusFilterList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRule.ProductionStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.addProductionStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearProductionStatusFilterList = function() {
  return this.setProductionStatusFilterList([]);
};


/**
 * repeated ScanRule.Category category_filter = 8;
 * @return {!Array<!proto.sp.scan_service.ScanRule.Category>}
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.getCategoryFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRule.Category>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRule.Category>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.setCategoryFilterList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRule.Category} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.addCategoryFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.Filters.prototype.clearCategoryFilterList = function() {
  return this.setCategoryFilterList([]);
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
*/
proto.sp.scan_service.GetScanRulesRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetScanRulesRequest.Filters}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetScanRulesRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetScanRulesRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetScanRulesRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
*/
proto.sp.scan_service.GetScanRulesRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetScanRulesRequest.SortParameter>}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetScanRulesRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetScanRulesRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanRulesRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
*/
proto.sp.scan_service.GetScanRulesRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetScanRulesRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRulesRequest.SortParameter}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetScanRulesRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated ScanRule.ScanRuleField fields = 4;
 * @return {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>}
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRule.ScanRuleField>} value
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRule.ScanRuleField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesRequest} returns this
 */
proto.sp.scan_service.GetScanRulesRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRulesResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRulesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRulesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanRulesList: jspb.Message.toObjectList(msg.getScanRulesList(),
    proto.sp.scan_service.ScanRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRulesResponse}
 */
proto.sp.scan_service.GetScanRulesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRulesResponse;
  return proto.sp.scan_service.GetScanRulesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRulesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRulesResponse}
 */
proto.sp.scan_service.GetScanRulesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanRule;
      reader.readMessage(value,proto.sp.scan_service.ScanRule.deserializeBinaryFromReader);
      msg.addScanRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRulesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRulesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRulesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanRule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanRulesResponse} returns this
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRulesResponse} returns this
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScanRule scan_rules = 2;
 * @return {!Array<!proto.sp.scan_service.ScanRule>}
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.getScanRulesList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanRule, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRule>} value
 * @return {!proto.sp.scan_service.GetScanRulesResponse} returns this
*/
proto.sp.scan_service.GetScanRulesResponse.prototype.setScanRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRule}
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.addScanRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRulesResponse} returns this
 */
proto.sp.scan_service.GetScanRulesResponse.prototype.clearScanRulesList = function() {
  return this.setScanRulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ScanRule.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanRule.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    category: jspb.Message.getFieldWithDefault(msg, 6, 0),
    productionStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    complianceStandardsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    regionsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanRule}
 */
proto.sp.scan_service.ScanRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanRule;
  return proto.sp.scan_service.ScanRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanRule}
 */
proto.sp.scan_service.ScanRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {!proto.sp.scan_service.ScanRule.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 7:
      var value = /** @type {!proto.sp.scan_service.ScanRule.ProductionStatus} */ (reader.readEnum());
      msg.setProductionStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceStandards(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProductionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getComplianceStandardsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getRegionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.ScanRule.Category = {
  CATEGORY_UNSET: 0,
  CATEGORY_OPT_OUT: 1,
  CATEGORY_PRIVACY_POLICY: 2,
  CATEGORY_CONSENT_ACTION: 3,
  CATEGORY_CONSENT_NOTICE: 4,
  CATEGORY_INFORMATION_STORAGE_AND_ACCESS: 5,
  CATEGORY_DATA_SUBJECT_REQUEST: 6,
  CATEGORY_IAB_TRANSPARENCY_AND_CONSENT_FRAMEWORK: 7,
  CATEGORY_IAB_US_PRIVACY_FRAMEWORK: 8
};

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanRule.ProductionStatus = {
  PRODUCTION_STATUS_UNSET: 0,
  PRODUCTION_STATUS_ALPHA: 1,
  PRODUCTION_STATUS_BETA: 2,
  PRODUCTION_STATUS_GA: 3
};

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanRule.ScanRuleField = {
  SCAN_RULE_FIELD_UNSET: 0,
  SCAN_RULE_FIELD_REGIONS: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanRule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.ScanRule.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
*/
proto.sp.scan_service.ScanRule.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanRule.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.sp.scan_service.ScanRule.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.sp.scan_service.ScanRule.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.sp.scan_service.ScanRule.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanRule.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Category category = 6;
 * @return {!proto.sp.scan_service.ScanRule.Category}
 */
proto.sp.scan_service.ScanRule.prototype.getCategory = function() {
  return /** @type {!proto.sp.scan_service.ScanRule.Category} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanRule.Category} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ProductionStatus production_status = 7;
 * @return {!proto.sp.scan_service.ScanRule.ProductionStatus}
 */
proto.sp.scan_service.ScanRule.prototype.getProductionStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanRule.ProductionStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanRule.ProductionStatus} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setProductionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated string compliance_standards = 8;
 * @return {!Array<string>}
 */
proto.sp.scan_service.ScanRule.prototype.getComplianceStandardsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setComplianceStandardsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.addComplianceStandards = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.clearComplianceStandardsList = function() {
  return this.setComplianceStandardsList([]);
};


/**
 * repeated string regions = 9;
 * @return {!Array<string>}
 */
proto.sp.scan_service.ScanRule.prototype.getRegionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.setRegionsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.addRegions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanRule} returns this
 */
proto.sp.scan_service.ScanRule.prototype.clearRegionsList = function() {
  return this.setRegionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.CreateScanRuleSetRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreateScanRuleSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreateScanRuleSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateScanRuleSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    published: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    scanRuleSetItemsList: jspb.Message.toObjectList(msg.getScanRuleSetItemsList(),
    proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreateScanRuleSetRequest;
  return proto.sp.scan_service.CreateScanRuleSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreateScanRuleSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublished(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 5:
      var value = new proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem;
      reader.readMessage(value,proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.deserializeBinaryFromReader);
      msg.addScanRuleSetItems(value);
      break;
    case 7:
      var value = /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreateScanRuleSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreateScanRuleSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateScanRuleSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublished();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getScanRuleSetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool published = 3;
 * @return {boolean}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.getPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.setPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 account_id = 4;
 * @return {number}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.clearAccountId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ScanRuleSet.ScanRuleSetItem scan_rule_set_items = 5;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.getScanRuleSetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, 5));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
*/
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.setScanRuleSetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.addScanRuleSetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.clearScanRuleSetItemsList = function() {
  return this.setScanRuleSetItemsList([]);
};


/**
 * optional ScanRuleSet.ScanRuleSetStatus status = 7;
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.CreateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.CreateScanRuleSetRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.CreateScanRuleSetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.CreateScanRuleSetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.CreateScanRuleSetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateScanRuleSetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.CreateScanRuleSetResponse}
 */
proto.sp.scan_service.CreateScanRuleSetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.CreateScanRuleSetResponse;
  return proto.sp.scan_service.CreateScanRuleSetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.CreateScanRuleSetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.CreateScanRuleSetResponse}
 */
proto.sp.scan_service.CreateScanRuleSetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.CreateScanRuleSetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.CreateScanRuleSetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.CreateScanRuleSetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.CreateScanRuleSetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.CreateScanRuleSetResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.CreateScanRuleSetResponse} returns this
 */
proto.sp.scan_service.CreateScanRuleSetResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateScanRuleSetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateScanRuleSetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    published: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    scanRuleSetItemsList: jspb.Message.toObjectList(msg.getScanRuleSetItemsList(),
    proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateScanRuleSetRequest;
  return proto.sp.scan_service.UpdateScanRuleSetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateScanRuleSetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublished(value);
      break;
    case 5:
      var value = new proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem;
      reader.readMessage(value,proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.deserializeBinaryFromReader);
      msg.addScanRuleSetItems(value);
      break;
    case 6:
      var value = /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateScanRuleSetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateScanRuleSetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getScanRuleSetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool published = 4;
 * @return {boolean}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.getPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.setPublished = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.clearPublished = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.hasPublished = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ScanRuleSet.ScanRuleSetItem scan_rule_set_items = 5;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.getScanRuleSetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, 5));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
*/
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.setScanRuleSetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.addScanRuleSetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.clearScanRuleSetItemsList = function() {
  return this.setScanRuleSetItemsList([]);
};


/**
 * optional ScanRuleSet.ScanRuleSetStatus status = 6;
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetRequest} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.UpdateScanRuleSetRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.UpdateScanRuleSetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.UpdateScanRuleSetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetResponse}
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.UpdateScanRuleSetResponse;
  return proto.sp.scan_service.UpdateScanRuleSetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.UpdateScanRuleSetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.UpdateScanRuleSetResponse}
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordsUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.UpdateScanRuleSetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.UpdateScanRuleSetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsUpdated();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 records_updated = 1;
 * @return {number}
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.prototype.getRecordsUpdated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.UpdateScanRuleSetResponse} returns this
 */
proto.sp.scan_service.UpdateScanRuleSetResponse.prototype.setRecordsUpdated = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleSetByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdRequest}
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleSetByIdRequest;
  return proto.sp.scan_service.GetScanRuleSetByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdRequest}
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleSetByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ScanRuleSet.ScanRuleSetField fields = 2;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>}
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetByIdRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleSetByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleSet: (f = msg.getScanRuleSet()) && proto.sp.scan_service.ScanRuleSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdResponse}
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleSetByIdResponse;
  return proto.sp.scan_service.GetScanRuleSetByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdResponse}
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sp.scan_service.ScanRuleSet;
      reader.readMessage(value,proto.sp.scan_service.ScanRuleSet.deserializeBinaryFromReader);
      msg.setScanRuleSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleSetByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleSetByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleSet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sp.scan_service.ScanRuleSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScanRuleSet scan_rule_set = 1;
 * @return {?proto.sp.scan_service.ScanRuleSet}
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.prototype.getScanRuleSet = function() {
  return /** @type{?proto.sp.scan_service.ScanRuleSet} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.ScanRuleSet, 1));
};


/**
 * @param {?proto.sp.scan_service.ScanRuleSet|undefined} value
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdResponse} returns this
*/
proto.sp.scan_service.GetScanRuleSetByIdResponse.prototype.setScanRuleSet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetByIdResponse} returns this
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.prototype.clearScanRuleSet = function() {
  return this.setScanRuleSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetByIdResponse.prototype.hasScanRuleSet = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRuleSetsRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleSetsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagingParameters: (f = msg.getPagingParameters()) && sp_service_common_common_pb.PagingParameters.toObject(includeInstance, f),
    filters: (f = msg.getFilters()) && proto.sp.scan_service.GetScanRuleSetsRequest.Filters.toObject(includeInstance, f),
    sortParametersList: jspb.Message.toObjectList(msg.getSortParametersList(),
    proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.toObject, includeInstance),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleSetsRequest;
  return proto.sp.scan_service.GetScanRuleSetsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sp_service_common_common_pb.PagingParameters;
      reader.readMessage(value,sp_service_common_common_pb.PagingParameters.deserializeBinaryFromReader);
      msg.setPagingParameters(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.GetScanRuleSetsRequest.Filters;
      reader.readMessage(value,proto.sp.scan_service.GetScanRuleSetsRequest.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 3:
      var value = new proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter;
      reader.readMessage(value,proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.deserializeBinaryFromReader);
      msg.addSortParameters(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleSetsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagingParameters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sp_service_common_common_pb.PagingParameters.serializeBinaryToWriter
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.sp.scan_service.GetScanRuleSetsRequest.Filters.serializeBinaryToWriter
    );
  }
  f = message.getSortParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortField = {
  SORT_FIELD_UNSET: 0,
  SORT_FIELD_ID: 1,
  SORT_FIELD_CREATED_TIME: 2,
  SORT_FIELD_UPDATED_TIME: 3,
  SORT_FIELD_STATUS: 4,
  SORT_FIELD_NAME: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    direction: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter;
  return proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.sp.scan_service.GetScanRuleSetsRequest.SortField} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.service_common.SortDirection} */ (reader.readEnum());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDirection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional SortField field = 1;
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.SortField}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.prototype.getField = function() {
  return /** @type {!proto.sp.scan_service.GetScanRuleSetsRequest.SortField} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.SortField} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional sp.service_common.SortDirection direction = 2;
 * @return {!proto.sp.service_common.SortDirection}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.prototype.getDirection = function() {
  return /** @type {!proto.sp.service_common.SortDirection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.service_common.SortDirection} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter.prototype.setDirection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.repeatedFields_ = [1,2,5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleSetsRequest.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    idFilterList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    statusFilterList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    creationTimeFilter: (f = msg.getCreationTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    updateTimeFilter: (f = msg.getUpdateTimeFilter()) && sp_service_common_common_pb.TimestampFilter.toObject(includeInstance, f),
    nameFilterList: jspb.Message.toObjectList(msg.getNameFilterList(),
    sp_service_common_common_pb.StringFilter.toObject, includeInstance),
    accountIdFilterList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    systemSetFilter: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleSetsRequest.Filters;
  return proto.sp.scan_service.GetScanRuleSetsRequest.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusFilter(values[i]);
      }
      break;
    case 3:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setCreationTimeFilter(value);
      break;
    case 4:
      var value = new sp_service_common_common_pb.TimestampFilter;
      reader.readMessage(value,sp_service_common_common_pb.TimestampFilter.deserializeBinaryFromReader);
      msg.setUpdateTimeFilter(value);
      break;
    case 5:
      var value = new sp_service_common_common_pb.StringFilter;
      reader.readMessage(value,sp_service_common_common_pb.StringFilter.deserializeBinaryFromReader);
      msg.addNameFilter(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountIdFilter(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSystemSetFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleSetsRequest.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdFilterList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getCreationTimeFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      sp_service_common_common_pb.TimestampFilter.serializeBinaryToWriter
    );
  }
  f = message.getNameFilterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      sp_service_common_common_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getAccountIdFilterList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated string id_filter = 1;
 * @return {!Array<string>}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getIdFilterList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setIdFilterList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.addIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearIdFilterList = function() {
  return this.setIdFilterList([]);
};


/**
 * repeated ScanRuleSet.ScanRuleSetStatus status_filter = 2;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus>}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getStatusFilterList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setStatusFilterList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.addStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearStatusFilterList = function() {
  return this.setStatusFilterList([]);
};


/**
 * optional sp.service_common.TimestampFilter creation_time_filter = 3;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getCreationTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 3));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setCreationTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearCreationTimeFilter = function() {
  return this.setCreationTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.hasCreationTimeFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sp.service_common.TimestampFilter update_time_filter = 4;
 * @return {?proto.sp.service_common.TimestampFilter}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getUpdateTimeFilter = function() {
  return /** @type{?proto.sp.service_common.TimestampFilter} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.TimestampFilter, 4));
};


/**
 * @param {?proto.sp.service_common.TimestampFilter|undefined} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setUpdateTimeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearUpdateTimeFilter = function() {
  return this.setUpdateTimeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.hasUpdateTimeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated sp.service_common.StringFilter name_filter = 5;
 * @return {!Array<!proto.sp.service_common.StringFilter>}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getNameFilterList = function() {
  return /** @type{!Array<!proto.sp.service_common.StringFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, sp_service_common_common_pb.StringFilter, 5));
};


/**
 * @param {!Array<!proto.sp.service_common.StringFilter>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
*/
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setNameFilterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.sp.service_common.StringFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.service_common.StringFilter}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.addNameFilter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sp.service_common.StringFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearNameFilterList = function() {
  return this.setNameFilterList([]);
};


/**
 * repeated int32 account_id_filter = 7;
 * @return {!Array<number>}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getAccountIdFilterList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setAccountIdFilterList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.addAccountIdFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearAccountIdFilterList = function() {
  return this.setAccountIdFilterList([]);
};


/**
 * optional bool system_set_filter = 8;
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.getSystemSetFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.setSystemSetFilter = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.Filters} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.clearSystemSetFilter = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.Filters.prototype.hasSystemSetFilter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional sp.service_common.PagingParameters paging_parameters = 1;
 * @return {?proto.sp.service_common.PagingParameters}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.getPagingParameters = function() {
  return /** @type{?proto.sp.service_common.PagingParameters} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.PagingParameters, 1));
};


/**
 * @param {?proto.sp.service_common.PagingParameters|undefined} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
*/
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.setPagingParameters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.clearPagingParameters = function() {
  return this.setPagingParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.hasPagingParameters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filters filters = 2;
 * @return {?proto.sp.scan_service.GetScanRuleSetsRequest.Filters}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.getFilters = function() {
  return /** @type{?proto.sp.scan_service.GetScanRuleSetsRequest.Filters} */ (
    jspb.Message.getWrapperField(this, proto.sp.scan_service.GetScanRuleSetsRequest.Filters, 2));
};


/**
 * @param {?proto.sp.scan_service.GetScanRuleSetsRequest.Filters|undefined} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
*/
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortParameter sort_parameters = 3;
 * @return {!Array<!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter>}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.getSortParametersList = function() {
  return /** @type{!Array<!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter, 3));
};


/**
 * @param {!Array<!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
*/
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.setSortParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.addSortParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sp.scan_service.GetScanRuleSetsRequest.SortParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.clearSortParametersList = function() {
  return this.setSortParametersList([]);
};


/**
 * repeated ScanRuleSet.ScanRuleSetField fields = 4;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>}
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetField} value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsRequest} returns this
 */
proto.sp.scan_service.GetScanRuleSetsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.GetScanRuleSetsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.GetScanRuleSetsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.GetScanRuleSetsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    continuationToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scanRuleSetsList: jspb.Message.toObjectList(msg.getScanRuleSetsList(),
    proto.sp.scan_service.ScanRuleSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.GetScanRuleSetsResponse}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.GetScanRuleSetsResponse;
  return proto.sp.scan_service.GetScanRuleSetsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.GetScanRuleSetsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.GetScanRuleSetsResponse}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContinuationToken(value);
      break;
    case 2:
      var value = new proto.sp.scan_service.ScanRuleSet;
      reader.readMessage(value,proto.sp.scan_service.ScanRuleSet.deserializeBinaryFromReader);
      msg.addScanRuleSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.GetScanRuleSetsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.GetScanRuleSetsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.GetScanRuleSetsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScanRuleSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sp.scan_service.ScanRuleSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string continuation_token = 1;
 * @return {string}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.getContinuationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsResponse} returns this
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.setContinuationToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.GetScanRuleSetsResponse} returns this
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.clearContinuationToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.hasContinuationToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScanRuleSet scan_rule_sets = 2;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet>}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.getScanRuleSetsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanRuleSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanRuleSet, 2));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet>} value
 * @return {!proto.sp.scan_service.GetScanRuleSetsResponse} returns this
*/
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.setScanRuleSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRuleSet}
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.addScanRuleSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sp.scan_service.ScanRuleSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.GetScanRuleSetsResponse} returns this
 */
proto.sp.scan_service.GetScanRuleSetsResponse.prototype.clearScanRuleSetsList = function() {
  return this.setScanRuleSetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sp.scan_service.ScanRuleSet.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanRuleSet.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanRuleSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanRuleSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanRuleSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditData: (f = msg.getAuditData()) && sp_service_common_common_pb.AuditData.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    published: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    accountId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    scanRuleSetItemsList: jspb.Message.toObjectList(msg.getScanRuleSetItemsList(),
    proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanRuleSet}
 */
proto.sp.scan_service.ScanRuleSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanRuleSet;
  return proto.sp.scan_service.ScanRuleSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanRuleSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanRuleSet}
 */
proto.sp.scan_service.ScanRuleSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new sp_service_common_common_pb.AuditData;
      reader.readMessage(value,sp_service_common_common_pb.AuditData.deserializeBinaryFromReader);
      msg.setAuditData(value);
      break;
    case 3:
      var value = /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublished(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = new proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem;
      reader.readMessage(value,proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.deserializeBinaryFromReader);
      msg.addScanRuleSetItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanRuleSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanRuleSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanRuleSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanRuleSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sp_service_common_common_pb.AuditData.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPublished();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScanRuleSetItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus = {
  SCAN_RULE_SET_STATUS_UNSET: 0,
  SCAN_RULE_SET_STATUS_ACTIVE: 1,
  SCAN_RULE_SET_STATUS_DISABLED: 2,
  SCAN_RULE_SET_STATUS_DELETED: 3
};

/**
 * @enum {number}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetField = {
  SCAN_RULE_SET_FIELD_UNSET: 0,
  SCAN_RULE_SET_FIELD_SCAN_RULE_SET_ITEMS: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.toObject = function(opt_includeInstance) {
  return proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    scanRuleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemPreference: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem;
  return proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanRuleId(value);
      break;
    case 2:
      var value = /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference} */ (reader.readEnum());
      msg.setItemPreference(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanRuleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemPreference();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference = {
  SCAN_RULE_SET_ITEM_PREFERENCE_UNSET: 0,
  SCAN_RULE_SET_ITEM_PREFERENCE_MUST_HAVE: 1,
  SCAN_RULE_SET_ITEM_PREFERENCE_NICE_TO_HAVE: 2,
  SCAN_RULE_SET_ITEM_PREFERENCE_NOT_REQUIRED: 3
};

/**
 * optional string scan_rule_id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.getScanRuleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem} returns this
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.setScanRuleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ScanRuleSetItemPreference item_preference = 2;
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.getItemPreference = function() {
  return /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.ScanRuleSetItemPreference} value
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem} returns this
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.setItemPreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 weight = 4;
 * @return {number}
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem} returns this
 */
proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sp.service_common.AuditData audit_data = 2;
 * @return {?proto.sp.service_common.AuditData}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getAuditData = function() {
  return /** @type{?proto.sp.service_common.AuditData} */ (
    jspb.Message.getWrapperField(this, sp_service_common_common_pb.AuditData, 2));
};


/**
 * @param {?proto.sp.service_common.AuditData|undefined} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
*/
proto.sp.scan_service.ScanRuleSet.prototype.setAuditData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.clearAuditData = function() {
  return this.setAuditData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanRuleSet.prototype.hasAuditData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ScanRuleSetStatus status = 3;
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getStatus = function() {
  return /** @type {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetStatus} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool published = 5;
 * @return {boolean}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.setPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 account_id = 6;
 * @return {number}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.setAccountId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.clearAccountId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanRuleSet.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sp.scan_service.ScanRuleSet.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated ScanRuleSetItem scan_rule_set_items = 8;
 * @return {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>}
 */
proto.sp.scan_service.ScanRuleSet.prototype.getScanRuleSetItemsList = function() {
  return /** @type{!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, 8));
};


/**
 * @param {!Array<!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem>} value
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
*/
proto.sp.scan_service.ScanRuleSet.prototype.setScanRuleSetItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem}
 */
proto.sp.scan_service.ScanRuleSet.prototype.addScanRuleSetItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.sp.scan_service.ScanRuleSet.ScanRuleSetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sp.scan_service.ScanRuleSet} returns this
 */
proto.sp.scan_service.ScanRuleSet.prototype.clearScanRuleSetItemsList = function() {
  return this.setScanRuleSetItemsList([]);
};


goog.object.extend(exports, proto.sp.scan_service);
