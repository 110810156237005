/**
 * @fileoverview gRPC-Web generated client stub for sp.scan_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var sp_service_common_common_pb = require('../../sp/service_common/common_pb.js')

var sp_scan_runner_common_pb = require('../../sp/scan_runner/common_pb.js')

var sp_scan_runner_scan_runner_pb = require('../../sp/scan_runner/scan_runner_pb.js')

var sp_scan_service_scan_service_pb = require('../../sp/scan_service/scan_service_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')
const proto = {};
proto.sp = {};
proto.sp.scan_service = require('./scan_service_internal_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.scan_service.ScanServiceInternalClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.FindAndLaunchScanJobsRequest,
 *   !proto.sp.scan_service.FindAndLaunchScanJobsResponse>}
 */
const methodDescriptor_ScanServiceInternal_FindAndLaunchScanJobs = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/FindAndLaunchScanJobs',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.FindAndLaunchScanJobsRequest,
  proto.sp.scan_service.FindAndLaunchScanJobsResponse,
  /**
   * @param {!proto.sp.scan_service.FindAndLaunchScanJobsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.FindAndLaunchScanJobsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.FindAndLaunchScanJobsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.FindAndLaunchScanJobsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.findAndLaunchScanJobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndLaunchScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndLaunchScanJobs,
      callback);
};


/**
 * @param {!proto.sp.scan_service.FindAndLaunchScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.FindAndLaunchScanJobsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.findAndLaunchScanJobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndLaunchScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndLaunchScanJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.LaunchScanJobRequest,
 *   !proto.sp.scan_service.LaunchScanJobResponse>}
 */
const methodDescriptor_ScanServiceInternal_LaunchScanJob = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/LaunchScanJob',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.LaunchScanJobRequest,
  proto.sp.scan_service.LaunchScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.LaunchScanJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.LaunchScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.LaunchScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.LaunchScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.LaunchScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.launchScanJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/LaunchScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_LaunchScanJob,
      callback);
};


/**
 * @param {!proto.sp.scan_service.LaunchScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.LaunchScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.launchScanJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/LaunchScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_LaunchScanJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.KillScanJobRequest,
 *   !proto.sp.scan_service.KillScanJobResponse>}
 */
const methodDescriptor_ScanServiceInternal_KillScanJob = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/KillScanJob',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.KillScanJobRequest,
  proto.sp.scan_service.KillScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.KillScanJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.KillScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.KillScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.KillScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.KillScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.killScanJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/KillScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_KillScanJob,
      callback);
};


/**
 * @param {!proto.sp.scan_service.KillScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.KillScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.killScanJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/KillScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_KillScanJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ProcessScanJobRequest,
 *   !proto.sp.scan_service.ProcessScanJobResponse>}
 */
const methodDescriptor_ScanServiceInternal_ProcessScanJob = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ProcessScanJob',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ProcessScanJobRequest,
  proto.sp.scan_service.ProcessScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.ProcessScanJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ProcessScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ProcessScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ProcessScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ProcessScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.processScanJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessScanJob,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ProcessScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ProcessScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.processScanJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessScanJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.FindAndProcessScanJobsRequest,
 *   !proto.sp.scan_service.FindAndProcessScanJobsResponse>}
 */
const methodDescriptor_ScanServiceInternal_FindAndProcessScanJobs = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/FindAndProcessScanJobs',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.FindAndProcessScanJobsRequest,
  proto.sp.scan_service.FindAndProcessScanJobsResponse,
  /**
   * @param {!proto.sp.scan_service.FindAndProcessScanJobsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.FindAndProcessScanJobsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.FindAndProcessScanJobsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.FindAndProcessScanJobsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.findAndProcessScanJobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessScanJobs,
      callback);
};


/**
 * @param {!proto.sp.scan_service.FindAndProcessScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.FindAndProcessScanJobsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.findAndProcessScanJobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessScanJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ProcessScanArtifactsRequest,
 *   !proto.sp.scan_service.ProcessScanArtifactsResponse>}
 */
const methodDescriptor_ScanServiceInternal_ProcessScanArtifacts = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ProcessScanArtifacts',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ProcessScanArtifactsRequest,
  proto.sp.scan_service.ProcessScanArtifactsResponse,
  /**
   * @param {!proto.sp.scan_service.ProcessScanArtifactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ProcessScanArtifactsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ProcessScanArtifactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ProcessScanArtifactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ProcessScanArtifactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.processScanArtifacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessScanArtifacts',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessScanArtifacts,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ProcessScanArtifactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ProcessScanArtifactsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.processScanArtifacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessScanArtifacts',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessScanArtifacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.FindAndProcessScanArtifactsRequest,
 *   !proto.sp.scan_service.FindAndProcessScanArtifactsResponse>}
 */
const methodDescriptor_ScanServiceInternal_FindAndProcessScanArtifacts = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/FindAndProcessScanArtifacts',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.FindAndProcessScanArtifactsRequest,
  proto.sp.scan_service.FindAndProcessScanArtifactsResponse,
  /**
   * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.FindAndProcessScanArtifactsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.FindAndProcessScanArtifactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.FindAndProcessScanArtifactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.findAndProcessScanArtifacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessScanArtifacts',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessScanArtifacts,
      callback);
};


/**
 * @param {!proto.sp.scan_service.FindAndProcessScanArtifactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.FindAndProcessScanArtifactsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.findAndProcessScanArtifacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessScanArtifacts',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessScanArtifacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.TerminateOvertimeScanJobsRequest,
 *   !proto.sp.scan_service.TerminateOvertimeScanJobsResponse>}
 */
const methodDescriptor_ScanServiceInternal_TerminateOvertimeScanJobs = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/TerminateOvertimeScanJobs',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.TerminateOvertimeScanJobsRequest,
  proto.sp.scan_service.TerminateOvertimeScanJobsResponse,
  /**
   * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.TerminateOvertimeScanJobsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.TerminateOvertimeScanJobsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.TerminateOvertimeScanJobsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.terminateOvertimeScanJobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/TerminateOvertimeScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_TerminateOvertimeScanJobs,
      callback);
};


/**
 * @param {!proto.sp.scan_service.TerminateOvertimeScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.TerminateOvertimeScanJobsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.terminateOvertimeScanJobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/TerminateOvertimeScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_TerminateOvertimeScanJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ProcessPropertyRequest,
 *   !proto.sp.scan_service.ProcessPropertyResponse>}
 */
const methodDescriptor_ScanServiceInternal_ProcessProperty = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ProcessProperty',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ProcessPropertyRequest,
  proto.sp.scan_service.ProcessPropertyResponse,
  /**
   * @param {!proto.sp.scan_service.ProcessPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ProcessPropertyResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ProcessPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ProcessPropertyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ProcessPropertyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.processProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessProperty',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessProperty,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ProcessPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ProcessPropertyResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.processProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessProperty',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.FindAndProcessPropertiesRequest,
 *   !proto.sp.scan_service.FindAndProcessPropertiesResponse>}
 */
const methodDescriptor_ScanServiceInternal_FindAndProcessProperties = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/FindAndProcessProperties',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.FindAndProcessPropertiesRequest,
  proto.sp.scan_service.FindAndProcessPropertiesResponse,
  /**
   * @param {!proto.sp.scan_service.FindAndProcessPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.FindAndProcessPropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.FindAndProcessPropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.FindAndProcessPropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.findAndProcessProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessProperties,
      callback);
};


/**
 * @param {!proto.sp.scan_service.FindAndProcessPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.FindAndProcessPropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.findAndProcessProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ProcessStalePropertyRequest,
 *   !proto.sp.scan_service.ProcessStalePropertyResponse>}
 */
const methodDescriptor_ScanServiceInternal_ProcessStaleProperty = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ProcessStaleProperty',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ProcessStalePropertyRequest,
  proto.sp.scan_service.ProcessStalePropertyResponse,
  /**
   * @param {!proto.sp.scan_service.ProcessStalePropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ProcessStalePropertyResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ProcessStalePropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ProcessStalePropertyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ProcessStalePropertyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.processStaleProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessStaleProperty',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessStaleProperty,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ProcessStalePropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ProcessStalePropertyResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.processStaleProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessStaleProperty',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessStaleProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ScanServiceInternal_ProcessExpiredPropertySets = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ProcessExpiredPropertySets',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.processExpiredPropertySets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessExpiredPropertySets',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessExpiredPropertySets,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.processExpiredPropertySets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessExpiredPropertySets',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessExpiredPropertySets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ProcessPropertySetsStatsRequest,
 *   !proto.sp.scan_service.ProcessPropertySetsStatsResponse>}
 */
const methodDescriptor_ScanServiceInternal_ProcessPropertySetsStats = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ProcessPropertySetsStats',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ProcessPropertySetsStatsRequest,
  proto.sp.scan_service.ProcessPropertySetsStatsResponse,
  /**
   * @param {!proto.sp.scan_service.ProcessPropertySetsStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ProcessPropertySetsStatsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ProcessPropertySetsStatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ProcessPropertySetsStatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.processPropertySetsStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessPropertySetsStats',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessPropertySetsStats,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ProcessPropertySetsStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ProcessPropertySetsStatsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.processPropertySetsStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ProcessPropertySetsStats',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ProcessPropertySetsStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.FindAndProcessStalePropertiesRequest,
 *   !proto.sp.scan_service.FindAndProcessStalePropertiesResponse>}
 */
const methodDescriptor_ScanServiceInternal_FindAndProcessStaleProperties = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/FindAndProcessStaleProperties',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.FindAndProcessStalePropertiesRequest,
  proto.sp.scan_service.FindAndProcessStalePropertiesResponse,
  /**
   * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.FindAndProcessStalePropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.FindAndProcessStalePropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.FindAndProcessStalePropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.findAndProcessStaleProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessStaleProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessStaleProperties,
      callback);
};


/**
 * @param {!proto.sp.scan_service.FindAndProcessStalePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.FindAndProcessStalePropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.findAndProcessStaleProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/FindAndProcessStaleProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_FindAndProcessStaleProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ReprocessPropertiesRequest,
 *   !proto.sp.scan_service.ReprocessPropertiesResponse>}
 */
const methodDescriptor_ScanServiceInternal_ReprocessProperties = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ReprocessProperties',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ReprocessPropertiesRequest,
  proto.sp.scan_service.ReprocessPropertiesResponse,
  /**
   * @param {!proto.sp.scan_service.ReprocessPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ReprocessPropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ReprocessPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ReprocessPropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ReprocessPropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.reprocessProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ReprocessProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ReprocessProperties,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ReprocessPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ReprocessPropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.reprocessProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ReprocessProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ReprocessProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.StartDebugScanRequest,
 *   !proto.sp.scan_service.StartDebugScanResponse>}
 */
const methodDescriptor_ScanServiceInternal_StartDebugScan = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/StartDebugScan',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.StartDebugScanRequest,
  proto.sp.scan_service.StartDebugScanResponse,
  /**
   * @param {!proto.sp.scan_service.StartDebugScanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.StartDebugScanResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.StartDebugScanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.StartDebugScanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.StartDebugScanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.startDebugScan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/StartDebugScan',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_StartDebugScan,
      callback);
};


/**
 * @param {!proto.sp.scan_service.StartDebugScanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.StartDebugScanResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.startDebugScan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/StartDebugScan',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_StartDebugScan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ScanStalePropertiesRequest,
 *   !proto.sp.scan_service.ScanStalePropertiesResponse>}
 */
const methodDescriptor_ScanServiceInternal_ScanStaleProperties = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ScanStaleProperties',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ScanStalePropertiesRequest,
  proto.sp.scan_service.ScanStalePropertiesResponse,
  /**
   * @param {!proto.sp.scan_service.ScanStalePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ScanStalePropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ScanStalePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ScanStalePropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ScanStalePropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.scanStaleProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ScanStaleProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ScanStaleProperties,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ScanStalePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ScanStalePropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.scanStaleProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ScanStaleProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ScanStaleProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UploadCsvRequest,
 *   !proto.sp.scan_service.UploadCsvResponse>}
 */
const methodDescriptor_ScanServiceInternal_UploadCsv = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/UploadCsv',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UploadCsvRequest,
  proto.sp.scan_service.UploadCsvResponse,
  /**
   * @param {!proto.sp.scan_service.UploadCsvRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UploadCsvResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UploadCsvRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UploadCsvResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UploadCsvResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.uploadCsv =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UploadCsv',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UploadCsv,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UploadCsvRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UploadCsvResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.uploadCsv =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UploadCsv',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UploadCsv);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.RebuildPropertySnapshotsRequest,
 *   !proto.sp.scan_service.RebuildPropertySnapshotsResponse>}
 */
const methodDescriptor_ScanServiceInternal_RebuildPropertySnapshots = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshots',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.RebuildPropertySnapshotsRequest,
  proto.sp.scan_service.RebuildPropertySnapshotsResponse,
  /**
   * @param {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.RebuildPropertySnapshotsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.RebuildPropertySnapshotsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.RebuildPropertySnapshotsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.rebuildPropertySnapshots =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshots',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RebuildPropertySnapshots,
      callback);
};


/**
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.RebuildPropertySnapshotsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.rebuildPropertySnapshots =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshots',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RebuildPropertySnapshots);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.RebuildPropertySnapshotRequest,
 *   !proto.sp.scan_service.RebuildPropertySnapshotResponse>}
 */
const methodDescriptor_ScanServiceInternal_RebuildPropertySnapshot = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshot',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.RebuildPropertySnapshotRequest,
  proto.sp.scan_service.RebuildPropertySnapshotResponse,
  /**
   * @param {!proto.sp.scan_service.RebuildPropertySnapshotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.RebuildPropertySnapshotResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.RebuildPropertySnapshotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.RebuildPropertySnapshotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.rebuildPropertySnapshot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshot',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RebuildPropertySnapshot,
      callback);
};


/**
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.RebuildPropertySnapshotResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.rebuildPropertySnapshot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshot',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RebuildPropertySnapshot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.RebuildPropertySnapshotByIdRequest,
 *   !proto.sp.scan_service.RebuildPropertySnapshotResponse>}
 */
const methodDescriptor_ScanServiceInternal_RebuildPropertySnapshotById = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshotById',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.RebuildPropertySnapshotByIdRequest,
  proto.sp.scan_service.RebuildPropertySnapshotResponse,
  /**
   * @param {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.RebuildPropertySnapshotResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.RebuildPropertySnapshotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.RebuildPropertySnapshotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.rebuildPropertySnapshotById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshotById',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RebuildPropertySnapshotById,
      callback);
};


/**
 * @param {!proto.sp.scan_service.RebuildPropertySnapshotByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.RebuildPropertySnapshotResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.rebuildPropertySnapshotById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RebuildPropertySnapshotById',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RebuildPropertySnapshotById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ComputePropertySnapshotRequest,
 *   !proto.sp.scan_service.ComputePropertySnapshotResponse>}
 */
const methodDescriptor_ScanServiceInternal_ComputePropertySnapshot = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ComputePropertySnapshot',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ComputePropertySnapshotRequest,
  proto.sp.scan_service.ComputePropertySnapshotResponse,
  /**
   * @param {!proto.sp.scan_service.ComputePropertySnapshotRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ComputePropertySnapshotResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ComputePropertySnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ComputePropertySnapshotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ComputePropertySnapshotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.computePropertySnapshot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ComputePropertySnapshot',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ComputePropertySnapshot,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ComputePropertySnapshotRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ComputePropertySnapshotResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.computePropertySnapshot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ComputePropertySnapshot',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ComputePropertySnapshot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.StreamLogsRequest,
 *   !proto.sp.scan_service.StreamLogsResponse>}
 */
const methodDescriptor_ScanServiceInternal_StreamLogs = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/StreamLogs',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.sp.scan_service.StreamLogsRequest,
  proto.sp.scan_service.StreamLogsResponse,
  /**
   * @param {!proto.sp.scan_service.StreamLogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.StreamLogsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.StreamLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.StreamLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.streamLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/StreamLogs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_StreamLogs);
};


/**
 * @param {!proto.sp.scan_service.StreamLogsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.StreamLogsResponse>}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.streamLogs =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/StreamLogs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_StreamLogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UploadExtensionResultsRequest,
 *   !proto.sp.scan_service.UploadExtensionResultsResponse>}
 */
const methodDescriptor_ScanServiceInternal_UploadExtensionResults = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/UploadExtensionResults',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UploadExtensionResultsRequest,
  proto.sp.scan_service.UploadExtensionResultsResponse,
  /**
   * @param {!proto.sp.scan_service.UploadExtensionResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UploadExtensionResultsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UploadExtensionResultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UploadExtensionResultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.uploadExtensionResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UploadExtensionResults',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UploadExtensionResults,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UploadExtensionResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UploadExtensionResultsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.uploadExtensionResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UploadExtensionResults',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UploadExtensionResults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetStalePropertiesRequest,
 *   !proto.sp.scan_service.GetStalePropertiesResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetStaleProperties = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetStaleProperties',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetStalePropertiesRequest,
  proto.sp.scan_service.GetStalePropertiesResponse,
  /**
   * @param {!proto.sp.scan_service.GetStalePropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetStalePropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetStalePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetStalePropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetStalePropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getStaleProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetStaleProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetStaleProperties,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetStalePropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetStalePropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getStaleProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetStaleProperties',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetStaleProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UpsertDeploymentSettingsRequest,
 *   !proto.sp.scan_service.UpsertDeploymentSettingsResponse>}
 */
const methodDescriptor_ScanServiceInternal_UpsertDeploymentSettings = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/UpsertDeploymentSettings',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UpsertDeploymentSettingsRequest,
  proto.sp.scan_service.UpsertDeploymentSettingsResponse,
  /**
   * @param {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UpsertDeploymentSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UpsertDeploymentSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UpsertDeploymentSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.upsertDeploymentSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UpsertDeploymentSettings',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UpsertDeploymentSettings,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UpsertDeploymentSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UpsertDeploymentSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.upsertDeploymentSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UpsertDeploymentSettings',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UpsertDeploymentSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetDeploymentSettingsRequest,
 *   !proto.sp.scan_service.GetDeploymentSettingsResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetDeploymentSettings = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetDeploymentSettings',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetDeploymentSettingsRequest,
  proto.sp.scan_service.GetDeploymentSettingsResponse,
  /**
   * @param {!proto.sp.scan_service.GetDeploymentSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetDeploymentSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetDeploymentSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetDeploymentSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetDeploymentSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getDeploymentSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetDeploymentSettings',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetDeploymentSettings,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetDeploymentSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetDeploymentSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getDeploymentSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetDeploymentSettings',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetDeploymentSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.PromoteScanJobRequest,
 *   !proto.sp.scan_service.PromoteScanJobResponse>}
 */
const methodDescriptor_ScanServiceInternal_PromoteScanJob = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/PromoteScanJob',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.PromoteScanJobRequest,
  proto.sp.scan_service.PromoteScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.PromoteScanJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.PromoteScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.PromoteScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.PromoteScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.PromoteScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.promoteScanJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/PromoteScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_PromoteScanJob,
      callback);
};


/**
 * @param {!proto.sp.scan_service.PromoteScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.PromoteScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.promoteScanJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/PromoteScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_PromoteScanJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.PrioritizeScanJobRequest,
 *   !proto.sp.scan_service.PrioritizeScanJobResponse>}
 */
const methodDescriptor_ScanServiceInternal_PrioritizeScanJob = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/PrioritizeScanJob',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.PrioritizeScanJobRequest,
  proto.sp.scan_service.PrioritizeScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.PrioritizeScanJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.PrioritizeScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.PrioritizeScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.PrioritizeScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.PrioritizeScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.prioritizeScanJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/PrioritizeScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_PrioritizeScanJob,
      callback);
};


/**
 * @param {!proto.sp.scan_service.PrioritizeScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.PrioritizeScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.prioritizeScanJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/PrioritizeScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_PrioritizeScanJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetAttachedPropertySetsRequest,
 *   !proto.sp.scan_service.GetAttachedPropertySetsResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetAttachedPropertySets = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetAttachedPropertySets',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetAttachedPropertySetsRequest,
  proto.sp.scan_service.GetAttachedPropertySetsResponse,
  /**
   * @param {!proto.sp.scan_service.GetAttachedPropertySetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetAttachedPropertySetsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetAttachedPropertySetsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetAttachedPropertySetsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getAttachedPropertySets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetAttachedPropertySets',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetAttachedPropertySets,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetAttachedPropertySetsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetAttachedPropertySetsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getAttachedPropertySets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetAttachedPropertySets',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetAttachedPropertySets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetAccountDomainSummaryRequest,
 *   !proto.sp.scan_service.GetAccountDomainSummaryResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetAccountDomainSummary = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetAccountDomainSummary',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetAccountDomainSummaryRequest,
  proto.sp.scan_service.GetAccountDomainSummaryResponse,
  /**
   * @param {!proto.sp.scan_service.GetAccountDomainSummaryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetAccountDomainSummaryResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetAccountDomainSummaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetAccountDomainSummaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getAccountDomainSummary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetAccountDomainSummary',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetAccountDomainSummary,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetAccountDomainSummaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetAccountDomainSummaryResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getAccountDomainSummary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetAccountDomainSummary',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetAccountDomainSummary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScoresForPropertySetRequest,
 *   !proto.sp.scan_service.GetScoresForPropertySetResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetScoresForPropertySet = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetScoresForPropertySet',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScoresForPropertySetRequest,
  proto.sp.scan_service.GetScoresForPropertySetResponse,
  /**
   * @param {!proto.sp.scan_service.GetScoresForPropertySetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScoresForPropertySetResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScoresForPropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScoresForPropertySetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScoresForPropertySetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getScoresForPropertySet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetScoresForPropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetScoresForPropertySet,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScoresForPropertySetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScoresForPropertySetResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getScoresForPropertySet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetScoresForPropertySet',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetScoresForPropertySet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetTrainingDataInputRequest,
 *   !proto.sp.scan_service.GetTrainingDataInputResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetTrainingDataInput = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetTrainingDataInput',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetTrainingDataInputRequest,
  proto.sp.scan_service.GetTrainingDataInputResponse,
  /**
   * @param {!proto.sp.scan_service.GetTrainingDataInputRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetTrainingDataInputResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetTrainingDataInputRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetTrainingDataInputResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetTrainingDataInputResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getTrainingDataInput =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetTrainingDataInput',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetTrainingDataInput,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetTrainingDataInputRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetTrainingDataInputResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getTrainingDataInput =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetTrainingDataInput',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetTrainingDataInput);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetRandomTrainingDataInputRequest,
 *   !proto.sp.scan_service.GetTrainingDataInputResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetRandomTrainingDataInput = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetRandomTrainingDataInput',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetRandomTrainingDataInputRequest,
  proto.sp.scan_service.GetTrainingDataInputResponse,
  /**
   * @param {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetTrainingDataInputResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetTrainingDataInputResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetTrainingDataInputResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getRandomTrainingDataInput =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetRandomTrainingDataInput',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetRandomTrainingDataInput,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetRandomTrainingDataInputRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetTrainingDataInputResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getRandomTrainingDataInput =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetRandomTrainingDataInput',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetRandomTrainingDataInput);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UpdateTrainingDataRequest,
 *   !proto.sp.scan_service.UpdateTrainingDataResponse>}
 */
const methodDescriptor_ScanServiceInternal_UpdateTrainingData = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/UpdateTrainingData',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UpdateTrainingDataRequest,
  proto.sp.scan_service.UpdateTrainingDataResponse,
  /**
   * @param {!proto.sp.scan_service.UpdateTrainingDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UpdateTrainingDataResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UpdateTrainingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UpdateTrainingDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UpdateTrainingDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.updateTrainingData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UpdateTrainingData',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UpdateTrainingData,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UpdateTrainingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UpdateTrainingDataResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.updateTrainingData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UpdateTrainingData',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UpdateTrainingData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetRandomScanJobPendingGatingRequest,
 *   !proto.sp.scan_service.GetRandomScanJobPendingGatingResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetRandomScanJobPendingGating = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetRandomScanJobPendingGating',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetRandomScanJobPendingGatingRequest,
  proto.sp.scan_service.GetRandomScanJobPendingGatingResponse,
  /**
   * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetRandomScanJobPendingGatingResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetRandomScanJobPendingGatingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getRandomScanJobPendingGating =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetRandomScanJobPendingGating',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetRandomScanJobPendingGating,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetRandomScanJobPendingGatingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetRandomScanJobPendingGatingResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getRandomScanJobPendingGating =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetRandomScanJobPendingGating',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetRandomScanJobPendingGating);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.CorrectScanJobResultsRequest,
 *   !proto.sp.scan_service.CorrectScanJobResultsResponse>}
 */
const methodDescriptor_ScanServiceInternal_CorrectScanJobResults = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/CorrectScanJobResults',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.CorrectScanJobResultsRequest,
  proto.sp.scan_service.CorrectScanJobResultsResponse,
  /**
   * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.CorrectScanJobResultsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.CorrectScanJobResultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.CorrectScanJobResultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.correctScanJobResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/CorrectScanJobResults',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_CorrectScanJobResults,
      callback);
};


/**
 * @param {!proto.sp.scan_service.CorrectScanJobResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.CorrectScanJobResultsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.correctScanJobResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/CorrectScanJobResults',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_CorrectScanJobResults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.VerifyApiKeyRequest,
 *   !proto.sp.scan_service.VerifyApiKeyResponse>}
 */
const methodDescriptor_ScanServiceInternal_VerifyApiKey = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/VerifyApiKey',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.VerifyApiKeyRequest,
  proto.sp.scan_service.VerifyApiKeyResponse,
  /**
   * @param {!proto.sp.scan_service.VerifyApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.VerifyApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.VerifyApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.VerifyApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.VerifyApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.verifyApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/VerifyApiKey',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_VerifyApiKey,
      callback);
};


/**
 * @param {!proto.sp.scan_service.VerifyApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.VerifyApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.verifyApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/VerifyApiKey',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_VerifyApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GenerateApiKeyRequest,
 *   !proto.sp.scan_service.GenerateApiKeyResponse>}
 */
const methodDescriptor_ScanServiceInternal_GenerateApiKey = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GenerateApiKey',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GenerateApiKeyRequest,
  proto.sp.scan_service.GenerateApiKeyResponse,
  /**
   * @param {!proto.sp.scan_service.GenerateApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GenerateApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GenerateApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GenerateApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GenerateApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.generateApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GenerateApiKey',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GenerateApiKey,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GenerateApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GenerateApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.generateApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GenerateApiKey',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GenerateApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.RevokeApiKeyRequest,
 *   !proto.sp.scan_service.RevokeApiKeyResponse>}
 */
const methodDescriptor_ScanServiceInternal_RevokeApiKey = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/RevokeApiKey',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.RevokeApiKeyRequest,
  proto.sp.scan_service.RevokeApiKeyResponse,
  /**
   * @param {!proto.sp.scan_service.RevokeApiKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.RevokeApiKeyResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.RevokeApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.RevokeApiKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.RevokeApiKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.revokeApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RevokeApiKey',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RevokeApiKey,
      callback);
};


/**
 * @param {!proto.sp.scan_service.RevokeApiKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.RevokeApiKeyResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.revokeApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/RevokeApiKey',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_RevokeApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ComputePropertyStatisticsRequest,
 *   !proto.sp.scan_service.ComputePropertyStatisticsResponse>}
 */
const methodDescriptor_ScanServiceInternal_ComputePropertyStatistics = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ComputePropertyStatistics',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ComputePropertyStatisticsRequest,
  proto.sp.scan_service.ComputePropertyStatisticsResponse,
  /**
   * @param {!proto.sp.scan_service.ComputePropertyStatisticsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ComputePropertyStatisticsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ComputePropertyStatisticsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ComputePropertyStatisticsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.computePropertyStatistics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ComputePropertyStatistics',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ComputePropertyStatistics,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ComputePropertyStatisticsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ComputePropertyStatisticsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.computePropertyStatistics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ComputePropertyStatistics',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ComputePropertyStatistics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.ComputeScanJobStatisticsRequest,
 *   !proto.sp.scan_service.ComputeScanJobStatisticsResponse>}
 */
const methodDescriptor_ScanServiceInternal_ComputeScanJobStatistics = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/ComputeScanJobStatistics',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.ComputeScanJobStatisticsRequest,
  proto.sp.scan_service.ComputeScanJobStatisticsResponse,
  /**
   * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.ComputeScanJobStatisticsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.ComputeScanJobStatisticsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.ComputeScanJobStatisticsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.computeScanJobStatistics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ComputeScanJobStatistics',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ComputeScanJobStatistics,
      callback);
};


/**
 * @param {!proto.sp.scan_service.ComputeScanJobStatisticsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.ComputeScanJobStatisticsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.computeScanJobStatistics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/ComputeScanJobStatistics',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_ComputeScanJobStatistics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetLatestActiveScanReportsRequest,
 *   !proto.sp.scan_service.GetLatestActiveScanReportsResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetLatestActiveScanReports = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetLatestActiveScanReports',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetLatestActiveScanReportsRequest,
  proto.sp.scan_service.GetLatestActiveScanReportsResponse,
  /**
   * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetLatestActiveScanReportsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetLatestActiveScanReportsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetLatestActiveScanReportsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getLatestActiveScanReports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetLatestActiveScanReports',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetLatestActiveScanReports,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetLatestActiveScanReportsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetLatestActiveScanReportsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getLatestActiveScanReports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetLatestActiveScanReports',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetLatestActiveScanReports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetScanJobReportsRequest,
 *   !proto.sp.scan_service.GetScanJobReportsResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetScanJobReports = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetScanJobReports',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetScanJobReportsRequest,
  proto.sp.scan_service.GetScanJobReportsResponse,
  /**
   * @param {!proto.sp.scan_service.GetScanJobReportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetScanJobReportsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetScanJobReportsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetScanJobReportsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getScanJobReports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetScanJobReports',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetScanJobReports,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetScanJobReportsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetScanJobReportsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getScanJobReports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetScanJobReports',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetScanJobReports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.MonitorExpiredLatestScansRequest,
 *   !proto.sp.scan_service.MonitorExpiredLatestScansResponse>}
 */
const methodDescriptor_ScanServiceInternal_MonitorExpiredLatestScans = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/MonitorExpiredLatestScans',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.MonitorExpiredLatestScansRequest,
  proto.sp.scan_service.MonitorExpiredLatestScansResponse,
  /**
   * @param {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.MonitorExpiredLatestScansResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.MonitorExpiredLatestScansResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.MonitorExpiredLatestScansResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.monitorExpiredLatestScans =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/MonitorExpiredLatestScans',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_MonitorExpiredLatestScans,
      callback);
};


/**
 * @param {!proto.sp.scan_service.MonitorExpiredLatestScansRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.MonitorExpiredLatestScansResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.monitorExpiredLatestScans =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/MonitorExpiredLatestScans',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_MonitorExpiredLatestScans);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.DumpTrainingDataRequest,
 *   !proto.sp.scan_service.DumpTrainingDataResponse>}
 */
const methodDescriptor_ScanServiceInternal_DumpTrainingData = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/DumpTrainingData',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.DumpTrainingDataRequest,
  proto.sp.scan_service.DumpTrainingDataResponse,
  /**
   * @param {!proto.sp.scan_service.DumpTrainingDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.DumpTrainingDataResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.DumpTrainingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.DumpTrainingDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.DumpTrainingDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.dumpTrainingData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/DumpTrainingData',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_DumpTrainingData,
      callback);
};


/**
 * @param {!proto.sp.scan_service.DumpTrainingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.DumpTrainingDataResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.dumpTrainingData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/DumpTrainingData',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_DumpTrainingData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GetCookiesForScanJobRequest,
 *   !proto.sp.scan_service.GetCookiesForScanJobResponse>}
 */
const methodDescriptor_ScanServiceInternal_GetCookiesForScanJob = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GetCookiesForScanJob',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GetCookiesForScanJobRequest,
  proto.sp.scan_service.GetCookiesForScanJobResponse,
  /**
   * @param {!proto.sp.scan_service.GetCookiesForScanJobRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GetCookiesForScanJobResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GetCookiesForScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GetCookiesForScanJobResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GetCookiesForScanJobResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.getCookiesForScanJob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetCookiesForScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetCookiesForScanJob,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GetCookiesForScanJobRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GetCookiesForScanJobResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.getCookiesForScanJob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GetCookiesForScanJob',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GetCookiesForScanJob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.GateScanJobsRequest,
 *   !proto.sp.scan_service.GateScanJobsResponse>}
 */
const methodDescriptor_ScanServiceInternal_GateScanJobs = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/GateScanJobs',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.GateScanJobsRequest,
  proto.sp.scan_service.GateScanJobsResponse,
  /**
   * @param {!proto.sp.scan_service.GateScanJobsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.GateScanJobsResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.GateScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.GateScanJobsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.GateScanJobsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.gateScanJobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GateScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GateScanJobs,
      callback);
};


/**
 * @param {!proto.sp.scan_service.GateScanJobsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.GateScanJobsResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.gateScanJobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/GateScanJobs',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_GateScanJobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.sp.scan_service.UpdateGatingRequest,
 *   !proto.sp.scan_service.UpdateGatingResponse>}
 */
const methodDescriptor_ScanServiceInternal_UpdateGating = new grpc.web.MethodDescriptor(
  '/sp.scan_service.ScanServiceInternal/UpdateGating',
  grpc.web.MethodType.UNARY,
  proto.sp.scan_service.UpdateGatingRequest,
  proto.sp.scan_service.UpdateGatingResponse,
  /**
   * @param {!proto.sp.scan_service.UpdateGatingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.sp.scan_service.UpdateGatingResponse.deserializeBinary
);


/**
 * @param {!proto.sp.scan_service.UpdateGatingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.sp.scan_service.UpdateGatingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.sp.scan_service.UpdateGatingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.sp.scan_service.ScanServiceInternalClient.prototype.updateGating =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UpdateGating',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UpdateGating,
      callback);
};


/**
 * @param {!proto.sp.scan_service.UpdateGatingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.sp.scan_service.UpdateGatingResponse>}
 *     Promise that resolves to the response
 */
proto.sp.scan_service.ScanServiceInternalPromiseClient.prototype.updateGating =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/sp.scan_service.ScanServiceInternal/UpdateGating',
      request,
      metadata || {},
      methodDescriptor_ScanServiceInternal_UpdateGating);
};


module.exports = proto.sp.scan_service;

