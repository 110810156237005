import React, {ReactElement, FC} from "react";
import { Grid } from '@mui/material';
import MasterContainer from "../components/containers/MasterContainer";
import PropertiesCard from "../components/cards/PropertiesCard";

const Properties: FC<any> = (): ReactElement => {
    return (
        <MasterContainer>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={12} sx={{ mt: 2}}>
                    <PropertiesCard maxHeight={750}/>
                </Grid>
            </Grid>
        </MasterContainer>
    );
};
export default Properties;