import React, {FC, ReactElement, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReplayIcon from '@mui/icons-material/Replay';
import {ScanServiceInternalClient} from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {ScanStalePropertiesRequest} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import {generateAuthHeader} from '../../lib/authorizationUtils';
import {SxProps} from "@mui/system";
import {ProxyType} from "../../generated/sp/scan_runner/scan_runner_pb";
import * as google_protobuf_duration_pb from "google-protobuf/google/protobuf/duration_pb";

interface BulkScanStalePropertiesProps {
    propertySetId: string | undefined;
    sx?: SxProps<Theme>;
}

const BulkScanStalePropertiesCard: FC<BulkScanStalePropertiesProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [scannedStaleProperties, setScannedStaleProperties] = useState<number>(0);
    const [stalenessDays, setStalenessDays] = useState<number>(100);
    const [stalenessHours, setStalenessHours] = useState<number>(0);
    const [priority, setPriority] = useState<number>(0);
    const [proxyType, setProxyType] = useState<ProxyType>(ProxyType.PROXY_TYPE_SOURCEPOINT_DATACENTER);

    // bulk process properties dialog
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickBulkScanStaleProperties = () => {
        setOpen(true);
    };

    const calculateSeconds = (): number => {
        return (stalenessDays * 86400) + (stalenessHours * 3600);
    }

    const scanStaleProperties = async (scanServiceInternal: ScanServiceInternalClient, stalenessDays: number, stalenessHours: number, priority: number, proxyType: ProxyType): Promise<number> => {
        // determine seconds
        let seconds = calculateSeconds();
        var req = new ScanStalePropertiesRequest();
        req.setPropertySetId(props.propertySetId!);
        req.setStalenessDuration(new google_protobuf_duration_pb.Duration().setSeconds(seconds))
        req.setPriority(priority);
        req.setProxyType(proxyType);
        console.log(`Rescanning with seconds=${seconds}`);
        return new Promise<number>((resolve, reject) => {
            scanServiceInternal.scanStaleProperties(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getPropertiesMarkedForRescanning())
            });
        });
    }

    const handleClose = (shouldReScanProperties: boolean) => () => {
        if (shouldReScanProperties) {
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            Promise.resolve(scanStaleProperties(scanServiceInternal, stalenessDays, stalenessHours, priority, proxyType)).then(function (recordsProcessed) {
                console.log(`Re-scanned -> recordsProcessed=${recordsProcessed}`);
                setScannedStaleProperties(recordsProcessed);
                setLoading(false);
            }, function (value) {
                // not called
            });
        }
        setOpen(false);
    };

    const handleStalenessDaysChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let val = event.target.value;
        let num = Number(val)
        setStalenessDays(num);
    }

    const handleStalenessHoursChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let val = event.target.value;
        let num = Number(val)
        setStalenessHours(num);
    }

    const handlePriorityChange = (event: SelectChangeEvent) => {
        let priorityToBeSet = parseInt(event.target.value);
        setPriority(priorityToBeSet);
    };

    const handleProxyTypeChange = (event: SelectChangeEvent) => {
        let proxyTypeToBeSet = parseInt(event.target.value);
        setProxyType(proxyTypeToBeSet);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose(false)} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{"Re-Scan Properties"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText> Are you sure you want to re-scan all stale
                            ( &gt; {stalenessDays} days) properties?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" autoFocus onClick={handleClose(false)}>Cancel</Button>
                        <Button size="small" variant="contained" onClick={handleClose(true)} autoFocus color="secondary">Re-Scan</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1, mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Bulk Stale Property Re-Scanning</Typography>
                    </Grid>
                    {scannedStaleProperties > 0 &&
                        <Grid item xs={12}>
                            <Typography sx={{mb: 1}} variant="subtitle1" component="div">Marked {scannedStaleProperties} stale properties for re-scanning!</Typography>
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <TextField size="small" label="Staleness (Days)" type="number" value={stalenessDays}
                                   onChange={x => handleStalenessDaysChange(x)} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField size="small" label="Staleness (Hours)" type="number" value={stalenessHours}
                                   onChange={x => handleStalenessHoursChange(x)} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="priority">Priority</InputLabel>
                            <Select
                                size="small"
                                labelId="priority"
                                id="priority"
                                value={priority.toString()} label="Priority"
                                onChange={x => handlePriorityChange(x)}>
                                <MenuItem value={0}>Low</MenuItem>
                                <MenuItem value={10}>High</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="proxyType">Proxy Type</InputLabel>
                            <Select
                              size="small"
                              labelId="proxyType"
                              id="proxyType"
                              value={proxyType.toString()} label="Proxy Type"
                              onChange={x => handleProxyTypeChange(x)}>
                                <MenuItem value={ProxyType.PROXY_TYPE_SOURCEPOINT_DATACENTER}>Sourcepoint DC</MenuItem>
                                <MenuItem value={ProxyType.PROXY_TYPE_BRIGHTDATA_DATACENTER}>BrightData DC</MenuItem>
                                <MenuItem value={ProxyType.PROXY_TYPE_BRIGHTDATA_RESIDENTIAL}>BrightData Residential</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div">Resulting Duration: {calculateSeconds()}s</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleClickBulkScanStaleProperties}
                            loading={loading}
                            disabled={props.propertySetId === undefined}
                            loadingPosition="start"
                            startIcon={<ReplayIcon/>}
                            variant="contained"
                        >
                            Re-Scan
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BulkScanStalePropertiesCard;