import React, {ReactElement, FC, useState, useEffect, useLayoutEffect, useCallback, useRef} from "react";
import { ScanServiceClient } from '../generated/sp/scan_service/scan_service_grpc_web_pb';
import {
  GetPropertySetsRequest,
  GetPropertySetsResponse,
  PropertySet,
} from '../generated/sp/scan_service/scan_service_pb';
import {PagingParameters, SortDirection, StringFilter, StringList} from '../generated/sp/service_common/common_pb';
import { Box, Grid, FormControl, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableSortLabel } from '@mui/material';
import { useNavigate} from "react-router-dom";
import { enumName } from '../lib/enumUtils';
import { formatStringMaxLength } from '../lib/stringUtils';
import { formatRegion, regionSelectorItems } from '../lib/regionUtils';
import { generateAuthHeader } from '../lib/authorizationUtils';
import { StyledTableCell, StyledTableRow } from '../lib/tableUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useUpdateEffect } from 'usehooks-ts'
import { getPropertySetStatusColor } from "../lib/statusUtils";
import {SELECTOR_ALL_ID} from "../lib/selectorUtils";
import {CsvHeader} from "../lib/exportCsvUtils";
import {CSVLink} from "react-csv";
import TableArrowDownIcon from "@mui-extra/icons/TableArrowDownIcon";

const generateGetPropertySetsRequest = (continuationToken: string | undefined, limit: number, sortField: GetPropertySetsRequest.SortField, sortDirection: SortDirection, embeddedModePropertyIdsFilter: string[] | undefined, propertySetStatusFilter: PropertySet.PropertySetStatus, regionFilter: string, nameFilter: string): GetPropertySetsRequest => {
  // determine filtering.
  var req = new GetPropertySetsRequest();
  let filters = new GetPropertySetsRequest.Filters();
  if( propertySetStatusFilter > 0) {
    filters.addStatusFilter(propertySetStatusFilter);
  }
  if( regionFilter.length > 0 && regionFilter !== SELECTOR_ALL_ID) {
    filters.addRegionIdFilter(regionFilter);
  }
  if( nameFilter !== undefined && nameFilter.length > 0) {
    filters.addNameFilter(new StringFilter().setCaseInsensitive(true).setContains(new StringList().addValues(nameFilter)));
  }
  if ( embeddedModePropertyIdsFilter !== undefined && embeddedModePropertyIdsFilter.length > 0) {
    embeddedModePropertyIdsFilter.forEach((propertyId) => filters.addHasPropertyIdFilter(propertyId));
  }
  req.setFilters(filters);
  // determine paging
  var pagingParams = new PagingParameters();
  if (continuationToken !== undefined) {
    pagingParams.setContinuationToken(continuationToken);
  }
  pagingParams.setLimit(limit);
  req.setPagingParameters(pagingParams);
  //determine sorting
  var sorting = new GetPropertySetsRequest.SortParameter();
  sorting.setField(sortField);
  sorting.setDirection(sortDirection);
  req.addSortParameters(sorting);
  return req;
}

const fetchPropertySets = async (scanService: ScanServiceClient, request: GetPropertySetsRequest): Promise<GetPropertySetsResponse> => {
  return new Promise<GetPropertySetsResponse>((resolve, reject) => {
    scanService.getPropertySets(request, generateAuthHeader(), (err, response) => {
      if (err) reject(err);
      else resolve(response)
    });
  });
}

interface PropertySetInfinityTableProps {
  maxHeight: number;
  embeddedModePropertyIdsFilter?: string[] | undefined; // if defined, the table will render without infinity mode and do exact match search
  showFilterUi: boolean;
  showExportUi: boolean;
}

const PropertySetInfinityTable: FC<PropertySetInfinityTableProps> = (props): ReactElement => {
    const [propertySets, setPropertySets] = useState<PropertySet[]>([]);
    const [propertySetStatusFilter, setPropertySetStatusFilter] = useState<PropertySet.PropertySetStatus>(PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_UNSET);
    const [regionFilter, setRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [nameFilter, setNameFilter] = useState<string>("");
    const [currentContinuationToken, setCurrentContinuationToken] = useState<string>();
    const [nextContinuationToken, setNextContinuationToken] = useState<string | undefined>("");
    const [sortField, setSortField] = React.useState<GetPropertySetsRequest.SortField>(GetPropertySetsRequest.SortField.SORT_FIELD_CREATED_TIME);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.SORT_DIRECTION_DESC);
    const [limit] = useState<number>(50);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    // infinity scroll state
    const tableEl = useRef<any>(null);
    const [loading, setLoading] = useState(true)
    const [distanceBottom, setDistanceBottom] = useState(0)

    const navigate = useNavigate();
    const navigateToPropertySet = (propertySetId: String) => {
        navigate(`/sets/property/${propertySetId}`);
    };

    useEffect(() => {
      (async () => {
        setPropertySets([]);
        setRefreshToggle(true);
      })();
    }, []);

    useUpdateEffect(() => {
      (async () => {
        console.log(`Loading property sets list data, embeddedModePropertyIdsFilter=[${props.embeddedModePropertyIdsFilter}]`);
        setLoading(true);
        const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
        const req = generateGetPropertySetsRequest(currentContinuationToken, limit, sortField, sortDirection, props.embeddedModePropertyIdsFilter, propertySetStatusFilter, regionFilter, nameFilter);
        const propertySetsResponse = await fetchPropertySets(scanService, req);
        let continuationToken = propertySetsResponse.getContinuationToken().length > 0 ? propertySetsResponse.getContinuationToken() : undefined;
        setNextContinuationToken(continuationToken);
        if (props.embeddedModePropertyIdsFilter !== undefined && props.embeddedModePropertyIdsFilter.length > 0) {
          if (props.embeddedModePropertyIdsFilter.length === 0) {
            // this is a safety to prevent false loading of an unfiltered list of scan jobs. embeddedModePropertyIdsFilter should have at least one value.
            setPropertySets([]);
          } else if (props.embeddedModePropertyIdsFilter.length > 0) {
            // always set the entire list, not infinity mode
            setPropertySets(propertySetsResponse.getPropertySetsList());
          }
        } else {
          // to support scrolling, we need to append to whatever is currently loaded, infinity mode
          setPropertySets(old => [...old, ...propertySetsResponse.getPropertySetsList()]);
        }
        setLoading(false);
      })();
    }, [props.embeddedModePropertyIdsFilter, currentContinuationToken, limit, propertySetStatusFilter, regionFilter, sortDirection, sortField, refreshToggle]);

    const exportCsvHeaders = (): CsvHeader[] => {
      return [
        { label: "Name", key: "name" },
        { label: "Account", key: "accountId" },
        { label: "Status", key: "status" },
        { label: "Region", key: "region" },
        { label: "Created", key: "created" },
      ]
    }

    interface CsvExportData {
      name: string;
      accountId: number;
      status: string;
      region: string;
      created: string;
    }

    const exportCsvData = (): CsvExportData[] => {
      return propertySets.map((propertySet) => {
        return {
          name: propertySet.getName(),
          accountId: propertySet.getAccountId(),
          status: enumName(PropertySet.PropertySetStatus, propertySet.getStatus(), false),
          region: formatRegion(propertySet.getRegionId()),
          created: propertySet.getAuditData()?.getCreated()?.toDate().toLocaleString() || ''
        };
      });
    }

    const scrollListener = useCallback(() => {
        const tableRef = tableEl.current
        if (!tableRef) throw Error("tableRef is not assigned");
        let bottom = tableRef.scrollHeight - tableRef.clientHeight
        //TODO: maybe set Distance bottom everytime.
        if (!distanceBottom) {
          setDistanceBottom(Math.round((bottom / 100) * 20))
        }
        let hasMore = nextContinuationToken !== undefined;
        if (tableRef.scrollTop > bottom - distanceBottom && hasMore && !loading) {
           console.log(`Infinite scroll triggered -- using continuationToken of : ${nextContinuationToken}`);
           //trigger data reload by swapping currentContinuationToken with nextContinuationToken
           setCurrentContinuationToken(nextContinuationToken); // this will trigger the data re-fetch.
        }
    }, [loading, distanceBottom, nextContinuationToken])

    useLayoutEffect(() => {
      const tableRef = tableEl.current
      if (!tableRef) throw Error("tableRef is not assigned");
        tableRef.addEventListener('scroll', scrollListener)
        return () => {
          tableRef.removeEventListener('scroll', scrollListener)
        }
    }, [scrollListener])

    const startFreshSearch = () => {
        setPropertySets([]); // wipe out data such that we dont keep appending to existing list.
        setCurrentContinuationToken(undefined); // wipe out token so we start with a fresh paging session
        setNextContinuationToken(undefined); // wipe out token so we start with a fresh paging session
    }

    const handlePropertySetStatusFilterChange = (event: SelectChangeEvent) => {
        let revised = parseInt(event.target.value);
        if ( revised !== propertySetStatusFilter) {
          startFreshSearch();
          setPropertySetStatusFilter(revised);
        }
    };

    const handleRegionFilterChange = (event: SelectChangeEvent) => {
        let revised = event.target.value;
        if ( revised !== regionFilter) {
            startFreshSearch();
            setRegionFilter(revised);
        }
    };

    const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNameFilter(event.target.value);
    };

    const handleNameFilterKeyChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if( event.key === 'Enter' ){
        handleRefresh();
      }
    };

    const handleSortRequest = (sortField: GetPropertySetsRequest.SortField) => {
        setSortField(sortField);
        setSortDirection(sortDirection === SortDirection.SORT_DIRECTION_DESC ? SortDirection.SORT_DIRECTION_ASC : SortDirection.SORT_DIRECTION_DESC);
        startFreshSearch();
    };

    const handleRefresh = () => {
        startFreshSearch();
        setRefreshToggle(prev => !prev);
    };

    return (
        <div>
            { props.showFilterUi &&
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography sx={{ mt: 1, mb: 1 }} style={{ fontWeight: 600 }} variant="subtitle2" component="div">Filter Property Sets</Typography>
              </Grid>
              <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="status">Status</InputLabel>
                    <Select size="small" labelId="Status" id="Status" value={propertySetStatusFilter.toString()} label="Status" onChange={handlePropertySetStatusFilterChange}>
                      <MenuItem value={PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_UNSET}>All</MenuItem>
                      <MenuItem value={PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_ACTIVE}>Active</MenuItem>
                      <MenuItem value={PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DISABLED}>Disabled</MenuItem>
                      <MenuItem value={PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DISABLED_PENDING}>Disabled Pending</MenuItem>
                      <MenuItem value={PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DELETED}>Deleted</MenuItem>
                      <MenuItem value={PropertySet.PropertySetStatus.PROPERTY_SET_STATUS_DELETED_PENDING}>Deleted Pending</MenuItem>
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="region">Region</InputLabel>
                    <Select size="small" labelId="region" id="region" value={regionFilter} label="Region" onChange={handleRegionFilterChange}>
                      {regionSelectorItems().map((region) => (
                          <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField size="small" label="Search by Name" type="string" value={nameFilter || ''} onChange={x=> handleNameFilterChange(x)}  onKeyPress={x=> handleNameFilterKeyChange(x)} fullWidth/>
              </Grid>
              <Grid item xs={4}>
                  <LoadingButton
                    size="small"
                    color="secondary"
                    onClick={handleRefresh}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<RefreshIcon />}
                    variant="contained"
                  >
                    Refresh
                  </LoadingButton>
              </Grid>
            </Grid>
           }
           { props.showExportUi &&
           <Grid container spacing={1} alignItems="center">
              <Grid item xs={10}>
                <Typography sx={{ mt: 1, mb: 0 }} variant="subtitle2" component="div">Found {propertySets.length} property sets</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Box display="flex" alignItems="right" justifyContent="right">
                      <CSVLink data={exportCsvData()} headers={exportCsvHeaders()} filename={"PropertySets-" + new Date().toLocaleDateString() + "-" + new Date().toLocaleTimeString() + ".csv"}>
                          <TableArrowDownIcon sx={{color: "secondary"}}/>
                      </CSVLink>
                  </Box>
              </Grid>
              <Grid item xs={12}>
                {loading? <LinearProgress sx={{ height: 10 }} color="secondary"/> : <Box sx={{ height: 10 }}>&nbsp;</Box>}
              </Grid>
              <Grid item xs={12} sx={{ height: 10 }} >&nbsp;</Grid>
            </Grid>
           }
           <TableContainer component={Paper} style={{ maxHeight: props.maxHeight + "px" }} ref={tableEl}>
            <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="PropertySets">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" onClick={() => handleSortRequest(GetPropertySetsRequest.SortField.SORT_FIELD_NAME)}>
                    <TableSortLabel active={sortField === GetPropertySetsRequest.SortField.SORT_FIELD_NAME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="center">Account</StyledTableCell>
                  <StyledTableCell align="center" width="5%" onClick={() => handleSortRequest(GetPropertySetsRequest.SortField.SORT_FIELD_STATUS)}>
                    <TableSortLabel active={sortField === GetPropertySetsRequest.SortField.SORT_FIELD_STATUS} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Status</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSortRequest(GetPropertySetsRequest.SortField.SORT_FIELD_REGION)}>
                    <TableSortLabel active={sortField === GetPropertySetsRequest.SortField.SORT_FIELD_REGION} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Region</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right" onClick={() => handleSortRequest(GetPropertySetsRequest.SortField.SORT_FIELD_CREATED_TIME)}>
                    <TableSortLabel active={sortField === GetPropertySetsRequest.SortField.SORT_FIELD_CREATED_TIME} direction={sortDirection === SortDirection.SORT_DIRECTION_DESC ? "desc" : "asc"}>Created</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {propertySets.map((row) => (
                  <StyledTableRow hover key={row.getId()} onClick={() => navigateToPropertySet(`${row.getId()}`)} sx= {{ cursor: "pointer", "&.Mui-selected, &.Mui-selected:hover": { backgroundColor: "#B0F7F2"} }}>
                    <StyledTableCell align="left">{formatStringMaxLength(row.getName())}</StyledTableCell>
                    <StyledTableCell align="center">{row.getAccountId()}</StyledTableCell>
                    <StyledTableCell align="center" sx={{ color: getPropertySetStatusColor(row.getStatus()) }}>{enumName(PropertySet.PropertySetStatus, row.getStatus(), false)}</StyledTableCell>
                    <StyledTableCell align="right">{formatRegion(row.getRegionId())}</StyledTableCell>
                    <StyledTableCell align="right">{row.getAuditData()?.getCreated()?.toDate().toLocaleString()}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    );
};

export default PropertySetInfinityTable;