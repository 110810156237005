import {SELECTOR_ALL_ID} from "./selectorUtils";
import {ScanJob} from "../generated/sp/scan_service/scan_service_pb";

const formatGatingType = (gatingType: ScanJob.ScanJobGating.GatingType | undefined) => {
  switch (gatingType) {
    case ScanJob.ScanJobGating.GatingType.GATING_TYPE_AWAITING:
      return "Awaiting";
    case ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_APPROVED:
      return "Auto Approved";
    case ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_REJECTED:
      return "Auto Rejected";
    case ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_NEEDS_HUMAN_REVIEW:
      return "Needs Human Review";
    case ScanJob.ScanJobGating.GatingType.GATING_TYPE_HUMAN_APPROVED:
      return "Human Approved";
    case ScanJob.ScanJobGating.GatingType.GATING_TYPE_HUMAN_REJECTED:
      return "Human Rejected";
    default:
      return "Unknown";
  }
}
const gatingTypeSelectorItems = (unsetOverrideName?: string | undefined, humanOnly?: boolean| undefined) => {
  let base = [
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_UNSET,
      name: unsetOverrideName === undefined ? SELECTOR_ALL_ID : unsetOverrideName
    },
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_HUMAN_APPROVED,
      name: formatGatingType(ScanJob.ScanJobGating.GatingType.GATING_TYPE_HUMAN_APPROVED)
    },
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_HUMAN_REJECTED,
      name: formatGatingType(ScanJob.ScanJobGating.GatingType.GATING_TYPE_HUMAN_REJECTED)
    },
  ];

  if (humanOnly !== undefined && humanOnly) {
    return base;
  }
  return base.concat([
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_AWAITING,
      name: formatGatingType(ScanJob.ScanJobGating.GatingType.GATING_TYPE_AWAITING)
    },
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_APPROVED,
      name: formatGatingType(ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_APPROVED)
    },
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_REJECTED,
      name: formatGatingType(ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_REJECTED)
    },
    {
      id: ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_NEEDS_HUMAN_REVIEW,
      name: formatGatingType(ScanJob.ScanJobGating.GatingType.GATING_TYPE_AUTO_NEEDS_HUMAN_REVIEW)
    },

  ]);
}

export {formatGatingType, gatingTypeSelectorItems};
