import React, {ReactElement, FC, useState} from "react";
import {Button, Card, CardContent, Grid, Typography} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReplayIcon from '@mui/icons-material/Replay';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery} from '@mui/material';
import {ScanServiceInternalClient} from '../../generated/sp/scan_service/scan_service_internal_grpc_web_pb';
import {ReprocessPropertiesRequest} from '../../generated/sp/scan_service/scan_service_internal_pb';
import {Theme, useTheme} from '@mui/material/styles';
import {generateAuthHeader} from '../../lib/authorizationUtils';
import {SxProps} from "@mui/system";

interface BulkPropertyProcessingCardProps {
    sx?: SxProps<Theme>;
}

const BulkPropertyProcessingCard: FC<BulkPropertyProcessingCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false)
    const [reprocessedProperties, setReprocessedProperties] = useState<number>(0);

    // bulk process properties dialog
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickBulkProcessProperties = () => {
        setOpen(true);
    };

    const processProperties = async (scanServiceInternal: ScanServiceInternalClient): Promise<number> => {
        var req = new ReprocessPropertiesRequest();
        return new Promise<number>((resolve, reject) => {
            scanServiceInternal.reprocessProperties(req, generateAuthHeader(), (err, response) => {
                if (err) reject(err);
                else resolve(response.getPropertiesMarkedForReprocess()!)
            });
        });
    }

    const handleClose = (shouldProcessProperties: boolean) => () => {
        if (shouldProcessProperties) {
            setLoading(true);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            Promise.resolve(processProperties(scanServiceInternal)).then(function (recordsProcessed) {
                console.log(`Created recordsProcessed=${recordsProcessed}`);
                setReprocessedProperties(recordsProcessed);
                setLoading(false);
            }, function (value) {
                // not called
            });
        }
        setOpen(false);
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose(false)}
                        aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">{"Re-Process Properties"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText> Are you sure you want to re-process all properties?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" autoFocus onClick={handleClose(false)}>Cancel</Button>
                        <Button size="small" variant="contained" onClick={handleClose(true)} autoFocus
                                color="secondary">Re-Process</Button>
                    </DialogActions>
                </Dialog>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{mt: 1, mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Bulk
                            Property Re-Processing</Typography>
                    </Grid>
                    {reprocessedProperties > 0 &&
                        <Grid item xs={12}>
                            <Typography sx={{mb: 1}} variant="subtitle1"
                                        component="div">Marked {reprocessedProperties} properties for
                                re-processing!</Typography>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <LoadingButton
                            size="small"
                            color="secondary"
                            onClick={handleClickBulkProcessProperties}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<ReplayIcon/>}
                            variant="contained"
                        >
                            Bulk Re-Process
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default BulkPropertyProcessingCard;