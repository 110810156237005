import React, {FC, ReactElement, useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select, SelectChangeEvent,
    Toolbar,
    Typography
} from '@mui/material';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {ScanServiceInternalClient} from "../../generated/sp/scan_service/scan_service_internal_grpc_web_pb";
import {GetRandomScanJobPendingGatingRequest} from "../../generated/sp/scan_service/scan_service_internal_pb";
import {generateAuthHeader} from "../../lib/authorizationUtils";
import {ScanJob} from "../../generated/sp/scan_service/scan_service_pb";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import LoadingButton from "@mui/lab/LoadingButton";
import {regionSelectorItems} from "../../lib/regionUtils";
import {Link} from "react-router-dom";
import {SELECTOR_ALL_ID} from "../../lib/selectorUtils";
import {ScanServiceClient} from "../../generated/sp/scan_service/scan_service_grpc_web_pb";
import ScanJobTimelineCard from "./ScanJobTimelineCard";
import ScanResultsCard from "./ScanResultsCard";
import {getScanJobById} from "../../lib/scanServiceUtils";

interface TodoCardProps {
    initialScanJobId: string | undefined;
    initialPropertySetId: string | undefined;
    sx?: SxProps<Theme>;
}

const TodoCard: FC<TodoCardProps> = (props): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [continuousMode, setContinuousMode] = useState<boolean>(false);
    const [scanJob, setScanJob] = useState<ScanJob | undefined>(undefined);
    const [regionFilter, setRegionFilter] = useState<string>(SELECTOR_ALL_ID);
    const [refreshToggle, setRefreshToggle] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let maybeScanJobId = props.initialScanJobId;
            let maybePropertySetId = props.initialPropertySetId;
            setContinuousMode(maybeScanJobId === undefined);
            console.log(`Loading scanjob todo data for scanJobId=[${maybeScanJobId!}]`);
            const scanService = new ScanServiceClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const scanServiceInternal = new ScanServiceInternalClient(process.env.REACT_APP_SOURCE_POINT_SERVICES_ENDPOINT!);
            const maybeScanJob = await getScanJob(scanService, scanServiceInternal, maybeScanJobId, maybePropertySetId, regionFilter);
            setScanJob(maybeScanJob);
            setLoading(false);
        })();
    }, [props.initialScanJobId, props.initialPropertySetId, regionFilter, refreshToggle]);

    const getScanJob = async (scanService: ScanServiceClient, scanServiceInternal: ScanServiceInternalClient, scanJobId: string | undefined, propertySetId: string | undefined, regionFilter: string): Promise<ScanJob | undefined> => {
        if (scanJobId === undefined) {
            return new Promise<ScanJob | undefined>((resolve, reject) => {
                let req = new GetRandomScanJobPendingGatingRequest();
                if( regionFilter.length > 0 && regionFilter !== SELECTOR_ALL_ID) {
                    req.setRegionId(regionFilter);
                }
                if( propertySetId !== undefined) {
                    req.setPropertySetId(propertySetId);
                }
                scanServiceInternal.getRandomScanJobPendingGating(req, generateAuthHeader(), (err, response) => {
                    if (err) reject(err);
                    else resolve(response.getScanJob())
                });
            });
        } else {
            return getScanJobById(scanService, scanJobId, [ScanJob.ScanJobField.SCAN_JOB_FIELD_RESULT_REPORTS]);
        }
    }

    const handleSkip = () => {
        setScanJob(undefined);
        setRefreshToggle(prev => !prev);
    }
    const handleRegionFilterChange = (event: SelectChangeEvent) => {
        let revised = event.target.value;
        if ( revised !== regionFilter) {
            setRegionFilter(revised);
        }
    };

    return (
        <Card>
            <CardContent sx={props.sx}>
                <Grid container spacing={1} alignItems="center">
                    { props.initialPropertySetId !== undefined &&
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                Exclusively using data from Property Set: <Link target='_blank' to={`/sets/property/${props.initialPropertySetId}`} >{props.initialPropertySetId|| ''}</Link>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={5}>
                        <Toolbar disableGutters={true}>
                            { continuousMode &&
                                <FormControl size="small">
                                    <InputLabel size="small" id="region">Region</InputLabel>
                                    <Select size="small" labelId="region" id="region" value={regionFilter} label="Region" onChange={handleRegionFilterChange}>
                                        {regionSelectorItems().map((region) => (
                                            <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            {continuousMode &&
                                <LoadingButton size="small"
                                               sx={{ ml: 1 }}
                                               onClick={handleSkip}
                                               autoFocus
                                               color="secondary"
                                               startIcon={<SkipNextIcon />}
                                               variant="contained">
                                    Skip
                                </LoadingButton>
                            }
                        </Toolbar>
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                        {loading ? <LinearProgress sx={{height: 10}} color="secondary"/> : <Box sx={{height: 10}}>&nbsp;</Box>}
                    </Grid>
                    <Grid item xs={12} style={{ maxHeight: "50vh", overflow: 'auto'}}>
                        { scanJob !== undefined &&
                          <ScanJobTimelineCard scanJobId={scanJob.getId()} sx={{ mt: 1 }}/>
                        }
                        { scanJob === undefined &&
                            <Typography style={{fontWeight: 600}} variant="subtitle2" component="div">No more ungated jobs left for this search criteria.</Typography>
                        }
                    </Grid>
                    { scanJob?.getScanJobResult() !== undefined  &&
                      <React.Fragment>
                          <Grid item xs={12}>
                              <Typography sx={{mb: 1}} style={{fontWeight: 600}} variant="subtitle1" component="div">Scan Results</Typography>
                          </Grid>
                          <Grid item xs={12} sx={{ mt: 1 }}>
                              <ScanResultsCard scanJobId={scanJob.getId()} scanJobResult={scanJob.getScanJobResult()} maxHeight={300} />
                          </Grid>
                      </React.Fragment>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TodoCard;